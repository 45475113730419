<app-loading-dialog *ngIf="isLoading">
</app-loading-dialog>


<div class="ms-sm-auto" style="width: 90%;">
    <div class="row" style="height: 100%;">

        <div class="col-2 shadow-3" style="background: #FBF7FE; height: 100vh; overflow-x: hidden; overflow-y: auto; ">

            <app-sidebar-import></app-sidebar-import>

        </div>
        <div class="col-10 p-5" style="height: 100vh; overflow-x: hidden; overflow-y: auto; ">

            <div class="row">
                <div class="col-12">
                    <H3>Upload File</H3>
                    <div class="row">

                        <div class="col-12">
                            <P class="pe-5">We accept the following formats: <b class="font-weight-bold">XLSX</b> or <b
                                    class="font-weight-bold">CSV</b>.
                                <br>
                                Import up to 3,000 rows of data at a time. <br>
                                The first row in your file must contain column headers (first name, middle name, last
                                name,
                                email address, company, address, city, state, country, phone, job title, industry,
                                linkedin, snippet,
                                snippet1, snippet2, snippet3, snippet4 out of all <strong>first name, last name</strong>
                                and <strong>email</strong> are required). <br>
                                Or download any of this <strong>Samples: (<a
                                        href="../../assets/map-samples/prospects_template_xlsx.xlsx"
                                        download>XLSX</a>/<a href="../../assets/map-samples/prospects_template_csv.csv"
                                        download>CSV</a>)</strong>,
                                add your prospects, and import the file.
                            </P>
                        </div>


                    </div>
                </div>




                <div class="col-12">
                    <input type="file" class="import-dnc-input" (change)="onFileSelected($event)" #fileUpload
                        (click)="$event.target.value=null">
                    <div class="import-dnc-upload">
                        <button type="button" class="btn btn-primary btn-sm mt-2 me-2" (click)="fileUpload.click()">
                            <i *ngIf="!importDncUploadInProgress" class="far fa-file-excel"></i>
                            <i *ngIf="importDncUploadInProgress" class="fas fa-spinner fa-spin"></i>
                            Select File
                        </button>
                    </div>

                    <hr>
                </div>


                <div class="col-12 mt-3" *ngIf="importDncUploadInProgress">
                    <p><i class="fas fa-diagnoses"></i> Analyzing the file, please wait...</p>
                </div>


                <div *ngIf="colMap" class="mt-3">
                    <div class="col-12">
                        <h6>Columns: {{colMap?.columns}}, Rows: {{colMap?.rows}}</h6>
                    </div>
                    <div class="col-12">
                      <div class="bg-warning"> <p class="p-3 text-light"> Please match MAP Prospect Field with imported File header.</p> </div>
                    </div>


                    <div class="col-12">
                        <table class="table table-sm table-hover mt-1 table-responsive">
                            <thead style="background-color: #F6F4EE;">
                                <tr>
                                    <th scope="col">MAP Prospect Field</th>
                                    <th scope="col">Import File Header</th>
                                    <th scope="col">Sample Data</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let original of colMap?.original_header; let i = index">
                                    <td>
                                        <ng-select id="availableFieldInput"
                                            (change)='onAvailableFieldSelected($event,original)' >
                                            <ng-option  *ngFor="let o of colMap?.available_fieldset" [value]="o">{{o}}
                                            </ng-option>
                                        </ng-select>
                                    </td>
                                    <td>{{original}}</td>
                                    <td>{{colMap?.sample_data[original].join(',')}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>


                    <div class="col-12">
                        <h6>Duplicate Prospects</h6>
                        <p>Some prospects may already exist in the system. You may skip them or update the existing
                            prospects with the new data.</p>

                        <div class="col">
                            <div class="col-1">
                                <div class="form-check">
                                    <input mdbRadio class="form-check-input" type="radio" name="duplicateProspects"
                                        id="skip" (change)="changeAction($event)" value="Skip" />
                                    <label class="form-check-label" for="skip"> Skip </label>
                                </div>

                                <div class="form-check">
                                    <input mdbRadio class="form-check-input" type="radio" name="duplicateProspects"
                                        id="update" (change)="changeAction($event)" value="Update" />
                                    <label class="form-check-label" for="update"> Update </label>
                                </div>
                            </div>

                        </div>
                        <div class="col">
                            <button type="button" class="btn btn-primary btn-sm mt-2 me-2"
                             (click)="validateImport()">Validate Data & Upload</button>
                        </div>
                    </div>
                </div>



                <!-- <div *ngIf="completedValidation">

                    <div class="col-12 mt-5" >
                        <div class="bg-success"> <p class="p-3 text-light"> Your uploaded file is now validated and ready to import,
                            click on "Upload" to finish the import process.</p> </div>
                      </div>

                    <button type="button" class="btn btn-primary btn-sm mt-2 me-2" *ngIf="completedValidation"
                    (click)="completeImport()">Upload</button>

                </div> -->




            </div>



        </div>

    </div>


  <div class="modal shadow d-block" *ngIf="errorMsg" id="staticBackdrop">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Error while uploading  data.</h5>
          <button type="button" class="btn-close" (click)="closeModal()" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <p class="text-danger"> {{ errorMsg?.error }} </p>
              <p class="text-danger text-fix text-break" *ngIf="errorMsg?.error_row_no?.blank_firstname_row_no?.length > 0 "> First Name related error at row number: {{ errorMsg?.error_row_no?.blank_firstname_row_no }}</p>
              <p class="text-danger text-fix text-break" *ngIf="errorMsg?.error_row_no?.blank_emailaddress_row_no?.length > 0">Blank Email Address  related error at row number: {{ errorMsg?.error_row_no?.blank_emailaddress_row_no }}</p>
              <p class="text-danger text-fix text-break" *ngIf="errorMsg?.error_row_no?.invalid_emailaddress_row_no?.length > 0">Invalid Email Address  related error at row number: {{ errorMsg?.error_row_no?.invalid_emailaddress_row_no }}</p>
            </div>
            </div>
        </div>
        <div class="modal-footer justify-content-between">
            <p class="font-weight-bold text-center mt-3"> Try again after fixing error in your file! </p>
          <button type="button" class="btn btn-secondary" (click)="closeModal()" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>


  <div class="modal shadow d-block" *ngIf="errorAfterUpload.rows > 3000"  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
        <!-- <h5 class="modal-title" id="staticBackdropLabel">Data Error:</h5>-->
          <button type="button" class="btn-close" (click)="closeModal2()" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">

                <div class="alert alert-danger p-2 text-center" role="alert">
                  <i class="fas fa-exclamation-triangle pe-2"></i>  {{errorAfterUpload.fileName}} contains more than 3000 rows.
                  <!--({{columnMappingResponseModel.value.rows}}). -->
                </div>

              <p class="text-center mt-3 mb-0 "> MAP supports importing upto 3000 prospects in file. </p>
              </div>
            </div>
        </div>
        <div class="modal-footer justify-content-end">

          <button type="button" class="btn btn-secondary" (click)="closeModal2()" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="errorAfterUpload.rows > 3000 || errorMsg" class="modal-backdrop fade show"></div>
