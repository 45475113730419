

<div class="ms-sm-auto" style="width: 90%;">

<div class="row" style="height: 100%;">



  <div class="col-2 shadow-3" style="background: #FBF7FE; height: 100vh; overflow-x: hidden; overflow-y: auto; ">

        <app-sidebar-settings></app-sidebar-settings>
    </div>




  <div class="col-10 p-3" style="height: 100vh; overflow-x: hidden; overflow-y: auto; ">


    <div class="d-flex mb-3">
      <div class="me-auto"> <h4>Prospect Groups</h4> </div>

      <form #groupsSearchForm="ngForm" >
        <mdb-form-control class="me-3">
          <input mdbInput type="text" id="groupsSearch" class="form-control "
          [ngModel]="groupSearchModel" name="search"  (input)="groupSearchSubmit($event)" />
          <label mdbLabel class="form-label" for="groupsSearch">Search</label>
        </mdb-form-control>
      </form>


        <!--<button type="button" class="btn btn-primary"><i class="fas fa-plus"></i> Add Group</button>-->

    </div>
   <hr>


    <div class="row" style="margin-left: 12px; margin-right: 17px;">
      <p class="mb-2 ps-0">Manually group similar prospects together. </p>

      <div class="table-responsive tableFixHead" infiniteScroll [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="100" (scrolled)="onProspectGroupScroll()" [scrollWindow]="false">
      <table class="table align-middle table-hover">
        <thead style="background-color: #F6F4EE;">
          <tr>
            <th scope="col">Name</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let i of prospectGrouplist;" class="tableHooverEffect">
            <td><a routerLink="/prospects" (click)="gotoProspectPage(i)"><strong>{{i.name}}</strong></a></td>
            <td>
              <button style="float: right;" type="button" (click)="confirmDeleteGroup(i.id)" class="btn btn-danger btn-sm px-3">
                <i class="fas fa-times"></i>
              </button>
            </td>
          </tr>

        </tbody>
      </table>
      </div>


    </div>

  </div>

  </div>


  </div>