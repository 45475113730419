
<app-loading-dialog *ngIf="isLoading"></app-loading-dialog>




<div class="ms-sm-auto" style="width: 90%;">
  <div class="row" style="height: 100%;">



    <div class="col-2" style="background: #FBF7FE; height: 100vh; overflow-x: hidden; overflow-y: auto; scrollbar-width: thin;">
      <app-sidebar-prospects
      [userList]="userList"
      [prospectLoaded]="prospectLoad"
      [prospectStatuslist]="prospectStatuslist"
      (prospectGroupChangeEvent)="ProspectGroupReceivedFromSidebar($event)"
      (campaignsChangeEvent)="CampaignReceived($event)"
      >
    </app-sidebar-prospects>
    </div>




    <div class="col-10 m-0 pe-0" style="height: 100vh; overflow-x: hidden; overflow-y: auto;scrollbar-width: thin; ">

      <!-- Header -->
      <div class="row shadow-1 p-2 sticky-top" style="z-index: 2; background-color: #FBF7FE;">

        <div class="col-3 pe-0">
          <ng-container *ngIf="totalProspectRecieved else loadingTotal">
            <h4 class="map-text-header mt-1">Prospects
              <span *ngIf="totalProspectRecieved && selectedProspectlist === 0"
              class="header-count">({{totalProspectRecieved}} found) </span>
              <!-- <br *ngIf="totalProspectRecieved && selectedProspectlist >= 1"> -->
              <span *ngIf="totalProspectRecieved && selectedProspectlist >= 1"
                class="header-count">({{selectedProspectlist}} of {{totalProspectRecieved}}
                selected) </span>
              <!-- selectedProspectlist.length -->
            </h4>
          </ng-container>
          <ng-template #loadingTotal>
            <ng-container *ngIf="totalProspectRecieved === 0 else spinnerProspects ">
              <h4 class="map-text-header">Prospects
                <span class="header-count">(0 found) </span>
              </h4>
            </ng-container>
            <ng-template #spinnerProspects>
              <app-spinner></app-spinner>
            </ng-template>
          </ng-template>
        </div>

        <div class="col-3 d-flex p-0">
          <input #campaignType [(ngModel)]="selectedCampaign" (typeaheadOnSelect)="onSelect($event)"
            (typeaheadLoading)="changeTypeaheadLoading($event)" [typeaheadMinLength]="0" [typeahead]="campaignlist"
            typeaheadOptionField="name" typeahead-wait-ms="100" class="form-control form-control-sm" name="Campaigns" type="text"
            (paste)="onPaste($event)" placeholder="Search campaigns" *ngIf="this.showCompaignDrop" autocomplete="off" />
          <span class="highlight"></span>
        </div>

        <!-- <div class="col-1">

        </div> -->

        <div class="col-6">



          <div class="d-flex justify-content-end">
            <button type="button" style="margin-right: 10px !important;" *ngIf="showAddCompaign" (click)="openSafetyChecks()"
            class="btn btn-light btn-sm ">Add to Campaign</button>


            <button type="button" *ngIf="showCompaignDrop" (click)="confirmDeleteProspect()"
              class="btn btn-link btn-sm">
              <i *ngIf="showingDeleteLoading" class="fas fa-spinner fa-spin"></i>
              <i *ngIf="!showingDeleteLoading" class="far fa-trash-alt"></i>
            </button>

            <button type="button" *ngIf="selectedProspectlist > 0" (click)="exportAll()"
            class="btn btn-link btn-sm">
            <i  *ngIf="showingExportLoading" class="fas fa-spinner fa-spin"></i>
            <i *ngIf="!showingExportLoading" class="fas fa-cloud-download-alt"></i>
          </button>




            <div mdbDropdown class="dropdown" style="margin-right: 10px !important;">
              <button class="btn btn-link btn-sm" type="button" id="columeHide" role="button" aria-expanded="false"
                mdbDropdownToggle>
                <i class="fas fa-eye"></i> Columns
              </button>
              <ul mdbDropdownMenu class="dropdown-menu dropdown-menu-end" *ngFor="let column of columnShowHideList"
                aria-labelledby="columeHide">

                <li class="p-1"><input mdbCheckbox class="form-check-input campaign-status-checkbox"
                    [(ngModel)]="column.isActive" (change)="toggleColumn(column)" [checked]="column.isActive"
                    type="checkbox" [id]="column.name" />
                  <label class="ps-1 form-check-label map-text-header" [for]="column.name">{{column.name}}</label>

                </li>
              </ul>
            </div>


            <div mdbDropdown class="dropdown dropstart">
              <button class="btn btn-primary btn-sm" type="button" id="dropdownMenuButton" aria-expanded="false"
                mdbDropdownToggle>
                <i class="fas fa-plus"></i> PROSPECTS
              </button>
              <ul mdbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <li><a class="dropdown-item" href="/imports/column-mapping"><i class="fas fa-compress text-primary"></i> Advanced Upload</a>
                </li>
                <!-- <li><a class="dropdown-item" href="/imports/new-v2"><i class="fas fa-file-upload text-primary"></i> Editable Upload</a>
                </li>
                <li><a class="dropdown-item" href="/imports/new"><i class="fas fa-file-upload text-primary"></i> Non-Editable Upload</a>
                </li> -->
                <li><a class="dropdown-item" href="/imports/one-prospect"><i class="fas fa-user-plus text-primary"></i> One Prospect Upload</a>
                </li>
                <hr class="dropdown-divider m-0" />
                <li><a routerLink="/imports" class="dropdown-item"><i class="fas fa-list-ul text-warning"></i> All Imports</a></li>

              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- Header end-->

      <div class="row">

        <!-- <div class="row mt-0 pt-0">
          <div class="col-12 text-center">
            <p class="p-0 m-0">Select All items related to this filters or search <strong
                style="text-decoration: underline; cursor: pointer;">(1666 prospects)</strong></p>
          </div>
            </div> -->
        <!-- <div (window:resize)="onResize($event)"> -->
        <!-- <div > -->
        <!-- <button (click)="container.scrollTop = 0">scroll to top</button> -->
        <div class="col-12 p-0" style="scrollbar-width: thin;">
          <div #prospectContainer class="table-responsive" infiniteScroll [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="100" (scrolled)="onProspectScroll()" [scrollWindow]="false">
            <table class="table table-sm table-hover  table-striped  table-bordered" >
              <thead>
                <tr>
                  <th scope="col">

                    <input #masterSelect mdbRadio style="cursor: pointer;" type="checkbox" [(ngModel)]="isMasterSel"
                      name="flexRadioDefault1" id="flexRadioDefault1" (change)="checkUncheckAll($event)" />

                  </th>
                  <th [class]="column_selected == 'email_address' ? 'header-name active' : 'header-name'" scope="col"
                  (click)="sortOn(0,'email_address')"><i class="fas fa-sort"></i> Email</th>
                  <!-- <th *ngIf="this.showStatusColumn">Status</th> -->
                  <th *ngIf="this.showStatusColumn" [class]="column_selected == 'status' ? 'header-name active' : 'header-name'" scope="col"
                  (click)="sortOn(0,'status')"><i class="fas fa-sort"></i> Status</th>
                  <th *ngIf="this.showOwnerColumn" [class]="column_selected == 'owner_name' ? 'header-name active' : 'header-name'" scope="col"
                  (click)="sortOn(0,'owner_name')"><i class="fas fa-sort"></i> Owner</th>
                  <!-- <th *ngIf="this.showRegionColumn" [class]="column_selected == 'region' ? 'header-name active' : 'header-name'" scope="col"
                  (click)="sortOn(0,'region')"><i class="fas fa-sort"></i> Region</th> -->
                  <th *ngIf="this.showCampaignsColumn" [class]="column_selected == 'cnt_campaign' ? 'header-name active' : 'header-name'" scope="col"
                  (click)="sortOn(0,'cnt_campaign')"><i class="fas fa-sort"></i> Campaigns</th>
                  <th *ngIf="this.showLastContactedColumn" [class]="column_selected == 'last_contacted' ? 'header-name active' : 'header-name'" scope="col"
                  (click)="sortOn(0,'last_contacted')"><i class="fas fa-sort"></i> Last Contacted</th>
                   <!-- <th *ngIf="this.showStatusColumn" [class]="column_selected == 'status_name' ? 'header-name active' : 'header-name'" scope="col"
                   (click)="sortOn(0,'status_name')"><i class="fas fa-sort"></i>Status</th> -->
                  <th *ngIf="this.showEmailSentColumn" [class]="column_selected == 'cnt_email_sent' ? 'header-name active' : 'header-name'" scope="col"
                  (click)="sortOn(0,'cnt_email_sent')"><i class="fas fa-sort"></i> Email Sent</th>
                  <th *ngIf="this.showProspectScoreColumn" [class]="column_selected == 'score' ? 'header-name active' : 'header-name'" scope="col"
                  (click)="sortOn(0,'score')"><i class="fas fa-sort"></i> Score</th>
                  <th *ngIf="this.showRepliesColumn" [class]="column_selected == 'cnt_replies_received' ? 'header-name active' : 'header-name'" scope="col"
                  (click)="sortOn(0,'cnt_replies_received')"><i class="fas fa-sort"></i> Replies</th>
                  <th *ngIf="this.showOptedOutColumn" [class]="column_selected == 'is_opted_out' ? 'header-name active' : 'header-name'" scope="col"
                  (click)="sortOn(0,'is_opted_out')"><i class="fas fa-sort"></i> Opted Out</th>
                  <th *ngIf="this.showBouncedColumn" [class]="column_selected == 'is_bounced' ? 'header-name active' : 'header-name'" scope="col"
                  (click)="sortOn(0,'is_bounced')"><i class="fas fa-sort"></i> Bounced</th>
                  <!-- <th *ngIf="this.showTimezoneColumn" [class]="column_selected == 'name' ? 'header-name active' : 'header-name'" scope="col"
                  (click)="sortOn(0,'name')"><i class="fas fa-sort"></i> Timezone</th> -->
                  <!-- <th *ngIf="this.showGroupColumn" [class]="column_selected == 'group_name' ? 'header-name active' : 'header-name'" scope="col"
                  (click)="sortOn(0,'group_name')"><i class="fas fa-sort"></i> Group</th> -->
                  <!-- <th *ngIf="this.showSnippetColumn" [class]="column_selected == 'snippet' ? 'header-name active' : 'header-name'" scope="col"
                  (click)="sortOn(0,'snippet')"><i class="fas fa-sort"></i> Snippet</th>
                  <th *ngIf="this.showSnippet1Column" [class]="column_selected == 'snippet1' ? 'header-name active' : 'header-name'" scope="col"
                  (click)="sortOn(0,'snippet1')"><i class="fas fa-sort"></i> Snippet 1</th>
                  <th *ngIf="this.showSnippet2Column" [class]="column_selected == 'snippet2' ? 'header-name active' : 'header-name'" scope="col"
                  (click)="sortOn(0,'snippet2')"><i class="fas fa-sort"></i> Snippet 2</th>
                  <th *ngIf="this.showSnippet3Column" [class]="column_selected == 'snippet3' ? 'header-name active' : 'header-name'" scope="col"
                  (click)="sortOn(0,'snippet3')"><i class="fas fa-sort"></i> Snippet 3</th>
                  <th *ngIf="this.showSnippet4Column" [class]="column_selected == 'snippet4' ? 'header-name active' : 'header-name'" scope="col"
                  (click)="sortOn(0,'snippet4')"><i class="fas fa-sort"></i> Snippet 4</th> -->
                </tr>
              </thead>
              <tbody>


                <tr *ngFor="let i of prospectlist; let count=index;">
                  <td scope="row">
                    <input type="checkbox" [(ngModel)]="i.isSelected" name="list_name" value="{{i.id}}"
                      (change)="isAllSelected($event, i.id)" />
                    {{count+1 }}

                  </td>
                  <td><a [class]="i.is_deleted == true ? 'prospect-deleted-item': 'no-class'" routerLink="/prospects/{{i.id}}"><strong>{{i.email_address}}</strong></a></td>
                  <td *ngIf="this.showStatusColumn">{{getStatusName(i)}}
                    </td>
                  <td *ngIf="this.showOwnerColumn">{{getUserName(i)}}</td>
                  <!-- <td *ngIf="this.showOwnerColumn">{{i.region}}</td> -->
                  <td class="text-center" *ngIf="this.showCampaignsColumn">{{i.cnt_campaign}}</td>
                  <td class="text-center" *ngIf="this.showLastContactedColumn">{{i.last_contacted | date: 'medium'}}
                  <!-- </td>
                      <td *ngIf="this.showStatusColumn">{{getStatusName(i)}}
                    </td> -->
                  <td class="text-center" *ngIf="this.showEmailSentColumn">{{i.cnt_email_sent}}</td>
                  <td class="text-center" *ngIf="this.showProspectScoreColumn">{{i.score}}</td>
                  <td class="text-center" *ngIf="this.showRepliesColumn">{{i.cnt_replies_received}}</td>
                  <td *ngIf="this.showOptedOutColumn">{{i.is_opted_out == false ? 'No' : 'Yes' }}</td>
                  <td *ngIf="this.showBouncedColumn">{{i.is_bounced == false ? 'No' : 'Yes' }}</td>
                  <!-- <td *ngIf="this.showTimezoneColumn"></td> -->
                  <!-- <td *ngIf="this.showGroupColumn">{{getProspectGroupName(i)}}</td>
                  <td *ngIf="this.showSnippetColumn">{{i.snippet}}</td>
                  <td *ngIf="this.showSnippet1Column">{{i.snippet1}}</td>
                  <td *ngIf="this.showSnippet2Column">{{i.snippet2}}</td>
                  <td *ngIf="this.showSnippet3Column">{{i.snippet3}}</td>
                  <td *ngIf="this.showSnippet4Column">{{i.snippet4}}</td> -->
                </tr>

              </tbody>
            </table>
          </div>
        </div>
        <!-- <div class="resize-handle-bottom" mwlResizeHandle [resizeEdges]="{ bottom: true }"></div> -->
        <!-- </div> -->
        <!-- </div> -->
      </div>
    </div>
  </div>
</div>

