import { Component, OnInit } from '@angular/core';
import { DashboardResponseModel, CampaignResponseModel } from './dashbord-model/dashboard-model';
import { BehaviorSubject } from 'rxjs';
import { DashboardViewModel } from './dashbord-model/dashboard-view-model';
import { MapAPIService } from '../app-providers/services/map-api-service';
import { ChartDataSets, ChartOptions} from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { MapCommon } from '../app-providers/map-base/MapCommon';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [MapAPIService, DashboardViewModel],

})
export class DashboardComponent implements OnInit {

  currentUser: any = null;

  // Dash 1
  public dashChartData1: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55], label: 'Sent' },
    { data: [56, 41, 70, 12, 4, 1], label: 'Open' },
    { data: [10, 8, 9, 2, 1, 0], label: 'Replied' },
  ];
  public dashChartLabels1: Label[] = ['January', 'February', 'March', 'April', 'May', 'June'];
  public dashChartColors1: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(84, 48, 116,0.3)',
      borderWidth: 1
    },
    {
      borderColor: 'black',
      backgroundColor: 'rgba(255,0,0,0.5)',
      borderWidth: 1
    },
    {
      borderColor: 'black',
      backgroundColor: 'rgba(255, 226, 154, 0.8)',
      borderWidth: 1
    },
    
  ];

  // Dash 2
  public dashChartData2: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55], label: 'Campaigns' },
    { data: [34, 12, 24, 65, 12, 11], label: 'Steps' },
  ];
  public dashChartLabels2: Label[] = ['January', 'February', 'March', 'April', 'May', 'June'];
  public dashChartColors2: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(84, 48, 116,0.3)',
      borderWidth: 1
    },
    {
      borderColor: 'black',
      backgroundColor: 'rgba(255,0,0,0.5)',
      borderWidth: 1
    },
    
  ];

   // Dash 2
   public dashChartData3: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55], label: 'Sent' },
    { data: [34, 12, 24, 65, 12, 11], label: 'Open' },
    { data: [34, 12, 24, 65, 12, 11], label: 'Replied' },
  ];
  public dashChartLabels3: Label[] = ['domain1.com', 'domain2.com', 'domain3.com', 'domain4.com', 'domainaa.com'];
  public dashChartColors3: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(84, 48, 116,0.3)',
      borderWidth: 1
    },
    {
      borderColor: 'black',
      backgroundColor: 'rgba(255,0,0,0.5)',
      borderWidth: 1
    },
    {
      borderColor: 'black',
      backgroundColor: 'rgba(255, 226, 154, 0.8)',
      borderWidth: 1
    },
  ];


  public overviewButtons =  [
    {id: '24hours', name: '24 Hours', selected: false},
    {id: '7days', name: '7 Days', selected: false},
    {id: '1month', name: '1 Month', selected: false},
    {id: '6months', name: '6 Months', selected: false},
    {id: 'all', name: 'All', selected: true}
  ]
  selectedOverviewButton = 'all';
  loadingOverviewStats = false;

  grafanaPanels = [
    { id: 1, 
      name: "Some Panel 1", 
      url: 'http://dev.map.buyerforesight.net:3000/d-solo/yhUhHGo7k/bfs-map?orgId=1&from=1648730521502&to=1656506521502&panelId=2'
    }
  ]

  dashboardResponseModel = new BehaviorSubject<DashboardResponseModel>(
    new DashboardResponseModel()
  );

  campaignResponseModel = new BehaviorSubject<CampaignResponseModel>(
    new CampaignResponseModel()
  );
  constructor(private vm: DashboardViewModel) {
    const localStorage_user = MapCommon.getloggedInUser();
    if (localStorage_user){
      this.currentUser = localStorage_user
    }
   } 

  ngOnInit(): void {
    this.vm.parentDashboardComponent = this;
    this.vm.subscribeToDashboardVariable(this.dashboardResponseModel, this.campaignResponseModel);
    this.getDashboardOverview();
    this.vm.getTopCampaign(5);
    this.getGrafanaPanels();
  }

  getGrafanaPanels(){
    this.vm.getGrafanaPanel(this.grafanaPanels[0].url)
  }

  getDashboardOverview(){
    this.loadingOverviewStats = true;
    this.vm.getAllDashboardEntities(this.selectedOverviewButton);
  }

  getPercentage(total, value){
    const newValue = 100 * Number(value) / Number(total);
    return Number(newValue.toFixed(2));
  }

  receivedDashboardOverview(){
    if(this.dashboardResponseModel.value){
      this.dashboardResponseModel.value.open_percentage = this.getPercentage(
        this.dashboardResponseModel.value.email_sent_count,
        this.dashboardResponseModel.value.email_open_count
      );

      this.dashboardResponseModel.value.dnc_percentage = this.getPercentage(
        this.dashboardResponseModel.value.email_sent_count,
        this.dashboardResponseModel.value.dnc_count
      );

      this.dashboardResponseModel.value.reply_percentage = this.getPercentage(
        this.dashboardResponseModel.value.email_sent_count,
        this.dashboardResponseModel.value.email_reply_count
      );

      this.dashboardResponseModel.value.click_percentage = this.getPercentage(
        this.dashboardResponseModel.value.email_sent_count,
        this.dashboardResponseModel.value.click_count
      );
    }

    this.loadingOverviewStats = false;
  }


  selectOverviewButton(value): void{
    this.selectedOverviewButton = value;
    this.overviewButtons.forEach(element => {
      if(element.id == value){
        element.selected = true;
      } else {
        element.selected = false;
      }
    });
    this.getDashboardOverview();
  }



}

