// Update URL
const APP_URL = 'https://bst.map.buyerforesight.net';
const API_URL = 'https://api.bst.map.buyerforesight.net'; 
 
// const APP_URL = 'https://dev.map.buyerforesight.net';

// const APP_URL = 'http://localhost:8000';
// const API_URL = 'http://127.0.0.1:8080';

export const environment = {
  platform_version: "v2.0",
  production: false,
  base_app_url: APP_URL,
  base_api_url: API_URL,
  api_full_url: API_URL + '/bfsmap/v2',
  oauth_login_redirect: APP_URL + '/settings/mailbox',
  encryption_iv: "LOLOMOLOKOLOPOLO",
  encryption_password: "Hhyu55s@JJue827j"

};
