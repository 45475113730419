<div class="col-12 d-flex justify-content-between">
    <h6 class="pt-1 ps-1">Company DNC</h6>
  
    <form #companySearchForm="ngForm">
      <mdb-form-control class="me-3">
        <input mdbInput type="text" id="companySearch" class="form-control form-control-sm" 
        [ngModel]="companySearchValue" name="search" (input)="companySearchSubmit($event)" />
        <label mdbLabel class="form-label" for="companySearch"><i class="fas fa-city"></i> Search</label>
      </mdb-form-control>
    </form>


    <button type="button" (click)="addCompanyModal()" class="btn btn-link btn-sm" > <i class="fas fa-plus"></i> Add Domain</button>
</div>
<div class="table-responsive" infiniteScroll [infiniteScrollDistance]="2"
[infiniteScrollThrottle]="100" (scrolled)="onClientDNCScroll()" [scrollWindow]="false">
<table class="table table-sm mt-1 table-responsive table-hover">
    <thead style="background-color: #F6F4EE;">
      <tr>
        <th scope="col">Domain</th>
        <th scope="col">Added at</th>
      </tr>
    </thead>
    <tbody>

      <tr (click)="editCompanyModal(i)" class="dncItem" *ngFor="let i of companyDncList">
        <td class="text-primary"><strong>{{i.email_domain}}</strong></td>
        <td>{{i.created_at | date:'medium'}}</td>
      </tr> 

    </tbody>
  </table></div>
