import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-alert-notifications',
  templateUrl: './alert-notifications.component.html',
  styleUrls: ['./alert-notifications.component.scss']
})
export class AlertNotificationsComponent implements OnInit {
  alert_message = "";
  typeOfAlert = "primary";
  static alertCall;
  constructor() { AlertNotificationsComponent.alertCall = this;}

  ngOnInit(): void {

  }
// Method to show alert
showAlert(alertMessage, typeOfAlert = "primary") {
  this.alert_message = alertMessage;
  if (typeOfAlert == "primary") {
  //  alert(alertMessage);
    $("#primarytoast").toast("show");
  } else {
    $("#dangertoast").toast("show");
  }
}
}
