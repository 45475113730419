import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'plus'
})
export class PlusPipe implements PipeTransform {

  transform(num: number, args?: any): any {
    return Math.abs(num);
  }

}
