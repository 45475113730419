<div class="ms-sm-auto" style="width: 90%;">


    

    <div class="row" style="height: 100%;">
    
        <div class="col-2 shadow-3 " style="background: #FBF7FE; height: 100vh; overflow-x:hidden; 
        overflow-y: auto; z-index: 1;">
           <app-analytics-sidebar></app-analytics-sidebar>
         </div>
  
  
         <div class="col-10 m-0" style="height: 100vh; overflow-x: hidden; overflow-y: auto; ">
  
            
  
      <div class="row shadow-1 p-3 sticky-top bg-white" style="z-index: 2;">
        <div class="col-md-4">
          <h4 class="map-text-header">Opt-Out Tracking 
              <!-- <span
              style="font-size: 12px; color: #ef6c00;">({{totalClientRecieved}} found)</span> -->
            </h4>
        </div>
  
        <div class="col-md-8">
          <div class="d-flex justify-content-end">
            
                <mdb-form-control style="width: 350px;">
                  <input mdbInput type="text"  id="search" class="form-control form-control-sm" 
                   name="search"  />
                  <label mdbLabel class="form-label" for="search">
                      <i class="far fa-envelope"></i> Search</label>
                </mdb-form-control>
                
          </div>
        </div>
      </div>
  
        </div>
    
    
    </div>
  </div>
  