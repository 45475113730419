import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observer } from 'rxjs';
import { MapCommon } from '../app-providers/map-base/MapCommon';
import { LoginUserModel } from './models/login-model';
import { LoginViewModel } from './models/login-view-model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  usernameModel: string = '';
  passwordModel: string = '';
  loginError: string = '';
  currentUser: LoginUserModel = null;
  isLoggedIn: boolean = false;

  // loginUserModel = new BehaviorSubject<LoginUserModel>(
  //   new LoginUserModel()
  // );

  constructor(private vm: LoginViewModel, private router: Router) {
  const localStorage_user = MapCommon.getloggedInUser();
  if (localStorage_user){
    this.currentUser = localStorage_user
    this.isLoggedIn = true;
    this.router.navigate(['/prospects']);
  }

   this.vm.parentLogin = this;
  //  this.vm.subscribeLoginUserVariable(this.loginUserModel)
  }


  
  // subscribeLoginUserVariable(
  //   observerLoginUserModel: Observer<LoginUserModel>,) {
  //   this.loginUserModel.subscribe(observerLoginUserModel)
  // }


  ngOnInit(): void {
  }

  userDetailReceived(){
    const localStorage_user = MapCommon.getloggedInUser();
    if (localStorage_user){
      this.currentUser = localStorage_user;
      this.isLoggedIn = true;
      this.router.navigate(['/prospects']);
    } else {
      this.loginError = "Unable to login, please check with admin."
    }

  }

  Login(){
    if (this.usernameModel.length === 0 || this.passwordModel.length === 0){
      this.loginError = "Username or password can't be empty"
    } else {
      const encodedCred = btoa(this.usernameModel + '||' + this.passwordModel)
      this.vm.logMeIn(encodedCred);
    }
  }

}
