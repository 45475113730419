
<app-loading-dialog *ngIf="loadingSpiner">
    
</app-loading-dialog>

<div class="col-12 pe-3 ps-3">


    <div class="col-12">

      <div style="overflow-y: auto;" class="pb-2">

      <div class="btn-group btn-group-lg shadow-1 prospectStats" role="group" aria-label="prospectStats">
        <button type="button"
          [class]="tab_selected == 'All' ? 'btn prospectStatsManuItemActive' : 'btn prospectStatsManuItem'"
          (click)="filterTabClicked('All',0)">All</button>

          <button type="button" *ngFor="let csd of campaignStepDetails; let count=index;"
        [class]="tab_selected == csd.id ? 'btn prospectStatsManuItemActive' : 'btn prospectStatsManuItem'"
        (click)="filterTabClicked(csd.id,csd.id)">Step {{count+1}}</button> 
      </div>
    </div>
  
      <div class="col-12 mt-3">
        <div class="btn-group btn-group-lg shadow-1 prospectStats" role="group" aria-label="prospectStats">

          <button type="button"
          [class]="btn_selected == 'Total' ? 'btn prospectStatsManuItemActive' : 'btn prospectStatsManuItem'"
          id="btn_total" (click)="filterButtonClick('Total')" >Total<br><span
            class="prospectStatsNumber">{{cnt_total_auto_reply}}</span></button>

          <button type="button"
          [class]="btn_selected == 'On Vacation' ? 'btn prospectStatsManuItemActive' : 'btn prospectStatsManuItem'"
            id="btn_total" (click)="filterButtonClick('On Vacation')">On Vacation <br><span
              class="prospectStatsNumber">{{cnt_ooo_reply}}</span></button>

              <button type="button"
              [class]="btn_selected == 'No Longer Working' ? 'btn prospectStatsManuItemActive' : 'btn prospectStatsManuItem'"
            id="btn_total" (click)="filterButtonClick('No Longer Working')">No Longer Working<br><span
              class="prospectStatsNumber">{{cnt_nlw_reply}}</span></button>


          <!-- <button type="button"
            [class]="btn_selected == 'Total' ? 'btn prospectStatsManuItemActive' : 'btn prospectStatsManuItem'"
            id="btn_total" (click)="filterButtonClick('Total')">Total <br><span
              class="prospectStatsNumber">{{cnt_email_total}}</span></button>
          <button type="button"
            [class]="btn_selected == 'New' ? 'btn prospectStatsManuItemActive' : 'btn prospectStatsManuItem'" id="btn_New"
            (click)="filterButtonClick('New')">New <br><span class="prospectStatsNumber">{{cnt_new}}</span></button>
          <button type="button"
            [class]="btn_selected == 'Processing' ? 'btn prospectStatsManuItemActive' : 'btn prospectStatsManuItem'"
            id="btn_Processing" (click)="filterButtonClick('Processing')">{{campiagnStatus == true ? 'Processing' :
            'Paused'}}
            <br><span class="prospectStatsNumber">{{cnt_processing}}</span></button>
          <button type="button"
            [class]="btn_selected == 'Scheduled' ? 'btn prospectStatsManuItemActive' : 'btn prospectStatsManuItem'"
            id="btn_Scheduled" (click)="filterButtonClick('Scheduled')">Scheduled <br><span
              class="prospectStatsNumber">{{cnt_scheduled}}</span></button>
          <button type="button"
            [class]="btn_selected == 'Finished' ? 'btn prospectStatsManuItemActive' : 'btn prospectStatsManuItem'"
            id="btn_Finished" (click)="filterButtonClick('Finished')">Sent <br><span
              class="prospectStatsNumber">{{cnt_finished}}</span></button>
          <button type="button"
            [class]="btn_selected == 'Opened' ? 'btn prospectStatsManuItemActive' : 'btn prospectStatsManuItem'"
            id="btn_Opened" (click)="filterButtonClick('Opened')">Opened <br><span
              class="prospectStatsNumber">{{cnt_opened}}</span></button>
          <button type="button"
            [class]="btn_selected == 'Replied' ? 'btn prospectStatsManuItemActive' : 'btn prospectStatsManuItem'"
            id="btn_Replied" (click)="filterButtonClick('Replied')">Replied <br><span
              class="prospectStatsNumber">{{cnt_replied}}</span></button>
          <button type="button"
            [class]="btn_selected == 'Bounced' ? 'btn prospectStatsManuItemActive' : 'btn prospectStatsManuItem'"
            id="btn_Bounced" (click)="filterButtonClick('Bounced')">Bounced <br><span
              class="prospectStatsNumber">{{cnt_bounced}}</span></button>
          <button type="button"
            [class]="btn_selected == 'OptedOut' ? 'btn prospectStatsManuItemActive' : 'btn prospectStatsManuItem'"
            id="btn_OptedOut" (click)="filterButtonClick('OptedOut')">Opted Out <br><span
              class="prospectStatsNumber">{{cnt_opted_out}}</span></button> -->
        </div>
      </div>
  
  
    </div>
  
  
  </div>
  
  <div class="col-12 pe-3 ps-3 mt-3">
    <div class="d-flex justify-content-between">


       <mdb-form-control>
          <input mdbInput type="text" id="form1" class="form-control" (input)="handleUserInput()" 
          [(ngModel)]="emailContainsText" value="{{emailContainsText}}" />
            <label mdbLabel class="form-label" for="form1"><i class="fas fa-search"></i> Search</label>
        </mdb-form-control> 
  
         <button (click)="exportAll()" class="btn btn-link">
        <i class="fas fa-cloud-download-alt" style="color: #EF6C00;"></i> Export All</button> 
    </div>
  </div>
  
  <div class="table-responsive tableFixHead" infiniteScroll [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="1000" (scrolled)="onEmailScroll()" [scrollWindow]="false"> 
  <ng-container *ngIf="campaignRunLogList.length >= 1 else noEmailAdded">
  <div class="col-12 pe-3 ps-3 mt-3">
  
    <div class="table-resposive">
        <table class="table bg-white shadow-1 table-sm table-hover  table-bordered">
          <thead style="background-color: rgb(240, 240, 240);">
                <tr>
                  
                  <th scope="col">Email</th>
                  <th scope="col">Step</th>
                  <th scope="col">Replied at</th>
                  <th scope="col">Sent At</th>
                  <th scope="col">Auto Reply Type</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let i of campaignRunLogList">
                  <td (click)="viewEmailDetail(i)" style="cursor: pointer;">
                    <strong class="text-primary">{{i.recipient_email_address}}</strong>
                  </td>
                  <td>{{i.subject}}</td>
                  <td>{{i.email_replied_at | date: 'medium'}}</td>
                  <td>{{i.email_sent_at | date: 'medium'}} </td>
                  <td>{{i.auto_reply_type}}</td>
                </tr>
      
               
              </tbody>
        </table>
      </div>
  </div>
  </ng-container>
  </div>
  
  <ng-template #noEmailAdded>
  <div class="col-12"> 
    <div class="row add-step me-3 ms-3 shadow-1 bg-white mt-3 p-3" style="border-radius: 5px;">
      <div class="col-12">
        <span><i style="color:#EF6C00" class="far fa-envelope fa-3x add-step-icon"></i></span>
        <p class="add-step-text">No email found!<br/>
          <span>Add prospects and send emails to them.</span>
        </p>
        <button type="button" routerLink="/prospects"  class="btn btn-primary">+ Add prospects</button>
      </div>
    </div>
  </div>
  </ng-template>
  
  <div class="row mt-5">
    <div class="col-12 mt-5"></div>
    <div class="col-12 mt-5"></div> 
  </div>
  
  
  
  
  
  
  
  
   
  