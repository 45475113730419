
<app-loading-dialog *ngIf="isLoading">

</app-loading-dialog>

<div class="modal-header" data-backdrop="false">
  <h5 class="modal-title" id="exampleModalLabel">New Campaign</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">

  <div class="col-12">
    <mdb-form-control>
      <input mdbInput type="text" id="form1" [(ngModel)]="campaignModel.name" class="form-control" />
      <label mdbLabel class="form-label" for="form1">Campaign Name *</label>
    </mdb-form-control>
  </div>


  <div class="settings-form-field pt-3">
    <input [(ngModel)]="selectedClient" (typeaheadOnSelect)="onSelectClient($event)"
    (typeaheadLoading)="changeTypeaheadLoadingClient($event)" [typeaheadMinLength]="0" [typeahead]="clientList"
    typeaheadOptionField="name" typeahead-wait-ms="50" class="form-control" name="Client" type="text"
    (focus)="onFocusClientEvent()" placeholder="Type here to select a client" autocomplete="off"
   />
  <span class="highlight"></span>

  </div>

  <div class="settings-form-field pt-3">


      <label mdbLabel class="form-label" for="region">Select a region</label>
      <select id="region" class="form-select " aria-label="region" [(ngModel)]="campaignRegionModel">
        <option *ngFor="let o of regionResponseModel.value?.results" value="{{o.id}}">{{o.name}}</option>
      </select>



  </div>


  <div class="form-check form-switch pt-1 pe-3 pt-3">
    <label class="form-check-label map-text-header" for="manualSwitchbox">
      <span *ngIf="sendModeModel">Auto</span>
      <span *ngIf="!sendModeModel">Manual</span>
    </label>
    <input mdbCheckbox class="form-check-input campaign-status-checkbox" [checked]="false" [(ngModel)]="sendModeModel"
      type="checkbox" id="manualSwitchbox" />
  </div>
  <p *ngIf="sendModeModel" class="mt-2">Campaign gets auto fired as per the given sending window.</p>
  <p *ngIf="!sendModeModel" class="mt-2">Campaign can be fired manually.</p>






  <div class="pt-1"></div>
  <div class="row">
    <div class="col-9">
      <div class="settings-form-field">
        <input [(ngModel)]="selectedSendingWindow" (typeaheadOnSelect)="onSelect($event)"
          (typeaheadLoading)="changeTypeaheadLoading($event)" [typeaheadMinLength]="0" [typeahead]="sendingWindowslist"
          typeaheadOptionField="name" typeahead-wait-ms="100" class="form-control" name="SendingWindow" type="text"
          (focus)="onFocusEvent()" placeholder="Type here to select a sending window" autocomplete="off"
          [disabled]="!sendModeModel" />
        <span class="highlight"></span>
      </div>
    </div>

    <div class="col-3">
      <button type="button" [disabled]="!sendModeModel" class="btn btn-sm btn-link mt-1" (click)="addSendingWindow()">+
        Add new</button>

    </div>
  </div>

  <div class="pt-3"></div>

  <div class="form-check form-switch pt-1 pe-3">
    <label class="form-check-label map-text-header" for="manualEmailSwitchbox">
      <span *ngIf="sendEmailModeModel">Personal</span>
      <span *ngIf="!sendEmailModeModel">External</span>
    </label>
    <input mdbCheckbox class="form-check-input campaign-status-checkbox" [checked]="false" [(ngModel)]="sendEmailModeModel"
      type="checkbox" id="manualEmailSwitchbox" />
  </div>
  <p class="mt-2" *ngIf="sendEmailModeModel">
    Google or Microsoft email service would be used.
  </p>
  <p class="mt-2" *ngIf="!sendEmailModeModel">
    AWS or SendGrind email service would be used.
  </p>




  <div class="form-check form-switch pt-1 pe-3">
    <label class="form-check-label map-text-header" for="multiMailmoxes">
      <span>Additional Sender Accounts </span>
    </label>
    <input mdbCheckbox [disabled]="clientId == undefined" class="form-check-input campaign-status-checkbox" [checked]="false" [(ngModel)]="multiMailmoxesModelCheck"
      type="checkbox" id="multiMailmoxes" (change)="changesMailboxToggle($event)" />
  </div>
  <p class="mt-2" *ngIf="multiMailmoxesModelCheck">
    Emails will be sent from selected accounts (Select Upto 2 accounts).
  </p>
  <p class="mt-2" *ngIf="!multiMailmoxesModelCheck">
    Emails will be send from your account.
  </p>
    <div *ngIf="multiMailmoxesModelCheck" class="optiondropdown">
      <ng-select [(ngModel)]="campaignModel.senders" id="createdBy" class="header-imput w-100"
      (change)='onMailBoxSelected()' [maxSelectedItems]="2" placeholder="Select Additional mailboxes" [multiple]="true">
      <ng-option  [disabled]="!expiredChecker(o.mailbox_connection?.expires_on)" [Class]="o.mailbox_connection.expires_on <= 7 ? 'text-danger' : 'text-success' " *ngFor="let o of userList" [value]="o.id"><span> {{o.email?.length > 0 ? o.email: o.username}}</span>
        <span *ngIf="expiredChecker(o.mailbox_connection?.expires_on)" class="float-end  mt-1 ms-2" style="font-size:9px">Expires by {{ o.mailbox_connection.expires_on | date :'short' }}  </span>
        <span *ngIf="!expiredChecker(o.mailbox_connection?.expires_on)" class="float-end  mt-1 ms-2" style="font-size:9px">Expired  {{ o.mailbox_connection.expires_on | date :'short' }}  </span>
      </ng-option>
     </ng-select>

     <p class="small mt-2"> You can select soon expiring (<=7 days) or expired account after you have renewed them.</p>
    </div>






  <div class="errors mt-2">
    <p class="text-danger text-center m-0" *ngFor="let i of formErrors">{{i}}</p>
  </div>
</div>

<div class="pt-3"></div>
<div class="modal-footer">
  <button type="button" class="btn btn-link" (click)="modalRef.close()">
    Close
  </button>
  <button type="button" class="btn btn-primary" (click)="submit()">Create Campaign</button>
</div>
