<app-loading-dialog *ngIf="isLoading">

</app-loading-dialog>


<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Add a new step</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">

  <div class="row">

    <p *ngIf="span_words.length > 0"> Spam words: <span *ngFor="let i of span_words;" class="badge bg-danger ms-2">{{i}}</span>
    </p>

    <p *ngIf="wrongVariables.length > 0">Wrong Variables: <span *ngFor="let i of wrongVariables;" class="badge bg-danger ms-2">
      {{i}}</span>
    </p>


    <div class="col-8">

      <div class="">
      <label mdbLabel class="form-label" >Subject:
      <span>
          <span mdbDropdown class="dropdown me-4">
          <button class="dropdown-toggle veriable-button pb-0" id="mapSubjectItems" aria-expanded="false" mdbDropdownToggle> Variables</button>
          <ul mdbDropdownMenu class="dropdown-menu" aria-labelledby="mapSubjectItems" style="overflow-y: scroll; height: 250px;" >
            <li *ngFor="let variable of variables" (click)="addVariableinSubject(variable)">
              <span class="dropdown-item">{{variable}}</span></li>
          </ul>
        </span>
      </span></label>
        <!-- <mdb-form-control> -->
          <!-- <input [(ngModel)]="stepSubjectModel" mdbInput type="text" id="subject" class="form-control" autocomplete="off" /> -->
          <!-- <input [(ngModel)]="stepSubjectModel"
          (ngModelChange)="changeTypeaheadLoading($event)"
          class="form-control form-control-sm"
          placeholder="Search subjects"
          mdbInput type="text" id="subject" class="form-control" /> -->
        <!-- </mdb-form-control> -->

        <!-- <div class="col-12 d-flex p-0">
          <input #campaignType [(ngModel)]="stepSubjectModel" (typeaheadOnSelect)="onSelect($event)"
            (typeaheadLoading)="changeTypeaheadLoading($event)" [typeaheadMinLength]="0" [typeahead]="subjectList"
            typeaheadOptionField="name" typeahead-wait-ms="100" class="form-control form-control" name="Subject" type="text"
            (paste)="onPaste($event)" placeholder="Search Subject" autocomplete="off" />
          <span class="highlight"></span>
        </div> -->

        <!-- <div class="col-12 d-flex p-0">
          <input [(ngModel)]="stepSubjectModel"
            (ngModelChange)="changeTypeaheadLoading()"  (focus)="changeTypeaheadLoading()"
             class="form-control autosuggesttag" name="Subject" type="text" (blur)="hideIt()"
              placeholder="Search Subject" autocomplete="off" />
          <span class="highlight"></span>

        </div>
        <div class="col-12 d-flex p-0 position-relative" >
          <ul class="bg-light pl-0 w-100 list-unstyled position-absolute dropdownsuggest" *ngIf="subjectList.length > 0">
            <li class="w-100 d-flex justify-content-between border-bottom py-2" (click)="onSelect(sub)" *ngFor="let sub of subjectList"><span class=" px-2 d-inline-block">{{sub.subject}} </span>  <span class="px-2 d-inline-block small fst-italic" [ngClass]="{'text-success' : sub.open_rate > 5.0,'text-danger' : sub.open_rate < 5.0}">Open rate: {{sub.open_rate | number:'1.2-2'}}% </span> </li>
          </ul>
        </div> -->
        <app-subject-line-auto-suggest [getSubjectModel]="stepSubjectModel"  (stepSubEvent)="addItem($event)"> </app-subject-line-auto-suggest>
      </div>

      <div class="form-check form-switch mt-2">
        <input
          mdbCheckbox
          class="form-check-input"
          type="checkbox"
          id="flexSwitchCheckDefault"
          [(ngModel)]="enableTemplate"
          />
        <label class="form-check-label" for="flexSwitchCheckDefault"
          >Enable Template</label
        >
      </div>

    </div>


  <div class="col-4">
    <label mdbLabel class="form-label" for="sendingRate">Email delivery speed</label>
    <select class="form-control" [(ngModel)]="sendingRateModel" id="sendingRate"  placeholder="Sending Rate">
        <option *ngFor="let o of sendingRateList" [disabled]="this.campaignDetail.use_personal_account && o.id == 4" [value]="o.id">{{o.text}}</option>
    </select>
   
  </div>

  </div>

  <div class="col-12 mt-2">
    <div class="row" *ngIf="enableTemplate==false">
      <div class="text-danger fw-bold fst-italic text-end  mt-2"> <small> *Attention: Change font as per your need.</small></div> 
      <editor apiKey="{{editorAPIKey}}"  [init]="editormodules"
      (ngModelChange)="checkSpamAndVeriablesWords($event)" [(ngModel)]="emailBodyModel"></editor>
      <!-- (onChange)="checkSpamWords()" -->
    </div>
    <div class="row" *ngIf="enableTemplate==true">
      <!-- <mdb-tabs [vertical]="true" [navColClass]="'col-4'" [contentColClass]="'col-8'">
        <mdb-tab *ngFor="let o of templateList" title="{{o.name}}">
          {{o.body}}
        </mdb-tab>
      </mdb-tabs> -->

        <div class="col-3 fixed">
          <mdb-form-control class="mt-2 mb-2">
            <input mdbInput type="text" id="form1" [(ngModel)]="search_by_name" class="form-control form-control-sm"
                (keyup)="inputkeyUp()" (keydown)="inputKeyDown()" />
            <label mdbLabel class="form-label" for="form1">Search</label>
          </mdb-form-control>
          <!-- <div class="bg-white"> -->
          <div  class="template-items" infiniteScroll [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="10" (scrolled)="onTemplateScroll()" [scrollWindow]="false"
          #campaignContainer>

            <div class="list-group " >
              <a href="javascript:void(0)" (click)="mapTemplate(i)" *ngFor="let i of templatelistB4Search; let index=index; "
               [class]="i.id == selectedTemplate ? 'list-group-item list-group-item-action active ':
               'list-group-item list-group-item-action template-item'" aria-current="true">
               <i class="fas fa-file-image"></i> {{i.name.length > 17 ? (i.name|slice:0:17) + '...':i.name}}
              </a>
            </div>

          </div>

           <!-- <table class="table table-sm table-hover  table-striped  table-bordered">
              <thead>
                <tr>
                    <th class="text-center" scope="col">TEMPLATE NAME</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let i of templatelistB4Search">
                  <td class="text-center" (click)="mapTemplate(i)">{{i.name}}</td>
                </tr>
              </tbody>
            </table> -->

            <!-- <label for="templateModel" class="form-label">Template: </label>
            <textarea [(ngModel)]="templateModel.body" class="form-control w-60" id="templateModel" rows="15"
            value="{{templateModel.body}}"  name="html_content"> </textarea>  -->

        </div>

        <div class="col-9 border-start" style="background-color: #F3F3F3;">
            <iframe *ngIf="templateBody"
                height="100%" width="100%"  [srcdoc] = "templateBody | noSanitizeHtml"></iframe>
            <div class="preview" *ngIf="!templateBody">
              No Template selected
            </div>
        </div>
    </div>
  </div>

  <!-- <div class="col-12">
    <input type="file" class="attachment-input" (change)="onFileSelected($event)" #fileUpload (click)="$event.target.value=null">
       <div class="attachment-upload">
        <button type="button" class="btn btn-primary btn-floating btn-sm mt-2 me-2" (click)="fileUpload.click()">
          <i class="fas fa-paperclip"></i>
        </button>

        <span class="badge bg-light text-dark" >{{uploadedCampaignStepAttachment?.file_name || "No attachment added yet."}} </span>

        <span class="ms-2" *ngIf="attachmentUploadInProgress">
          <i class="fas fa-spinner fa-pulse"></i>
        </span>

        <span [class]="uploadedCampaignStepAttachment ? 'ms-2 attachment-removable ': 'ms-2 attachment-hidden'"
        (click)="removeAttachment()">
         Remove <i class="fas fa-trash text-danger"></i>
        </span>
     </div>
  </div> -->


<div class="row mt-2">

    <div class="col-6">
      <div class="form-check">
        <input mdbCheckbox class="form-check-input" type="checkbox" value="" id="flexCheckChecked1" [(ngModel)]="isSignature" [checked]="true"/>
        <label class="form-check-label" for="flexCheckChecked1">
          Include Signature in email body
        </label>
      </div>
    </div>

    <div class="col-6">
      <div class="form-check">
        <input mdbCheckbox class="form-check-input" type="checkbox" value="" id="flexCheckChecked2" [(ngModel)]="isOpenTracking" [checked]="true"/>
        <label class="form-check-label" for="flexCheckChecked2">
          Include opt-out statement in emails
        </label>
      </div>
    </div>


    <div class="col-6">
      <div class="form-check">
        <input mdbCheckbox class="form-check-input" type="checkbox" value="" id="flexCheckChecked3" [(ngModel)]="isOptOut" [checked]="true"/>
        <label class="form-check-label" for="flexCheckChecked3">
          Track whether an email has been opened
        </label>
      </div>
    </div>


    <div class="col-6">
      <div class="form-check">
        <input mdbCheckbox class="form-check-input" type="checkbox" value="" id="flexCheckChecked4" [(ngModel)]="isLinkTracking" [checked]="true"/>
        <label class="form-check-label" for="flexCheckChecked4">
          Track whether a link has been clicked
        </label>
      </div>
    </div>

    <div class="col-12" *ngIf="isLinkTracking">
      <p class="m-0 text-danger">Link tracking is strongly discouraged. With link tracking on, it's more likely that your emails will end up in spam.</p>
    </div>

</div>





  <div class="row" *ngIf="mandatoryError">
    <div class="col text-center mt-2">
      <label mdbLabel class="form-label" style="color: red;" for="error">{{mandatoryError}}</label>
    </div>
  </div>


</div>

  <div class=" p-2">
    <div class="row d-flex justify-content-between">
      <hr>
      <div class="col ps-3">
        <span *ngIf="savingDraftStatus" class="form-text text-muted ps-1">Saving draft...</span>
        <span (click)="removeDraft()" class="form-text text-muted ps-1 remove-draft">
          {{ showDraft &&  stepSubjectModel != '' &&  emailBodyModel != '' ? 'Remove Draft' : ''}}
        </span>
      </div>
      <div class="col d-flex justify-content-end pe-4">
        <button type="button" class="btn btn-link" (click)="modalRef.close()">
          Close
        </button>
        <button type="button" [disabled]="isLoading" (click)="createNewStep()" class="btn btn-primary">Create</button>
      </div>
    </div>

  </div>
  <!-- <div class="modal-footer">
</div> -->
