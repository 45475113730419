
  <div>
    <ul class="nav flex-column mb-auto">

      will be removed if only one page
      <!-- <li class="nav-item">
        <a href="#" class="text-muted">Campaigns Report</a>
      </li> -->
      <!-- <li class="nav-item">
        <a href="#" class="text-muted">Report Card</a>
      </li>
      <li class="nav-item">
        <a href="#" class="text-muted">Campaigns</a>
      </li> -->
    </ul>
  </div>
  <!-- <div style="margin-top: 25px;">
    <ul class="nav flex-column mb-auto">
      <li class="nav-item">
        <a href="#" class="text-muted">EMAILS</a>
      </li>
      <li class="nav-item">
        <a href="#" class="text-muted">Overview</a>
      </li>
      <li class="nav-item">
        <a href="#" class="text-muted">Best Time to Send</a>
      </li>
      <li class="nav-item">
        <a href="#" class="text-muted">Response Time</a>
      </li>
    </ul>
  </div> -->
 