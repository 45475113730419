

<div class="ms-sm-auto" style="width: 90%;">

    
<div class="row" style="height: 100%;">



    <div class="col-2 shadow-3" style="background: #FBF7FE; height: 100vh; overflow-x: hidden; overflow-y: auto; ">      

        <app-sidebar-settings></app-sidebar-settings>
    </div>
  
  
   
    <div class="col-5 p-2" style="height: 100vh; overflow-x: hidden; overflow-y: auto; ">

      <app-company-dnc></app-company-dnc> 
    
    </div>  


    <div class="col-5 p-2" style="height: 100vh; overflow-x: hidden; overflow-y: auto; ">

      <app-prospect-dnc></app-prospect-dnc>  
    
    </div>
  
  
  </div>
   

  </div>