<div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Add new client</h5>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modalRef.close()"
    ></button>
  </div>
  <div class="modal-body">

    <mdb-form-control>
      <input mdbInput [(ngModel)]="clientNameModel" type="text" id="form1" class="form-control" />
      <label mdbLabel class="form-label" for="form1">Client Name *</label>
    </mdb-form-control>
      
    
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-link" (click)="modalRef.close()">
      Close
    </button>
    <button (click)="addNewClient()" type="button" class="btn btn-primary">Save changes</button>
  </div>