<app-loading-dialog *ngIf="isLoading">

</app-loading-dialog>

<div class="row ">
  <div class="col-3"></div>

  <ng-container *ngIf="(processingItems == 0 && totalSechduleEmailSent == 0) else stepsProcessingStage">
    <div class="col-6" style="border-radius: 5px;">

      <div class="col-12 p-3 shadow-1 bg-danger position-sticky" style="top:0px;z-index: 999;" *ngIf="campaignCreatedBy !=  loggedInUser">
        <p class="p-2 m-0 text-light"> Campaign settings is disabled. Only original creator is allowed to modify the settings. </p>
      </div>

      <div class="col-12 bg-white p-3 shadow-1">

        <div class="settings-form-field mt-2">
          <label mdbLabel for="name">Camapign Name</label>
          <mdb-form-control>

            <input mdbInput type="text" id="name" name="name" [disabled]="campaignCreatedBy !=  loggedInUser" [(ngModel)]="campaignData.name"
              value="campaignData.name" class="form-control" />
          </mdb-form-control>
        </div>



        <!-- <div class="settings-form-field mt-2">
          <label mdbLabel for="DailyProspectsStart">Daily Send Limit</label>
          <mdb-form-control>
            <input mdbInput type="text" id="DailyProspectsStart" (keypress)="numberOnly($event)"
              [(ngModel)]="campaignDetails.send_limit_daily" value="campaignDetails.send_limit_daily" class="form-control"
              aria-describedby="DailyProspectsStartADB" />
          </mdb-form-control>
          <div id="DailyProspectsStartADB" class="form-text">
            The maximum number of prospects that will automatically be added to the first step of the campaign per day.
          </div>
        </div> -->
        <div class="settings-form-field mt-2">
          <label mdbLabel for="name">Client Name</label>
          <mdb-form-control>

            <input mdbInput type="text" id="clientName" name="clientName" [(ngModel)]="clientName"
              value="clientName" disabled class="form-control" />
          </mdb-form-control>
        </div>


        <!-- <div class="settings-form-field mt-2">
            <label mdbLabel class="form-label" for="region">Select a region</label>
            <select disabled id="region" class="form-select " aria-label="region" [(ngModel)]="regionModel">
              <option *ngFor="let o of regionResponseModel.value?.results" value="{{o.id}}">{{o.name}}</option>
            </select>

            {{regionModel}}
        </div> -->

        <div class="settings-form-field mt-3">
          <label mdbLabel class="form-label mb-0" for="region">Region</label>
         <ng-select [disabled]="true" [(ngModel)]="regionModel" id="region" placeholder="Select a region">
            <ng-option *ngFor="let s of regionResponseModel.value?.results" [value]="s.id">{{s.name}}</ng-option>
          </ng-select>
        </div>



        <div class="settings-form-field mt-3">
          <div class="form-check form-switch pt-1 pe-3">
            <label class="form-check-label map-text-header" for="manualSwitchbox">
              <span *ngIf="sendModeModel">Auto</span>
        <span *ngIf="!sendModeModel">Manual</span>
            </label>
            <input mdbCheckbox class="form-check-input campaign-status-checkbox"
            [checked]="false" [(ngModel)]="sendModeModel"
              type="checkbox" id="manualSwitchbox" [disabled]="disableManualSwitch || campaignCreatedBy !=  loggedInUser"/>
          </div>

          <p *ngIf="sendModeModel" class="mt-2">Campaign gets auto fired as per the given sending window.</p>
          <p *ngIf="!sendModeModel" class="mt-2">Campaign can be fired manually.</p>

          </div>

        <div class="settings-form-field mt-1">
          <label mdbLabel for="sw">Sending Window</label>
          <input [(ngModel)]="selectedSendingWindow" (typeaheadOnSelect)="onSelect($event)"
        (typeaheadLoading)="changeTypeaheadLoading($event)" [typeaheadMinLength]="0" [typeahead]="sendingWindowslist"
        typeaheadOptionField="name" typeahead-wait-ms="100" class="form-control" name="SendingWindow" type="text"
        placeholder="Select a sending window" autocomplete="off" [disabled]="disableSendingWindow || !sendModeModel || campaignCreatedBy !=  loggedInUser" />
      <span class="highlight"></span>
          <!-- <ng-select [(ngModel)]="campaignDetails.schedule" id="sw" placeholder="Select Sending Window">
            <ng-option *ngFor="let s of sendingWindowslist" [value]="s.id">{{s.name}}</ng-option>
          </ng-select> -->

          <div id="textExample1" class="form-text">
            A sending window is optional; it allows you to specify when emails can go out.
            <a href="/settings/sending-windows">View and create sending windows here.</a>
          </div>
        </div>
        <div class="pt-3"></div>

        <div class="form-check form-switch pt-1 pe-3">
          <label class="form-check-label map-text-header" for="manualEmailSwitchbox">
            <span *ngIf="sendEmailModeModel">Personal</span>
            <span *ngIf="!sendEmailModeModel">External</span>
          </label>
          <input mdbCheckbox class="form-check-input campaign-status-checkbox" [disabled]="campaignCreatedBy !=  loggedInUser" [checked]="false" [(ngModel)]="sendEmailModeModel"
            type="checkbox" id="manualEmailSwitchbox" />
        </div>
        <p class="mt-2" *ngIf="sendEmailModeModel">
          Google or Microsoft email service would be used.
        </p>
        <p class="mt-2" *ngIf="!sendEmailModeModel">
          AWS or SendGrind email service would be used.
        </p>


        <div class="form-check form-switch pt-1 pe-3">
          <label class="form-check-label map-text-header" for="multiMailmoxes">
            <span>Additional Sender Accounts </span>
          </label>
          <input mdbCheckbox [disabled]="true"  class="form-check-input campaign-status-checkbox" [checked]="false" [(ngModel)]="multiMailmoxesModelCheck"
            type="checkbox" id="multiMailmoxes" (change)="changesMailboxToggle($event)" />
        </div>
        <p class="mt-2" *ngIf="multiMailmoxesModelCheck">
          Emails will be sent from selected accounts (Select Upto 2 accounts).
        </p>
        <p class="mt-2" *ngIf="!multiMailmoxesModelCheck">
          Emails will be send from your account.
        </p>
          <div *ngIf="multiMailmoxesModelCheck" class="optiondropdown">
            <ng-select [(ngModel)]="campaignData.senders"  [maxSelectedItems]="2" [disabled]="multiMailmoxesModelCheck || campaignCreatedBy !=  loggedInUser" id="createdBy" class="header-imput w-100"
            (change)='onMailBoxSelected($event)'  placeholder="Select Multiple mailboxes" [multiple]="true">
            <ng-option *ngFor="let o of userList" [disabled]="!expiredChecker(o.mailbox_connection?.expires_on)"  [value]="o.id">{{o.email?.length > 0 ? o.email: o.username}}
              <span *ngIf="expiredChecker(o.mailbox_connection?.expires_on)" class="float-end  mt-1 ms-2" style="font-size:9px">Expires by  {{  o.mailbox_connection.expires_on | date :'short' }} </span>
        <span *ngIf="!expiredChecker(o.mailbox_connection?.expires_on)" class="float-end  mt-1 ms-2" style="font-size:9px">Expired  {{  o.mailbox_connection.expires_on | date :'short' }}  </span>
            </ng-option>
           </ng-select>
           <p class="small mt-2"> You can select soon expiring (<=7 days) or expired account after you have renewed them</p>
          </div>



          <div class="errors mt-2">
            <p class="text-danger text-center m-0" *ngFor="let i of formErrors">{{i}}</p>
          </div>


        <div class="settings-form-field mt-3">
          <div class="d-flex flex-row">
            <button type="button" class="btn btn-primary btn-sm" [disabled]="campaignCreatedBy !=  loggedInUser" (click)="update()">SAVE</button>
            <!-- <a href="#" style="margin-top: 0.5rem; margin-left: 0.5rem;">Discard changes</a> -->
          </div>
        </div>

      </div>



      <div class="col-12 mt-3 bg-white p-3 shadow-1">
        <p style="font-size: 1.3rem; ">Duplicate</p>
        <p class="text-muted">
          A new campaign will be created with the same steps.
        </p>
        <button class="btn btn-outline-primary btn-sm" style="margin-top: 10px;"
          (click)="confirmDuplicateCampaign()">
          <span><i class="far fa-copy"></i></span>
          <span style="margin-left: 6px;">CREATE DUPLICATE</span>
        </button>
      </div>


      <div class="col-12 mt-3 bg-white p-3 shadow-1">
        <p style="font-size: 1.3rem; ">Delete Campaign</p>
        <p class="text-muted">
          If you delete this campaign, all emails that have not been sent will be cancelled. <br /> All stats related this
          campaign will be lost.<br />
          You can delete a campaign only if it's in <strong>"New"</strong> state
        </p>
        <button class="btn btn-danger btn-sm" (click)="confirmDeleteCampaign()" [disabled]="campaignData.campaign_step_status !== 'New' || campaignCreatedBy !=  loggedInUser">
          <span><i class="far fa-trash-alt"></i></span>
          <span style="margin-left: 6px;">DELETE</span>
        </button>
      </div>


    </div>
  </ng-container>

  <ng-template #stepsProcessingStage>
    <div class="col-6" style="border-radius: 5px;">
      <div class="col-12 bg-white p-3 shadow-1">
        <h3 class="text-center">Sorry!</h3>
        <h6 class="text-center">You can't modify campaign settings until if there is any step under processing or paused.</h6>
      </div>
    </div>
  </ng-template>



  <div class="col-3"></div>
</div>

<div class="row mt-5">
  <div class="col-12 mt-5"></div>
  <div class="col-12 mt-5"></div>
  <div class="col-12 mt-5"></div>
  <div class="col-12 mt-5"></div>
</div>
