<div class="modal-header">
    <h5 class="modal-title" id="addDomainDnc">Add Domain DNC</h5>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modalRef.close()"
    ></button>
  </div>
  <div class="modal-body">
    
    <mdb-form-control>
      <input mdbInput type="text" id="form1" class="form-control" [(ngModel)]="emailDomainModel" value="emailDomainModel"/>
      <label mdbLabel class="form-label" for="form1">Email Domain</label>
    </mdb-form-control>

    <div class="pt-3"></div>

    <mdb-form-control>
      <textarea mdbInput class="form-control" id="textAreaExample" [(ngModel)]="reasonForDncModel" value="reasonForDncModel" rows="4"></textarea>
      <label mdbLabel class="form-label" for="textAreaExample">Reason For DNC</label>
    </mdb-form-control>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-link" (click)="modalRef.close()">
      Close
    </button>
    <button type="button" (click)="createDomainDnc()" class="btn btn-primary">Add to DNC</button>
  </div>