export class ProspectGroupModel {
    "id": number
    "name": string
    "slug": string
    "created_at": Date
    "import_file_url": string
    "cnt_total": number
    "cnt_duplicate": number
    "cnt_dnc": number
    "is_deleted": boolean
    "created_by": number
}

export class ProspectGroupResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [ProspectGroupModel]
}

export class ProspectStatusResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [ProspectStatusModel]
}

export class ProspectStatusModel {
    "id": number
    "name": string
    "slug": string
    "created_at": Date
    "modified_at": Date
    "description": string
    "is_deleted": boolean
    "created_by": number
    "modified_by": number
}


export class UserExtraModel {
    "id": number
    "email_address": string    
    "email_sender_name": string
    "slug": string    
    "created_at": Date
    "modified_at": Date
    "send_limit_daily": number
    "email_signature": string
    "opt_out_message": string
    "is_tracking_enabled": boolean
    "is_link_tracking_enabled": boolean
    "is_opt_out_enabled": boolean
    "is_signature_enabled": boolean
    "is_deleted": boolean
    "created_by": number
    "modified_by": number
    "user": number 
}

export class SendingWindowsModel {
    "id": number
    "name": string
    "modified_at": string
    "sunday_start_at"= []
    "sunday_end_at"= []
    "monday_start_at"= []
    "monday_end_at"= []
    "tuesday_start_at"= []
    "tuesday_end_at"= []
    "wednesday_start_at"= []
    "wednesday_end_at"= []
    "thursday_start_at"= []
    "thursday_end_at"= []
    "friday_start_at"= []
    "friday_end_at"= []
    "saturday_start_at"= []
    "saturday_end_at"= []
    "created_by": number
    "timezone": number
  }

export class SendingWindowsResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [SendingWindowsModel]
}


export class CompanyDncModel {
    "id": number
    "email_domain": string
    "created_at": string    
    "modified_at": Date
    "dnc_reason": string
    "created_by": number    
    "modified_by": number
}

export class CompanyDncResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [CompanyDncModel]
}

export class ProspectDncModel {
    "id": number
    "email_address": string
    "modified_at": Date
    "dnc_reason": string
    "created_by": number
    "created_at": string
    "client": number
    "modified_by": number
}

export class ProspectDncResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [ProspectDncModel]
}


export class ScheduleTimezoneModel {
    "id": number
    "name": string
    "slug": string
    "created_at": string
    "gmt_offset_minute": number
    "is_behind_gmt": boolean
    "is_deleted": boolean
    "created_by": number
}

export class ScheduleTimezoneResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [ScheduleTimezoneModel]
}


export class ClientsModel {
    "id": number
    "name": string
    "slug": string
    "created_at": Date
    "modified_at": Date
    "cnt_campaign": number
    "is_deleted": string
    "created_by": number
    "modified_by": number
} 


export class  ClientsResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [ClientsModel]
} 

export class EmailSenderModel {
    "id": number
    "email_address": string
    "slug": string
    "created_at": Date
    "modified_at": boolean
    "provider": string
    "host": string
    "send_limit_daily": number
    "send_limit_used": number
    "is_deleted": boolean
    "created_by": number
    "modified_by": number
}

export class EmailSenderResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [EmailSenderModel]
}

