<!-- 


<div class="ms-sm-auto" style="width: 90%;">
  
<div class="row sticky-top" style="z-index: 2;">
  <div class="col-12 shadow-1 p-3 bg-white">
    <div class="row">
      
      <div class="col-md-4">
        <h4 class="map-text-header">Clients</h4> 
    </div>
  
    <div class="col-md-8">
      <div class="d-flex justify-content-end">  

        <form #searchForm="ngForm">
        <mdb-form-control class="me-3">
          <input mdbInput type="text" id="clientSearch" class="form-control"
          (input)="searchSubmit($event)"
           [ngModel]="searchValue" name="search" />
          <label mdbLabel class="form-label" for="clientSearch"><i class="fas fa-search"></i> Search</label>
        </mdb-form-control>
        </form>

        <button (click)="addClient()" type="button" class="btn btn-primary btn-sm"> <i class="fas fa-plus"></i> Add Client</button> 
      </div> 
    </div>

    </div>
    
  </div> 
</div>
 


<div class="row" style="height: 100vh; overflow-x: hidden; overflow-y: auto; background-color: #FBF7FE;">  
  

  <div class="d-flex align-content-start flex-wrap">

    <div class="col-3 p-2 " *ngFor="let i of clientsList">
      <div class="card">
        <div class="card-body"> 
          <h5 class="card-title">{{i.name}}</h5> 
          <div class="d-flex">
            <div class="me-auto">
              <h1>  
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none" 
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather">
                <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon><polyline points="2 17 12 22 22 17"></polyline>
                <polyline points="2 12 12 17 22 12"></polyline></svg>
      
                {{i.cnt_campaign}}
                
                </h1>
            </div>
            <div class="pt-1">
              <h3 class="clientEditLink" (click)="deleteClient(i.id, i.name, i.created_by)"><i class="far fa-trash-alt"></i></h3>
            </div>
            <div class="ps-3 pt-1">
              <h3 class="clientEditLink" (click)="editClient(i)"><i class="far fa-edit"></i></h3>
            </div>
          </div>
          
       
          <p class="card-text">
            {{i.created_at | date:'medium'}}
          </p>
        </div>
      </div>
    </div>


  </div>



  <div class="mt-5"></div>
  <div class="mt-5"></div> 

</div>

</div> -->


<div class="ms-sm-auto" style="width: 90%;">




  <div class="row" style="height: 100%;">

    <div class="col-2 shadow-3 " style="background: #FBF7FE; height: 100vh; overflow-x:hidden; 
   overflow-y: auto; z-index: 1;">

   
      <form #searchForm="ngForm">
        <mdb-form-control class="mt-3">
          <input mdbInput type="text" id="clientSearch" class="form-control"
          (input)="searchSubmit($event)"
          [ngModel]="searchValue" name="search" />
          <label mdbLabel class="form-label" for="clientSearch"><i class="fas fa-search"></i> Search</label>
        </mdb-form-control>
        </form>


        <div class="pt-3"></div>

        <ng-select [(ngModel)]="createdById" id="createdBy" (change)='onCreatedBySelected()' placeholder="Created By">
          <ng-option *ngFor="let o of userList" [value]="o.id">{{o.first_name}} {{o.last_name}}</ng-option>
        </ng-select>
    
    </div>




    <div class="col-10 m-0" style="height: 100vh; overflow-x: hidden; overflow-y: auto; ">


      <div class="row shadow-1 p-3 sticky-top bg-white" style="z-index: 2;">
        <div class="col-md-4">
          <h4 class="map-text-header">Clients <span
              style="font-size: 12px; color: #ef6c00;">({{totalClientRecieved}} found)</span></h4>
        </div>

        <div class="col-md-8">
          <div class="d-flex justify-content-end">

            
        <!-- <button (click)="addClient()" type="button" class="btn btn-primary btn-sm"> <i class="fas fa-plus"></i> Add Client</button>  -->
     
          </div>
        </div>
      </div>


      <div class="row">

        <!-- <hr> -->

        <div class="col-12">

          <div #clientContainer class="table-responsive" infiniteScroll [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="200" (scrolled)="onClientScroll()" [scrollWindow]="false">
            <table class="table table-sm table-hover table-bordered">
              <thead>
                <tr>
                  <th scope="col" class="text-center">
                    #
                  </th>
                  <th scope="col" >Name</th>
                  <th scope="col" class="text-center">Campaigns</th>
                  <th scope="col">Created by</th>
                  <th scope="col">Created at</th>
                  <th scope="col" class="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>

                <tr *ngFor="let i of clientsList; let count=index;">
                  <td class="text-center" scope="row">{{count+1}}</td>
                  <td class="client-name-item"><strong>{{i.name}}</strong></td>
                  <td (click)="gotoCampaignPage(i)" class="campaign-filter-number text-center">{{i.cnt_campaign}}
                    <i class="fas fa-eye"></i>

                  </td>
                  <td>{{getUserName(i.created_by)}}</td>
                  <td>{{i.created_at | date: 'medium'}}</td>
                  <td class="text-center">
                    <span class="pe-3"><a (click)="filterOnReport(i.id)"><i class="far fa-chart-bar clientActionItem"></i></a></span>
                    <!-- <span class="pe-3"><a (click)="deleteClient(i.id, i.name, i.created_by)"><i class="far fa-trash-alt clientActionItem"></i></a></span>
                    <span><a (click)="editClient(i)"><i class="far fa-edit clientActionItem"></i></a></span> -->
                  </td>
                </tr>

              </tbody>
            </table>


          </div>  
           

        </div>


      </div>


    </div>