import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { CampaignStepModel } from "../../campaign-model/campaign-model";
import { MapAPIService } from "src/app/app-providers/services/map-module";
import { BehaviorSubject } from 'rxjs';
import { CampaignViewModel } from '../../campaign-view-model/campaign-view-model';
import { MapCommon } from 'src/app/app-providers/map-base/MapCommon';
import { AlertNotificationsComponent } from 'src/app/Alerts/alert-notifications/alert-notifications.component';
import { VariableService } from '../../subject-line-auto-suggest/variable.service';
@Component({
  selector: 'app-step-ab-test',
  templateUrl: './step-ab-test.component.html',
  styleUrls: ['./step-ab-test.component.scss'],
  providers: [MapAPIService, CampaignViewModel],
})


export class StepAbTestComponent implements OnInit {
  currentUser: number;
  editormodules: any;
  editorAPIKey: string;
  ABStepCreateItems
  // ab_variant_bodyModel: string = '';
  // ab_variant_subjectModel: string = '';
  // ab_variant_TypeModel: string = '';
  mandatoryError: string;
  StepObject: any = [];
  campaignId: number;
  campaignStepModel: CampaignStepModel;
  public fieldValues: Array<any> = [];
  newAttribute: any = {};
  ab_variants = [];
  is_followStep: boolean = false;
  isLoading: boolean = false;
  variables = MapCommon.getAvailableEmailVariables();


  tempList = []

  isOpenTracking: boolean = true;
  isSignature: boolean = true;
  isOptOut: boolean = true;
  isLinkTracking: boolean = false;
  templateBody: string = "";

  search_by_name: string;
  search_by_active: string;
  typingTimer;                //timer identifier
  doneTypingInterval = 1000;
  page_index: number = 1;
  templateList = {};
  templatelistB4Search = [];
  subjectList = [];




  constructor(public modalRef: MdbModalRef<StepAbTestComponent>, private vm: CampaignViewModel,private dynamicVariableservice:VariableService) {
    this.editormodules = MapCommon.getCampaignEditorModule();
    this.editorAPIKey = MapCommon.getTinyEditorAPIKey();
    this.fieldValues = [{
      stepSubjectModel: null,
      emailBodyModel: null,
      mandatoryError: null,
      enableTemplate: null,
      subjectList:null
    }]
    this.vm.parentStepAbTestComponent = this;
    this.vm.getTemplates(this.search_by_name, this.page_index, 'abstep-campaignstep');
  }

  ngOnInit(): void {
    console.log(this.ABStepCreateItems)
    if(this.ABStepCreateItems){
      this.fieldValues = this.ABStepCreateItems
    }
    if (this.StepObject) {
      this.campaignStepModel = this.StepObject;

      var followStep = this.StepObject.followup_from;
      if (!followStep == null || followStep > 0) {
        this.is_followStep = true;
      }

      var items = this.StepObject.ab_variants;

      if (items.length > 0) {
        var fieldValueSet = []
        items.forEach(element => {
          fieldValueSet.push({ "stepSubjectModel": element.subject, "emailBodyModel": element.body, mandatoryError: null ,enableTemplate:element.body.includes('<!DOCTYPE html>'), subjectList:[]});
        });
        this.fieldValues = fieldValueSet;
      }
    }
  }

  returnVal(id:number){
    return 'subject-'+id
  }

  addItem(event:any, count:any){
    this.fieldValues[count].stepSubjectModel = event
  }

  addVariableinSubject(variable: string, fieldValue: any){
    this.dynamicVariableservice.setDynamicVariable(variable)
    const index = this.fieldValues.indexOf(fieldValue)
    this.fieldValues[index].stepSubjectModel = (this.fieldValues[index].stepSubjectModel) ? (this.fieldValues[index].stepSubjectModel + variable) : (variable)
    document.getElementById("mapSubjectItems").click()
    console.log( this.fieldValues)

  }



  getTemplateList(event){
    this.templateList = event
    if (this.templatelistB4Search.length == 0)
      this.templatelistB4Search = event.results;
    else{
      this.tempList = event.results
      for (var j = 0; j < this.tempList.length; j++) {
        // var index = this.prospectlistB4Search.findIndex(x => x.id == this.prospectlist[j].id);
        this.templatelistB4Search.push(event.results[j]);
      }
    }
  }

  filterData() {
    this.page_index = 1;
    this.templatelistB4Search = [];
    this.vm.getTemplates(this.search_by_name, this.page_index, 'abstep-campaignstep');
  }

  //on keyup, start the countdown
  inputkeyUp() {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(this.filterData.bind(this), this.doneTypingInterval);
  };

  //on keydown, clear the countdown
  inputKeyDown() {
    clearTimeout(this.typingTimer);
  };


  mapTemplate(element, index){
    this.fieldValues[index].emailBodyModel = element.body;
  }


  onTemplateScroll(){
    if (this.templateList['next']) {
      this.page_index++;
      this.vm.getTemplates(this.search_by_name, this.page_index, 'abstep-campaignstep');
    }
  }



  removeLoading(){
    this.isLoading = false;
  }

  closePopupAfterUpdate() {
    this.isLoading = false;
    this.modalRef.close(true);
  }

  updateStep() {
    // let fireUp = false
    let issueOccur = false;
    var abVariant = this.fieldValues;

    var subjectTestList = []


    for (var i = 0; i < abVariant.length; i++) {

      // Main error value
      var field_error = ""; 

      // check error exists or not 
      function _error(){
        if (field_error.length > 0){
          return true;
        } else {
          return false;
        }
      }

      function is_empty(str){
        if(str === undefined || str === null || str === ''){
          return true;
        } else {
          return false;
        }
      }

      // Check for empty subject  (Subject)
      if(!_error() && is_empty(abVariant[i].stepSubjectModel)){
          field_error = "Subject line can't be empty";
      }

      // Validate with main step's subject (Subject)
      if(!_error() && (abVariant[i].stepSubjectModel.trim() == this.campaignStepModel.subject.trim())){
        field_error = "Subject line can't be same as main step's subject"
      }

      // Check for same subjects in abs (Subject)
      if(!_error() &&  subjectTestList.includes(abVariant[i].stepSubjectModel.trim())){
          field_error = "Subject lines should be different";
      }
 

      // if( abVariant[i].stepSubjectModel !== undefined  ){
      //   if( abVariant[i].stepSubjectModel !== null){
      //     if( abVariant[i].stepSubjectModel !== ''  ){
      //       if (subjectTestList.includes(abVariant[i].stepSubjectModel.trim())) {
      //         this.fieldValues[i].mandatoryError = "Subject lines should be different";
      //         issueOccur = true;

      //       } else {
      //         this.fieldValues[i].mandatoryError = "";
      //         subjectTestList.push(abVariant[i].stepSubjectModel.trim())
      //       }
      //     } else {
      //       issueOccur = true;
      //       this.fieldValues[i].mandatoryError = "Subject line can't be empty";
      //     }
      //   }else {
      //     issueOccur = true;
      //     this.fieldValues[i].mandatoryError = "Subject line can't be empty";
      //   }

      // }else {
      //   issueOccur = true;
      //   this.fieldValues[i].mandatoryError = "Subject line can't be empty";
      // }



      // Check for empty body in abs (Body)
      if(!_error() && is_empty(abVariant[i].stepSubjectModel)){
          field_error = "Body can't be empty";
      }

      
      // Check for empty body in abs also check template link validation (Body) 
      if(!_error() && (abVariant[i].emailBodyModel.split("</a>").length - 2 >= 2)){
        if(!abVariant[i].enableTemplate){
          field_error = "Body cannot have more than two links.";
        }
          
      }



      // if(abVariant[i].emailBodyModel !== undefined  ){
      //   if(abVariant[i].emailBodyModel !== null  ){
      //     if(abVariant[i].emailBodyModel !== ''){
      //       if (abVariant[i].emailBodyModel.split("</a>").length - 2 >= 2) {

      //         if(abVariant[i].enableTemplate){
      //           fireUp = true
      //         } else {
      //           this.fieldValues[i].mandatoryError = "Body cannot have more than two links."
      //         }
      //         issueOccur = true;
      //       }
      //     }else{
      //       issueOccur = true;
      //       this.fieldValues[i].mandatoryError =  "Body can't be empty"
      //     }


      //   } else{
      //     issueOccur = true;
      //     this.fieldValues[i].mandatoryError =  "Body can't be empty"
      //   }
      // }else{
      //   issueOccur = true;
      //   this.fieldValues[i].mandatoryError =  "Body can't be empty"
      // }

      // if ( (abVariant[i].emailBodyModel === undefined || abVariant[i].emailBodyModel === null ||
      //   abVariant[i].emailBodyModel === '') &&( abVariant[i].stepSubjectModel === undefined ||
      //   abVariant[i].stepSubjectModel === null || abVariant[i].stepSubjectModel === '')) {
      //   this.fieldValues[i].mandatoryError = "Subject or Body can't be empty";
      //   issueOccur = true;
      // }


      // Final error checkup or push to subject for for other validation
      if(_error()){
        issueOccur = true;
        this.fieldValues[i].mandatoryError = field_error;
      } else {
        subjectTestList.push(abVariant[i].stepSubjectModel.trim());
      }


    }

    // if (fireUp) {
    //   this.updateCampaignStep();
    // }

    if (!issueOccur) {
      this.updateCampaignStep();
    }
  }


  updateCampaignStep(){

    var abVariant = this.fieldValues;
    this.isLoading = true;
    abVariant.forEach(element => {
      this.ab_variants.push({ "subject": element.stepSubjectModel, "body": element.emailBodyModel });
    });
    this.campaignStepModel.ab_variants = this.ab_variants;
    // this.campaignStepModel.ab_variant_body = this.ab_variants[0].body.replace("<p>", "").replace("</p>", "").replace("<br />", "");
    // this.campaignStepModel.ab_variant_subject = this.ab_variants[0].subject;
    this.vm.parentStepAbTestComponent = this;

    // As per subhajit for rejected item validation
    if(this.campaignStepModel.status == 'Rejected'){
      this.campaignStepModel.status = 'New';
    }

    this.vm.updateCampaignStep(this.campaignStepModel, 'ab-test');
  }

  addFieldValue(e) {
    if (this.is_followStep){
      AlertNotificationsComponent.alertCall.showAlert("Can't add a new variant on follow-up step", 'danger');
    } else {
      e.stopPropagation();
      this.fieldValues.push(this.newAttribute);
      this.newAttribute = {};
    }

  }


  deleteFieldValue(e, index) {
    e.stopPropagation();
    this.fieldValues.splice(index, 1);
  }



}
