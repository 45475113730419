import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'app-show-import-stats',
  templateUrl: './show-import-stats.component.html',
  styleUrls: ['./show-import-stats.component.scss']
})
export class ShowImportStatsComponent implements OnInit {
  
  stats: any

  constructor(public modalRef: MdbModalRef<ShowImportStatsComponent>) { }

  ngOnInit(): void {
    localStorage.removeItem('prospectImportStats');
  }

}
