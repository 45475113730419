import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observer } from "rxjs";
import { AlertNotificationsComponent } from "src/app/Alerts/alert-notifications/alert-notifications.component";
import { MapAPICollection } from "src/app/app-providers/services/map-api-collection";
import { IApiServiceDelegate } from "src/app/app-providers/services/map-api-IApiServiceDelegate";
import { MapAPIService } from "src/app/app-providers/services/map-api-service";
import { DncClientEditComponent } from "../dnc-client/dnc-client-edit/dnc-client-edit.component";
import { DncClientComponent } from "../dnc-client/dnc-client.component";
import { DncDomainAddComponent } from "../dnc-domain/dnc-domain-add/dnc-domain-add.component";
import { DncDomainEditComponent } from "../dnc-domain/dnc-domain-edit/dnc-domain-edit.component";
import { DncDomainComponent } from "../dnc-domain/dnc-domain.component";
import { DncGlobalEditComponent } from "../dnc-global/dnc-global-edit/dnc-global-edit.component";
import { DncGlobalComponent } from "../dnc-global/dnc-global.component";
import { ImportDncComponent } from "../import-dnc/import-dnc.component";
import { ClientDncModel, ClientDncResponseModel, ClientsResponseModel, 
    DomainDncModel, DomainDncResponseModel, GlobalDncResponseModel, ImportDncResponseModel } from "./dnc-model";


@Injectable({
    providedIn: "root",
})

export class DncViewModel {

    parentDncDomainComponent: DncDomainComponent;
    parentDncDomainEditComponent: DncDomainEditComponent;
    parentDncDomainAddComponent: DncDomainAddComponent;

    parentDncClientComponent: DncClientComponent;
    parentDncClientEditComponent: DncClientEditComponent;

    parentImportDncComponent: ImportDncComponent;
    
    parentDncGlobalComponent: DncGlobalComponent;
    parentDncGlobalEditComponent: DncGlobalEditComponent;

    clientsResponseModel = new BehaviorSubject<ClientsResponseModel>(
        new ClientsResponseModel()
    );

    importDncResponseModel = new BehaviorSubject<ImportDncResponseModel>(
        new ImportDncResponseModel()
    );

    domainDncResponseModel = new BehaviorSubject<DomainDncResponseModel>(
        new DomainDncResponseModel()
    )

    clientDncResponseModel = new BehaviorSubject<ClientDncResponseModel>(
        new ClientDncResponseModel()
    )

    globalDncResponseModel = new BehaviorSubject<GlobalDncResponseModel>(
        new GlobalDncResponseModel()
    )


    constructor(private apiService: MapAPIService) { }

    subscribeToImportVariable( observerImportDncResponseModel: Observer<ImportDncResponseModel>   ){
        this.importDncResponseModel.subscribe(observerImportDncResponseModel)
    }

    subscribeClientsVariable(
        observerClientsResponseModel: Observer<ClientsResponseModel>,
           
        ) {
        
        this.clientsResponseModel.subscribe(observerClientsResponseModel);
       
    }

    subscribeDomainDncVariable(
        observerDomainDncResponseModel: Observer<DomainDncResponseModel>,) {
        this.domainDncResponseModel.subscribe(observerDomainDncResponseModel)
    }

    subscribeClientDncVariable(
        observerClientDncResponseModel: Observer<ClientDncResponseModel>,) {
        this.clientDncResponseModel.subscribe(observerClientDncResponseModel)
    }


    subscribeGlobalDncVariable(
        observerGlobalDncResponseModel: Observer<GlobalDncResponseModel>,) {
        this.globalDncResponseModel.subscribe(observerGlobalDncResponseModel)
    }


    searchClientDnc(search: string, client: any, dncType: any) {
        var str = "?client__id__isnull=false";
        if(client){
            str += "&client__id=" + client
        }
        if(search.length > 0){
            str += "&email_address__icontains=" + search
        }
        if(dncType){
            str += "&type=" + dncType
        }

        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_PROSPECT_DNC + str,
            Success: (e: any) => {
                this.clientDncResponseModel.next(e);
                this.parentDncClientComponent.clientDncReceived();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while search', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    searchGlobalDnc(search: string) {
        var str = "?client__id__isnull=true&email_address__icontains=" + search
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_PROSPECT_DNC + str,
            Success: (e: any) => {
                this.globalDncResponseModel.next(e);
                this.parentDncGlobalComponent.globalDncReceived();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while search', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    getAllGlobalDnc(page) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_PROSPECT_DNC + "?client__id__isnull=true&page=" + page + "&page_size=30",
            Success: (e: any) => {
                this.globalDncResponseModel.next(e);
                this.parentDncGlobalComponent.globalDncReceived();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading clients', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    getAllClientDnc(page) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_PROSPECT_DNC + "?client__id__isnull=false&page=" + page + "&page_size=30",
            Success: (e: any) => {
                this.clientDncResponseModel.next(e);
                this.parentDncClientComponent.clientDncReceived();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading clients', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }


    importDnc(file, currentUser, selectedType, selectedClient){
        var formData = new FormData();
        var httpHeaders = new HttpHeaders();
        var uploadContentType = { type: 'multipart/form-data' }
        formData.set('import_file_url', new Blob([file], uploadContentType), file.name);
        formData.set('created_by', currentUser);

        if(selectedType == 1){
            formData.set('client', selectedClient);
        }     


        httpHeaders.append('Accept', 'application/x-www-form-urlencoded');
        this.apiService.uploadFile(String(MapAPICollection.IMPORT_DNC), formData,  httpHeaders).subscribe((e: any) => { 
            this.importDncResponseModel.next(e.res)
            this.parentImportDncComponent.clearAfterUpload(true)            
            AlertNotificationsComponent.alertCall.showAlert('DNC imported successfully', 'primary');
        },
        (error: any) => {
            this.parentImportDncComponent.clearAfterUpload(false)
            AlertNotificationsComponent.alertCall.showAlert(error, 'danger');
        }
        );
        
    }

    getAllClients(caller) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_CLIENTS + '?page=1&page_size=200',
            Success: (e: any) => {
                this.clientsResponseModel.next(e);
                if(caller === 'import-dnc'){
                    this.parentImportDncComponent.clientsReceived();
                }
                if(caller === 'dnc-client'){
                    this.parentDncClientComponent.clientsReceived();
                }
               
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading clients', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    getAllDomainDnc(page) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_COMPANY_DNC + "?page=" + page + "&page_size=30",
            Success: (e: any) => {
                this.domainDncResponseModel.next(e);
                this.parentDncDomainComponent.domainDncReceived();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading domain dnc', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }



    searchDomainDnc(search: string) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_COMPANY_DNC + '?email_domain__icontains=' + search,
            Success: (e: any) => {
                this.domainDncResponseModel.next(e);
                this.parentDncDomainComponent.domainDncReceived();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while search', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    addDomainDNC(emailDomain: string, reasonForDnc: string, currentUser: number) {
        let d: IApiServiceDelegate = {
            Data: { "email_domain": emailDomain, "dnc_reason": reasonForDnc, "created_by": currentUser },
            Method: "POST",
            Action: MapAPICollection.POST_COMPANY_DNC,
            Success: (e: any) => {
                // this.getAllDomainDnc(1);
                this.parentDncDomainAddComponent.addSuccess();
                AlertNotificationsComponent.alertCall.showAlert((emailDomain + ' added on domain DNC'), 'primary');
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while adding domain DNC', 'danger');
                return null;
            },
        };
        this.apiService.callService(d);
    }

    updateClientDnc(clientDncModel: ClientDncModel, caller) {
        let d: IApiServiceDelegate = {
            Data: clientDncModel,
            Method: "PUT",
            Action: MapAPICollection.PUT_PROSPECT_DNC + '/' + clientDncModel.id.toString(),
            Success: (e: any) => {
                if (caller === 'client'){
                    this.parentDncClientEditComponent.updateSuccess();
                }
                if (caller === 'global'){
                    this.parentDncGlobalEditComponent.updateSuccess();
                }
                    
                AlertNotificationsComponent.alertCall.showAlert((clientDncModel.email_address + ' updated'), 'primary');
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while adding Company DNC', 'danger');
                return null;
            },
        };
        this.apiService.callService(d);
    }

    deleteClientDncDnc(clientDncModel: ClientDncModel, caller) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "DELETE",
            Action: MapAPICollection.DELETE_PROSPECT_DNC + '/' + clientDncModel.id.toString(),
            Success: (e: any) => {
                if (caller === 'client'){
                    this.parentDncClientEditComponent.deleteSuccess();
                }
                if (caller === 'global'){
                    this.parentDncGlobalEditComponent.deleteSuccess();
                }
                AlertNotificationsComponent.alertCall.showAlert((clientDncModel.email_address + ' removed'), 'primary');
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert("Can't remove prospect DNC", 'danger');
                return null;
            },
        };
        this.apiService.callService(d);
    }

    updateDomainDnc(domainDncModel: DomainDncModel) {
        let d: IApiServiceDelegate = {
            Data: domainDncModel,
            Method: "PATCH",
            Action: MapAPICollection.PUT_COMPANY_DNC + '/' + domainDncModel.id.toString(),
            Success: (e: any) => {
                // this.getAllDomainDnc(1);
                this.parentDncDomainEditComponent.updateSuccess();
                AlertNotificationsComponent.alertCall.showAlert((domainDncModel.email_domain + ' updated'), 'primary');
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while updating domain DNC', 'danger');
                return null;
            },
        };
        this.apiService.callService(d);
    }

    deleteDomainDnc(domainDncModel: DomainDncModel) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "DELETE",
            Action: MapAPICollection.DELETE_COMPANY_DNC + '/' + domainDncModel.id.toString(),
            Success: (e: any) => {
                // this.getAllDomainDnc(1);
                this.parentDncDomainEditComponent.deleteSuccess();
                AlertNotificationsComponent.alertCall.showAlert((domainDncModel.email_domain + ' removed'), 'primary');
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert("Can't remove domain DNC", 'danger');
                return null;
            },
        };
        this.apiService.callService(d);
    }

    exportGlobalDNC(search, includedItems, excludedItems){
        var str = "?client__id__isnull=true"
        if(search){
            str += "&email_domain__icontains=" + search
        }
        if(includedItems.length > 0){
            str += '&id__inc=' + includedItems.join(',')
        }

        if(excludedItems.length > 0){
            str += '&id__exc=' + excludedItems.join(',')
        }
        var completeUrl = MapAPICollection.EXPORT_PROSPECT_DNC + str;

        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: completeUrl,
            Success: (e: any) => {
                this.parentDncGlobalComponent.domainDncExported();
                AlertNotificationsComponent.alertCall.showAlert('We have received your request, please check your inbox', 'primary');
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while exporting data', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);

        // let httpHeaders = new HttpHeaders();
        // httpHeaders.append("Accept", "application/json");
        // this.apiService.downloadFile(completeUrl, httpHeaders).subscribe((e: any) => {
        //     this.parentDncGlobalComponent.domainDncExported(e.res.body);
        // },
        // (error: any) => {
        //     AlertNotificationsComponent.alertCall.showAlert('Error while exporting prospects', 'danger');
        //     return error;
        //   }
        
        // );
    }

    exportClientDNC(search, client, includedItems, excludedItems){
        var str = "?client__id__isnull=false"
        if(search){
            str += "&email_domain__icontains=" + search
        }
        if(includedItems.length > 0){
            str += '&id__inc=' + includedItems.join(',')
        }

        if(excludedItems.length > 0){
            str += '&id__exc=' + excludedItems.join(',')
        }
        if(Number(client) > 0){
            str += '&client__id=' + client
        }

        var completeUrl = MapAPICollection.EXPORT_PROSPECT_DNC + str;

        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: completeUrl,
            Success: (e: any) => {
                this.parentDncClientComponent.domainDncExported();
                AlertNotificationsComponent.alertCall.showAlert('We have received your request, please check your inbox', 'primary');
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while exporting data', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);


        // let httpHeaders = new HttpHeaders();
        // httpHeaders.append("Accept", "application/json");
        // this.apiService.downloadFile(completeUrl, httpHeaders).subscribe((e: any) => {
        //     this.parentDncClientComponent.domainDncExported(e.res.body);
        // },
        // (error: any) => {
        //     AlertNotificationsComponent.alertCall.showAlert('Error while exporting prospects', 'danger');
        //     return error;
        //   }
        
        // );
    }


    exportDomainDNC(search, includedItems, excludedItems){
        var str = "?no_filter=null"
        if(search){
            str += "&email_domain__icontains=" + search
        }
        if(includedItems.length > 0){
            str += '&id__inc=' + includedItems.join(',')
        }

        if(excludedItems.length > 0){
            str += '&id__exc=' + excludedItems.join(',')
        }
        var completeUrl = MapAPICollection.EXPORT_DOMAIN_DNC + str;

        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: completeUrl,
            Success: (e: any) => {
                this.parentDncDomainComponent.domainDncExported();
                AlertNotificationsComponent.alertCall.showAlert('We have received your request, please check your inbox', 'primary');
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while exporting data', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);

        // let httpHeaders = new HttpHeaders();
        // httpHeaders.append("Accept", "application/json");
        // this.apiService.downloadFile(completeUrl, httpHeaders).subscribe((e: any) => {
        //     this.parentDncDomainComponent.domainDncExported(e.res.body);
        // },
        // (error: any) => {
        //     AlertNotificationsComponent.alertCall.showAlert('Error while exporting prospects', 'danger');
        //     return error;
        //   }
        
        // );
    }

}