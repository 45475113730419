import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MdbModalService, MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { BehaviorSubject } from 'rxjs';
import { ConfirmDialogService } from 'src/app/admin/confirm-dialog/confirm-dialog.service';
import { MapCommon } from 'src/app/app-providers/map-base/MapCommon';
import { CampaignModel, CampaignRunLogModel, CampaignRunLogResponseModel, CampaignStepModel, CampaignStepResponseModel} from '../campaign-model/campaign-model';
import { CampaignViewModel } from '../campaign-view-model/campaign-view-model';
import { CampaignStepCustomizeComponent } from './campaign-step-customize/campaign-step-customize.component';

@Component({
  selector: 'app-campaign-step-review',
  templateUrl: './campaign-step-review.component.html',
  styleUrls: ['./campaign-step-review.component.scss']
})
export class CampaignStepReviewComponent implements OnInit {
  @ViewChild("emailreview") emailreview: ElementRef;

  customizeModalRef: MdbModalRef<CampaignStepCustomizeComponent>;
  reviewList = []
  reviewListB4Search = []
  stepId: number;
  campaignId: number;
  emailSearchValue = '';
  @ViewChild(NgForm) emailSearchForm: NgForm;
  static stepReviewCall;
  totalReviewListCount: number;
  camapaignStepDetail: CampaignStepModel;
  loadingData: boolean = false;
  isLoading: boolean = false;

  connectedMailbox: string = null;
  connectedMailboxId: number = null;
  disableAllButtons: boolean = false;
  page_index = 1;
  campaignRunLogStepReviewResponseModel = new BehaviorSubject<CampaignRunLogResponseModel>(
    new CampaignRunLogResponseModel()
  );

  campaignStepModel = new BehaviorSubject<CampaignStepModel>(
    new CampaignStepModel()
  );

  campaignModel = new BehaviorSubject<CampaignModel>(
    new CampaignModel()
  );

  campaignStepsResponseModel = new BehaviorSubject<CampaignStepResponseModel>(
    new CampaignStepResponseModel()
  );


  constructor(
    private vm: CampaignViewModel,
    private modalService: MdbModalService,
    private route: ActivatedRoute,
    private router: Router,
    private confirmDialogService: ConfirmDialogService,
    private Renderer : Renderer2
    ) {
      this.connectedMailbox = localStorage.getItem('map_connected_mailbox');
      this.connectedMailboxId = Number(localStorage.getItem('map_connection_id'));
      if(!this.connectedMailbox) {
        this.disableAllButtons = true;
      }
      CampaignStepReviewComponent.stepReviewCall = this;

  }

  ngOnInit(): void {
    this.stepId = Number(this.route.snapshot.paramMap.get('stepId'));
    this.campaignId = Number(this.route.snapshot.paramMap.get('id'));
    this.vm.parentCampaignStepReviewComponent = this;
    this.vm.subscribeCampaignStepReviewVariables(
      this.campaignRunLogStepReviewResponseModel,
      this.campaignStepModel,
      this.campaignModel,
      this.campaignStepsResponseModel
      );
    this.vm.getCampaignById(this.campaignId, 'campaign-review');
    this.vm.getCampaignStepById(this.stepId, 'campaign-step-review');
    this.vm.getCampaignRunLogStepReview(this.page_index,this.stepId);
    this.vm.getCampaignSteps(this.campaignId, 'campaign-review');

  }


  CampaignRunLogStepReviewReceived(){
    if (this.reviewListB4Search.length == 0) {
      this.reviewList = this.campaignRunLogStepReviewResponseModel.value.results;
    }
    else {
      this.reviewList = this.campaignRunLogStepReviewResponseModel.value.results;
      if (this.campaignRunLogStepReviewResponseModel.value.previous !== null) {
        for (var j = 0; j < this.reviewList.length; j++) {
          var index = this.reviewListB4Search.findIndex(x => x.id == this.reviewList[j].id);
          if (index === -1)
            this.reviewListB4Search.push(this.reviewList[j]);
        }
        this.reviewList = this.reviewListB4Search;
      }
    }
    this.totalReviewListCount = this.campaignRunLogStepReviewResponseModel.value.count;
    this.loadingData = false;

  }

  CampaignStepsRecieved(){
    var campaignSteps = this.campaignStepsResponseModel.value.results;
    var all_status = []
    campaignSteps.forEach(element => {
      all_status.push(element.status)
    });
    if(all_status.includes('Processing') || all_status.includes('Paused')){
      this.disableAllButtons = true
    }
    console.log(all_status)
  }

  CampaignDetailReceived(){
    if(this.campaignModel.value.send_mode === 'Auto'){
      this.disableAllButtons = true;
    }
  }

  CampaignStepReceived() {
    this.camapaignStepDetail = this.campaignStepModel.value;
  }

  searchWithEmail(e: any){
    this.vm.getCampaignRunLogStepReview(1, this.stepId, this.emailSearchForm.value.search);
  }

  sendAllEmailsConfirmed() {
    this.vm.sendReviewAllEmail(this.stepId);
  }

  sendAllEmailsConfirmation(){
    this.confirmDialogService.confirmThis("Are you sure you want to send all emails?", function () {
      CampaignStepReviewComponent.stepReviewCall.sendAllEmailsConfirmed();
    }, function () {
    })
  }

  sendAllEmailsSuccess() {
    this.router.navigate(['campaigns/', this.campaignId]);
  }

  closeLoadingSpinner() {
    this.isLoading = false;
  }

  deleteSuccess(){
    this.vm.parentCampaignStepReviewComponent = this;
    this.vm.getCampaignRunLogStepReview(1,this.stepId);
    this.emailreview.nativeElement.scrollTop = 0;
    this.isLoading = false;
  }

  sendOneEmailsSuccess() {
    this.vm.parentCampaignStepReviewComponent = this;
    this.vm.getCampaignRunLogStepReview(1,this.stepId);
    this.emailreview.nativeElement.scrollTop = 0;

    this.isLoading = false;

  }

  sendOneEmailConfirmation(objectId){
    this.confirmDialogService.confirmThis("Please confirm sending email?", function () {
      CampaignStepReviewComponent.stepReviewCall.sendOneEmail(objectId);
    }, function () {
    })
  }


  sendOneEmail(objectId){
    this.isLoading = true;
    this.vm.sendReviewOneEmail(objectId);
  }

  getDownloadUrl(media_link){
    return MapCommon.getBaseApiUrl() + media_link;
  }


  deleteEmailConfirmation(object: CampaignRunLogModel) {
    this.confirmDialogService.confirmThis("Are you sure you want to delete?", function () {
      CampaignStepReviewComponent.stepReviewCall.deleteEmail(object.id);
    }, function () {
    })
  }


  deleteEmail(objectId){
    this.isLoading = true;
    this.vm.deleteReviewEmail(objectId);
  }


  customizeEmail(object: CampaignRunLogModel){
    this.customizeModalRef = this.modalService.open(CampaignStepCustomizeComponent, {
      modalClass: 'modal-lg',
      data: { object: object }
    });
    this.customizeModalRef.onClose.subscribe((message: any) => {
      if (message === true) {
        this.vm.parentCampaignStepReviewComponent = this;
        this.vm.getCampaignRunLogStepReview(this.page_index,this.stepId);
      }
    });
  }

  onReviewEmailScroll()
  {
    if (this.campaignRunLogStepReviewResponseModel.value.next) {
      this.reviewListB4Search = this.reviewList;
      this.page_index++;
      this.vm.getCampaignRunLogStepReview(this.page_index,this.stepId);

    }
  }

}
