<div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Edit Client</h5>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modalRef.close()"
    ></button>
  </div>
  <div class="modal-body">


    <mdb-form-control>
      <input mdbInput [(ngModel)]="clientModel.name" value="{{clientModel.name}}" type="text" 
      id="editClientNameForm" class="form-control" />
    </mdb-form-control>
      
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-link" (click)="modalRef.close()">
      Close
    </button>
    <button type="button" (click)="editClient()" class="btn btn-primary">Save changes</button>
  </div>