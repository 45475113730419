<div class="col-12 d-flex justify-content-between">
    <h6 class="pt-1 ps-1">Prospect DNC</h6> 
  
    <form #prospectSearchForm="ngForm">
      <mdb-form-control class="me-3">
        <input mdbInput type="text" id="prospectSearch" class="form-control form-control-sm" 
        [ngModel]="prospectSearchValue" name="search" (input)="prospectSearchSubmit($event)" />
        <label mdbLabel class="form-label" for="prospectSearch"><i class="far fa-envelope"></i> Search</label>
      </mdb-form-control>
      </form>

    <button type="button" (click)="openProspectModal()" class="btn btn-link btn-sm" > <i class="fas fa-plus"></i> Add Email</button>
</div>
<div class="table-responsive" infiniteScroll [infiniteScrollDistance]="2"
[infiniteScrollThrottle]="100" (scrolled)="onProspectDNCScroll()" [scrollWindow]="false">
<table class="table table-sm table-hover mt-1 table-responsive">
    <thead style="background-color: #F6F4EE;">
      <tr>
        <th scope="col">Email Address</th>
        <th scope="col">Client/Global</th>
      </tr>
    </thead>
    <tbody> 
      <tr (click)="editProspectModal(i)" *ngFor="let i of prospectDncList" class="dncItem">
        <td class="text-primary"><strong>{{i.email_address}}</strong></td>
        <td>{{getClientName(i.client)}}</td>
      </tr> 

    </tbody>
  </table></div>