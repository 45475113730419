import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-analytics-sidebar',
  templateUrl: './analytics-sidebar.component.html',
  styleUrls: ['./analytics-sidebar.component.scss']
})
export class AnalyticsSidebarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
