import { Component, OnInit } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { BehaviorSubject } from 'rxjs';
import { MapCommon } from 'src/app/app-providers/map-base/MapCommon';
import { ScheduleTimezoneResponseModel, SendingWindowsModel, 
  SendingWindowsResponseModel } from '../setting-model/setting-model';
import { SettingViewModel } from '../setting-view-model/setting-view-model';
import { AddSendingWindowsComponent } from './add-sending-windows/add-sending-windows.component';
import { UpdateSendingWindowsComponent } from './update-sending-windows/update-sending-windows.component';
import { ResizableDirective, ResizeEvent } from 'angular-resizable-element';
import { Router } from '@angular/router';

@Component({
  selector: 'app-settings-sending-windows',
  templateUrl: './settings-sending-windows.component.html',
  styleUrls: ['./settings-sending-windows.component.scss'],
  providers: [ResizableDirective],
})
export class SettingsSendingWindowsComponent implements OnInit {
  groupSearchModel = '';
  currentUser: number;
  sendingWindowslist = [];
  sendingWindowslistB4Search = [];
  sendingWindowsBacluplist = [];
  scheduleTimezoneList = [];
  page_index = 1;
  totalSendingWindowsRecieved:number;
  isLoading: boolean = false;

  typingTimer;                //timer identifier
  doneTypingInterval = 1000;  //time in ms, 5 second for example

  // sendingWindowsModel: SendingWindowsModel;

  addModalRef: MdbModalRef<AddSendingWindowsComponent>;
  updateModalRef: MdbModalRef<UpdateSendingWindowsComponent>;

  sendingWindowResponseModel = new BehaviorSubject<SendingWindowsResponseModel>(
    new SendingWindowsResponseModel()
  );

  scheduleTimezoneResponseModel = new BehaviorSubject<ScheduleTimezoneResponseModel>(
    new ScheduleTimezoneResponseModel()
  );

  constructor(private vm: SettingViewModel, private modalService: MdbModalService, private router: Router) { 
    this.currentUser = MapCommon.getloggedInUser().id;
    this.vm.parentSettingSendingWindow = this;
    this.vm.subscribeSendingWindowsVariable(this.sendingWindowResponseModel);
    this.vm.subscribeScheduleTimezoneVariable(this.scheduleTimezoneResponseModel);
    this.vm.getAllScheduleTimezone('sending-window-list');
    // this.vm.getAllSendingWindows(this.page_index, this.groupSearchModel);  
    this.getData();
  }

  ngOnInit(): void {
   
  }


  getTimezoneGmtOffSet(timezoneId){
    const timezoneItem =  this.scheduleTimezoneList.find(item => item.id == timezoneId);    
    if (timezoneItem) {
      var is_behind_gmt = timezoneItem['is_behind_gmt'] ? '-' : '+'
      var h = Math.floor(timezoneItem['gmt_offset_minute'] / 60);
      var m = timezoneItem['gmt_offset_minute'] % 60;
      var new_h = ( h < 10) ? '0' + h : h; 
      var new_m = (m < 10) ? '0' + m : m; 
      var gmt = 'GMT' + is_behind_gmt + new_h + ':' + new_m;
      return gmt
    } else {
      return 'Not Found'
    }
  }

  sendingWindowsReceived() {

    if (this.sendingWindowslistB4Search.length == 0)
      this.sendingWindowslist = this.sendingWindowResponseModel.value.results;
      else {
        this.sendingWindowslist = this.sendingWindowResponseModel.value.results;
        if (this.sendingWindowResponseModel.value.previous !== null) {
          for (var j = 0; j < this.sendingWindowslist.length; j++) {
            var index = this.sendingWindowslistB4Search.findIndex(x => x.id == this.sendingWindowslist[j].id);
            if (index === -1)
              this.sendingWindowslistB4Search.push(this.sendingWindowslist[j]);
          }
          this.sendingWindowslist = this.sendingWindowslistB4Search;
        }
      }
      this.totalSendingWindowsRecieved = this.sendingWindowResponseModel.value.count;
    this.sendingWindowsBacluplist = this.sendingWindowslist;

    this.isLoading = false;
  }

    //on keyup, start the countdown
    inputkeyUp() {
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(this.getData.bind(this), this.doneTypingInterval);
    };
  
    //on keydown, clear the countdown
    inputKeyDown() {
      clearTimeout(this.typingTimer);
    };

    getData() {
      this.isLoading = true;
      this.vm.getAllSendingWindows(this.page_index, this.groupSearchModel);
    }


  // groupSearchSubmit(e: any){
  //   this.sendingWindowslist = this.sendingWindowsBacluplist;
  //   this.sendingWindowslist = this.sendingWindowslist.filter(a => a.name.indexOf(e.target.value) > -1);
  // }
  
  getTimezoneName(id: number) { 
    var result = this.scheduleTimezoneList.filter(obj => {
      return obj.id === id;
    })
    return (result[0].name).toString();
  }

  scheduleTimezoneReceived() {
    this.scheduleTimezoneList = this.scheduleTimezoneResponseModel.value.results;
    this.sendingWindowslist.forEach(element => {
      element.timezone_text = this.getTimezoneName(element.timezone);
    });

  }

  addSendingWindows() {
    this.router.navigate(['/settings/sending-windows/add'])
    // this.addModalRef = this.modalService.open(AddSendingWindowsComponent, {
    //   modalClass: 'modal-fullscreen',
    //   data: {scheduleTimezoneList: this.scheduleTimezoneList, currentUser: this.currentUser} 
    // });
  }

  editSendingWindows(selectedSendingWindowsModel: SendingWindowsModel) {
    // console.log('sendingwindow', selectedSendingWindowsModel.id)

    this.router.navigate([`/settings/sending-windows/edit`,selectedSendingWindowsModel.id])
    // this.updateModalRef = this.modalService.open(UpdateSendingWindowsComponent, {
    //   modalClass: 'modal-fullscreen',
    //   data: {
    //     scheduleTimezoneList: this.scheduleTimezoneList, 
    //     sendingWindowModel: selectedSendingWindowsModel, 
    //     currentUser: this.currentUser} 
    // });
    
  }

 // This method is being called once user hits bottom while scrolling sending windows
 onSendingWindowScroll() {
  if (this.sendingWindowResponseModel.value.next) {
    this.sendingWindowslistB4Search = this.sendingWindowslist;
    this.page_index++;
    this.getData();
    // this.vm.getAllSendingWindows(this.page_index, this.groupSearchModel);
  }
}

  
}
