import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter  } from '@angular/core';
import { CampaignClientResponseModel, CampaignFilterModel, CampaignUserResponseModel }
  from "../campaign-model/campaign-model";
import { MapAPIService } from "src/app/app-providers/services/map-module";
import { BehaviorSubject } from 'rxjs';
import { CampaignViewModel } from '../campaign-view-model/campaign-view-model';
import { CampaignsComponent } from '../campaigns.component';
import { MapCommon } from 'src/app/app-providers/map-base/MapCommon';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-sidebar-campaigns',
  templateUrl: './sidebar-campaigns.component.html',
  styleUrls: ['./sidebar-campaigns.component.scss'],
  providers: [DatePipe],
})
export class SidebarCampaignsComponent implements OnInit {

  @ViewChild('myClient') myClient: ElementRef<HTMLElement>;
  @ViewChild('createdByRadio') createdByRadio: ElementRef<HTMLElement>;
  @ViewChild('createdAtRadio') createdAtRadio: ElementRef<HTMLElement>;

  userList = [];
  clientList = [];

  @Output() userListChangeEvent = new EventEmitter<any[]>();
  @Output() clientChangeEvent = new EventEmitter<any[]>();


  clientCheckBox:boolean = false;
  searchName : string = null;
  StatusStep:any
  StatusList = [
  'New',
  'Scheduled',
  'Processing',
  'Paused',
  'Finished',
  'Rejected',]
  filterArr = [];
  showClearFilter = false;

  createdById: number = null;
  createdByArr = [];

  clientId: number = null;
  clientArr = [];




  campaignStatus: string = null;
  campaignStatusArr = [];

  nameContainsText: string = null;
  nameStartsWithText: string = null;
  nameEndsWithText: string = null;
  nameIsText: string = null;

  campaignNameArr = [];
  campaignFilterModel = new CampaignFilterModel();

  ownerOptions: number;
  currentUser: number;

  user_report_dates: any;
  bsMaxDate = new Date();
  createdAtArr = [];

  campaignUserResponseModel = new BehaviorSubject<CampaignUserResponseModel>(
    new CampaignUserResponseModel()
  );

  campaignClientResponseModel = new BehaviorSubject<CampaignClientResponseModel>(
    new CampaignClientResponseModel()
  );

  constructor(private vm: CampaignViewModel, private cc: CampaignsComponent, private datePipe: DatePipe) {
    this.currentUser = MapCommon.getloggedInUser().id;

    this.updateCreatedAtDate();

  }

  ngOnInit(): void {
    this.vm.parentSidebarCampaignsComponent = this;
    this.vm.subscribeAllSideBarVariables(this.campaignUserResponseModel, this.campaignClientResponseModel);
    // save extra api call on campaign listing page by commenting below api calls
    this.vm.getAllUsers('campaignSideBar');
    this.vm.getAllClients('sidebar-campaign');
  }


  UserRecieved() {
    this.userList = this.campaignUserResponseModel.value.results;

    if(this.currentUser){
      this.createdById = this.currentUser;
      // this.onCreatedBySelected();
      this.createdByArr = [];
      this.campaignFilterModel.created_by__id = this.createdById;
      this.createdByArr.push({ 'filterName': 'created_by__id' }, { 'filterValue': this.createdById });
      if (this.createdByArr.length > 0) {
        if (this.createdByArr[0].filterName == 'created_by__id')
          this.campaignFilterModel.created_by__id = this.createdByArr[1].filterValue;
      }
      let el: HTMLElement = this.createdByRadio.nativeElement;
      // console.log(el)
      el.click();
    }

    this.userListChangeEvent.emit(this.userList)
  }

  ClientRecieved() {
    this.clientList = this.campaignClientResponseModel.value.results;
    var tempProspectGroupId = localStorage.getItem("selectedClient");
    if (tempProspectGroupId !== null) {
      this.clientId = parseInt(tempProspectGroupId);
      // this.onclientSelected();
      this.clientArr = [];
      this.campaignFilterModel.client__id = this.clientId;
      this.clientArr.push({ 'filterName': 'client__id' }, { 'filterValue': this.clientId });
      if (this.clientArr.length > 0) {

        if (this.clientArr[0].filterName == 'client__id')
          this.campaignFilterModel.client__id = this.clientArr[1].filterValue;

      }
      this.clientCheckBox = true;
      let el: HTMLElement = this.myClient.nativeElement;
      el.click();
      if (tempProspectGroupId !== null) {
        localStorage.removeItem('selectedClient');
      }
    }

    this.clientChangeEvent.emit(this.clientList);
  }

  // searchSubmit(e: any) {
  //   this.campaignFilterModel.name__contains=this.searchName;
  //   this.CallFinalFilter();
  //  }

  sidebarFilter(obj = null, filterName, checkboxObj) {
    if (obj) {
      if (!this.inArray(filterName, this.filterArr)) {

        this.filterArr.push({'filterName': filterName, 'filterObj': checkboxObj, 'toggleObj': obj});
        // Add filter value
        this.AddFilter(filterName);
      }
      else {
        this.filterArr.forEach((element, index) => {
          if (element.filterName == filterName) this.filterArr.splice(index, 1);
        });

        // remove filter
        this.RemoveFilter(filterName);

      }
      obj.toggle();
    }
    this.showClearFilter = this.filterHasElement();
  }

  filterHasElement() {
    if (this.filterArr.length > 0)
      return true;
    else
      return false;
  }

  inArray(needle, haystack) {
    var count = haystack.length;
    for (var i = 0; i < count; i++) {
      if (haystack[i].filterName === needle) { return true; }
    }
    return false;
  }
  onCreatedBySelected() {
    if(this.createdById == null){
      document.getElementById('createdByRadio').click()
    }
    this.createdByArr = [];
    this.campaignFilterModel.created_by__id = this.createdById;
    this.createdByArr.push({ 'filterName': 'created_by__id' }, { 'filterValue': this.createdById });
    this.AddFilter('createdBy');
    // this.CallFinalFilter();
  }
  onclientSelected() {
  
    if(this.clientId == null){
      document.getElementById('flexRadioDefault2').click()
    }
    this.clientArr = [];
    this.campaignFilterModel.client__id = this.clientId;
    this.clientArr.push({ 'filterName': 'client__id' }, { 'filterValue': this.clientId });
    this.AddFilter('client');
    // this.CallFinalFilter();
  }

  RemoveFilter(filterName) {
    if (filterName == 'name') {
      this.campaignFilterModel.name = null;
      this.campaignFilterModel.name__contains = null;
      this.campaignFilterModel.name__startswith = null;
      this.campaignFilterModel.name__endswith = null;
    }
    if (filterName == 'createdBy') {
      this.campaignFilterModel.created_by__id = null;
    }

    if (filterName == 'client') {
      this.campaignFilterModel.client__id = null;
    }

    if(filterName == 'status')
    {
      this.campaignFilterModel.status = null;
    }

    this.CallFinalFilter();
  }
  AddFilter(filterName) {
    if (filterName == 'name') {
      if (this.campaignNameArr.length > 0) {
        if (this.campaignNameArr[0].filterName == 'name')
          this.campaignFilterModel.name = this.campaignNameArr[1].filterValue;

        if (this.campaignNameArr[0].filterName == 'name__contains')
          this.campaignFilterModel.name__contains = this.campaignNameArr[1].filterValue;

        if (this.campaignNameArr[0].filterName == 'name__startswith')
          this.campaignFilterModel.name__startswith = this.campaignNameArr[1].filterValue;

        if (this.campaignNameArr[0].filterName == 'name__endswith')
          this.campaignFilterModel.name__endswith = this.campaignNameArr[1].filterValue;
      }
    }

    if (filterName == 'createdBy') {
      if (this.createdByArr.length > 0) {
        if (this.createdByArr[0].filterName == 'created_by__id')
          this.campaignFilterModel.created_by__id = this.createdByArr[1].filterValue;
      }
    }

    if (filterName == 'client') {
      if (this.clientArr.length > 0) {

        if (this.clientArr[0].filterName == 'client__id')
          this.campaignFilterModel.client__id = this.clientArr[1].filterValue;

      }
    }

    if(filterName == 'status')
    {
      if (this.campaignStatusArr.length > 0) {

        if (this.campaignStatusArr[0].filterName == 'status')
          this.campaignFilterModel.status = this.campaignStatusArr[1].filterValue;
      }
    }

    this.CallFinalFilter();
  }

  activeName = null;
  togglenameFilter(obj = null, actionSelected, radioObj= null) {
    if (actionSelected == 'nameContains') {
      this.campaignFilterModel.name = null;
      this.campaignFilterModel.name__contains = this.nameContainsText;
      this.campaignFilterModel.name__startswith = null;
      this.campaignFilterModel.name__endswith = null;
      this.campaignNameArr = [];
      this.campaignNameArr.push({ 'filterName': 'name__contains' }, { 'filterValue': this.nameContainsText }, { 'radioObj': radioObj } );
    }
    if (actionSelected == 'nameStartsWith') {
      this.campaignFilterModel.name = null;
      this.campaignFilterModel.name__contains = null;
      this.campaignFilterModel.name__startswith = this.nameStartsWithText;
      this.campaignFilterModel.name__endswith = null;
      this.campaignNameArr = [];
      this.campaignNameArr.push({ 'filterName': 'name__startswith' }, { 'filterValue': this.nameStartsWithText }, { 'radioObj': radioObj });
    }
    if (actionSelected == 'nameEndsWith') {
      this.campaignFilterModel.name = null;
      this.campaignFilterModel.name__contains = null;
      this.campaignFilterModel.name__startswith = null;
      this.campaignFilterModel.name__endswith = this.nameEndsWithText;
      this.campaignNameArr = [];
      this.campaignNameArr.push({ 'filterName': 'name__endswith' }, { 'filterValue': this.nameEndsWithText }, { 'radioObj': radioObj });
    }
    if (actionSelected == 'nameIs') {
      this.campaignFilterModel.name = this.nameIsText;
      this.campaignFilterModel.name__contains = null;
      this.campaignFilterModel.name__startswith = null;
      this.campaignFilterModel.name__endswith = null;
      this.campaignNameArr = [];
      this.campaignNameArr.push({ 'filterName': 'name' }, { 'filterValue': this.nameIsText }, { 'radioObj': radioObj });
    }
    // this.CallFinalFilter();

    if (obj == this.activeName)
      return;
    if (this.activeName) {
      this.activeName.toggle();
    }

    if (obj) {
      obj.toggle();
    }

    this.activeName = obj;
  }


  // this message handle input for name
  handleUserInput(inputVal) {
    // name
    if (inputVal == 'nameContains') {
      if (this.campaignNameArr.length > 0) {
        this.campaignNameArr[1].filterValue = this.nameContainsText;
      }
      this.AddFilter('name');
    }
    if (inputVal == 'nameStartsWith') {
      if (this.campaignNameArr.length > 0) {
        this.campaignNameArr[1].filterValue = this.nameStartsWithText;
      }
      this.AddFilter('name');
    }
    if (inputVal == 'nameEndsWith') {
      if (this.campaignNameArr.length > 0) {
        this.campaignNameArr[1].filterValue = this.nameEndsWithText;
      }
      this.AddFilter('name');
    }
    if (inputVal == 'nameIs') {
      if (this.campaignNameArr.length > 0) {
        this.campaignNameArr[1].filterValue = this.nameIsText;
      }
      this.AddFilter('name');
    }
  }

  onstatusSelected( object){
    console.log(object)
    switch(object){
    case 'yes':
    this.campaignStatus = "Active";
    break;
    case 'no':
    this.campaignStatus = "Inactive";
    break;
    case 'Finished':
    this.campaignStatus = "Finished";
    break;
    case 'New':
    this.campaignStatus = "New";
    break;
    case 'Paused':
    this.campaignStatus = "Paused";
    break;
    case 'Rejected':
    this.campaignStatus = "Rejected";
    break;
    case 'Processing':
    this.campaignStatus = "Processing";
    break;
    case 'Scheduled':
    this.campaignStatus = "Scheduled";
    break;
    case undefined :
    document.getElementById('flexRadioDefault8').click()
    break;
    }
    
    this.campaignStatusArr = [];
    this.campaignFilterModel.status = this.campaignStatus;
    this.campaignStatusArr.push({ 'filterName': 'status' }, { 'filterValue': this.campaignStatus }, { 'radioObj': object });
    if(this.StatusStep){
      this.CallFinalFilter();
    }


  }

  CallFinalFilter() {
    this.cc.callFilter(this.campaignFilterModel);
  }
  // CallFinalFilterStepStatus() {
  //   this.cc.callFilterStepStatus(this.campaignFilterModel);
  // }

  clearSelection(e) {
    e.preventDefault();
    this.campaignFilterModel = new CampaignFilterModel();

    // Clear created by
    this.createdById = null;
    this.createdByArr = []

    // clear client
    this.clientId = null
    this.clientArr = [];

    // clear name
    this.nameContainsText = null;
    this.nameStartsWithText = null;
    this.nameEndsWithText = null;
    this.nameIsText = null;

    // clear the child selection for optedOut
    if (this.campaignNameArr.length > 0) {
      this.campaignNameArr[2].radioObj.target.checked = false;
    }
    this.campaignNameArr = [];

    // clear status
    if (this.campaignStatusArr.length > 0) {
      // this.campaignStatusArr[2].radioObj.target.checked = false;
      this.StatusStep = undefined
    }
    this.campaignStatusArr = [];

    this.filterArr.forEach((element, index) => {
      element.toggleObj.toggle();
      element.filterObj.target.checked = false;
    });

    this.filterArr = [];
    this.showClearFilter = this.filterHasElement();

    this.updateCreatedAtDate();
    this.CallFinalFilter();

  }

  updateCreatedAtDate(): void{
    var startDate = new Date();
    this.bsMaxDate.setDate(startDate.getDate());
    startDate.setDate(startDate.getDate() - 30);
    this.user_report_dates = [startDate, this.bsMaxDate];
    this.campaignFilterModel.created_at_start_date =  this.datePipe.transform(this.user_report_dates[0], "YYYY-MM-dd");
    this.campaignFilterModel.created_at_end_date = this.datePipe.transform(this.user_report_dates[1], "YYYY-MM-dd");
  }


  onValueChange(value: Date): void {
    this.createdAtArr = []
    if (value) {
      if(value !== this.user_report_dates){
        this.campaignFilterModel.created_at_start_date = this.datePipe.transform(value[0], "YYYY-MM-dd");
        this.campaignFilterModel.created_at_end_date = this.datePipe.transform(value[1], "YYYY-MM-dd");
        this.createdAtArr.push({ 'filterName': 'created_at_start_date' }, { 'filterValue': this.campaignFilterModel.created_at_start_date });
        this.createdAtArr.push({ 'filterName': 'created_at_end_date' }, { 'filterValue': this.campaignFilterModel.created_at_end_date });
        this.AddFilter('dates')
      }

    }
    else {
      this.updateCreatedAtDate();
    }

  }

}
