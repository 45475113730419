import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LinkTrackingViewModel } from '../models/view-model';
import { Meta } from '@angular/platform-browser'; 

@Component({
  selector: 'app-link-tracking',
  templateUrl: './link-tracking.component.html',
  styleUrls: ['./link-tracking.component.scss']
})
export class LinkTrackingComponent implements OnInit {

  currentURL: string;
  destination: string;

  constructor(private router: Router, private vm: LinkTrackingViewModel, private meta: Meta) {
    this.currentURL = this.router.url;
   }

  ngOnInit(): void {
    if(this.currentURL.includes('?destination=') && this.currentURL.includes('/source/')){
      var mainSlug = this.currentURL.replace('/source/', '');
        var linkinfo = mainSlug.split('?destination=')[0].split('-');
        var linkinfoMapping = String(
          'cm' + String(linkinfo[0]) + 
          '-st' + String(linkinfo[1]) + 
          '-pr' + String(linkinfo[2]) + 
          '-li' + String(linkinfo[3])
        )
        this.destination = mainSlug.split('?destination=')[1];
        try {
          var decodeURI = decodeURIComponent(this.destination);
          this.vm.postLinkInfo(linkinfoMapping, decodeURI);
          this.meta.addTag(
            {
              'http-Equiv': 'Refresh',
              'content': "5; {}".replace('{}', decodeURI)
            }
          );
        } catch(e) {
          console.error(e);
        }
        
          
    } else {
      this.router.navigateByUrl('/');
    }

  }





}
