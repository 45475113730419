import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BehaviorSubject, Observer } from 'rxjs';
import { MapCommon } from './app-providers/map-base/MapCommon';
import { LoginUserModel } from './login/models/login-model';
import { LoginViewModel } from './login/models/login-view-model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'bf-map-frontend';

  public href: string = "";

  currentUser: LoginUserModel = null;
  isLoggedIn: boolean = false;

     
 
  constructor(private router: Router, private loginVM: LoginViewModel) {
    const localStorage_user = MapCommon.getloggedInUser();
    if (localStorage_user){
      this.currentUser = localStorage_user
      this.isLoggedIn = true;
    }

    this.loginVM.parentAppcomponent = this;
  }

  

  ngOnInit(): void {

  }

  updateSidebar() {
    const localStorage_user = MapCommon.getloggedInUser();
    if (localStorage_user){
      this.currentUser = localStorage_user
      this.isLoggedIn = true;
    }
  }



}
