<div class="ms-sm-auto" style="width: 90%;">


    <div class="row" style="height: 100%;">



        <div class="col-6" style="background: #FBF7FE; height: 100vh; overflow-x: hidden; overflow-y: auto; ">


            <div class="row">
                <div class="col-4 pt-3">

                    <h5>Prospect</h5>

                </div>
                <div class="col-8 mt-2">
                    <div style="float: right;">
                        <button type="button" (click)="openAddToCampaignComponent()" class="btn btn-link btn-sm">
                            <i class="fas fa-paper-plane"></i> Add to campaign
                        </button>
                        <button (click)="openProspectEditModal()" type="button" class="btn btn-link btn-sm">
                            <i class="fas fa-edit"></i>
                        </button>
                    </div>
                </div>

            </div>

            <hr style="margin-top: 0px; margin-bottom: 10px;">

            <div class="row">
                <div class="col-12">

                    <div class="row mt-3">
                        <div class="col d-flex justify-content-center" *ngIf="!prospectdetail.is_global_dnc">
                            <button type="button" class="btn btn-danger" (click)="markDNC()">Mark DNC</button>
                        </div>
                    </div>


                    <div class="row mt-4 ps-3">
                        <div class="col-4">
                            <p>Email</p>
                        </div>
                        <div class="col-8">
                            <p>{{prospectdetail.email_address}}</p>
                        </div>
                        <div class="col-4">
                            <p>Last Contacted</p>
                        </div>
                        <div class="col-8">
                            <p>{{prospectdetail.last_contacted | date: 'medium'}}</p>
                        </div>
                        <!-- <div class="col-4">
                            <p>Status</p>
                        </div>
                        <div class="col-8">
                            <p>{{statusName}}</p>
                        </div> -->
                        <div class="col-4">
                            <p>Full Name</p>
                        </div>
                        <div class="col-8">
                            <p>{{prospectdetail.firstname}} {{prospectdetail.lastname}}</p>
                        </div>
                        <div class="col-4">
                            <p>Company</p>
                        </div>
                        <div class="col-8">
                            <p>{{prospectdetail.company}}</p>
                        </div>
                        <div class="col-4">
                            <p>Score</p>
                        </div>
                        <div class="col-8">
                            <p>{{prospectdetail.score}}</p>
                        </div>
                        <div class="col-4">
                            <p>Send Limit</p>
                        </div>
                        <div class="col-8">
                            <p *ngIf="prospectdetail.send_limit_reset_duration">
                                {{prospectdetail.send_limit_used}}/{{prospectdetail.send_limit_max }} used
                                <br>Limit will reset after {{getLimitResetDate(prospectdetail.send_limit_last_reset_at,
                                prospectdetail.send_limit_reset_duration) | date: 'd MMM, y'}}
                            </p>
                        </div>
                        <div class="col-4">
                            <p>Status</p>
                        </div>
                        <div class="col-8">
                            <p>{{getStatusName(prospectdetail.status)}}</p>
                        </div>
                        <!-- <div class="col-4">
                            <p>Region</p>
                        </div> -->
                        <!-- <div class="col-8">
                            <p>{{prospectdetail.region}}</p>
                        </div> -->
                        <div class="col-4">
                            <p>Phone</p>
                        </div>
                        <div class="col-8">
                            <p *ngIf="prospectdetail?.phone" class="m-0">{{prospectdetail?.phone}}</p>
                            <p *ngIf="prospectdetail?.phone_1" class="m-0">{{prospectdetail?.phone_1}}</p>
                            <p *ngIf="prospectdetail?.phone_2" class="m-0">{{prospectdetail?.phone_2}}</p>
                        </div>
                    </div>
                </div>


                <div class="col-6 mt-5">
                    <h5>Campaigns</h5>
                    <hr style="margin-top: 0px; margin-bottom: 10px;">

                    <div class="row ps-3 pt-1" *ngFor="let c of campaigndetail">
                        <div class="col-10">
                            <p>{{c.name}}</p>
                        </div>
                        <div class="col-2">
                            <button type="button" class="btn btn-link btn-sm" (click)="deleteCampaign(c.id)">
                                <i class="far fa-trash-alt"></i>
                            </button>

                        </div>
                    </div>

                </div>



                <!-- <div class="col-6 mt-5">
                    <h5>Groups</h5>
                    <hr style="margin-top: 0px; margin-bottom: 10px;">
                    <div *ngFor="let i of prospectdetail.prospect_group" class="row ps-3 pt-1">
                        <div class="col-10">
                            <p>{{getProspectGroupName(i)}}</p>
                        </div>
                        <ng-container *ngIf="prospectdetail.prospect_group.length >= 2 else disableProspectGroupDelete" >
                            <div class="col-2">
                                <button (click)="removeProspectGroupConf(i)" type="button" class="btn btn-link btn-sm">
                                    <i class="far fa-trash-alt"></i>
                                </button>
                            </div>
                        </ng-container>

                        <ng-template #disableProspectGroupDelete>
                            <div class="col-2">
                                <button disabled type="button" class="btn btn-link btn-sm">
                                    <i class="far fa-trash-alt"></i>
                                </button>
                            </div>
                        </ng-template>

                    </div>


                </div> -->


                <div class="col-6 mt-5">
                    <h5>DNC Clients</h5>
                    <hr style="margin-top: 0px; margin-bottom: 10px;">
                    <div *ngFor="let dnc of dncdetail" class="row ps-3 pt-1">
                        <div class="col-10">
                            <p>{{dnc.client__name}}</p>
                        </div>
                        <div class="col-2">
                            <!-- <button type="button" (click)="deleteDNC(dnc)" class="btn btn-link btn-sm">
                                <i class="far fa-trash-alt"></i>
                            </button> -->

                        </div>
                    </div>

                    <!-- <div class="row ps-3 pt-1">
                            <div class="col-4">
                                <p>Data Group 2</p>
                            </div>
                            <div class="col-8">
                                <button type="button" class="btn btn-link btn-sm" >
                                    <i class="far fa-trash-alt"></i>
                                </button>

                            </div>
                        </div> -->
                </div>
            </div>


        </div>




        <div class="col-6 p-2" style="height: 100vh;">


            <div class="row">
                <div class="col-12">
                    <h5>History</h5>
                    <hr>
                </div>

                <div class="prospect-event-scrollable">
                <div class="prospect-events-list">

                    <div *ngFor="let item of prospectHistoryResponseModel.value?.results; let count = index">



                        <div class="prospect-event" *ngIf="item.dnc">
                            <div class="prospect-event-wrapper">
                                <div class="prospect-event-icon">
                                    <i class="fas fa-ban event-icon"></i>
                                </div>
                                <div class="prospect-event-data">
                                    <p class="m-0 text-fix">{{prospect_full_name}} marked
                                        {{item.dnc.client ? 'Client':'Global'}} DNC by {{item.dnc.created_by}}
                                    </p>
                                    <p class="m-0 prospect-event-date">
                                        <i class="fas fa-info-circle"></i>
                                         {{item.dnc.reason}}</p>
                                    <p class="m-0 prospect-event-date">{{item.dnc.created_at | date: 'medium'}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="prospect-event" *ngIf="item.email_bounced_at">
                            <div class="prospect-event-wrapper">
                                <div class="prospect-event-icon">
                                    <i class="fas fa-ban event-icon"></i>
                                </div>
                                <div class="prospect-event-data">
                                    <p class="m-0 text-fix">{{prospect_full_name}} was bounced from <strong><a href="/campaigns/{{item.campaign.campaign_id}}">{{item.campaign.campaign_name}}</a></strong> campaign.</p>
                                    <p class="m-0 prospect-event-date">{{item.email_bounced_at | date: 'medium'}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="prospect-event" *ngIf="item.email_replied_at">
                            <div class="prospect-event-wrapper">
                                <div class="prospect-event-icon">
                                    <i class="fas fa-reply event-icon"></i>
                                </div>
                                <div class="prospect-event-data">
                                    <p class="m-0 text-fix">{{prospect_full_name}} replied to an email of <strong><a href="/campaigns/{{item.campaign.campaign_id}}">{{item.campaign.campaign_name}}</a></strong> campaign.</p>
                                    <p class="m-0 prospect-event-date">{{item.email_replied_at | date: 'medium'}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="prospect-event" *ngIf="item.email_opened_at">
                            <div class="prospect-event-wrapper">
                                <div class="prospect-event-icon">
                                    <i class="far fa-envelope-open event-icon"></i>
                                </div>
                                <div class="prospect-event-data">
                                    <p class="m-0 text-fix">{{prospect_full_name}} opened an email of <strong><a href="/campaigns/{{item.campaign.campaign_id}}">{{item.campaign.campaign_name}}</a></strong> campaign.</p>
                                    <p class="m-0 prospect-event-date">{{item.email_opened_at | date: 'medium'}}</p>
                                </div>
                            </div>
                        </div>


                        <div class="prospect-event" *ngIf="item.email_sent_at">
                            <div class="prospect-event-wrapper">
                                <div class="prospect-event-icon">
                                    <i class="far fa-envelope event-icon"></i>
                                </div>
                                <div class="prospect-event-data">
                                    <p class="m-0 text-fix">An email was sent to {{prospect_full_name}} from <strong><a href="/campaigns/{{item.campaign.campaign_id}}">{{item.campaign.campaign_name}}</a></strong> campaign.</p>
                                    <p class="m-0 prospect-event-date">{{item.email_sent_at | date: 'medium'}}</p>
                                </div>
                            </div>
                        </div>


                        <div class="prospect-event" *ngIf="item.campaign">
                            <div class="prospect-event-wrapper">
                                <div class="prospect-event-icon">
                                    <i class="fas fa-paper-plane event-icon"></i>
                                </div>
                                <div class="prospect-event-data">
                                    <p class="m-0 text-fix"><strong>{{item.user?.username}}</strong> added {{prospect_full_name}} to the
                                        <strong><a href="/campaigns/{{item.campaign.campaign_id}}">{{item.campaign.campaign_name}}</a></strong> campaign.</p>
                                </div>
                            </div>
                        </div>


                    </div>




                        <div class="prospect-event">
                            <div class="prospect-event-wrapper">
                                <div class="prospect-event-icon">
                                    <i class="fas fa-plus event-icon"></i>
                                </div>

                                <div class="prospect-event-data">
                                    <p class="m-0 text-fix">{{userModel.value.email ? userModel.value.email: userModel.value.username}} created {{prospect_full_name}}.</p>
                                    <p class="m-0 prospect-event-date">{{prospectdetail.created_at | date: 'medium'}}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>


    </div>

</div>
