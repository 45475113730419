<div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Send Test</h5>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modalRef.close()"
    ></button>
  </div>
  <div class="modal-body">

    <p>
      Using this feature, you can send a test email to a single recepient.<br>
      Please enter an email to send test email to:
    </p>

    <mdb-form-control>
      <input mdbInput [(ngModel)]="recipientEmail" type="email" id="sendTestEmail" class="form-control" 
      value="{{recipientEmail}}" placeholder="Enter an email" />
    </mdb-form-control>

    <ng-container *ngIf="formError">
      <div class="mt-3 text-center text-danger">
        <p>{{formError}}</p>
      </div>
    </ng-container>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-link" (click)="modalRef.close()">
      Close
    </button>
    <button type="button" (click)="sendTestConfirmed()" class="btn btn-primary">Send</button>
  </div>