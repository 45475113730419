

<div class="ms-sm-auto" style="width: 90%;">

    
    <div class="row" style="height: 100%;">
    
    
    
        <div class="col-2 shadow-3" style="background: #FBF7FE; height: 100vh; overflow-x: hidden; overflow-y: auto; ">      
    
            <app-sidebar-dnc></app-sidebar-dnc>
        </div>
      
      
       
        <div class="col-10 p-2" style="height: 100vh; overflow-x: hidden; overflow-y: auto; ">


            <div class="col-12 d-flex justify-content-between">

              <div class="d-flex">
                <h6 class="pt-1 ps-1">Global DNC <span class="header-count">({{totalDncItems}} Found)</span></h6> 

                  <button type="button" *ngIf="selectedItems > 0" (click)="exportAll()"
                  class="btn btn-link btn-sm ms-2">
                  <i  *ngIf="showingExportLoading" class="fas fa-spinner fa-spin"></i>
                  <i *ngIf="!showingExportLoading" class="fas fa-cloud-download-alt"></i>
                </button>
              </div>
              
                <form #globalDncSearchForm="ngForm">
                  <mdb-form-control class="me-3">
                    <input mdbInput type="text" id="globalDncSearch" class="form-control form-control-sm" 
                    [ngModel]="globalDncSearchValue" name="search" (input)="globalDncSearchSubmit($event)" />
                    <label mdbLabel class="form-label" for="globalDncSearch"><i class="far fa-envelope"></i> Search</label>
                  </mdb-form-control>
                  </form>
            
                <button type="button" (click)="openGlobalDncModal()" class="btn btn-link btn-sm" > <i class="fas fa-plus"></i> Add Email</button>
            </div>
            <div class="table-responsive" infiniteScroll [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="100" (scrolled)="onGlobalDncScroll()" [scrollWindow]="false">
            <table class="table table-sm table-hover mt-1 table-responsive">
                <thead style="background-color: #F6F4EE;">
                  <tr>
                    <th scope="col" style="width: 30px;">
                      <input #masterSelect mdbRadio style="cursor: pointer;" type="checkbox" [(ngModel)]="isMasterSel"
                        name="flexRadioDefault1" id="flexRadioDefault1" (change)="checkUncheckAll($event)" />
                    </th>
                    <th scope="col">Email Address</th>
                    <th scope="col">Created at</th>
                    <th scope="col">Reason</th>
                  </tr>
                </thead>
                <tbody> 
                  <tr *ngFor="let i of globalDncList" class="dncItem">
                    <td>
                      <input type="checkbox" [(ngModel)]="i.isSelected" name="list_name" value="{{i.id}}"
                        (change)="isAllSelected($event, i.id)" />
                    </td>
                    <td (click)="editGlobalDncModal(i)"  class="text-primary"><strong>{{i.email_address}}</strong></td>
                    <td (click)="editGlobalDncModal(i)" >{{i.created_at | date: 'medium'}}</td>
                    <td (click)="editGlobalDncModal(i)" >{{i.dnc_reason}}</td>
                  </tr> 
            
                </tbody>
              </table></div>
    
         
        
        </div>  
    
    
      
      
      </div>
       
    
      </div>