<div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">New Template</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close(false)"></button>
</div>
<div class="modal-body">


    <div class="row">
        <div class="col-3 fixed">
            <label mdbLabel class="form-label" for="form1">Template Name *</label>
            <mdb-form-control>
                <input mdbInput type="text" id="form1" [(ngModel)]="templateModel.name" class="form-control" /> 
            </mdb-form-control>
            <label mdbLabel class="form-label" for="region">Select Template type</label>
            <select id="status" class="form-select " [(ngModel)]="templateModel.status" aria-label="region">
                <option [value]="true">Active</option>
                <option [value]="false">Inactive</option>
            </select> 
          
            <label for="templateModel" class="form-label">Template: </label>
            <textarea [(ngModel)]="templateModel.body" class="form-control w-100" id="templateModel" rows="15"
            value="{{templateModel.body}}"  name="html_content"> </textarea> 
        
         

        </div>

        <div class="col-9 border-start p-2" style="background-color: #F3F3F3;">
            <!-- <div *ngIf="templateModel.body?.length > 0" 
                class="preview" [innerHTML]="templateModel.body | noSanitizeHtml">                
            </div> -->

            <iframe *ngIf="templateModel.body?.length > 0" 
                height="100%" width="100%"  [srcdoc] = "templateModel.body | noSanitizeHtml"></iframe>
            <div class="preview" *ngIf="(templateModel.body?.length == 0) || !templateModel.body">
                No preview, please add HTML source code.
            </div>
        </div>
    </div>


    <div class="errors mt-2">
        <p class="text-danger text-center m-0" *ngFor="let i of formErrors">{{i}}</p>
    </div>

</div>

<div class="pt-3"></div>
<div class="modal-footer">
    <button type="button" class="btn btn-link" (click)="modalRef.close(false)">
        Close
    </button>
    <button type="button" class="btn btn-primary" (click)="submit()">Create Template</button>
</div>