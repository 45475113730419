import { Injectable } from "@angular/core";
import { BehaviorSubject, Observer } from "rxjs";
import {
    CampaignReportResponseModel, CampaignReportUserResponseModel,CampaignClientResponseModel, RegionResponseModel
} from "../report-model/report-model";
import {
    IApiServiceDelegate,
    MapAPIService,
    MapAPICollection,
} from "../../app-providers/services/map-module";
import { AlertNotificationsComponent } from "src/app/Alerts/alert-notifications/alert-notifications.component";
import { ReportsCampaignsComponent } from "../reports-campaigns/reports-campaigns.component";


@Injectable({
    providedIn: "root",
})
export class ReportViewModel {
    parentComponent: ReportsCampaignsComponent;
    // Variables to serve data to component

    campaignReportResponseModel = new BehaviorSubject<CampaignReportResponseModel>(
        new CampaignReportResponseModel()
    );

    campaignReportUserResponseModel = new BehaviorSubject<CampaignReportUserResponseModel>(
        new CampaignReportUserResponseModel()
    );

    campaignClientResponseModel = new BehaviorSubject<CampaignClientResponseModel>(
        new CampaignClientResponseModel()
    );


    regionResponseModel = new BehaviorSubject<RegionResponseModel>(
        new RegionResponseModel()
      );


    // campaignStepsResponseModel
    constructor(private apiService: MapAPIService) { }



    // Methods to subscribe all variables
    subscribeAllVariables(observerCampaignReportResponseModel: Observer<CampaignReportResponseModel>,
        observerCampaignReportUserResponseModel: Observer<CampaignReportUserResponseModel>,
        observerCampaignClientResponseModel: Observer<CampaignClientResponseModel>,
        observerRegionResponseModel: Observer<RegionResponseModel>
    ) {
        this.campaignReportResponseModel.subscribe(observerCampaignReportResponseModel);
        this.campaignReportUserResponseModel.subscribe(observerCampaignReportUserResponseModel);
        this.campaignClientResponseModel.subscribe(observerCampaignClientResponseModel);
        this.regionResponseModel.subscribe(observerRegionResponseModel);
    }

    // Method to get all clients
    getAllClients() {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_CLIENTS + "?page_size=200",
            Success: (e: any) => {

                    this.campaignClientResponseModel.next(e);
                    this.parentComponent.ClientRecieved();
                    return e;

            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading Clients', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    getRegions(){
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_REGIONS,
            Success: (e: any) => {
                this.regionResponseModel.next(e);
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading regions', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    getAllUsers() {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_ALL_USERS,
            Success: (e: any) => {
                this.campaignReportUserResponseModel.next(e);
                this.parentComponent.userRecieved();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading users', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }
    
    getCampaignReportsPayloadAndCount(returnType, createdBy,clientId, start_date, end_date, regionId, ordering){
        var count = 0
        var str = ''
        if (createdBy !== undefined && createdBy !== null && createdBy !== ""){
            count += 1;
            str = str + "&campaign__created_by__id=" + createdBy;
          }
          if (clientId !== undefined && clientId !== null && clientId > 0){
            count += 1;
            str = str + "&campaign__client__id=" + clientId;
          }
          if(start_date && end_date){
            count += 1;
            str = str + "&start_date=" + start_date + "&end_date=" + end_date;
          }
          if(regionId){
            count += 1;
            str = str + "&region=" + regionId;
          }
          if(ordering){
            count += 1;
            str = str + "&ordering=" + ordering;
          }
        if(returnType === true){
            return str
        } else {
            return count
        }
          
    }



    getCampaignReportsId(createdBy,clientId, page, start_date, end_date, regionId, ordering) {
        var str = "?page=" + page + "&page_size=30";
        // send true to get count
        str += this.getCampaignReportsPayloadAndCount(true, createdBy,clientId, start_date, end_date, regionId, ordering);
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_CAMPAIGN_REPORT_STATS_NEW + str,
            Success: (e: any) => {
                this.campaignReportResponseModel.next(e);
                this.parentComponent.CampaignReportReceived();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading campaign report data', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

}
