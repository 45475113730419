import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { MapCommon } from 'src/app/app-providers/map-base/MapCommon';
import { ProspectDncModel } from '../../setting-model/setting-model';
import { SettingViewModel } from '../../setting-view-model/setting-view-model';

@Component({
  selector: 'app-edit-prospect-dnc',
  templateUrl: './edit-prospect-dnc.component.html',
  styleUrls: ['./edit-prospect-dnc.component.scss']
})
export class EditProspectDncComponent implements OnInit {

  prospectDncModel: ProspectDncModel;
  userDeleteWarning: boolean = false;
  removeFromDncWarningCss = 'btn btn-danger ms-auto me-auto';
  currentUser: number;

  constructor(public modalRef: MdbModalRef<EditProspectDncComponent>, private vm: SettingViewModel) {
    this.currentUser = MapCommon.getloggedInUser().id;
   }

  ngOnInit(): void {
  }

  editProspectDnc(){
    this.prospectDncModel.modified_by = this.currentUser;
    this.prospectDncModel.modified_at = new Date;
    this.vm.updateProspectDnc(this.prospectDncModel);  
    this.modalRef.close();
  }

  removeFromDncWarning(){
    this.userDeleteWarning = true; 
    this.removeFromDncWarningCss = 'btn btn-outline-light ms-auto me-auto';
    
  }

  removeFromDncConfirmed(){
    this.vm.deleteProspectDnc(this.prospectDncModel);
    this.modalRef.close();
  }

  cancelWarning(){
    this.modalRef.close();
  }

}
