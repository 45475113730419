import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { CampaignModel, CheckProspectHistory,Clients} from '../model/prospect-model';
import { MapAPIService } from "src/app/app-providers/services/map-module";
import { BehaviorSubject } from 'rxjs';
import { ProspectViewModel } from '../viewModel/prospect-viewmodel';
import { ExportCampaignService } from 'src/app/admin/services/export-campaign.service';
@Component({
  selector: 'app-sefety-checks',
  templateUrl: './sefety-checks.component.html',
  styleUrls: ['./sefety-checks.component.scss']
})
export class SefetyChecksComponent implements OnInit {
  data: any = {};
  clientName: string;
  outreached_cmp_o: number =0;
  blocked_cl_o: number =0;
  blocked_cl_t: number =0;
  replied:number=0;
  bounced: number =0;
  is_ooo: number =0;
  opted_out: number =0;
  rejected_clv: number =0;
  routeAllData: any;
  totalSelectProspects: number = 0;
  finalProspect: any = [];
  actionableProspect: any = [];
  Selected_Prospect_from_campaign: number;
  ignored_Prospect_from_campaign: number;
  campaignData: any = {};
  campaignModel = new CampaignModel();
  checkProspectHistoryModel = new CheckProspectHistory();
  connected_mailbox: string;
  checkProspectHistory = new BehaviorSubject<CheckProspectHistory>(
    new CheckProspectHistory()
  );
  showLoading: boolean = false;
  componentLoading: boolean = false;

  clients = new BehaviorSubject<Clients>(
    new Clients()
  );

  constructor(public modalRef: MdbModalRef<SefetyChecksComponent>, private router: Router,
    private vm: ProspectViewModel, private exportCampaignService: ExportCampaignService) {
    // Remove these texts 
    // this.totalSelectProspects = 154;

  }

  ngOnInit(): void {
    this.showLoading = true;
    var connectedMailbox = localStorage.getItem("map_connected_mailbox");
    if (connectedMailbox !== null) {
      this.connected_mailbox = connectedMailbox;
    }
    this.campaignModel = this.campaignData;
    
    // this.Selected_Prospect_from_campaign = this.campaignModel.temp_prospect.length;
    // this.ignored_Prospect_from_campaign = this.campaignModel.temp_prospect.length;

    this.Selected_Prospect_from_campaign = this.campaignModel.total_prospect_selected;
    this.ignored_Prospect_from_campaign = this.campaignModel.total_prospect_selected;

    this.vm.parentSefetyChecksComponent = this;
    this.vm.subscribeSaftyCheckCampaignVariable(this.checkProspectHistory, this.clients);
    this.vm.getClientById(this.campaignModel.client);
    this.vm.getProspectHistory(
      this.campaignModel.prospectFilterModel, 
      this.campaignModel.id, 
      this.campaignModel.includedProspects,
      this.campaignModel.excludedProspects
      );

  }

  fillClient()
  {
    this.clientName = this.clients.value.name;
  }

  fillOtherDetails() {
    this.checkProspectHistoryModel = this.checkProspectHistory.value;
    this.rejected_clv = this.checkProspectHistoryModel.rejected_clv.length;
    this.is_ooo = this.checkProspectHistoryModel.is_ooo.length;
    this.opted_out = this.checkProspectHistoryModel.opted_out.length; 
    this.outreached_cmp_o = this.checkProspectHistoryModel.outreached_cmp_o.length;
    this.blocked_cl_o = this.checkProspectHistoryModel.blocked_cl_o.length;
    this.blocked_cl_t = this.checkProspectHistoryModel.blocked_cl_t.length;
    this.replied = this.checkProspectHistoryModel.replied.length;
    this.bounced = this.checkProspectHistoryModel.bounced.length;
    this.AddArray(this.checkProspectHistoryModel.total);
    // this.RemoveArray(this.checkProspectHistoryModel.rejected_clv);
    this.RemoveArray(this.checkProspectHistoryModel.bounced);
    this.RemoveArray(this.checkProspectHistoryModel.opted_out);
    this.RemoveArray(this.checkProspectHistoryModel.is_ooo);
    this.RemoveArray(this.checkProspectHistoryModel.blocked_cl_t);
    
    this.showLoading = false;
  }
  changeSaftyCheck(e, saftyType) {
    if (saftyType == 'OutReach' && e.target.value == 1) {
      this.AddArray(this.checkProspectHistoryModel.outreached_cmp_o);
      this.RemoveArray(this.checkProspectHistoryModel.rejected_clv);
      this.RemoveArray(this.checkProspectHistoryModel.bounced);
      this.RemoveArray(this.checkProspectHistoryModel.opted_out);
    }
    else if (saftyType == 'OutReach' && e.target.value == 0) {
      this.RemoveArray(this.checkProspectHistoryModel.outreached_cmp_o);
    }
    if (saftyType == 'clv-rejection' && e.target.value == 1) {
      this.AddArray(this.checkProspectHistoryModel.rejected_clv);
    }
    else if (saftyType == 'clv-rejection' && e.target.value == 0) {
      this.RemoveArray(this.checkProspectHistoryModel.rejected_clv);
    }

    if (saftyType == 'global-dnc' && e.target.value == 1) {
      this.AddArray(this.checkProspectHistoryModel.blocked_cl_o);
      this.RemoveArray(this.checkProspectHistoryModel.rejected_clv);
      this.RemoveArray(this.checkProspectHistoryModel.bounced);
      this.RemoveArray(this.checkProspectHistoryModel.opted_out);
    }
    else if (saftyType == 'global-dnc' && e.target.value == 0) {
      this.RemoveArray(this.checkProspectHistoryModel.blocked_cl_o);
    }
    if (saftyType == 'client-dnc' && e.target.value == 1) {
      this.AddArray(this.checkProspectHistoryModel.blocked_cl_t);
      this.RemoveArray(this.checkProspectHistoryModel.rejected_clv);
      this.RemoveArray(this.checkProspectHistoryModel.bounced);
      this.RemoveArray(this.checkProspectHistoryModel.opted_out);
    }
    else if (saftyType == 'client-dnc' && e.target.value == 0) {
      this.RemoveArray(this.checkProspectHistoryModel.blocked_cl_t);
    }
    if (saftyType == 'out_of_ofc' && e.target.value == 1) {
      this.AddArray(this.checkProspectHistoryModel.is_ooo);
      this.RemoveArray(this.checkProspectHistoryModel.rejected_clv);
      this.RemoveArray(this.checkProspectHistoryModel.bounced);
      this.RemoveArray(this.checkProspectHistoryModel.opted_out);
    }
    else if (saftyType == 'out_of_ofc' && e.target.value == 0) {
      this.RemoveArray(this.checkProspectHistoryModel.is_ooo);
    }
    if (saftyType == 'bounced' && e.target.value == 1) {
      this.AddArray(this.checkProspectHistoryModel.bounced);
    }
    else if (saftyType == 'bounced' && e.target.value == 0) {
      this.RemoveArray(this.checkProspectHistoryModel.bounced);
    }
    if (saftyType == 'replied' && e.target.value == 1) {
      this.AddArray(this.checkProspectHistoryModel.replied);
      this.RemoveArray(this.checkProspectHistoryModel.rejected_clv);
      this.RemoveArray(this.checkProspectHistoryModel.bounced);
      this.RemoveArray(this.checkProspectHistoryModel.opted_out);
    }
    else if (saftyType == 'replied' && e.target.value == 0) {
      this.RemoveArray(this.checkProspectHistoryModel.replied);
    }
    if (saftyType == 'opted_out' && e.target.value == 1) {
      this.AddArray(this.checkProspectHistoryModel.opted_out);
    }
    else if (saftyType == 'opted_out' && e.target.value == 0) {
      this.RemoveArray(this.checkProspectHistoryModel.opted_out);
    }
    
  }

  AddArray(arr) {
      for (var i = 0; i < arr.length; i++) {
        if (this.finalProspect.indexOf(arr[i]) === -1) {
          this.finalProspect.push(arr[i]);
        }      
    }
    this.totalSelectProspects = this.finalProspect.length;
    this.ignored_Prospect_from_campaign = this.Selected_Prospect_from_campaign - this.totalSelectProspects;
  }
  RemoveArray(arr) {
    for( var i=this.finalProspect.length - 1; i>=0; i--){
      for( var j=0; j<arr.length; j++){
          if(this.finalProspect[i] && (this.finalProspect[i] === arr[j])){
            this.finalProspect.splice(i, 1);
         }
       }
   }
    this.totalSelectProspects = this.finalProspect.length;
    this.ignored_Prospect_from_campaign = this.Selected_Prospect_from_campaign - this.totalSelectProspects;
  }
  updateCampaign() {
    this.componentLoading = true;
    for (var i = 0; i < this.finalProspect.length; i++) {
      if (this.campaignModel.prospect.indexOf(this.finalProspect[i]) === -1) {
        this.campaignModel.prospect.push(this.finalProspect[i]);
      }
    }
    this.vm.updateCampaign(this.campaignModel, 'prospect');
  }

  redirectAfterUpdateCampaign(campaignId) {
    this.componentLoading = false;
    this.modalRef.close();
    this.router.navigate(['/campaigns/', campaignId])
  }

  dowloadProspects(type){

    var data = []
    if(type == 'outreached_cmp_o'){
      data = this.checkProspectHistoryModel.outreached_cmp_o
    }

    if(type == 'replied'){
      data = this.checkProspectHistoryModel.replied
    }

    if(type == 'bounced'){
      data = this.checkProspectHistoryModel.bounced
    }

    if(type == 'rejected_clv'){
      data = this.checkProspectHistoryModel.rejected_clv
    }

    if(type == 'is_ooo'){
      data = this.checkProspectHistoryModel.is_ooo
    }

    if(type == 'blocked_cl_t'){
      data = this.checkProspectHistoryModel.blocked_cl_t
    }

    if(type == 'blocked_cl_o'){
      data = this.checkProspectHistoryModel.blocked_cl_o
    }

    if(type == 'opted_out'){
      data = this.checkProspectHistoryModel.opted_out
    }

    if(type == 'opted_out'){
      data = this.checkProspectHistoryModel.opted_out
    }

    if(data.length > 0){
      this.vm.downloadItems(data)
    }

  }

  // downloadReceived(data){
  //   this.exportCampaignService.downloadCSV(data, 'prospect_export.csv');
  // }

}
