import { Injectable } from "@angular/core";
import { BehaviorSubject, Observer } from "rxjs";
import { IApiServiceDelegate, MapAPIService, MapAPICollection, } from "../../app-providers/services/map-module";
import { AlertNotificationsComponent } from "src/app/Alerts/alert-notifications/alert-notifications.component";
import { ClientsComponent } from "../clients.component";
import { ClientsModel, ClientsResponseModel, ClientUserResponseModel } from "./clientModel";


@Injectable({
    providedIn: "root",
})
export class ClientsViewModel { 

    parentClients: ClientsComponent;

    clientsResponseModel = new BehaviorSubject<ClientsResponseModel>(
        new ClientsResponseModel()
    );

    clientUserResponseModel = new BehaviorSubject<ClientUserResponseModel>(
        new ClientUserResponseModel()
    );

    constructor(private apiService: MapAPIService) { }

    subscribeAllClientsVariable(
        observerClientsResponseModel: Observer<ClientsResponseModel>,
        observerUserResponseModel: Observer<ClientUserResponseModel>,
        ) {
        this.clientsResponseModel.subscribe(observerClientsResponseModel);
        this.clientUserResponseModel.subscribe(observerUserResponseModel);
    }

    getAllUsers() {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_ALL_USERS,
            Success: (e: any) => {
                this.clientUserResponseModel.next(e);
                this.parentClients.userRecieved();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading users', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    
    getAllClients(page_index,page_size) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_CLIENTS+ "?page=" + page_index + "&page_size=" + page_size,
            Success: (e: any) => {
                this.clientsResponseModel.next(e);
                this.parentClients.clientsReceived();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading clients', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    searchClients(search: string, createdBy,page_index,page_size) {
        var str="?page=" + page_index + "&page_size=" + page_size+"&";
        //optedout
        if (search !== undefined && search !== null && search !== "") {
            str = str + "name__icontains=" + search + "&";
        }
        //optedout
        if (createdBy !== undefined && createdBy !== null && createdBy > 0) {
            str = str + "created_by__id=" + createdBy + "&";
        }

        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_CLIENTS + str.slice(0, -1),
            Success: (e: any) => {
                this.clientsResponseModel.next(e);
                this.parentClients.clientsReceived();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading clients', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    addClient(clientNameModel, currentUser) {
        let d: IApiServiceDelegate = {
            Data: { "name": clientNameModel, "created_by": currentUser },
            Method: "POST",
            Action: MapAPICollection.POST_CLIENTS,
            Success: (e: any) => {
                this.getAllClients(1,30);
                AlertNotificationsComponent.alertCall.showAlert((clientNameModel + ' added successfully'), 'primary');
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Unable to add new client', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    deleteClient(clientID: number, clientName: string) {
        let d: IApiServiceDelegate = {
            Data: { "id": clientID },
            Method: "DELETE",
            Action: MapAPICollection.DELETE_CLIENTS + '/' + clientID.toString(),
            Success: (e: any) => {
                this.getAllClients(1,30);
                AlertNotificationsComponent.alertCall.showAlert((clientName + ' removed successfully'), 'primary');
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Can not remove the client', 'danger');
                return e;
            },
        };
        this.apiService.callService(d); 
    }

    editClient(clientModel: ClientsModel) {
        let d: IApiServiceDelegate = {
            Data: clientModel,
            Method: "PUT",
            Action: MapAPICollection.UPDATE_CLIENTS + '/' + clientModel.id.toString(),
            Success: (e: any) => {
                this.getAllClients(1,30);
                AlertNotificationsComponent.alertCall.showAlert((clientModel.name + ' details updated successfully'), 'primary');
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert(('Unable to update ' + clientModel.name) , 'danger');
                return e;
            },
        };
        this.apiService.callService(d); 
    }

    
}