export class CampaignModel {
    "id": number
    "name": string
    "slug": string
    "created_at": Date
    "modified_at": Date
    "status": string
    "use_personal_account":boolean
    "send_limit_daily": number
    "send_limit_used": number
    "is_deleted": boolean
    "send_mode": string
    "created_by": number
    "modified_by": number
    "client": number
    "schedule": number
    "prospect": []
    "region": number
    "campaign_provider": {}
    "senders": []
    "can_edit": boolean
}

export class CampaignResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [CampaignModel]
}

export class CampaignUserResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [CampaignUserModel]
}


export class MailboxConnectionProviderModel {
    "provider": string
    "limit_remaining": number
}

export class CampaignUserModel {
    "id": number
    "username": string
    "first_name": string
    "last_name": string
    "email": string
    "connected_mailbox_address": string
    "mailbox_connection": {
        "send_limit": [MailboxConnectionProviderModel]
        "is_connected": boolean
    }
}

export class CampaignClientResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [CampaignClientsModel]
}
export class CampaignClientsModel {
    "id": number
    "name": string
    "slug": string
    "created_at": Date
    "modified_at": null
    "cnt_campaign": number
    "is_deleted": string
    "created_by": number
    "modified_by": number
}

export class CampaignFilterModel {
    "name": string
    "name__contains": string
    "name__startswith": string
    "name__endswith": string
    "status": string
    "created_by__id": number
    "client__id": number
    "created_at_start_date": string
    "created_at_end_date": string
    "page_size": number
    "page": number
}

export class SendingWindowsModel {
    "id": number
    "name": string
    "modified_at": string
    "sunday_start_at": string
    "sunday_end_at": string
    "monday_start_at": string
    "monday_end_at": string
    "tuesday_start_at": string
    "tuesday_end_at": string
    "wednesday_start_at": string
    "wednesday_end_at": string
    "thursday_start_at": string
    "thursday_end_at": string
    "friday_start_at": string
    "friday_end_at": string
    "saturday_start_at": string
    "saturday_end_at": string
    "created_by": number
    "timezone": number
}


export class RegionResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [RegionModel]
}

export class RegionModel {
    "id": number
    "name": string
    "queue_name": string
    "slug": string
}



export class SendingWindowsResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [SendingWindowsModel]
}


export class ScheduleTimezoneModel {
    "id": number
    "name": string
    "slug": string
    "created_at": string
    "gmt_offset_minute": number
    "is_behind_gmt": boolean
    "is_deleted": boolean
    "created_by": number
}

export class CampaignStatsResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [CampaignReportStatsModel]
}

export class CampaignReportStatsModel {
    "id": number
    "stats": [CampaignStatsModel]
}

export class CampaignStatsModel {
    "cnt_total": number
    "cnt_clv_rejection": number
    "cnt_dnc_rejection": number
    "cnt_sent": number
    "cnt_bounced": number
    "cnt_reached": number
    "cnt_opened": number
    "cnt_replied": number
    "cnt_opted_out": number
    "step_stats": []
}

export class CampaignRunLogStatsResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [CampaignReportRunLogStatsModel]
}

export class CampaignReportRunLogStatsModel {
    "id": number
    "stats": [CampaignRunLogStatModel]
}

export class CampaignRunLogStatModel {
    "cnt_total": number
    "cnt_new": number
    "cnt_scheduled": number
    "cnt_processing": number
    "cnt_finished": number
    "cnt_bounced": number
    "cnt_opened": number
    "cnt_replied": number
    "cnt_opted_out": number
}


export class CampaignReplyLogStatsResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [CampaignReportReplyLogStatsModel]
}


export class  UserExtraResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [UserExtraModel]
}

export class UserExtraModel {
    "id": number
    "email_address": string
    "email_sender_name": string
    "slug": string
    "created_at": Date
    "modified_at": Date
    "send_limit_daily": number
    "email_signature": string
    "opt_out_message": string
    "is_tracking_enabled": boolean
    "is_link_tracking_enabled": boolean
    "is_opt_out_enabled": boolean
    "is_signature_enabled": boolean
    "is_deleted": boolean
    "created_by": number
    "modified_by": number
    "user": number
}

export class CampaignReportReplyLogStatsModel {
    "id": number
    "stats": [CampaignReplyLogStatModel]
}

export class CampaignReplyLogStatModel {
    "cnt_total_reply": number
    "cnt_total_auto_reply": number
    "cnt_nlw_reply": number
    "cnt_ooo_reply": number
    "step_stats": []
}

export class CampaignStepResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [CampaignStepModel]
}

export class CampaignStepAttachment {
    "id": number
    "file_name": string
    "media_file": string
    "slug": string
    "created_at": string
    "modified_at": string
    "created_by": number
    "modified_by": number
}

export class UserextraPrams {
    // "id": number;
    "is_signature_enabled": boolean;
    "is_opt_out_enabled": boolean;
    "is_tracking_enabled": boolean;
    "is_link_tracking_enabled": boolean;
}




export class CampaignStepModel {
    "id": number
    "subject": string
    "slug": string
    "modified_at": Date
    "status": string
    "type": string
    "body": string
    "ab_variants":any[]
    "ab_variant_subject": string
    "ab_variant_body": string
    "is_deleted": boolean
    "created_by": number
    "created_at": Date
    "modified_by": number
    "campaign": number
    "followup_interval_days": number
    "value": number
    "followup_from": number
    "followup_condition": number
    "sending_rate": number
    "spam_words":[string]
    "step_media": number;
    "step_media_details": CampaignStepAttachment;
    "wait_minutes": number;
    "userextraparams": UserextraPrams;
    "step_stats": {
        "emails_sent": {
            "total": number
        }
    }

}

export class ReplyModel {
    "id": number
    "subject": string
    "body": string
    "email_address_cc": []
    "email_address_bcc": []
    "cmrl_id": number
    "created_by": number
    "created_at": Date
}

export class CampaignRunLogFilterModel {
    "campaign_step__campaign__id": number
    "campaign_step__id": number
    "campaign_step__status": string
    "recipient_email_address": string
    "log_status__startswith":string
    "recipient_email_address__contains": string
    "email_sent_at__isnull": string
    "email_bounced_at__isnull": string
    "email_opened_at__isnull": string
    "email_replied_at__isnull": string
    "email_opted_out_at__isnull": string
    "auto_reply_type":string
    "is_auto_reply": boolean
    "page":number
    "page_size":number
}


export class CampaignRunLogModel {
    "id": number
    "subject": string
    "log_status": string
    "slug": string
    "created_at": Date
    "modified_at": Date
    "body": string
    "recipient_email_address": string
    "sender_email_address": string
    "sender_name": string
    "email_ext_provider": string
    "email_sent_at": Date
    "email_opened_at": Date
    "email_bounced_at": Date
    "email_opted_out_at": Date
    "email_replied_at": Date
    "is_auto_reply": boolean
    "created_by": number
    "modified_by": number
    "campaign_step": number
    "prospect": number
    "clv_runlog": number
    "rr_runlog": [ReadReplyRunlogModel]
    "prospect_interest_category": number
}

export class ReadReplyRunlogModel {
    "email_body": string
    "email_preview": string
    "id": number
    "runlog": number
    "subject": string
}


export class CampaignRunLogResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [CampaignRunLogModel]
}

export class ProspectResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [ProspectModel]
}
export class ProspectModel {
    "id": number
    "firstname": string
    "middlename": string
    "lastname": string
    "email_address": string
    "slug": string

    // "2number21-number6-number2Tnumber7:51:number9.5number3Z"
    "modified_at": Date
    "company": string
    "address": string
    "city": string
    "state": string
    "country": string
    "phone": string
    "job_title": string
    "industry": string
    "linkedin": string
    "cnt_campaign": number
    "snippet": string
    "snippet1": string
    "snippet2": string
    "snippet3": string
    "snippet4": string
    // "2number21-number6-number2Tnumber7:51:number9.5number3Z"
    "last_contacted": Date

    "cnt_email_sent": number
    "cnt_email_open": number
    "cnt_replies_received": number
    "is_opted_out": boolean
    "is_bounced": boolean
    "is_deleted": boolean
    "created_by": number
    "modified_by": number
    "status": number
    "prospect_group": [number]
    "is_Selected": boolean = false
    "addl_campaign": []
    "addl_dnc_client": []
}


// Campaign Master Stat

export class CampaignMasterStepStats {

    "id": number
    "name": string
    "created_by": number
    "total_prospect": number
    "total_emails": number
    "emails_created": number
    "emails_scheduled": number
    "emails_processing": number
    "emails_sent": {
        "total": number
        "bounced": number
        "reached": {
            "total": number
            "opened": number
            "opted_out": number
            "replied": {
                "total": number
                "auto_reply": {
                    "total": number
                    "no_longer_working": number
                    "on_vacation": number
                },
                "manual_reply": number
            }
        }
    }
    "emails_restricted": {
        "total": number
        "restricted_by_global_dnc": number
        "restricted_by_client_dnc": number
        "restricted_by_clv":  number
        "restricted_by_ooo_policy": number
    }

}

export class CampaignMasterStat {
    "id": number
    "name": string
    "created_by": number
    "stats": {
        "total_prospect": number
        "total_emails": number
        "emails_created": number
        "emails_scheduled": number
        "emails_processing": number
        "emails_sent" : {
            "total": number
            "bounced": number
            "reached": {
                "total": number
                "opted_out": number
                "opened": number
                "replied": {
                    "total": number
                    "auto_reply": {
                        "total": number
                        "no_longer_working": number
                        "on_vacation": number
                    }
                }
            }
        }
        "emails_restricted": {
            "total": number
            "restricted_by_ooo_policy": number
            "restricted_by_global_dnc": number
            "restricted_by_clv": number
            "restricted_by_client_dnc": number
        }
    }
    "step_stats": [CampaignMasterStepStats]
}



export class FollowupConditionResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [FollowupConditionModel]
}

export class FollowupConditionModel {
    "id": number
    "condition": string
    "slug": string
    "created_at": Date
    "modified_at": Date
    "created_by": number
    "modified_by": number
}



export class StepTreeNode {
    "id": number;
    "children": StepTreeNode[];
    "isExpanded"?: boolean;

    "subject": string;
    "slug": string;
    "modified_at": string;
    "status": string;
    "status_text": string;
    "type": string;
    "body": string;
    "ab_variant_subject": string;
    "ab_variant_body": string;
    "is_deleted": boolean;
    "created_by": number;
    "created_at": string;
    "modified_by": number;
    "campaign": number;
    "followup_interval_days": number;
    "followup_from": number;
    "followup_condition": number;
    "msg_seq_id": string;
    "step_media": number;
    "step_media_details": CampaignStepAttachment;
    "wait_minutes": number;
    "step_stats": {
      "id": number;
      "name": string;
      "created_by": number;
      "total_prospect": number;
      "total_emails": number;
      "emails_created": number;
      "emails_scheduled": number;
      "emails_processing": number;
      "emails_sent": {
        "total": number;
        "bounced": number;
        "reached": {
          "total": number;
          "opened": number;
          "opted_out": number;
          "replied": {
            "total": number;
            "auto_reply": {
              "total": number;
              "no_longer_working": number;
              "on_vacation": number;
            },
            "manual_reply": number;
          }
        }
      }
      "emails_restricted": {
        "total": number;
        "restricted_by_global_dnc": number;
        "restricted_by_client_dnc": number;
        "restricted_by_clv": number;
        "restricted_by_ooo_policy": number;
        "restricted_by_prospect_sl": number;

      }

    }


  }
