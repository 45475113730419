<div class="modal-header">
    <p class="modal-title">Prospects imported successfully</p>
    <!-- <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button> -->
  </div>
  <div class="modal-body">
 

    <p class="m-0"><strong>Group:</strong> {{stats?.name}}</p>
    <p class="m-0"><strong>Total Uploaded:</strong> <span class="ms-1">{{stats?.cnt_total}}</span></p>
    <p class="m-0"><strong>Prospects in DNC:</strong> <span class="ms-1" [ngClass]="stats?.cnt_dnc > 0 ? 'text-red' : 'no-class'">{{stats?.cnt_dnc}}</span></p>
    <p class="m-0"><strong>Already in MAP:</strong> <span class="ms-1" [ngClass]="stats?.cnt_duplicate > 0 ? 'text-red' : 'no-class'">{{stats?.cnt_duplicate}}</span></p>
    <p class="m-0"><strong>Total Imported:</strong> {{stats?.cnt_total - stats?.cnt_duplicate}}
      <!-- <br><span style="font-size: 10px;"><i class="far fa-question-circle"></i> Imported = Total - DNC - Duplicates</span> -->
    </p>
   
  
  
  </div>
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-link" (click)="modalRef.close()">
      Close
    </button> -->
    <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>

  </div>