<div>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Preview Email</h5> 

    <div>
      <button (click)="OnSelectIntarest(i.id)" [disabled]="(i.id === selectedIntarest)" 
    type="button" [class]="i.id === selectedIntarest ? 'btn btn-dark btn-sm me-2': 'btn btn-sm btn-light me-2'" 
    *ngFor="let i of prospectInterests">
    {{ i.id === selectedIntarest ? 'Marked as': ''}} {{i.name}}
  </button>

    <button [class]="!selectedIntarest ? 'btn btn-danger me-2 remove-button btn-sm': 'btn btn-danger btn-sm me-2'" 
    type="button" (click)="removeIntarest()">
      Remove Interest
     </button>
    </div>


    <button type="button" class="btn btn-primary" (click)="modalRef.close()">
      Close
    </button>

    <!-- <div class="d-flex justify-content-end">
     
    </div> -->
  
  </div>

  <div class="pe-2 ps-2 mt-2">
    <mdb-accordion>
      <mdb-accordion-item>
        <ng-template mdbAccordionItemHeader>
          <div class="col-12 d-flex justify-content-between">
            <div>
              <h5 class="subject-h5">Subject: <span class="subject">{{campaignEmailData.subject}}</span></h5>
              <p class="m-0"><i class="fas fa-user-circle"></i> {{campaignEmailData.recipient_email_address}}</p>
            </div>
            <div class="pt-3 pe-2">
              <span><i class="far fa-clock"></i> {{campaignEmailData.email_sent_at | date:'medium'}}</span>
            </div>
          </div>
           
        </ng-template>
        <ng-template mdbAccordionItemBody>
          <div [innerHTML]="campaignEmailData.body | noSanitizeHtml" style="background-color: #fcfcfc;" class="p-2 shadow-1"></div>
        </ng-template>
      </mdb-accordion-item>
    <div *ngFor="let item of campaignEmailData.rr_runlog">
      <mdb-accordion-item>
        <ng-template mdbAccordionItemHeader>
          <div class="col-12 d-flex justify-content-between">
            <div>
              <h5 class="subject-h5">Subject: <span class="subject">{{item.subject}}</span></h5>
              <p class="m-0"><i class="fas fa-user-circle"></i> {{campaignEmailData.recipient_email_address}}</p>
            </div>
            <div class="pt-3 pe-2">
              <span><i class="far fa-clock"></i> {{campaignEmailData.email_sent_at | date:'medium'}}</span>
            </div>
          </div>
        </ng-template>
        <ng-template mdbAccordionItemBody>
          <div [innerHTML]="item.email_body | noSanitizeHtml" style="background-color: #fcfcfc;" class="p-2 shadow-1"></div>
        </ng-template>
      </mdb-accordion-item>
    </div>    
    </mdb-accordion>
  </div>

  <!-- <div *ngIf="campaignEmailData.log_status=='Sent'">
    <button style="background-color: #EF6C00;" type="button" (click)="replyPopUp()" class="btn btn-dark btn-secondary m-2">
      <i class="fas fa-reply"></i> Reply</button>
  </div> -->

</div>