import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { MapCommon } from 'src/app/app-providers/map-base/MapCommon';
import { DomainDncModel } from '../../dnc-models/dnc-model';
import { DncViewModel } from '../../dnc-models/dnc-view-model';

@Component({
  selector: 'app-dnc-domain-edit',
  templateUrl: './dnc-domain-edit.component.html',
  styleUrls: ['./dnc-domain-edit.component.scss']
})
export class DncDomainEditComponent implements OnInit {

  domainDncModel: DomainDncModel;
  userDeleteWarning: boolean = false;
  removeFromDncWarningCss = 'btn btn-danger ms-auto me-auto';
  currentUser: number;


  constructor(public modalRef: MdbModalRef<DncDomainEditComponent>, private vm: DncViewModel) {
    this.currentUser = MapCommon.getloggedInUser().id;
  }

  ngOnInit(): void {
  }

  editDomainDnc(){
    this.domainDncModel.modified_by = this.currentUser;
    this.domainDncModel.modified_at = new Date;
    this.vm.parentDncDomainEditComponent = this;
    this.vm.updateDomainDnc(this.domainDncModel);  
  }

  removeFromDncWarning(){
    this.userDeleteWarning = true; 
    this.removeFromDncWarningCss = 'btn btn-outline-light ms-auto me-auto';
    
  }

  removeFromDncConfirmed(){
    this.vm.parentDncDomainEditComponent = this;
    this.vm.deleteDomainDnc(this.domainDncModel);
  }

  cancelWarning(){
    this.modalRef.close(false);
  }

  updateSuccess() {
    this.modalRef.close(true);
  }

  deleteSuccess() {
    this.modalRef.close(true);
  }

}
