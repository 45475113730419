<div class="ms-sm-auto" style="width: 90%;">


  <div class="row" style="height: 100%;">


    <div class="col-12 p-0 mt-2"
      style="background-color: #FBF7FE; height: 100vh; overflow-x: hidden; overflow-y: auto; scrollbar-width: thin;">


      <div class="col-12 shadow-1 p-3 sticky-top bg-white" style="z-index: 2;">
        <div class="row">

          <div class="col-6">

            <ng-container *ngIf="totalReportsRecieved else loadingTotal">
              <h4 class="map-text-header">Campaigns Report
                <span style="font-size: 12px; color: #ef6c00;">({{campaignReport?.length}}/{{totalReportsRecieved}}
                  found) </span>
              </h4>
            </ng-container>
            <ng-template #loadingTotal>
              <ng-container *ngIf="totalReportsRecieved === 0 else spinnerCampaignReport">
                <h4 class="map-text-header">Campaigns Report
                  <span class="header-count">(0 found) </span>
                </h4>
              </ng-container>
              <ng-template #spinnerCampaignReport>
                <app-spinner></app-spinner>
              </ng-template>
            </ng-template>

          </div>

          <div class="col-6">
            <div class="d-flex justify-content-end">

              <button class="btn btn-primary btn-sm" type="button" id="filterBtn" (click)="filterCollapse.toggle()"
                [attr.aria-expanded]="!filterCollapse.collapsed" aria-controls="collapseFilters">
                <i class="fas fa-filter"></i> Filters</button>

              <button class="btn btn-primary btn-sm ms-2" type="button" (click)="reportCollapse.toggle()"
                [attr.aria-expanded]="!reportCollapse.collapsed" aria-controls="collapseReport">
                <i class="fas fa-chart-line"></i> Chart</button>


            </div>

          </div>

        </div>

      </div>




      <div class="col-12"  id="collapseFilters" mdbCollapse #filterCollapse="mdbCollapse">

        <div class="row pt-3 ps-3 pe-3 pb-3">
          <div class="col-4">
            <input type="text" placeholder="Dates" class="form-control w-100"  
            [class]="user_report_dates === filterDateView ? 'dim': '' " bsDaterangepicker [bsValue]="user_report_dates"
              [bsConfig]="{ isAnimated: true, containerClass: 'theme-default', rangeInputFormat: 'DD/MM/YYYY'}"
              #dp="bsDaterangepicker"  [maxDate]="bsMaxDate" (bsValueChange)="onValueChange($event)">
            <!-- <input type="text" placeholder="Dates" class="form-control w-100" bsDaterangepicker
              [bsConfig]="{ isAnimated: true, containerClass: 'theme-default', rangeInputFormat: 'DD/MM/YYYY'}"
              #dp="bsDaterangepicker" [(ngModel)]="bsRangeValue" [maxDate]="bsMaxDate" (bsValueChange)="onValueChange($event)"> -->
          </div>

          <div class="col-4">
            <ng-select [(ngModel)]="clientId" id="clientInput" class="header-imput w-100" (change)='onclientSelected()'
              placeholder="Client">
              <ng-option *ngFor="let o of clientList" [value]="o.id">{{o.name}}</ng-option>
            </ng-select>
          </div>

          <div class="col-4">
            <ng-select [(ngModel)]="regionId" id="regionInput"
            class="header-imput w-100" (change)='onRegionSelected()' placeholder="Region">
              <ng-option *ngFor="let o of regionResponseModel.value?.results" [value]="o.id">{{o.name}}</ng-option>
              </ng-select>
          </div>

          <div class="col-12 mt-1">
            <ng-select [(ngModel)]="createdById" id="createdBy" class="header-imput w-100"
              (change)='onCreatedBySelected()' placeholder="Created By" [multiple]="true">
              <ng-option *ngFor="let o of userList" [value]="o.id">{{o.email?.length > 0 ? o.email: o.username}}
              </ng-option>
            </ng-select>
          </div>
        </div>

      </div>


      <div id="collapseReport" mdbCollapse #reportCollapse="mdbCollapse">
        <div class="col-12 pt-2 pb-2 d-flex justify-content-center">
          <div class="chartjs-container">
            <canvas height="40vh" width="80vw" baseChart [datasets]="barChartData" [labels]="barChartLabels"
              [options]="barChartOptions" [plugins]="barChartPlugins" [legend]="barChartLegend" responsive="true"
              [chartType]="barChartType">
            </canvas>
          </div>
        </div>
      </div>





      <div class="col-12" *ngIf="campaignReport.length !== 0 else loadingcampaignReport"  >

        <div #reportContainer class="table-responsive bg-white" infiniteScroll [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="100" (scrolled)="onCampaignReportScroll()" [scrollWindow]="false">
        <!-- <div #reportContainer class="table-responsive bg-white" style="max-height: 73vh;" infiniteScroll [infiniteScrollDistance]="1"
          [infiniteScrollThrottle]="100"  [scrollWindow]="false"> -->
          <table class="table table-sm table-hover">
            <thead>
              <tr>
                <th class="header-name" scope="col" (click)="sortOn(1,'last_step_date')"><i class="fas fa-sort"></i> Date</th>
                <th [class]="column_selected == 'name' ? 'header-name active' : 'header-name'" scope="col"
                  (click)="sortOn(0,'name')"><i class="fas fa-sort"></i> Campaign Name</th>
                <th [class]="column_selected == 'cnt_total' ? 'header-name active' : 'header-name'" scope="col"
                  (click)="sortOn(1,'cnt_total')"><i class="fas fa-sort"></i> Total</th>
                <th [class]="column_selected == 'cnt_sent' ? 'header-name active' : 'header-name'" scope="col"
                  (click)="sortOn(1,'cnt_sent')"><i class="fas fa-sort"></i> Contacted</th>
                <th [class]="column_selected == 'cnt_opened' ? 'header-name active' : 'header-name'" scope="col"
                  (click)="sortOn(1,'cnt_opened')"><i class="fas fa-sort"></i> Opened</th>
                <th [class]="column_selected == 'cnt_opened_percentage' ? 'header-name active' : 'header-name'"
                  scope="col" (click)="sortOn(1,'cnt_opened_percentage')"><i class="fas fa-sort"></i> Opened (%)</th>
                <th [class]="column_selected == 'cnt_replied' ? 'header-name active' : 'header-name'" scope="col"
                  (click)="sortOn(1,'cnt_replied')"><i class="fas fa-sort"></i> Replied</th>
                <th [class]="column_selected == 'cnt_replied_percentage' ? 'header-name active' : 'header-name'"
                  scope="col" (click)="sortOn(1,'cnt_replied_percentage')"><i class="fas fa-sort"></i> Replied (%)</th>
                <th [class]="column_selected == 'cnt_bounced' ? 'header-name active' : 'header-name'" scope="col"
                  (click)="sortOn(1,'cnt_bounced')"><i class="fas fa-sort"></i> Bounced</th>
                <th [class]="column_selected == 'cnt_bounced_percentage' ? 'header-name active' : 'header-name'"
                  scope="col" (click)="sortOn(1,'cnt_bounced_percentage')"><i class="fas fa-sort"></i> Bounced (%)</th>
                <th [class]="column_selected == 'cnt_opted_out' ? 'header-name active' : 'header-name'" scope="col"
                  (click)="sortOn(1,'cnt_opted_out')"><i class="fas fa-sort"></i> Opted Out</th>
                <th [class]="column_selected == 'cnt_opted_out_percentage' ? 'header-name active' : 'header-name'"
                  scope="col" (click)="sortOn(1,'cnt_opted_out_percentage')"><i class="fas fa-sort"></i> Opted Out (%)
                </th>
                <th [class]="column_selected == 'cnt_clv_rejection' ? 'header-name active' : 'header-name'" scope="col"
                  (click)="sortOn(1,'cnt_clv_rejection')"><i class="fas fa-sort"></i> CLV</th>
                <th [class]="column_selected == 'cnt_dnc_rejection' ? 'header-name active' : 'header-name'" scope="col"
                  (click)="sortOn(1,'cnt_dnc_rejection')"><i class="fas fa-sort"></i> DNC</th>
                <th [class]="column_selected == 'cnt_ooo_rejection' ? 'header-name active' : 'header-name'" scope="col"
                  (click)="sortOn(1,'cnt_ooo_rejection')"><i class="fas fa-sort"></i> OOO</th>

              </tr>
            </thead>
            <tbody>

              <tr *ngFor="let cr of campaignReport;">
                <td>{{ cr.last_step_date != null ? (cr.last_step_date | date) : "N/A" }}</td>
                <td><a routerLink="/campaigns/{{cr.campaign_id}}"><strong>{{cr.campaign_name}} </strong><span
                      style="font-size: 10px; padding-left:5px; color: #EF6C00">by
                      {{getUserName(cr.created_by)}}</span></a></td>
                <td>{{cr.data.cnt_total}}</td>
                <td>{{cr.data.cnt_sent}}</td>
                <td>{{cr.data.cnt_opened}}</td>
                <td> <span class="statpercentage">
                    {{cr.data.cnt_opened_percentage}}%</span>
                </td>
                <td>{{cr.data.cnt_replied}}</td>
                <td><span class="statpercentage">
                    {{cr.data.cnt_replied_percentage}}%</span>
                </td>
                <td>{{cr.data.cnt_bounced}}</td>
                <td> <span class="statpercentage">
                    {{cr.data.cnt_bounced_percentage}}%</span>
                </td>
                <td>{{cr.data.cnt_opted_out}}</td>
                <td><span class="statpercentage">
                    {{cr.data.cnt_opted_out_percentage}}%</span>
                </td>
                <td>{{cr.data.cnt_clv_rejection}}</td>
                <td>{{cr.data.cnt_dnc_rejection}}</td>
                <td>{{cr.data.cnt_ooo_rejection}}</td>

              </tr>

            </tbody>
          </table>

        </div>


      </div>

      <ng-template #loadingcampaignReport>

        <ng-container *ngIf="campaignReport.length === 0">
          <div class="col-12">
            <div class="row no-data me-3 ms-3 shadow-1 bg-white mt-1 p-3" style="border-radius: 5px;">
              <div class="col-12">
                <span>
                  <!-- <i *ngIf="appliedFilters === 0" class="fas fa-filter fa-3x no-data-icon" style="color:#EF6C00"></i> -->

                  <div class="d-flex justify-content-center" *ngIf="appliedFilters === 0" >
                    <span class="no-data-img" >
                      <img width="50px" class="mt-1"
                      height="auto" src="../../../assets/images/no_filter.png" alt="">
                    </span>
                  </div>
                  
                 
                  <i *ngIf="appliedFilters != 0" class="fas fa-stream fa-3x no-data-icon" style="color:#EF6C00"></i>
                </span>
                <p class="no-data-text">
                  {{appliedFilters === 0 ? 'No Filter Selected!':'No Record Found!' }}
                  <br />
                  <span>
                    {{appliedFilters === 0 ? 'Please apply some filters to populate this area':'
                    Please adjust the filters to expand the amount of data displayed.' }}
                  </span>
                </p>
              </div>
            </div>
          </div> 
        </ng-container>

     

        <!-- <ng-container *ngIf="campaignReport.length === 0 && !loadingCampaignReport else spinnerCampaignReportReceived">
          <h5 class="mt-2 font-weight-bold text-danger text-center py-5 bg-white"> Please apply some filters to populate this area. 
            No Filter selected currently.</h5>
        </ng-container> -->
        <!-- <ng-template #spinnerCampaignReportReceived>
          <app-spinner style="margin:10% 46%;display:block;width:100%"></app-spinner>
        </ng-template> -->
      </ng-template>


    </div>
  </div>


</div>
