
<div class="settings-sidebar">
  <p class="mt-4 mb-0"><strong><i class="far fa-user-circle"></i> Personal Settings</strong></p>
  <hr class="mb-0">
  <ul class="nav flex-column mb-auto">
    <!-- <li class="nav-item">
      <a class="nav-link" [routerLinkActive]="['active']" routerLink="/settings/personal">Personal</a>
    </li> -->
    <li class="nav-item">
      <a class="nav-link" [routerLinkActive]="['active']" routerLink="/settings/mailbox">Mailbox</a>
    </li>
  </ul>


  <div class="mt-2"></div>

  <p class="mt-4 mb-0"><strong><i class="far fa-building"></i> Company Settings</strong></p>
  <hr class="mb-0">
  <ul class="nav flex-column mb-auto">
    <!-- <li class="nav-item">
      <a routerLink="/settings/statuses">Prospect Statuses
      </a>
    </li> -->
    <li class="nav-item">
      <a class="nav-link" [routerLinkActive]="['active']" routerLink="/settings/groups">Prospect Groups</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [routerLinkActive]="['active']" routerLink="/settings/sending-windows">Sending Windows</a>
    </li>
  
   

  </ul>
 

  <!-- <div class="mt-2"></div>

  <p class="mt-4 mb-0"><strong><i class="fas fa-map-signs"></i> Governance Info</strong></p>
  <hr class="mb-0">
  <ul class="nav flex-column mb-auto">
    <li class="nav-item">
      <a class="nav-link" > Rules</a>
    </li>
    </ul> -->
</div> 
