import { Component, OnInit } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ProspectEditComponent } from '../prospect-edit/prospect-edit.component';
import { Router } from '@angular/router';
import { ProspectGroupResponseModel, ProspectHistoryResponseModel, ProspectModel, ProspectStatusModel, ProspectStatusResponseModel, UserModel } from '../model/prospect-model';
import { BehaviorSubject } from 'rxjs';
import { ProspectViewModel } from '../viewModel/prospect-viewmodel';
import { MapAPIService } from "src/app/app-providers/services/map-module";
import { MarkDncComponent } from '../mark-dnc/mark-dnc.component';
import { ConfirmDialogService } from '../../admin/confirm-dialog/confirm-dialog.service';
import { AddToCampaignComponent } from '../add-to-campaign/add-to-campaign.component';
@Component({
  selector: 'app-prospect-detail',
  templateUrl: './prospect-detail.component.html',
  styleUrls: ['./prospect-detail.component.scss'],
  providers: [MapAPIService, ProspectViewModel],
})
export class ProspectDetailComponent implements OnInit {

  modalRef: MdbModalRef<ProspectEditComponent>;
  modalRefC: MdbModalRef<AddToCampaignComponent>;
  data: any = {};
  routeAllData: any;
  prospectdetail: any = {};
  campaigndetail: any = [];
  dncdetail: any = [];
  prospectId: number;
  campaignBeingDeleted: number;
  statusName: string;
  prospectGrouplist = [];
  prospectStatuslist = [];
  prospect_full_name: string = ''
  modalOptions = {
    data: {
      prospectData: {},
      prospectGroupData: []
    },
    // modalClass: 'modal-lg'
  }
  static settingCall;

  prospectGroupResponseModel = new BehaviorSubject<ProspectGroupResponseModel>(
    new ProspectGroupResponseModel()
  );

  prospectModel = new BehaviorSubject<ProspectModel>(
    new ProspectModel()
  );

  prospectStatusModel = new BehaviorSubject<ProspectStatusModel>(
    new ProspectStatusModel()
  );

  prospectStatusResponseModel = new BehaviorSubject<ProspectStatusResponseModel>(
    new ProspectStatusResponseModel()
  )

  prospectHistoryResponseModel = new BehaviorSubject<ProspectHistoryResponseModel>(
    new ProspectHistoryResponseModel()
  )

  userModel = new BehaviorSubject<UserModel>(
    new UserModel()
  )

  constructor(private modalService: MdbModalService, private router: Router, private vm: ProspectViewModel,
    private confirmDialogService: ConfirmDialogService) {

    ProspectDetailComponent.settingCall = this;

    if (this.router.getCurrentNavigation().extras.state) {
      this.routeAllData = this.router.getCurrentNavigation().extras.state;
      if (this.routeAllData) {
        this.data.routeData = this.routeAllData.routeData ? JSON.parse(this.routeAllData.routeData) : '';
        this.statusName = this.routeAllData.statusName ? this.routeAllData.statusName : '';
      } else {
        // retrive id from activated route 
        // Call API
      }
    }
  }


  ngOnInit(): void {
    this.vm.parentProspectDetailComponent = this;
    this.vm.subscribeGroupVariable(this.prospectGroupResponseModel);
    this.vm.getAllProspectGroups("parentProspectDetailComponer");
    if (this.data.routeData == undefined) {
      var url = window.location.href;
      this.prospectId = Number(url.substring(url.lastIndexOf('/') + 1));
      this.vm.subscribeProspectDetailVariables(
        this.prospectModel, 
        this.prospectStatusModel,
        this.prospectStatusResponseModel,
        this.prospectHistoryResponseModel,
        this.userModel
        );
      this.vm.getAllProspectStatus('prospect-detail');
      // console.log('prospectstatus models', this.prospectStatusModel)
      this.vm.getProspectById(this.prospectId, 'prospect-detail');
    }
    else {
      this.prospectdetail = this.data.routeData.prospectData;
      this.campaigndetail = this.prospectdetail.addl_campaign;
      this.dncdetail = this.prospectdetail.addl_dnc_client;
      this.prospectId = this.prospectdetail.id;
      if (this.campaignBeingDeleted > 0) {
        this.campaigndetail.forEach((element, index) => {
          if (element.id == this.campaignBeingDeleted) this.campaigndetail.splice(index, 1);
        });
      }
    }

  }


  getLimitResetDate(last_date, duration){
    if (last_date && duration)
    var newDay = new Date(last_date);
    return newDay.setDate(newDay.getDate() + duration).toString();
  }


  openProspectEditModal() {
    // this.modalOptions.data.prospectGroupData = this.prospectGrouplist;
    // this.modalOptions.data.prospectData = Object.assign({}, this.prospectdetail);
    // this.modalRef = this.modalService.open(ProspectEditComponent, this.modalOptions);
    const prospectID = this.prospectdetail.id
    const prospectDetailUrl = '/prospects/edit/' + prospectID
    this.router.navigate([prospectDetailUrl], {
      state: {
        routeData: JSON.stringify({ prospectData: this.prospectdetail, prospectGroupData: this.prospectGrouplist, prospectAllStatusData: this.prospectStatuslist })
      }
    });
  }
  openAddToCampaignComponent() {
    this.modalRefC = this.modalService.open(AddToCampaignComponent);
  }
  ProspectGroupReceived() {
    this.prospectGrouplist = this.prospectGroupResponseModel.value.results;
  }

  ProspectStatusDetailReceived() {
    this.statusName = this.prospectStatusModel.value?.name;
  }
  ProspectDetailReceived() {
    this.prospectdetail = this.prospectModel.value;
    this.prospect_full_name = String(this.prospectdetail.firstname + ' ' + this.prospectdetail.lastname)
    this.campaigndetail = this.prospectdetail.addl_campaign;
    this.dncdetail = this.prospectdetail.addl_dnc_client;
    this.vm.getProspectStatusById(this.prospectdetail.status);
    this.vm.getProspectJourneyHistory(this.prospectId);
    this.vm.getUserById(this.prospectdetail.created_by);
  }

  getProspectGroupName(id) {
    var groupItem = this.prospectGrouplist.filter(i => i.id == Number(id));
    if (groupItem.length > 0) {
      return groupItem[0].name;
    }
  }

  getStatusName(id) {
    var statusItem = this.prospectStatuslist.filter(i => i.id == Number(id));
    if (statusItem.length > 0) {
      return statusItem[0].name;
    }
  }


  markDNC() {
    this.modalOptions.data.prospectData = this.prospectdetail;
    this.modalRef = this.modalService.open(MarkDncComponent, this.modalOptions)
  }

  prospectStatusesReceived(){
    this.prospectStatuslist = this.prospectStatusResponseModel.value.results
  }

  removeGroupFromProspect(groupId: number) {
    var newProspectModel = new ProspectModel;
    newProspectModel = JSON.parse(JSON.stringify(this.prospectdetail));
    const index = newProspectModel.prospect_group.indexOf(groupId);
    if (index > -1) {
      newProspectModel.prospect_group.splice(index, 1);
    }
    this.vm.updateProspectGroup(newProspectModel);
    this.vm.getProspectById(this.prospectId, 'prospect-detail');
  }

  removeProspectFromCampaign(campaignId) {
    this.campaignBeingDeleted = campaignId;
    this.vm.removeProspectFromCampaign(this.prospectId, campaignId);
  }

  removeProspectGroupConf(groupId: number) {
    this.confirmDialogService.confirmThis("Are you sure you want to remove prospect group?", function () {
      ProspectDetailComponent.settingCall.removeGroupFromProspect(groupId);
    }, function () {
    })
  }

  deleteCampaign(id) {
    this.confirmDialogService.confirmThis("Are you sure you want to remove prospect from campaign?", function () {
      ProspectDetailComponent.settingCall.removeProspectFromCampaign(id);
    }, function () {
    })
  }

  // deleteDNC(input) {
  //   this.confirmDialogService.confirmThis("Are you sure to remove dnc client (" + input.client__name + ")?", function () {
  //     alert("dnc client will be removed");
  //   }, function () {
  //   })
  // }


}
