import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ReleaseNoteModel, ReleaseNoteResponseModel } from '../help-models/help-model';
import { BehaviorSubject } from 'rxjs';
import { ReleaseNoteViewModel } from '../help-models/help-view-model';


@Component({
  selector: 'app-help-about',
  templateUrl: './help-about.component.html',
  styleUrls: ['./help-about.component.scss']
})
export class HelpAboutComponent implements OnInit {

  currentVersion: string;
  releaseNoteList = []
  page_index = 1;
  releaseNoteResponseModel = new BehaviorSubject<ReleaseNoteResponseModel>(
    new ReleaseNoteResponseModel()
  );

  constructor(private vm: ReleaseNoteViewModel) {
    this.currentVersion = environment.platform_version;   }

  ngOnInit(): void {
    this.vm.parentHelpAboutComponent = this;
    this.vm.subscribeReleaseNoteResponseVariable(this.releaseNoteResponseModel)
    this.vm.getAllReleaseNote(this.page_index);
  }

  releaseNoteReceived(){
    this.releaseNoteList = this.releaseNoteResponseModel.value.results;
  }

}
