import { Injectable } from "@angular/core";
import { BehaviorSubject, Observer } from "rxjs";
import {
    ProspectModel, ProspectResponseModel, ProspectStatusResponseModel,
    ProspectUserResponseModel, ProspectGroupResponseModel, ClientResponseModel, DNCModel,
    CampaignResponseModel, CampaignModel, ProspectFilterModel, ProspectStatusModel, CheckProspectHistory, Clients, ProspectHistoryResponseModel, UserModel
} from "../model/prospect-model";
import { ProspectsComponent } from "../prospects.component";
import { SidebarProspectsComponent } from "../sidebar-prospects/sidebar-prospects.component";
import {
    IApiServiceDelegate,
    MapAPIService,
    MapAPICollection,
} from "../../app-providers/services/map-module";
import { AlertNotificationsComponent } from "src/app/Alerts/alert-notifications/alert-notifications.component";
import { ProspectDetailComponent } from "../prospect-detail/prospect-detail.component";
import { MarkDncComponent } from "../mark-dnc/mark-dnc.component";
import { AddToCampaignComponent } from "../add-to-campaign/add-to-campaign.component";
import { ProspectEditComponent } from "../prospect-edit/prospect-edit.component";
import { MapCommon } from "src/app/app-providers/map-base/MapCommon";
import { SefetyChecksComponent } from "../sefety-checks/sefety-checks.component";
import { HttpHeaders } from "@angular/common/http";
@Injectable({
    providedIn: "root",
})
export class ProspectViewModel {
    parentComponent: ProspectsComponent;
    parentSidebarProspectsComponent: SidebarProspectsComponent;
    parentProspectDetailComponent: ProspectDetailComponent;
    parentMarkDncComponent: MarkDncComponent;
    parentAddToCampaignComponent: AddToCampaignComponent;
    parentProspectEditComponent: ProspectEditComponent;
    parentSefetyChecksComponent: SefetyChecksComponent
    // Variables to serve data to component

    prospectResponseModel = new BehaviorSubject<ProspectResponseModel>(
        new ProspectResponseModel()
    );
    checkProspectHistory = new BehaviorSubject<CheckProspectHistory>(
        new CheckProspectHistory()
    );
    prospectStatusResponseModel = new BehaviorSubject<ProspectStatusResponseModel>(
        new ProspectStatusResponseModel()
    );
    prospectUserResponseModel = new BehaviorSubject<ProspectUserResponseModel>(
        new ProspectUserResponseModel()
    );
    prospectGroupResponseModel = new BehaviorSubject<ProspectGroupResponseModel>(
        new ProspectGroupResponseModel()
    );

    clientResponseModel = new BehaviorSubject<ClientResponseModel>(
        new ClientResponseModel()
    );

    campaignResponseModel = new BehaviorSubject<CampaignResponseModel>(
        new CampaignResponseModel()
    );

    sideBarUserResponseModel = new BehaviorSubject<ProspectUserResponseModel>(
        new ProspectUserResponseModel()
    );

    sideBarStatusResponseModel = new BehaviorSubject<ProspectStatusResponseModel>(
        new ProspectStatusResponseModel()
    );

    sideBarProspectGroupResponseModel = new BehaviorSubject<ProspectGroupResponseModel>(
        new ProspectGroupResponseModel()
    );

    prospectDetailsProspectModel = new BehaviorSubject<ProspectModel>(
        new ProspectModel()
    );
    prospectEditProspectModel = new BehaviorSubject<ProspectModel>(
        new ProspectModel()
    );
    prospectDetailsProspectStatusModel = new BehaviorSubject<ProspectStatusModel>(
        new ProspectStatusModel()
    );
    clients = new BehaviorSubject<Clients>(
        new Clients()
    );

    prospectHistoryResponseModel = new BehaviorSubject<ProspectHistoryResponseModel>(
        new ProspectHistoryResponseModel()
    )

    userModel = new BehaviorSubject<UserModel>(
        new UserModel()
    )


    constructor(private apiService: MapAPIService) { }



    // Methods to subscribe all variables
    subscribeAllVariables(observerProspectResponseModel: Observer<ProspectResponseModel>,
        observerProspectStatusResponseModel: Observer<ProspectStatusResponseModel>,
        observerProspectUserResponseModel: Observer<ProspectUserResponseModel>,
    ) {
        this.prospectResponseModel.subscribe(observerProspectResponseModel)
        this.prospectStatusResponseModel.subscribe(observerProspectStatusResponseModel)
        this.prospectUserResponseModel.subscribe(observerProspectUserResponseModel)

    }
    subscribeGroupVariable(observerProspectGroupResponseModel: Observer<ProspectGroupResponseModel>,) {
        this.prospectGroupResponseModel.subscribe(observerProspectGroupResponseModel)
    }
    subscribeClientVariable(observerClientResponseModel: Observer<ClientResponseModel>,) {
        this.clientResponseModel.subscribe(observerClientResponseModel)
    }

    subscribeCampaignVariable(observerCampaignResponseModel: Observer<CampaignResponseModel>,) {
        this.campaignResponseModel.subscribe(observerCampaignResponseModel)
    }
    subscribeSaftyCheckCampaignVariable(observerCheckProspectHistory: Observer<CheckProspectHistory>,
        observerClients: Observer<Clients>) {
        this.checkProspectHistory.subscribe(observerCheckProspectHistory)
        this.clients.subscribe(observerClients)

    }
    subscribeUserVariables(observerProspectUserResponseModel: Observer<ProspectUserResponseModel>,) {
        this.sideBarUserResponseModel.subscribe(observerProspectUserResponseModel)
    }
    subscribeStatusVariables(observerSideBarUserResponseModel: Observer<ProspectStatusResponseModel>,) {
        this.sideBarStatusResponseModel.subscribe(observerSideBarUserResponseModel)
    }
    subscribeGroupVariables(observerProspectGroupResponseModel: Observer<ProspectGroupResponseModel>,) {
        this.sideBarProspectGroupResponseModel.subscribe(observerProspectGroupResponseModel)
    }

    subscribeProspectDetailVariables(
        observerProspectModel: Observer<ProspectModel>,
        observerProspectStatusModel: Observer<ProspectStatusModel>,
        observerProspectStatusResponseModel: Observer<ProspectStatusResponseModel>,
        observerProspectHistoryModel: Observer<ProspectHistoryResponseModel>,
        observerUserModel: Observer<UserModel>
        ) {
        this.prospectDetailsProspectModel.subscribe(observerProspectModel);
        this.prospectDetailsProspectStatusModel.subscribe(observerProspectStatusModel);
        this.prospectStatusResponseModel.subscribe(observerProspectStatusResponseModel);
        this.prospectHistoryResponseModel.subscribe(observerProspectHistoryModel);
        this.userModel.subscribe(observerUserModel);

    }

    subscribeProspectEditDetailVariables(observerProspectModel: Observer<ProspectModel>,) {
        this.prospectEditProspectModel.subscribe(observerProspectModel)
    }

    getProspectData(prospectFilterObj: ProspectFilterModel) {
        var str = "?";


        //email_address
        if (prospectFilterObj.email_address__icontains !== undefined && prospectFilterObj.email_address__icontains !== null) {
            str = str + "email_address__icontains=" + prospectFilterObj.email_address__icontains + "&";
        }
        if (prospectFilterObj.email_address__endswith !== undefined && prospectFilterObj.email_address__endswith !== null) {
            str = str + "email_address__endswith=" + prospectFilterObj.email_address__endswith + "&";
        }
        if (prospectFilterObj.email_address__startswith !== undefined && prospectFilterObj.email_address__startswith !== null) {
            str = str + "email_address__startswith=" + prospectFilterObj.email_address__startswith + "&";
        }
        if (prospectFilterObj.email_address !== undefined && prospectFilterObj.email_address !== null) {
            str = str + "email_address=" + prospectFilterObj.email_address + "&";
        }

        // firstname
        if (prospectFilterObj.firstname__icontains !== undefined && prospectFilterObj.firstname__icontains !== null) {
            str = str + "firstname__icontains=" + prospectFilterObj.firstname__icontains + "&";
        }
        if (prospectFilterObj.firstname__endswith !== undefined && prospectFilterObj.firstname__endswith !== null) {
            str = str + "firstname__endswith=" + prospectFilterObj.firstname__endswith + "&";
        }
        if (prospectFilterObj.firstname__startswith !== undefined && prospectFilterObj.firstname__startswith !== null) {
            str = str + "firstname__startswith=" + prospectFilterObj.firstname__startswith + "&";
        }

        // lastname
        if (prospectFilterObj.lastname__icontains !== undefined && prospectFilterObj.lastname__icontains !== null) {
            str = str + "lastname__icontains=" + prospectFilterObj.lastname__icontains + "&";
        }
        if (prospectFilterObj.lastname__endswith !== undefined && prospectFilterObj.lastname__endswith !== null) {
            str = str + "lastname__endswith=" + prospectFilterObj.lastname__endswith + "&";
        }
        if (prospectFilterObj.lastname__startswith !== undefined && prospectFilterObj.lastname__startswith !== null) {
            str = str + "lastname__startswith=" + prospectFilterObj.lastname__startswith + "&";
        }

        //company
        if (prospectFilterObj.company__icontains !== undefined && prospectFilterObj.company__icontains !== null) {
            str = str + "company__icontains=" + prospectFilterObj.company__icontains + "&";
        }
        if (prospectFilterObj.company__endswith !== undefined && prospectFilterObj.company__endswith !== null) {
            str = str + "company__endswith=" + prospectFilterObj.company__endswith + "&";
        }
        if (prospectFilterObj.company__startswith !== undefined && prospectFilterObj.company__startswith !== null) {
            str = str + "company__startswith=" + prospectFilterObj.company__startswith + "&";
        }
        if (prospectFilterObj.company__isnull !== undefined && prospectFilterObj.company__isnull !== null) {
            str = str + "company__isnull=" + prospectFilterObj.company__isnull + "&";
        }

        //industry
        if (prospectFilterObj.industry__icontains !== undefined && prospectFilterObj.industry__icontains !== null) {
            str = str + "industry__icontains=" + prospectFilterObj.industry__icontains + "&";
        }
        if (prospectFilterObj.industry__endswith !== undefined && prospectFilterObj.industry__endswith !== null) {
            str = str + "industry__endswith=" + prospectFilterObj.industry__endswith + "&";
        }
        if (prospectFilterObj.industry__startswith !== undefined && prospectFilterObj.industry__startswith !== null) {
            str = str + "industry__startswith=" + prospectFilterObj.industry__startswith + "&";
        }
        if (prospectFilterObj.industry__isnull !== undefined && prospectFilterObj.industry__isnull !== null) {
            str = str + "industry__isnull=" + prospectFilterObj.industry__isnull + "&";
        }

        //phone
        if (prospectFilterObj.phone__icontains !== undefined && prospectFilterObj.phone__icontains !== null) {
            str = str + "phone__icontains=" + prospectFilterObj.phone__icontains + "&";
        }
        if (prospectFilterObj.phone__endswith !== undefined && prospectFilterObj.phone__endswith !== null) {
            str = str + "phone__endswith=" + prospectFilterObj.phone__endswith + "&";
        }
        if (prospectFilterObj.phone__startswith !== undefined && prospectFilterObj.phone__startswith !== null) {
            str = str + "phone__startswith=" + prospectFilterObj.phone__startswith + "&";
        }
        if (prospectFilterObj.phone__isnull !== undefined && prospectFilterObj.phone__isnull !== null) {
            str = str + "phone__isnull=" + prospectFilterObj.phone__isnull + "&";
        }

        //job_title
        if (prospectFilterObj.job_title__icontains !== undefined && prospectFilterObj.job_title__icontains !== null) {
            str = str + "job_title__icontains=" + prospectFilterObj.job_title__icontains + "&";
        }
        if (prospectFilterObj.job_title__endswith !== undefined && prospectFilterObj.job_title__endswith !== null) {
            str = str + "job_title__endswith=" + prospectFilterObj.job_title__endswith + "&";
        }
        if (prospectFilterObj.job_title__startswith !== undefined && prospectFilterObj.job_title__startswith !== null) {
            str = str + "job_title__startswith=" + prospectFilterObj.job_title__startswith + "&";
        }
        if (prospectFilterObj.job_title__isnull !== undefined && prospectFilterObj.job_title__isnull !== null) {
            str = str + "job_title__isnull=" + prospectFilterObj.job_title__isnull + "&";
        }

        //city
        if (prospectFilterObj.city__icontains !== undefined && prospectFilterObj.city__icontains !== null) {
            str = str + "city__icontains=" + prospectFilterObj.city__icontains + "&";
        }
        if (prospectFilterObj.city__endswith !== undefined && prospectFilterObj.city__endswith !== null) {
            str = str + "city__endswith=" + prospectFilterObj.city__endswith + "&";
        }
        if (prospectFilterObj.city__startswith !== undefined && prospectFilterObj.city__startswith !== null) {
            str = str + "city__startswith=" + prospectFilterObj.city__startswith + "&";
        }
        if (prospectFilterObj.city__isnull !== undefined && prospectFilterObj.city__isnull !== null) {
            str = str + "city__isnull=" + prospectFilterObj.city__isnull + "&";
        }

        //state
        if (prospectFilterObj.state__icontains !== undefined && prospectFilterObj.state__icontains !== null) {
            str = str + "state__icontains=" + prospectFilterObj.state__icontains + "&";
        }
        if (prospectFilterObj.state__endswith !== undefined && prospectFilterObj.state__endswith !== null) {
            str = str + "state__endswith=" + prospectFilterObj.state__endswith + "&";
        }
        if (prospectFilterObj.state__startswith !== undefined && prospectFilterObj.state__startswith !== null) {
            str = str + "state__startswith=" + prospectFilterObj.state__startswith + "&";
        }
        if (prospectFilterObj.state__isnull !== undefined && prospectFilterObj.state__isnull !== null) {
            str = str + "state__isnull=" + prospectFilterObj.state__isnull + "&";
        }

        //region
        if (prospectFilterObj.region__icontains !== undefined && prospectFilterObj.region__icontains !== null) {
            str = str + "region__icontains=" + prospectFilterObj.region__icontains + "&";
        }
        if (prospectFilterObj.region__endswith !== undefined && prospectFilterObj.region__endswith !== null) {
            str = str + "region__endswith=" + prospectFilterObj.region__endswith + "&";
        }
        if (prospectFilterObj.region__startswith !== undefined && prospectFilterObj.region__startswith !== null) {
            str = str + "region__startswith=" + prospectFilterObj.region__startswith + "&";
        }
        if (prospectFilterObj.region__isnull !== undefined && prospectFilterObj.region__isnull !== null) {
            str = str + "region__isnull=" + prospectFilterObj.region__isnull + "&";
        }

        //country
        if (prospectFilterObj.country__icontains !== undefined && prospectFilterObj.country__icontains !== null) {
            str = str + "country__icontains=" + prospectFilterObj.country__icontains + "&";
        }
        if (prospectFilterObj.country__endswith !== undefined && prospectFilterObj.country__endswith !== null) {
            str = str + "country__endswith=" + prospectFilterObj.country__endswith + "&";
        }
        if (prospectFilterObj.country__startswith !== undefined && prospectFilterObj.country__startswith !== null) {
            str = str + "country__startswith=" + prospectFilterObj.country__startswith + "&";
        }
        if (prospectFilterObj.country__isnull !== undefined && prospectFilterObj.country__isnull !== null) {
            str = str + "country__isnull=" + prospectFilterObj.country__isnull + "&";
        }


        //address
        if (prospectFilterObj.address__icontains !== undefined && prospectFilterObj.address__icontains !== null) {
            str = str + "address__icontains=" + prospectFilterObj.address__icontains + "&";
        }
        if (prospectFilterObj.address__endswith !== undefined && prospectFilterObj.address__endswith !== null) {
            str = str + "address__endswith=" + prospectFilterObj.address__endswith + "&";
        }
        if (prospectFilterObj.address__startswith !== undefined && prospectFilterObj.address__startswith !== null) {
            str = str + "address__startswith=" + prospectFilterObj.address__startswith + "&";
        }
        if (prospectFilterObj.address__isnull !== undefined && prospectFilterObj.address__isnull !== null) {
            str = str + "address__isnull=" + prospectFilterObj.address__isnull + "&";
        }

        //linkedin
        if (prospectFilterObj.linkedin__icontains !== undefined && prospectFilterObj.linkedin__icontains !== null) {
            str = str + "linkedin__icontains=" + prospectFilterObj.linkedin__icontains + "&";
        }
        if (prospectFilterObj.linkedin__endswith !== undefined && prospectFilterObj.linkedin__endswith !== null) {
            str = str + "linkedin__endswith=" + prospectFilterObj.linkedin__endswith + "&";
        }
        if (prospectFilterObj.linkedin__startswith !== undefined && prospectFilterObj.linkedin__startswith !== null) {
            str = str + "linkedin__startswith=" + prospectFilterObj.linkedin__startswith + "&";
        }
        if (prospectFilterObj.linkedin__isnull !== undefined && prospectFilterObj.linkedin__isnull !== null) {
            str = str + "linkedin__isnull=" + prospectFilterObj.linkedin__isnull + "&";
        }

        //snippets
        if (prospectFilterObj.snippet__icontains !== undefined && prospectFilterObj.snippet__icontains !== null) {
            str = str + "snippet__icontains=" + prospectFilterObj.snippet__icontains + "&";
        }
        if (prospectFilterObj.snippet1__icontains !== undefined && prospectFilterObj.snippet1__icontains !== null) {
            str = str + "snippet1__icontains=" + prospectFilterObj.snippet1__icontains + "&";
        }
        if (prospectFilterObj.snippet2__icontains !== undefined && prospectFilterObj.snippet2__icontains !== null) {
            str = str + "snippet2__icontains=" + prospectFilterObj.snippet2__icontains + "&";
        }
        if (prospectFilterObj.snippet3__icontains !== undefined && prospectFilterObj.snippet3__icontains !== null) {
            str = str + "snippet3__icontains=" + prospectFilterObj.snippet3__icontains + "&";
        }
        if (prospectFilterObj.snippet4__icontains !== undefined && prospectFilterObj.snippet4__icontains !== null) {
            str = str + "snippet4__icontains=" + prospectFilterObj.snippet4__icontains + "&";
        }

        //created_by
        if (prospectFilterObj.created_by__id !== undefined && prospectFilterObj.created_by__id !== null && prospectFilterObj.created_by__id !== 0) {
            str = str + "created_by__id=" + prospectFilterObj.created_by__id + "&";
        }

        //statusId
        if (prospectFilterObj.status__id !== undefined && prospectFilterObj.status__id !== null && prospectFilterObj.status__id !== 0) {
            str = str + "status__id=" + prospectFilterObj.status__id + "&";
        }

        //prospect_group
        if (prospectFilterObj.prospect_group__name !== undefined && prospectFilterObj.prospect_group__name !== null) {
            str = str + "prospect_group__name=" + prospectFilterObj.prospect_group__name + "&";
        }

        //campaign_count
        if (prospectFilterObj.campaign__neq !== undefined && prospectFilterObj.campaign__neq !== null) {
            str = str + "campaign__neq=" + prospectFilterObj.campaign__neq + "&";
        }
        if (prospectFilterObj.campaign__eq !== undefined && prospectFilterObj.campaign__eq !== null) {
            str = str + "campaign__eq=" + prospectFilterObj.campaign__eq + "&";
        }

        if(prospectFilterObj.interested_category !== undefined && prospectFilterObj.interested_category !== null) {
            str = str + "interested_category=" + prospectFilterObj.interested_category + "&";
        }

        // Auto reply
        if(prospectFilterObj.is_auto_reply !== undefined && prospectFilterObj.is_auto_reply !== null) {
            str = str + "is_auto_reply=" + prospectFilterObj.is_auto_reply + "&";
        }


        //cnt_replies_received
        if (prospectFilterObj.cnt_replies_received !== undefined && prospectFilterObj.cnt_replies_received !== null) {
            str = str + "cnt_replies_received=" + prospectFilterObj.cnt_replies_received + "&";
        }
        if (prospectFilterObj.cnt_replies_received__gt !== undefined && prospectFilterObj.cnt_replies_received__gt !== null) {
            str = str + "cnt_replies_received__gt=" + prospectFilterObj.cnt_replies_received__gt + "&";
        }
        if (prospectFilterObj.cnt_replies_received__lt !== undefined && prospectFilterObj.cnt_replies_received__lt !== null) {
            str = str + "cnt_replies_received__lt=" + prospectFilterObj.cnt_replies_received__lt + "&";
        }

        //cnt_email_open
        if (prospectFilterObj.cnt_email_open !== undefined && prospectFilterObj.cnt_email_open !== null) {
            str = str + "cnt_email_open=" + prospectFilterObj.cnt_email_open + "&";
        }
        if (prospectFilterObj.cnt_email_open__gt !== undefined && prospectFilterObj.cnt_email_open__gt !== null) {
            str = str + "cnt_email_open__gt=" + prospectFilterObj.cnt_email_open__gt + "&";
        }
        if (prospectFilterObj.cnt_email_open__lt !== undefined && prospectFilterObj.cnt_email_open__lt !== null) {
            str = str + "cnt_email_open__lt=" + prospectFilterObj.cnt_email_open__lt + "&";
        }

        //last contacted
        if (prospectFilterObj.last_contacted_period__gt !== undefined && prospectFilterObj.last_contacted_period__gt !== null) {
            str = str + "last_contacted_period__gt=" + prospectFilterObj.last_contacted_period__gt + "&";
        }
        if (prospectFilterObj.last_contacted_period__lt !== undefined && prospectFilterObj.last_contacted_period__lt !== null) {
            str = str + "last_contacted_period__lt=" + prospectFilterObj.last_contacted_period__lt + "&";
        }
        if (prospectFilterObj.last_contacted_period__eq !== undefined && prospectFilterObj.last_contacted_period__eq !== null) {
            str = str + "last_contacted_period__eq=" + prospectFilterObj.last_contacted_period__eq + "&";
        }

        //cnt_email_sent
        if (prospectFilterObj.cnt_email_sent !== undefined && prospectFilterObj.cnt_email_sent !== null) {
            str = str + "cnt_email_sent=" + prospectFilterObj.cnt_email_sent + "&";
        }
        if (prospectFilterObj.cnt_email_sent__gt !== undefined && prospectFilterObj.cnt_email_sent__gt !== null) {
            str = str + "cnt_email_sent__gt=" + prospectFilterObj.cnt_email_sent__gt + "&";
        }
        if (prospectFilterObj.cnt_email_sent__lt !== undefined && prospectFilterObj.cnt_email_sent__lt !== null) {
            str = str + "cnt_email_sent__lt=" + prospectFilterObj.cnt_email_sent__lt + "&";
        }

        // Deleted
        if(prospectFilterObj.is_deleted){
            str = str + "is_deleted=" + String(true) + "&";
        }

        //score
        if (prospectFilterObj.score__gt !== undefined && prospectFilterObj.score__gt !== null) {
            str = str + "score__gt=" + prospectFilterObj.score__gt + "&";
        }
        if (prospectFilterObj.score__lt !== undefined && prospectFilterObj.score__lt !== null) {
            str = str + "score__lt=" + prospectFilterObj.score__lt + "&";
        }

        //bounces
        if (prospectFilterObj.is_bounced !== undefined && prospectFilterObj.is_bounced !== null) {
            str = str + "is_bounced=" + prospectFilterObj.is_bounced + "&";
        }
        //optedout
        if (prospectFilterObj.is_opted_out !== undefined && prospectFilterObj.is_opted_out !== null) {
            str = str + "is_opted_out=" + prospectFilterObj.is_opted_out + "&";
        }

        str = str + "page=" + prospectFilterObj.page + "&page_size=" + prospectFilterObj.page_size;

        return str;
    }


    deleteProspect(prospectFilterObj: ProspectFilterModel, includedProspects=null, excludedProspects=null) {
        var str = this.getProspectData(prospectFilterObj);
        str = this.getIncludedAndExcludedProspectData(str, includedProspects, excludedProspects);
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.DELETE_BULK_PROSPECT + str,
            Success: (e: any) => {
                AlertNotificationsComponent.alertCall.showAlert('Prospect(s) deleted successfully', 'primary');
                this.parentComponent.callFilter(prospectFilterObj);
                this.parentComponent.clearDeleteLoading();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while deleting prospect', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    // Method to all prospect
    getAllProspect(prospectFilterObj: ProspectFilterModel) {
        var str = this.getProspectData(prospectFilterObj);
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_PROSPECTS_V2 + str,
            Success: (e: any) => {
                this.prospectResponseModel.next(e);
                this.parentComponent.ProspectRecieved();
                return e;
            },
            Error: (e: String) => {
                this.parentComponent.clearLoading();
                AlertNotificationsComponent.alertCall.showAlert('Error while loading prospects', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    getAllProspectAfterFIlter(prospectFilterObj: ProspectFilterModel,ordering:any) {
      var str = this.getProspectData(prospectFilterObj);
      let d: IApiServiceDelegate = {
          Data: {},
          Method: "GET",
          Action: MapAPICollection.GET_PROSPECTS_V2 + str + '&ordering='+ordering,
          Success: (e: any) => {
              this.prospectResponseModel.next(e);
              this.parentComponent.ProspectRecieved();
              return e;
          },
          Error: (e: String) => {
              this.parentComponent.clearLoading();
              AlertNotificationsComponent.alertCall.showAlert('Error while loading prospects', 'danger');
              return e;
          },
      };
      this.apiService.callService(d);
  }


    // Method to export prospect
    exportFilterdProspects(prospectFilterObj: ProspectFilterModel, selectedProspectlist: number, includedProspects=null, excludedProspects=null) {
        prospectFilterObj.page_size = selectedProspectlist;
        prospectFilterObj.page = 1;
        var str = this.getProspectData(prospectFilterObj);
        str = this.getIncludedAndExcludedProspectData(str, includedProspects, excludedProspects);
        var completeUrl = MapAPICollection.EXPORT_PROSPECTS + str

        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: completeUrl,
            Success: (e: any) => {
                this.parentComponent.prospectExported();
                AlertNotificationsComponent.alertCall.showAlert('We have received your request, please check your inbox', 'primary');
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while exporting prospects', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);

        // let httpHeaders = new HttpHeaders();
        // httpHeaders.append("Accept", "application/json");
        // this.apiService.downloadFile(completeUrl, httpHeaders).subscribe((e: any) => {
        //     this.parentComponent.prospectExported(e.res.body);
        // },
        // (error: any) => {
        //     AlertNotificationsComponent.alertCall.showAlert('Error while exporting prospects', 'danger');
        //     return error;
        //   }

        // );
    }

    // Method to all prospect
    getAllCampaign(seachStr, caller) {
        var str = "?page=1&page_size=5"
        if(seachStr?.length > 0){
            str += "&name__icontains=" + seachStr
        }
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_CAMPAIGN + str,
            Success: (e: any) => {
                this.campaignResponseModel.next(e);
                if (caller == 'prospect')
                    this.parentComponent.CampaignReceived();
                if (caller == 'sidebar')
                    this.parentSidebarProspectsComponent.CampaignReceived();
                if (caller == 'prospect-add-to-capaign')
                    this.parentAddToCampaignComponent.CampaignReceived();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading prospects', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }
    // to create put data for prospect
    getCampaignPutData(objCampaign: CampaignModel) {
        var jsonPostData = {
            "name": objCampaign.name,
            "modified_at": new Date(),
            "status": objCampaign.status,
            "send_limit_daily": objCampaign.send_limit_daily,
            "modified_by": MapCommon.getloggedInUser().id,
            "client": objCampaign.client,
            "schedule": objCampaign.schedule,
            "prospect": objCampaign.prospect
        }
        return jsonPostData;
    }
    updateCampaign(objCampaign: CampaignModel, caller) {
        let d: IApiServiceDelegate = {
            Data: this.getCampaignPutData(objCampaign),
            Method: "PATCH",
            Action: MapAPICollection.UPDATE_CAMPAIGN.replace("{id}", objCampaign.id.toString()),
            Success: (e: any) => {
                if (caller === 'prospect-add-to-capaign')
                    this.parentAddToCampaignComponent.onSuccessClosePopUp();
                if (caller === 'prospect') {
                    this.parentSefetyChecksComponent.redirectAfterUpdateCampaign(objCampaign.id);
                }
                AlertNotificationsComponent.alertCall.showAlert('Prospect(s) added in campaign successfully', 'primary');
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while adding prospects in campaign', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    // getProspectHistory(prospectArr, campaignId) {
    //     let d: IApiServiceDelegate = {
    //         Data: { "check_prospects": prospectArr },
    //         Method: "PUT",
    //         Action: MapAPICollection.CHECK_PROSPECT_HISTORY.replace("{id}", campaignId.toString()),
    //         Success: (e: any) => {
    //             this.checkProspectHistory.next(e);
    //             this.parentSefetyChecksComponent.fillOtherDetails();
    //             return e;
    //         },
    //         Error: (e: String) => {
    //             AlertNotificationsComponent.alertCall.showAlert('Error while getting prospect history', 'danger');
    //             return e;
    //         },
    //     };
    //     this.apiService.callService(d);
    // }

    getIncludedAndExcludedProspectData(str, includedProspects=null, excludedProspects=null){
        if(includedProspects?.length > 0){
            str = str += "&id__inc=" + String(includedProspects.join(','))
        }
        if(excludedProspects?.length > 0){
            str = str += "&id__exc=" + String(excludedProspects.join(','))
        }
        return str
    }
    getProspectHistory(prospectFilterObj: ProspectFilterModel, campaignId, includedProspects=null, excludedProspects=null) {
        var str = this.getProspectData(prospectFilterObj);
        str = this.getIncludedAndExcludedProspectData(str, includedProspects, excludedProspects);
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.CHECK_PROSPECT_HISTORY.replace("{id}", campaignId.toString()) + str,
            Success: (e: any) => {
                this.checkProspectHistory.next(e);
                this.parentSefetyChecksComponent.fillOtherDetails();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while getting prospect history', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }



    // Method to all prospect status
    getAllProspectStatus(caller) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_PROSPECT_STATUS,
            Success: (e: any) => {
                if (caller == 'prospect') {
                    this.prospectStatusResponseModel.next(e);
                    this.parentComponent.ProspectStatusRecieved();
                }
                if (caller == 'sidebar') {
                    this.sideBarStatusResponseModel.next(e);
                    this.parentSidebarProspectsComponent.ProspectStatusRecieved();
                }
                if(caller === 'prospect-detail'){
                    this.prospectStatusResponseModel.next(e);
                    this.parentProspectDetailComponent.prospectStatusesReceived()
                }
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading Status', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    // Method to all users
    getAllUsers(caller) {
        //var str = "?&name__icontains=" + seachStr + "&page=1&page_size=20";
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_ALL_USERS,
            Success: (e: any) => {
                if (caller == 'prospect') {
                    this.prospectUserResponseModel.next(e);
                    this.parentComponent.UserRecieved();
                }
                if (caller == 'sidebar') {
                    this.sideBarUserResponseModel.next(e);
                    this.parentSidebarProspectsComponent.UserRecieved();
                }
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading users', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    // Method to get all clients
    getAllClients() {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_CLIENTS + '?page_size=100000',
            Success: (e: any) => {
                this.clientResponseModel.next(e);
                this.parentMarkDncComponent.ClientRecieved();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading Clients', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }
    // Method to fetch all prospect groups
    getAllProspectGroupsForProspect(seachStr, caller) {
        var str = "?page=1&page_size=10"
        if(seachStr?.length > 0){
            str += "&name__icontains=" + seachStr
        }
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_PROSPECTS_GROUPS+str,
            Success: (e: any) => {
                this.prospectGroupResponseModel.next(e);
                if (caller == "parentComponent")
                    this.parentComponent.ProspectGroupReceived();
                if (caller == "parentProspectDetailComponer")
                    this.parentProspectDetailComponent.ProspectGroupReceived();
                if (caller == "sidebar") {
                    this.sideBarProspectGroupResponseModel.next(e);
                    this.parentSidebarProspectsComponent.ProspectGroupReceived();
                }
                if (caller == 'prospect-edit') {
                    this.parentProspectEditComponent.ProspectGroupReceived();
                }

                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading prospect groups', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    // Method to fetch all prospect groups
    getAllProspectGroups(caller) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_PROSPECTS_GROUPS,
            Success: (e: any) => {
                this.prospectGroupResponseModel.next(e);
                if (caller == "parentComponent")
                    this.parentComponent.ProspectGroupReceived();
                if (caller == "parentProspectDetailComponer")
                    this.parentProspectDetailComponent.ProspectGroupReceived();
                if (caller == "sidebar") {
                    this.sideBarProspectGroupResponseModel.next(e);
                    this.parentSidebarProspectsComponent.ProspectGroupReceived();
                }
                if (caller == 'prospect-edit') {
                    this.parentProspectEditComponent.ProspectGroupReceived();
                }

                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading prospect groups', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    getProspectById(prospectId, caller) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_PROSPECT_BY_ID.replace("{id}", prospectId.toString()),
            Success: (e: any) => {
                if (caller == 'prospect-detail') {
                    this.prospectDetailsProspectModel.next(e);
                    this.parentProspectDetailComponent.ProspectDetailReceived();
                }
                if (caller == 'prospect-edit') {
                    this.prospectEditProspectModel.next(e);
                    this.parentProspectEditComponent.ProspectDetailReceived();
                }
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading prospect data', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    getClientById(clientId) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_CLIENTS_BY_ID.replace("{id}", clientId.toString()),
            Success: (e: any) => {

                this.clients.next(e);
                this.parentSefetyChecksComponent.fillClient();

                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading client data', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    getUserById(userId) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_USER_BY_ID.replace("{id}", userId.toString()),
            Success: (e: any) => {
                this.userModel.next(e);
                // this.parentCampaignDetailComponent.UserRecieved();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading user data', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    getProspectStatusById(prospectStatusId) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_PROSPECT_STATUS_BY_ID.replace("{id}", prospectStatusId.toString()),
            Success: (e: any) => {
                this.prospectDetailsProspectStatusModel.next(e);
                this.parentProspectDetailComponent.ProspectStatusDetailReceived();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading prospect status data', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }


    // to create post data for prospect
    getProspectPostData(prospectObj: ProspectModel) {
        var jsonPostData = {
            "firstname": prospectObj.firstname,
            "middlename": prospectObj.middlename,
            "lastname": prospectObj.lastname,
            "email_address": prospectObj.email_address,
            "company": prospectObj.company,
            "address": prospectObj.address,
            "city": prospectObj.city,
            "state": prospectObj.state,
            "region": prospectObj.region,
            "country": prospectObj.country,
            "phone": prospectObj.phone,
            "job_title": prospectObj.job_title,
            "industry": prospectObj.industry,
            "linkedin": prospectObj.linkedin,
            "snippet": prospectObj.snippet,
            "snippet1": prospectObj.snippet1,
            "snippet2": prospectObj.snippet2,
            "snippet3": prospectObj.snippet3,
            "snippet4": prospectObj.snippet4,
            "score": 0,
            "cnt_campaign": 0,
            "cnt_email_sent": 0,
            "cnt_email_open": 0,
            "cnt_replies_received": 0,
            "created_by": prospectObj.created_by,
            "status": prospectObj.status,
            "prospect_group": prospectObj.prospect_group
        }
        return jsonPostData;
    }

    validateProspect(prospectObj: ProspectModel) {

        if (prospectObj.firstname == undefined || prospectObj.firstname.trim().length == 0) {
            AlertNotificationsComponent.alertCall.showAlert('Please enter first name', 'danger');
            return false
        }
        if (prospectObj.lastname == undefined || prospectObj.lastname.trim().length == 0) {
            AlertNotificationsComponent.alertCall.showAlert('Please enter last name', 'danger');
            return false
        }
        if (prospectObj.email_address == undefined || prospectObj.email_address.trim().length == 0) {
            AlertNotificationsComponent.alertCall.showAlert('Please enter email', 'danger');
            return false
        }
        if (prospectObj.email_address !== undefined && prospectObj.email_address.trim().length > 0 && !this.validateEmail(prospectObj.email_address)) {
            AlertNotificationsComponent.alertCall.showAlert('Invalid email', 'danger');
            return false
        }
        if (prospectObj.job_title == undefined || prospectObj.job_title.trim().length == 0) {
            AlertNotificationsComponent.alertCall.showAlert('Please enter job title', 'danger');
            return false
        }

        if (prospectObj.country == undefined || prospectObj.country.trim().length == 0) {
            AlertNotificationsComponent.alertCall.showAlert('Please enter country', 'danger');
            return false
        }

        if (prospectObj.company == undefined || prospectObj.company.trim().length == 0) {
            AlertNotificationsComponent.alertCall.showAlert('Please enter company name', 'danger');
            return false
        }
        // if (prospectObj.phone !== undefined || prospectObj.phone.trim().length > 0 && !this.validatePhone(prospectObj.phone)) {
        //   AlertNotificationsComponent.alertCall.showAlert('Invalid phone','danger');
        //   return false
        // }

        // if (prospectObj.linkedin !== undefined && prospectObj.linkedin !== null && prospectObj.linkedin.trim().length > 0 && !this.validateLinkedInURL(prospectObj.linkedin)) {
        //     AlertNotificationsComponent.alertCall.showAlert('Invalid Linkedin url', 'danger');
        //     return false
        //   }

        return true;
    }

    // to validate linkedIn url
    validateLinkedInURL(linkedInUrl) {
        // var pattern = new RegExp('/(https?)?:?(\/\/)?(([w]{3}||\w\w)\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/');
        var pattern = new RegExp('^https?://((www|\w\w)\.)?linkedin.com/((in/[^/]+/?)|(pub/[^/]+/((\w|\d)+/?){3}))$'); // fragment locator
        return !!pattern.test(linkedInUrl);
    }
    validateEmail(email) {
        var pattern = new RegExp('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$');
        return !!pattern.test(email);
    }
    validatePhone(phone) {
        var pattern = new RegExp('^((\\+91-?)|0)?[0-9]{10}$');
        return !!pattern.test(phone);
    }
    updateProspect(prospectObj: ProspectModel) {

        if (!this.validateProspect(prospectObj)) {
            return;
        }
        let d: IApiServiceDelegate = {
            Data: this.getProspectPostData(prospectObj),
            Method: "PATCH",
            Action: MapAPICollection.UPDATE_PROSPECTS + "/" + prospectObj.id,
            Success: (e: any) => {
                this.parentProspectEditComponent.redirectToPreviousPage(prospectObj.id);
                AlertNotificationsComponent.alertCall.showAlert("Prospect Updated successfully", 'primary');
                return e;
            },
            Error: (e: any) => {
                AlertNotificationsComponent.alertCall.showAlert('Something went wrong', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    updateProspectGroup(prospectObj: ProspectModel) {

        if (!this.validateProspect(prospectObj)) {
            return;
        }
        let d: IApiServiceDelegate = {
            Data: this.getProspectPostData(prospectObj),
            Method: "PATCH",
            Action: MapAPICollection.UPDATE_PROSPECTS + "/" + prospectObj.id,
            Success: (e: any) => {

                AlertNotificationsComponent.alertCall.showAlert("Prospect group removed successfully", 'primary');
                return e;
            },
            Error: (e: any) => {
                AlertNotificationsComponent.alertCall.showAlert('Something went wrong', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    getProspectJourneyHistory(propspectId){
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_PROSPECT_HISTORY.replace("{id}", propspectId),
            Success: (e: any) => {
                this.prospectHistoryResponseModel.next(e)
                // this.parentProspectDetailComponent.ngOnInit();
                // AlertNotificationsComponent.alertCall.showAlert("Prospect removed from campaign successfully", 'primary');
                return e;
            },
            Error: (e: any) => {
                AlertNotificationsComponent.alertCall.showAlert('Something went wrong', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    removeProspectFromCampaign(propspectId, campaignId) {
        let d: IApiServiceDelegate = {
            Data: { "campaign_id": campaignId },
            Method: "PUT",
            Action: MapAPICollection.REMOVE_PROSPECT_FROM_CAMPAIGN.replace("{id}", propspectId),
            Success: (e: any) => {
                this.parentProspectDetailComponent.ngOnInit();
                AlertNotificationsComponent.alertCall.showAlert("Prospect removed from campaign successfully", 'primary');
                return e;
            },
            Error: (e: any) => {
                AlertNotificationsComponent.alertCall.showAlert('Something went wrong', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }


    validateDNCProspect(dncObj: DNCModel, chkGlobal) {
        if ((dncObj.client == undefined || dncObj.client == null || dncObj.client.toString() == 'NaN') && !chkGlobal) {
            AlertNotificationsComponent.alertCall.showAlert('Please select client', 'danger');
            return false
        }
        if (dncObj.dnc_reason == undefined || dncObj.dnc_reason == null || dncObj.dnc_reason.trim().length == 0) {
            AlertNotificationsComponent.alertCall.showAlert('Please provide dnc reason', 'danger');
            return false
        }

        return true;
    }

    getDNCProspectPostData(dncObj: DNCModel) {
        var jsonPostData = {
            "email_address": dncObj.email_address,
            "dnc_reason": dncObj.dnc_reason,
            "created_by": MapCommon.getloggedInUser().id,
            "prospect": dncObj.prospect,
            "client": dncObj.client,
            "type": dncObj.type,
        }
        return jsonPostData;
    }
    // Method to save prospect
    saveDNCProcpect(dncObj: DNCModel, chkGlobal, chkType) {
        if (!this.validateDNCProspect(dncObj, chkGlobal)) {
            return;
        }
        if (chkGlobal) {
            dncObj.client = null;
        }
        dncObj.type = chkType;
                //dncObj.created_by = HelperService.currentUser;
        let d: IApiServiceDelegate = {
            Data: this.getDNCProspectPostData(dncObj),
            Method: "POST",
            Action: MapAPICollection.ADD_DNC_PROSPECT,
            Success: (e: any) => {
                AlertNotificationsComponent.alertCall.showAlert("DNC Prospect Added successfully", 'primary');
                return e;

            },
            Error: (e: any) => {
                if (e.email_address !== undefined) {
                    AlertNotificationsComponent.alertCall.showAlert('dnc prospect with this email address already exists', 'error');
                }
                else {
                    AlertNotificationsComponent.alertCall.showAlert('Something went wrong', 'danger');
                }

                return e;
            },
        };
        this.apiService.callService(d);
    }


    downloadItems(prospects) {
        var str = '?id__inc=' + prospects.join(',') + '&page_size=1000'
        var completeUrl = MapAPICollection.EXPORT_PROSPECTS + str

        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: completeUrl,
            Success: (e: any) => {
                AlertNotificationsComponent.alertCall.showAlert('We have received your request, please check your inbox', 'primary');
                return e;
            },
            Error: (e: any) => {
                AlertNotificationsComponent.alertCall.showAlert('Something went wrong, error while export', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);


        // let httpHeaders = new HttpHeaders();
        // httpHeaders.append("Accept", "application/json");
        // this.apiService.downloadFile(completeUrl, httpHeaders).subscribe((e: any) => {
        //     this.parentSefetyChecksComponent.downloadReceived(e.res.body);
        // },
        // (error: any) => {
        //     AlertNotificationsComponent.alertCall.showAlert('Error while exporting prospects', 'danger');
        //     return error;
        //   }

        // );
    }
}
