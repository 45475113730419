import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observer } from "rxjs";
import { AlertNotificationsComponent } from "src/app/Alerts/alert-notifications/alert-notifications.component";
import { MapAPICollection } from "src/app/app-providers/services/map-api-collection";
import { IApiServiceDelegate } from "src/app/app-providers/services/map-api-IApiServiceDelegate";
import { MapAPIService } from "src/app/app-providers/services/map-api-service";
import { TemplateAddComponent } from "../template-add/template-add.component";
import { TemplateEditComponent } from "../template-edit/template-edit.component";
import { TemplatesComponent } from "../templates.component";
import { TemplateModel, TemplateResponseModel } from "./template-model";

@Injectable({
    providedIn: "root",
})

export class TemplateViewModel {
    parentTemplatesComponent: TemplatesComponent;
    parentTemplateAddComponent: TemplateAddComponent;
    parentTemplateEditComponent: TemplateEditComponent;

    templateResponseModel = new BehaviorSubject<TemplateResponseModel>(
        new TemplateResponseModel()
    );
    constructor(private apiService: MapAPIService) { }
    subscribeToTemplateVariable(observerTemplateResponseModel: Observer<TemplateResponseModel>) {
        this.templateResponseModel.subscribe(observerTemplateResponseModel)
    }

    getAllTemplate(name, active) {
        var str = "?page=1&page_size=30";
        if (name)
            str += "&name=" + name;
        if (active == true)
            str += "&status=" + true;
        else if(active == false){
            str += "&status=" + false;
        }
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_ALL_TEMPLATE + str,
            Success: (e: any) => {
                this.templateResponseModel.next(e);
                // this.parentDashboardComponent.globalDncReceived();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading templates', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    saveTemplate(templateModel: TemplateModel) {
        let d: IApiServiceDelegate = {
            Data: { 
                "name": templateModel.name, 
                "body": templateModel.body, 
                "status": templateModel.status, 
                "created_by":  templateModel.created_by
            },
            Method: "POST",
            Action: MapAPICollection.POST_TEMPLATE,
            Success: (e: any) => {
                AlertNotificationsComponent.alertCall.showAlert('Template added successfully', 'primary');
                this.parentTemplateAddComponent.loadAllTemplate();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while saving templates', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    editTemplate(templateModel: TemplateModel, caller) {
        let d: IApiServiceDelegate = {
            Data: {
                "id": templateModel.id, 
                "name": templateModel.name, 
                "body": templateModel.body, 
                "status": templateModel.status
            },
            Method: "PATCH",
            Action: MapAPICollection.EDIT_TEMPLATE.replace("{id}", templateModel.id.toString()),
            Success: (e: any) => {
                AlertNotificationsComponent.alertCall.showAlert('Template updated successfully', 'primary');
                if (caller === 'edit')
                    this.parentTemplateEditComponent.loadAllTemplate();
                else if (caller === 'parent')
                    this.parentTemplatesComponent.filterData();

                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while updating templates', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    deleteTemplate(templateId) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "DELETE",
            Action: MapAPICollection.DELETE_TEMPLATE.replace("{id}", templateId.toString()),
            Success: (e: any) => {
                AlertNotificationsComponent.alertCall.showAlert('Template deleted successfully', 'primary');
                this.parentTemplatesComponent.filterData();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while deleting templates', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

}