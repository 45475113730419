import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
export { MapAPIService } from './map-api-service';
export { IApiServiceDelegate } from './map-api-IApiServiceDelegate';
export { MapAPICollection } from './map-api-collection'
@NgModule({
  imports: [
    CommonModule
    
  ],
   
  providers: []
})
export class MapModule { }
