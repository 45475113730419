
export class LinkTracking {
    "id": number
    "campaign_name": string
    "campaign_step": number
    "campaign_step_name": string
    "client": {
        "client_id": number
        "client_name": string
    }
    "cnt_clicks": number
    "link": string
    "prospect": number
    "prospect_name": string
    "prospect_email_address": string
    "created_at": string
    "linkopened": {
        "link": number
        "created_at": string
        "device": string
        "device_type": number
        "id": number
        "ip_address": string
        "location": {
            "city": string
            "state": string
            "country": string
            "postal_code": string
        }
    "operating_system": string
    "useragent": string
    }
}
export class LinkTrackingResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [LinkTracking]
}

export class OpenTrackingResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [OpenTracking]
}

export class OpenTracking
{
    "campaign_name": string
      "prospect_name": string
      "prospect_email": string
      "client": {
        "id": number
        "name": string
      }
      "campaign_step": {
        "id": number
        "subject": string
      }
      "device": string
      "device_type": number
      "ip_address": string
      "operating_system": string
      "useragent": string
      "created_at": Date
      "location": {
        "city": string
        "country": string
        "state":string
        "postal_code": string
      }
}

export class AnalyticsClientResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [AnalyticsClientsModel]
}
export class AnalyticsClientsModel {
    "id": number
    "name": string
    "slug": string
    "created_at": Date
    "modified_at": null
    "cnt_campaign": number
    "is_deleted": string
    "created_by": number
    "modified_by": number
}


export class AnalyticsCampaignResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [AnalyticsCampaignModel]
}
export class AnalyticsCampaignModel {
    "id": number
    "name": string
    "slug": string
    "created_at": Date
    "modified_at": Date
    "status": string
    "send_limit_daily": number
    "is_deleted": string
    "created_by": number
    "modified_by": number
    "client": number
    "schedule": string
    "prospect": [number]
}

export class AnalyticsUserResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [AnalyticsUserModel]
}

export class AnalyticsUserModel {
    "id": number
    "username": string
    "first_name": string
    "last_name": string
    "email": string
}


export class BestSendingTimeModel {
    "time_interval_1": {"hour_span": string, "email_open_count": number}
    "time_interval_2": {"hour_span": string, "email_open_count": number}
    "time_interval_3": {"hour_span": string, "email_open_count": number}
    "time_interval_4": {"hour_span": string, "email_open_count": number}
    "time_interval_5": {"hour_span": string, "email_open_count": number}
    "time_interval_6": {"hour_span": string, "email_open_count": number}
    "time_interval_7": {"hour_span": string, "email_open_count": number}
    "time_interval_8": {"hour_span": string, "email_open_count": number}
}


export class RegionResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [RegionModel]
}

export class RegionModel {
    "id": number
    "name": string
    "queue_name": string
    "slug": string
}


export class SubjectListResponseModel {
  "count": number
  "next": string
  "previous": string
  "results": [SubjectListModel]
}
export class SubjectListModel {
  "id": number
  "avg_open_rate": number
  "last_open_rate": number
  "last_open_date": string
  "created_at": string
  "subject": string
  "updated_at": string
}
