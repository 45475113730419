import { Component, OnInit } from '@angular/core';
import { TemplateModel, TemplateResponseModel } from './template-model/template-model';
import { BehaviorSubject } from 'rxjs';
import { MapAPIService } from '../app-providers/services/map-api-service';
import { TemplateViewModel } from './template-model/template-view-model';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { TemplateAddComponent } from './template-add/template-add.component';
import { TemplateEditComponent } from './template-edit/template-edit.component';
import { TemplatePreviewComponent } from './template-preview/template-preview.component';
import { ConfirmDialogService } from '../admin/confirm-dialog/confirm-dialog.service';
@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss'],
  providers: [MapAPIService, TemplateViewModel],
})
export class TemplatesComponent implements OnInit {
  modalRef: MdbModalRef<TemplateAddComponent>;
  modalRefEdit: MdbModalRef<TemplateEditComponent>;
  modalOptionsEdit = {
    data: {
      templateData: {}
    },
    modalClass: 'modal-fullscreen'
  }
  templateResponseModel = new BehaviorSubject<TemplateResponseModel>(
    new TemplateResponseModel()
  );
  search_by_name: string;
  search_by_active: string;
  typingTimer;                //timer identifier
  doneTypingInterval = 1000;  //time in ms, 5 second for example
  static settingCall;

  modalOptions = {
    data: {
      parentTemplatesComponent: this,
    },
    modalClass: 'modal-fullscreen'
  }

  constructor(
    private modalService: MdbModalService, 
    private vm: TemplateViewModel, 
    private confirmDialogService: ConfirmDialogService
    ) {
      TemplatesComponent.settingCall = this;
    }

  ngOnInit(): void {
    this.vm.parentTemplatesComponent = this;
    this.vm.subscribeToTemplateVariable(this.templateResponseModel);
    this.filterData();
  }

  activateTemplate(templateModel) {
    if (templateModel?.status === true)
      templateModel.status = false;
    else if (templateModel?.status === false)
      templateModel.status = true;
    this.vm.editTemplate(templateModel, 'parent');
  }

  deleteTemplate(templateModel) {
    this.confirmDialogService.confirmThis("Are you sure you want to delete selected template?", function () {
      TemplatesComponent.settingCall.vm.deleteTemplate(templateModel.id);
    }, function () {
    })
 
  }

  previewTemplate(templateModel) {
    this.modalOptionsEdit.data.templateData = templateModel;
    this.modalRef = this.modalService.open(TemplatePreviewComponent, this.modalOptionsEdit);
  }

  editTemplate(templateModel) {
    this.modalOptionsEdit.data.templateData = templateModel;
    this.modalRef = this.modalService.open(TemplateEditComponent, this.modalOptionsEdit);
    this.modalRef.onClose.subscribe((message: any) => {
      if(message==true){
        this.filterData();
      }
    });
  }
  duplicateTemplate(templateModel: TemplateModel) {
    var data = JSON.parse(JSON.stringify(templateModel))
    data.name = "";
    this.modalOptionsEdit.data.templateData = data;
    this.modalRef = this.modalService.open(TemplateAddComponent, this.modalOptionsEdit);
    this.modalRef.onClose.subscribe((message: any) => {
      if(message==true){
        this.filterData();
      }
    });
  }

  addTemplateModal() {
    this.modalOptions.data.parentTemplatesComponent = this;
    this.modalRef = this.modalService.open(TemplateAddComponent, this.modalOptions);
    this.modalRef.onClose.subscribe((message: any) => {
      if(message==true){
        this.filterData();
      }
    });
  }


  filterData() {
    this.vm.getAllTemplate(this.search_by_name, this.search_by_active);
  }

  //on keyup, start the countdown
  inputkeyUp() {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(this.filterData.bind(this), this.doneTypingInterval);
  };

  //on keydown, clear the countdown 
  inputKeyDown() {
    clearTimeout(this.typingTimer);
  };

}
