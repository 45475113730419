<div class="ms-sm-auto" style="width: 90%;">




  <div class="row" style="height: 100%;">

    <div class="col-2 shadow-3 " style="background: #FBF7FE; height: 100vh; overflow-x:hidden; scrollbar-width: thin;
      overflow-y: auto; z-index: 1;">
      <app-analytics-sidebar></app-analytics-sidebar>
    </div>


    <div class="col-10 m-0" style="height: 100vh; overflow-x: hidden; overflow-y: auto;scrollbar-width: thin; ">



      <div class="row shadow-1 p-3 sticky-top bg-white" style="z-index: 2;">
        <div class="col-md-12">
          <h4 class="map-text-header">Open Tracking
            <!-- <span
            style="font-size: 12px; color: #ef6c00;">({{totalClientRecieved}} found)</span> -->
          </h4>
        </div>
      </div>



      <div class="row mt-2">
        <div class="col-4">
          <mdb-form-control>
            <!-- <input mdbInput type="text" id="email" class="form-control" name="email" [(ngModel)]="search_by_email"
              (keyup)="inputkeyUp()"   (keydown)="inputKeyDown()" /> -->
            <input mdbInput type="text" id="email" class="form-control" name="email" [(ngModel)]="search_by_email"
               (change)="searchSubmit($event)"   />
            <label mdbLabel class="form-label" for="email">
              Search by email</label>
          </mdb-form-control>
        </div>

        <div class="col-4">
          <input type="text" placeholder="Start date - End date" class="form-control w-100" bsDaterangepicker
            [bsConfig]="{ isAnimated: true, containerClass: 'theme-default', rangeInputFormat: 'DD/MM/YYYY'}"
            #dp="bsDaterangepicker" [maxDate]="bsMaxDate" (bsValueChange)="onValueChange($event)">
        </div>

        <div class="col-4">
          <input #campaignType [(ngModel)]="selectedCampaign" (typeaheadOnSelect)="onSelect($event)"
            (typeaheadLoading)="changeTypeaheadLoading($event)" [typeaheadMinLength]="0" [typeahead]="campaignlist"
            typeaheadOptionField="name" typeahead-wait-ms="100" class="form-control" name="Campaigns" type="text"
            (paste)="onPaste($event)" placeholder="Search by campaign" autocomplete="off" />
          <span class="highlight"></span>
        </div>

      </div>


      <div class="row mt-2">
        <div class="col-12" style="scrollbar-width: thin;">

          <div #openTrackingContainer class="table-responsive" infiniteScroll [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="30" (scrolled)="onOpenTrackingScroll()" [scrollWindow]="false">

            <table class="table table-sm table-hover table-bordered">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Opened At</th>
                  <th>Client</th>
                  <th>Campaign</th>
                  <th>Step</th>
                  <th>Device</th>
                  <th>Location</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of opentrackingList">
                  <td>{{item.prospect_name}}</td>
                  <td>{{item.prospect_email}}</td>
                  <td>{{item.created_at | date: 'medium'}}</td>
                  <td>{{item.client.name}}</td>
                  <td>{{item.campaign_name}}</td>
                  <td>{{item.campaign_step?.subject}}</td>
                  <td>{{item.device}}</td>
                  <td>{{item.location.state ?
                    item.location.state + ',': ''}} {{item.location.country}}</td>
                </tr>


              </tbody>
            </table>

          </div>

        </div>
      </div>


    </div>


  </div>
</div>
