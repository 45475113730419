import { Component, OnInit, ViewChild } from '@angular/core';
import {ProspectGroupResponseModel} from '../setting-model/setting-model';
import { SettingViewModel } from '../setting-view-model/setting-view-model';
import { MapAPIService } from "src/app/app-providers/services/map-module";
import { BehaviorSubject } from 'rxjs';

import { ConfirmDialogService } from '../../admin/confirm-dialog/confirm-dialog.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-settings-groups',
  templateUrl: './settings-groups.component.html',
  styleUrls: ['./settings-groups.component.scss'],
  providers: [MapAPIService,SettingViewModel],
})
export class SettingsGroupsComponent implements OnInit {
  prospectGrouplist = [];
  prospectGrouplistB4Search = [];
  page_index = 1;
  static settingCall;

  groupSearchModel = '';
  @ViewChild(NgForm) groupSearchForm: NgForm;

  prospectGroupResponseModel = new BehaviorSubject<ProspectGroupResponseModel>(
    new ProspectGroupResponseModel()
  );

  constructor(private vm: SettingViewModel, private confirmDialogService: ConfirmDialogService) {
    SettingsGroupsComponent.settingCall = this;
  }

  ngOnInit(): void {
    this.vm.parentSettingGroupComponent = this;
    this.vm.subscribeGroupVariable(this.prospectGroupResponseModel);
    this.vm.getAllProspectGroups(this.page_index);
  }

  groupSearchSubmit(e: any){
    this.vm.searchGroups(this.groupSearchForm.value.search)
  }

  ProspectGroupReceived() {
    if (this.prospectGrouplistB4Search.length == 0)
      this.prospectGrouplist = this.prospectGroupResponseModel.value.results;
    else {
      this.prospectGrouplist = this.prospectGroupResponseModel.value.results;
      if (this.prospectGroupResponseModel.value.previous !== null) {
        for (var j = 0; j < this.prospectGrouplist.length; j++) {
          var index = this.prospectGrouplistB4Search.findIndex(x => x.id == this.prospectGrouplist[j].id);
          if (index === -1)
            this.prospectGrouplistB4Search.push(this.prospectGrouplist[j]);
        }
        this.prospectGrouplist = this.prospectGrouplistB4Search;
      }
    }
  }

  confirmedDeleteGroup(id: number) {
    this.vm.deleteGroup(id)

  }
  gotoProspectPage(input)
  {
    localStorage.setItem("selectdProspectGroup", input.name);
    
   // alert(input.name);
  }

  confirmDeleteGroup(id: number) {
    this.confirmDialogService.confirmThis("Are you sure to delete prospects group?", function () {
      SettingsGroupsComponent.settingCall.confirmedDeleteGroup(id);
    }, function () {
    });
  }
  onProspectGroupScroll()
  {
    if (this.prospectGroupResponseModel.value.next) {
      this.prospectGrouplistB4Search = this.prospectGrouplist;
      this.page_index++;
      this.vm.getAllProspectGroups(this.page_index);
    }
  }


}
