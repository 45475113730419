<app-loading-dialog *ngIf="isLoading">
    
</app-loading-dialog>


<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Reply</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">

  <div class="row">

    <p *ngIf="span_words.length > 0"> Spam words: <span *ngFor="let i of span_words;" class="badge bg-danger ms-2">{{i}}</span>
    </p>

    <p *ngIf="wrongVariables.length > 0">Wrong Variables: <span *ngFor="let i of wrongVariables;" class="badge bg-danger ms-2">
      {{i}}</span>
    </p>




    <div class="col-8">

      <div class="mb-3">
      <label mdbLabel class="form-label" >Subject: 
      </label>
        <mdb-form-control>
          <input [(ngModel)]="stepSubjectModel" mdbInput type="text" id="subject" class="form-control" />
          <!-- <label mdbLabel class="form-label" for="subject">Subject</label> -->
        </mdb-form-control>        
      </div>
    </div>
    <div style="padding-top: 35px;" class="col-2">
      <div class="form-check form-switch">
        <!-- <span class="badge bg-primary">CC</span> -->
          <input [(ngModel)]="set_cc"
            mdbCheckbox
            class="form-check-input"
            type="checkbox"
            id="flexSwitchCheckDefault"
          />
          <label class="form-check-label" for="flexSwitchCheckDefault">Cc</label>        
          <!-- <mdb-form-control> -->
            <!-- <input [(ngModel)]="stepSubjectModel" mdbInput type="text" id="subject" class="form-control" /> -->
            <!-- <label mdbLabel class="form-label" for="subject">Subject</label> -->
          <!-- </mdb-form-control>         -->
        </div>
    </div>
    <div style="padding-top: 35px;" class="col-2">
      <div class="form-check form-switch">
        <input [(ngModel)]="set_bcc"
            mdbCheckbox
            class="form-check-input"
            type="checkbox"
            id="flexSwitchCheckDefault"
          />
          <label class="form-check-label" for="flexSwitchCheckDefault">Bcc</label>
          <!-- <mdb-form-control> -->
            <!-- <input [(ngModel)]="stepSubjectModel" mdbInput type="text" id="subject" class="form-control" /> -->
            <!-- <label mdbLabel class="form-label" for="subject">Subject</label> -->
          <!-- </mdb-form-control>         -->
        </div>
    </div>


  <!-- <div class="col-4">
    <label mdbLabel class="form-label" for="sendingRate">Email delivery speed</label>
    <select class="form-control" [(ngModel)]="sendingRateModel" id="sendingRate"  placeholder="Sending Rate">
        <option *ngFor="let o of sendingRateList" [value]="o.id">{{o.text}}</option>
    </select>
  </div> -->

  </div>
  <div class="col-12 input-group mb-3" *ngIf="set_cc">
    <span class="input-group-text" id="inputGroup-sizing-sm">Cc</span>
    <input [(ngModel)]="email_cc" type="text" class="form-control" aria-label="CC"
      aria-describedby="basic-addon1" />
  </div>
  <div class="col-12 input-group mb-3" *ngIf="set_bcc">
    <span class="input-group-text" id="inputGroup-sizing-sm">Bcc</span>
    <input [(ngModel)]="email_bcc" type="text" class="form-control" aria-label="BCC"
      aria-describedby="basic-addon1" />
  </div>
 
  <div class="col-12 mt-2">
    <div class="row" >

      <editor apiKey="{{editorAPIKey}}"  [init]="editormodules" 
      (ngModelChange)="checkSpamAndVeriablesWords($event)" [(ngModel)]="emailBodyModel"></editor>
    </div>
  </div>

  <!-- <div class="col-12">
    <input type="file" class="attachment-input" (change)="onFileSelected($event)" #fileUpload (click)="$event.target.value=null">
       <div class="attachment-upload">
        <button type="button" class="btn btn-primary btn-floating btn-sm mt-2 me-2" (click)="fileUpload.click()">
          <i class="fas fa-paperclip"></i>
        </button>    

        <span class="badge bg-light text-dark" >{{uploadedCampaignStepAttachment?.file_name || "No attachment added yet."}} </span>

        <span class="ms-2" *ngIf="attachmentUploadInProgress">
          <i class="fas fa-spinner fa-pulse"></i>
        </span>

        <span [class]="uploadedCampaignStepAttachment ? 'ms-2 attachment-removable ': 'ms-2 attachment-hidden'" 
        (click)="removeAttachment()">
         Remove <i class="fas fa-trash text-danger"></i> 
        </span>
     </div>
  </div> -->

  <div class="row" *ngIf="mandatoryError">
    <div class="col text-center mt-2">
      <label mdbLabel class="form-label" style="color: red;" for="error">{{mandatoryError}}</label>
    </div>
  </div>


</div>

  <div class=" p-2">
    <div class="row d-flex justify-content-between">
      <hr>
      <div class="col ps-3">
        <span *ngIf="savingDraftStatus" class="form-text text-muted ps-1">Saving draft...</span>
        <span (click)="removeDraft()" class="form-text text-muted ps-1 remove-draft">
          {{ showDraft &&  stepSubjectModel != '' &&  emailBodyModel != '' ? 'Remove Draft' : ''}}
        </span>
      </div>
      <div class="col d-flex justify-content-end pe-4">
        <button type="button" class="btn btn-link" (click)="modalRef.close()">
          Close
        </button>
        <button type="button" [disabled]="isLoading" (click)="createNewStep()" class="btn btn-primary">Send</button>
      </div>
    </div>
   
  </div>
  <!-- <div class="modal-footer">
</div> -->