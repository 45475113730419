
<div class="dnc-sidebar">
    <div class="mt-2"></div>
  
    <!-- <hr class="mb-0"> -->
    
    <ul class="nav flex-column mb-auto">
      <li class="nav-item">
        <a class="nav-link" [routerLinkActive]="['active']" routerLink="/dnc/domain">
          <i class="fas fa-unlink pe-3"></i> Domain DNC</a>
      </li>
      <li class="nav-item">
        <a  class="nav-link" [routerLinkActive]="['active']" routerLink="/dnc/client">
          <i class="fas fa-male pe-4"></i> Client DNC</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLinkActive]="['active']" routerLink="/dnc/global">
          <i class="fas fa-globe-americas pe-3"></i> Global DNC</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLinkActive]="['active']" routerLink="/dnc/import-dnc">
          <i class="fas fa-file-export  pe-3"></i> Import DNC</a>
      </li>
    </ul>
</div> 
  