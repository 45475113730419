import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { MapCommon } from 'src/app/app-providers/map-base/MapCommon';

@Component({
  selector: 'app-add-prospect-dnc',
  templateUrl: './add-prospect-dnc.component.html',
  styleUrls: ['./add-prospect-dnc.component.scss']
})
export class AddProspectDncComponent implements OnInit {

  clients = [];
  selectedClientModel: number = 0;
  emailModel = '';
  currentUser: number;


  constructor(public modalRef: MdbModalRef<AddProspectDncComponent>, private router: Router) { 
    this.currentUser = MapCommon.getloggedInUser().id;
  }

  ngOnInit(): void {
  }

  goTOCreateProspect() {
    this.modalRef.close();
    this.router.navigate(['/imports/one-prospect']);
  }


  createProspectDNC(){

  }

}
