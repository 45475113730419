

<div class="import-sidebar">
  <div class="mt-2"></div>

  <!-- <hr class="mb-0"> -->

  <ul class="nav flex-column mb-auto">
    <li class="nav-item">
      <a class="nav-link p-1" [routerLinkActive]="['active']" routerLink="/imports/column-mapping">
        <i class="fas fa-compress pe-1"></i> Advanced Upload</a>
    </li>
    <!-- <li class="nav-item">
      <a class="nav-link" [routerLinkActive]="['active']" routerLink="/imports/new-v2">
        <i class="fas fa-file-excel pe-3"></i> Editable Upload</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [routerLinkActive]="['active']" routerLink="/imports/new">
        <i class="far fa-file-excel pe-3"></i> Non-Editable Upload</a>
    </li> -->
    <!-- <li class="nav-item">
      <a class="nav-link" [routerLinkActive]="['active']" routerLink="/imports/copy-paste">
        <i class="fas fa-male pe-4"></i> Copy & Paste</a>
    </li> -->
    <li class="nav-item">
      <a class="nav-link p-1" [routerLinkActive]="['active']" routerLink="/imports/one-prospect">
        <i class="fas fa-user pe-1"></i> One Prospect Upload</a>
    </li>

    <li class="nav-item">
      <a class="nav-link p-1" routerLink="/imports">
        <i class="fas fa-list-ul pe-1"></i> All Imports</a>
    </li>


  </ul>
</div>




