import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  CampaignRunLogStatsResponseModel, CampaignRunLogFilterModel, CampaignRunLogResponseModel,
  CampaignRunLogModel, CampaignMasterStat
} from "../campaign-model/campaign-model";
import { MapAPIService } from "src/app/app-providers/services/map-module";
import { BehaviorSubject } from 'rxjs';
import { CampaignViewModel } from '../campaign-view-model/campaign-view-model';
import { CampaignDetailComponent } from '../campaign-detail/campaign-detail.component';
import { CampaignDetailSummaryComponent } from '../campaign-detail-summary/campaign-detail-summary.component'
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ViewCampaignDetailEmailComponent } from './view-campaign-detail-email/view-campaign-detail-email.component';
import { ExportCampaignService } from 'src/app/admin/services/export-campaign.service';
import { AlertNotificationsComponent } from 'src/app/Alerts/alert-notifications/alert-notifications.component';
import { AddToAnotherCampaignComponent } from '../add-to-another-campaign/add-to-another-campaign.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-campaign-detail-emails',
  templateUrl: './campaign-detail-emails.component.html',
  styleUrls: ['./campaign-detail-emails.component.scss'],
  providers: [MapAPIService, CampaignViewModel],
})
export class CampaignDetailEmailsComponent implements OnInit {

  emailViewmodalRef: MdbModalRef<ViewCampaignDetailEmailComponent>;
  addToAnotherCampaignModalRef: MdbModalRef<AddToAnotherCampaignComponent>;
  timeFetched: string = ''
  campaignId: number;
  emailContainsText: string = null;
  campaignReportRunlogStats: any = [];
  campaignStepDetails: any = [];
  campiagnStatus: boolean = false;
  cnt_email_total: number = 0;
  cnt_new: number = 0;
  cnt_scheduled: number = 0;
  cnt_processing: number = 0;
  cnt_sent: number = 0;
  cnt_bounced: number = 0;
  cnt_opened: number = 0;
  cnt_notopened: number = 0;
  cnt_replied: number = 0;
  cnt_opted_out: number = 0;
  cnt_rejected: number = 0;
  btn_selected: string = '';
  tab_selected: string = '';
  campaignRunLogFilterModel = new CampaignRunLogFilterModel();
  campaignRunLogList: any = [];
  campaignRunLogListB4Search: any = [];
  page_index = 1;
  selectedRestriction: string = 'Restricted';
  ShowRestriction: boolean;
  loadingSpiner: boolean = false;
  exportAllPageSize: number;
  @ViewChild("runlogContainer") campaignContainer: ElementRef;
  sortDirection: string = 'desc';
  column_selected: string = '';
  column_level = 0;
  sortActive: boolean = false;

  typingTimer;                //timer identifier
  doneTypingInterval = 1000;  //time in ms, 5 second for example


  sortByColumn(list: any[] | undefined, column: string, level, direction = 'desc'): any[] {
    let sortedArray = (list || []).sort((a, b) => {
      if (level === 0) {
        if (a[column] > b[column]) {
          return (direction === 'desc') ? 1 : -1;
        }
        if (a[column] < b[column]) {
          return (direction === 'desc') ? -1 : 1;
        }
        return 0;
      }
      if (level === 1) {
        if (Number(a[column].length) > Number(b[column].length)) {
          return (direction === 'desc') ? 1 : -1;
        }
        if (Number(a[column].length) < Number(b[column].length)) {
          return (direction === 'desc') ? -1 : 1;
        }
        return 0;
      }
    })
    return sortedArray;
  };

  campaignRunLogStatsResponseModel = new BehaviorSubject<CampaignRunLogStatsResponseModel>(
    new CampaignRunLogStatsResponseModel()
  );

  campaignRunLogResponseModel = new BehaviorSubject<CampaignRunLogResponseModel>(
    new CampaignRunLogResponseModel()
  );
  campaignMasterStat = new BehaviorSubject<CampaignMasterStat>(
    new CampaignMasterStat()
  );
  constructor(private vm: CampaignViewModel, private modalService: MdbModalService,
    private exportCampaignService: ExportCampaignService, private router: Router
  ) { }

  ngOnInit(): void {
    var url = window.location.href;
    this.campaignId = Number(url.substring(url.lastIndexOf('/') + 1));
    this.getCampaignStatus();
    this.vm.parentCampaignDetailEmailsComponent = this;
    this.vm.subscribeCampaignProspectVariables(this.campaignMasterStat);
    this.vm.getReportCampaignRunlogStats(this.campaignId, 'campaign-email');
    // if(!sessionStorage.getItem("campaignReportRunlogStats")){
    // this.vm.getReportCampaignRunlogStats(this.campaignId, 'campaign-email');
    // } else{
    //   this.campaignReportRunlogStats = JSON.parse(sessionStorage.getItem("campaignReportRunlogStats"))
    //   this.filterTabClicked('All', 0);
    //   this.fillUpTheCounts('All');
    //   this.timeFetched =  sessionStorage.getItem("campaignReportRunlogStatsTime");
    // }
    this.vm.subscribeCampaignRunLogVariables(this.campaignRunLogResponseModel)
  }
  // fetchData(){
  //   sessionStorage.removeItem("campaignReportRunlogStatsTime")
  //   this.vm.getReportCampaignRunlogStats(this.campaignId, 'campaign-email');
  //   this.filterButtonClick(localStorage.getItem('emailFilter'))
  // }

  exportAll() {
    if(this.campaignRunLogList.length > 0){
      this.loadingSpiner = true;
      this.vm.parentCampaignDetailEmailsComponent = this;
      this.vm.getExportRunlogs(this.campaignRunLogFilterModel, 'campaign-email', this.exportAllPageSize);
    } else {
      AlertNotificationsComponent.alertCall.showAlert("No data available, can't export", 'danger')
    }
  }

  // exportProspectsReceived(items){
  //   var prospects = items.map(item => item.prospect);
  //   prospects = prospects.filter((x, i, a) => a.indexOf(x) == i);
  //   this.vm.parentCampaignDetailEmailsComponent = this;
  //   this.vm.exportFinalRunlogs(items, prospects, 'campaign-email');
  // }

  exportApiOpsCompleted(){
    this.loadingSpiner = false;
    // this.exportCampaignService.downloadCSV(data, (this.campaignId + '_campaign_export'));
  }


  convertDevicesListToStringWithComma(data){
    if (data.length > 0)
    {
      var items = []
    for (var i of data){
      if(!items.includes(i.device))
      {
        items.push(i.device)
      }
    }

    return items.toString()
    }
}

getLocation(data){
  var location = ""
  if (data?.length > 0) {
   var item = data[data.length - 1]
   if(item?.location){
    if (item.location.city?.length > 0 ){
      location = location + item.location.city
     }
     if (item.location.state?.length > 0){
       if(!(item.location.city?.length > 0)){
        location = location + item.location.state
       } else {
        location = location + ', ' + item.location.state
       }

     }
     if (item.location.country?.length > 0){

      if(!(item.location.city?.length > 0) && !(item.location.state?.length > 0)){
        location = location + item.location.country
       } else {
        location = location +  ', ' + item.location.country
       }

     }
   }


  }
  return location
}


addToAnotherCampaign(){

  const modalOptions = {
    data: {
      selectedfilters: this.campaignRunLogFilterModel,
      selectedProspects: this.campaignRunLogResponseModel.value.count
    },
    modalClass: 'modal-small'
  }
  this.addToAnotherCampaignModalRef = this.modalService.open(AddToAnotherCampaignComponent, modalOptions);
  this.addToAnotherCampaignModalRef.onClose.subscribe((message: any) => {
    if (message) {
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
      this.router.navigate([`/campaigns/${message}`]));
    }
  });

}

sortOn(level: number, column: string) {
  this.column_selected = column;
  this.sortActive = true;
  this.sortByColumn(this.campaignRunLogList, column, level, this.sortDirection);
  if (this.sortDirection === 'asc')
    this.sortDirection = 'desc';
  else
    this.sortDirection = 'asc';
  this.campaignContainer.nativeElement.scrollTop = 0;
}


  CampaignRunLogReceivedOnEmails() {
    this.loadingSpiner = false;
    if (this.campaignRunLogListB4Search.length == 0){
      this.campaignRunLogList = this.campaignRunLogResponseModel.value.results;
    }
    else {
      this.campaignRunLogList = this.campaignRunLogResponseModel.value.results;
      if (this.campaignRunLogResponseModel.value.previous !== null) {
        for (var j = 0; j < this.campaignRunLogList.length; j++) {
          var index = this.campaignRunLogListB4Search.findIndex(x => x.id == this.campaignRunLogList[j].id);
          if (index === -1)
            this.campaignRunLogListB4Search.push(this.campaignRunLogList[j]);
        }
        this.campaignRunLogList = this.campaignRunLogListB4Search;
      }
    }

    this.exportAllPageSize = this.campaignRunLogResponseModel.value.count;
  }

  getCampaignStatus() {
    this.campiagnStatus = CampaignDetailComponent.parentCall.campiagnStatus;
    this.campaignStepDetails = CampaignDetailSummaryComponent.siblingCall.campaignSteps;
  }

  CampaignReportRunLogStatsRecieved(timeLog:any) {
    this.campaignReportRunlogStats = this.campaignMasterStat.value;
    // if(!sessionStorage.getItem("campaignReportRunlogStats")){
    //   this.filterTabClicked('All', 0);
    // }
    this.fillUpTheCounts('All');
    // this.filterTabClicked('All', 0)
    // let sessionReportRunLog =  JSON.stringify(this.campaignMasterStat.value);
    // sessionStorage.setItem("campaignReportRunlogStats", sessionReportRunLog);

    // sessionStorage.setItem("campaignReportRunlogStatsTime", timeLog);
    // this.timeFetched = timeLog


  }

  fillUpTheCounts(caller) {
    if (caller == 'All') {
      this.cnt_email_total = this.campaignReportRunlogStats.stats.total_emails;
      this.cnt_new = this.campaignReportRunlogStats.stats.emails_created;
      this.cnt_scheduled = this.campaignReportRunlogStats.stats.emails_scheduled;
      this.cnt_opened = this.campaignReportRunlogStats.stats.emails_sent.reached.opened;
      this.cnt_notopened= this.campaignReportRunlogStats.stats.emails_sent.reached.notopened;
      this.cnt_replied = this.campaignReportRunlogStats.stats.emails_sent.reached.replied.total +
      this.campaignReportRunlogStats.stats.emails_sent.reached.replied.auto_reply.total;
      this.cnt_bounced = this.campaignReportRunlogStats.stats.emails_sent.bounced;
      this.cnt_opted_out = this.campaignReportRunlogStats.stats.emails_sent.reached.opted_out;
      this.cnt_processing = this.campaignReportRunlogStats.stats.emails_processing;
      this.cnt_sent = this.campaignReportRunlogStats.stats.emails_sent.total;
      this.cnt_rejected = this.campaignReportRunlogStats.stats.emails_restricted.total;
    }
    else {
      var stepLen = this.campaignReportRunlogStats.step_stats?.length;
      if (stepLen > 0) {
        for (let i = 0; i < stepLen; i++) {
          if (this.campaignReportRunlogStats.step_stats[i].id == caller) {
            this.cnt_email_total = this.campaignReportRunlogStats.step_stats[i].total_emails;
            this.cnt_new = this.campaignReportRunlogStats.step_stats[i].emails_created;
            this.cnt_scheduled = this.campaignReportRunlogStats.step_stats[i].emails_scheduled;
            this.cnt_opened = this.campaignReportRunlogStats.step_stats[i].emails_sent.reached.opened;
            this.cnt_notopened= this.campaignReportRunlogStats.step_stats[i].emails_sent.reached.notopened;
            this.cnt_replied = this.campaignReportRunlogStats.step_stats[i].emails_sent.reached.replied.total +
            this.campaignReportRunlogStats.step_stats[i].emails_sent.reached.replied.auto_reply.total;
            this.cnt_bounced = this.campaignReportRunlogStats.step_stats[i].emails_sent.bounced;
            this.cnt_opted_out = this.campaignReportRunlogStats.step_stats[i].emails_sent.reached.opted_out;
            this.cnt_processing = this.campaignReportRunlogStats.step_stats[i].emails_processing;
            this.cnt_sent = this.campaignReportRunlogStats.step_stats[i].emails_sent.total;
            this.cnt_rejected = this.campaignReportRunlogStats.step_stats[i].emails_restricted.total;
            return;
          }
        }
      };
    }
  }

  filterTabClicked(tabSelected, stepId) {
    this.tab_selected = tabSelected;
    if(this.btn_selected === ''){
      this.btn_selected = 'Total';
    }
    if (tabSelected == 'All') {
      this.campaignRunLogFilterModel.campaign_step__campaign__id = this.campaignId;
      this.campaignRunLogFilterModel.campaign_step__id = null;
      this.fillUpTheCounts('All');
    }
    else {
      this.campaignRunLogFilterModel.campaign_step__campaign__id = null;
      this.campaignRunLogFilterModel.campaign_step__id = stepId;
      this.fillUpTheCounts(stepId);
    }

    this.CallFinalFiler();
  }

  changeRestriction() {
    this.campaignRunLogFilterModel.log_status__startswith = this.selectedRestriction;
    this.CallFinalFiler();
  }
  filterButtonClick(filterChoose) {
    // localStorage.setItem('emailFilter',filterChoose )
    this.btn_selected = filterChoose;

    
    if(this.tab_selected === ''){
      this.tab_selected = 'All';
      this.campaignRunLogFilterModel.campaign_step__campaign__id = this.campaignId;
    }


    this.campaignRunLogFilterModel.email_opened_at__isnull = null;
    this.campaignRunLogFilterModel.email_bounced_at__isnull = null;
    this.campaignRunLogFilterModel.email_opted_out_at__isnull = null;
    this.campaignRunLogFilterModel.email_replied_at__isnull = null;
    this.campaignRunLogFilterModel.campaign_step__status = null;
    this.campaignRunLogFilterModel.email_sent_at__isnull = null;
    this.campaignRunLogFilterModel.log_status__startswith = null;
    this.ShowRestriction = false;

    if (filterChoose == 'New'){
      this.campaignRunLogFilterModel.campaign_step__status = 'New';
    }

    if (filterChoose == 'Processing'){
      this.campaignRunLogFilterModel.campaign_step__status = 'Processing';
    }

    if (filterChoose == 'Scheduled'){
      this.campaignRunLogFilterModel.campaign_step__status = 'Scheduled';
    }

    if (filterChoose == 'Finished'){
      // this.campaignRunLogFilterModel.campaign_step__status = 'Finished';
      this.campaignRunLogFilterModel.email_sent_at__isnull = 'false';
    }

    if (filterChoose == 'Bounced')
      this.campaignRunLogFilterModel.email_bounced_at__isnull = 'False';

    if (filterChoose == 'Opened'){
      this.campaignRunLogFilterModel.email_opened_at__isnull = 'False';
      this.campaignRunLogFilterModel.email_bounced_at__isnull = 'True';
    }

     if (filterChoose == 'NotOpened'){
      this.campaignRunLogFilterModel.email_sent_at__isnull = 'False';
      this.campaignRunLogFilterModel.email_opened_at__isnull = 'True';
      this.campaignRunLogFilterModel.email_bounced_at__isnull = 'True';
     }

    if (filterChoose == 'Replied'){
      this.campaignRunLogFilterModel.email_replied_at__isnull = 'False';
    }

    if (filterChoose == 'OptedOut'){
      this.campaignRunLogFilterModel.email_opted_out_at__isnull = 'False';
    }

    if (filterChoose == 'Rejected') {
      this.ShowRestriction = true;
      this.campaignRunLogFilterModel.log_status__startswith = this.selectedRestriction;
    }
    this.CallFinalFiler();
  }

  status: boolean = false;
  clickEvent() {
    this.status = !this.status;
  }

  handleUserInput() {
    if(this.tab_selected === ''){
      this.tab_selected = 'All';
      this.campaignRunLogFilterModel.campaign_step__campaign__id = this.campaignId;
    }

    if(this.btn_selected === ''){
      this.btn_selected = 'Total';
    }
    
    this.campaignRunLogFilterModel.recipient_email_address__contains = this.emailContainsText;
    this.CallFinalFiler();
  }

  //on keyup, start the countdown
  inputkeyUp() {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(this.handleUserInput.bind(this), this.doneTypingInterval);
  };

  //on keydown, clear the countdown 
  inputKeyDown() {
    clearTimeout(this.typingTimer);
  };

  CallFinalFiler() {
    this.page_index = 1;
    this.campaignRunLogFilterModel.page = this.page_index;
    this.loadingSpiner = true;
    this.vm.getAllCampaignRunLog(this.campaignRunLogFilterModel, 'campaign-email');
  }

  viewEmailDetail(runLogmodel: CampaignRunLogModel) {
    const data = JSON.parse(JSON.stringify(runLogmodel));
    const modalOptions = {
      data: {
        campaignEmailData: data,
        campaignId: this.campaignId
      },
      // modalClass: 'modal-lg'
      modalClass: 'modal-fullscreen'
    }
    this.emailViewmodalRef = this.modalService.open(ViewCampaignDetailEmailComponent, modalOptions);
  }

  // This method is being called once user hits bottom while scrolling email
  onEmailScroll() {
    if (this.campaignRunLogResponseModel.value.next) {
      this.campaignRunLogListB4Search = this.campaignRunLogList;
      this.page_index++;
      this.campaignRunLogFilterModel.page = this.page_index;
      this.vm.getAllCampaignRunLog(this.campaignRunLogFilterModel, 'campaign-email');
    }
  }

}
