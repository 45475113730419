
<div class="row" style="height: 100%;">



    <div class="col-2" style="background: rgb(243, 243, 243); height: 100vh; overflow-x: hidden; overflow-y: auto; ">

        <app-sidebar-reports></app-sidebar-reports>
    </div>




  <div class="col-10 p-3 mt-2" style="height: 100vh; overflow-x: hidden; overflow-y: auto; scrollbar-width: thin;">

    </div>


  </div>
