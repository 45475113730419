import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'app-time-picker-modal',
  templateUrl: './time-picker-modal.component.html',
  styleUrls: ['./time-picker-modal.component.scss']
})
export class TimePickerModalComponent implements OnInit {

  TimeSelections = [
    {time: '00:00:00', time_display: "12:00 AM"}, {time: '01:00:00', time_display: "01:00 AM"},
    {time: '02:00:00', time_display: "02:00 AM"}, {time: '03:00:00', time_display: "03:00 AM"},
    {time: '04:00:00', time_display: "04:00 AM"}, {time: '05:00:00', time_display: "05:00 AM"},
    {time: '06:00:00', time_display: "06:00 AM"}, {time: '07:00:00', time_display: "07:00 AM"},
    {time: '08:00:00', time_display: "08:00 AM"}, {time: '09:00:00', time_display: "09:00 AM"},
    {time: '10:00:00', time_display: "10:00 AM"}, {time: '11:00:00', time_display: "11:00 AM"}, 
    {time: '12:00:00', time_display: "12:00 PM"}, {time: '13:00:00', time_display: "01:00 PM"},
    {time: '14:00:00', time_display: "02:00 PM"}, {time: '15:00:00', time_display: "03:00 PM"},
    {time: '16:00:00', time_display: "04:00 PM"}, {time: '17:00:00', time_display: "05:00 PM"},
    {time: '18:00:00', time_display: "06:00 PM"}, {time: '19:00:00', time_display: "07:00 PM"},
    {time: '20:00:00', time_display: "08:00 PM"}, {time: '21:00:00', time_display: "09:00 PM"},
    {time: '22:00:00', time_display: "10:00 PM"}, {time: '23:00:00', time_display: "11:00 PM"},
    
  ];

  errorText: string = '';
  bothOptSelected: boolean = true;
  startSelectedModel: string = '';
  endSelectedModel: string = '';

  constructor(public timePickerModalRef: MdbModalRef<TimePickerModalComponent>) {}

  ngOnInit(): void {
  }

  getFullItem(SelectTime: string){    
    var result = this.TimeSelections.filter(obj => {
      return obj.time === SelectTime;
    })
    return result[0]
  }

  SaveTime(){
    if ((this.startSelectedModel === '') || (this.endSelectedModel === '')) {      
      this.errorText = "Please select both the options";
      this.bothOptSelected = false;
    } else if (this.startSelectedModel === this.endSelectedModel) {
      this.errorText = "Both the options can't be same";
      this.bothOptSelected = false;
    }else if(this.startSelectedModel > this.endSelectedModel){
      this.errorText = "Start time can't be greater than End time";
      this.bothOptSelected = false;
    }    
    else {
      const startDisplay = this.getFullItem(this.startSelectedModel);
      const endDisplay = this.getFullItem(this.endSelectedModel);
      const selectedTime = {start: startDisplay, end: endDisplay }
      this.timePickerModalRef.close(
        selectedTime
      );
    } 

  }


  closeModal() {
    const selectedTime = null;
    this.timePickerModalRef.close(selectedTime);
  }


}
