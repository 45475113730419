<!-- <div id="pause" class="d-flex align-items-center justify-content-center">
    <div id="spinner"></div>
    <h6 class="mt-2" style="color: white; z-index: 888888888;">Fetching data ...</h6>
</div> -->


<div id="pause" class="d-flex align-items-center justify-content-center">

    <div>
        <div class="spinner">
            <div class="double-bounce1"></div>
            <div class="double-bounce2"></div>
        </div>
        <h6 class="mt-2 text-center" style="color: white; z-index: 888888888; ">Fetching data</h6>
    </div>
    

</div>
