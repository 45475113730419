import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { AlertNotificationsComponent } from 'src/app/Alerts/alert-notifications/alert-notifications.component';
import { MapCommon } from 'src/app/app-providers/map-base/MapCommon';
import { ClientsViewModel } from '../client-models/clientViewModel';

@Component({
  selector: 'app-client-add',
  templateUrl: './client-add.component.html',
  styleUrls: ['./client-add.component.scss']
})
export class ClientAddComponent implements OnInit {

  currentUser: number;
  clientNameModel = "";

  constructor(public modalRef: MdbModalRef<ClientAddComponent>, private vm: ClientsViewModel) {
    this.currentUser = MapCommon.getloggedInUser().id;
  }

  ngOnInit(): void {
  }

  addNewClient(){
    if (this.clientNameModel === '') {
      AlertNotificationsComponent.alertCall.showAlert("Please add client name", 'danger');
    }
    else if (this.clientNameModel.length <= 1 ) {
      AlertNotificationsComponent.alertCall.showAlert("Client is mandatory, minimum 2 is required", 'danger');
    } else {
      this.vm.addClient(this.clientNameModel, this.currentUser);
      this.modalRef.close()
    }
  }

}
