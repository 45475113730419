



<!-- <div style="margin-top: 20px;"></div> -->

<div *ngIf="showClearFilter" class="row shadow-1 p-3 sticky-top bg-white" style="z-index: 2;">
  <div style="margin-top: 15px;"></div>
  <a style="cursor: pointer;" (click)="clearSelection($event)">Clear filters</a>
</div>


<!-- <form #searchForm="ngForm" (ngSubmit)="searchSubmit($event)">
  <mdb-form-control class="mt-3">
    <input mdbInput type="text" id="searchname" [(ngModel)]="searchName" [ngModelOptions]="{standalone: true}"
    class="form-control" />
    <label mdbLabel class="form-label" for="searchname"><i class="fas fa-search"></i> Search</label>
  </mdb-form-control>
  </form> -->

  <mdb-form-control class="mt-3">
    <input mdbInput type="text" (click)="togglenameFilter(nameContains,'nameContains')" id="searchname" [(ngModel)]="nameContainsText" value="{{nameContainsText}}"
    (input)="handleUserInput('nameContains')"
    class="form-control" />
    <label mdbLabel class="form-label" for="searchname"><i class="fas fa-search"></i> Search</label>
  </mdb-form-control>
<!-- <p class="text-muted" style="margin-top: 10%; margin-bottom: 0px;"><strong>OWNER</strong></p>
<ng-select class="ownerSelection" [(ngModel)]="ownerOptions">
  <ng-option *ngFor="let o of owners" [value]="o.id">{{o.name}}</ng-option>
</ng-select> -->


<div class="p-1">

<div class="form-check mb-2">
  <input #createdByRadio mdbRadio (click)="sidebarFilter(createdBy,'createdBy', $event)" aria-controls="createdByRadio" aria-expanded="false"
    class="form-check-input" type="checkbox" name="createdByRadio" id="createdByRadio" [checked]="false" />
  <label class="form-check-label" for="createdByRadio"> Created By</label>
  <ng-select [(ngModel)]="createdById" id="createdBy" class="custSelect"  (change)='onCreatedBySelected()' mdbCollapse
    #createdBy="mdbCollapse" class="p-0">
    <ng-option *ngFor="let o of userList" [value]="o.id">{{o.email?.length > 0 ? o.email: o.username}}</ng-option>
  </ng-select>
</div>


<div class="form-check mb-2">
  <input #myClient mdbRadio (click)="sidebarFilter(client,'client',$event)" aria-controls="client" aria-expanded="false"
    class="form-check-input" type="checkbox" [(ngModel)]="clientCheckBox" name="flexRadioDefault2" id="flexRadioDefault2" [checked]="false" />
  <label class="form-check-label" for="flexRadioDefault2"> Client</label>
  <ng-select [(ngModel)]="clientId" id="client" (change)='onclientSelected()' class="custSelect" mdbCollapse #client="mdbCollapse">
    <ng-option *ngFor="let o of clientList" [value]="o.id">{{o.name}}</ng-option>
  </ng-select>

</div>


<div class="form-check mb-2 d-none">
  <input mdbRadio (click)="sidebarFilter(name,'name',$event)" aria-controls="name" aria-expanded="false"
    class="form-check-input" type="checkbox" name="flexRadioDefault3" id="flexRadioDefault3" [checked]="false" />
  <label class="form-check-label" for="flexRadioDefault3"> Name</label>

  <div class="mt-1" id="name" mdbCollapse #name="mdbCollapse">

    <div class="form-check">
      <input mdbRadio class="form-check-input" type="radio" name="flexRadioname" id="nameContains"
        (click)="togglenameFilter(nameContains,'nameContains', $event)" aria-controls="nameContains" />

      <label class="form-check-label" for="nameContains">Contain</label>
      <input mdbInput type="text" id="nameContains" mdbCollapse #nameContains="mdbCollapse"
        class="form-control" [(ngModel)]="nameContainsText" value="{{nameContainsText}}"
        (input)="handleUserInput('nameContains')" />
    </div>


    <div class="form-check">
      <input mdbRadio class="form-check-input" type="radio" name="flexRadioname" id="nameStartsWith"
        (click)="togglenameFilter(nameStartsWith,'nameStartsWith', $event)" aria-controls="nameStartsWith" />
      <label class="form-check-label" for="nameStartsWith">Starts With</label>
      <input mdbInput type="text" id="nameStartsWithform1" id="nameStartsWith" mdbCollapse #nameStartsWith="mdbCollapse"
        class="form-control" [(ngModel)]="nameStartsWithText" value="{{nameStartsWithText}}"
        (input)="handleUserInput('nameStartsWith')" />
    </div>
    <div class="form-check">
      <input mdbRadio class="form-check-input" type="radio" name="flexRadioname" id="nameEndsWith"
        (click)="togglenameFilter(nameEndsWith,'nameEndsWith', $event)" aria-controls="nameEndsWith" />
      <label class="form-check-label" for="nameEndsWith">Ends With</label>
      <input mdbInput type="text" id="nameEndsWithform1" id="nameEndsWith" mdbCollapse #nameEndsWith="mdbCollapse"
        class="form-control" [(ngModel)]="nameEndsWithText" value="{{nameEndsWithText}}"
        (input)="handleUserInput('nameEndsWith')" />
    </div>

    <div class="form-check">
      <input mdbRadio class="form-check-input" type="radio" name="flexRadioname" id="nameIs"
        (click)="togglenameFilter(nameIs,'nameIs', $event)" aria-controls="nameIs" />
      <label class="form-check-label" for="nameIs">Is</label>
      <input mdbInput type="text" id="nameIsform1" id="nameIs" mdbCollapse #nameIs="mdbCollapse" class="form-control"
        [(ngModel)]="nameIsText" value="{{nameIsText}}" (input)="handleUserInput('nameIs')" />
    </div>

  </div>
</div>

<div class="form-check mb-2">
  <input mdbRadio (click)="sidebarFilter(status,'status',$event)" aria-controls="statuscollapse" aria-expanded="false"
    class="form-check-input" type="checkbox" name="flexRadioDefault8" id="flexRadioDefault8" [checked]="false" />
  <label class="form-check-label" for="flexRadioDefault8">Status</label>
  <div class="mt-1" id="status" mdbCollapse #status="mdbCollapse">
    <!-- <div class="form-check">
      <input mdbRadio class="form-check-input" type="radio" (click)="onstatusSelected('yes', $event)" name="flexRadiostatus"
        id="statusdyes" />
      <label class="form-check-label" for="statusdyes">Active</label>
    </div>
    <div class="form-check">
      <input mdbRadio class="form-check-input" type="radio" (click)="onstatusSelected('no', $event)" name="flexRadiostatus"
        id="statusdno" />
      <label class="form-check-label" for="statusdno">Inactive</label>
    </div> -->

    <!-- <div class="form-check">
      <input mdbRadio class="form-check-input" type="radio" (click)="onstatusSelected('new', $event)" name="flexRadiostatus"
        id="statusdnew" />
      <label class="form-check-label" for="statusdnew">New</label>
    </div>
    <div class="form-check">
      <input mdbRadio class="form-check-input" type="radio" (click)="onstatusSelected('scheduled', $event)" name="flexRadiostatus"
        id="statusdscheduled" />
      <label class="form-check-label" for="statusdscheduled">Scheduled</label>
    </div>
    <div class="form-check">
      <input mdbRadio class="form-check-input" type="radio" (click)="onstatusSelected('processing', $event)" name="flexRadiostatus"
        id="statusdprocessing" />
      <label class="form-check-label" for="statusdprocessing">Processing</label>
    </div>
    <div class="form-check">
      <input mdbRadio class="form-check-input" type="radio" (click)="onstatusSelected('paused', $event)" name="flexRadiostatus"
        id="statusdpaused" />
      <label class="form-check-label" for="statusdpaused">Paused</label>
    </div>
    <div class="form-check">
      <input mdbRadio class="form-check-input" type="radio" (click)="onstatusSelected('finished', $event)" name="flexRadiostatus"
        id="statusdfinished" />
      <label class="form-check-label" for="statusdfinished">Finished</label>
    </div>
    <div class="form-check">
      <input mdbRadio class="form-check-input" type="radio" (click)="onstatusSelected('rejected', $event)" name="flexRadiostatus"
        id="statusdrejected" />
      <label class="form-check-label" for="statusdrejected">Rejected</label>
    </div> -->
    <ng-select [(ngModel)]="StatusStep" id="StepStatus" class="custSelect"  (change)="onstatusSelected($event)" >
      <ng-option *ngFor="let o of StatusList" [value]="o">{{o}}</ng-option>
    </ng-select>
  </div>
</div>


<div class="form-check mb-2">

  <!-- (click)="sidebarFilter(createdAtCollapse,'createdAtCollapse', $event)" -->
  <input #createdAtRadio mdbRadio [disabled]="true"
  aria-controls="createdAtRadio" aria-expanded="false"
    class="form-check-input" type="checkbox" name="createdAtRadioflexRadio" id="createdAtRadioflexRadio" [checked]="true" />
  <label class="form-check-label" for="createdAtRadioflexRadio"> Created At</label>

    <input type="text" placeholder="Dates" class="form-control form-control-sm w-100" bsDaterangepicker [bsValue]="user_report_dates" placement="right"
    [bsConfig]="{ isAnimated: true, containerClass: 'theme-default', rangeInputFormat: 'DD/MM/YYYY'}" id="createdAt" #createdAt
    #dp="bsDaterangepicker" [maxDate]="bsMaxDate" (bsValueChange)="onValueChange($event)">

  <!-- <div class="mt-1" id="createdAtCollapse" mdbCollapse #createdAtCollapse="mdbCollapse">
  </div> -->
</div>



</div>
