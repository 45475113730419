import { Component, OnInit,  ViewChild, ElementRef  } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ClientsModel, ClientsResponseModel, ClientUserResponseModel } from './client-models/clientModel';
import { ClientsViewModel } from './client-models/clientViewModel';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ClientAddComponent } from './client-add/client-add.component';
import { ClientEditComponent } from './client-edit/client-edit.component';
import { ClientDeleteComponent } from './client-delete/client-delete.component';
import { NgForm } from '@angular/forms';
import { MapCommon } from '../app-providers/map-base/MapCommon';
import { Router } from '@angular/router';
@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {
  @ViewChild("clientContainer") clientContainer: ElementRef;
  clientsList = [];
  clientsListB4Search = [];
  totalClientRecieved: number;
  clientAddModalRef: MdbModalRef<ClientAddComponent>;
  clientEditModalRef: MdbModalRef<ClientEditComponent>;
  clientDeleteModalRef: MdbModalRef<ClientDeleteComponent>;
  currentUser: number;
  page_index = 1;
  clientResponseModel = new BehaviorSubject<ClientsResponseModel>(
    new ClientsResponseModel()
  );

  clientUserResponseModel = new BehaviorSubject<ClientUserResponseModel>(
    new ClientUserResponseModel()
  );

  userList = [];
  createdById: number = null;

  searchValue = '';
  @ViewChild(NgForm) searchForm: NgForm;

  constructor(private vm: ClientsViewModel, private modalService: MdbModalService, private router: Router) {
    this.currentUser = MapCommon.getloggedInUser().id;
    this.vm.parentClients = this;
    this.vm.subscribeAllClientsVariable(this.clientResponseModel, this.clientUserResponseModel);
    this.vm.getAllUsers();
    this.vm.getAllClients(this.page_index, 30);
  }

  ngOnInit(): void {

  }

  onCreatedBySelected() {
    this.callFinalFilter();
  }

  gotoCampaignPage(input) {
    localStorage.setItem("selectedClient", input.id);
    this.router.navigate(['campaigns']);
  }

  getUserName(id) {
    var userItem = this.userList.filter(i => i.id == Number(id));
    if (userItem.length > 0) {
      return userItem[0].first_name + " " + userItem[0].last_name;
    }
  }

  searchSubmit(e: any) {
    this.callFinalFilter();
  }

  callFinalFilter() {
    this.clientContainer.nativeElement.scrollTop = 0;
    this.page_index=1;
    this.vm.searchClients(this.searchForm.value.search, this.createdById,this.page_index,30)
  }

  clientsReceived() {
    //  this.clientsList = this.clientResponseModel.value.results;
    if (this.clientsListB4Search.length == 0)
      this.clientsList = this.clientResponseModel.value.results;
    else {
      this.clientsList = this.clientResponseModel.value.results;
      if (this.clientResponseModel.value.previous !== null) {
        for (var j = 0; j < this.clientsList.length; j++) {
          var index = this.clientsListB4Search.findIndex(x => x.id == this.clientsList[j].id);
          if (index === -1)
            this.clientsListB4Search.push(this.clientsList[j]);
        }
        this.clientsList = this.clientsListB4Search;
      }
    }
    this.totalClientRecieved = this.clientResponseModel.value.count;
  }

  userRecieved() {
    this.userList = this.clientUserResponseModel.value.results;
  }

  addClient() {
    this.clientAddModalRef = this.modalService.open(ClientAddComponent)
  }

  deleteClient(clientId: number, clientName: string) {
    this.clientDeleteModalRef = this.modalService.open(ClientDeleteComponent, {
      data: {
        deleteClientId: clientId,
        deleteClientName: clientName,
        currentUser: this.currentUser
      }
    });
  }


  filterOnReport(client_id) {
    localStorage.setItem("selectedClient", client_id);
    this.router.navigate(['reports/campaigns']);
  }


  editClient(clientModel: ClientsModel) {
    clientModel.modified_by = this.currentUser;
    clientModel.modified_at = new Date;
    const json_data = JSON.stringify(clientModel);
    this.clientEditModalRef = this.modalService.open(ClientEditComponent, {
      data: {
        clientModel: JSON.parse(json_data),
        currentUser: this.currentUser
      }
    });
  }

  // This method is being called once user hits bottom while scrolling Clients
  onClientScroll() {
    if (this.clientResponseModel.value.next) {
      this.clientsListB4Search = this.clientsList;
      this.page_index++;
      this.vm.getAllClients(this.page_index, 30);
    }
  }

}
