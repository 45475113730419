
    <div class="row" *ngIf="!connectedMailbox"> 
        <div class="warning-msg m-0 text-center text-white">
        <span><i class="fas fa-exclamation-triangle"></i>
        You haven't connected an email account yet. Please connect to mailbox before you make any action here. You can connect your email in 
        <a routerLink="/settings/mailbox">mailbox settings</a></span>
        </div>
    </div>

 



