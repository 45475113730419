import { Component, OnInit } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { MapCommon } from 'src/app/app-providers/map-base/MapCommon';
import { CampaignRunLogModel } from '../../campaign-model/campaign-model';
import { CampaignViewModel } from '../../campaign-view-model/campaign-view-model';
import { CampaignAddStepComponent } from '../../campaign-add-step/campaign-add-step.component';
import { ReplyProspectComponent } from '../../../reply-prospect/reply-prospect.component'
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-view-campaign-detail-email',
  templateUrl: './view-campaign-detail-email.component.html',
  styleUrls: ['./view-campaign-detail-email.component.scss']
})
export class ViewCampaignDetailEmailComponent implements OnInit {

  campaignEmailData: CampaignRunLogModel;
  prospectInterests = MapCommon.getProspectInterests();
  selectedIntarest: number = null;
  addStepModalRef: MdbModalRef<ViewCampaignDetailEmailComponent>;
  campaignId: number

  campaignRunlogModel = new BehaviorSubject<CampaignRunLogModel>(
    new CampaignRunLogModel()
  );
  constructor(
    public modalRef: MdbModalRef<ViewCampaignDetailEmailComponent>, 
    private vm: CampaignViewModel,
    private modalService: MdbModalService,
    private router: Router
    ) {
      
    }

  ngOnInit(): void {
    this.vm.subscribeCampaignRunlogVariables(this.campaignRunlogModel);
    if(this.campaignEmailData.prospect_interest_category){
      this.selectedIntarest = this.campaignEmailData.prospect_interest_category;
    }
  }


  OnSelectIntarest(prospectInterests: number){
    this.selectedIntarest = prospectInterests;
    this.updateRunLog();
  }

  removeIntarest(){
    this.selectedIntarest = null;
    this.updateRunLog();
  }

  campaignRunlogReceived(){
    this.campaignEmailData = this.campaignRunlogModel.value; 
  }  

  updateRunLog(){
    this.vm.parentViewCampaignDetailEmailComponent = this;
    this.vm.updateProspectIntarestRunlog(this.campaignEmailData.id, this.selectedIntarest);
  }

  prospectIntarestUpdated(){
    
  }




  
  replyPopUp() {
    // this.modalRef.close(true);
    const modalOptions = {
      data: {
        campaignId: this.campaignId,
        campaignEmailData: this.campaignEmailData,
        currentUser: MapCommon.getloggedInUser().id
      },
      modalClass: 'modal-lg'
    }

    this.addStepModalRef = this.modalService.open(ReplyProspectComponent, modalOptions);
    this.addStepModalRef.onClose.subscribe((message: any) => { 
      if (message === true) {
      this.vm.parentViewCampaignDetailEmailComponent = this;
      this.vm.retrieveCampaignRunlogById(this.campaignEmailData.id)
      }
    });
  }


}
