<div class="px-4 py-5 my-5 text-center">
    <img class="d-block mx-auto mb-4" src="../../assets/images/logo-mini.png" alt="" width="72" height="57">
    <h1 class="display-5 fw-bold">BuyerForesight</h1>
    <div class="col-lg-6 mx-auto">
      <p class="lead mb-4">
        We launched BuyerForesight in 2014 to fill a gap we saw to deliver highly-qualified sales conversations for B2B tech companies increasingly selling to line of business leaders instead of IT.

        </p>

        <p class="lead mb-4">
            We believe the only way to build sales pipeline for high-consideration B2B sales is to have trust-based relationships with key buyers and influencers. Our model creates those relationships by offering knowledge and opportunity to prospects before asking for a sales meeting.
       
          </p>

            <p class="lead mb-4">
                Our path forward is leveraging AI and NLP to make this approach scale and generate billions in new sales pipeline for our customers.
   
                </p>

       
     
      <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
        <a href="https://www.buyerforesight.com" class="btn btn-primary btn-lg px-4 gap-3">Visit BuyerForesight for more</a>
        <!-- <button type="button" class="btn btn-outline-secondary btn-lg px-4">Secondary</button> -->
      </div>
    </div>
  </div>