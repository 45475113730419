import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { MapCommon } from 'src/app/app-providers/map-base/MapCommon';
import { LoginUserModel } from 'src/app/login/models/login-model';
import { CampaignViewModel } from '../../campaign-view-model/campaign-view-model';

@Component({
  selector: 'app-step-send-test',
  templateUrl: './step-send-test.component.html',
  styleUrls: ['./step-send-test.component.scss']
})
export class StepSendTestComponent implements OnInit {

  currentUser: LoginUserModel = null;
  stepId: number;
  recipientEmail: string = null;
  formError: string = null;
  // connected_mailbox: string;


  constructor(public modalRef: MdbModalRef<StepSendTestComponent>, private vm: CampaignViewModel) {
    this.currentUser = MapCommon.getloggedInUser();
    if(this.currentUser){
      this.recipientEmail = this.currentUser.email
    }
    // this.connectedMailbox = localStorage.getItem('map_connected_mailbox');
  }

  sendTestConfirmed(){
    if(!this.recipientEmail || !this.recipientEmail.includes("@") || !this.recipientEmail.includes('.')) {
      this.formError = "Please enter a valid email id";
    } else {
      this.vm.sendTestEmail(this.stepId, this.recipientEmail, this.currentUser.id);
      this.modalRef.close()
    }
  }
 
  ngOnInit(): void {
  }

}
