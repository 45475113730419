import { Component, OnInit } from '@angular/core';
import { ProspectModel } from '../model/prospect-model';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { ProspectViewModel } from '../viewModel/prospect-viewmodel';
import { MapAPIService } from "src/app/app-providers/services/map-module";
import { ProspectGroupResponseModel } from 'src/app/imports/import-model/import-model';
import { ProspectStatusResponseModel } from '../model/prospect-model';
// import { Prospect1 } from 'src/app/imports/import-model/import-model';
@Component({
  selector: 'app-prospect-edit',
  templateUrl: './prospect-edit.component.html',
  styleUrls: ['./prospect-edit.component.scss'],
  providers: [MapAPIService, ProspectViewModel]
})
export class ProspectEditComponent implements OnInit {
  prospectData: any = [];
  prospectGroupData: any = [];
  prospectStatusData: any = [];
  exampleData = [];
  pglistItems: any = [];
  statuslistItems: any = [];
  value1: any = [];
  updatedPG: any = [];
  prospectId: number;
  data: any = {};
  routeAllData: any;
  prospectResponseModel = new BehaviorSubject<ProspectModel>(
    new ProspectModel()
  );
  prospectGroupResponseModel = new BehaviorSubject<ProspectGroupResponseModel>(
    new ProspectGroupResponseModel()
  );
  prospectStatusResponseModel = new BehaviorSubject<ProspectStatusResponseModel>(
    new ProspectStatusResponseModel()
  );
  prospectModel = new ProspectModel();
  constructor(private vm: ProspectViewModel, private router: Router) {
    if (this.router.getCurrentNavigation().extras.state) {
      this.routeAllData = this.router.getCurrentNavigation().extras.state;
      if (this.routeAllData) {
        this.data.routeData = this.routeAllData.routeData ? JSON.parse(this.routeAllData.routeData) : '';
      } else {
        // retrive id from activated route 
        // Call API
      }
    }

  }

  public options: any;

  clientId: any;
  ngOnInit(): void {
    this.vm.parentProspectEditComponent = this;
    if (this.data.routeData == undefined) {
       var url = window.location.href;
      this.prospectId = Number(url.substring(url.lastIndexOf('/') + 1));
      this.redirectToPreviousPage(this.prospectId);
     
     
      // this.vm.subscribeGroupVariable(this.prospectGroupResponseModel);
      // this.vm.subscribeProspectEditDetailVariables(this.prospectResponseModel);
      // this.vm.getProspectById(this.prospectId, 'prospect-edit');
    }
    else {
      this.prospectModel = this.data.routeData.prospectData;
      this.prospectGroupData = this.data.routeData.prospectGroupData;
      this.prospectStatusData = this.data.routeData.prospectAllStatusData;
      
      this.fillProspectGroupItemList();
      this.fillProspectStatusItemList();
      for (var i = 0; i < this.prospectModel.prospect_group.length; i++) {
        this.value1.push(this.prospectModel.prospect_group[i].toString());
      }
    }

    this.options = {
      width: '100%',
      multiple: true,
      tags: true
    };
  }

  ProspectDetailReceived() {
    this.prospectModel = this.prospectResponseModel.value;
  
    this.vm.getAllProspectGroups('prospect-edit');
  }

  ProspectGroupReceived() {
    this.prospectGroupData = this.prospectGroupResponseModel.value.results;
  
   this.fillProspectGroupItemList();
    for (var i = 0; i < this.prospectModel.prospect_group.length; i++) {
      this.value1.push(this.prospectModel.prospect_group[i].toString());
    }
  }
  
  ProspectStatusReceived() {
    this.prospectStatusData = this.prospectStatusResponseModel.value.results;
   this.fillProspectStatusItemList();
    // for (var i = 0; i < this.prospectModel.prospect_group.length; i++) {
    //   this.value1.push(this.prospectModel.prospect_group[i].toString());
    // }
  }
  
  // this.fillProspectStatusItemList();
  fillProspectStatusItemList() {
    for (var i = this.prospectStatusData.length - 1; i >= 0; i--) {
      this.statuslistItems.push({ 'id': this.prospectStatusData[i].id, 'text': this.prospectStatusData[i].name });
    }
  }

  fillProspectGroupItemList() {
    for (var i = this.prospectGroupData.length - 1; i >= 0; i--) {
      this.pglistItems.push({ 'id': this.prospectGroupData[i].id, 'text': this.prospectGroupData[i].name });
    }
    //  if (this.data.routeData == undefined)

  }

  redirectToPreviousPage(prospectID)
  {
    const prospectDetailUrl = '/prospects/' + prospectID
    this.router.navigate([prospectDetailUrl]);
  }

  updateProspect() {
    //var ss= [Number];
    for (var i = 0; i < this.value1.length; i++) {
      this.updatedPG.push(Number(this.value1[i]));
    }
    this.prospectModel.prospect_group = this.updatedPG;
    this.vm.updateProspect(this.prospectModel);
    // this.modalRef.close();
    //console.log(this.prospectModel.prospect_group);
    // this.prospectGroupModel = {};
  }

}
