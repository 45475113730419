<div class="ms-sm-auto" style="width: 100%;">

  <div class="row" style="
  background-image: url(../../assets/images/map-background.jpg); 
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;">

    <div class="col"></div>

    <div class="col align-self-stretch" style="margin-top: 100px;">

      <div class="card p-2">
        <div class="card-body">

          <div class="row mt-1">
            <div class="col-12" style="text-align: center;">
              <img class="logo" src="../../assets/images/logo-mini.png">
            </div>
          </div>

          <div class="mt-2 text-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="145.665" height="65" viewBox="0 0 145.665 65">
              <g id="Group_731" data-name="Group 731" transform="translate(238 -76)">
                <text id="MAP" transform="translate(-238 129)" fill="#43116e" font-size="49"
                  font-family="SegoeUI, Segoe UI">
                  <tspan x="0" y="0">MAP</tspan>
                </text>
                <path id="Icon_simple-minutemailer" data-name="Icon simple-minutemailer"
                  d="M10.028,4.055l6.218,6.73-4.387,8.647,9.264-1.66L25.34,27.948,43.255,4.456ZM8.882,18.632l1.725-1.418-2.224.7-.227-.324.919-.892-1.206.487a12.276,12.276,0,0,0-4.025,20.38,12.189,12.189,0,0,1-1.6-6.047,12.583,12.583,0,0,1,7.777-11.571l.568-1.381-1.352.6-.38-.514Zm7.865-3.551L19.627,10,36.565,6.03Z"
                  transform="translate(-135.59 92.196)" fill="#ef6c00" />
              </g>
            </svg>
          </div>

          <hr style="height: 0.5px">

          <div class="text-center" *ngIf="!showPasswordResetSuccess">
            <h5 class="card-title">Change Password</h5>
          </div>

          <div *ngIf="showCheckingToken">
            <div class="text-center mt-1">
              <app-spinner></app-spinner>
            </div>
          </div>


          <div *ngIf="!showBrokenUrl && !showPasswordResetSuccess && !showCheckingToken">
            <mdb-form-control class="mt-2">
              <input mdbInput type="password" [(ngModel)]="password1" id="password1" class="form-control"
                placeholder="New Password" />
            </mdb-form-control>

            <mdb-form-control class="mt-2">
              <input mdbInput type="password" [(ngModel)]="password2" id="password2" class="form-control"
                placeholder="Confirm Password" />
            </mdb-form-control>

            <div class="mt-2" style="color:  red;" *ngIf="showPasswordMatchError">
              <ul>
                <li> Both the password should be same.</li>
                <li> The password must contain at least eight characters, uppercase, lowercase and special characters.
                </li>

              </ul>

            </div>


          </div>

          <div *ngIf="showPasswordResetSuccess">
            <div class="text-center">
              <!-- <p><i class="far fa-check-circle"></i></p> -->
              <div class="swal2-icon swal2-success swal2-animate-success-icon" style="display: flex;">
                <div class="swal2-success-circular-line-left" style="background-color: rgb(255, 255, 255);"></div>
                <span class="swal2-success-line-tip"></span>
                <span class="swal2-success-line-long"></span>
                <div class="swal2-success-ring"></div>
                <div class="swal2-success-fix" style="background-color: rgb(255, 255, 255);"></div>
                <div class="swal2-success-circular-line-right" style="background-color: rgb(255, 255, 255);"></div>
              </div>
              <h6>Password Changed</h6>
              <p>Your password has been changed successfully.</p>
              <p>Use your new password to log in.</p>
            </div>
          </div>

          
          <div *ngIf="showBrokenUrl" class="text-center">

            <div class="swal2-icon swal2-error swal2-animate-error-icon" style="display: flex;"><span
                class="swal2-x-mark"><span class="swal2-x-mark-line-left"></span><span
                  class="swal2-x-mark-line-right"></span></span></div>
            <p>Invalid url, we can't process your request at this moment.</p>
            <p>Please generate new reset link again.</p>
          </div>

          <div class="d-flex justify-content-center mt-3">
            <a *ngIf="showPasswordResetSuccess || showBrokenUrl" routerLink="/login" mdbRipple type="button" class="btn btn-light">Click
              here to login</a>
            <button *ngIf="!showBrokenUrl && !showPasswordResetSuccess && !showCheckingToken"
              (click)="confirmPassword()" mdbRipple type="button" class="btn btn-primary">Change password</button>
          </div>



        </div>
      </div>


    </div>


    <div class="col"></div>
  </div>



</div>