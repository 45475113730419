import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MapCommon } from '../app-providers/map-base/MapCommon';
import { LoginUserModel } from '../login/models/login-model';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
 
  currentUser: LoginUserModel = null;
  isLoggedIn: boolean = false;
  domain: string = '';
  firstname: string = '';
  lastname: string = '';
  
  constructor(private router: Router) {
    const localStorage_user = MapCommon.getloggedInUser();
    if (localStorage_user.id) {
      this.currentUser = localStorage_user;
      this.isLoggedIn = true;
    }
  }

  ngOnInit(): void {
    this.firstname = this.currentUser.first_name;
    this.lastname = this.currentUser.last_name;
    this.domain = this.currentUser.username.split("@")[1]

  }

  logoutMe(): void{ 
    MapCommon.removeItemsOnLogOut();
    window.location.reload();
    // this.router.navigate(['login']);
  }

}
