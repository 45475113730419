import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardService as AuthGuard } from './admin/services/auth-guard.service';
import { BestSendingTimeComponent } from './analytics/best-sending-time/best-sending-time.component';
import { LinkTrackingAnalyticsComponent } from './analytics/link-tracking-analytics/link-tracking-analytics.component';
import { OpenTrackingAnalyticsComponent } from './analytics/open-tracking-analytics/open-tracking-analytics.component';
import { OptOutTrackingComponent } from './analytics/opt-out-tracking/opt-out-tracking.component';

import { CampaignDetailComponent } from './campaigns/campaign-detail/campaign-detail.component';
import { CampaignStepReviewComponent } from './campaigns/campaign-step-review/campaign-step-review.component';
import { CampaignsComponent } from './campaigns/campaigns.component';
import { ClientsComponent } from './clients/clients.component';
import { DncClientComponent } from './dnc/dnc-client/dnc-client.component';
import { DncDomainComponent } from './dnc/dnc-domain/dnc-domain.component';
import { DncGlobalComponent } from './dnc/dnc-global/dnc-global.component';
import { DncComponent } from './dnc/dnc.component';
import { ImportDncComponent } from './dnc/import-dnc/import-dnc.component';
import { HelpAboutComponent } from './help/help-about/help-about.component';
import { HelpDocsComponent } from './help/help-docs/help-docs.component';
import { HelpFeedbackComponent } from './help/help-feedback/help-feedback.component';
import { HomeComponent } from './home/home.component';
import { CopyPasteImportComponent } from './imports/copy-paste-import/copy-paste-import.component';
import { ImportColMappingComponent } from './imports/import-col-mapping/import-col-mapping.component';
import { ImportsComponent } from './imports/imports.component';
import { NewImportV2Component } from './imports/new-import-v2/new-import-v2.component';
import { NewImportComponent } from './imports/new-import/new-import.component';

import { OneProspectImportComponent } from './imports/one-prospect-import/one-prospect-import.component';
import { LoginComponent } from './login/login.component';
import { PasswordConfirmComponent } from './login/password-confirm/password-confirm.component';
import { PasswordResetComponent } from './login/password-reset/password-reset.component';
import { MaintenanceModeComponent } from './maintenance-mode/maintenance-mode.component';
import { ProspectDetailComponent } from './prospects/prospect-detail/prospect-detail.component';
import { ProspectEditComponent } from './prospects/prospect-edit/prospect-edit.component';
import { ProspectsComponent } from './prospects/prospects.component';
import { ReportsCampaignsComponent } from './reports/reports-campaigns/reports-campaigns.component';
import { ReportsComponent } from './reports/reports.component';
import { SettingsDncComponent } from './settings/settings-dnc/settings-dnc.component';
import { SettingsGroupsComponent } from './settings/settings-groups/settings-groups.component';
import { SettingsMailboxComponent } from './settings/settings-mailbox/settings-mailbox.component';
import { SettingsPersonalComponent } from './settings/settings-personal/settings-personal.component';
import { SettingsSendingWindowsComponent } from './settings/settings-sending-windows/settings-sending-windows.component';
import { AddSendingWindowsComponent } from './settings/settings-sending-windows/add-sending-windows/add-sending-windows.component';
import { UpdateSendingWindowsComponent } from './settings/settings-sending-windows/update-sending-windows/update-sending-windows.component';
import { SettingsStatusesComponent } from './settings/settings-statuses/settings-statuses.component';
import { LinkTrackingComponent } from './tools/link-tracking/link-tracking.component';
import { UnsubInternalPageComponent } from './unsubscribe/unsub-internal-page/unsub-internal-page.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { TemplatesComponent } from './templates/templates.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SubjectLineComponent } from './analytics/subject-line/subject-line.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { RephraseAiComponent } from './rephrase-ai/rephrase-ai.component';

const routes: Routes = [

  // Main Path
  {path: '', component: HomeComponent},

  // Dashboard
  {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },

  // Login
  {path: 'login', component: LoginComponent},
  {path: 'login/password-reset', component: PasswordResetComponent},
  {path: 'login/password-confirm', component: PasswordConfirmComponent},


  // Prospects

  {path: 'prospects', component: ProspectsComponent, canActivate: [AuthGuard] },
  {path: 'prospects/:id', component: ProspectDetailComponent, canActivate: [AuthGuard] },
  {path: 'prospects/edit/:id', component: ProspectEditComponent, canActivate: [AuthGuard] },
  // {path: 'app-prospect-edit', component: ProspectEditComponent, canActivate: [AuthGuard] },
  // {path: 'app-prospect-detail', component: ProspectDetailComponent, canActivate: [AuthGuard] },
  // Imports
  {path: 'imports', component: ImportsComponent, canActivate: [AuthGuard] },
  {path: 'imports/new', component: NewImportComponent, canActivate: [AuthGuard] },
  {path: 'imports/new-v2', component: NewImportV2Component, canActivate: [AuthGuard] },
  {path: 'imports/copy-paste', component: CopyPasteImportComponent, canActivate: [AuthGuard] },
  {path: 'imports/one-prospect', component: OneProspectImportComponent, canActivate: [AuthGuard] },
  {path: 'imports/column-mapping', component: ImportColMappingComponent, canActivate: [AuthGuard] },

  // Campaigns
  {path: 'campaigns', component: CampaignsComponent, canActivate: [AuthGuard] },
  {path: 'campaigns/:id', component: CampaignDetailComponent, canActivate: [AuthGuard] },
  {path: 'campaigns/:id/review/:stepId', component: CampaignStepReviewComponent, canActivate: [AuthGuard] },

  // Template
  {path: 'templates', component: TemplatesComponent, canActivate: [AuthGuard] },

  // Clients
  {path: 'clients', component: ClientsComponent, canActivate: [AuthGuard] },

  // Reports
  {path: 'reports', redirectTo: 'reports/campaigns', pathMatch: 'full'},
  {path: 'reports/campaigns', component: ReportsCampaignsComponent, canActivate: [AuthGuard] },

  // AI
  {path: 'rephraseAI', component: RephraseAiComponent,},
  //  canActivate: [AuthGuard] 


  // Analytics
  {path: 'analytics', redirectTo: 'analytics/best-sending-time', pathMatch: 'full'},
  {path: 'analytics/link-tracking', component: LinkTrackingAnalyticsComponent, canActivate: [AuthGuard] },
  {path: 'analytics/open-tracking', component: OpenTrackingAnalyticsComponent, canActivate: [AuthGuard] },
  {path: 'analytics/opt-out-tracking', component: OptOutTrackingComponent, canActivate: [AuthGuard] },
  {path: 'analytics/best-sending-time', component: BestSendingTimeComponent, canActivate: [AuthGuard] },
  {path: 'analytics/subject-line', component: SubjectLineComponent, canActivate: [AuthGuard] },

  // Settings
  {path: 'settings', redirectTo: 'settings/mailbox', pathMatch: 'full'},
  {path: 'settings/personal', component: SettingsPersonalComponent, canActivate: [AuthGuard] },
  {path: 'settings/mailbox', component: SettingsMailboxComponent, canActivate: [AuthGuard] },
  {path: 'settings/statuses', component: SettingsStatusesComponent, canActivate: [AuthGuard] },
  {path: 'settings/groups', component: SettingsGroupsComponent, canActivate: [AuthGuard] },
  {path: 'settings/sending-windows', component: SettingsSendingWindowsComponent, canActivate: [AuthGuard] },
  {path: 'settings/sending-windows/add', component: AddSendingWindowsComponent, canActivate: [AuthGuard] },
  {path: 'settings/sending-windows/edit/:id', component: UpdateSendingWindowsComponent, canActivate: [AuthGuard] },
  {path: 'settings/dnc', component: SettingsDncComponent, canActivate: [AuthGuard] },


  // DNC
  {path: 'dnc', redirectTo: 'dnc/domain', pathMatch: 'full'},
  {path: 'dnc/domain', component: DncDomainComponent, canActivate: [AuthGuard] },
  {path: 'dnc/client', component: DncClientComponent, canActivate: [AuthGuard] },
  {path: 'dnc/global', component: DncGlobalComponent, canActivate: [AuthGuard] },
  {path: 'dnc/import-dnc', component: ImportDncComponent, canActivate: [AuthGuard] },

  {path: 'help', redirectTo: 'help/about', pathMatch: 'full'},
  {path: 'help/about', component: HelpAboutComponent, canActivate: [AuthGuard] },
  {path: 'help/docs', component: HelpDocsComponent, canActivate: [AuthGuard] },
  {path: 'help/feedback', component: HelpFeedbackComponent, canActivate: [AuthGuard] },


  // Unsubscribe
  {path: 'opt-out/unsub', component: UnsubInternalPageComponent},
  {path: 'opt-out/:id', component: UnsubscribeComponent},

  // Tracking
  {path: 'source/:id', component: LinkTrackingComponent},

  // maintenance-mode
  {path: 'maintenance-mode', component: MaintenanceModeComponent},

  // unauthorized
  {path: 'unauthorized', component: UnauthorizedComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
