<div class="ms-sm-auto" style="width: 90%;">




  <div class="row" style="height: 100%;">


    <div class="col-12 m-0"
      style="height: 100vh; overflow-x: hidden; overflow-y: auto; background-color: rgb(243, 243, 243);">

      <!-- <div class="row shadow-1 p-3 sticky-top bg-white" style="z-index: 2;">
        <div class="col-md-4">
          <h4 class="map-text-header">Dashboard 
          </h4>
        </div> 
      </div> -->


 

      <div class="row p-2 mt-2">

        <div class="col-12 d-flex justify-content-between pb-3">
          <div class="">
            <h4 class="map-text-header pt-1 m-0">Hello {{currentUser.first_name}}!
            </h4>
          </div>
            <div>
              <div class="btn-group shadow-0" role="group">
                <button type="button" class="btn btn-sm btn-light" (click)="selectOverviewButton(item.id)"
                *ngFor="let item of overviewButtons;" id="overviewButton{{item.id}}" [disabled]="item['selected']"
                data-mdb-color="dark">{{item.name}}</button>
              </div>
            </div>
        </div>

        <div class="col-3">
          <div class="text-center dash-item shadow-1"> 
            <h3 class="map-color2 p-2" *ngIf="!loadingOverviewStats"> 
              {{dashboardResponseModel.value?.client_count ?
                dashboardResponseModel.value?.client_count : '0'}}
            </h3>

            <div class="map-color2 p-2" *ngIf="loadingOverviewStats">
              <div  class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
              
           
            <p class="text-white bg-primary p-2">
              <i class="fas fa-boxes"></i>
              Clients
            </p>
          </div>
        </div>

        <div class="col-3">
          <div class="text-center dash-item shadow-1"> 
            <h3 class="map-color2 p-2" *ngIf="!loadingOverviewStats"> {{dashboardResponseModel.value?.campaign_count ?
              dashboardResponseModel.value?.campaign_count : '0'}}
            </h3>

            <div class="map-color2 p-2" *ngIf="loadingOverviewStats">
              <div  class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>


            <p class="text-white bg-primary p-2">
              <i class="fas fa-paper-plane"></i>
              Campaigns
            </p>
          </div>
        </div>

        <div class="col-3">
          <div class="text-center dash-item shadow-1"> 
            <h3 class="map-color2 p-2" *ngIf="!loadingOverviewStats"> {{dashboardResponseModel.value?.step_count ?
              dashboardResponseModel.value?.step_count : '0'}}
            </h3>

            <div class="map-color2 p-2" *ngIf="loadingOverviewStats">
              <div  class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>


            <p class="text-white bg-primary p-2">
              <i class="fas fa-paper-plane"></i>
              Steps
            </p>
          </div>
        </div>
 

        <div class="col-3">
          <div class="text-center dash-item shadow-1"> 
            <h3 class="map-color2 p-2" *ngIf="!loadingOverviewStats"> {{dashboardResponseModel.value?.prospect_count ?
              dashboardResponseModel.value?.prospect_count : '0'}}
            </h3>
            <div class="map-color2 p-2" *ngIf="loadingOverviewStats">
              <div  class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
            <p class="text-white bg-primary p-2">
              <i class="fas fa-users"></i> Prospects
            </p>
          </div>
        </div>

       

        <div class="col-3">
          <div class="text-center dash-item shadow-1"> 
            <h3 class="map-color2 p-2" *ngIf="!loadingOverviewStats"> 
              {{dashboardResponseModel.value?.email_sent_count  ?
                dashboardResponseModel.value?.email_sent_count  : '0'}}
            </h3>
            <div class="map-color2 p-2" *ngIf="loadingOverviewStats">
              <div  class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
            <p class="text-white bg-primary p-2">
              <i class="far fa-envelope"></i>
             Emails
            </p>
          </div>
        </div>

        <div class="col-3">
          <div class="text-center dash-item shadow-1 "> 
            <h3 class="map-color2 p-2" *ngIf="!loadingOverviewStats"> 
              {{dashboardResponseModel.value?.email_reply_count  ?
                dashboardResponseModel.value?.email_reply_count  : '0'}}
                <span class="text-fix">
                  {{dashboardResponseModel.value?.reply_percentage ? 
                    dashboardResponseModel.value?.reply_percentage:0}}%</span>
            </h3>
            <div class="map-color2 p-2" *ngIf="loadingOverviewStats">
              <div  class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
            <p class="text-white bg-primary p-2">
              <i class="far fa-envelope"></i>
             Replies
            </p>
          </div>
        </div>


        <div class="col-3">
          <div class="text-center dash-item shadow-1"> 
            <div class="d-flex justify-content-center text-center">

              <h3 class="map-color2 p-2" *ngIf="!loadingOverviewStats">
                {{dashboardResponseModel.value?.email_open_count  ?
                  dashboardResponseModel.value?.email_open_count  : '0'}}
                  <span class="text-fix">
                    {{dashboardResponseModel.value?.open_percentage ? 
                      dashboardResponseModel.value?.open_percentage:0}}%</span> </h3>
              <div class="dash-vr" *ngIf="!loadingOverviewStats"></div>
              <h3 class="map-color2 p-2" *ngIf="!loadingOverviewStats"> 
                {{dashboardResponseModel.value?.click_count  ?
                  dashboardResponseModel.value?.click_count  : '0'}}
                  <span class="text-fix">
                    {{dashboardResponseModel.value?.click_percentage ? 
                      dashboardResponseModel.value?.click_percentage:0}}%</span>
              </h3>
              <div class="map-color2 p-2" *ngIf="loadingOverviewStats">
                <div  class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>

            </div> 
            <p class="text-white bg-primary p-2">
              <i class="far fa-envelope-open"></i>
             Opens/Clicks
            </p>
          </div>
        </div> 

        <div class="col-3">
          <div class="text-center dash-item shadow-1"> 
            <h3 class="map-color2 p-2" *ngIf="!loadingOverviewStats"> 
              {{dashboardResponseModel.value?.dnc_count  ?
                dashboardResponseModel.value?.dnc_count  : '0'}} <span class="text-fix">
                  {{dashboardResponseModel.value?.dnc_percentage ? 
                    dashboardResponseModel.value?.dnc_percentage:0}}%</span>
            </h3>
            <div class="map-color2 p-2" *ngIf="loadingOverviewStats">
              <div  class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
            <p class="text-white bg-primary p-2">
             <i class="fas fa-user-alt-slash"></i>
             Unsubscribes
            </p>
          </div>
        </div>

        


       
 
      </div>

      


    
      <div class="row p-2 ">
        <div class="col-6">
          <div class="bg-white ">
            <div class="border-bottom p-2 d-flex justify-content-between">
              <p class="m-0 pt-1 map-color2">Recent Campaigns</p>
            </div>

            <table class="table table-sm table-hover text-fix table-responsive">
              <thead>
                <tr>
                  <th scope="col">Campaigns</th>
                  <th scope="col" class="text-center">Sent</th>
                  <th scope="col" class="text-center">Open</th>
                  <th scope="col" class="text-center">Replied</th>
                  <th scope="col" class="text-center">Unsubscribed</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let i of campaignResponseModel.value?.results">
                  <td> <a href="#"><strong>{{i.name?.length > 20 ? (i.name|slice:0:20) + '...':i.name}}</strong></a> </td>
                  <td class="text-center">{{i.recipient ? i.recipient: 0}}</td>
                  <td class="text-center">{{i.open ? i.open: 0}}</td>
                  <td class="text-center">{{i.click ? i.click: 0}}</td>
                  <td class="text-center">{{i.unsubscribe ? i.unsubscribe: 0}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>


        <div class="col-6">
          <div class="bg-white ">
            <div class="border-bottom p-2 d-flex justify-content-between">
              <p class="m-0 pt-1 map-color2">Top Accounts</p>

              <ng-select id="accounts" class="map-ng-select" placeholder="Select a domain">
                        <ng-option *ngFor="let item of [1,2,3,4,5]"  
                        
                        [value]="item">domain{{item}}.com</ng-option>
              </ng-select>

            </div>

            <table class="table table-sm table-hover text-fix table-responsive">
              <thead>
                <tr>
                  <th scope="col">Accounts</th>
                  <th scope="col" class="text-center">Sent</th>
                  <th scope="col" class="text-center">Open</th>
                  <th scope="col" class="text-center">Replied</th>
                  <th scope="col" class="text-center">Unsubscribed</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let i of [1,2,3,4,5]">
                  <!-- <td> <a href="#"><strong>{{i.name?.length > 20 ? (i.name|slice:0:20) + '...':i.name}}</strong></a> </td> -->
                  <td> <a href="#"><strong>demo{{i}}@emailserver.com</strong></a> </td>
                  <td class="text-center">{{i.recipient ? i.recipient: 0}}</td>
                  <td class="text-center">{{i.open ? i.open: 0}}</td>
                  <td class="text-center">{{i.click ? i.click: 0}}</td>
                  <td class="text-center">{{i.unsubscribe ? i.unsubscribe: 0}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>


      <div class="row p-2">
        <div class="col-6"></div>
        <div class="col-6"></div>
      </div>


      <!-- <div class="row p-2"> 
        <div class="col-6"> 
          <div class="flex-item bg-white shadow-1">
            <div class="border-bottom p-2 d-flex justify-content-between">
              <p class="m-0 pt-1 map-color2">Domains</p>

              <div mdbDropdown class="btn-group shadow-0">
                <button  type="button" class="btn btn-sm btn-light dropdown-toggle"
                  mdbDropdownToggle aria-expanded="false" > All</button>
                <ul mdbDropdownMenu class="dropdown-menu">
                  <li><a class="dropdown-item" href="#">24 Hours</a></li>
                  <li><a class="dropdown-item" href="#">7 Days</a></li>
                  <li><a class="dropdown-item" href="#">1 Month</a></li>
                  <li><a class="dropdown-item" href="#">All</a></li>
                </ul>
              </div>
            </div>

            <div style="display: block;" class="p-2">
            <canvas baseChart height="112px"
                  [datasets]="dashChartData3"
                  [labels]="dashChartLabels3"
                  [colors]="dashChartColors3"
                  [legend]="true"
                  [chartType]="'horizontalBar'"
                  [plugins]="[]">
              </canvas>
            </div>
          
          </div> 
        </div> 
        <div class="col-6">
          <div class="bg-white ">
            <div class="border-bottom p-2 d-flex justify-content-between">
              <p class="m-0 pt-1 map-color2">Top Prospects</p>

            </div>

            <table class="table table-sm table-hover text-fix table-responsive">
              <thead>
                <tr>
                  <th scope="col">Prospect</th>
                  <th scope="col" class="text-center">Campaigns</th>
                  <th scope="col" class="text-center">Sent</th>
                  <th scope="col" class="text-center">Open</th>
                  <th scope="col" class="text-center">Replied</th>
                  <th scope="col" class="text-center">Unsubscribed</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let i of [1,2,3,4,5]"> 
                  <td> Chandan Dandapat </td>
                  <td class="text-center"> 5</td>
                  <td class="text-center">{{i.recipient ? i.recipient: 0}}</td>
                  <td class="text-center">{{i.open ? i.open: 0}}</td>
                  <td class="text-center">{{i.click ? i.click: 0}}</td>
                  <td class="text-center">{{i.unsubscribe ? i.unsubscribe: 0}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> 
      </div> -->

        <!-- <div class="row p-2 ">
        <div class="col-6"> 
          <div class="flex-item bg-white shadow-1">
            <div class="border-bottom p-2 d-flex justify-content-between">
              <p class="m-0 pt-1 map-color2">Sent vs Open vs Replied</p>

              <div mdbDropdown class="btn-group shadow-0">
                <button  type="button" class="btn btn-sm btn-light dropdown-toggle"
                  mdbDropdownToggle aria-expanded="false" > All</button>
                <ul mdbDropdownMenu class="dropdown-menu">
                  <li><a class="dropdown-item" href="#">24 Hours</a></li>
                  <li><a class="dropdown-item" href="#">7 Days</a></li>
                  <li><a class="dropdown-item" href="#">1 Month</a></li>
                  <li><a class="dropdown-item" href="#">All</a></li>
                </ul>
              </div>
            </div>

            <div style="display: block;" class="p-2">
            <canvas baseChart height="120px"
                  [datasets]="dashChartData1"
                  [labels]="dashChartLabels1"
                  [colors]="dashChartColors1"
                  [legend]="true"
                  [chartType]="'line'"
                  [plugins]="[]">
              </canvas>
            </div>
          
          </div> 
        </div>


        <div class="col-6"> 
          <div class="flex-item bg-white shadow-1">
            <div class="border-bottom p-2 d-flex justify-content-between">
              <p class="m-0 pt-1 map-color2">Campaigns & Steps</p>

              <div mdbDropdown class="btn-group shadow-0">
                <button  type="button" class="btn btn-sm btn-light dropdown-toggle"
                  mdbDropdownToggle aria-expanded="false" > All</button>
                <ul mdbDropdownMenu class="dropdown-menu">
                  <li><a class="dropdown-item" href="#">24 Hours</a></li>
                  <li><a class="dropdown-item" href="#">7 Days</a></li>
                  <li><a class="dropdown-item" href="#">1 Month</a></li>
                  <li><a class="dropdown-item" href="#">All</a></li>
                </ul>
              </div>
            </div>

            <div style="display: block;" class="p-2">
            <canvas baseChart height="120px"
                  [datasets]="dashChartData2"
                  [labels]="dashChartLabels2"
                  [colors]="dashChartColors2"
                  [legend]="true"
                  [chartType]="'bar'"
                  [plugins]="[]">
              </canvas>
            </div>
          
          </div> 
        </div> 
      </div> -->






    </div>



  </div>
</div>