import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { MapCommon } from 'src/app/app-providers/map-base/MapCommon';
import { LoginUserModel } from 'src/app/login/models/login-model';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate{

  constructor(private router: Router) { 
  }

  canActivate(): boolean {
    
    const localStorage_user = MapCommon.getloggedInUser();
    if (!localStorage_user) {
      MapCommon.removeItemsOnLogOut();
      this.router.navigate(['login']);
      return false;
    } else {
      
    // Check token
    if (!localStorage_user.token){
      MapCommon.removeItemsOnLogOut();
      this.router.navigate(['login']);
      return false;
    } else {
      return true
      
    }

    }
  }

}
