<div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">{{sendingWindowModel.name}}</h5>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modalRef.close()"
    ></button>
  </div>
  <div class="modal-body">

    <div class="text-center">
        
    <h6 style="color: #EF6C00;"><strong><i class="far fa-clock"></i>  {{scheduleTimezoneName}}</strong></h6>
    </div>

    

    <div class="row mt-2 ps-3 d-flex">
      <div class="col-1 dayItem"><h6>Mon:</h6></div>
      <ng-container *ngIf="mondayStartModel.length > 0">
        <div class="col-11 pe-2">
            <span class="badge bg-secondary bg-time-slot ms-2" *ngFor="let item of mondayStartModel; let i=index">
            {{displayTime(item.time_display, mondayEndModel[i].time_display)}}</span>
        </div> 
      </ng-container>
      <ng-container *ngIf="mondayStartModel.length == 0">
          <div class="col-11">
            <h6>-</h6>
          </div> 
      </ng-container>
    </div>


    <div class="row mt-2 ps-3 d-flex">
      <div class="col-1 dayItem"><h6>Tues:</h6></div>
      
      <ng-container *ngIf="tuesdayStartModel.length > 0">
        <div class="col-11 pe-2">
            <span class="badge bg-secondary bg-time-slot ms-2" *ngFor="let item of tuesdayStartModel; let i=index">
            {{displayTime(item.time_display, tuesdayEndModel[i].time_display)}}</span>
        </div>
      </ng-container>

      <ng-container *ngIf="tuesdayStartModel.length == 0">
          <div class="col-3">
             <h6>-</h6>
          </div> 
      </ng-container> 

      
  </div>



      
  <div class="row mt-2 ps-3 d-flex">
      <div class="col-1 dayItem"><h6>Wed:</h6></div>
      
      <ng-container *ngIf="wednesdayStartModel.length > 0">
        <div class="col-11 pe-2">
            <span class="badge bg-secondary bg-time-slot ms-2" *ngFor="let item of wednesdayStartModel; let i=index">
            {{displayTime(item.time_display, wednesdayEndModel[i].time_display)}}</span>
        </div>
      </ng-container>

      <ng-container *ngIf="wednesdayStartModel.length == 0">
          <div class="col-3">
             <h6>-</h6>
          </div> 
      </ng-container> 
     
  </div>
 
 

  <div class="row mt-2 ps-3 d-flex">
      <div class="col-1 dayItem"><h6>Thur:</h6></div>
      
      <ng-container *ngIf="thursdayStartModel.length > 0">
        <div class="col-11 pe-2">
            <span class="badge bg-secondary bg-time-slot ms-2" *ngFor="let item of thursdayStartModel; let i=index">
            {{displayTime(item.time_display, thursdayEndModel[i].time_display)}}</span>
        </div>

      </ng-container>

      <ng-container *ngIf="thursdayStartModel.length == 0">
          <div class="col-3">
             <h6>-</h6>
          </div> 
      </ng-container>
       
  </div>

 

  <div class="row mt-2 ps-3 d-flex">
      <div class="col-1 dayItem"><h6>Fri:</h6></div>
      
      <ng-container *ngIf="fridayStartModel.length > 0">
        <div class="col-11 pe-2">
            <span class="badge bg-secondary bg-time-slot ms-2" *ngFor="let item of fridayStartModel; let i=index">
            {{displayTime(item.time_display, fridayEndModel[i].time_display)}}</span>
        </div>
      </ng-container>

      <ng-container *ngIf="fridayStartModel.length == 0">
          <div class="col-6">
             <h6>-</h6>
          </div> 
      </ng-container> 
    
  </div>
 
 

  <div class="row mt-2 ps-3 d-flex">
      <div class="col-1 dayItem"><h6>Sat:</h6></div>
      
      <ng-container *ngIf="saturdayStartModel.length > 0">
        <div class="col-11 pe-2">
            <span class="badge bg-secondary bg-time-slot ms-2" *ngFor="let item of saturdayStartModel; let i=index">
            {{displayTime(item.time_display, saturdayEndModel[i].time_display)}}</span>
        </div>
      </ng-container>

      <ng-container *ngIf="saturdayStartModel.length == 0">
          <div class="col-3">
             <h6>-</h6>
          </div> 
      </ng-container>
    
  </div>


  <div class="row mt-2 ps-3 d-flex">
      <div class="col-1 dayItem"><h6>Sun:</h6></div>
      
      <ng-container *ngIf="sundayStartModel.length > 0">
        <div class="col-11 pe-2">
            <span class="badge bg-secondary bg-time-slot ms-2" *ngFor="let item of sundayStartModel; let i=index">
            {{displayTime(item.time_display, sundayEndModel[i].time_display)}}</span>
        </div>
      </ng-container>

      <ng-container *ngIf="sundayStartModel.length == 0">
          <div class="col-3">
             <h6>-</h6>
          </div> 
      </ng-container> 
     
  </div>



  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-link" (click)="modalRef.close()">
      Close
    </button>
    <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
  </div>