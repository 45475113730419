import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import {
  ProspectModel, ProspectResponseModel, ProspectStatusResponseModel,
  ProspectUserResponseModel, ProspectGroupResponseModel, ProspectFilterModel,
  CampaignResponseModel, CampaignModel
} from '../prospects/model/prospect-model';
import { ProspectViewModel } from '../prospects/viewModel/prospect-viewmodel';
import { MapAPIService } from "src/app/app-providers/services/map-module";
import { BehaviorSubject, Observable, of, OperatorFunction } from 'rxjs';
import { Router } from '@angular/router';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { ConfirmDialogService } from '../admin/confirm-dialog/confirm-dialog.service';
import { ResizableDirective, ResizeEvent } from 'angular-resizable-element';
import { SefetyChecksComponent } from './sefety-checks/sefety-checks.component';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { catchError, debounceTime, distinctUntilChanged, map, tap, switchMap } from 'rxjs/operators';
import { ExportCampaignService } from '../admin/services/export-campaign.service';

interface CustomColumn {
  possition: number;
  name: string;
  isActive: boolean;
}

@Component({
  selector: 'app-prospects',
  templateUrl: './prospects.component.html',
  styleUrls: ['./prospects.component.scss'],
  providers: [MapAPIService, ProspectViewModel, ResizableDirective, ExportCampaignService],
})
export class ProspectsComponent implements OnInit {
  @ViewChild("prospectContainer") prospectContainer: ElementRef;
  @ViewChild('masterSelect') masterSelect: ElementRef<HTMLElement>;
  @ViewChild("campaignType") campaignType: ElementRef;
  prospectLoad :boolean = false;

  public options: any;
  sefetyChecksModalRef: MdbModalRef<SefetyChecksComponent>;
  searching = false;
  searchFailed = false;
  selectedRow: number;
  page_index = 1;
  isFocused: boolean = false;
  isPasted: boolean = false;
  editedRows: boolean[];
  public columnList = ['Status', 'Owner', 'Campaigns', 'Last Contacted', 'Email Sent', 'Prospect Score', 'Replies', 'Opted Out',
    'Bounced'];
    // 'Region', 'Timezone', 'Group', 'Snippet', 'Snippet 1', 'Snippet 2', 'Snippet 3', 'Snippet 4'

  showStatusColumn: boolean = true;
  showOwnerColumn: boolean = true;
  showRegionColumn: boolean = true;
  showCampaignsColumn: boolean = true;
  showLastContactedColumn: boolean = true;
  showEmailSentColumn: boolean = true;
  showProspectScoreColumn: boolean = true;
  showRepliesColumn: boolean = true;
  showOptedOutColumn: boolean = true;
  showBouncedColumn: boolean = true;
  showTimezoneColumn: boolean = true;
  showGroupColumn: boolean = true;
  showSnippetColumn: boolean = true;
  showSnippet1Column: boolean = true;
  showSnippet2Column: boolean = true;
  showSnippet3Column: boolean = true;
  showSnippet4Column: boolean = true;
  public placeholder = 'placeholder';
  public columnShowHideList: CustomColumn[] = [];
  prospectModel = new ProspectModel();
  selectedCampaignObj = new CampaignModel();
  prospectlist = [];
  value1: any = [];
  exampleData: any = [];
  prospectlistB4Search = [];
  // selectedProspectlist: number[] = [];
  selectedProspectlist: number = 0;
  excludedProspects: number[] = [];
  includedProspects: number[] = [];
  prospectStatuslist = [];
  userList = [];
  prospectGrouplist = [];
  campaignlist = [];
  tempCampaignlist = [];
  selectedCampaign: string;
  showAddCompaign: boolean = false;
  fillAutoCampaign: boolean = false;
  isMasterSel: boolean = false;
  showCompaignDrop: boolean = false;
  selectedCampaignId: number;
  totalProspectRecieved: number;
  prospectFilterModel: ProspectFilterModel;
  showingExportLoading: boolean = false;
  showingDeleteLoading: boolean = false;

  sortDirection: string = 'desc';
  column_selected: string = '';
  column_level = 0;
  sortActive: boolean = false;
  sortByColumn(list: any[] | undefined, column: string, level, direction = 'desc'): any[] {
    let sortedArray = (list || []).sort((a, b) => {
      if (level === 0) {
        if (a[column] > b[column]) {
          return (direction === 'desc') ? 1 : -1;
        }
        if (a[column] < b[column]) {
          return (direction === 'desc') ? -1 : 1;
        }
        return 0;
      }
      if (level === 1) {
        if (Number(a[column].length) > Number(b[column].length)) {
          return (direction === 'desc') ? 1 : -1;
        }
        if (Number(a[column].length) < Number(b[column].length)) {
          return (direction === 'desc') ? -1 : 1;
        }
        return 0;
      }
    })
    return sortedArray;
  };

  modalOptions = {
    data: {
      campaignData: {}
    },
    modalClass: 'modal-lg',
    ignoreBackdropClick: true
  }
  prospectResponseModel = new BehaviorSubject<ProspectResponseModel>(
    new ProspectResponseModel()
  );
  prospectStatusResponseModel = new BehaviorSubject<ProspectStatusResponseModel>(
    new ProspectStatusResponseModel()
  );

  prospectStatusResponseModel1 = new BehaviorSubject<ProspectStatusResponseModel>(
    new ProspectStatusResponseModel()
  );
  prospectUserResponseModel = new BehaviorSubject<ProspectUserResponseModel>(
    new ProspectUserResponseModel()
  );
  prospectGroupResponseModel = new BehaviorSubject<ProspectGroupResponseModel>(
    new ProspectGroupResponseModel()
  );
  campaignResponseModel = new BehaviorSubject<CampaignResponseModel>(
    new CampaignResponseModel()
  );

  isLoading: boolean = false;

  static settingCall;
  constructor(private vm: ProspectViewModel, private router: Router, private modalService: MdbModalService,private changeDetectorRef: ChangeDetectorRef,
    private confirmDialogService: ConfirmDialogService, private exportCampaignService: ExportCampaignService) { ProspectsComponent.settingCall = this; }

  ngOnInit(): void {
    this.isFocused = true;
    this.initializeColumnProperties();
    this.vm.parentComponent = this;
    this.vm.subscribeAllVariables(this.prospectResponseModel, this.prospectStatusResponseModel,
      this.prospectUserResponseModel);
    this.vm.subscribeStatusVariables(this.prospectStatusResponseModel1);

    // console.log('prospect_status', this.prospectStatusResponseModel1)
    this.vm.subscribeGroupVariable(this.prospectGroupResponseModel)
    this.vm.subscribeCampaignVariable(this.campaignResponseModel)
    this.loadAllItems();
    this.options = {
      width: '100%',
      multiple: false,
      tags: true
    };
    //  this.getCheckedItemList();
  }

  toggleColumn(column) {
    if (column.isActive) {
      if (column.possition > this.columnList.length - 1) {
        this.columnList.push(column.name);
      } else {
        this.columnList.splice(column.possition, 0, column.name);
      }
      this.changeColumnStatus(column.name, true);
    } else {
      let i = this.columnList.indexOf(column.name);
      let opr = i > -1 ? this.columnList.splice(i, 1) : undefined;
      this.changeColumnStatus(column.name, false);
    }
  }


  // add this function to trigger subject
  sortOn(level: number, column: string) {
    this.column_selected = column;
    this.sortActive = true;
    // if(column == 'owner_name' || column == 'status_name'){
    //   this.sortByColumn(this.prospectlist, column, level, this.sortDirection)
    // } else{
      if (this.sortDirection === 'asc'){
        this.sortDirection = 'desc';
        this.vm.getAllProspectAfterFIlter(this.prospectFilterModel , '-'+column );
        this.prospectContainer.nativeElement.scrollTop ?  this.prospectContainer.nativeElement.scrollTop = 0 : this.prospectContainer.nativeElement;
      }else{
        this.sortDirection = 'asc';
        this.vm.getAllProspectAfterFIlter(this.prospectFilterModel , column );
        this.prospectContainer.nativeElement.scrollTop ?  this.prospectContainer.nativeElement.scrollTop = 0 : this.prospectContainer.nativeElement;
      }
    // }




  }

  uncheckOnClearSelection() {
    if (this.isMasterSel) {
      let el: HTMLElement = this.masterSelect.nativeElement;
      el.click();
    }
  }
  changeColumnStatus(columnName, status) {
    if (columnName == 'Status')
      this.showStatusColumn = status;
    if (columnName == 'Region')
      this.showStatusColumn = status;
    if (columnName == 'Owner')
      this.showOwnerColumn = status;
    if (columnName == 'Campaigns')
      this.showCampaignsColumn = status;
    if (columnName == 'Last Contacted')
      this.showLastContactedColumn = status;
    if (columnName == 'Email Sent')
      this.showEmailSentColumn = status;
    if (columnName == 'Prospect Score')
      this.showProspectScoreColumn = status;
    if (columnName == 'Replies')
      this.showRepliesColumn = status;
    if (columnName == 'Opted Out')
      this.showOptedOutColumn = status;
    if (columnName == 'Bounced')
      this.showBouncedColumn = status;
    if (columnName == 'Timezone')
      this.showTimezoneColumn = status;
    if (columnName == 'Group')
      this.showGroupColumn = status;
    if (columnName == 'Region')
      this.showRegionColumn = status;

    if (columnName == 'Snippet')
      this.showSnippetColumn = status;
    if (columnName == 'Snippet 1')
      this.showSnippet1Column = status;
    if (columnName == 'Snippet 2')
      this.showSnippet2Column = status;
    if (columnName == 'Snippet 3')
      this.showSnippet3Column = status;
    if (columnName == 'Snippet 4')
      this.showSnippet4Column = status;

  }

  initializeColumnProperties() {
    this.columnList.forEach((element, index) => {
      this.columnShowHideList.push(
        { possition: index, name: element, isActive: true }
      );
    });
  }
  callFilter(prospectFilterModel: ProspectFilterModel) {
    this.page_index = 1;
    prospectFilterModel.page = 1;
    prospectFilterModel.page_size = 100;
    this.isLoading = true;
    this.prospectLoad = true
    this.changeDetectorRef.detectChanges();
    this.vm.getAllProspect(prospectFilterModel);
    this.prospectFilterModel = prospectFilterModel;

  }
  loadAllItems() {
    this.vm.getAllProspectStatus('prospect');
    this.vm.getAllUsers('prospect');
    // this.vm.getAllProspectGroups("parentComponent");
    this.prospectFilterModel = new ProspectFilterModel();
    // this.prospectFilterModel.page = 1;
    // this.prospectFilterModel.page_size = 200;
    // this.vm.getAllProspect(this.prospectFilterModel);
    // this.vm.getAllCampaign("", 'prospect');
  }


  onPaste(event: any) {
    this.isPasted = true;
  }

  // search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
  // text$.pipe(
  //   debounceTime(300),
  //   distinctUntilChanged(),
  //   tap(() => this.searching = true),
  //   switchMap(term =>
  //     this.vm.getAllCampaign(term, 'prospect').pipe(
  //       tap(() => this.searchFailed = false),
  //       catchError(() => {
  //         this.searchFailed = true;
  //         return of([]);
  //       }))
  //   ),
  //   tap(() => this.searching = false)
  // )
  onInput(content: string) {
    console.log("New content: ", content);
  }

  ProspectRecieved() {
    if (this.prospectlistB4Search.length == 0){
      this.prospectlist = this.prospectResponseModel.value.results;
      this.prospectLoad = false;
    } else {
      this.prospectlist = this.prospectResponseModel.value.results;
      this.prospectLoad = false;
      if (this.prospectResponseModel.value.previous !== null) {
        for (var j = 0; j < this.prospectlist.length; j++) {
          var index = this.prospectlistB4Search.findIndex(x => x.id == this.prospectlist[j].id);
          if (index === -1)
            this.prospectlistB4Search.push(this.prospectlist[j]);
        }
        this.prospectlist = this.prospectlistB4Search;
      }
      if(this.prospectlist.length > 0) {
        this.prospectContainer.nativeElement.scrollTop = 0
      }

    }
    this.totalProspectRecieved = this.prospectResponseModel.value.count;
    // console.log(this.totalProspectRecieved)
    if (this.isMasterSel) {
      let el: HTMLElement = this.masterSelect.nativeElement;
      el.click();
      this.sleep(1000);
      el.click();
    }

    this.clearLoading();

  }

  clearLoading(){
    this.isLoading = false;
  }


  sleep(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }


  ProspectStatusRecieved() {
    this.prospectStatuslist = this.prospectStatusResponseModel.value.results;
  }

  UserRecieved() {
    this.userList = this.prospectUserResponseModel.value.results;
  }

  ProspectGroupReceivedFromSidebar(e: any){
    this.prospectGrouplist = e;
  }

  ProspectGroupReceived() {
    this.prospectGrouplist = this.prospectGroupResponseModel.value.results;
  }

  public valueChanged(event: string) {
    console.log('value changed: ' + event);
  }

  public modelChanged(event: string) {
    console.log('model changed: ' + event);
  }

  CampaignReceived(e:any=null) {
    if(e != null){
      this.tempCampaignlist = e
    } else {
      this.tempCampaignlist = this.campaignResponseModel.value.results;
    }

    if (this.isFocused) {
      this.campaignlist = this.tempCampaignlist.slice(0, 10);
      // this.campaignlist.unshift({"id":0,"name":"Showing top 10 campaigns"});
      this.isFocused = false;
    }
    else {
      this.campaignlist = this.tempCampaignlist;
      if (this.isPasted) {

        let el: HTMLElement = this.campaignType.nativeElement;
        this.campaignType.nativeElement.blur();
        this.campaignType.nativeElement.focus();
        this.isPasted = false;
      }
    }
    if (this.fillAutoCampaign) {
      this.selectedCampaignObj = this.campaignlist[0];
      // this.selectedCampaignObj.temp_prospect = [0];
      if (Number(this.selectedCampaignObj.id) > 0) {
        this.showAddCompaign = true;
        this.fillAutoCampaign = false;
      }
    }
    var tempCampaign = localStorage.getItem("selectedCampaign");
    if (tempCampaign !== null) {
      this.selectedCampaign = tempCampaign;
    }
    var exampleChild = [];
    this.campaignlist.forEach((element, index) => {
      exampleChild.push({
        id: element.id,
        text: element.name
      })
    });
    this.exampleData =
      [
        {
          id: '0',
          text: 'Top 10 Campaigns',
          children: exampleChild
        },

      ];
  }
  onSelect(event: TypeaheadMatch): void {
    if (event.item.id > 0) {
      this.showAddCompaign = true;
      this.selectedCampaignObj = event.item;
      // this.selectedCampaignObj.temp_prospect = [0];
    }
    else {

    }
  }
  //call all prospecting group
  changeTypeaheadLoading(event) {
    if (this.selectedCampaign && this.selectedCampaign.length > 2)
      this.vm.getAllCampaign(this.selectedCampaign, 'prospect');
  }

  getStatusName(inputStatusItem) {
    var statusItem = this.prospectStatuslist.filter(i => i.id == Number(inputStatusItem.status));
    if (statusItem.length > 0) {
      inputStatusItem.status_name = statusItem[0].name;
      return statusItem[0].name;
    }
    else {
      inputStatusItem.status_name = "";
    }
  }

  getUserName(prospectItem) {
    var userItem = this.userList.filter(i => i.id == Number(prospectItem.created_by));
    if (userItem.length > 0) {
      // prospectItem.owner_name = userItem[0].first_name + " " + userItem[0].last_name;
      // return userItem[0].first_name + " " + userItem[0].last_name;
      if (userItem[0].email?.length > 0) {
        return userItem[0].email;
      } else {
        if (userItem[0].username?.length > 0) {
          return userItem[0].username
        }
      }
    }
    return ""
  }

  getProspectGroupName(prospectItem) {
    var groupItem = this.prospectGrouplist.filter(i => i.id == Number(prospectItem.prospect_group[0]));
    if (groupItem.length > 0) {
      prospectItem.group_name = groupItem[0].name;
      return groupItem[0].name;
    }
    else
      prospectItem.group_name = "";
  }

  gotoDetailPage(prospectDataById) {
    const prospectID = prospectDataById.id
    const prospectDetailUrl = '/prospects/' + prospectID
    this.router.navigate([prospectDetailUrl], {
      state: {
        routeData: JSON.stringify({ prospectData: prospectDataById }),
        statusName: this.getStatusName(prospectDataById.status)
      }
    });
  }

  checkAllOnScroll() {
    if (this.isMasterSel) {
      for (var i = 0; i < this.prospectlist.length; i++) {
        this.prospectlist[i].isSelected = true;
      }
    }
  }

  getCheckedItemList() {
    this.selectedProspectlist = 0;
    this.excludedProspects = [];
    this.includedProspects = [];
    if (this.isMasterSel) {
      this.selectedProspectlist = this.totalProspectRecieved;
      this.excludedProspects = this.prospectlist.filter(item => item.isSelected == false).map(data => data.id)
    } else {
      this.selectedProspectlist = this.prospectlist.filter(item => item.isSelected == true).map(data => data.id).length;
      this.includedProspects = this.prospectlist.filter(item => item.isSelected == true).map(data => data.id)
    }
    this.selectedProspectlist = this.selectedProspectlist - this.excludedProspects.length;



    // console.log("Included prospects: ", this.includedProspects)
    // console.log("Excluded prospects: ", this.excludedProspects)
    // console.log("selected Prospect list count: ", this.selectedProspectlist)
    // if (this.excludedProspects.length > 0){
    //   this.isMasterSel = false
    // }


    // for (var i = 0; i < this.prospectlist.length; i++) {
    //   if (this.prospectlist[i].isSelected)
    //     this.selectedProspectlist.push(this.prospectlist[i].id);
    // }

    if (this.selectedProspectlist > 0) {
      this.showCompaignDrop = true;
      if (this.selectedCampaign !== undefined && this.selectedCampaign !== null && this.selectedCampaign !== "") {
        this.vm.getAllCampaign(this.selectedCampaign, 'prospect');
        this.fillAutoCampaign = true;
      }
      if (Number(this.selectedCampaignObj.id) > 0)
        this.showAddCompaign = true;
    }
    else {
      this.showCompaignDrop = false;
      this.showAddCompaign = false;
    }
  }


  checkUncheckAll(e: any) {
    this.isMasterSel = e.target.checked;
    if (!this.isMasterSel) {
      this.includedProspects = [];
      this.excludedProspects = [];
    }

    for (var i = 0; i < this.prospectlist.length; i++) {
      this.prospectlist[i].isSelected = this.isMasterSel;
    }
    this.getCheckedItemList();
  }

  isAllSelected(e: any, itemId: number) {
    this.prospectlist.find(item => item.id == itemId).isSelected = e.target.checked;

    // if(!this.excludedProspects.includes(itemId)){
    //   this.excludedProspects.push(itemId);
    // }

    // this.isMasterSel = this.prospectlist.every(function (item: any) {
    //   return item.isSelected == true;
    // })
    this.getCheckedItemList();
  }


  openSafetyChecks() {
    // this.selectedCampaignObj.temp_prospect.pop();
    // this.selectedCampaignObj.prospectFilterModel = this.prospectFilterModel;
    // for (var i = 0; i < this.selectedProspectlist; i++) {
    //   if (this.selectedCampaignObj.temp_prospect.indexOf(this.selectedProspectlist[i]) === -1) {
    //     this.selectedCampaignObj.temp_prospect.push(this.selectedProspectlist[i]);
    //   }
    // }

    this.selectedCampaignObj.excludedProspects = [0]
    this.selectedCampaignObj.excludedProspects.pop();
    if (this.excludedProspects.length > 0) {
      this.excludedProspects.forEach(element => {
        this.selectedCampaignObj.excludedProspects.push(element)
      });
    }
    this.selectedCampaignObj.includedProspects = [0]
    this.selectedCampaignObj.includedProspects.pop();
    if (this.includedProspects.length > 0) {
      this.includedProspects.forEach(element => {
        this.selectedCampaignObj.includedProspects.push(element)
      });
    }

    this.selectedCampaignObj.prospectFilterModel = this.prospectFilterModel;
    this.selectedCampaignObj.total_prospect_selected = this.selectedProspectlist;
    this.modalOptions.data.campaignData = Object.assign({}, this.selectedCampaignObj);
    this.sefetyChecksModalRef = this.modalService.open(SefetyChecksComponent, this.modalOptions);
  }

  // updateCampaign() {
  //   for (var i = 0; i < this.selectedProspectlist.length; i++) {
  //     if (this.selectedCampaignObj.prospect.indexOf(this.selectedProspectlist[i]) === -1) {
  //       this.selectedCampaignObj.prospect.push(this.selectedProspectlist[i]);
  //     }
  //   }
  //   this.vm.updateCampaign(this.selectedCampaignObj, 'prospect');
  // }

  // redirectAfterUpdateCampaign(campaignId){
  //   this.router.navigate(['/campaigns/', campaignId])
  // }

  clearDeleteLoading() {
    this.showingDeleteLoading = false;
  }


  confirmDeleteProspect() {
    this.showingDeleteLoading = true;
    this.confirmDialogService.confirmThis("Are you sure you want to delete selected prospect?", function () {

      ProspectsComponent.settingCall.vm.deleteProspect(
        ProspectsComponent.settingCall.prospectFilterModel,
        ProspectsComponent.settingCall.includedProspects,
        ProspectsComponent.settingCall.excludedProspects);
    }, function () {
      this.showingDeleteLoading = false;
    })
  }



  // This method is being called once user hits bottom while scrolling prospects
  onProspectScroll() {
    if (this.prospectResponseModel.value.next) {
      this.prospectlistB4Search = this.prospectlist;
      this.page_index++;
      this.prospectFilterModel.page = this.page_index;
      this.isLoading = true;
      this.changeDetectorRef.detectChanges();
      if(this.sortActive){
        if (this.sortDirection === 'asc'){
          this.vm.getAllProspectAfterFIlter(this.prospectFilterModel , this.column_selected );
        }else{
          this.vm.getAllProspectAfterFIlter(this.prospectFilterModel , '-'+this.column_selected );

        }
      } else{
        this.vm.getAllProspect(this.prospectFilterModel);
      }

    }
  }

  exportAll() {
    this.vm.parentComponent = this;
    this.vm.exportFilterdProspects(
      this.prospectFilterModel, this.selectedProspectlist, this.includedProspects, this.excludedProspects);
    this.showingExportLoading = true;
  }

  disableExportLoading() {
    this.showingExportLoading = false;
  }

  prospectExported() {
    // this.exportCampaignService.downloadCSV(data, 'prospect_export.csv');
    this.disableExportLoading();
  }

}
