import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ImportViewModel } from '../import-view-model/import-view-model';
import { MapAPIService } from "src/app/app-providers/services/map-module";
import { MapCommon } from "src/app/app-providers/map-base/MapCommon";
import { DatePipe } from '@angular/common';
import { ProspectModel } from '../import-model/import-model';
import { ConfirmDialogService } from '../../admin/confirm-dialog/confirm-dialog.service';
import * as XLSX from 'xlsx';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-new-import',
  templateUrl: './new-import.component.html',
  styleUrls: ['./new-import.component.scss'],
  providers: [MapAPIService, ImportViewModel, DatePipe],
})


export class NewImportComponent implements OnInit {
  @ViewChild("doNothingButton") doNothingButton: ElementRef;

  public records: any[] = [];
  @ViewChild('csvReader') csvReader: any;
  fileToUpload: File = null;
  hiddenEvent: any;
  static importCall;
  issueWithRecord: boolean = false;
  issueWithAncii: boolean = false;
  duplicateEmails: boolean = false;
  wrongEmailFound: boolean = false;
  issueWithHeader: boolean = false;
  mustHaveHeaders = ["First Name", "first name", "Last Name", "last name", "Email Address", "email address",
  "Middle Name", "middle name", "Company", "company", "Address", "address", "City", "city", "State", "state",
  "Region", "region","Country", "country", "Phone", "phone", "Job Title", "job title", "Industry", "industry", "LinkedIn", "linkedin",
  "Snippet", "snippet", "Snippet1", "snippet1", "Snippet2", "snippet2", "Snippet3", "snippet3", "Snippet4", "snippet4"]
  public showWrongHeaders = [];

  public showDuplicatesAfterError: any[] = [];
  public showInvalidEmailsAfterError: any[] = [];

  isLoading: boolean = false;

  constructor(private vm: ImportViewModel, public datepipe: DatePipe,
    private confirmDialogService: ConfirmDialogService,
    private router: Router,
    public changeDetectRef:ChangeDetectorRef

    ) { NewImportComponent.importCall = this; }

  date: Date;


  ngOnInit(): void {
    this.vm.parentNewImportComponent = this;
  }


  getFileName(fileName) {
    var upload_file_name = ""
    if (fileName.endsWith('.csv')){
      upload_file_name = fileName.slice(0, -4).toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
    } else {
      upload_file_name = fileName.slice(0, -5).toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
    }
    this.date = new Date();
    let latest_date = this.datepipe.transform(this.date, 'HHmmss') + '_' + this.datepipe.transform(this.date, 'ddMMMyy');
    var name = MapCommon.getloggedInUser().first_name + "_";
    console.log(name + upload_file_name + "_" + latest_date)
    return name + upload_file_name + "_" + latest_date;

  }


  handleFileInput() {
    this.isLoading = true;
    this.vm.uploadProspectFile(this.hiddenEvent[0], this.getFileName(this.hiddenEvent[0].name));
  }

  clearTable(e) {
    this.isLoading = false;
    localStorage.setItem("selectdProspectGroup", e.res.name);
    localStorage.setItem("prospectImportStats", JSON.stringify(e.res));
    this.router.navigate(['/prospects'])
  }


  showErrorWhileUplaoding(duplicates = null, invalids = null) {
    this.isLoading = false;
    this.records = [];
    this.issueWithRecord = false;
    this.issueWithAncii = false;
    this.duplicateEmails = false;
    this.wrongEmailFound= false;
    this.issueWithHeader = false;
    this.showWrongHeaders = [];
    if (duplicates !== null){
      this.showDuplicatesAfterError = duplicates;
    }
    if(invalids !== null){
      this.showInvalidEmailsAfterError = invalids;
    }
  }

  confirmUpload() {
    this.confirmDialogService.confirmThis("Are you sure you want to upload this data?", function () {
      NewImportComponent.importCall.handleFileInput();
    }, function () {
    })
  }

  checkFileHeader(headers){

    function comparer(otherArray){
      return function(current){
        return otherArray.filter(function(other){
          return other == current
        }).length == 0;
      }
    }

  var checkExtraHeaders = headers.filter(comparer(this.mustHaveHeaders));
    if (checkExtraHeaders.length > 0){
      this.issueWithHeader = true;
      this.showWrongHeaders = checkExtraHeaders;
    }
  }

  uploadListener($event: any): void {
    this.issueWithRecord = false;
    this.issueWithAncii = false;
    this.duplicateEmails = false;
    this.wrongEmailFound= false;
    this.issueWithHeader = false;
    this.showWrongHeaders = [];
    this.showDuplicatesAfterError = [];
    this.showInvalidEmailsAfterError = [];
    this.records = [];
    this.hiddenEvent = Object.assign({}, $event.target.files);
    let text = [];
    let files = $event.srcElement.files;
    if (this.isValidCSVFile(files[0])) {

      let input = $event.target;
      let reader = new FileReader();
      reader.readAsText(input.files[0]);

      reader.onload = () => {
        let csvData = reader.result;
        let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
        let headersRow = this.getHeaderArray(csvRecordsArray);
        this.checkFileHeader(headersRow);
        this.records = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
      };

      reader.onerror = function () {
        console.log('error is occured while reading file!');
      };

    } else {
      this.excelFileUpload($event);
    }
    this.sleep(1000);
    let el: HTMLElement = this.doNothingButton.nativeElement;
    el.click();
    $event.target.value = "";
    // alert("I am called!");
  }



  excelFileUpload(event: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      const headers = []
      var excelHeaders = [];
      var range = XLSX.utils.decode_range(ws['!ref']);
      var C, R = range.s.r; /* start in the first row */
      /* walk every column in the range */
      for (C = range.s.c; C <= range.e.c; ++C) {

        var cell = ws[XLSX.utils.encode_cell({ c: C, r: R })] /* find the cell in the first row */
        var hdr = "UNKNOWN " + C; // <-- replace with your desired default
        if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
        if (hdr.indexOf("UNKNOWN ") === -1)
          excelHeaders.push(hdr);
      }
      var range = XLSX.utils.decode_range(ws['!ref']);
      // const columnCount = XLSX.utils.decode_range(ws['!ref']).e.c + 1
      for (let i = 0; i < excelHeaders.length; ++i) {
        headers[i] = ws[`${XLSX.utils.encode_col(i)}1`].v
      }
      this.checkFileHeader(headers);

      /* save data */
      const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      this.records = this.getDataFromExcel(data);
      this.sleep(1000);
      let el: HTMLElement = this.doNothingButton.nativeElement;
      el.click();
      //console.log(data); // Data will be logged in array format containing objects
    };
  }


  getExcelDataByHeader(a, b){
     if(a != undefined){
      return a
     }
     if (b != undefined){
      return b
     }
     else {
       return ''
     }
  }


  getDataFromExcel(data) {
    let csvArr = [];
    let emailArr = [];
    for (let i = 0; i < data.length; i++) {
      let csvRecord: ProspectModel = new ProspectModel();
      csvRecord.firstname = this.getExcelDataByHeader(data[i]["First Name"], data[i]["first name"]);
      csvRecord.middlename = this.getExcelDataByHeader(data[i]["Middle Name"] , data[i]["middle name"]);
      csvRecord.lastname = this.getExcelDataByHeader(data[i]["Last Name"] , data[i]["last name"]);
      csvRecord.email_address = this.getExcelDataByHeader(data[i]["Email Address"] , data[i]["email address"]);
      csvRecord.company = this.getExcelDataByHeader(data[i]["Company"] , data[i]["company"]);
      csvRecord.address = this.getExcelDataByHeader(data[i]["Address"] , data[i]["address"]);
      csvRecord.city = this.getExcelDataByHeader(data[i]["City"] , data[i]["city"]);
      csvRecord.state = this.getExcelDataByHeader(data[i]["State"],  data[i]["state"]);
      csvRecord.region = this.getExcelDataByHeader(data[i]["Region"],  data[i]["region"]);
      csvRecord.country = this.getExcelDataByHeader(data[i]["Country"], data[i]["country"]);
      csvRecord.phone = this.getExcelDataByHeader(data[i]["Phone"],  data[i]["phone"]);
      csvRecord.job_title = this.getExcelDataByHeader(data[i]["Job Title"],  data[i]["job title"]);
      csvRecord.industry = this.getExcelDataByHeader(data[i]["Industry"],  data[i]["industry"]);
      csvRecord.linkedin = this.getExcelDataByHeader(data[i]["LinkedIn"], data[i]["linkedIn"]);
      csvRecord.snippet = this.getExcelDataByHeader(data[i]["Snippet"],  data[i]["snippet"]);
      csvRecord.snippet1 = this.getExcelDataByHeader(data[i]["Snippet1"],  data[i]["snippet1"]);
      csvRecord.snippet2 = this.getExcelDataByHeader(data[i]["Snippet2"],  data[i]["snippet2"]);
      csvRecord.snippet3 = this.getExcelDataByHeader(data[i]["Snippet3"],  data[i]["snippet3"]);
      csvRecord.snippet4 = this.getExcelDataByHeader(data[i]["Snippet4"],  data[i]["snippet4"]);
      csvRecord.email_alredy_exist = false;
      csvRecord.wrong_email_pattern = false;
      csvRecord.upload_status_check = "Ready to Process";

      if (csvRecord.email_address !== undefined && csvRecord.email_address !== null && csvRecord.email_address !== "") {
        var prospectCleanedEmail = String(csvRecord.email_address).toLowerCase().trim();

        if (!emailArr.includes(prospectCleanedEmail))
          emailArr.push(prospectCleanedEmail);
        else
        {
          csvRecord.email_alredy_exist = true;
          csvRecord.upload_status_check = "Duplicate Email"
        }
      }

      // var list_junk = ['na', 'NA', 'empty', 'not able found', 'NAN', ]

      if (csvRecord.firstname === undefined && csvRecord.firstname === "" ||
        csvRecord.lastname === undefined && csvRecord.lastname === "" ||
        csvRecord.email_address === undefined || csvRecord.email_address === ""
       )
        {
          if(this.issueWithRecord === false){
            this.issueWithRecord = true;
          }
          csvRecord.upload_status_check = "Missing Data"
        }

      if(csvRecord.email_address?.length > 0 && csvRecord.email_address != undefined){
        if(this.vm.validateEmail(csvRecord.email_address) === false){
          csvRecord.wrong_email_pattern = true;
          csvRecord.upload_status_check = "Wrong Email"
        }
      }


      csvArr.push(csvRecord);
    }
    return csvArr;
  }


csvRowToArray(row, delimiter = ',', quoteChar = '"'){
  let nStart = 0, nEnd = 0, a=[], nRowLen=row.length, bQuotedValue;
  while (nStart <= nRowLen) {
      bQuotedValue = (row.charAt(nStart) === quoteChar);
      if (bQuotedValue) {
          nStart++;
          nEnd = row.indexOf(quoteChar + delimiter, nStart)
      } else {
          nEnd = row.indexOf(delimiter, nStart)
      }
      if (nEnd < 0) nEnd = nRowLen;
      a.push(row.substring(nStart,nEnd));
      nStart = nEnd + delimiter.length + (bQuotedValue ? 1 : 0)
  }
  return a;
}
  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {

    let csvArr = [];
    let emailArr = [];
    for (let i = 1; i < csvRecordsArray.length; i++) {
      let curruntRecord = this.csvRowToArray(<string>csvRecordsArray[i]);
      if (curruntRecord.length == headerLength) {
        let csvRecord: ProspectModel = new ProspectModel();
        csvRecord.firstname = curruntRecord[0];
        csvRecord.middlename = curruntRecord[1];
        csvRecord.lastname = curruntRecord[2];
        csvRecord.email_address = curruntRecord[3];
        csvRecord.company = curruntRecord[4];
        csvRecord.address = curruntRecord[5];
        csvRecord.city = curruntRecord[6];
        csvRecord.state = curruntRecord[7];
        csvRecord.region = curruntRecord[8];
        csvRecord.country = curruntRecord[9];
        csvRecord.phone = curruntRecord[10];
        csvRecord.job_title = curruntRecord[11];
        csvRecord.industry = curruntRecord[12];
        csvRecord.linkedin = curruntRecord[13];
        csvRecord.snippet = curruntRecord[14];
        csvRecord.snippet1 = curruntRecord[15];
        csvRecord.snippet2 = curruntRecord[16];
        csvRecord.snippet3 = curruntRecord[17];
        csvRecord.snippet4 = curruntRecord[18];
        csvRecord.email_alredy_exist =false;
        csvRecord.wrong_email_pattern = false;
        csvRecord.upload_status_check = "Ready to Process";

        if (csvRecord.email_address !== undefined && csvRecord.email_address !== null && csvRecord.email_address !== "") {
          var prospectCleanedEmail = String(csvRecord.email_address).toLowerCase().trim();
          if (!emailArr.includes(prospectCleanedEmail))
            {
              emailArr.push(prospectCleanedEmail);
            }
          else
            {
            csvRecord.email_alredy_exist = true;
            csvRecord.upload_status_check = "Duplicate Email"
            }
        }

        if (csvRecord.firstname === undefined && csvRecord.firstname === "" ||
          csvRecord.lastname === undefined && csvRecord.lastname === "" ||
          csvRecord.email_address === undefined || csvRecord.email_address === "") {
            if(this.issueWithRecord === false){
              this.issueWithRecord = true;
            }
            csvRecord.upload_status_check = "Missing Data"
        }

        if(csvRecord.email_address.length > 0 && csvRecord.email_address != undefined){
          if(!this.vm.validateEmail(csvRecord.email_address)){
            csvRecord.wrong_email_pattern = true;
            csvRecord.upload_status_check = "Wrong Email"
          }
        }

        csvArr.push(csvRecord);
      }
    }
    return csvArr;
  }

  isValidCSVFile(file: any) {
    return file.name.endsWith(".csv");
  }
  sleep(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }
  doNothing() {
  }
  showRecordsWithIssues() {
    $("#prospectData tr").each(function () {
      var hideRow = true;
      $(this).find("td").each(function () {
        if ($(this)[0].className === 'td-background') {
          hideRow = false;
        }
        if ($(this)[0].className === 'td-yellow-background') {
          hideRow = false;
        }

        if ($(this)[0].className === 'td-wrong-email-background') {
          hideRow = false;
        }
      });
      if (hideRow === true)
        $(this).hide();
    });
  }


  isAscii(str) {
    var ascii = /^[ -~]+$/;
    return !ascii.test(str);
  }
  getClassName(str, isReq, emailExists = null, wrong_email_pattern) {

    if (isReq === 'true' && (str === undefined || str === null || str.length === 0))
      return "td-background";
    else if ((str !== undefined && str !== null && str.length > 0) && this.isAscii(str)) {
      // alert("i am set ansii");
      if(this.issueWithAncii === false){
        this.issueWithAncii = true;
      }
      return "td-background";
    }
    else if (emailExists) {
      if (this.duplicateEmails === false) {
        this.duplicateEmails = true;
      }
      return "td-yellow-background";
    }
    else if (wrong_email_pattern) {
      if (this.wrongEmailFound === false){
        this.wrongEmailFound = true;
      }
      return "td-wrong-email-background";
    }
    else
      return "td-no-background";


  }
  getHeaderArray(csvRecordsArr: any) {
    let headers = (<string>csvRecordsArr[0]).split(',');
    let headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  fileReset() {
    this.csvReader.nativeElement.value = "";
    this.records = [];
  }

  forceUpload(){
    if(this.hiddenEvent){
      this.confirmDialogService.confirmThis("Are you sure you want to upload this data?", function () {
        NewImportComponent.importCall.handleFileInput();
      }, function () {
      })
    }
  }

  ngAfterViewInit() {
    this.changeDetectRef.detectChanges();
  }

}
