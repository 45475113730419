import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { MapCommon } from 'src/app/app-providers/map-base/MapCommon';
import { GlobalDncModel } from '../../dnc-models/dnc-model';
import { DncViewModel } from '../../dnc-models/dnc-view-model';

@Component({
  selector: 'app-dnc-global-edit',
  templateUrl: './dnc-global-edit.component.html',
  styleUrls: ['./dnc-global-edit.component.scss']
})
export class DncGlobalEditComponent implements OnInit {

  globalDncModel: GlobalDncModel;
  userDeleteWarning: boolean = false;
  removeFromDncWarningCss = 'btn btn-danger ms-auto me-auto';
  currentUser: number;

  constructor(public modalRef: MdbModalRef<DncGlobalEditComponent>, private vm: DncViewModel) {
    this.currentUser = MapCommon.getloggedInUser().id;
   }

  ngOnInit(): void {
  }

  editClientDnc(){
    this.globalDncModel.modified_by = this.currentUser;
    this.globalDncModel.modified_at = new Date;
    this.vm.parentDncGlobalEditComponent = this;
    this.vm.updateClientDnc(this.globalDncModel, 'global');
  }

  removeFromDncWarning(){
    this.userDeleteWarning = true; 
    this.removeFromDncWarningCss = 'btn btn-outline-light ms-auto me-auto';
    
  }

  removeFromDncConfirmed(){
    this.vm.parentDncGlobalEditComponent = this;
    this.vm.deleteClientDncDnc(this.globalDncModel, 'global');
  }

  updateSuccess() {
    this.modalRef.close(true);
  }

  deleteSuccess() {
    this.modalRef.close(true);
  }

  cancelWarning(){
    this.modalRef.close(false);
  }
}
