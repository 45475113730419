

<div class="ms-sm-auto" style="width: 90%;">


<div class="row" style="height: 100%;">



  <div class="col-2 shadow-3" style="background: #FBF7FE; height: 100vh; overflow-x: hidden; overflow-y: auto;scrollbar-width: thin; ">

        <app-sidebar-settings></app-sidebar-settings>
    </div>


  <div class="col-10 p-3" style="height: 100vh; overflow-x: hidden; overflow-y: auto; scrollbar-width: thin;">






    <div class="row">

    </div>

    <h4>Personal</h4> <hr>

    <div class="row" style="padding-left: 10px; padding-right: 10px;">


      <div class="container mt-3">
        <h5>Password</h5> <br>
        <p>To change your password, please visit the link below.</p> <br>
        <button class="btn btn-light" type="button" (click)="logout()">Change Password</button>
      </div>

      <!-- <div class="row">
        <div class="col text-center" style="padding-top: 70px;">

            <svg xmlns="http://www.w3.org/2000/svg" width="161.814" height="161.814" viewBox="0 0 161.814 161.814">
                <path id="Icon_material-broken_image" data-name="Icon material-broken_image" d="M166.314,22.479V81.721L139.345,54.662,103.386,90.711,67.428,54.752,31.469,90.711,4.5,63.652V22.479A18.032,18.032,0,0,1,22.479,4.5H148.334A18.032,18.032,0,0,1,166.314,22.479ZM139.345,80.193l26.969,27.059v41.083a18.032,18.032,0,0,1-17.979,17.979H22.479A18.032,18.032,0,0,1,4.5,148.334V89.182l26.969,26.879L67.428,80.1l35.959,35.959Z" transform="translate(-4.5 -4.5)" fill="#f3f3f3"/>
              </svg>
              <p class="pt-4">This selection will be available from next release.</p>
        </div>
      </div> -->

   </div>

    </div>


  </div>


  </div>
