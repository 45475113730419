import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
// import { BehaviorSubject, Observer } from "rxjs";
import { IApiServiceDelegate, MapAPIService, MapAPICollection, } from "../../app-providers/services/map-module";
// import { AlertNotificationsComponent } from "src/app/Alerts/alert-notifications/alert-notifications.component";


@Injectable({
    providedIn: "root",
})




export class LinkTrackingViewModel {
 

    constructor(private apiService: MapAPIService, private router: Router) { }

    postLinkInfo(link_info: string, destination) {
        console.log(destination)
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "PUT",
            Action: MapAPICollection.LINK_TRACKING + '/' + link_info,
            Success: (e: any) => {
                window.location.href = destination;   
                return e;
            },
            Error: (e: String) => {
                window.location.href = destination;
                return e;
            },
        };
        this.apiService.callService(d);
    }


}