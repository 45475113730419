import { Router } from "@angular/router";
import { LoginUserModel } from "src/app/login/models/login-model";
import { environment } from "src/environments/environment";

export class MapCommon {
  static mainRouter: Router;

  constructor() { }

  // to get logged in saved objects
  static getloggedInUser() {
    return JSON.parse(localStorage.getItem("loggedInUser")) || undefined;
  }

  static getSendingRateList(){
    return [
      {'id': 1, 'text': 'High (10-20 sec delay)'},
      {'id': 2, 'text': 'Medium (20-40 sec delay)'},
      {'id': 3, 'text': 'Slow (40-60 sec delay)'},
      {'id': 4, 'text': 'Fast (0 sec delay)'}
    ]
  }


  static getProspectInterests(){
    return [
      {'id': 1, 'name': 'Interested'},
      {'id': 2, 'name': 'Not interested'},
      {'id': 3, 'name': 'Unavailable'}
    ]
  }

  static getBaseApiUrl(){
    return environment.base_api_url;
  }

  static getDefaultProspectGroup() {
    return 1;
  }
  // to get status of logged in user
  static isLoggedIn() {
    if (
      this.getloggedInUser() != undefined &&
      this.getloggedInUser() != null &&
      this.getloggedInUser().token != undefined &&
      this.getloggedInUser().token != ""
    ) {
      return true;
    } else {
      return false;
    }
  }

  // to remove all local storage elements of the user
  static removeItemsOnLogOut() {
    //localStorage.removeItem('loggedInUser');
    localStorage.clear();
  }

  // to add logged in object recieved from login api
  static setloggedInUser(response: LoginUserModel) {
    try {
      if (response.id){
        localStorage.setItem("loggedInUser", JSON.stringify(response));
      }
    } catch {
      // Pass
    }
  }

  static getTinyEditorAPIKey() {
    return  "zst79efjk2uw4eyf0t05bgzi8islnueoegxm9xfpwrmo4oaa";
  }

  static getSettingMailboxEditorModule(caller = null){
    var modules = {};  
    if(caller === 'opt-out'){
      modules = {
        plugins: 'print preview paste importcss searchreplace autolink directionality code visualblocks visualchars fullscreen link codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount textpattern noneditable help charmap quickbars emoticons',
        menubar: 'file edit view insert format tools table help',
        toolbar: 'insertOptoutLink | undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile link anchor codesample | ltr rtl',
        toolbar_sticky: true,
        height: 300,
        quickbars_selection_toolbar: 'bold italic | quicklink insertOptoutLink h2 h3 blockquote quicktable',
        noneditable_noneditable_class: 'mceNonEditable',
        toolbar_mode: 'sliding',
        contextmenu: 'link table',
        fontsize_formats: "6pt 7pt 8pt 9pt 10pt 11pt 12pt 13pt 14pt 18pt 24pt 36pt",
        font_formats: "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Calibri=calibri,sans-serif; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
        // content_style: "@import url('https://fonts.googleapis.com/css?family=Calibri:400,700,400italic,700italic'); body { font-family:Helvetica,Arial,sans-serif; font-size:12px }"
        setup: function (editor) {
          editor.ui.registry.addButton('insertOptoutLink', {
            tooltip: 'Opt-out Link',
            text: 'Opt-out link',
            onAction: function () {
              var selectedText = editor.selection.getContent({ format: 'text' });
              if(selectedText && selectedText?.length > 0){
                editor.insertContent('<a href="{{Opt-Out-Url}}" target="_blank">' + selectedText + '</a>');  
              } else {
                editor.insertContent('{{Opt-Out-Url}}');  
              } 
            }
          });
  
        },
        content_style: "@import url('https://fonts.googleapis.com/css?family=Calibri:400,700,400italic,700italic');"
      }
    } else {
      modules = {
        plugins: 'print preview paste importcss searchreplace autolink directionality code visualblocks visualchars fullscreen link codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount textpattern noneditable help charmap quickbars emoticons',
        menubar: 'file edit view insert format tools table help',
        toolbar: 'insertOptoutLink | undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile link anchor codesample | ltr rtl',
        toolbar_sticky: true,
        height: 300,
        quickbars_selection_toolbar: 'bold italic | quicklink insertOptoutLink h2 h3 blockquote quicktable',
        noneditable_noneditable_class: 'mceNonEditable',
        toolbar_mode: 'sliding',
        contextmenu: 'link table',
        fontsize_formats: "6pt 7pt 8pt 9pt 10pt 11pt 12pt 13pt 14pt 18pt 24pt 36pt",
        font_formats: "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Calibri=calibri,sans-serif; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
        content_style: "@import url('https://fonts.googleapis.com/css?family=Calibri:400,700,400italic,700italic');"
      }
    } 

    return modules
  }

  static getCampaignReviewEditorModule() {
    return {
      plugins: 'print preview paste importcss searchreplace autolink directionality code visualblocks visualchars fullscreen link codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount textpattern noneditable help charmap quickbars emoticons',
      menubar: 'file edit view insert format tools table help',
      toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile link anchor codesample | ltr rtl',
      toolbar_sticky: true,
      height: 400,
      quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quicktable',
      noneditable_noneditable_class: 'mceNonEditable',
      toolbar_mode: 'sliding',
      contextmenu: 'link table',
      fontsize_formats: "6pt 7pt 8pt 9pt 10pt 11pt 12pt 13pt 14pt 18pt 24pt 36pt",
      font_formats: "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Calibri=calibri,sans-serif; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
      // content_style: "@import url('https://fonts.googleapis.com/css?family=Calibri:400,700,400italic,700italic'); body { font-family:Helvetica,Arial,sans-serif; font-size:12px }"
      content_style: "@import url('https://fonts.googleapis.com/css?family=Calibri:400,700,400italic,700italic'); "
     
    }
  }

  static getCampaignEditorModule() {
    return {
      plugins: 'print preview paste importcss searchreplace autolink directionality code visualblocks visualchars fullscreen link codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount textpattern noneditable help charmap quickbars emoticons',
      menubar: 'file edit view insert format tools table help',
      toolbar: 'cus-variable | undo redo | bold italic underline strikethrough  | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile link anchor codesample | ltr rtl',
      toolbar_sticky: true,
      height: 350,
      quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quicktable',
      noneditable_noneditable_class: 'mceNonEditable',
      toolbar_mode: 'sliding',
      contextmenu: 'link  table',
      fontsize_formats: "6pt 7pt 8pt 9pt 10pt 11pt 12pt 13pt 14pt 18pt 24pt 36pt",
      font_formats: "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Calibri=calibri,sans-serif; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
      setup: function (editor) {
        var toggleState = false;
        editor.ui.registry.addMenuButton('cus-variable', {
          text: 'Variables',
          fetch: function (callback) {
            var items = [
              {type: 'menuitem', text: 'First Name', onAction: function () {editor.insertContent('{{First Name}}'); }},
              {type: 'menuitem', text: 'Middle Name', onAction: function () {editor.insertContent('{{Middle Name}}'); }},
              {type: 'menuitem', text: 'Last Name', onAction: function () {editor.insertContent('{{Last Name}}'); }},
              {type: 'menuitem', text: 'Email Address', onAction: function () {editor.insertContent('{{Email Address}}'); }},
              {type: 'menuitem', text: 'Company', onAction: function () {editor.insertContent('{{Company}}'); }},
              {type: 'menuitem', text: 'Address', onAction: function () {editor.insertContent('{{Address}}'); }},
              {type: 'menuitem', text: 'City', onAction: function () {editor.insertContent('{{City}}'); }},
              {type: 'menuitem', text: 'State', onAction: function () {editor.insertContent('{{State}}'); }},
              {type: 'menuitem', text: 'Country', onAction: function () {editor.insertContent('{{Country}}'); }},
              {type: 'menuitem', text: 'Phone', onAction: function () {editor.insertContent('{{Phone}}'); }},
              {type: 'menuitem', text: 'Job Title', onAction: function () {editor.insertContent('{{Job Title}}'); }},
              {type: 'menuitem', text: 'Industry', onAction: function () {editor.insertContent('{{Industry}}'); }},
              {type: 'menuitem', text: 'LinkedIn', onAction: function () {editor.insertContent('{{LinkedIn}}'); }},
              {type: 'menuitem', text: 'Snippet', onAction: function () {editor.insertContent('{{Snippet}}'); }},
              {type: 'menuitem', text: 'Snippet 1', onAction: function () {editor.insertContent('{{Snippet1}}'); }},
              {type: 'menuitem', text: 'Snippet 2', onAction: function () {editor.insertContent('{{Snippet2}}'); }},
              {type: 'menuitem', text: 'Snippet 3', onAction: function () {editor.insertContent('{{Snippet3}}'); }},
              {type: 'menuitem', text: 'Snippet 4', onAction: function () {editor.insertContent('{{Snippet4}}'); }},
            ];
            callback(items);
          }
        });

      },
      content_style: "@import url('https://fonts.googleapis.com/css?family=Calibri:400,700,400italic,700italic'); "
      // content_style: "@import url('https://fonts.googleapis.com/css?family=Calibri:400,700,400italic,700italic'); body { font-family:Helvetica,Arial,sans-serif; font-size:12px }"
     
     }
  }


  static getAvailableEmailVariables() {return [
    "{{First Name}}", "{{Middle Name}}", "{{Last Name}}", "{{Email Address}}", "{{Company}}", "{{Address}}", "{{City}}",
    "{{State}}", "{{Country}}", "{{Phone}}", "{{Job Title}}", "{{Industry}}", "{{LinkedIn}}", "{{Snippet}}",
    "{{Snippet1}}", "{{Snippet2}}", "{{Snippet3}}", "{{Snippet4}}"]
  }

  static findWrongVariables(textarea){
    var regex = new RegExp(/{{(.*?)}}/g);
    var available_email_variables = this.getAvailableEmailVariables();
    function findItems(regex, str, matches = []) {
      const res = regex.exec(str)
      res && matches.push(res) && findItems(regex, str, matches)
      return matches
    }

    const matches_items = findItems(regex, textarea);
    var wrongItems = []
    matches_items.forEach(e => {
      if(!available_email_variables.includes(e[0])){
        wrongItems.push(e[0])
      }
    })
    return wrongItems;
  }


}
