<div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Auto Generate A/B Steps</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
  </div>
  <div class="modal-body">
  
    <div class="p-4">
        <p class="text-center">
          Provide number of A/B steps to be auto generated.
          </p>
      
          <mdb-form-control>
            <input mdbInput type="number" [min]="1" [max]="10" 
            [(ngModel)]="ABSTEPS" class="form-control"    placeholder="Enter an number" />
          </mdb-form-control>
    </div>

      
</div>

<div class="modal-footer d-flex justify-content-between">
    <button type="button" class="btn btn-link" (click)="modalRef.close()">
      Close
    </button>
    <button type="button" (click)="generateAbSteps()" class="btn btn-primary">Generate</button>
  </div>

  <app-loading-dialog *ngIf="isLoading">

  </app-loading-dialog>