
<div class="tracking-sidebar">
  <div class="mt-2"></div>

  <!-- <hr class="mb-0"> -->

  <ul class="nav flex-column mb-auto">

    <li class="nav-item">
      <a class="nav-link" [routerLinkActive]="['active']" routerLink="/analytics/best-sending-time">
       <i class="far fa-clock pe-2"></i> Best Sending Time</a>
    </li>

    <li class="nav-item">
      <a class="nav-link" [routerLinkActive]="['active']" routerLink="/analytics/link-tracking">
        <i class="fas fa-link pe-2"></i> Link Tracking</a>
    </li>

    <li class="nav-item">
      <a class="nav-link" [routerLinkActive]="['active']" routerLink="/analytics/open-tracking">
        <i class="far fa-envelope-open pe-2"></i> Open Tracking</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [routerLinkActive]="['active']" routerLink="/analytics/subject-line">
        <i class="far fa-sticky-note pe-2"></i> Subject Tracking</a>
    </li>

    <!-- <li class="nav-item">
      <a class="nav-link" [routerLinkActive]="['active']" routerLink="/analytics/opt-out-tracking">
        <i class="far fa-times-circle pe-2"></i> Opt-Out Tracking</a>
    </li> -->



  </ul>
</div>
