<div class="col-12 d-flex p-0" *ngIf="!FieldValues">
  <input [(ngModel)]="_getSubjectModel"
    (ngModelChange)="changeTypeaheadLoading()" id={{setId}}  (focus)="changeTypeaheadLoading()"
     class="form-control autosuggesttag"  type="text" (blur)="hideIt()"
      placeholder="Search Subject" autocomplete="off" name={{setId}} />
  <span class="highlight"></span>

</div>
<div class="col-12 d-flex p-0 position-relative" *ngIf="subjectList.length > 0">
  <ul class="bg-light pl-0 w-100 list-unstyled position-absolute dropdownsuggest" id={{setId}} >
    <li class="w-100 d-flex justify-content-between border-bottom py-2" style="font-size:12px" (click)="onSelect(sub)" *ngFor="let sub of subjectList"><span class="small px-2 d-inline-block">{{sub.subject.length < 110 ? sub.subject : sub.subject.slice(0,110)+'....' }} </span>  <span class="px-2 d-inline-block small fst-italic" style="font-size:11px" [ngClass]="{'text-success' : sub.open_rate > 5.0,'text-danger' : sub.open_rate < 5.0}">Open rate: {{sub.open_rate | number:'1.2-2'}}% </span> </li>
  </ul>
</div>
<div class="col-12 d-flex p-0" *ngIf="FieldValues">
  <input [(ngModel)]="_getSubjectModel"
    (ngModelChange)="changeTypeaheadLoadingFocusNot($event)" id={{setId}}  (focus)="changeTypeaheadLoadingFocus($event)"
     class="form-control autosuggesttag"  type="text" (blur)="hideIt()"
      placeholder="Search Subject" autocomplete="off" name={{setId}} />
  <span class="highlight"></span>

</div>
<div class="col-12 d-flex p-0 position-relative" *ngIf="FieldValues">
  <div *ngFor="let values of FieldValues ;let count=index;">
  <ul class="bg-light pl-0 w-100 list-unstyled position-absolute dropdownsuggest" id={{returnVal(count)}} *ngIf="values.subjectList && loadSubject === subjectID">
    <li class="w-100 d-flex justify-content-between border-bottom " style="font-size:12px" (click)="onSelect(sub)" *ngFor="let sub of values.subjectList"><span class="small py-2 px-2 d-inline-block">{{sub.subject.length < 110 ? sub.subject : sub.subject.slice(0,110)+'....' }} </span>  <span class="px-2 py-2 d-inline-block small fst-italic" style="font-size:11px" [ngClass]="{'text-success' : sub.open_rate > 5.0,'text-danger' : sub.open_rate < 5.0}">Open rate: {{sub.open_rate | number:'1.2-2'}}% </span> </li>
  </ul>
</div>
</div>
