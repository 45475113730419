export class  TemplateResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [TemplateModel]
}

export class TemplateModel
{
    id:number
    name: string
    body: string
    status: boolean
    created_at: Date
    modified_at: Date
    created_by: number
    modified_by: number
}