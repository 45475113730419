

<div class="ms-sm-auto" style="width: 90%;">


    <div class="row" style="height: 100%;">



        <div class="col-2 shadow-3" style="background: #FBF7FE; height: 100vh; overflow-x: hidden; overflow-y: auto; ">

            <app-sidebar-dnc></app-sidebar-dnc>
        </div>



        <div class="col-10 p-2" style="height: 100vh; overflow-x: hidden; overflow-y: auto; ">


            <div class="col-12 d-flex justify-content-between">
                <h6 class="pt-1 ps-1">Import DNC </h6>
                <!-- <span class="header-count">(0 Found)</span>  -->
            </div>

            <hr class="m-0">

           <div class="row mt-2  ps-1">
               <div class="col-3">


               <div class="mt-3 ps-2">
                <p class="mb-0">What to import:</p>
                <ng-select [(ngModel)]="selectedType" id="selectedScreenInput"
                class="header-imput w-100" (change)='onSelectedImportType()' placeholder="Type not selected">
                    <ng-option  [value]="1">Client DNC</ng-option>
                    <ng-option  [value]="2">Global DNC</ng-option>
                </ng-select>

               </div>


               <div class="mt-3 ps-2">
                <p class="mb-0">Select a client:</p>
                <ng-select [disabled]="selectedType == 2 || !selectedType"
                [(ngModel)]="selectedClient" id="selectedClientInput" (change)='onSelectedClient()'
                class="header-imput w-100" placeholder="Client not selected">
                    <ng-option *ngFor="let item of clientResponseModel?.value.results"
                    [value]="item.id">{{item.name}}</ng-option>
                </ng-select>

               </div>



               </div>

               <div class="col-9 mt-3">
                    <P class="pe-5" >We accept the following formats: <b class="font-weight-bold">XLSX</b> or <b class="font-weight-bold">CSV</b>.
                      <br>
                      Import up to 2,000 rows of data at a time. <br>
                      The first row in your file must contain column headers (first name, middle name, last name,
                      email address, company, address, city, state, country, phone, job title, industry, linkedin, snippet,
                      snippet1, snippet2, snippet3, snippet4 out of all <strong>first name</strong> and <strong>email</strong> are required). <br>
                      Or download any of this <strong>Samples: (<a href="../../assets/map-samples/prospects_template_xlsx.xlsx"
                          download>XLSX</a>/<a href="../../assets/map-samples/prospects_template_csv.csv" download>CSV</a>)</strong>,
                      add your prospects, and import the file.
                    </P>
               </div>

           </div>

           <div class="row">
                <div class="col-12 ps-3" *ngIf="(selectedClient > 0 && selectedType == 1) || (selectedType == 2)">
                    <div class="ps-2">


                        <div class="col-12">
                            <input type="file" class="import-dnc-input" (change)="onFileSelected($event)" #fileUpload (click)="$event.target.value=null">
                            <div class="import-dnc-upload">
                                <button type="button" class="btn btn-primary btn-sm mt-2 me-2" (click)="fileUpload.click()">
                                <i *ngIf="!importDncUploadInProgress" class="far fa-file-excel"></i>
                                <i  *ngIf="importDncUploadInProgress" class="fas fa-spinner fa-spin"></i>
                                 Select File
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

           </div>


        <div class="row p-3 mt-4" style="background-color: rgb(240, 240, 240);"
           *ngIf="showImportSccuess">

             <div class="col-12" *ngIf="importDncResponseModel?.value.total_record > 0">
               <h6>Total: {{importDncResponseModel?.value.total_record}}</h6>
               <h6>Successed: {{importDncResponseModel?.value.success_count}}</h6>
               <h6>Failed: {{importDncResponseModel?.value.failed_count}}</h6>
             </div>

           <div class="col-6 mt-2" *ngIf="importDncResponseModel?.value.failed?.length > 0">
               <h6>Invalid Emails</h6>
               <div style="overflow-x: auto; height: 30vh; width: 18rem;">
                <div class="card">
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item" *ngFor="let item of importDncResponseModel?.value.failed">{{item}}</li>
                    </ul>
                </div>
               </div>
           </div>
           </div>





        </div>




      </div>


      </div>






