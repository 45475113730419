<app-loading-dialog *ngIf="isLoading">
</app-loading-dialog>

<div class="ms-sm-auto" style="width: 90%;">
  <div class="row" style="height: 100%;">

    <div class="col-2 shadow-3" style="background: #FBF7FE; height: 100vh; overflow-x: hidden; overflow-y: auto; ">

      <app-sidebar-import></app-sidebar-import>

    </div>
    <div class="col-10 p-5" style="height: 100vh; overflow-x: hidden; overflow-y: auto; ">

      <div class="row">
        <div class="col-12">
          <H3>Upload File</H3>
          <div class="row">

          <div class="col-8">
            <P>We accept the following formats: <b class="font-weight-bold">XLSX</b> or <b class="font-weight-bold">CSV</b>.
              <br>
              Import up to 2,000 rows of data at a time. <br>
              The first row in your file must contain column headers (<b class="font-weight-bold">First Name, Last Name and Email</b> are required). <br>
              Or download any of this <strong>Samples: (<a href="../../assets/map-samples/prospects_template_xlsx.xlsx"
                  download>XLSX</a>/<a href="../../assets/map-samples/prospects_template_csv.csv" download>CSV</a>)</strong>,
              add your prospects, and import the file.
            </P>
          </div>

          <div class="col-4">
            <p *ngIf="issueWithRecord" class="issueWithRecord m-0">* There are some missing data.</p>
              <p *ngIf="issueWithAncii" class="issueWithRecord m-0">* There are some speacial character on the file.</p>
              <p *ngIf="duplicateEmails" class="issueWithRecord m-0">* Duplicate emails found.</p>
              <p *ngIf="wrongEmailFound" class="issueWithRecord m-0">* Wrong emails found.</p>
              <p *ngIf="issueWithHeader" class="issueWithRecord m-0">* There is problem with file header, please check samples.</p>
              <ul *ngIf="showWrongHeaders.length > 0" class="horizontal-wrong-header">
                <li class="issueWithRecord" *ngFor="let header of showWrongHeaders">{{header}}, </li>
              </ul>
          </div>
          </div>

          <div class="form-group">
            <div class="d-flex justify-content-between">
              <button type="button" class="btn btn-warning" (click)="csvReader.click()">SELECT FILE</button>
              <input accept=".csv,.XLSX" hidden type="file" #csvReader (change)="uploadListener($event)" />

             <div>
              <button type="button" type="button" class="btn btn-primary me-2 ms-2"
              *ngIf="records.length > 0 && !issueWithRecord && !issueWithAncii &&
              !duplicateEmails && !wrongEmailFound && !issueWithHeader"
                (click)="confirmUpload()">Click To upload</button>



                <button #doNothingButton type="button" type="button" hidden class="btn btn-primary me-2 ms-2"
                (click)="doNothing()">Click To do nothing</button>
                <button type="button" type="button" class="btn btn-primary me-2 ms-2"
                *ngIf="records.length > 0 && (issueWithRecord || issueWithAncii || duplicateEmails || wrongEmailFound) && !issueWithHeader"
                  (click)="showRecordsWithIssues()">Show records with issue</button>

                  <button *ngIf="this.hiddenEvent &&
                  (records.length > 0 && (issueWithAncii || duplicateEmails || wrongEmailFound || issueWithHeader) && !issueWithRecord)"
                  type="button" class="btn btn-link" (click)="forceUpload()">Force Upload</button>

                <!-- <button *ngIf="this.hiddenEvent &&
                (records.length > 0 && issueWithRecord || issueWithAncii || duplicateEmails || wrongEmailFound || issueWithHeader)"
                type="button" class="btn btn-link" (click)="forceUpload()">Force Upload</button> -->

             </div>
            </div>

          </div>
        </div>
      </div>



      <div class="row p-3 mt-2" style="background-color: rgb(240, 240, 240);"
      *ngIf="(showDuplicatesAfterError.length > 0) || (showInvalidEmailsAfterError.length > 0)">

        <div class="col-12">
          <h5>Sorry, Can't upload the file, please check the data and re-upload.</h5>
        </div>

      <div class="col-6 mt-2" *ngIf="showDuplicatesAfterError.length > 0">
          <h6>Duplicate Emails</h6>
          <div class="card" style="width: 18rem;">
            <ul class="list-group list-group-flush">
              <li class="list-group-item" *ngFor="let duplicateEmail of showDuplicatesAfterError">{{duplicateEmail}}</li>
            </ul>
        </div>
      </div>

      <div class="col-6 mt-2" *ngIf="showInvalidEmailsAfterError.length > 0">
          <h6>Invalid Emails</h6>
          <div class="card" style="width: 18rem;">
            <ul class="list-group list-group-flush">
              <li class="list-group-item" *ngFor="let invalidEmail of showInvalidEmailsAfterError">{{invalidEmail}}</li>
            </ul>
        </div>
      </div>
      </div>


      <div class="row mt-3" *ngIf="!(showDuplicatesAfterError.length > 0) ||
        !(showInvalidEmailsAfterError.length > 0) || !issueWithHeader">
        <div class="col-12">
          <div class="table-responsive">
            <table class="table table-sm table-hover table-bordered" *ngIf="records.length > 0">
              <thead>
                <tr>
                  <th>Status</th>
                  <th>First Name *</th>
                  <th>Middle Name </th>
                  <th>Last Name *</th>
                  <th>Email Address *</th>
                  <th>Company</th>
                  <th>Address </th>
                  <th>City </th>
                  <th>State </th>
                  <th>Region </th>
                  <th>Country</th>
                  <th>Phone </th>
                  <th>Job Title</th>
                  <th>Industry </th>
                  <th>Linkedin </th>
                  <th>Snippet </th>
                  <th>Snippet 1 </th>
                  <th>Snippet 2</th>
                  <th>Snippet 3 </th>
                  <th>Snippet 4 </th>

                </tr>
              </thead>
              <tbody id="prospectData">
                <tr *ngFor="let record of records;let i = index;">
                  <td class="td-no-background"><span>{{record.upload_status_check}}</span> </td>
                  <td [class]="getClassName(record.firstname,'true')"><span>{{record.firstname}}</span> </td>
                  <td [class]="getClassName(record.middlename,'false')"> <span>{{record.middlename}}</span> </td>
                  <td [class]="getClassName(record.lastname,'true')">  <span>{{record.lastname}}</span> </td>
                  <td [class]="getClassName(record.email_address,'true', record.email_alredy_exist, record.wrong_email_pattern)"> <span>{{record.email_address}}</span> </td>
                  <td [class]="getClassName(record.company,'false')"> <span>{{record.company}}</span> </td>
                  <td [class]="getClassName(record.address,'false')"> <span>{{record.address}}</span> </td>
                  <td [class]="getClassName(record.city,'false')"> <span>{{record.city}}</span> </td>
                  <td [class]="getClassName(record.state,'false')"> <span>{{record.state}}</span> </td>
                  <td [class]="getClassName(record.region,'false')"> <span>{{record.region}}</span> </td>
                  <td [class]="getClassName(record.country,'false')"> <span>{{record.country}}</span> </td>
                  <td [class]="getClassName(record.phone,'false')"> <span>{{record.phone}}</span> </td>
                  <td [class]="getClassName(record.job_title,'false')"> <span>{{record.job_title}}</span> </td>
                  <td [class]="getClassName(record.industry,'false')"> <span>{{record.industry}}</span> </td>
                  <td [class]="getClassName(record.linkedin,'false')"> <span>{{record.linkedin}}</span> </td>
                  <td [class]="getClassName(record.snippet,'false')"> <span>{{record.snippet}}</span> </td>
                  <td [class]="getClassName(record.snippet1,'false')"> <span>{{record.snippet1}}</span> </td>
                  <td [class]="getClassName(record.snippet2,'false')"> <span>{{record.snippet2}}</span> </td>
                  <td [class]="getClassName(record.snippet3,'false')"> <span>{{record.snippet3}}</span> </td>
                  <td [class]="getClassName(record.snippet4,'false')"> <span>{{record.snippet4}}</span> </td>

                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>



    </div>



  </div>

</div>
