import { Injectable } from "@angular/core";
import { BehaviorSubject, Observer } from "rxjs";
import { AlertNotificationsComponent } from "src/app/Alerts/alert-notifications/alert-notifications.component";
import { MapAPICollection } from "src/app/app-providers/services/map-api-collection";
import { IApiServiceDelegate } from "src/app/app-providers/services/map-api-IApiServiceDelegate";
import { MapAPIService } from "src/app/app-providers/services/map-api-service";
import { UnsubscribeComponent } from "../unsubscribe.component";
import { UnsubscribeProspectModel } from "./unsubscribeModel";

@Injectable({
    providedIn: "root",
})

export class UnsubscribeViewModel {


    parentUnsubscribeComponent: UnsubscribeComponent;


    unsubscribeProspectModel = new BehaviorSubject<UnsubscribeProspectModel>(
        new UnsubscribeProspectModel()
    );

  
    constructor(private apiService: MapAPIService) { }

    subscribeUnsubscribeProspectVariable(
        observerUnsubscribeProspectModel: Observer<UnsubscribeProspectModel>,) {
        this.unsubscribeProspectModel.subscribe(observerUnsubscribeProspectModel)
    }



    
    unsubscribeMe(url: string): void {
        let d: IApiServiceDelegate = {
          Data: {},
          Method: "GET",
          Action: MapAPICollection.UNSUBSCRIBE + '/' + url,
          Success: (e: any) => {
            this.parentUnsubscribeComponent.unsubsribeSuccess()
            // AlertNotificationsComponent.alertCall.showAlert(('Successfully disconnected'), 'primary');
            return e;
              
          },
          Error: (e: string) => {
            this.parentUnsubscribeComponent.unsubsribeFailed()
              // AlertNotificationsComponent.alertCall.showAlert('There was some problem, please try later!', 'danger');
              return null;
          },
        };
        this.apiService.callService(d);
      }


      
    getProspectEmail(id: number){
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_PROSPECT_EMAIL.replace('{id}', id.toString()),
            Success: (e: any) => {
              this.unsubscribeProspectModel.next(e)
              this.parentUnsubscribeComponent.prospectEmailReceived()
              return e;
                
            },
            Error: (e: string) => {
                this.parentUnsubscribeComponent.cantFindProspectEmail()
                // AlertNotificationsComponent.alertCall.showAlert('There was some problem, please try later!', 'danger');
                return null;
            },
          };
          this.apiService.callService(d);
        }

}
