<div *ngIf="showClearFilter" class="row shadow-1 p-3 sticky-top bg-white" style="z-index: 2;">
  <div style="margin-top: 15px;"></div>
  <a href="#" (click)="clearSelection($event)">Clear filters</a>
</div>

<form #searchForm="ngForm">
  <mdb-form-control class="mt-3">
    <input mdbInput type="text" id="form1" [(ngModel)]="searchEmailId" [disabled]="prospectLoaded" (change)="searchSubmit($event)"
      [ngModelOptions]="{standalone: true}" class="form-control" />
    <label mdbLabel class="form-label" for="form1"><i class="far fa-envelope"></i> Email</label>
  </mdb-form-control>
</form>

<!-- <mdb-form-control class="mt-3">
    <input mdbInput type="text" id="form1" (click)="sidebarFilter(email,'email')"
    (ngModel)]="emailContainsText" value="{{emailContainsText}}"
    (input)="handleUserInput('emailContains')" class="form-control" />
    <label mdbLabel class="form-label"  for="form1"><i class="far fa-envelope"></i> Email</label>
  </mdb-form-control> -->

<!-- <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioEmail" id="emailContains"
          (click)="toggleEmailFilter(emailContains,'emailContains')" aria-controls="emailContains" />
        <label class="form-check-label" for="emailContains">Contain</label>
        <input mdbInput type="text" id="emailContainsform1" id="emailContains" mdbCollapse #emailContains="mdbCollapse"
          class="form-control" [(ngModel)]="emailContainsText" value="{{emailContainsText}}" (input)="handleUserInput('emailContains')"  />
      </div> -->

<div class="p-2 prospect-sidebar">
  <div class="form-check mb-2">
    <input mdbRadio (click)="sidebarFilter(createdBy,'createdBy',$event)" aria-controls="createdBy"
      aria-expanded="false" class="form-check-input" type="checkbox" name="flexRadioDefault1" id="flexRadioDefault1"
      [checked]="false" />
    <label class="form-check-label" for="flexRadioDefault1"> Created By</label>
    <div class="pt-1">
      <ng-select [(ngModel)]="createdById" id="createdBy" (change)='onCreatedBySelected()' mdbCollapse
        #createdBy="mdbCollapse">
        <ng-option *ngFor="let o of userList" [value]="o.id">{{o.email?.length > 0 ? o.email: o.username}}</ng-option>
      </ng-select>
    </div>
  </div>

  <div class="form-check mb-2">
    <input mdbRadio (click)="sidebarFilter(status,'status',$event)" aria-controls="status"
      aria-expanded="false" class="form-check-input" type="checkbox" name="flexRadioDefault2" id="flexRadioDefault2"
      [checked]="false" />
    <label class="form-check-label" for="flexRadioDefault2"> Status</label>
    <div class="mt-1" id="status" mdbCollapse #status="mdbCollapse">
      <div class="form-check"  *ngFor="let status of prospectStatuslist;let i = index;" [data-index]="i">
        <input mdbRadio value="{{status.id}}" (click)="onStatusSelected(status)" [checked]="statusId == status.id"
        class="form-check-input" type="radio" name="flexRadioStatus" id="open_{{status.id}}" />
        <label class="form-check-label" for="open_{{status.id}}">{{status.name}}</label>
      </div>

    </div>
  </div>

  <!-- <div class="form-check mb-2">
    <input mdbRadio (click)="sidebarFilter(campaigncollapse,'campaigncollapse')" aria-controls="campaigncollapse"
      aria-expanded="false" class="form-check-input" type="checkbox" name="flexRadioDefault3" id="flexRadioDefault3"
      [checked]="false" />
    <label class="form-check-label" for="flexRadioDefault3"> Campaign</label>
    <div class="mt-3" id="campaigncollapse" mdbCollapse #campaigncollapse="mdbCollapse">
      <div class="form-check">
        <input mdbRadio class="form-check-input" (click)="toggleCampaign()" type="radio" name="flexRadioCampaign"
          id="inany" />
        <label class="form-check-label" for="inany">In any</label>
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" (click)="toggleCampaign()" name="flexRadioCampaign"
          id="notinany" />
        <label class="form-check-label" for="notinany">Not in any</label>
      </div>

      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioCampaign" id="in"
          (click)="toggleCampaign(campainIn)" aria-controls="campainIn" />
        <label class="form-check-label" for="in">In</label>

        <ng-select [(ngModel)]="ownerOptions" id="campainIn" mdbCollapse #campainIn="mdbCollapse">
          <ng-option *ngFor="let o of owners" [value]="o.id">{{o.name}}</ng-option>
        </ng-select>
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioCampaign" id="notin"
          (click)="toggleCampaign(campainNotIn)" aria-controls="campainNotIn" />
        <label class="form-check-label" for="notin">Not in</label>

        <ng-select [(ngModel)]="ownerOptions" id="campainNotIn" mdbCollapse #campainNotIn="mdbCollapse">
          <ng-option *ngFor="let o of owners" [value]="o.id">{{o.name}}</ng-option>
        </ng-select>
      </div>

    </div>
  </div> -->

  <!-- <div class="form-check mb-2">
    <input mdbRadio (click)="sidebarFilter(connectedcollapse,'connectedcollapse')" aria-controls="connectedcollapse"
      aria-expanded="false" class="form-check-input" type="checkbox" name="flexRadioDefault4" id="flexRadioDefault4"
      [checked]="false" />
    <label class="form-check-label" for="flexRadioDefault4">Connected</label>
    <div class="mt-3" id="connectedcollapse" mdbCollapse #connectedcollapse="mdbCollapse">
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioConnected" id="connectedyes" />
        <label class="form-check-label" for="connectedyes">Yes</label>
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioConnected" id="connectedno" />
        <label class="form-check-label" for="connectedno">No</label>
      </div>
    </div>
  </div> -->
  <!-- <div class="form-check mb-2">
    <input mdbRadio (click)="sidebarFilter(importedfromcollapse,'importedfromcollapse')"
      aria-controls="importedfromcollapse" aria-expanded="false" class="form-check-input" type="checkbox"
      name="flexRadioDefault5" id="flexRadioDefault5" [checked]="false" />
    <label class="form-check-label" for="flexRadioDefault5"> Imported From</label>
    <ng-select [(ngModel)]="ownerOptions" id="importedfromcollapse" mdbCollapse #importedfromcollapse="mdbCollapse">
      <ng-option *ngFor="let o of owners" [value]="o.id">{{o.name}}</ng-option>
    </ng-select>
  </div> -->
  <div class="form-check mb-2">
    <input mdbRadio (click)="sidebarFilter(optedout,'optedout',$event)" aria-controls="optedoutcollapse"
      aria-expanded="false" class="form-check-input" type="checkbox" name="flexRadioDefault8" id="flexRadioDefault8"
      [checked]="false" />
    <label class="form-check-label" for="flexRadioDefault8">Opted Out</label>
    <div class="mt-2" id="optedout" mdbCollapse #optedout="mdbCollapse">
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" (click)="onoptedoutSelected('yes',$event)" [checked]="optOutState('yes')"
          name="flexRadioOptedOut" id="optedoutyes" />
        <label class="form-check-label" for="optedoutyes">Yes</label>
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" (click)="onoptedoutSelected('no',$event)" [checked]="optOutState('no')"
          name="flexRadioOptedOut" id="optedoutdno" />
        <label class="form-check-label" for="optedoutdno">No</label>
      </div>
    </div>
  </div>



  <div class="form-check mb-2">
    <input mdbRadio (click)="sidebarFilter(bounced,'bounced',$event)" aria-controls="bouncedcollapse"
      aria-expanded="false" class="form-check-input" type="checkbox" name="flexRadioDefault80" id="flexRadioDefault80"
      [checked]="false" />
    <label class="form-check-label" for="flexRadioDefault80">Bounced</label>
    <div class="mt-1" id="bounced" mdbCollapse #bounced="mdbCollapse">
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" (click)="onbouncedSelected('yes',$event)" [checked]="bouncedState('yes')"
          name="flexRadiobounced" id="bounceddyes" />
        <label class="form-check-label" for="bounceddyes">Yes</label>
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" (click)="onbouncedSelected('no',$event)" [checked]="bouncedState('no')"
          name="flexRadiobounced" id="bounceddno" />
        <label class="form-check-label" for="bounceddno">No</label>
      </div>
    </div>
  </div>

  <!-- <div class="form-check mb-2">
    <input mdbRadio (click)="sidebarFilter(repliedcollapse,'repliedcollapse')" aria-controls="repliedcollapse"
      aria-expanded="false" class="form-check-input" type="checkbox" name="flexRadioDefault8" id="flexRadioDefault8"
      [checked]="false" />
    <label class="form-check-label" for="flexRadioDefault8">Replied</label>
    <div class="mt-3" id="repliedcollapse" mdbCollapse #repliedcollapse="mdbCollapse">
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioReplied" id="repliedyes" />
        <label class="form-check-label" for="repliedyes">Yes</label>
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioReplied" id="repliedno" />
        <label class="form-check-label" for="repliedno">No</label>
      </div>
    </div>
  </div> -->

  <!-- <div class="form-check mb-2">
    <input mdbRadio (click)="sidebarFilter(openedcollapse,'openedcollapse')" aria-controls="openedcollapse"
      aria-expanded="false" class="form-check-input" type="checkbox" name="flexRadioDefault8" id="flexRadioDefault8"
      [checked]="false" />
    <label class="form-check-label" for="flexRadioDefault8">Opened</label>
    <div class="mt-3" id="openedcollapse" mdbCollapse #openedcollapse="mdbCollapse">
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioOpened" id="openedyes" />
        <label class="form-check-label" for="openedyes">Yes</label>
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioOpened" id="openedno" />
        <label class="form-check-label" for="openedno">No</label>
      </div>
    </div>
  </div> -->


  <div class="form-check mb-2">
    <input #myProspectGroup mdbRadio (click)="sidebarFilter(propectGroup,'propectGroup',$event)"
      aria-controls="propectGroup" aria-expanded="false" class="form-check-input" [(ngModel)]="prospectgroupCheckBox"
      type="checkbox" name="flexRadioDefault5" id="flexRadioDefault5" />
    <label class="form-check-label" for="flexRadioDefault5">Prospect Group</label>
    <div class="mt-1">
      <!-- <ng-select [(ngModel)]="prospectgroupId" id="propectGroup" (change)='onProspectGroupSelected()' mdbCollapse #propectGroup="mdbCollapse">
        <ng-option *ngFor="let pg of prospectGrouplist" [value]="pg.name">{{pg.name}}</ng-option>
      </ng-select> -->
      <input id="propectGroup" mdbCollapse #propectGroup="mdbCollapse" [(ngModel)]="prospectgroupId"   
        (typeaheadOnSelect)="onProspectSelect($event)" (typeaheadLoading)="changeTypeaheadProspectLoading($event)"
        [typeaheadMinLength]="0" [typeahead]="prospectGrouplist" typeaheadOptionField="name" typeahead-wait-ms="10"
        class="form-control" name="Prospects Group" [adaptivePosition]="false" type="text"  (focus)="onFocusProspectGroup()" placeholder="Select a prospect group" autocomplete="off" />
      <span class="highlight"></span>
    </div>


  </div>

  <!-- <div class="form-check mb-2">
    <input mdbRadio (click)="sidebarFilter(limitName,'limitName')" aria-controls="limitName" aria-expanded="false"
      class="form-check-input" type="checkbox" name="flexRadioDefault10" id="flexRadioDefault10" [checked]="false" />
    <label class="form-check-label" for="flexRadioDefault10"> Limit</label>
    <mdb-form-control id="limitName" mdbCollapse #limitName="mdbCollapse">
      <input mdbInput type="text" id="form1" class="form-control" />
      <label mdbLabel class="form-label" for="form1">Limit</label>
    </mdb-form-control>
  </div> -->

  <div class="form-check mb-2">
    <input mdbRadio (click)="sidebarFilter(msgSent,'msgSent',$event)" aria-controls="msgSent" aria-expanded="false"
      class="form-check-input" type="checkbox" name="flexRadioDefault10" id="flexRadioDefault10" [checked]="false" />
    <label class="form-check-label" for="flexRadioDefault10"> Messages Sent</label>
    <div class="mt-1" id="msgSent" mdbCollapse #msgSent="mdbCollapse">
      <div class="form-check">
        <input mdbRadio (click)="toggleMessageSentFilter(msgSentinput,'msgSentMoreThan',$event)"
          class="form-check-input" type="radio" name="flexRadioMsgSent" id="msgSentMoreThan" />
        <label class="form-check-label" for="msgSentMoreThan">More Than</label>
      </div>
      <div class="form-check">
        <input mdbRadio (click)="toggleMessageSentFilter(msgSentinput,'msgSentLessThan',$event)"
          class="form-check-input" type="radio" name="flexRadioMsgSent" id="msgSentLessThan" />
        <label class="form-check-label" for="msgSentLessThan">Less Than</label>
      </div>
      <div class="form-check">
        <input mdbRadio (click)="toggleMessageSentFilter(msgSentinput,'msgSentEqualTo',$event)"
          aria-controls="msgSentinput" class="form-check-input" type="radio" name="flexRadioMsgSent"
          id="msgSentEqualTo" />
        <label class="form-check-label" for="msgSentEqualTo">Equal To</label>
      </div>
      <mdb-form-control id="msgSentinput" mdbCollapse #msgSentinput="mdbCollapse">
        <input mdbInput type="number" id="msgSentinputform1" (input)="handleUserInput('msgSent')"
          [(ngModel)]="sentCount" value="{{sentCount}}" class="form-control mt-1" />
        <label mdbLabel class="form-label" for="msgSentinputform1"></label>
      </mdb-form-control>
    </div>
  </div>

  <div class="form-check mb-2">
    <input mdbRadio (click)="sidebarFilter(msgClicks,'msgClicks',$event)" aria-controls="msgClicks"
      aria-expanded="false" class="form-check-input" type="checkbox" name="flexRadioDefault11" id="flexRadioDefault11"
      [checked]="false" />
    <label class="form-check-label" for="flexRadioDefault11"> Messages Opened</label>
    <div class="mt-1" id="msgClicks" mdbCollapse #msgClicks="mdbCollapse">
      <div class="form-check">
        <input mdbRadio (click)="toggleMessageClickFilter(msgClicksinput,'msgClicksMoreThan',$event)"
          class="form-check-input" type="radio" name="flexRadioMessageClick" id="msgClicksMoreThan" />
        <label class="form-check-label" for="msgClicksMoreThan">More Than</label>
      </div>
      <div class="form-check">
        <input mdbRadio (click)="toggleMessageClickFilter(msgClicksinput,'msgClicksLessThan',$event)"
          class="form-check-input" type="radio" name="flexRadioMessageClick" id="msgClicksLessThan" />
        <label class="form-check-label" for="msgClicksLessThan">Less Than</label>
      </div>
      <div class="form-check">
        <input mdbRadio (click)="toggleMessageClickFilter(msgClicksinput,'msgClicksEqualTo',$event)"
          aria-controls="msgClicksinput" class="form-check-input" type="radio" name="flexRadioMessageClick"
          id="msgClicksEqualTo" />
        <label class="form-check-label" for="msgClicksEqualTo">Equal To</label>
      </div>
      <mdb-form-control id="msgClicksinput" mdbCollapse #msgClicksinput="mdbCollapse">
        <input mdbInput type="number" id="msgClicksinputform1" (input)="handleUserInput('msgClicks')"
          [(ngModel)]="clickCount" value="{{clickCount}}" class="form-control mt-1" />
        <label mdbLabel class="form-label" for="msgClicksinputform1"></label>
      </mdb-form-control>
    </div>
  </div>


  <div class="form-check mb-2">
    <input mdbRadio (click)="sidebarFilter(msgReplies,'msgReplies',$event)" aria-controls="msgReplies"
      aria-expanded="false" class="form-check-input" type="checkbox" name="flexRadioDefault12" id="flexRadioDefault12"
      [checked]="false" />
    <label class="form-check-label" for="flexRadioDefault12"> Messages Replied</label>
    <div class="mt-1" id="msgReplies" mdbCollapse #msgReplies="mdbCollapse">
      <div class="form-check">
        <input mdbRadio (click)="toggleMessageReplyFilter(msgRepliesinput, 'yes', $event)" [checked]="msgReplyState('yes')"
          class="form-check-input" type="radio" name="flexRadioMessageReplies" id="msgRepliesYes" />
        <label class="form-check-label" for="msgRepliesYes">Yes</label>
      </div>
      <div class="form-check">
        <input mdbRadio (click)="toggleMessageReplyFilter(msgRepliesinput, 'no',$event)"  [checked]="msgReplyState('no')"
          class="form-check-input" type="radio" name="flexRadioMessageReplies" id="msgRepliesNo" />
        <label class="form-check-label" for="msgRepliesNo">No</label>
      </div>

      <!-- <div class="form-check">
        <input mdbRadio (click)="toggleMessageReplyFilter(msgRepliesinput,'msgRepliesMoreThan',$event)"
          class="form-check-input" type="radio" name="flexRadioMessageReplies" id="msgRepliesMoreThan" />
        <label class="form-check-label" for="msgRepliesMoreThan">More Than</label>
      </div>
      <div class="form-check">
        <input mdbRadio (click)="toggleMessageReplyFilter(msgRepliesinput,'msgRepliesLessThan',$event)"
          class="form-check-input" type="radio" name="flexRadioMessageReplies" id="msgRepliesLessThan" />
        <label class="form-check-label" for="msgRepliesLessThan">Less Than</label>
      </div>
      <div class="form-check">
        <input mdbRadio (click)="toggleMessageReplyFilter(msgRepliesinput,'msgRepliesEqualTo',$event)"
          aria-controls="msgRepliessinput" class="form-check-input" type="radio" name="flexRadioMessageReplies"
          id="msgRepliesEqualTo" />
        <label class="form-check-label" for="msgRepliesEqualTo">Equal To</label>
      </div> -->
      <div class="mt-2" style="display: none;" >
        <mdb-form-control id="msgRepliesinput" mdbCollapse #msgRepliesinput="mdbCollapse">
          <input mdbInput type="number" id="msgRepliesinputform1" (input)="handleUserInput('msgReplies')"
            [(ngModel)]="repliesCount" value="{{repliesCount}}" class="form-control" />
          <label mdbLabel class="form-label" for="msgRepliesinputform1"></label>
        </mdb-form-control>
      </div>
    </div>
  </div>





  <div class="form-check mb-2">
    <input mdbRadio (click)="sidebarFilter(autoreply,'autoreply',$event)" aria-controls="optedoutcollapse"
      aria-expanded="false" class="form-check-input" type="checkbox" name="flexRadioDefault32" id="flexRadioDefault32"
      [checked]="false" />
    <label class="form-check-label" for="flexRadioDefault32">Auto Reply</label>
    <div class="mt-2" id="autoreply" mdbCollapse #autoreply="mdbCollapse">
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" (click)="autoReplySelected('yes',$event)" [checked]="autoReplyState('yes')"
          name="flexRadioAutoReply" id="autoreplyyes" />
        <label class="form-check-label" for="autoreplyyes">Yes</label>
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" (click)="autoReplySelected('no',$event)"  [checked]="autoReplyState('no')"
          name="flexRadioAutoReply" id="autoreplyno" />
        <label class="form-check-label" for="autoreplyno">No</label>
      </div>
    </div>
  </div>


  <div class="form-check mb-2">
    <input mdbRadio (click)="sidebarFilter(prospectInterest,'prospectInterest',$event)" aria-controls="prospectInterestcollapse"
      aria-expanded="false" class="form-check-input" type="checkbox" name="flexRadioDefault31" id="flexRadioDefault31"
      [checked]="false" />
    <label class="form-check-label" for="flexRadioDefault31">Prospect Interest</label>
    <div class="mt-2" id="prospectInterest" mdbCollapse #prospectInterest="mdbCollapse">
      <div class="form-check" *ngFor="let item of prospectInterests">
        <input mdbRadio class="form-check-input" type="radio" (click)="onProspectInterestSelected(item.id,$event)" [checked]="prospectFilterModel.interested_category == item.id"
          name="flexRadioProspectInterest" id="prospectInterest_{{item.id}}" />
        <label class="form-check-label" for="prospectInterest_{{item.id}}">{{item.name}}</label>
      </div>

    </div>
  </div>





  <div class="form-check mb-2">
    <input mdbRadio (click)="sidebarFilter(lastContacted,'lastContacted',$event)" aria-controls="lastContacted"
      aria-expanded="false" class="form-check-input" type="checkbox" name="lastContactedRadio1" id="lastContactedRadio1"
      [checked]="false" />
    <label class="form-check-label" for="lastContactedRadio1"> Last Contacted Days</label>
    <div class="mt-1" id="lastContacted" mdbCollapse #lastContacted="mdbCollapse">
      <div class="form-check">
        <input mdbRadio (click)="toggleLastContactedFilter(lastContactedinput,'lastContactedMoreThan',$event)"
          class="form-check-input" type="radio" name="flexRadiolastContacted" id="lastContactedMoreThan" />
        <label class="form-check-label" for="lastContactedMoreThan">More Than</label>
      </div>
      <div class="form-check">
        <input mdbRadio (click)="toggleLastContactedFilter(lastContactedinput,'lastContactedLessThan',$event)"
          class="form-check-input" type="radio" name="flexRadiolastContacted" id="lastContactedLessThan" />
        <label class="form-check-label" for="lastContactedLessThan">Less Than</label>
      </div>
      <div class="form-check">
        <input mdbRadio (click)="toggleLastContactedFilter(lastContactedinput,'lastContactedEqualTo',$event)"
          aria-controls="lastContactedinput" class="form-check-input" type="radio" name="flexRadiolastContacted"
          id="lastContactedEqualTo" />
        <label class="form-check-label" for="lastContactedEqualTo">Equal To</label>
      </div>
      <mdb-form-control id="lastContactedinput" mdbCollapse #lastContactedinput="mdbCollapse">
        <input mdbInput type="number" id="lastContactedinputform1" (input)="handleUserInput('lastContacted')"
          [(ngModel)]="lastContactedCount" value="{{lastContactedCount}}" class="form-control mt-1" />
        <label mdbLabel class="form-label" for="lastContactedinputform1"></label>
      </mdb-form-control>
    </div>
  </div>


  <div class="form-check mb-2">
    <input mdbRadio (click)="sidebarFilter(null,'showDeleted', $event)" aria-controls="deleted"
      aria-expanded="false" class="form-check-input" type="checkbox" name="flexRadioDefault30" id="flexRadioDefault30"
      [checked]="false" />
    <label class="form-check-label" for="flexRadioDefault30"> Show Deleted</label>

  </div>


  <div class="form-check mb-2">
    <input mdbRadio (click)="sidebarFilter(scores,'scores',$event)" aria-controls="scores"
      aria-expanded="false" class="form-check-input" type="checkbox" name="flexRadioDefault29" id="flexRadioDefault29"
      [checked]="false" />
    <label class="form-check-label" for="flexRadioDefault29"> Score</label>
    <div class="mt-1" id="scores" mdbCollapse #scores="mdbCollapse">
      <div class="form-check">
        <input mdbRadio (click)="toggleScoreFilter(scoresinput,'scoresMoreThan',$event)"
          class="form-check-input" type="radio" name="flexRadioScore" id="scoresMoreThan" />
        <label class="form-check-label" for="scoresMoreThan">More Than</label>
      </div>
      <div class="form-check">
        <input mdbRadio (click)="toggleScoreFilter(scoresinput,'scoresLessThan',$event)"
          class="form-check-input" type="radio" name="flexRadioScore" id="scoresLessThan" />
        <label class="form-check-label" for="scoresLessThan">Less Than</label>
      </div>
      <!-- <div class="form-check">
        <input mdbRadio (click)="toggleScoreFilter(scoresinput,'scoresEqualTo',$event)"
          aria-controls="scoressinput" class="form-check-input" type="radio" name="flexRadioMessageReplies"
          id="scoresEqualTo" />
        <label class="form-check-label" for="scoresEqualTo">Equal To</label>
      </div> -->
      <div class="mt-2">
        <mdb-form-control id="scoresinput" mdbCollapse #scoresinput="mdbCollapse">
          <input mdbInput type="number" id="scoresinputform1" (input)="handleUserInput('scores')"
            [(ngModel)]="scoreCount" value="{{scoreCount}}" class="form-control" />
          <label mdbLabel class="form-label" for="scoresinputform1"></label>
        </mdb-form-control>
      </div>
    </div>
  </div>
  <!-- <div class="form-check mb-2">
    <input mdbRadio (click)="sidebarFilter(callCompleted,'callCompleted')" aria-controls="callCompleted"
      aria-expanded="false" class="form-check-input" type="checkbox" name="flexRadioDefault10" id="flexRadioDefault10"
      [checked]="false" />
    <label class="form-check-label" for="flexRadioDefault10"> Calls Completed</label>
    <div class="mt-3" id="callCompleted" mdbCollapse #callCompleted="mdbCollapse">
      <div class="form-check">
        <input mdbRadio (click)="toggleCallCompleteFilter(callCompletedinput)" class="form-check-input" type="radio"
          name="flexRadioCallCompleted" id="callCompletedMoreThan" />
        <label class="form-check-label" for="callCompletedMoreThan">More Than</label>
      </div>
      <div class="form-check">
        <input mdbRadio (click)="toggleCallCompleteFilter(callCompletedinput)" class="form-check-input" type="radio"
          name="flexRadioCallCompleted" id="callCompletedLessThan" />
        <label class="form-check-label" for="callCompletedLessThan">Less Than</label>
      </div>
      <div class="form-check">
        <input mdbRadio (click)="toggleCallCompleteFilter(callCompletedinput)" aria-controls="callCompletedsinput"
          class="form-check-input" type="radio" name="flexRadioCallCompleted" id="callCompletedEqualTo" />
        <label class="form-check-label" for="callCompletedEqualTo">Equal To</label>
      </div>
      <mdb-form-control id="callCompletedinput" mdbCollapse #callCompletedinput="mdbCollapse">
        <input mdbInput type="number" id="callCompletedinputform1" class="form-control" />
        <label mdbLabel class="form-label" for="callCompletedinputform1"></label>
      </mdb-form-control>
    </div>
  </div> -->

  <!-- <div class="form-check mb-2">
    <input mdbRadio (click)="sidebarFilter(lastConnected,'lastConnected')" aria-controls="lastConnected"
      aria-expanded="false" class="form-check-input" type="checkbox" name="flexRadioDefault10" id="flexRadioDefault10"
      [checked]="false" />
    <label class="form-check-label" for="flexRadioDefault10"> Last Connected</label>
    <div class="mt-3" id="lastConnected" mdbCollapse #lastConnected="mdbCollapse">
      <div class="form-check">
        <input mdbRadio (click)="toggleLastConnectedFilter(lastConnectedinput)" class="form-check-input" type="radio"
          name="flexRadioLastConnected" id="lastConnectedMoreThan" />
        <label class="form-check-label" for="lastConnectedMoreThan">More Than</label>
      </div>
      <div class="form-check">
        <input mdbRadio (click)="toggleLastConnectedFilter(lastConnectedinput)" class="form-check-input" type="radio"
          name="flexRadioLastConnected" id="lastConnectedLessThan" />
        <label class="form-check-label" for="lastConnectedLessThan">Less Than</label>
      </div>
      <mdb-form-control id="lastConnectedinput" mdbCollapse #lastConnectedinput="mdbCollapse">
        <input mdbInput type="text" id="lastConnectedinputform1" class="form-control" />
        <label mdbLabel class="form-label" for="lastConnectedinputform1">Days</label>

      </mdb-form-control>
    </div>
  </div> -->
  <div class="form-check mb-2">
    <input #myCampaign mdbRadio (click)="sidebarFilter(campaign,'campaign',$event)" aria-controls="campaign"
      aria-expanded="false" class="form-check-input" type="checkbox" name="flexRadioDefault13" id="flexRadioDefault13"
      [checked]="false" />
    <label class="form-check-label" for="flexRadioDefault13"> Campaign</label>
    <div class="mt-1" id="campaign" mdbCollapse #campaign="mdbCollapse">
      <div class="form-check">
        <input mdbRadio (click)="togglecampaignFilter(campaigninput,'campaignMoreThan',$event)" class="form-check-input"
          type="radio" name="flexRadiocampaign" id="campaignMoreThan" />
        <label class="form-check-label" for="campaignMoreThan">In</label>
      </div>
      <div class="form-check">
        <input #myCampaignNotIn mdbRadio (click)="togglecampaignFilter(campaigninput,'campaignEqual',$event)"
          class="form-check-input" type="radio" name="flexRadiocampaign" id="campaignEqual" />
        <label class="form-check-label" for="campaignEqual">Not In</label>
      </div>
      <div class="mt-2">
        <!-- <mdb-form-control id="campaigninput" mdbCollapse #campaigninput="mdbCollapse">
        <input mdbInput type="text" id="campaigninputform1" (input)="handleUserInput('campaign')"
        [(ngModel)]="campaignName" value="{{campaignName}}" class="form-control" />
      </mdb-form-control> -->
        <input  #campaignTypeSidebar id="campaigninput" mdbCollapse #campaigninput="mdbCollapse" [(ngModel)]="selectedCampaign"
          (typeaheadOnSelect)="onSelect($event)" (typeaheadLoading)="changeTypeaheadLoading($event)"  (paste)="onPaste($event)"
          [typeaheadMinLength]="0" [typeahead]="campaignlist" typeaheadOptionField="name" typeahead-wait-ms="100"
          class="form-control" name="Campaigns" type="text"  [adaptivePosition]="false" placeholder="Select a campaign" autocomplete="off" />
        <span class="highlight"></span>

      </div>
    </div>
  </div>

  <div class="form-check mb-2">
    <input mdbRadio (click)="sidebarFilter(email,'email',$event)" aria-controls="email" aria-expanded="false"
      class="form-check-input" type="checkbox" name="flexRadioDefault3" id="flexRadioDefault3" [checked]="false" />
    <label class="form-check-label" for="flexRadioDefault3"> Email</label>
    <div class="mt-1" id="email" mdbCollapse #email="mdbCollapse">
      <!-- <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioEmail" id="emailContains"
          (click)="toggleEmailFilter(emailContains,'emailContains')" aria-controls="emailContains" />
        <label class="form-check-label" for="emailContains">Contain</label>
        <input mdbInput type="text" id="emailContainsform1" id="emailContains" mdbCollapse #emailContains="mdbCollapse"
          class="form-control" [(ngModel)]="emailContainsText" value="{{emailContainsText}}" (input)="handleUserInput('emailContains')"  />
      </div> -->
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioEmail" id="emailStartsWith"
          (click)="toggleEmailFilter(emailStartsWith,'emailStartsWith',$event)" aria-controls="emailStartsWith" />
        <label class="form-check-label" for="emailStartsWith">Starts With</label>
        <input mdbInput type="text" id="emailStartsWithform1" id="emailStartsWith" mdbCollapse
          #emailStartsWith="mdbCollapse" class="form-control" [(ngModel)]="emailStartsWithText"
          value="{{emailStartsWithText}}" (input)="handleUserInput('emailStartsWith')" />
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioEmail" id="emailEndsWith"
          (click)="toggleEmailFilter(emailEndsWith,'emailEndsWith',$event)" aria-controls="emailEndsWith" />
        <label class="form-check-label" for="emailEndsWith">Ends With</label>
        <input mdbInput type="text" id="emailEndsWithform1" id="emailEndsWith" mdbCollapse #emailEndsWith="mdbCollapse"
          class="form-control" [(ngModel)]="emailEndsWithText" value="{{emailEndsWithText}}"
          (input)="handleUserInput('emailEndsWith')" />
      </div>
      <!-- <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioEmail" id="emailIs"
          (click)="toggleEmailFilter(emailIs,'emailIs')" aria-controls="emailIs" />
        <label class="form-check-label" for="emailIs">Is</label>
        <input mdbInput type="text" id="emailIsform1" id="emailIs" mdbCollapse #emailIs="mdbCollapse"
        class="form-control" [(ngModel)]="emailIsText" value="{{emailIsText}}" (input)="handleUserInput('emailIs')"/>
      </div> -->
    </div>
  </div>

  <div class="form-check mb-2">
    <input mdbRadio (click)="sidebarFilter(firstname,'firstname',$event)" aria-controls="firstname"
      aria-expanded="false" class="form-check-input" type="checkbox" name="flexRadioDefault33" id="flexRadioDefault33"
      [checked]="false" />
    <label class="form-check-label" for="flexRadioDefault33"> First Name</label>
    <div class="mt-1" id="firstname" mdbCollapse #firstname="mdbCollapse">
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioFirstName" id="firstnameContains"
          (click)="togglefirstnameFilter(firstnameContains,'firstnameContains',$event)"
          aria-controls="firstnameContains" />
        <label class="form-check-label" for="firstnameContains">Contains</label>
        <input mdbInput type="text" id="firstnameContainsform1" id="firstnameContains" mdbCollapse
          #firstnameContains="mdbCollapse" class="form-control" [(ngModel)]="firstnameContainsText"
          value="{{firstnameContainsText}}" (input)="handleUserInput('firstnameContains')" />
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioFirstName" id="firstnameStartsWith"
          (click)="togglefirstnameFilter(firstnameStartsWith,'firstnameStartsWith',$event)"
          aria-controls="firstnameStartsWith" />
        <label class="form-check-label" for="firstnameStartsWith">Starts With</label>
        <input mdbInput type="text" id="firstnameStartsWithform1" id="firstnameStartsWith" mdbCollapse
          #firstnameStartsWith="mdbCollapse" class="form-control" [(ngModel)]="firstnameStartsWithText"
          value="{{firstnameStartsWithText}}" (input)="handleUserInput('firstnameStartsWith')" />
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioFirstName" id="firstnameEndsWith"
          (click)="togglefirstnameFilter(firstnameEndsWith,'firstnameEndsWith',$event)"
          aria-controls="firstnameEndsWith" />
        <label class="form-check-label" for="firstnameEndsWith">Ends With</label>
        <input mdbInput type="text" id="firstnameEndsWithform1" id="firstnameEndsWith" mdbCollapse
          #firstnameEndsWith="mdbCollapse" class="form-control" [(ngModel)]="firstnameEndsWithText"
          value="{{firstnameEndsWithText}}" (input)="handleUserInput('firstnameEndsWith')" />
      </div>
    </div>
  </div>

  <div class="form-check mb-2">
    <input mdbRadio (click)="sidebarFilter(lastname,'lastname',$event)" aria-controls="lastname" aria-expanded="false"
      class="form-check-input" type="checkbox" name="flexRadioDefault14" id="flexRadioDefault14" [checked]="false" />
    <label class="form-check-label" for="flexRadioDefault14">Last Name</label>
    <div class="mt-1" id="lastname" mdbCollapse #lastname="mdbCollapse">
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioLastName" id="lastnameContains"
          (click)="togglelastnameFilter(lastnameContains,'lastnameContains',$event)" aria-controls="lastnameContains" />
        <label class="form-check-label" for="lastnameContains">Contains</label>
        <input mdbInput type="text" id="lastnameContainsform1" id="lastnameContains" mdbCollapse
          #lastnameContains="mdbCollapse" class="form-control" [(ngModel)]="lastnameContainsText"
          value="{{lastnameContainsText}}" (input)="handleUserInput('lastnameContains')" />
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioLastName" id="lastnameStartsWith"
          (click)="togglelastnameFilter(lastnameStartsWith,'lastnameStartsWith',$event)"
          aria-controls="lastnameStartsWith" />
        <label class="form-check-label" for="lastnameStartsWith">Starts With</label>
        <input mdbInput type="text" id="lastnameStartsWithform1" id="lastnameStartsWith" mdbCollapse
          #lastnameStartsWith="mdbCollapse" class="form-control" [(ngModel)]="lastnameStartsWithText"
          value="{{lastnameStartsWithText}}" (input)="handleUserInput('lastnameStartsWith')" />
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioLastName" id="lastnameEndsWith"
          (click)="togglelastnameFilter(lastnameEndsWith,'lastnameEndsWith',$event)" aria-controls="lastnameEndsWith" />
        <label class="form-check-label" for="lastnameEndsWith">Ends With</label>
        <input mdbInput type="text" id="lastnameEndsWithform1" id="lastnameEndsWith" mdbCollapse
          #lastnameEndsWith="mdbCollapse" class="form-control" [(ngModel)]="lastnameEndsWithText"
          value="{{lastnameEndsWithText}}" (input)="handleUserInput('lastnameEndsWith')" />
      </div>
    </div>
  </div>

  <div class="form-check mb-2">
    <input mdbRadio (click)="sidebarFilter(company,'company',$event)" aria-controls="company" aria-expanded="false"
      class="form-check-input" type="checkbox" name="flexRadioDefault15" id="flexRadioDefault15" [checked]="false" />
    <label class="form-check-label" for="flexRadioDefault15"> Company</label>
    <div class="mt-1" id="company" mdbCollapse #company="mdbCollapse">
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioCompany" id="companyContains"
          (click)="togglecompanyFilter(companyContains,'companyContains',$event)" aria-controls="companyContains" />
        <label class="form-check-label" for="companyContains">Contains</label>
        <input mdbInput type="text" id="companyContainsform1" id="companyContains" mdbCollapse
          #companyContains="mdbCollapse" class="form-control" [(ngModel)]="companyContainsText"
          value="{{companyContainsText}}" (input)="handleUserInput('companyContains')" />
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioCompany" id="companyStartsWith"
          (click)="togglecompanyFilter(companyStartsWith,'companyStartsWith',$event)"
          aria-controls="companyStartsWith" />
        <label class="form-check-label" for="companyStartsWith">Starts With</label>
        <input mdbInput type="text" id="companyStartsWithform1" id="companyStartsWith" mdbCollapse
          #companyStartsWith="mdbCollapse" class="form-control" [(ngModel)]="companyStartsWithText"
          value="{{companyStartsWithText}}" (input)="handleUserInput('companyStartsWith')" />
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioCompany" id="companyEndsWith"
          (click)="togglecompanyFilter(companyEndsWith,'companyEndsWith',$event)" aria-controls="companyEndsWith" />
        <label class="form-check-label" for="companyEndsWith">Ends With</label>
        <input mdbInput type="text" id="companyEndsWithform1" id="companyEndsWith" mdbCollapse
          #companyEndsWith="mdbCollapse" class="form-control" [(ngModel)]="companyEndsWithText"
          value="{{companyEndsWithText}}" (input)="handleUserInput('companyEndsWith')" />
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" (click)="togglecompanyFilter(null,'companyEmpty',$event)"
          name="flexRadioCompany" id="companyEmpty" />
        <label class="form-check-label" for="companyEmpty">Empty</label>
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio"
          (click)="togglecompanyFilter(null,'companyNotEmpty',$event)" name="flexRadioCompany" id="companyNotEmpty" />
        <label class="form-check-label" for="companyNotEmpty">Not Empty</label>
      </div>
    </div>
  </div>

  <div class="form-check mb-2">
    <input mdbRadio (click)="sidebarFilter(industry,'industry',$event)" aria-controls="industry" aria-expanded="false"
      class="form-check-input" type="checkbox" name="flexRadioDefault16" id="flexRadioDefault16" [checked]="false" />
    <label class="form-check-label" for="flexRadioDefault16"> Industry</label>
    <div class="mt-1" id="industry" mdbCollapse #industry="mdbCollapse">
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioIndustry" id="industryContains"
          (click)="toggleindustryFilter(industryContains,'industryContains',$event)" aria-controls="industryContains" />
        <label class="form-check-label" for="industryContains">Contains</label>
        <input mdbInput type="text" id="industryContainsform1" id="industryContains" mdbCollapse
          #industryContains="mdbCollapse" class="form-control" [(ngModel)]="industryContainsText"
          value="{{industryContainsText}}" (input)="handleUserInput('industryContains')" />
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioIndustry" id="industryStartsWith"
          (click)="toggleindustryFilter(industryStartsWith,'industryStartsWith',$event)"
          aria-controls="industryStartsWith" />
        <label class="form-check-label" for="industryStartsWith">Starts With</label>
        <input mdbInput type="text" id="industryStartsWithform1" id="industryStartsWith" mdbCollapse
          #industryStartsWith="mdbCollapse" class="form-control" [(ngModel)]="industryStartsWithText"
          value="{{industryStartsWithText}}" (input)="handleUserInput('industryStartsWith')" />
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioIndustry" id="industryEndsWith"
          (click)="toggleindustryFilter(industryEndsWith,'industryEndsWith',$event)" aria-controls="industryEndsWith" />
        <label class="form-check-label" for="industryEndsWith">Ends With</label>
        <input mdbInput type="text" id="industryEndsWithform1" id="industryEndsWith" mdbCollapse
          #industryEndsWith="mdbCollapse" class="form-control" [(ngModel)]="industryEndsWithText"
          value="{{industryEndsWithText}}" (input)="handleUserInput('industryEndsWith')" />
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio"
          (click)="toggleindustryFilter(null,'industryEmpty',$event)" name="flexRadioIndustry" id="industryEmpty" />
        <label class="form-check-label" for="industryEmpty">Empty</label>
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio"
          (click)="toggleindustryFilter(null,'industryNotEmpty',$event)" name="flexRadioIndustry"
          id="industryNotEmpty" />
        <label class="form-check-label" for="industryNotEmpty">Not Empty</label>
      </div>
    </div>
  </div>

  <div class="form-check mb-2">
    <input mdbRadio (click)="sidebarFilter(phone,'phone',$event)" aria-controls="phone" aria-expanded="false"
      class="form-check-input" type="checkbox" name="flexRadioDefault17" id="flexRadioDefault17" [checked]="false" />
    <label class="form-check-label" for="flexRadioDefault17"> Phone</label>
    <div class="mt-1" id="phone" mdbCollapse #phone="mdbCollapse">
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioPhone" id="phoneContains"
          (click)="togglephoneFilter(phoneContains,'phoneContains',$event)" aria-controls="phoneContains" />
        <label class="form-check-label" for="phoneContains">Contains</label>
        <input mdbInput type="text" id="phoneContainsform1" id="phoneContains" mdbCollapse #phoneContains="mdbCollapse"
          class="form-control" [(ngModel)]="phoneContainsText" value="{{phoneContainsText}}"
          (input)="handleUserInput('phoneContains')" />
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioPhone" id="phoneStartsWith"
          (click)="togglephoneFilter(phoneStartsWith,'phoneStartsWith',$event)" aria-controls="phoneStartsWith" />
        <label class="form-check-label" for="phoneStartsWith">Starts With</label>
        <input mdbInput type="text" id="phoneStartsWithform1" id="phoneStartsWith" mdbCollapse
          #phoneStartsWith="mdbCollapse" class="form-control" [(ngModel)]="phoneStartsWithText"
          value="{{phoneStartsWithText}}" (input)="handleUserInput('phoneStartsWith')" />
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioPhone" id="phoneEndsWith"
          (click)="togglephoneFilter(phoneEndsWith,'phoneEndsWith',$event)" aria-controls="phoneEndsWith" />
        <label class="form-check-label" for="phoneEndsWith">Ends With</label>
        <input mdbInput type="text" id="phoneEndsWithform1" id="phoneEndsWith" mdbCollapse #phoneEndsWith="mdbCollapse"
          class="form-control" [(ngModel)]="phoneEndsWithText" value="{{phoneEndsWithText}}"
          (input)="handleUserInput('phoneEndsWith')" />
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" (click)="togglephoneFilter(null,'phoneEmpty',$event)"
          name="flexRadioPhone" id="phoneEmpty" />
        <label class="form-check-label" for="phoneEmpty">Empty</label>
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" (click)="togglephoneFilter(null,'phoneNotEmpty',$event)"
          name="flexRadioPhone" id="phoneNotEmpty" />
        <label class="form-check-label" for="phoneNotEmpty">Not Empty</label>
      </div>
    </div>
  </div>

  <div class="form-check mb-2">
    <input mdbRadio (click)="sidebarFilter(job_title,'job_title',$event)" aria-controls="job_title"
      aria-expanded="false" class="form-check-input" type="checkbox" name="flexRadioDefault18" id="flexRadioDefault18"
      [checked]="false" />
    <label class="form-check-label" for="flexRadioDefault18"> Job Title</label>
    <div class="mt-1" id="job_title" mdbCollapse #job_title="mdbCollapse">
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioJobTitle" id="job_titleContains"
          (click)="togglejob_titleFilter(job_titleContains,'job_titleContains',$event)"
          aria-controls="job_titleContains" />
        <label class="form-check-label" for="job_titleContains">Contains</label>
        <input mdbInput type="text" id="job_titleContainsform1" id="job_titleContains" mdbCollapse
          #job_titleContains="mdbCollapse" class="form-control" [(ngModel)]="job_titleContainsText"
          value="{{job_titleContainsText}}" (input)="handleUserInput('job_titleContains')" />
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioJobTitle" id="job_titleStartsWith"
          (click)="togglejob_titleFilter(job_titleStartsWith,'job_titleStartsWith',$event)"
          aria-controls="job_titleStartsWith" />
        <label class="form-check-label" for="job_titleStartsWith">Starts With</label>
        <input mdbInput type="text" id="job_titleStartsWithform1" id="job_titleStartsWith" mdbCollapse
          #job_titleStartsWith="mdbCollapse" class="form-control" [(ngModel)]="job_titleStartsWithText"
          value="{{job_titleStartsWithText}}" (input)="handleUserInput('job_titleStartsWith')" />
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioJobTitle" id="job_titleEndsWith"
          (click)="togglejob_titleFilter(job_titleEndsWith,'job_titleEndsWith',$event)"
          aria-controls="job_titleEndsWith" />
        <label class="form-check-label" for="job_titleEndsWith">Ends With</label>
        <input mdbInput type="text" id="job_titleEndsWithform1" id="job_titleEndsWith" mdbCollapse
          #job_titleEndsWith="mdbCollapse" class="form-control" [(ngModel)]="job_titleEndsWithText"
          value="{{job_titleEndsWithText}}" (input)="handleUserInput('job_titleEndsWith')" />
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio"
          (click)="togglejob_titleFilter(null,'job_titleEmpty',$event)" name="flexRadioJobTitle" id="job_titleEmpty" />
        <label class="form-check-label" for="job_titleEmpty">Empty</label>
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio"
          (click)="togglejob_titleFilter(null,'job_titleNotEmpty',$event)" name="flexRadioJobTitle"
          id="job_titleNotEmpty" />
        <label class="form-check-label" for="job_titleNotEmpty">Not Empty</label>
      </div>
    </div>
  </div>

  <div class="form-check mb-2">
    <input mdbRadio (click)="sidebarFilter(city,'city',$event)" aria-controls="city" aria-expanded="false"
      class="form-check-input" type="checkbox" name="flexRadioDefault19" id="flexRadioDefault19" [checked]="false" />
    <label class="form-check-label" for="flexRadioDefault19"> City</label>
    <div class="mt-1" id="city" mdbCollapse #city="mdbCollapse">
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioCity" id="cityContains"
          (click)="togglecityFilter(cityContains,'cityContains',$event)" aria-controls="cityContains" />
        <label class="form-check-label" for="cityContains">Contains</label>
        <input mdbInput type="text" id="cityContainsform1" id="cityContains" mdbCollapse #cityContains="mdbCollapse"
          class="form-control" [(ngModel)]="cityContainsText" value="{{cityContainsText}}"
          (input)="handleUserInput('cityContains')" />
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioCity" id="cityStartsWith"
          (click)="togglecityFilter(cityStartsWith,'cityStartsWith',$event)" aria-controls="cityStartsWith" />
        <label class="form-check-label" for="cityStartsWith">Starts With</label>
        <input mdbInput type="text" id="cityStartsWithform1" id="cityStartsWith" mdbCollapse
          #cityStartsWith="mdbCollapse" class="form-control" [(ngModel)]="cityStartsWithText"
          value="{{cityStartsWithText}}" (input)="handleUserInput('cityStartsWith')" />
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioCity" id="cityEndsWith"
          (click)="togglecityFilter(cityEndsWith,'cityEndsWith',$event)" aria-controls="cityEndsWith" />
        <label class="form-check-label" for="cityEndsWith">Ends With</label>
        <input mdbInput type="text" id="cityEndsWithform1" id="cityEndsWith" mdbCollapse #cityEndsWith="mdbCollapse"
          class="form-control" [(ngModel)]="cityEndsWithText" value="{{cityEndsWithText}}"
          (input)="handleUserInput('cityEndsWith')" />
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" (click)="togglecityFilter(null,'cityEmpty',$event)"
          name="flexRadioCity" id="cityEmpty" />
        <label class="form-check-label" for="cityEmpty">Empty</label>
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" (click)="togglecityFilter(null,'cityNotEmpty',$event)"
          name="flexRadioCity" id="cityNotEmpty" />
        <label class="form-check-label" for="cityNotEmpty">Not Empty</label>
      </div>
    </div>
  </div>

  <div class="form-check mb-2">
    <input mdbRadio (click)="sidebarFilter(state,'state',$event)" aria-controls="state" aria-expanded="false"
      class="form-check-input" type="checkbox" name="flexRadioDefault20" id="flexRadioDefault20" [checked]="false" />
    <label class="form-check-label" for="flexRadioDefault20"> State/Province</label>
    <div class="mt-1" id="state" mdbCollapse #state="mdbCollapse">
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioState" id="stateContains"
          (click)="togglestateFilter(stateContains,'stateContains',$event)" aria-controls="stateContains" />
        <label class="form-check-label" for="stateContains">Contains</label>
        <input mdbInput type="text" id="stateContainsform1" id="stateContains" mdbCollapse #stateContains="mdbCollapse"
          class="form-control" [(ngModel)]="stateContainsText" value="{{stateContainsText}}"
          (input)="handleUserInput('stateContains')" />
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioState" id="stateStartsWith"
          (click)="togglestateFilter(stateStartsWith,'stateStartsWith',$event)" aria-controls="stateStartsWith" />
        <label class="form-check-label" for="stateStartsWith">Starts With</label>
        <input mdbInput type="text" id="stateStartsWithform1" id="stateStartsWith" mdbCollapse
          #stateStartsWith="mdbCollapse" class="form-control" [(ngModel)]="stateStartsWithText"
          value="{{stateStartsWithText}}" (input)="handleUserInput('stateStartsWith')" />
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioState" id="stateEndsWith"
          (click)="togglestateFilter(stateEndsWith,'stateEndsWith',$event)" aria-controls="stateEndsWith" />
        <label class="form-check-label" for="stateEndsWith">Ends With</label>
        <input mdbInput type="text" id="stateEndsWithform1" id="stateEndsWith" mdbCollapse #stateEndsWith="mdbCollapse"
          class="form-control" [(ngModel)]="stateEndsWithText" value="{{stateEndsWithText}}"
          (input)="handleUserInput('stateEndsWith')" />
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" (click)="togglestateFilter(null,'stateEmpty',$event)"
          name="flexRadioState" id="stateEmpty" />
        <label class="form-check-label" for="stateEmpty">Empty</label>
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" (click)="togglestateFilter(null,'stateNotEmpty',$event)"
          name="flexRadioState" id="stateNotEmpty" />
        <label class="form-check-label" for="stateNotEmpty">Not Empty</label>
      </div>
    </div>
  </div>
  <!-- <div class="form-check mb-2">
    <input mdbRadio (click)="sidebarFilter(region,'region',$event)" aria-controls="region" aria-expanded="false"
      class="form-check-input" type="checkbox" name="flexRadioDefault21" id="flexRadioDefault21" [checked]="false" />
    <label class="form-check-label" for="flexRadioDefault21"> Region</label>
    <div class="mt-1" id="region" mdbCollapse #region="mdbCollapse">
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioRegion" id="regionContains"
          (click)="toggleregionFilter(regionContains,'regionContains',$event)" aria-controls="regionContains" />
        <label class="form-check-label" for="regionContains">Contains</label>
        <input mdbInput type="text" id="regionContainsform1" id="regionContains" mdbCollapse
          #regionContains="mdbCollapse" class="form-control" [(ngModel)]="regionContainsText"
          value="{{regionContainsText}}" (input)="handleUserInput('regionContains')" />
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioRegion" id="regionStartsWith"
          (click)="toggleregionFilter(regionStartsWith,'regionStartsWith',$event)"
          aria-controls="regionStartsWith" />
        <label class="form-check-label" for="regionStartsWith">Starts With</label>
        <input mdbInput type="text" id="regionStartsWithform1" id="regionStartsWith" mdbCollapse
          #regionStartsWith="mdbCollapse" class="form-control" [(ngModel)]="regionStartsWithText"
          value="{{regionStartsWithText}}" (input)="handleUserInput('regionStartsWith')" />
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioRegion" id="regionEndsWith"
          (click)="toggleregionFilter(regionEndsWith,'regionEndsWith',$event)" aria-controls="regionEndsWith" />
        <label class="form-check-label" for="regionEndsWith">Ends With</label>
        <input mdbInput type="text" id="regionEndsWithform1" id="regionEndsWith" mdbCollapse
          #regionEndsWith="mdbCollapse" class="form-control" [(ngModel)]="regionEndsWithText"
          value="{{regionEndsWithText}}" (input)="handleUserInput('regionEndsWith')" />
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" (click)="toggleregionFilter(null,'regionEmpty',$event)"
          name="flexRadioRegion" id="regionEmpty" />
        <label class="form-check-label" for="regionEmpty">Empty</label>
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio"
          (click)="toggleregionFilter(null,'regionNotEmpty',$event)" name="flexRadioRegion" id="regionNotEmpty" />
        <label class="form-check-label" for="regionNotEmpty">Not Empty</label>
      </div>
    </div>
  </div> -->
  <div class="form-check mb-2">
    <input mdbRadio (click)="sidebarFilter(country,'country',$event)" aria-controls="country" aria-expanded="false"
      class="form-check-input" type="checkbox" name="flexRadioDefault21" id="flexRadioDefault21" [checked]="false" />
    <label class="form-check-label" for="flexRadioDefault21"> Country</label>
    <div class="mt-1" id="country" mdbCollapse #country="mdbCollapse">
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioCountry" id="countryContains"
          (click)="togglecountryFilter(countryContains,'countryContains',$event)" aria-controls="countryContains" />
        <label class="form-check-label" for="countryContains">Contains</label>
        <input mdbInput type="text" id="countryContainsform1" id="countryContains" mdbCollapse
          #countryContains="mdbCollapse" class="form-control" [(ngModel)]="countryContainsText"
          value="{{countryContainsText}}" (input)="handleUserInput('countryContains')" />
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioCountry" id="countryStartsWith"
          (click)="togglecountryFilter(countryStartsWith,'countryStartsWith',$event)"
          aria-controls="countryStartsWith" />
        <label class="form-check-label" for="countryStartsWith">Starts With</label>
        <input mdbInput type="text" id="countryStartsWithform1" id="countryStartsWith" mdbCollapse
          #countryStartsWith="mdbCollapse" class="form-control" [(ngModel)]="countryStartsWithText"
          value="{{countryStartsWithText}}" (input)="handleUserInput('countryStartsWith')" />
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioCountry" id="countryEndsWith"
          (click)="togglecountryFilter(countryEndsWith,'countryEndsWith',$event)" aria-controls="countryEndsWith" />
        <label class="form-check-label" for="countryEndsWith">Ends With</label>
        <input mdbInput type="text" id="countryEndsWithform1" id="countryEndsWith" mdbCollapse
          #countryEndsWith="mdbCollapse" class="form-control" [(ngModel)]="countryEndsWithText"
          value="{{countryEndsWithText}}" (input)="handleUserInput('countryEndsWith')" />
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" (click)="togglecountryFilter(null,'countryEmpty',$event)"
          name="flexRadioCountry" id="countryEmpty" />
        <label class="form-check-label" for="countryEmpty">Empty</label>
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio"
          (click)="togglecountryFilter(null,'countryNotEmpty',$event)" name="flexRadioCountry" id="countryNotEmpty" />
        <label class="form-check-label" for="countryNotEmpty">Not Empty</label>
      </div>
    </div>
  </div>

  <div class="form-check mb-2">
    <input mdbRadio (click)="sidebarFilter(address,'address',$event)" aria-controls="address" aria-expanded="false"
      class="form-check-input" type="checkbox" name="flexRadioDefault22" id="flexRadioDefault22" [checked]="false" />
    <label class="form-check-label" for="flexRadioDefault22"> Address</label>
    <div class="mt-1" id="address" mdbCollapse #address="mdbCollapse">
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioAddress" id="addressContains"
          (click)="toggleaddressFilter(addressContains,'addressContains',$event)" aria-controls="addressContains" />
        <label class="form-check-label" for="addressContains">Contains</label>
        <input mdbInput type="text" id="addressContainsform1" id="addressContains" mdbCollapse
          #addressContains="mdbCollapse" class="form-control" [(ngModel)]="addressContainsText"
          value="{{addressContainsText}}" (input)="handleUserInput('addressContains')" />
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioAddress" id="addressStartsWith"
          (click)="toggleaddressFilter(addressStartsWith,'addressStartsWith',$event)"
          aria-controls="addressStartsWith" />
        <label class="form-check-label" for="addressStartsWith">Starts With</label>
        <input mdbInput type="text" id="addressStartsWithform1" id="addressStartsWith" mdbCollapse
          #addressStartsWith="mdbCollapse" class="form-control" [(ngModel)]="addressStartsWithText"
          value="{{addressStartsWithText}}" (input)="handleUserInput('addressStartsWith')" />
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioAddress" id="addressEndsWith"
          (click)="toggleaddressFilter(addressEndsWith,'addressEndsWith',$event)" aria-controls="addressEndsWith" />
        <label class="form-check-label" for="addressEndsWith">Ends With</label>
        <input mdbInput type="text" id="addressEndsWithform1" id="addressEndsWith" mdbCollapse
          #addressEndsWith="mdbCollapse" class="form-control" [(ngModel)]="addressEndsWithText"
          value="{{addressEndsWithText}}" (input)="handleUserInput('addressEndsWith')" />
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" (click)="toggleaddressFilter(null,'addressEmpty',$event)"
          name="flexRadioAddress" id="addressEmpty" />
        <label class="form-check-label" for="addressEmpty">Empty</label>
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio"
          (click)="toggleaddressFilter(null,'addressNotEmpty',$event)" name="flexRadioAddress" id="addressNotEmpty" />
        <label class="form-check-label" for="addressNotEmpty">Not Empty</label>
      </div>
    </div>
  </div>

  <div class="form-check mb-2">
    <input mdbRadio (click)="sidebarFilter(linkedin,'linkedin',$event)" aria-controls="linkedin" aria-expanded="false"
      class="form-check-input" type="checkbox" name="flexRadioDefault23" id="flexRadioDefault23" [checked]="false" />
    <label class="form-check-label" for="flexRadioDefault23"> Linkedin</label>
    <div class="mt-1" id="linkedin" mdbCollapse #linkedin="mdbCollapse">
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioLinkedin" id="linkedinContains"
          (click)="togglelinkedinFilter(linkedinContains,'linkedinContains',$event)" aria-controls="linkedinContains" />
        <label class="form-check-label" for="linkedinContains">Contains</label>
        <input mdbInput type="text" id="linkedinContainsform1" id="linkedinContains" mdbCollapse
          #linkedinContains="mdbCollapse" class="form-control" [(ngModel)]="linkedinContainsText"
          value="{{linkedinContainsText}}" (input)="handleUserInput('linkedinContains')" />
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioLinkedin" id="linkedinStartsWith"
          (click)="togglelinkedinFilter(linkedinStartsWith,'linkedinStartsWith',$event)"
          aria-controls="linkedinStartsWith" />
        <label class="form-check-label" for="linkedinStartsWith">Starts With</label>
        <input mdbInput type="text" id="linkedinStartsWithform1" id="linkedinStartsWith" mdbCollapse
          #linkedinStartsWith="mdbCollapse" class="form-control" [(ngModel)]="linkedinStartsWithText"
          value="{{linkedinStartsWithText}}" (input)="handleUserInput('linkedinStartsWith')" />
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioLinkedin" id="linkedinEndsWith"
          (click)="togglelinkedinFilter(linkedinEndsWith,'linkedinEndsWith',$event)" aria-controls="linkedinEndsWith" />
        <label class="form-check-label" for="linkedinEndsWith">Ends With</label>
        <input mdbInput type="text" id="linkedinEndsWithform1" id="linkedinEndsWith" mdbCollapse
          #linkedinEndsWith="mdbCollapse" class="form-control" [(ngModel)]="linkedinEndsWithText"
          value="{{linkedinEndsWithText}}" (input)="handleUserInput('linkedinEndsWith')" />
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio"
          (click)="togglelinkedinFilter(null,'linkedinEmpty',$event)" name="flexRadioLinkedin" id="linkedinEmpty" />
        <label class="form-check-label" for="linkedinEmpty">Empty</label>
      </div>
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio"
          (click)="togglelinkedinFilter(null,'linkedinNotEmpty',$event)" name="flexRadioLinkedin"
          id="linkedinNotEmpty" />
        <label class="form-check-label" for="linkedinNotEmpty">Not Empty</label>
      </div>
    </div>
  </div>

  <div class="form-check mb-2">
    <input mdbRadio (click)="sidebarFilter(snippet,'snippet',$event)" aria-controls="snippet" aria-expanded="false"
      class="form-check-input" type="checkbox" name="flexRadioDefault24" id="flexRadioDefault24" [checked]="false" />
    <label class="form-check-label" for="flexRadioDefault24"> Snippet</label>
    <div class="mt-1" id="snippet" mdbCollapse #snippet="mdbCollapse">
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioSnippet" id="snippetContains"
          (click)="togglesnippetFilter(snippetContains,'snippetContains',$event)" aria-controls="snippetContains" />
        <label class="form-check-label" for="snippetContains">Contains</label>
        <input mdbInput type="text" id="snippetContainsform1" id="snippetContains" mdbCollapse
          #snippetContains="mdbCollapse" class="form-control" [(ngModel)]="snippetContainsText"
          value="{{snippetContainsText}}" (input)="handleUserInput('snippetContains')" />
      </div>
    </div>
  </div>
  <div class="form-check mb-2">
    <input mdbRadio (click)="sidebarFilter(snippet1,'snippet1',$event)" aria-controls="snippet1" aria-expanded="false"
      class="form-check-input" type="checkbox" name="flexRadioDefault25" id="flexRadioDefault25" [checked]="false" />
    <label class="form-check-label" for="flexRadioDefault25"> Snippet1</label>
    <div class="mt-1" id="snippet1" mdbCollapse #snippet1="mdbCollapse">
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioSnippet1" id="snippet1Contains"
          (click)="togglesnippet1Filter(snippet1Contains,'snippet1Contains',$event)" aria-controls="snippet1Contains" />
        <label class="form-check-label" for="snippet1Contains">Contains</label>
        <input mdbInput type="text" id="snippet1Containsform1" id="snippet1Contains" mdbCollapse
          #snippet1Contains="mdbCollapse" class="form-control" [(ngModel)]="snippet1ContainsText"
          value="{{snippet1ContainsText}}" (input)="handleUserInput('snippet1Contains')" />
      </div>
    </div>
  </div>

  <div class="form-check mb-2">
    <input mdbRadio (click)="sidebarFilter(snippet2,'snippet2',$event)" aria-controls="snippet2" aria-expanded="false"
      class="form-check-input" type="checkbox" name="flexRadioDefault26" id="flexRadioDefault26" [checked]="false" />
    <label class="form-check-label" for="flexRadioDefault26"> Snippet2</label>
    <div class="mt-1" id="snippet2" mdbCollapse #snippet2="mdbCollapse">
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioSnippet2" id="snippet2Contains"
          (click)="togglesnippet2Filter(snippet2Contains,'snippet2Contains',$event)" aria-controls="snippet2Contains" />
        <label class="form-check-label" for="snippet2Contains">Contains</label>
        <input mdbInput type="text" id="snippet2Containsform1" id="snippet2Contains" mdbCollapse
          #snippet2Contains="mdbCollapse" class="form-control" [(ngModel)]="snippet2ContainsText"
          value="{{snippet2ContainsText}}" (input)="handleUserInput('snippet2Contains')" />
      </div>
    </div>
  </div>

  <div class="form-check mb-2">
    <input mdbRadio (click)="sidebarFilter(snippet3,'snippet3',$event)" aria-controls="snippet3" aria-expanded="false"
      class="form-check-input" type="checkbox" name="flexRadioDefault27" id="flexRadioDefault27" [checked]="false" />
    <label class="form-check-label" for="flexRadioDefault27"> Snippet3</label>
    <div class="mt-1" id="snippet3" mdbCollapse #snippet3="mdbCollapse">
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioSnippet3" id="snippet3Contains"
          (click)="togglesnippet3Filter(snippet3Contains,'snippet3Contains',$event)" aria-controls="snippet3Contains" />
        <label class="form-check-label" for="snippet3Contains">Contains</label>
        <input mdbInput type="text" id="snippet3Containsform1" id="snippet3Contains" mdbCollapse
          #snippet3Contains="mdbCollapse" class="form-control" [(ngModel)]="snippet3ContainsText"
          value="{{snippet3ContainsText}}" (input)="handleUserInput('snippet3Contains')" />
      </div>
    </div>
  </div>

  <div class="form-check mb-2">
    <input mdbRadio (click)="sidebarFilter(snippet4,'snippet4',$event)" aria-controls="snippet4" aria-expanded="false"
      class="form-check-input" type="checkbox" name="flexRadioDefault28" id="flexRadioDefault28" [checked]="false" />
    <label class="form-check-label" for="flexRadioDefault28"> Snippet4</label>
    <div class="mt-1" id="snippet4" mdbCollapse #snippet4="mdbCollapse">
      <div class="form-check">
        <input mdbRadio class="form-check-input" type="radio" name="flexRadioSnippet4" id="snippet4Contains"
          (click)="togglesnippet4Filter(snippet4Contains,'snippet4Contains',$event)" aria-controls="snippet4Contains" />
        <label class="form-check-label" for="snippet4Contains">Contains</label>
        <input mdbInput type="text" id="snippet4Containsform1" id="snippet4Contains" mdbCollapse
          #snippet4Contains="mdbCollapse" class="form-control" [(ngModel)]="snippet4ContainsText"
          value="{{snippet4ContainsText}}" (input)="handleUserInput('snippet4Contains')" />
      </div>
    </div>
  </div>

</div>
