import { Component, OnInit } from '@angular/core';
import {ProspectStatusResponseModel} from '../setting-model/setting-model';
import { SettingViewModel } from '../setting-view-model/setting-view-model';
import { MapAPIService } from "src/app/app-providers/services/map-module";
import { BehaviorSubject } from 'rxjs';
@Component({
  selector: 'app-settings-statuses',
  templateUrl: './settings-statuses.component.html',
  styleUrls: ['./settings-statuses.component.scss'],
  providers: [MapAPIService,SettingViewModel],
})
export class SettingsStatusesComponent implements OnInit {
  prospectStatuslist = [];
  
  prospectStatusResponseModel = new BehaviorSubject<ProspectStatusResponseModel>(
    new ProspectStatusResponseModel()
  );

  constructor(private vm: SettingViewModel) { }

  ngOnInit(): void {
    this.vm.parentSettingStatusComponent = this;
    this.vm.subscribeStatusVariable(this.prospectStatusResponseModel);
    this.vm.getAllProspectStatus();
  }

  ProspectStatusRecieved() {
    this.prospectStatuslist = this.prospectStatusResponseModel.value.results;
  }

}
