import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MapCommon } from 'src/app/app-providers/map-base/MapCommon';
import { UserExtraResponseModel } from '../campaign-model/campaign-model';
import { CampaignViewModel } from '../campaign-view-model/campaign-view-model';

@Component({
  selector: 'app-campaign-detail-signature',
  templateUrl: './campaign-detail-signature.component.html',
  styleUrls: ['./campaign-detail-signature.component.scss']
})
export class CampaignDetailSignatureComponent implements OnInit {
  @Input() sendersId: []
  loadingSpiner: boolean = false;
  temp_user_ids = [3,5,7]
  userExtraList = []
  SignatureViewModel: Array<[]> = []
  SelectedEmail: string = ''
  editormodules: any;
  editorAPIKey: string;
  activeButton: number = 0

  userExtraResponseModel = new BehaviorSubject<UserExtraResponseModel>(
    new UserExtraResponseModel()
  );

  constructor(
    private vm: CampaignViewModel
  ) {
    this.editormodules = MapCommon.getCampaignEditorModule();
    this.editorAPIKey = MapCommon.getTinyEditorAPIKey();
   }

  ngOnInit(): void {
    this.vm.parentCampaignDetailSignatureComponent = this;
    this.vm.subscribeToSignatureVeriables(this.userExtraResponseModel);
    this.temp_user_ids = this.sendersId
    this.getUserExtras();

  }


  getUserExtras(){
    this.vm.getUserExtras(this.temp_user_ids)
  }

  userExtrasReceived(){
    this.userExtraList = this.userExtraResponseModel.value.results;
    this.userExtraList = this.userExtraList.slice(0, this.temp_user_ids.length);
    this.SelectedEmail =  this.userExtraList.map(e => e.email_signature)[0]
  }

  showTemplate(emailBody:any,id:number){
    this.SelectedEmail  = emailBody
    this.activeButton = id
  }


}
