import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProspectModel, ProspectGroupResponseModel } from '../import-model/import-model';
import { ImportViewModel } from '../import-view-model/import-view-model';
import { MapAPIService } from "src/app/app-providers/services/map-module";
import { BehaviorSubject } from 'rxjs';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { MapCommon } from 'src/app/app-providers/map-base/MapCommon';
import { FormsModule } from  '@angular/forms';
import { ImportsComponent} from '../imports.component';
@Component({
  selector: 'app-one-prospect-import',
  templateUrl: './one-prospect-import.component.html',
  styleUrls: ['./one-prospect-import.component.scss'],
  providers: [MapAPIService, ImportViewModel,FormsModule],
})
export class OneProspectImportComponent implements OnInit , OnDestroy {
  selectedProspectGroupId: number;
  selectedProspectGroupName: string;
  prospectGroupList = [];
  prospectModel = new ProspectModel();
  editPrevProspect:boolean = false
  prospectGroupModel = new BehaviorSubject<ProspectGroupResponseModel>(
    new ProspectGroupResponseModel()
  );
  nameValidate:boolean = false
  emailValidate:boolean = false
  ImportsComponent:ImportsComponent
  constructor(private vm: ImportViewModel) { }
  // Variables to serve data to component


  ngOnInit(): void {
    this.vm.parentComponent = this;
    this.vm.subscribeAllVariables(this.prospectGroupModel);
    this.vm.getAllProspectGroups("");
    // this.vm.getAllProspectGroups("",2);
    if(localStorage.getItem('create-prospect-from-error')){
      this.prospectModel = JSON.parse(localStorage.getItem('create-prospect-from-error'))
      this.editPrevProspect = true
      this.nameChange();
      this.emailChange();
    }
  }

  ngOnDestroy(): void {
    localStorage.removeItem('create-prospect-from-error')
    localStorage.removeItem('selectedProspectGroupName')
    this.prospectModel =  new ProspectModel();

  }
  //call all prospecting group
  changeTypeaheadLoading(event) {
      this.vm.getAllProspectGroups(this.selectedProspectGroupName);
  }

  nameChange(){
    if(this.prospectModel.firstname == undefined || this.prospectModel.firstname == null || this.prospectModel.firstname == ""){
      this.nameValidate = true
    } else{
      this.nameValidate = false
    }
  }
  validateEmail(email) {
    var pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    return !!pattern.test(String(email).toLowerCase());
  }

  emailChange(){
    if(this.prospectModel.email_address == undefined || this.prospectModel.email_address == null ){
      this.emailValidate = true
    } else{
      if(this.validateEmail(this.prospectModel.email_address)){
        this.emailValidate = false
      } else{
        this.emailValidate = true
      }
    }
  }

  ProspectGroupReceived() {
    this.prospectGroupList = this.prospectGroupModel.value.results;
    if(localStorage.getItem('selectedProspectGroupName')){
       this.selectedProspectGroupName = this.prospectGroupList.filter( e => e.id == localStorage.getItem('selectedProspectGroupName') )[0].name
       this.selectedProspectGroupId  =  this.prospectGroupList.filter( e => e.id == localStorage.getItem('selectedProspectGroupName') )[0].id
     }
  }

  onSelect(event: any): void {
    this.selectedProspectGroupName = event.item.name;
    this.selectedProspectGroupId = event.item.id;
  }

  onSuccessSave()
  {
    if( localStorage.getItem('create-prospect-from-error')) {
      this.vm.removeErrorCountAfterCorrection(JSON.parse(localStorage.getItem('create-prospect-from-error')).id)  
      this.prospectModel =  new ProspectModel();
      this.selectedProspectGroupName = undefined
      localStorage.removeItem('create-prospect-from-error')
      localStorage.removeItem('selectedProspectGroupName')
      ImportsComponent.importsCall.closeModal()
    }
  }

  submit() {
    this.nameChange();
    this.emailChange();
    if(!this.emailValidate || !this.nameValidate){
      if (this.selectedProspectGroupId > 0) {
        this.prospectModel.prospect_group = [this.selectedProspectGroupId];
      }
      else {
        this.prospectModel.prospect_group = [MapCommon.getDefaultProspectGroup()];
      }
      this.vm.saveProspect(this.prospectModel);
    }

    //console.log(this.prospectModel.prospect_group);

  }


}
