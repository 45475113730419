
<div class="help-sidebar">
    <div class="mt-2"></div>
  
    <!-- <hr class="mb-0"> -->
    
    <ul class="nav flex-column mb-auto">
      <li class="nav-item">
        <a class="nav-link" [routerLinkActive]="['active']" routerLink="/help/about">
          <i class="fas fa-unlink pe-3"></i> About</a>
      </li>
      <!-- <li class="nav-item">
        <a  class="nav-link" [routerLinkActive]="['active']" routerLink="/help/docs">
          <i class="fas fa-male pe-4"></i> Manuals</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLinkActive]="['active']" routerLink="/help/feedback">
          <i class="fas fa-globe-americas pe-3"></i> Give Feedback</a>
      </li> -->
    </ul>
</div> 
  