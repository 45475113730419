import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { BehaviorSubject } from 'rxjs';
import { ExportCampaignService } from 'src/app/admin/services/export-campaign.service';
import { MapCommon } from 'src/app/app-providers/map-base/MapCommon';
import { MapAPIService } from 'src/app/app-providers/services/map-api-service';
import { ClientsResponseModel, ClientDncModel, ClientDncResponseModel } from '../dnc-models/dnc-model';
import { DncViewModel } from '../dnc-models/dnc-view-model';
import { DncClientAddComponent } from './dnc-client-add/dnc-client-add.component';
import { DncClientEditComponent } from './dnc-client-edit/dnc-client-edit.component';

@Component({
  selector: 'app-dnc-client',
  templateUrl: './dnc-client.component.html',
  styleUrls: ['./dnc-client.component.scss'],
  providers: [MapAPIService, DncViewModel, ExportCampaignService],
})
export class DncClientComponent implements OnInit {

  page_index = 1;
  clientDncList = [];
  clientsList = [];
  clientDncListB4Search = [];
  totalDncItems: number = 0;
  currentUser: number;
  addClientDncModalRef: MdbModalRef<DncClientAddComponent>;
  editClientDncModalRef: MdbModalRef<DncClientEditComponent>;
  selectedClient: number = null; 
  selectedDncType: number = null; 
  dncSubType = [{"id":"1","name":"Attendee"}, {"id": "2","name": "Sponsor"}, {"id": "3","name": "Other"}]

  clientDncResponseModel = new BehaviorSubject<ClientDncResponseModel>(
    new ClientDncResponseModel()
  );

  clientResponseModel = new BehaviorSubject<ClientsResponseModel>(
    new ClientsResponseModel()
  );

  clientDncSearchValue = '';
  @ViewChild(NgForm) clientDncSearchForm: NgForm;

  constructor(private vm: DncViewModel, private modalService: MdbModalService,
    private exportCampaignService: ExportCampaignService
    ) {
    this.currentUser = MapCommon.getloggedInUser().id;
    this.vm.parentDncClientComponent = this;
    this.vm.subscribeClientsVariable(this.clientResponseModel);
    this.vm.getAllClients('dnc-client');
    this.vm.subscribeClientDncVariable(this.clientDncResponseModel);
    this.vm.getAllClientDnc(this.page_index);
  }

  ngOnInit(): void {

  }

  onSelectedClient(){
    this.vm.parentDncClientComponent = this;
    this.vm.searchClientDnc(this.clientDncSearchForm.value.search, this.selectedClient, this.selectedDncType);
  }

  onDncType(){
    this.vm.parentDncClientComponent = this;
    this.vm.searchClientDnc(this.clientDncSearchForm.value.search, this.selectedClient, this.selectedDncType);
  }


  // Multi Select
  isMasterSel: boolean = false;
  includedItems: number[] = [];
  excludedItems: number[] = [];
  selectedItems: number = 0;

  checkUncheckAll(e: any) {
    this.isMasterSel = e.target.checked;
    if (!this.isMasterSel) {
      this.includedItems = [];
      this.excludedItems = [];
    }
    for (var i = 0; i < this.clientDncList.length; i++) {
      this.clientDncList[i].isSelected = this.isMasterSel;
    }    
    this.getCheckedItemList();
  }

  isAllSelected(e: any, itemId: number) {
    this.clientDncList.find(item => item.id == itemId).isSelected = e.target.checked;
    this.getCheckedItemList();
  }

  getCheckedItemList() {
    this.selectedItems = 0;
    this.includedItems = [];
    this.excludedItems = [];
    if(this.isMasterSel){
      this.selectedItems = this.totalDncItems;
      this.excludedItems = this.clientDncList.filter(item => item.isSelected == false).map(data => data.id);
    } else {
      this.selectedItems = this.clientDncList.filter(item => item.isSelected == true).map(data => data.id).length;
      this.includedItems = this.clientDncList.filter(item => item.isSelected == true).map(data => data.id)
    }
    this.selectedItems = this.selectedItems - this.excludedItems.length;
  }

  // Export
  showingExportLoading: boolean = false;

  exportAll(){
    this.showingExportLoading = true;
    this.vm.parentDncClientComponent = this;
    this.vm.exportClientDNC(this.clientDncSearchForm.value.search, this.selectedClient, this.includedItems, this.excludedItems);

  }


  
  domainDncExported(){
    // this.exportCampaignService.downloadCSV(data, 'domain_dnc_export.csv');
    this.showingExportLoading = false;
  }



  clientsReceived() {
    this.clientsList = this.clientResponseModel.value.results;

  }

  clientDncSearchSubmit(e: any) {
    this.vm.parentDncClientComponent = this;
    this.vm.searchClientDnc(this.clientDncSearchForm.value.search, this.selectedClient, this.selectedDncType);
  }

  clientDncReceived() {
    this.totalDncItems = this.clientDncResponseModel.value.count;
    if (this.clientDncListB4Search.length == 0)
      this.clientDncList = this.clientDncResponseModel.value.results;
    else {
      this.clientDncList = this.clientDncResponseModel.value.results;
      if (this.clientDncResponseModel.value.previous !== null) {
        for (var j = 0; j < this.clientDncList.length; j++) {
          var index = this.clientDncListB4Search.findIndex(x => x.id == this.clientDncList[j].id);
          if (index === -1)
            this.clientDncListB4Search.push(this.clientDncList[j]);
        }
        this.clientDncList = this.clientDncListB4Search;
      }
    }

    if(this.isMasterSel){
      this.clientDncList.forEach(el => el.isSelected = true);
    }

  }

  openAddClientDncModal() {
    this.addClientDncModalRef = this.modalService.open(DncClientAddComponent, { data: { clients: this.clientsList } });
  }

  getClientName(clientID: number) {
    try {
      var result = this.clientsList.filter(obj => {
        return obj.id === Number(clientID);
      })
      return (result[0].name).toString();
    } catch {
      return 'Global';
    };
  }

  editClientDncModal(clientDncModel: ClientDncModel) {
    clientDncModel.modified_by = this.currentUser;
    this.editClientDncModalRef = this.modalService.open(DncClientEditComponent, {
      data: { clientDncModel: Object.assign({}, clientDncModel), clients: this.clientsList }
    });
    this.editClientDncModalRef.onClose.subscribe((message: any) => {
      if (message === true) {
        this.page_index = 1;
        this.vm.getAllClientDnc(this.page_index);
      }
    });
  }

  onClientDncScroll() {
    if (this.clientDncResponseModel.value.next) {
      this.clientDncListB4Search = this.clientDncList;
      this.page_index++;
      this.vm.getAllClientDnc(this.page_index);
    }
  }

}
