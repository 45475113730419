import { Component, OnInit , Input } from '@angular/core';
import { CampaignDetailComponent } from '../campaign-detail/campaign-detail.component';
import { CampaignClientsModel, CampaignModel, CampaignUserResponseModel, RegionResponseModel, SendingWindowsResponseModel } from '../campaign-model/campaign-model';
import { BehaviorSubject } from 'rxjs';
import { CampaignViewModel } from '../campaign-view-model/campaign-view-model';
import { ConfirmDialogService } from '../../admin/confirm-dialog/confirm-dialog.service';
import { Router } from '@angular/router';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { CampaignDetailSummaryComponent } from '../campaign-detail-summary/campaign-detail-summary.component';
@Component({
  selector: 'app-campaign-detail-settings',
  templateUrl: './campaign-detail-settings.component.html',
  styleUrls: ['./campaign-detail-settings.component.scss']
})
export class CampaignDetailSettingsComponent implements OnInit {
  campaignDetails: any = {};
  sendingWindowId: number = 0;
  sendingWindowslist = [];
  userList = [];
  clientName: string;
  sendModeModel: boolean = false;
  regionModel: number = 0;
  sendEmailModeModel: boolean = false;
  campaignData = new CampaignModel();
  selectedSendingWindow: string;
  isParentFill: boolean = false;
  static settingCall;
  stepsList: any = [];
  disableSendingWindow: boolean = false;
  disableManualSwitch: boolean = false;
  processingItems: number = 0;
  scheduledItems = [];
  totalSechduleEmailSent = 0;
  multiMailmoxesModelCheck: boolean = false;
  sendersModel = [];
  formErrors = [];
  clientId:number;
  loggedInUser:number
  campaignCreatedBy:number
  isLoading: boolean = false;


  @Input() multipleUser :boolean
  sendingWindowsResponseModel = new BehaviorSubject<SendingWindowsResponseModel>(
    new SendingWindowsResponseModel()
  );


  regionResponseModel = new BehaviorSubject<RegionResponseModel>(
    new RegionResponseModel()
  );

  activeMainBoxesUserResponseModel = new BehaviorSubject<CampaignUserResponseModel>(
    new CampaignUserResponseModel()
  );

  constructor(private vm: CampaignViewModel, private confirmDialogService: ConfirmDialogService,
    private router: Router
  ) { CampaignDetailSettingsComponent.settingCall = this; }

  ngOnInit(): void {
    this.loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"))?.id
    this.vm.parentCampaignDetailSettingsComponent = this;
    // this.vm.getAllUsers('detail-settings');
    this.vm.subscribeCampaignDetailSettingVariables(
      this.sendingWindowsResponseModel,
      this.regionResponseModel,
      this.activeMainBoxesUserResponseModel
    );
    this.vm.getAllSendingWindows("", 'detail-settings', 10);
    this.vm.getRegions();


    this.getDataFromParent();
    this.stepsList = CampaignDetailSummaryComponent.siblingCall.getSteps();

    if (this.stepsList.length > 0){
      this.processingItems = this.stepsList.filter(el => el.status == 'Processing' || el.status == 'Paused').length;

      this.scheduledItems = this.stepsList.filter(el => el.status == 'Scheduled' || el.status == 'Paused');
      if(this.scheduledItems.length > 0){
        this.scheduledItems.forEach(element => {
          var count = element?.step_stats?.emails_sent?.total || 0;
          this.totalSechduleEmailSent + count;
        });
      }

      if(this.processingItems > 0){
        this.disableSendingWindow = true;
        this.disableManualSwitch = true;
      }
    }
    this.multiMailmoxesModelCheck = this.multipleUser
    this.campaignCreatedBy = this.campaignDetails?.created_by
    console.log(JSON.parse(localStorage.getItem("loggedInUser")).id , this.campaignDetails.created_by    )
  }


  UserRecieved(){
    this.userList = this.activeMainBoxesUserResponseModel.value.results;
    // console.log(this.userList)
  }



  //call all prospecting group
  changeTypeaheadLoading(event) {
    if (this.selectedSendingWindow && this.selectedSendingWindow.length > 2)
      this.vm.getAllSendingWindows(this.selectedSendingWindow, 'detail-settings', 10);
  }

  onSelect(event: TypeaheadMatch): void {
    this.sendingWindowId = event.item.id;
  }

  getFullItem(sendingWindowId: number) {
    var result = this.sendingWindowslist.filter(obj => {
      return obj.id === sendingWindowId;
    })
    return result[0]
  };

  sendingWindowsReceived() {
    this.sendingWindowslist = this.sendingWindowsResponseModel.value.results;

    if (this.isParentFill) {
      this.sendingWindowId = this.campaignDetails.schedule;
      if (this.sendingWindowslist.length > 0) {
        for (var j = 0; j < this.sendingWindowslist.length; j++) {
          if (this.sendingWindowslist[j].id == this.sendingWindowId)
            this.selectedSendingWindow = this.sendingWindowslist[j].name;
        }
      }
      this.isParentFill = false;
    }
    // Please check here
    // this.selectedSendingWindow = this.getFullItem(this.campaignData.schedule).name;
    // this.sendingWindowId = this.campaignData.schedule;
  }
  getDataFromParent() {
    this.campaignDetails = CampaignDetailComponent.parentCall.getData();
    this.clientName = CampaignDetailComponent.parentCall.getClientName();
    this.campaignData = this.campaignDetails;
    this.clientId = this.campaignData.client
    this.vm.getAllUsersForClient('detail-settings', this.clientId)
    // console.log(this.campaignData)
    this.sendEmailModeModel = this.campaignData.use_personal_account;
    if (this.campaignData.send_mode === 'Manual') {
      this.sendModeModel = false
    } else {
      this.sendModeModel = true
    };
    this.isParentFill = true;

    if(this.campaignData.region > 0){
      this.regionModel = this.campaignData.region;
    }

    if(!this.campaignData?.senders){
      this.campaignData.senders = [];
    }

  }





  changesMailboxToggle(event){
    if(!event.target.checked || event.target.checked == false){
      this.campaignDetails.senders = [];
    }
  }


  onMailBoxSelected(e:any){
    let dateExpired = new Date(new Date().getDate()+7).getMilliseconds()
    let  expired = this.userList.filter(e => e.mailbox_connection.is_connected ? new Date(e.mailbox_connection?.expires_on.substr(0,10)).getMilliseconds() : new Date().getMilliseconds()  <= dateExpired  ).map(e => e.mailbox_connection.expires_on)

  }
expiredChecker(dateString:string = ''){
  let dateExpired = new Date(new Date().getDate()+7).getMilliseconds()
  return new Date(dateString.substr(0,10)).getMilliseconds()   <= dateExpired
}


  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }


  // getRegion(region: number){
  //   if(region){
  //     const item = this.regionResponseModel.value.results.find(el => el.id == region)
  //     if (item){
  //       return item.name
  //     } else {

  //       return region
  //     }
  //   } else {
  //     return '-'
  //   }
  // }

  update() {

    if(this.multiMailmoxesModelCheck == true &&
      (this.campaignData.senders?.length == 0 || this.campaignData.senders === undefined)){
      this.formErrors.push("Please select at least one sender account or turn off multi-account feature.");
      return
    }

    if (this.sendModeModel === true) {
      this.campaignData.send_mode = 'Auto';
      this.campaignData.schedule = this.sendingWindowId;

    } else {
      this.campaignData.send_mode = 'Manual';
      this.campaignData.schedule = null;
    };
    this.campaignData.use_personal_account = this.sendEmailModeModel;

    this.vm.updateCampaign(this.campaignData);
  }

  updateNameOfParent() {
    CampaignDetailComponent.parentCall.updateName(this.campaignData.name);
  }

  redirectToCampaignDetailPage(id: number) {
    this.isLoading = false;
    var url = '/campaigns/' + String(id);
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => this.router.navigate([url]) );
  }

  deleteCampaign() {
    this.isLoading = false;
    this.vm.deleteCampaign(this.campaignData.id);
  }

  sendToCampaignListing(){
    this.isLoading = false;
    this.router.navigate(['/campaigns']);
  }

  confirmDeleteCampaign() {
    this.confirmDialogService.confirmThis("Are you sure you want to delete campaign?", function () {
      CampaignDetailSettingsComponent.settingCall.deleteCampaign();
    }, function () {
    })
  }

  clearLoading(){
    this.isLoading = false;
  }

  duplicateCampaign() {
    // console.log("I'm clicked")
    // this.campaignData.name = this.campaignData.name + "_duplicate";
    // var campaignJsonData = JSON.parse(JSON.stringify(this.campaignData))
    // campaignJsonData.prospect = []
    // this.vm.saveCampaign(campaignJsonData, 'campaign-detail');
    this.isLoading = true;
    this.vm.duplicateCampaign(this.campaignData.id)
  }

  confirmDuplicateCampaign() {
    this.confirmDialogService.confirmThis("Are you sure you want to duplicate this campaign?", function () {
      CampaignDetailSettingsComponent.settingCall.duplicateCampaign();
    }, function () {
    })
  }
}
