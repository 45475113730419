

<app-sidenav></app-sidenav>

<div class="ms-sm-auto" style="width: 90%;">

<div class="row sticky-top" style="z-index: 2;">
  <div class="col-12 shadow-1 p-3 bg-white">
    <div class="row">

      <div class="col-md-8">
        <ng-container *ngIf="campaignDetails.name else loadingName">
          <h4 class="map-text-header">{{campaignDetails.name}} | <span style="font-size: 14px; color: #EF6C00;">by
            {{userEmail}}</span></h4>
        </ng-container>

        <ng-template #loadingName>
          <app-spinner></app-spinner>
        </ng-template>

      </div>

      <!--<div class="col-md-4">
        <div class="d-flex justify-content-end">

          <div class="form-check form-switch pt-1 pe-3">
            <label class="form-check-label map-text-header" for="activeSwitchbox">{{campaignDetails.status}}</label>
            <input mdbCheckbox class="form-check-input campaign-status-checkbox"
            (click)="ConfirmStatusChanged()"
              [checked]="campiagnStatus" type="checkbox" id="activeSwitchbox" />
          </div>

           <div class="pe-3">
            <button type="button" class="btn btn-sm btn-danger btn-floating">
              <i class="far fa-trash-alt"></i>
            </button>
          </div>
        </div>
      </div>-->

        <div class="col-md-4">
          <div class="d-flex justify-content-end">

            <div class="pe-3 campaign-region" *ngIf="campaignDetails.region">
              <p class="m-0 mt-1"><i class="fas fa-globe-americas"></i> {{getRegion(campaignDetails.region)}}</p>
            </div>

            <!-- <div class="form-check form-switch pt-1 pe-3">
              <label class="form-check-label map-text-header" for="activeSwitchbox">{{campaignDetails.status}}</label>
              <input mdbCheckbox class="form-check-input campaign-status-checkbox"
              (change)="ConfirmStatusChanged($event)"
                [checked]="campiagnStatus" type="checkbox" id="activeSwitchbox" />
            </div> -->

            <!-- <div class="pe-3">
              <button type="button" class="btn btn-sm btn-danger btn-floating">
                <i class="far fa-trash-alt"></i>
              </button>
            </div> -->

            <!-- <div class="pe-3">
              <button type="button" class="btn btn-sm btn-success btn-floating">
                <i class="fas fa-envelope"></i>
              </button>
            </div> -->

            <!-- <div class="pe-3">
            <button type="button" class="btn btn-sm btn-warning btn-floating">
              <i class="fas fa-sync"></i>
            </button>
            </div> -->

            <div class="pe-3">
            <button (click)="gotoProspectPage()" type="button" class="btn btn-sm btn-primary">
              <i class="fas fa-plus"></i> Add Prospects</button>
            </div>

          </div>
        </div>

    </div>

  </div>
</div>


<app-campaign-mailbox-alert>

</app-campaign-mailbox-alert>




<div class="row" *ngIf="showLimitReached">
  <div class="warning-msg m-0 text-center text-white">
  <span><i class="fas fa-exclamation-triangle"></i>

    Attention! You have reached your email sending limit for this account, available limits are
    <span *ngFor="let item of campaignUser?.mailbox_connection.send_limit; let isLast=last">
      {{(item.provider == 'Gmail' || item.provider == 'Outlook')  ? 'personal':'external' }} ({{item.limit_remaining}}){{isLast ? '' : ' and '}}
    </span>
    </span>
  </div>
</div>




<div class="tabc">






<!-- <div class="row" style="height: 100vh; overflow-x: hidden; overflow-y: auto; background-color: #FBF7FE;"> -->



<div class="row">

    <!-- <div class="row" style="background-color: #FBF7FE;">   -->

    <div id="map-campaign-tabs" class="col-12 p-0">
      <mdb-tabs [fill]="true" class="flex-fill tabc" >


        <mdb-tab class="tabc" id="ab" title="SUMMARY">
          <ng-template mdbTabContent>
            <div class="c-tabs">
              <app-campaign-detail-summary (limitReached)="limitReached($event)" [campaignDetail]="campaignDetails" [campaignState]="campaignStatus"></app-campaign-detail-summary>
            </div>
        </ng-template>
        </mdb-tab>

        <mdb-tab title="PROSPECTS" >
          <ng-template mdbTabContent>
            <div class="c-tabs">
              <app-campaign-detail-prospects></app-campaign-detail-prospects>
            </div>
        </ng-template>
        </mdb-tab>

        <mdb-tab title="EMAILS">
          <ng-template mdbTabContent>
            <div class="c-tabs">
              <app-campaign-detail-emails></app-campaign-detail-emails>
            </div>
        </ng-template>
        </mdb-tab>

        <mdb-tab title="AUTO REPLY">
          <ng-template mdbTabContent>
            <div class="c-tabs">
              <app-campaign-detail-auto-reply></app-campaign-detail-auto-reply>
            </div>
        </ng-template>
        </mdb-tab>

        <mdb-tab title="Signatures" *ngIf="showSignature">
          <ng-template mdbTabContent>
            <div class="c-tabs">
              <app-campaign-detail-signature [sendersId]="sendersID"></app-campaign-detail-signature>
            </div>
        </ng-template>
        </mdb-tab>

        <mdb-tab title="SETTINGS">
          <ng-template mdbTabContent>
            <div class="c-tabs">
              <app-campaign-detail-settings [multipleUser]="showSignature"></app-campaign-detail-settings>
            </div>
        </ng-template>
        </mdb-tab>

      </mdb-tabs>
    </div>

  </div>


  </div>

  <div>
