
<div class="ms-sm-auto" style="width: 90%; height: 100%;">


  <!-- style="background-color: #7e1acb;" -->
    <!-- Main row -->
    <div class="row" style="height: 100%;"> 
        
        <!-- section -->
      <div class="col-12 h-100 p-0" >

          <div class="row m-0" style="height: 8vh; ">
            <div class="col-12 shadow-1 bg-white" style="z-index: 2;">
              <div class="d-flex justify-content-center mt-3 animate__animated animate__flipInX">
                <div class="align-self-center">
                  <img id="aiLogo" class="animate__animated animate__headShake" src="../../assets/images/chatbot.png" alt=""> 
                </div>
                <h4 class="map-text-header text-center mt-1 ms-3">
                  RephraseAI</h4>
              </div>
              
            </div> 
          </div>
  
          
          <div class="row m-0" style=" height: 92vh;">


            <div class="col-4 p-0 shadow-sm h-100" 
            style="background-color: #FBF7FE; overflow-x: hidden; overflow-y: auto; scrollbar-width: thin;">
                <ul class="list-group list-group-light" style="border-radius: unset;">
                    <li class="list-group-item list-group-item-action" 
                    [ngClass]="item.id === selectedItem.id ? 'list-group-item list-group-item-action active' : 'list-group-item list-group-item-action'" 
                    *ngFor="let item of data_list"
                    (click)="selectItem(item)"
                    >
                        <div class="animate__animated animate__fadeIn">
                            <p class="fst-italic text-fix mt-2">{{item.actual}}</p>
                            <div class="d-flex justify-content-between text-fix">
                                <p class="mb-0" style="color: #EF6C00;">{{item.username}}</p>
                                <p class="mt-1 mb-0" style="font-size: 10px;">{{item.date}}</p>
                            </div>
                        </div>
                    </li>
                   
                </ul>
            </div>


            <div class="col-8 d-flex flex-column h-100 p-0" style=" background-color: #f8edf8; ">

   

              <div class="w-100
              bg-white shadow-sm animate__animated animate__fadeInRight">

                <div class="text-center pt-1">
                  <a
                      class="link"
                      (click)="basicCollapse.toggle()"
                      role="button"
                      [attr.aria-expanded]="!basicCollapse.collapsed"
                      aria-controls="enhanceMoreDiv"
                    >
                    Enhance more <i class="fas fa-caret-down"></i> 
                    </a> 
                </div>

                
                <div class="p-3" id="enhanceMoreDiv" mdbCollapse #basicCollapse="mdbCollapse">
                 <div class="row">
                  

                  <div class="col-4">
                    <div class="input-group mb-3">
                      <input type="text" class="form-control form-control-sm" placeholder="Purpose" 
                        aria-describedby="basic-addon1" />
                    </div>
                  </div>

                  <div class="col-4">

                    <ng-select  id="Type" class="header-imput w-100" 
                    placeholder="Type" [multiple]="true">
                    <ng-option *ngFor="let o of [1,2,3,4]" [value]="o">Type {{o}}</ng-option>
                  </ng-select>  
      
                  </div>


                  <div class="col-4">

                    <ng-select  id="Offering" class="header-imput w-100" 
                    placeholder="Offering" [multiple]="true">
                    <ng-option *ngFor="let o of [1,2,3,4]" [value]="o">Offering {{o}}</ng-option>
                  </ng-select>  

                   
                  </div>


                  <div class="col-4">
                    <div class="input-group mb-3">
                      <input type="text" class="form-control form-control-sm" placeholder="Location"
                        aria-describedby="basic-addon1" />
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="input-group mb-3">
                      <input type="text" class="form-control form-control-sm" placeholder="Topic" 
                        aria-describedby="basic-addon1" />
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="input-group mb-3">
                      <input type="text" class="form-control form-control-sm" placeholder="Designation levels"
                        aria-describedby="basic-addon1" />
                    </div>
                  </div>

                  
                 </div>
                </div>


                <div class="input-group pe-3 ps-3 pb-3">

                  <input class="form-control bg-light" style="height: 40px; "
                  placeholder="Provide subject to rephrase" aria-describedby="button-addon2"
                  >
                <!-- </textarea> -->

                  <button class="btn btn-sm btn-warning" type="button"
                  (click)="searched()"
                  id="button-addon2" mdbRipple rippleColor="danger" >
                    <svg style="rotate: 90;" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" 
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" 
                    class="feather feather-send"><line x1="22" y1="2" x2="11" y2="13"></line>
                    <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon></svg> 
                  </button>
                 

                </div>
              </div>
              

              <div class="m-3 animate__animated animate__fadeInUp">

                <h6 class="p-3"><i class="fas fa-user-edit"></i>  Subject</h6>
                <div class="card mb-3">
                  <div class="card-body p-3">
                    <p class="mb-0 mt-1 text-primary">
                      
                      <strong> {{selectedItem.actual}}</strong></p>
                  </div> 
                </div>

                <h6 class="p-3 animate__animated animate__fadeInUp"><i class="far fa-list-alt"></i> Suggestions</h6> 

                  <div class="mb-3 animate__animated animate__fadeInUp" *ngFor="let variant of selectedItem.variants; index as i;"> 
                    <div class="d-flex"> 
                      <div>
                        <h6 class="p-3 mt-2">
                          {{i + 1}} 
                        </h6>
                      </div>
                      <div class="card w-100">
                        <div class="card-body p-3 d-flex justify-content-between">
                          <p class="mb-0 mt-1 text-primary">
                            <strong> {{variant.value}}</strong></p>
                           
                            <div>
                              <button type="button" class="btn btn-sm btn-light" 
                              style="box-shadow: unset;"><i class="far fa-copy"></i></button>
                            </div>
                        </div> 
                      </div>
                       
                    </div> 


                </div>
               


      
              </div>
            


     
          

            </div>


          </div>
          
  
      </div> 


    </div>
      <!-- Main row  end-->
  
  </div>
  