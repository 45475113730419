import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AlertNotificationsComponent } from 'src/app/Alerts/alert-notifications/alert-notifications.component';
import { MapCommon } from 'src/app/app-providers/map-base/MapCommon';
import { ClientsResponseModel, ImportDncResponseModel } from '../dnc-models/dnc-model';
import { DncViewModel } from '../dnc-models/dnc-view-model';

@Component({
  selector: 'app-import-dnc',
  templateUrl: './import-dnc.component.html',
  styleUrls: ['./import-dnc.component.scss']
})
export class ImportDncComponent implements OnInit {

  selectedType: number = 1;
  selectedClient: number = null; 
  hiddenEvent: any;
  currentUser: number;
  importDncUploadInProgress: boolean = false;
  showImportSccuess: boolean = false;

  clientResponseModel = new BehaviorSubject<ClientsResponseModel>(
    new ClientsResponseModel()
  );

  importDncResponseModel = new BehaviorSubject<ImportDncResponseModel>(
    new ImportDncResponseModel()
);
  
  constructor(private vm: DncViewModel) { }

  ngOnInit(): void {
    this.currentUser = MapCommon.getloggedInUser().id;
    this.vm.parentImportDncComponent = this;
    this.vm.subscribeClientsVariable(this.clientResponseModel);
    this.vm.subscribeToImportVariable(this.importDncResponseModel);
    this.vm.getAllClients('import-dnc');

    // this.showImportSccuess = true;
    // this.importDncResponseModel.next(new ImportDncResponseModel);
    // this.importDncResponseModel.value.failed = ["Chandan.dandapat@gmail.com", ]
    // this.importDncResponseModel.value.totol_count = 2
    // this.importDncResponseModel.value.failed_count = 1
    // this.importDncResponseModel.value.success_count = 1
    

  }

  onSelectedImportType(){
    if(this.selectedType == 2){
      this.selectedClient = null;
    }
  }

  onSelectedClient(){
  }

  clientsReceived(){
    // Do nothing
  }

  uploadListener($event: any){
    this.hiddenEvent = Object.assign({}, $event.target.files);
  }


  onFileSelected(event): void{
    const file:File = event.target.files[0];
    if (file) {
      var size = 1 * 1024 * 1024
      if (file.size > size){
        AlertNotificationsComponent.alertCall.showAlert("Please upload less than 1MB file", 'danger');
      } else {
        this.importDncUploadInProgress = true;
        this.vm.parentImportDncComponent = this;
        this.vm.importDnc(file, this.currentUser, this.selectedType, this.selectedClient);
      }
      
    }
  }


  clearAfterUpload(success: boolean): void{ 
    if(success){
      this.showImportSccuess = true;
    }
    this.importDncUploadInProgress = false;
  
  }

}
