import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Observer } from "rxjs";
import {
    IApiServiceDelegate,
    MapAPIService,
    MapAPICollection,
} from "../../app-providers/services/map-module";
import { AlertNotificationsComponent } from "src/app/Alerts/alert-notifications/alert-notifications.component";
import { LinkTrackingAnalyticsComponent } from "../link-tracking-analytics/link-tracking-analytics.component";
import { AnalyticsCampaignResponseModel, AnalyticsClientResponseModel, AnalyticsUserResponseModel, BestSendingTimeModel, LinkTrackingResponseModel, OpenTrackingResponseModel, RegionResponseModel ,SubjectListResponseModel } from "./model";
import { CampaignResponseModel } from "src/app/prospects/model/prospect-model";
import { BestSendingTimeComponent } from "../best-sending-time/best-sending-time.component";
import { OpenTrackingAnalyticsComponent } from "../open-tracking-analytics/open-tracking-analytics.component";
import { SubjectLineComponent } from "../subject-line/subject-line.component";

@Injectable({
    providedIn: "root",
})

export class AnalyticsViewModel {

    parentLinkTrackingComponent: LinkTrackingAnalyticsComponent;
    parentBestSendingTimeComponent: BestSendingTimeComponent;
    parentOpenTrackingAnalyticsComponent: OpenTrackingAnalyticsComponent;
    SubjectLineComponentRef: SubjectLineComponent;

    linkTrackingResponseModel = new BehaviorSubject<LinkTrackingResponseModel>(
        new LinkTrackingResponseModel()
    );

    openTrackingResponseModel = new BehaviorSubject<OpenTrackingResponseModel>(
        new OpenTrackingResponseModel()
    );

    clientResponseModel = new BehaviorSubject<AnalyticsClientResponseModel>(
        new AnalyticsClientResponseModel()
    );

    campaignResponseModel = new BehaviorSubject<AnalyticsCampaignResponseModel>(
        new AnalyticsCampaignResponseModel()
    );

    userResponseModel = new BehaviorSubject<AnalyticsUserResponseModel>(
        new AnalyticsUserResponseModel()
    );

    bestSendingTimeModel = new BehaviorSubject<BestSendingTimeModel>(
        new BestSendingTimeModel()
    );

    regionResponseModel = new BehaviorSubject<RegionResponseModel>(
        new RegionResponseModel()
      );
    subjectListResponseModel = new BehaviorSubject<SubjectListResponseModel>(
        new SubjectListResponseModel()
      );

    constructor(private apiService: MapAPIService) { }

    subscribeAllLinkTrackingVariables(
        observerClientResponseModel: Observer<AnalyticsClientResponseModel>,
        observerLinkTrackingResponseModel: Observer<LinkTrackingResponseModel>,
        observerCampaignResponseModel: Observer<AnalyticsCampaignResponseModel>
    ) {
        this.clientResponseModel.subscribe(observerClientResponseModel);
        this.linkTrackingResponseModel.subscribe(observerLinkTrackingResponseModel);
        this.campaignResponseModel.subscribe(observerCampaignResponseModel);
    }

    subscribeAllOpenTrackingVariables(
        observerOpenTrackingResponseModel: Observer<OpenTrackingResponseModel>,
        observerCampaignResponseModel: Observer<AnalyticsCampaignResponseModel>
    ) {
        this.openTrackingResponseModel.subscribe(observerOpenTrackingResponseModel);
        this.campaignResponseModel.subscribe(observerCampaignResponseModel);
    }

    subscribeAllBestSendingVariables(
        observerClientResponseModel: Observer<AnalyticsClientResponseModel>,
        observerCampaignResponseModel: Observer<AnalyticsCampaignResponseModel>,
        observerUserResponseModel: Observer<AnalyticsUserResponseModel>,
        observerBestSendingTimeModel: Observer<BestSendingTimeModel>,
        observerRegionResponseModel: Observer<RegionResponseModel>
        ){
        this.clientResponseModel.subscribe(observerClientResponseModel);
        this.campaignResponseModel.subscribe(observerCampaignResponseModel);
        this.userResponseModel.subscribe(observerUserResponseModel);
        this.bestSendingTimeModel.subscribe(observerBestSendingTimeModel);
        this.regionResponseModel.subscribe(observerRegionResponseModel);
    }

    subscribeSubjectLine(
      observerSubjectResponseModel: Observer<SubjectListResponseModel>,
      observerRegions: Observer<RegionResponseModel>,
      observerClient:Observer<AnalyticsClientResponseModel>
      ) {
    this.subjectListResponseModel.subscribe(observerSubjectResponseModel);
    this.regionResponseModel.subscribe(observerRegions);
    this.clientResponseModel.subscribe(observerClient);
  }


    getRegions(){
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_REGIONS,
            Success: (e: any) => {
                this.regionResponseModel.next(e);
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading regions', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    getAllLinkTracking(page_index, search_by_email, search_by_link, search_by_client,
        search_by_campaign, search_by_step, search_by_date) {
        var url =  MapAPICollection.GET_ANALYTICS_LINK_TRACKING + "?page_index=" + page_index + "&page_size=30"
        if (search_by_email.length > 0) {
            url = url + "&email_address=" + String(search_by_email)
        }
        if (search_by_link.length > 0) {
            url = url + "&link=" + String(search_by_link)
        }
        if (search_by_client){
            url = url + "&client=" + String(search_by_client)
        }
        if (search_by_campaign) {
            url = url + "&campaign=" + String(search_by_campaign)
        }
        if (search_by_step) {
            url = url + "&step=" + String(search_by_step)
        }
        if (search_by_date){
            url = url + "&created_at=" + String(search_by_date)
        }
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: url,
            Success: (e: any) => {
                this.linkTrackingResponseModel.next(e);
                this.parentLinkTrackingComponent.linkTrackingReceived();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading link tracking info', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }
    getAllOpenTracking(search_by_campaign, search_by_email,
        search_by_date_start, search_by_date_end,page_index,) {
        var url =  MapAPICollection.GET_ANALYTICS_OPEN_TRACKING + "?page_index=" + page_index + "&page_size=50"
        if (search_by_email.length > 0) {
            url = url + "&email_address=" + String(search_by_email)
        }

        if (search_by_campaign) {
            url = url + "&campaign=" + String(search_by_campaign)
        }
        if (search_by_date_end) {
            url = url + "&end_date=" + String(search_by_date_end)
        }
        if (search_by_date_start){
            url = url + "&start_date=" + String(search_by_date_start)
        }
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: url,
            Success: (e: any) => {
                this.openTrackingResponseModel.next(e);
                this.parentOpenTrackingAnalyticsComponent.openTrackingReceived();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading open tracking info', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    getBestSendingTime(createdById=null, campaignId=null, clientId=null,
        search_start_date = null, search_end_date = null, regionId=null, reportTimezoneModel) {
        var str = "?time_zone=" + reportTimezoneModel;
        if(search_start_date && search_end_date){
            str = str + "&start_date=" + search_start_date + "&end_date=" + search_end_date
        }
        if(clientId){
            str = str + "&client=" + clientId
        }
        if(campaignId){
            str = str + "&campaign=" + campaignId
        }
        if(createdById){
            str = str + "&user=" + createdById
        }
        if(regionId){
            str = str + "&region=" + regionId
        }
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_BEST_SENDING_TIME + str,
            Success: (e: any) => {
                this.bestSendingTimeModel.next(e);
                this.parentBestSendingTimeComponent.BestSendingTimeReceived();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading best sending time', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    // Method to all prospect
    getAllCampaign(seachStr, caller) {
    var str = "?&name__icontains=" + seachStr + "&page=1&page_size=10";

    let d: IApiServiceDelegate = {
        Data: {},
        Method: "GET",
        Action: MapAPICollection.GET_CAMPAIGN + str,
        Success: (e: any) => {
            this.campaignResponseModel.next(e);
            if (caller == 'link-tracking'){
                this.parentLinkTrackingComponent.CampaignReceived();
            }
            if (caller == 'open-tracking'){
                this.parentOpenTrackingAnalyticsComponent.CampaignReceived();
            }
            if(caller == 'best-sending-time'){
                this.parentBestSendingTimeComponent.CampaignReceived();
            }
            return e;
        },
        Error: (e: String) => {
            AlertNotificationsComponent.alertCall.showAlert('Error while loading prospects', 'danger');
            return e;
        },
    };
    this.apiService.callService(d);
}
    getAllClients(caller) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_CLIENTS + "?page_size=1000",
            Success: (e: any) => {
                this.clientResponseModel.next(e);
                if(caller === 'link-tracking'){
                    this.parentLinkTrackingComponent.ClientRecieved();
                }
                if(caller === 'best-sending-time'){
                    this.parentBestSendingTimeComponent.ClientRecieved();
                }
                if(caller === 'subject-line'){
                    this.SubjectLineComponentRef.ClientRecieved();
                }

                return e;

            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading Clients', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }


    getAllUsers() {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_ALL_USERS + '?page=1&page_size=250',
            Success: (e: any) => {
                this.userResponseModel.next(e);
                this.parentBestSendingTimeComponent.userRecieved();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading users', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    getSubjects(query, region, page_index,client,search_start_date,search_end_date,ordering){
        var url = MapAPICollection.GET_ALL_SUBJECT + "?&page=" + page_index + "&page_size=1000";
        if(query.length > 0){
            url += "&subject__icontains=" + encodeURIComponent(query);
        };
        if(region){
            url += "&region=" + region;
        };
        if(client?.length > 0){
          url += "&client=" + client.join(',');
      };
      if(search_start_date?.length > 0 && search_end_date?.length > 0){
        url = url + "&start_date=" + search_start_date + "&end_date=" + search_end_date
       }
      if(ordering){
        url= url+'&ordering='+ordering
      }
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: url,
            Success: (e: any) => {
                    this.subjectListResponseModel.next(e)
                    this.SubjectLineComponentRef.getSubjectList(e)

                // AlertNotificationsComponent.alertCall.showAlert('attached file deleted', 'primary');
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while fetching template list', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
  }


}
