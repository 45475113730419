import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({ name: 'noSanitizeUrl' })
export class NoSanitizeUrlPipe implements PipeTransform {
  // url: SafeResourceUrl;
  constructor(private domSanitizer: DomSanitizer) { }

  transform(url: string): SafeUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    // console.log(this.url.toString())
    //  this.url
  }
}