export class ProspectModel {
    "id": number
    "firstname": string
    "middlename": string
    "lastname": string
    "email_address": string
    "slug": string

    // "2number21-number6-number2Tnumber7:51:number9.5number3Z"
    "modified_at": Date
    "company": string
    "address": string
    "city": string
    "state": string
    "region": string
    "country": string
    "phone": string
    "job_title": string
    "industry": string
    "linkedin": string
    "cnt_campaign": number
    "snippet": string
    "snippet1": string
    "snippet2": string
    "snippet3": string
    "snippet4": string
    // "2number21-number6-number2Tnumber7:51:number9.5number3Z"
    "last_contacted": Date

    "cnt_email_sent": number
    "cnt_email_open": number
    "cnt_replies_received": number
    "is_opted_out": boolean
    "is_bounced": boolean
    "is_deleted": boolean
    "email_alredy_exist": boolean
    "wrong_email_pattern": boolean
    "upload_status_check": string
    "created_by": number
    "modified_by": number
    "status": number
    "prospect_group": [number]
}
export class ProspectGroupResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [ProspectGroupModel]
}
export class ProspectGroupModel {
    "id": number
    "name": string
    "slug": string
    "created_at": Date
    "import_file_url": string
    "cnt_total": number
    "cnt_duplicate": number
    "cnt_dnc": number
    "is_deleted": boolean
    "created_by": number
}
export class ProspectImportResponseModel {
    "id": number
    "name": string
    "slug": string
    "created_at": Date
    "import_file_url": string
    "cnt_total": number
    "cnt_duplicate": number
    "cnt_dnc": number
    "is_deleted": string
    "created_by": number
}

export class UserResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [UserModel]
}


export class ColumnMappingResponseModel {
    "available_fieldset": [string]
    "columns": number
    "file_url": string
    "id": number
    "original_header": [string]
    "rows": number
    "sample_data": any
    "mapped_header":any
}

export class UserModel {
    "id": number
    "username": string
    "first_name": string
    "last_name": string
    "email": string
}
