import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ClientsViewModel } from '../client-models/clientViewModel';


@Component({
  selector: 'app-client-delete',
  templateUrl: './client-delete.component.html',
  styleUrls: ['./client-delete.component.scss']
})
export class ClientDeleteComponent implements OnInit {

  deleteClientId: number;
  deleteClientName: string;

  constructor(public modalRef: MdbModalRef<ClientDeleteComponent>, private vm: ClientsViewModel) {}

  ngOnInit(): void {
  }

  deleteClient() {
    this.vm.deleteClient(this.deleteClientId, this.deleteClientName);
    this.modalRef.close();
  }

}
