import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { AlertNotificationsComponent } from 'src/app/Alerts/alert-notifications/alert-notifications.component';
import { MapCommon } from 'src/app/app-providers/map-base/MapCommon';
import { SettingViewModel } from '../../setting-view-model/setting-view-model';

@Component({
  selector: 'app-add-company-dnc',
  templateUrl: './add-company-dnc.component.html',
  styleUrls: ['./add-company-dnc.component.scss']
})
export class AddCompanyDncComponent implements OnInit {

  emailDomainModel = '';
  reasonForDncModel = '';
  currentUser: number;

  constructor(public modalRef: MdbModalRef<AddCompanyDncComponent>, private vm: SettingViewModel) {
    this.currentUser = MapCommon.getloggedInUser().id;
  }

  ngOnInit(): void {
  }

  createCompanyDnc() {
    if (this.emailDomainModel === '' || !this.emailDomainModel.includes('.') || this.emailDomainModel.includes('@') ) {
      AlertNotificationsComponent.alertCall.showAlert("Email domain is not valid", 'danger');
    }
    else if (this.reasonForDncModel === '' || this.reasonForDncModel.length <= 4 ) {
      AlertNotificationsComponent.alertCall.showAlert("Reason is mandatory, minimum 5 is required", 'danger');
    } else {
      this.vm.addCompanyDNC(this.emailDomainModel, this.reasonForDncModel, this.currentUser);
      this.modalRef.close()
    }
  }

}
