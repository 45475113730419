<div class="modal-header">
  <h5 class="modal-title" id="timepickermodal">Select Time</h5>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="closeModal()"
  ></button>
</div>
<div class="modal-body">

  <div class="row">
      <div class="col-6">
          <p class="m-0">Start Time</p>
          <select [(ngModel)]="startSelectedModel" class="form-select" aria-label="Start Time">
              <option *ngFor="let opt of TimeSelections" value="{{opt.time}}">{{opt.time_display}}</option>
          </select>
      </div>

      <div class="col-6">
          <p class="m-0">End Time</p>
          <select [(ngModel)]="endSelectedModel" class="form-select" aria-label="End Time">
              <option *ngFor="let opt of TimeSelections" value="{{opt.time}}">{{opt.time_display}}</option>
          </select>
      </div>
  </div> 

  <ng-container *ngIf="!bothOptSelected">
     <p style="color: #EF6C00" class="text-center pt-2">{{errorText}}</p>
  </ng-container>
    

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-link" (click)="closeModal()">
    Close
  </button>
  <button type="button" (click)="SaveTime()" class="btn btn-primary">Save</button>
</div>
