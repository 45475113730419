<!-- First Row -->
<app-loading-dialog *ngIf="isLoading">

</app-loading-dialog>

<!-- <div class="col-12 pe-3 ps-3">
  <p class="text-end"> Last fetched at {{ timeFetched | date :'shortTime' }} <i class=" px-2 fas fa-redo-alt text-danger" style="cursor:pointer;" (click)="fetchData()"></i> </p>
 </div> -->

<div class="col-12 pe-4 ps-3">
  <div class="d-flex justify-content-between summary-details">
    <div class="col-2 me-1 shadow-1 pt-3">
      <p class="text-primary"><strong>Total Emails</strong></p>
      <p class="summary-number">{{cnt_total}}<br /><span class="text-muted">100.00%</span></p>
    </div>

    <div class="col-7 shadow-1 me-1 pt-3" style="display: flex;">
      <div class="col-2">
        <p class="text-primary"><strong>Sent</strong></p>
        <p class="summary-number">{{cnt_sent}}<br /><span class="text-muted">{{cnt_total == 0 ? 0 :
            ((cnt_sent/cnt_total)*100).toFixed(2)}}%</span></p>
      </div>
      <div class="col-2">
        <p class="text-primary"><strong>Reached</strong></p>
        <p class="summary-number">{{cnt_reached}}<br /><span class="text-muted">{{cnt_sent == 0 ? 0 :
            ((cnt_reached/cnt_sent)*100).toFixed(2)}}%</span></p>
      </div>
      <div class="col">
        <p class="text-primary"><strong>Opened</strong></p>
        <p class="summary-number">{{cnt_opened}}<br /><span class="text-muted">{{cnt_sent == 0 ? 0 :
            ((cnt_opened/cnt_sent)*100).toFixed(2)}}%</span></p>
      </div>
      <div class="col">
        <p class="text-primary"><strong>Replied</strong></p>
        <p class="summary-number">{{cnt_replied}}<br /><span class="text-muted">{{cnt_sent == 0 ? 0 :
            ((cnt_replied/cnt_sent)*100).toFixed(2)}}%</span></p>
      </div>
      <div class="col">
        <p class="text-primary"><strong>Bounced</strong></p>
        <p class="summary-number">{{cnt_bounced}}<br /><span class="text-muted">{{cnt_sent == 0 ? 0 :
            ((cnt_bounced/cnt_sent)*100).toFixed(2)}}%</span></p>
      </div>
      <div class="col me-1">
        <p class="text-primary"><strong>Opted Out</strong></p>
        <p class="summary-number">{{cnt_opted_out}}<br /><span class="text-muted">{{cnt_sent == 0 ? 0 :
            ((cnt_opted_out/cnt_sent)*100).toFixed(2)}}%</span></p>
      </div>
    </div>

    <div class="col-3 shadow-1 pt-3" style="display: flex;">
      <div class="col">
        <p class="text-primary"><strong>CLV Rejections</strong></p>
        <p class="summary-number">{{cnt_clv_rejection}}<br /><span class="text-muted">{{cnt_sent == 0 ? 0 :
            ((cnt_clv_rejection/cnt_sent)*100).toFixed(2)}}%</span></p>
      </div>

      <div class="col">
        <p class="text-primary"><strong>DNC Rejections</strong></p>
        <p class="summary-number">{{cnt_dnc_rejection}}<br /><span class="text-muted">{{cnt_sent == 0 ? 0 :
            ((cnt_dnc_rejection/cnt_sent)*100).toFixed(2)}}%</span></p>
      </div>
    </div>


  </div>
</div>




<div class="col-12 p-3 " *ngIf="showExistingSteps">


  <div class="row m-0 p-0 bg-white pt-1 pb-1 shadow-1 step-header-items">
    <div class="col-8 d-flex justify-content-between ">

      <div class="mt-1 d-flex justify-content-between">
        <div *ngIf="sendingWindows && scheduleTimezone" (click)="showSendingWindow(sendingWindows)"
        style="cursor: pointer;">
        <i class="far fa-clock"></i>
        {{sendingWindows.name}} - <span style="color: #ef0065;"> {{scheduleTimezone.name}}</span>
      </div>

      <div *ngIf="!sendingWindows && campaignSendMode === 'Manual'" >
        <i class="fas fa-cog"></i> Campaign Mode: <span style="color: #ef0065">Manual</span>
      </div>

      <div *ngIf="!sendingWindows && campaignSendMode === 'Auto'" >
        <i class="fas fa-cog"></i> Campaign Mode: <span style="color: #ef0065">Auto</span>
      </div>

      <div>
        <p class="p-0 ms-3" style="font-size: 12px; margin-bottom: unset;">{{campaign_reason}} </p>
      </div>

      <!-- <div *ngIf="campaignSendMode === 'Auto' && sendingWindows">
        <p class="p-0  ms-3" style="font-size: 12px; margin-bottom: unset;"></p>
      </div> -->
      </div>

      <button type="button" (click)="AddStepPopup()" class="btn btn-sm btn-link me-1">
        <i class="fas fa-plus"></i> Add step</button>

    </div>
    <div class="col-4">
      <div class="row mt-1">
        <div class="col-3 m-0">
          <p class="text-muted m-0">Sent</p>
        </div>
        <div class="col-3 m-0">
          <p class="text-muted m-0">Opened</p>
        </div>
        <div class="col-3 m-0">
          <p class="text-muted m-0">Replied</p>
        </div>
        <div class="col-3 m-0">
          <p class="text-muted m-0" style="white-space: nowrap;">Opted Out</p>
        </div>
      </div>
    </div>
  </div>

  <ng-template #tmplNode let-node="node">
    <div class="node-item" [attr.data-id]="node.id" [attr.id]="'node-'+node.id">



      <div [class]="(node.status == 'Rejected') ? 'row m-0 p-0 bg-white mt-2 shadow-1 rejected-step': 'row m-0 p-0 bg-white mt-2 shadow-1'">
        <div class="col-1 d-flex justify-content-between">
          <p (click)="node.isExpanded=!node.isExpanded" style="cursor: pointer;"
          [class]="(node.status == 'Rejected') ? 'step-status-text m-0 pt-3 text-danger': 'step-status-text m-0 pt-3'"
          placement="right"
          mdbTooltip="{{(node.status == 'Rejected') ?
          ('Step rejected. Keep less than 250 prospects per step/variant'): ('Step status is ' + node.status)}}"
          >
            <!-- {{node.children.length ? (node.isExpanded?'-&nbsp;':'+') : '&nbsp;&nbsp;&nbsp;'}}  -->

            <i *ngIf="(node.children?.length > 0 && node.isExpanded) && !(node.status == 'Rejected')" class="fas fa-angle-down collapse-child-icon"></i>
            <i *ngIf="(node.children?.length > 0 && !node.isExpanded)  && !(node.status == 'Rejected')" class="fas fa-angle-up collapse-child-icon"></i>
            <i *ngIf="(node.status == 'Rejected')" class="far fa-question-circle"></i>
            {{node.status === 'Generating Variants' ? 'Auto': node.status}}


          </p>

            <p *ngIf="!node.followup_from" class="p-0 m-0 mt-3" mdbTooltip="This is a fresh email"><span>
              <i  *ngIf="!(node.status === 'Processing')" class="fas fa-envelope"></i>
              <i  *ngIf="node.status === 'Processing'" class="fas fa-spinner fa-spin"></i>
            </span></p>
            <p *ngIf="node.followup_from" class="p-0 m-0 mt-3" mdbTooltip="This is a follow-up email"><span>
              <i  *ngIf="!(node.status === 'Processing')" class="far fa-share-square"></i>
              <i  *ngIf="node.status === 'Processing'" class="fas fa-spinner fa-spin"></i>
            </span></p>
 

        </div>

        <div class="col-7 d-flex justify-content-between">

          <div class="col-5">
            <p class="text-primary step-subject mt-2" style="cursor: pointer;" (click)="EditStepPopup(node)">{{node.subject}}
            </p>
            <p *ngIf="node.msg_seq_id" class="step-msg-id">{{node.msg_seq_id}}</p>
            <p *ngIf="node.step_media" class="step-attachment mb-1" >
              <a href="{{getDownloadUrl(node.step_media_details.media_file)}}" mdbTooltip="Download File">
                <i class="fas fa-paperclip"></i> {{node.step_media_details.file_name}}</a>
            </p>
          </div>



          <div class="col-7">


            <div class="row p-0 m-0">
              <div class="col-6 d-flex justify-content-start">
                <div *ngIf="node?.sending_rate" class="item-notes pb-1 pt-3 pe-2" mdbTooltip="Email delivery speed"> 
                <!-- <div *ngIf="node.sending_rate" class="item-notes pb-1 pt-3 pe-2" mdbTooltip="Email delivery speed"> -->
                  <span class="badge badge-followup"><i class="fas fa-truck"></i>
                    {{getSendingRateAsText(node.sending_rate)}}</span>

                </div>


                <div *ngIf="node.children.length >= 1" class="item-notes mt-3 pe-2" mdbTooltip="Total Follow-up">
                  <span class="badge badge-followup">
                    <i class="far fa-share-square"></i> {{node.children.length}}</span>
                </div>

                <div *ngIf="node.followup_condition" class="item-notes mt-3 pe-2" mdbTooltip="Follow-up condition">
                  <span class="badge badge-followup"><i class="fas fa-user-tag"></i>
                    {{getConditionAsText(node.followup_condition)}}</span>
                </div>


                <div *ngIf="node.wait_minutes" class="item-notes mt-3 pe-2" style="cursor: pointer;"
                mdbTooltip="Send After" (click)="sendAfter(node)">
                  <span class="badge badge-followup"> <i class="fas fa-history"></i>
                    {{getSendAfterText(node.wait_minutes)}}</span>
                </div>

                <div *ngIf="node.followup_interval_days" class="item-notes mt-3 pe-2" mdbTooltip="Follow-up interval days">
                  <span
                  class="badge bg-warning badge-followup badge-followup-interval-days"><i class="far fa-clock"></i>
                   {{node.followup_interval_days}}</span>

                </div>

                <div *ngIf="node.status === 'Generating Variants'" class="item-notes mt-3 pe-2" mdbTooltip="Generating Variants">
                  <span
                  class="badge bg-warning ">
                  <i  class="fas fa-spinner fa-spin"></i>
                  Generating Variants</span>

                </div>


                

              </div>

              <div class="col-6 d-flex justify-content-end">

                <div class="" style="margin-top: 12px;">
                  <span *ngIf="(node.status === 'New' || node.status === 'Scheduled')" mdbTooltip="Review emails"
                  class="btn btn-outline-warning btn-sm btn-floating" (click)="reviewStep(node.id)"><i class="far fa-eye"></i></span>

                  <span *ngIf="node.status === 'Processing'" mdbTooltip="Pause emails"
                  class="btn btn-outline-danger btn-sm ms-2 btn-floating" (click)="pauseResumeCancelStep(node.id, 'pause')">
                    <i class="far fa-pause-circle"></i></span>

                  <span *ngIf="(node.status === 'Paused' || node.status === 'Pause') && service_error_paused === false " mdbTooltip="Resume emails"
                  class="btn btn-outline-success btn-sm ms-2 btn-floating" (click)="pauseResumeCancelStep(node.id, 'resume')">
                    <i class="far fa-play-circle"></i></span>
                </div>

              </div>

            </div>

          </div>
        </div>

        <div class="col-4">
          <div class="row">
            <div class="col-3">
              <p class="stepStatpercentage pt-3">{{node.step_stats.emails_sent.total}}/{{node.step_stats.total_emails}}</p>
            </div>
            <div class="col-3">
              <p class="stepStatpercentage pt-3">{{node.step_stats.emails_sent.total == 0 ? 0 :
                ((node.step_stats.emails_sent.reached.opened/node.step_stats.emails_sent.total)*100).toFixed(2)}}%</p>
            </div>
            <div class="col-3">
              <p class="stepStatpercentage pt-3">{{node.step_stats.emails_sent.total == 0 ? 0 :
                ((
                  (node.step_stats.emails_sent.reached.replied.total + node.step_stats.emails_sent.reached.replied.auto_reply.total)
                /node.step_stats.emails_sent.total)*100).toFixed(2)}}%

              </p>
            </div>
            <div class="col-3 d-flex justify-content-between">
              <p class="stepStatpercentage pt-3">{{node.step_stats.emails_sent.total == 0 ? 0 :
                ((node.step_stats.emails_sent.reached.opted_out/node.step_stats.emails_sent.total)*100).toFixed(2)}}%
              </p>

              <div mdbDropdown class="dropdown dropstart pt-2" >
                <button class="btn btn-link btn-sm " type="button" id="dropdownMenuButton-{{node.id}}"
                  aria-expanded="false" mdbDropdownToggle>
                  <i class="fas fa-ellipsis-v"></i>
                </button>
                <ul mdbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownMenuButton-{{node.id}}">
                  <li class="stepMoreItem" *ngIf="!(node.status === 'Finished')">
                    <a (click)="abTest(node)" class="dropdown-item">
                      <i class="fas fa-vials"></i> A/B Test</a>
                  </li>

                  <li class="stepMoreItem" *ngIf="(node.status === 'New')">
                    <a (click)="generateAB(node) " class="dropdown-item">
                      <i class="fab fa-stack-exchange"></i> Generate A/Bs</a>
                  </li>

                  <li class="stepMoreItem" *ngIf="(node.status === 'Finished')">
                    <a (click)="followUp(node)" class="dropdown-item">
                      <i class="far fa-share-square"></i> Follow Up</a>
                  </li>
                  <li class="stepMoreItem"><a (click)="sendTestEmail(node.id)" class="dropdown-item">
                      <i class="fas fa-paper-plane"></i> Send Test</a>
                  </li> 

                  <li class="stepMoreItem" *ngIf="(node.status === 'New') && !sendingWindows && (campaignSendMode === 'Auto')">
                    <a (click)="sendAfter(node) " class="dropdown-item">
                      <i class="fas fa-history"></i> Send After</a>
                  </li>

                  <hr class="dropdown-divider p-0 m-0" />

                  <li class="stepMoreItem">
                    <a (click)="CloneStep(node)" class="dropdown-item text-warning">                    
                    <i class="fas fa-copy"></i> Clone</a>
                  </li>

                  <!-- <li *ngIf="!(node.status === 'Finished' || node.status === 'Processing')">
                    <hr class="dropdown-divider p-0 m-0" />
                  </li> -->

                  <li class="stepMoreItem" *ngIf="!(node.status === 'Finished' || node.status === 'Processing')">
                    <a
                    (click)="ConfirmDeleteStep(node) " class="dropdown-item text-danger">
                      <i class="far fa-trash-alt"></i> Delete</a>

                  </li>


                </ul>
              </div>
            </div>
            </div>
          </div>
      </div>








      <ng-container *ngIf="node.ab_variants?.length > 0">

        <div class="row m-0 p-0 border-bottom abtestBar"
        *ngFor="let ab_variant of node.ab_variants; let count = index" (click)="abTest(node)">
          <div class="col-1">
            <p class="ab-test-text m-0 ps-2 pt-1">A/B Test</p>
          </div>
          <div class="col-7">
            <p class="text-primary step-subject pt-1">{{ab_variant.subject}}</p>
          </div>

          <ng-container *ngIf="ab_variant.stats else noABStatsFound">


            <div class="col-4">
              <div class="row">
                <div class="col-3">
                  <p class="m-0 pt-1 pb-1"><span class="ab-stats-text">{{ab_variant.stats?.emails_sent.total}}/{{ab_variant.stats?.total_emails}}</span></p>
                 </div>
                <div class="col-3">
                  <p class="m-0 pt-1 pb-1"><span class="ab-stats-text">{{ab_variant.stats?.total_emails == 0 ? 0 :
                    ((ab_variant.stats?.emails_sent.reached.opened/ab_variant.stats?.total_emails)*100).toFixed(2)}}%</span></p>
                </div>
                <div class="col-3">
                  <p class="m-0 pt-1 pb-1"><span class="ab-stats-text">{{ab_variant.stats?.total_emails == 0 ? 0 :
                    ((
                      (ab_variant.stats?.emails_sent.reached.replied.total +
                      ab_variant.stats?.emails_sent.reached.replied.auto_reply.total)
                    /ab_variant.stats?.total_emails)*100).toFixed(2)}}%</span> </p>

                </div>
                <div class="col-3">
                  <p class="m-0 pt-1 pb-1"><span class="ab-stats-text">{{ab_variant.stats?.total_emails == 0 ? 0 :
                    ((ab_variant.stats?.emails_sent.reached.opted_out/ab_variant.stats?.total_emails)*100).toFixed(2)}}%</span></p>
                </div>
              </div>
            </div>

          </ng-container>
          <ng-template #noABStatsFound>
            <div class="col-4">
              <div class="row">
                <div class="col-3">
                  <p class="m-0 pt-1 pb-1"><span class="ab-stats-text">0/0</span></p>
                </div>
                <div class="col-3">
                  <p class="m-0 pt-1 pb-1"><span class="ab-stats-text">0.00%</span></p>
                </div>
                <div class="col-3">
                  <p class="m-0 pt-1 pb-1"><span class="ab-stats-text">0.00%</span></p>
                </div>
                <div class="col-3">
                  <p class="m-0 pt-1 pb-1"><span class="ab-stats-text">0.00%</span></p>
                </div>
              </div>
            </div>

          </ng-template>

        </div>




      </ng-container>





      <div *ngIf="node.isExpanded && node.children.length" class="node-children" [id]="node.id">

        <div *ngFor="let child of node.children">
          <ng-container *ngTemplateOutlet="tmplNode,context:{node:child}"></ng-container>
        </div>

      </div>

    </div>
  </ng-template>

  <div [id]="'main'">

    <div *ngFor="let node of campaignStepsTree">

      <ng-container *ngTemplateOutlet="tmplNode,context:{node:node}"></ng-container>


    </div>
  </div>


</div>






<div class="col-12" *ngIf="!showExistingSteps">
  <div class="row add-step me-3 ms-3 shadow-1 bg-white mt-3 p-3">
    <div class="col-12">
      <span><i style="color:#ef0065" class="fas fa-volume-up fa-3x add-step-icon"></i></span>
      <p class="add-step-text">Add your first Step! <br />
        <span>Click the button below to add an email, call, or task.</span>
      </p>
      <button type="button" class="btn btn-primary" (click)="AddStepPopup()">+ ADD STEP</button>
    </div>
  </div>
</div>



<div class="row mt-5">
  <div class="col-12 mt-5"></div>
  <div class="col-12 mt-5"></div>
  <div class="col-12 mt-5"></div>
  <div class="col-12 mt-5"></div>
</div>

<!-- <div id="GeneratingVariantBlank" style="display: none;"></div> -->
