import { Component, OnInit } from '@angular/core';
import { LoginViewModel } from '../models/login-view-model';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {

  userEmail: string = ''
  showError: boolean = false;
  showSuccess: boolean = false;
  showSpinner: boolean = false;

  constructor(private vm: LoginViewModel) { }

  ngOnInit(): void {
  }

  resetMyPassword(): void{
    if(this.userEmail.length > 0){
      this.showSpinner = true;
      this.vm.parentPasswordResetComponent = this;
      this.vm.resetPassword(this.userEmail);
    } else {
      this.showError = true;
    }
  }

  passwordResetSuccess(): void{
    this.showSuccess = true;
    this.showError = false;
  }

}
