<div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Update Client DNC</h5>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="cancelWarning()"
    ></button>
  </div>
  <div class="modal-body">
    
    
    <div class="col-12 text-center">
      <button type="button" [ngClass]="[removeFromDncWarningCss]" (click)="removeFromDncWarning()">Remove From DNC</button>
    </div>

    <ng-container *ngIf="userDeleteWarning">
      <div class="col-12 pt-3 text-center">
      <p>Are you sure want to delete?</p>
      <button type="button" (click)="cancelWarning()" class="btn btn-link ms-auto me-auto">Cancel</button>
      <button type="button" (click)="removeFromDncConfirmed()" class="btn btn-link ms-auto me-auto">Confirm</button>
    </div>
    </ng-container>
 

    <div class="row pt-3">
      <div class="col-3 pt-1"><label for="emailAddress" class="form-label">Email: </label></div>
      <div class="col-8"> 
      <input mdbInput type="email" [(ngModel)]="clientDncModel.email_address" class="form-control"  name="emailAddress"
      id="emailAddress" value="{{clientDncModel.email_address}}"> 
     </div> 
    </div>

    
    <div class="row pt-3">
      <div class="col-3 pt-1"><label for="dncReason" class="form-label">Reason For DNC: </label></div>
      <div class="col-8"> 
      <textarea type="email" mdbInput [(ngModel)]="clientDncModel.dnc_reason" class="form-control" name="dncReason"
      id="dncReason" rows="6" value="{{clientDncModel.dnc_reason}}"> </textarea>
     </div> 
    </div>


  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-link" (click)="cancelWarning()">
      Close
    </button>
    <button type="button" (click)="editClientDnc()" class="btn btn-primary">Save changes</button>
  </div>