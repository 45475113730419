export class CampaignReportModel {
    "id": number
    "name": string
    "created_by": number
    "stats": [CampaignReportStatModel]
  
}

export class CampaignReportStatModel {
    "cnt_total": number
    "cnt_clv_rejection": number
    "cnt_dnc_rejection": number
    "cnt_ooo_rejection": number
    "cnt_sent": number
    "cnt_bounced": number
    "cnt_reached": number
    "cnt_opened": number
    "cnt_replied": number
    "cnt_opted_out": 0
}



export class CampaignReportResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [CampaignReportModel]
}


export class CampaignReportUserResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [CampaignReportUserModel]
}

export class CampaignReportUserModel {
    "id": number
    "username": string
    "first_name": string
    "last_name": string
    "email": string
}

export class CampaignClientResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [CampaignClientsModel]
}
export class CampaignClientsModel {
    "id": number
    "name": string
    "slug": string
    "created_at": Date
    "modified_at": null
    "cnt_campaign": number
    "is_deleted": string
    "created_by": number
    "modified_by": number
}


export class RegionResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [RegionModel]
}

export class RegionModel {
    "id": number
    "name": string
    "queue_name": string
    "slug": string
}