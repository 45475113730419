import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { MapCommon } from 'src/app/app-providers/map-base/MapCommon';
import { CompanyDncModel } from '../../setting-model/setting-model';
import { SettingViewModel } from '../../setting-view-model/setting-view-model';


@Component({
  selector: 'app-edit-company-dnc',
  templateUrl: './edit-company-dnc.component.html',
  styleUrls: ['./edit-company-dnc.component.scss']
})
export class EditCompanyDncComponent implements OnInit {

  companyDncModel: CompanyDncModel;
  userDeleteWarning: boolean = false;
  removeFromDncWarningCss = 'btn btn-danger ms-auto me-auto';
  currentUser: number;

  constructor(public modalRef: MdbModalRef<EditCompanyDncComponent>, private vm: SettingViewModel) {
    this.currentUser = MapCommon.getloggedInUser().id;
  }

  ngOnInit(): void {
    
  }

  editCompanyDnc(){
    this.companyDncModel.modified_by = this.currentUser;
    this.companyDncModel.modified_at = new Date;
    this.vm.updateCompanyDnc(this.companyDncModel);  
    this.modalRef.close();
  }

  removeFromDncWarning(){
    this.userDeleteWarning = true; 
    this.removeFromDncWarningCss = 'btn btn-outline-light ms-auto me-auto';
    
  }

  removeFromDncConfirmed(){
    this.vm.deleteCompanyDnc(this.companyDncModel);
    this.modalRef.close();
  }

  cancelWarning(){
    this.modalRef.close();
  }

}
