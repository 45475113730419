import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rephrase-ai',
  templateUrl: './rephrase-ai.component.html',
  styleUrls: ['./rephrase-ai.component.scss']
})
export class RephraseAiComponent implements OnInit {

  data_list = [
    {
      id: 1,
      username: "arshi@cs-conferences.net",
      actual: "Network & Learn- Virtual Networking",
      date: "Apr 26, 2023, 11:02:38 AM",
      variants: [
        {id: 1, value: "Virtual Networking and Learning Opportunity"},
        {id: 2, value: "Connect and Expand Your Knowledge"},
        {id: 3, value: "Virtual Networking: Learn and Grow Together"}
      ]
    },

    {
      id: 2,
      username: "joe@commonsense-events.com",
      actual: "Virtual Networking - Invitation",
      date: "Apr 26, 2023, 11:02:38 AM",
      variants: [
        {id: 1, value: "Connecting You with Like-Minded Professionals"},
        {id: 2, value: "Virtual Networking: Meet New Professionals Online"},
        {id: 3, value: "Expand Your Network: Join Our Virtual Event"}
      ]
    },

    {
      id: 3,
      username: "arshi@cs-conferences.net",
      actual: "Virtual Roundtable on ABM for Event Marketing",
      date: "Apr 26, 2023, 11:02:38 AM",
      variants: [
        {id: 1, value: "Virtual Roundtable: ABM Event Marketing"},
        {id: 2, value: "ABM for Events Virtual Discussion"},
        {id: 3, value: "ABM and Event Marketing: Virtual Roundtable"}
      ]
    },

    {
      id: 4,
      username: "arshi@cs-conferences.net",
      actual: "Networking Dinner - Invitation",
      date: "Apr 26, 2023, 11:02:38 AM",
      variants: [
        {id: 1, value: "Connect at Networking Dinner Event"},
        {id: 2, value: "Professional Networking Dinner Invitation"},
        {id: 3, value: "Expand Your Network: Join Dinner"}
      ]
    },

    {
      id: 5,
      username: "joe@commonsense-events.com",
      actual: "Can we connect?",
      date: "Apr 26, 2023, 11:02:38 AM",
      variants: [
        {id: 1, value: "Building Connections"},
        {id: 2, value: "Reach Out to Connect"},
        {id: 3, value: "Let's network and collaborate."}
      ]
    },

    {
      id: 6,
      username: "joe@commonsense-events.com",
      actual: "Networking over coffee",
      date: "Apr 26, 2023, 11:02:38 AM",
      variants: [
        {id: 1, value: "Coffee Meeting: Building Connections"},
        {id: 2, value: "Coffee Networking Request"},
        {id: 3, value: "Coffee Meeting Invitation: Network with Me"}
      ]
    },


  ]

  selectedItem;

  constructor() {
    this.selectedItem = this.data_list[0];
  }

  ngOnInit(): void {
  }

  selectItem(item){
    this.selectedItem = this.data_list.find(function(el){return el === item})
  }

  searched(){
    var image = document.getElementById("aiLogo");
    image.style.setProperty('display', 'none');
    setTimeout(() => {
      image.style.setProperty('--animate-duration', '2s');
      image.style.setProperty('display', 'block');
    }, 1);

  }
}
