
<!-- <app-loading-dialog *ngIf="savingSpiner">
    
</app-loading-dialog> -->



<div class="ms-sm-auto" style="width: 90%;">


    <div class="row" style="height: 100%;">
  
  
  
      <div class="col-2 shadow-3" style="background: #FBF7FE; height: 100vh; overflow-x: hidden; overflow-y: auto; ">
  
        <app-sidebar-help></app-sidebar-help>
      </div>
  
  
  
  
      <div class="col-10 p-3" style="height: 100vh; overflow-x: hid den; overflow-y: auto; ">
  
          <div class="row">
              <div class="col-12 d-flex justify-content-between">
                  <h4>Give Feedback</h4> 
              </div>
              <hr class="m-0">
  
  
  
          </div>
  
     
  
      </div>
  
  
  
    </div>
  
  
  </div>