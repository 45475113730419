

<div class="ms-sm-auto" style="width: 90%;">

    
    <div class="row" style="height: 100%;">
    
    
    
        <div class="col-2 shadow-3" style="background: #FBF7FE; height: 100vh; overflow-x: hidden; overflow-y: auto; ">      
    
            <app-sidebar-dnc></app-sidebar-dnc>
        </div>
      
      
       
        <div class="col-10 p-2" style="height: 100vh; overflow-x: hidden; overflow-y: auto; ">


            <div class="row">

              <div class="col-3 d-flex">
                <h6 class="pt-1 ps-1">Client DNC 

                  <span *ngIf="totalDncItems && selectedItems === 0"
                  class="header-count">({{totalDncItems}} found) </span>
                  <span *ngIf="totalDncItems && selectedItems >= 1"
                    class="header-count">({{selectedItems}} of {{totalDncItems}}
                    selected) </span>
                
                
                </h6> 

                <button type="button" *ngIf="selectedItems > 0" (click)="exportAll()"
                  class="btn btn-link btn-sm ms-2">
                  <i  *ngIf="showingExportLoading" class="fas fa-spinner fa-spin"></i>
                  <i *ngIf="!showingExportLoading" class="fas fa-cloud-download-alt"></i>
                </button>
              </div>
              
              <div class="col-9 d-flex justify-content-between">
                <form #clientDncSearchForm="ngForm">
                  <mdb-form-control>
                    <input mdbInput type="text" id="clientDncSearch" class="form-control"  
                    [ngModel]="clientDncSearchValue" name="search" (input)="clientDncSearchSubmit($event)" />
                    <label mdbLabel class="form-label" for="clientDncSearch"><i class="far fa-envelope"></i> Search</label>
                  </mdb-form-control>
                </form>

                <ng-select style="width: 180px;"
                [(ngModel)]="selectedClient" id="selectedClientInput" (change)='onSelectedClient()'
                  class="header-imput" placeholder="Select Client">
                      <ng-option *ngFor="let item of clientResponseModel?.value.results"  
                      [value]="item.id">{{item.name}}</ng-option>
                  </ng-select>

                  <ng-select style="width: 180px;"
                  [(ngModel)]="selectedDncType" id="selectedClientInput" (change)='onDncType()'
                    class="header-imput" placeholder="DNC Type">
                        <ng-option *ngFor="let item of dncSubType"  
                        
                        [value]="item.id">{{item.name}}</ng-option>
                    </ng-select>


                    <button type="button" (click)="openAddClientDncModal()" class="btn btn-link btn-sm"> 
                      <i class="fas fa-plus"></i> Add</button>
        
              </div>

          </div>




            <div class="table-responsive" infiniteScroll [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="100" (scrolled)="onClientDncScroll()" [scrollWindow]="false">
            <table class="table table-sm table-hover mt-1 table-responsive">
                <thead style="background-color: #F6F4EE;">
                  <tr>
                    <th scope="col" style="width: 30px;">
                      <input #masterSelect mdbRadio style="cursor: pointer;" type="checkbox" [(ngModel)]="isMasterSel"
                        name="flexRadioDefault1" id="flexRadioDefault1" (change)="checkUncheckAll($event)" />
                    </th>
                    <th scope="col">Email Address</th>
                    <th scope="col">Created at</th>
                    <th scope="col">Client</th>
                    <th scope="col">Type</th>
                    <th scope="col">Reason</th>
                  </tr>
                </thead>
                <tbody> 
                  <tr *ngFor="let i of clientDncList" class="dncItem">
                    <td>
                      <input type="checkbox" [(ngModel)]="i.isSelected" name="list_name" value="{{i.id}}"
                        (change)="isAllSelected($event, i.id)" />
                    </td>
                    <td  (click)="editClientDncModal(i)" class="text-primary"><strong>{{i.email_address}}</strong></td>
                    <td  (click)="editClientDncModal(i)">{{i.created_at | date: 'medium'}}</td>
                    <td  (click)="editClientDncModal(i)">{{getClientName(i.client)}}</td>
                    <td  (click)="editClientDncModal(i)">{{i.type.name}}</td>
                    <!-- <td  (click)="editClientDncModal(i)">{{i.sponsor}}</td> -->
                    <td  (click)="editClientDncModal(i)">{{i.dnc_reason}}</td>
                  </tr> 
            
                </tbody>
              </table></div>
    
         
        
        </div>  
    
    
      
      
      </div>
       
    
      </div>