<app-loading-dialog *ngIf="isLoading">

</app-loading-dialog>



<div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Follow Up</h5>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="closeModal()"
    ></button>
  </div>
  <div class="modal-body">

    <div class="row">
      <p *ngIf="span_words.length > 0"> Spam words: <span *ngFor="let i of span_words;" class="badge bg-danger ms-1">{{i}}</span>
      </p>

      <p *ngIf="wrongVariables.length > 0">Wrong Variables: <span *ngFor="let i of wrongVariables;" class="badge bg-danger ms-2">
        {{i}}</span>
      </p>


      <div class="col">
        <span>Subject *</span>
        <mdb-form-control>
          <input disabled [(ngModel)]="followUp_subjectModel" mdbInput type="text" id="followUp_subjectModel" class="form-control" />
        </mdb-form-control>
      </div>
    </div>

    <div class="row mt-2">
     <div class="col-6">
    <span>Follow up condition *</span>
      <select [(ngModel)]="followUp_conditionModel" class="form-select" aria-label="Default select example">
        <option value="0">Select condition</option>
        <option *ngFor="let condition of followUpConditions" value="{{condition.id}}">{{condition.condition}}</option>
      </select>
     </div>
     <div *ngIf="followUp_conditionModel!=5" class="col-6">
      <span>Interval Days</span> 
      <mdb-form-control>
        <input [(ngModel)]="followUp_intervalModel" mdbInput type="number" id="followUp_intervalModel" class="form-control" />
      </mdb-form-control>
    </div>
    <!-- <div *ngif="followUp_conditionModel===2" class="col-6"> -->
    <div *ngIf="followUp_conditionModel==5" class="col-6">
      <span>Opened Count More Than *</span> 
      <mdb-form-control>
        <input  [(ngModel)]="value" mdbInput type="number" id="followUp_intervalModel" class="form-control" />
      </mdb-form-control> 
    </div>
    </div>

    <div class="form-check form-switch mt-2">
      <input
        mdbCheckbox
        class="form-check-input"
        type="checkbox"
        id="flexSwitchCheckDefault"
        [(ngModel)]="enableTemplate"
        />
      <label class="form-check-label" for="flexSwitchCheckDefault"
        >Enable Template</label
      >
    </div>


    <div *ngIf="mandatoryError" class="row">
      <div class="col text-center mt-2">
        <label mdbLabel class="form-label" style="color: red;" for="error">{{mandatoryError}}</label>
      </div>
    </div>


    <div class="row mt-2" *ngIf="enableTemplate==false">
      <div class="col">
          <editor apiKey="{{editorAPIKey}}" [init]="editormodules" (ngModelChange)="checkSpamAndVeriablesWords($event)" [(ngModel)]="followUp_bodyModel"></editor>
          <!-- (ngModelChange)="checkSpamWords()"     -->
      </div>
    </div>

    <div class="row" *ngIf="enableTemplate==true">
      <div class="col-3 fixed">
        <mdb-form-control class="mt-2 mb-2">
          <input mdbInput type="text" id="form1" [(ngModel)]="search_by_name" class="form-control form-control-sm"
              (keyup)="inputkeyUp()" (keydown)="inputKeyDown()" />
          <label mdbLabel class="form-label" for="form1">Search</label>
        </mdb-form-control>
        <!-- <div class="bg-white"> -->
          <div  class="template-items" infiniteScroll [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="10" (scrolled)="onTemplateScroll()" [scrollWindow]="false"
          #campaignContainer>

            <div class="list-group " >
              <a href="javascript:void(0)" (click)="mapTemplate(i)" *ngFor="let i of templatelistB4Search"
               class="" [class]="i.id == selectedTemplate ? 'list-group-item list-group-item-action active':
               'list-group-item list-group-item-action template-item'" aria-current="true">
               <i class="fas fa-file-image"></i>  {{i.name.length > 17 ? (i.name|slice:0:17) + '...':i.name}}
              </a>
            </div>

          </div>



<!--
          <table class="table table-sm table-hover  table-striped  table-bordered">
            <thead>
              <tr>
                  <th class="text-center" scope="col">TEMPLATE NAME</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let i of templatelistB4Search">
                <td class="text-center" (click)="mapTemplate(i)">{{i.name}}</td>
              </tr>
            </tbody>
          </table> -->
        <!-- </div>  -->

          <!-- <label for="templateModel" class="form-label">Template: </label>
          <textarea [(ngModel)]="templateModel.body" class="form-control w-60" id="templateModel" rows="15"
          value="{{templateModel.body}}"  name="html_content"> </textarea>  -->

      </div>

      <div class="col-9 border-start" style="background-color: #F3F3F3;">
          <iframe *ngIf="templateBody"
              height="100%" width="100%"  [srcdoc] = "templateBody | noSanitizeHtml"></iframe>
          <div class="preview" *ngIf="!templateBody">
            No Template selected
          </div>
      </div>

    </div>



    <!-- <div class="row">
      <div class="col-12">
        <input type="file" class="attachment-input" (change)="onFileSelected($event)" #fileUpload (click)="$event.target.value=null">
           <div class="attachment-upload">
            <button type="button" class="btn btn-primary btn-floating btn-sm mt-2 me-2" (click)="fileUpload.click()">
              <i class="fas fa-paperclip"></i>
            </button>

            <span class="badge bg-light text-dark" >{{uploadedCampaignStepAttachment?.file_name || "No attachment added yet."}} </span>

            <span class="ms-2" *ngIf="attachmentUploadInProgress">
              <i class="fas fa-spinner fa-pulse"></i>
            </span>

            <span [class]="uploadedCampaignStepAttachment ? 'ms-2 attachment-removable ': 'ms-2 attachment-hidden'"
            (click)="removeAttachment()">
             Remove <i class="fas fa-trash text-danger"></i>
            </span>
         </div>
      </div>
    </div> -->

    <div class="row mt-2">

      <div class="col-6">
        <div class="form-check">
          <input mdbCheckbox class="form-check-input" type="checkbox" value="" id="flexCheckChecked" [(ngModel)]="isSignature" [checked]="true"/>
          <label class="form-check-label" for="flexCheckChecked">
            Include Signature in email body
          </label>
        </div>
      </div>

      <div class="col-6">
        <div class="form-check">
          <input mdbCheckbox class="form-check-input" type="checkbox" value="" id="flexCheckChecked" [(ngModel)]="isOpenTracking" [checked]="true"/>
          <label class="form-check-label" for="flexCheckChecked">
            Include opt-out statement in emails
          </label>
        </div>
      </div>


      <div class="col-6">
        <div class="form-check">
          <input mdbCheckbox class="form-check-input" type="checkbox" value="" id="flexCheckChecked" [(ngModel)]="isOptOut" [checked]="true"/>
          <label class="form-check-label" for="flexCheckChecked">
            Track whether an email has been opened
          </label>
        </div>
      </div>


      <div class="col-6">
        <div class="form-check">
          <input mdbCheckbox class="form-check-input" type="checkbox" value="" id="flexCheckChecked" [(ngModel)]="isLinkTracking" [checked]="true"/>
          <label class="form-check-label" for="flexCheckChecked">
            Track whether a link has been clicked
          </label>
        </div>
      </div>

  </div>







    <div class="row">
      <div class="col text-center mt-2">
        <label mdbLabel class="form-label" style="color: red;" for="error">{{mandatoryError}}</label>
      </div>
    </div>

    <div class="row" *ngIf="ab_variants > 0">
      <div class="col">
        <label mdbLabel class="form-label" style="color: red;" for="error">
          You are about follow-up on step that has multi variants, don't forgot to update message for A/B tests</label>
      </div>
    </div>


  </div>








  <div class="modal-footer">
    <button type="button" class="btn btn-link" (click)="closeModal()">
      Close
    </button>
    <button type="button"  [disabled]="isLoading" (click)="createFollowUp()" class="btn btn-primary">Create</button>
  </div>
