import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-unsub-internal-page',
  templateUrl: './unsub-internal-page.component.html',
  styleUrls: ['./unsub-internal-page.component.scss']
})
export class UnsubInternalPageComponent implements OnInit {

  publicEmail: string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    const currentURL: string = this.route.snapshot.queryParamMap.get('email');
    this.publicEmail = currentURL.substr(currentURL.lastIndexOf('#') + 1);
  }

}
