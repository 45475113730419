import { Injectable } from "@angular/core";
import { BehaviorSubject, Observer } from "rxjs";
import { ClientsResponseModel, CompanyDncModel, CompanyDncResponseModel,  EmailSenderResponseModel,  ProspectDncModel,  ProspectDncResponseModel, ProspectGroupResponseModel,
    ProspectStatusResponseModel, ScheduleTimezoneResponseModel, SendingWindowsModel, SendingWindowsResponseModel, UserExtraModel
} from "../setting-model/setting-model";
import { SettingsStatusesComponent } from "../settings-statuses/settings-statuses.component";
import { SettingsGroupsComponent } from "../settings-groups/settings-groups.component";
import { IApiServiceDelegate, MapAPIService, MapAPICollection, } from "../../app-providers/services/map-module";
import { AlertNotificationsComponent } from "src/app/Alerts/alert-notifications/alert-notifications.component";
import { SettingsMailboxComponent } from "../settings-mailbox/settings-mailbox.component";
import { SettingsSendingWindowsComponent } from "../settings-sending-windows/settings-sending-windows.component";
import { CompanyDncComponent } from "../settings-dnc/company-dnc/company-dnc.component";
import { ProspectDncComponent } from "../settings-dnc/prospect-dnc/prospect-dnc.component";
import { AddSendingWindowsComponent } from "../settings-sending-windows/add-sending-windows/add-sending-windows.component";
import { UpdateSendingWindowsComponent } from "../settings-sending-windows/update-sending-windows/update-sending-windows.component";


@Injectable({
    providedIn: "root",
})
export class SettingViewModel {


    parentMailboxComponent: SettingsMailboxComponent;
    parentSettingStatusComponent: SettingsStatusesComponent;
    parentSettingGroupComponent: SettingsGroupsComponent;
    parentSettingSendingWindow: SettingsSendingWindowsComponent;
    parentSettingCompanyDnc: CompanyDncComponent;
    parentSettingProspectDnc: ProspectDncComponent;
    parentAddSendingWindowsComponent: AddSendingWindowsComponent;
    parentUpdateSendingWindowsComponent: UpdateSendingWindowsComponent;


    userExtraResponseModel = new BehaviorSubject<UserExtraModel>(
        new UserExtraModel()
    );

    emailSenderResponseModel = new BehaviorSubject<EmailSenderResponseModel>(
        new EmailSenderResponseModel()
    );

    companyDncResponseModel = new BehaviorSubject<CompanyDncResponseModel>(
        new CompanyDncResponseModel()
    )

    prospectDncResponseModel = new BehaviorSubject<ProspectDncResponseModel>(
        new ProspectDncResponseModel()
    )

    sendingWindowsResponseModel = new BehaviorSubject<SendingWindowsResponseModel>(
        new SendingWindowsResponseModel()
    );

    sendingWindowsModel = new BehaviorSubject<SendingWindowsModel>(
        new SendingWindowsModel()
    );

    prospectGroupResponseModel = new BehaviorSubject<ProspectGroupResponseModel>(
        new ProspectGroupResponseModel()
    );

    prospectStatusResponseModel = new BehaviorSubject<ProspectStatusResponseModel>(
        new ProspectStatusResponseModel()
    );

    scheduleTimezoneResponseModel = new BehaviorSubject<ScheduleTimezoneResponseModel>(
        new ScheduleTimezoneResponseModel()
    );

    clientsResponseModel = new BehaviorSubject<ClientsResponseModel>(
        new ClientsResponseModel()
    );

    constructor(private apiService: MapAPIService) { }

    subscribeClientsVariable(
        observerClientsResponseModel: Observer<ClientsResponseModel>,) {
        this.clientsResponseModel.subscribe(observerClientsResponseModel)
    }

    subscribeCompanyDncVariable(
        observerCompanyDncResponseModel: Observer<CompanyDncResponseModel>,) {
        this.companyDncResponseModel.subscribe(observerCompanyDncResponseModel)
    }

    subscribeProspectDncVariable(
        observerProspectDncResponseModel: Observer<ProspectDncResponseModel>,) {
        this.prospectDncResponseModel.subscribe(observerProspectDncResponseModel)
    }


    subscribeSendingWindowsVariable(
        observerSendingWindowsResponseModel: Observer<SendingWindowsResponseModel>,) {
        this.sendingWindowsResponseModel.subscribe(observerSendingWindowsResponseModel)
    }

    subscribeSendingWindowVariable(
        observerSendingWindowModel: Observer<SendingWindowsModel>,) {
        this.sendingWindowsModel.subscribe(observerSendingWindowModel)
    }

    subscribeScheduleTimezoneVariable(
        observerScheduleTimezoneResponseModel: Observer<ScheduleTimezoneResponseModel>,) {
        this.scheduleTimezoneResponseModel.subscribe(observerScheduleTimezoneResponseModel)
    }


    subscribeUserExtraVariable(
        observerUserExtraModel: Observer<UserExtraModel>,) {
        this.userExtraResponseModel.subscribe(observerUserExtraModel)
    }

    subscribeEmailSenderVariable(
        observerEmailSenderModel: Observer<EmailSenderResponseModel>,) {
        this.emailSenderResponseModel.subscribe(observerEmailSenderModel)
    }

    subscribeGroupVariable(
        observerProspectGroupResponseModel: Observer<ProspectGroupResponseModel>,) {
        this.prospectGroupResponseModel.subscribe(observerProspectGroupResponseModel)
    }

    subscribeStatusVariable(observerProspectStatusResponseModel: Observer<ProspectStatusResponseModel>) {
        this.prospectStatusResponseModel.subscribe(observerProspectStatusResponseModel)
    }


    getAllClients() {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_CLIENTS,
            Success: (e: any) => {
                this.clientsResponseModel.next(e);
                this.parentSettingProspectDnc.clientsReceived();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading clients', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }



    // Method to all prospect status
    getAllProspectStatus() {
        //var str = "?&name__contains=" + seachStr + "&page=1&page_size=20";
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_PROSPECT_STATUS,
            Success: (e: any) => {
                this.prospectStatusResponseModel.next(e);
                this.parentSettingStatusComponent.ProspectStatusRecieved();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading Status', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }


    // Method to fetch all prospect groups
    getAllProspectGroups(page) {
        var str = "?&page="+page+"&page_size=30";

        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_PROSPECTS_GROUPS,
            Success: (e: any) => {
                this.prospectGroupResponseModel.next(e);
                this.parentSettingGroupComponent.ProspectGroupReceived();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading prospect groups', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    searchGroups(searchText: string) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_PROSPECTS_GROUPS + "?&name__icontains=" + searchText,
            Success: (e: any) => {
                this.prospectGroupResponseModel.next(e);
                this.parentSettingGroupComponent.ProspectGroupReceived();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading prospect groups', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    getAllSendingWindows(page, groupSearch) {
            var url = MapAPICollection.GET_SENDING_WINDOWS+"?page="+page+"&page_size=30";
            if(groupSearch.length > 0){
                url += '&name__icontains=' + groupSearch;
            }
            let d: IApiServiceDelegate = {
                Data: {},
                Method: "GET",
                Action: url,
                Success: (e: any) => {
                    this.sendingWindowsResponseModel.next(e);
                    this.parentSettingSendingWindow.sendingWindowsReceived();
                    return e;
                },
                Error: (e: String) => {
                    AlertNotificationsComponent.alertCall.showAlert('Error while loading sending window', 'danger');
                    return e;
                },
            };
            this.apiService.callService(d);
    }

    getSendingWindow(id) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_SENDING_WINDOWS+"/"+id,
            Success: (e: any) => {
                console.log('response', e)
                this.sendingWindowsModel.next(e)
                this.parentUpdateSendingWindowsComponent.sendingWindowsReceived();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading sending window', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
}

    getAllCompanyDnc(page) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_COMPANY_DNC+"?page="+page+"&page_size=30",
            Success: (e: any) => {
                this.companyDncResponseModel.next(e);
                this.parentSettingCompanyDnc.companyDncReceived();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading company dnc', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    searchCompanyDnc(search: string) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_COMPANY_DNC + '?email_domain__icontains=' + search,
            Success: (e: any) => {
                this.companyDncResponseModel.next(e);
                this.parentSettingCompanyDnc.companyDncReceived();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while search', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    getAllProspectDnc(page) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_PROSPECT_DNC+"?page="+page+"&page_size=30",
            Success: (e: any) => {
                this.prospectDncResponseModel.next(e);
                this.parentSettingProspectDnc.prospectDncReceived();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading prospect groups', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    searchProspectDnc(search: string) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_PROSPECT_DNC + '?email_address__icontains=' + search,
            Success: (e: any) => {
                this.prospectDncResponseModel.next(e);
                this.parentSettingProspectDnc.prospectDncReceived();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading prospect groups', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }


    getUserExtra(currentUserID: number) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_USER_EXTRA + '?user__id=' + currentUserID.toString(),
            Success: (e: any) => {
                this.userExtraResponseModel.next(e.results[0]);
                this.parentMailboxComponent.UserExtraReceived();
                return e.results[0];
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading user extra', 'danger');
                return null;
            },
        };
        this.apiService.callService(d);
    }
    
    getEmailSender() {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_MAILBOX_EXT_CONNECTION ,
            Success: (e: any) => {
                this.emailSenderResponseModel.next(e);
                this.parentMailboxComponent.EmailSenderReceived();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading sender addresses', 'danger');
                return null;
            },
        };
        this.apiService.callService(d);
    }

    getAllScheduleTimezone(caller){
        console.log('hello from getallscheduletimezone', caller)
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_SCHEDULE_TIMEZONE,
            Success: (e: any) => {
                this.scheduleTimezoneResponseModel.next(e);
                if(caller === 'add'){
                    this.parentAddSendingWindowsComponent.scheduleTimezoneReceived();
                }
                if(caller === 'edit'){
                    this.parentUpdateSendingWindowsComponent.scheduleTimezoneReceived();
                }

                if(caller === 'sending-window-list'){
                    this.parentSettingSendingWindow.scheduleTimezoneReceived();
                }
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading timezone', 'danger');
                return null;
            },
        };
        this.apiService.callService(d);
    }

    createSendingWindows(sendingWindowModel: SendingWindowsModel){
        let d: IApiServiceDelegate = {
            Data: JSON.parse(JSON.stringify(sendingWindowModel)),
            Method: "POST",
            Action: MapAPICollection.POST_SENDING_WINDOWS,
            Success: (e: any) => {
                this.getAllScheduleTimezone('add');
                // this.getAllSendingWindows(1);
                AlertNotificationsComponent.alertCall.showAlert(sendingWindowModel.name + ' created', 'primary');
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert("Can't create new sending window", 'danger');
                return null;
            },
        };
        this.apiService.callService(d);
    }

    updateSendingWindows(sendingWindowModel: SendingWindowsModel, modified_by: number){
        const sendingWindowsjsonPostData = {
            "name": sendingWindowModel.name,
            "modified_at": new Date,
            "sunday_start_at": sendingWindowModel.sunday_start_at,
            "sunday_end_at": sendingWindowModel.sunday_end_at,
            "monday_start_at": sendingWindowModel.monday_start_at,
            "monday_end_at": sendingWindowModel.monday_end_at,
            "tuesday_start_at": sendingWindowModel.tuesday_start_at,
            "tuesday_end_at": sendingWindowModel.tuesday_end_at,
            "wednesday_start_at": sendingWindowModel.wednesday_start_at,
            "wednesday_end_at": sendingWindowModel.wednesday_end_at,
            "thursday_start_at": sendingWindowModel.thursday_start_at,
            "thursday_end_at": sendingWindowModel.thursday_end_at,
            "friday_start_at": sendingWindowModel.friday_start_at,
            "friday_end_at": sendingWindowModel.friday_end_at,
            "saturday_start_at": sendingWindowModel.saturday_start_at,
            "saturday_end_at": sendingWindowModel.saturday_end_at,
            "created_by": sendingWindowModel.created_by,
            "timezone": sendingWindowModel.timezone
          }
        let d: IApiServiceDelegate = {
            Data: JSON.parse(JSON.stringify(sendingWindowsjsonPostData)),
            Method: "PUT",
            Action: MapAPICollection.PUT_SENDING_WINDOWS + '/' + sendingWindowModel.id.toString(),
            Success: (e: any) => {
                this.getAllScheduleTimezone('update');
                // this.getAllSendingWindows(1);
                AlertNotificationsComponent.alertCall.showAlert(sendingWindowModel.name + ' updated', 'primary');
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert("Can't update sending window", 'danger');
                return null;
            },
        };
        this.apiService.callService(d);
    }

    getUserExtraPostData(userExtraObj: UserExtraModel) {
        var jsonPostData = {
            "email_address": userExtraObj.email_address,
            "email_sender_name": userExtraObj.email_sender_name,
            "modified_at": userExtraObj.modified_at,
            "send_limit_daily": userExtraObj.send_limit_daily,
            "email_signature": userExtraObj.email_signature,
            "opt_out_message": userExtraObj.opt_out_message,
            "is_opt_out_enabled": userExtraObj.is_opt_out_enabled,
            "is_tracking_enabled": userExtraObj.is_tracking_enabled,
            "is_link_tracking_enabled":userExtraObj.is_link_tracking_enabled,
            "is_signature_enabled":userExtraObj.is_signature_enabled,
            "created_by": userExtraObj.created_by,
            "modified_by": userExtraObj.modified_by,
            "user": userExtraObj.user,
          }
        return jsonPostData;
    }

    updateUserExtra(userExtraObj: UserExtraModel) {
        let d: IApiServiceDelegate = {
            Data: this.getUserExtraPostData(userExtraObj),
            Method: "PUT",
            Action: MapAPICollection.PUT_USER_EXTRA + '/' + userExtraObj.id.toString(),
            Success: (e: any) => {
                this.userExtraResponseModel.next(e);
                this.parentMailboxComponent.UserExtraReceived();
                AlertNotificationsComponent.alertCall.showAlert('Settings Updated', 'primary');
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading user extra', 'danger');
                return null;
            },
        };
        this.apiService.callService(d);
    }

    addCompanyDNC(emailDomain: string, reasonForDnc: string, currentUser: number) {
        let d: IApiServiceDelegate = {
            Data: { "email_domain": emailDomain, "dnc_reason": reasonForDnc, "created_by": currentUser },
            Method: "POST",
            Action: MapAPICollection.POST_COMPANY_DNC,
            Success: (e: any) => {
                this.getAllCompanyDnc(1);
                AlertNotificationsComponent.alertCall.showAlert((emailDomain + ' added on company DNC'), 'primary');
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while adding Company DNC', 'danger');
                return null;
            },
        };
        this.apiService.callService(d);
    }

    updateCompanyDnc(companyDncModel: CompanyDncModel){
        let d: IApiServiceDelegate = {
            Data: companyDncModel,
            Method: "PUT",
            Action: MapAPICollection.PUT_COMPANY_DNC + '/' + companyDncModel.id.toString(),
            Success: (e: any) => {
                this.getAllCompanyDnc(1);
                AlertNotificationsComponent.alertCall.showAlert((companyDncModel.email_domain + ' updated'), 'primary');
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while updating Company DNC', 'danger');
                return null;
            },
        };
        this.apiService.callService(d);
    }


    updateProspectDnc(prospectDncModel: ProspectDncModel) {
        let d: IApiServiceDelegate = {
            Data: prospectDncModel,
            Method: "PUT",
            Action: MapAPICollection.PUT_PROSPECT_DNC + '/' + prospectDncModel.id.toString(),
            Success: (e: any) => {
                this.getAllCompanyDnc(1);
                AlertNotificationsComponent.alertCall.showAlert((prospectDncModel.email_address + ' updated'), 'primary');
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while adding Company DNC', 'danger');
                return null;
            },
        };
        this.apiService.callService(d);
    }


    deleteCompanyDnc(companyDncModel: CompanyDncModel) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "DELETE",
            Action: MapAPICollection.DELETE_COMPANY_DNC + '/' + companyDncModel.id.toString(),
            Success: (e: any) => {
                this.getAllCompanyDnc(1);
                AlertNotificationsComponent.alertCall.showAlert((companyDncModel.email_domain + ' removed'), 'primary');
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert("Can't remove company DNC", 'danger');
                return null;
            },
        };
        this.apiService.callService(d);
    }


    deleteProspectDnc(prospectDncModel: ProspectDncModel) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "DELETE",
            Action: MapAPICollection.DELETE_PROSPECT_DNC + '/' + prospectDncModel.id.toString(),
            Success: (e: any) => {
                this.getAllProspectDnc(1);
                AlertNotificationsComponent.alertCall.showAlert((prospectDncModel.email_address + ' removed'), 'primary');
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert("Can't remove prospect DNC", 'danger');
                return null;
            },
        };
        this.apiService.callService(d);
    }

    deleteGroup(id: number) {
        let d: IApiServiceDelegate = {
          Data: {},
          Method: "DELETE",
          Action: MapAPICollection.DELETE_SETTINGS_GROUPS + '/' + id.toString(),
          Success: (e: any) => {
              this.getAllProspectGroups(1);
              AlertNotificationsComponent.alertCall.showAlert('prspects group removed', 'primary');
              return e;
          },
          Error: (e: String) => {
              AlertNotificationsComponent.alertCall.showAlert("Can't remove prospect group", 'danger');
              return null;
          },
      };
      this.apiService.callService(d);
      }
  


    deleteSendingWindow(sendingWindowsModel: SendingWindowsModel) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "DELETE",
            Action: MapAPICollection.DELETE_SENDING_WINDOWS + '/' + sendingWindowsModel.id.toString(),
            Success: (e: any) => {
                this.getAllScheduleTimezone('update');
                // this.getAllSendingWindows(1);
                AlertNotificationsComponent.alertCall.showAlert((sendingWindowsModel.name + ' removed'), 'primary');
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert("Can't remove sending window", 'danger');
                return null;
            },
        };
        this.apiService.callService(d);
    }

    connectMailbox(requestObj: any): void {
      let d: IApiServiceDelegate = {
        Data: requestObj,
        Method: "POST",
        Action: MapAPICollection.CONNECT_MAILBOX,
        Success: (e: any) => {
            localStorage.setItem('map_connected_mailbox', e.mailbox_address);
            localStorage.setItem('map_connection_id', e.id);
            this.parentMailboxComponent.connectedMailBoxReceived()
            AlertNotificationsComponent.alertCall.showAlert(('Connection Success'), 'primary');
            return e;
        },
        Error: (e: string) => {
            AlertNotificationsComponent.alertCall.showAlert('Connection Error', 'danger');
            return null;
        },
      };
      this.apiService.callService(d);
    }


    getMailboxConnection(connectionId: any): void {
        let d: IApiServiceDelegate = {
          Data: {},
          Method: "GET",
          Action: MapAPICollection.GET_MAILBOX.replace('{id}', String(connectionId)),
          Success: (e: any) => {
              return e;
          },
          Error: (e: string) => {
              this.parentMailboxComponent.errorFoundWhileMailboxConnectionCheck();
              return null;
          },
        };
        this.apiService.callService(d);
      }

    disconnectMailbox(connectionId: number): void {
        let d: IApiServiceDelegate = {
          Data: {},
          Method: "DELETE",
          Action: MapAPICollection.DISCONNECT_MAILBOX.replace('{id}', connectionId.toString()),
          Success: (e: any) => {
            localStorage.removeItem('map_connected_mailbox');
            localStorage.removeItem('map_connection_id');
            this.parentMailboxComponent.disconnectedMailBoxReceived()
            AlertNotificationsComponent.alertCall.showAlert(('Successfully disconnected'), 'primary');
            return e;
              
          },
          Error: (e: string) => {
              AlertNotificationsComponent.alertCall.showAlert('There are active camapigns at the moment, disconnect would be allowed after campaigns have finished', 'danger');
              return null;
          },
        };
        this.apiService.callService(d);
      }

}
