import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Observer } from "rxjs";
import {
    CampaignClientResponseModel, CampaignFilterModel, CampaignModel, CampaignResponseModel,
    CampaignStatsResponseModel, CampaignUserModel, ProspectResponseModel,
    CampaignStepModel, CampaignRunLogStatsResponseModel, CampaignStepResponseModel, CampaignUserResponseModel,
    SendingWindowsResponseModel, CampaignRunLogFilterModel, CampaignRunLogResponseModel, SendingWindowsModel,
    ScheduleTimezoneModel, CampaignRunLogModel, CampaignReplyLogStatsResponseModel, CampaignMasterStat, FollowupConditionResponseModel, RegionResponseModel, ReplyModel, UserExtraResponseModel
} from "../campaign-model/campaign-model";
import { CampaignsComponent } from "../campaigns.component";
import { SidebarCampaignsComponent } from "../sidebar-campaigns/sidebar-campaigns.component";
import {
    IApiServiceDelegate,
    MapAPIService,
    MapAPICollection,
} from "../../app-providers/services/map-module";
import { AlertNotificationsComponent } from "src/app/Alerts/alert-notifications/alert-notifications.component";

import { CampaignAddComponent } from "../campaign-add/campaign-add.component";
import { CampaignDetailSummaryComponent } from "../campaign-detail-summary/campaign-detail-summary.component";
import { CampaignDetailSettingsComponent } from "../campaign-detail-settings/campaign-detail-settings.component";
import { CampaignDetailProspectsComponent } from "../campaign-detail-prospects/campaign-detail-prospects.component";
import { CampaignDetailEmailsComponent } from "../campaign-detail-emails/campaign-detail-emails.component";
import { CampaignDetailComponent } from "../campaign-detail/campaign-detail.component";
import { MapCommon } from "src/app/app-providers/map-base/MapCommon";
import { StepAbTestComponent } from "../campaign-detail-summary/step-ab-test/step-ab-test.component";
import { CampaignEditStepComponent } from "../campaign-edit-step/campaign-edit-step.component";
import { CampaignStepReviewComponent } from "../campaign-step-review/campaign-step-review.component";
import { CampaignStepCustomizeComponent } from "../campaign-step-review/campaign-step-customize/campaign-step-customize.component";
import { CampaignDetailAutoReplyComponent } from "../campaign-detail-auto-reply/campaign-detail-auto-reply.component";
import { CampaignAddStepComponent } from "../campaign-add-step/campaign-add-step.component";
import { StepFollowUpComponent } from "../campaign-detail-summary/step-follow-up/step-follow-up.component";
import { HttpHeaders } from "@angular/common/http";
import { ViewCampaignDetailEmailComponent } from "../campaign-detail-emails/view-campaign-detail-email/view-campaign-detail-email.component";
import { StepSendAfterComponent } from "../campaign-detail-summary/step-send-after/step-send-after.component";
import { AddToAnotherCampaignComponent } from "../add-to-another-campaign/add-to-another-campaign.component";
import { ReplyProspectComponent } from "src/app/reply-prospect/reply-prospect.component";
import { CampaignDetailSignatureComponent } from "../campaign-detail-signature/campaign-detail-signature.component";
import { SubjectLineAutoSuggestComponent } from "../subject-line-auto-suggest/subject-line-auto-suggest.component";
import { StepAbGenerateComponent } from "../campaign-detail-summary/step-ab-generate/step-ab-generate.component";
@Injectable({
    providedIn: "root",
})
export class CampaignViewModel {
    parentComponent: CampaignsComponent;
    parentSidebarCampaignsComponent: SidebarCampaignsComponent;
    parentAddCampaignsComponent: CampaignAddComponent;
    parentCampaignDetailSummaryComponent: CampaignDetailSummaryComponent;
    parentCampaignDetailSettingsComponent: CampaignDetailSettingsComponent;
    parentCampaignDetailProspectsComponent: CampaignDetailProspectsComponent;
    parentCampaignDetailEmailsComponent: CampaignDetailEmailsComponent;
    parentCampaignDetailAutoReplyComponent: CampaignDetailAutoReplyComponent;
    parentCampaignDetailComponent: CampaignDetailComponent;
    parentStepAbTestComponent: StepAbTestComponent;
    parentCampaignEditStepComponent: CampaignEditStepComponent;
    parentCampaignStepReviewComponent: CampaignStepReviewComponent;
    parentCampaignStepCustomizeComponent: CampaignStepCustomizeComponent;
    parentCampaignAddStepComponent: CampaignAddStepComponent;
    SubjectLineAutoSuggestComponent: SubjectLineAutoSuggestComponent;
    parentStepFollowUpComponent: StepFollowUpComponent;
    parentViewCampaignDetailEmailComponent: ViewCampaignDetailEmailComponent;
    parentStepSendAfterComponent: StepSendAfterComponent;
    parentAddToAnotherCampaignComponent: AddToAnotherCampaignComponent;
    parentReplyProspectComponent: ReplyProspectComponent;
    parentCampaignDetailSignatureComponent: CampaignDetailSignatureComponent;
    stepAbGenerateComponent: StepAbGenerateComponent;
    // Variables to serve data to component

    campaignResponseModel = new BehaviorSubject<CampaignResponseModel>(
        new CampaignResponseModel()
    );

    prospectResponseModel = new BehaviorSubject<ProspectResponseModel>(
        new ProspectResponseModel()
    );

    campaignSidebarUserResponseModel = new BehaviorSubject<CampaignUserResponseModel>(
        new CampaignUserResponseModel()
    );
    campaignUserResponseModel = new BehaviorSubject<CampaignUserResponseModel>(
        new CampaignUserResponseModel()
    );
    campaignClientResponseModel = new BehaviorSubject<CampaignClientResponseModel>(
        new CampaignClientResponseModel()
    );

    sendingWindowsResponseModel = new BehaviorSubject<SendingWindowsResponseModel>(
        new SendingWindowsResponseModel()
    );

    sendingWindowResponseModel = new BehaviorSubject<SendingWindowsModel>(
        new SendingWindowsModel()
    );

    scheduleTimezoneResponseModel = new BehaviorSubject<ScheduleTimezoneModel>(
        new ScheduleTimezoneModel()
    );

    campaignStatsResponseModel = new BehaviorSubject<CampaignStatsResponseModel>(
        new CampaignStatsResponseModel()
    );

    campaignStepResponseModel = new BehaviorSubject<CampaignStepResponseModel>(
        new CampaignStepResponseModel()
    );

    campaignReplyLogStatsResponseModel = new BehaviorSubject<CampaignReplyLogStatsResponseModel>(
        new CampaignReplyLogStatsResponseModel()
    );

    campaignRunLogStatsResponseModel = new BehaviorSubject<CampaignRunLogStatsResponseModel>(
        new CampaignRunLogStatsResponseModel()
    );
    campaignRunLogResponseModel = new BehaviorSubject<CampaignRunLogResponseModel>(
        new CampaignRunLogResponseModel()
    );
    campaignRunLogModel = new BehaviorSubject<CampaignRunLogModel>(
        new CampaignRunLogModel()
    );

    campaignRunLogStepReviewResponseModel = new BehaviorSubject<CampaignRunLogResponseModel>(
        new CampaignRunLogResponseModel()
    );

    campaignModel = new BehaviorSubject<CampaignModel>(
        new CampaignModel()
    );
    campaignUserModel = new BehaviorSubject<CampaignUserModel>(
        new CampaignUserModel()
    );

    campaignStepModel = new BehaviorSubject<CampaignStepModel>(
        new CampaignStepModel()
    );

    campaignMasterStat = new BehaviorSubject<CampaignMasterStat>(
        new CampaignMasterStat()
    );

    followupConditionResponseModel = new BehaviorSubject<FollowupConditionResponseModel>(
        new FollowupConditionResponseModel()
    );

    regionResponseModel = new BehaviorSubject<RegionResponseModel>(
        new RegionResponseModel()
      );


    replyModel = new BehaviorSubject<ReplyModel>(
        new ReplyModel()
    );

    activeMainBoxesUserResponseModel = new BehaviorSubject<CampaignUserResponseModel>(
        new CampaignUserResponseModel()
    );

    userExtraResponseModel = new BehaviorSubject<UserExtraResponseModel>(
        new UserExtraResponseModel()
    );


    // campaignStepsResponseModel
    constructor(private apiService: MapAPIService) { }

    subscribeAddReplyVariables(observer: Observer<ReplyModel>){
        this.replyModel.subscribe(observer)
    }

    subscribeAddToAnotherCampaignVeriables(observer: Observer<CampaignResponseModel>){
        this.campaignResponseModel.subscribe(observer)
    }

    subscribeToSignatureVeriables(observer: Observer<UserExtraResponseModel>){
        this.userExtraResponseModel.subscribe(observer)
    }


    // Methods to subscribe all variables
    subscribeAllVariables(observerCampaignResponseModel: Observer<CampaignResponseModel>,
        observerUserResponseModel: Observer<CampaignUserResponseModel>,
        observerCampaignClientResponseModel: Observer<CampaignClientResponseModel>,
        observerRegionResponseModel: Observer<RegionResponseModel>
    ) {
        this.campaignResponseModel.subscribe(observerCampaignResponseModel);
        this.campaignUserResponseModel.subscribe(observerUserResponseModel);
        this.campaignClientResponseModel.subscribe(observerCampaignClientResponseModel);
        this.regionResponseModel.subscribe(observerRegionResponseModel);
    }
    // Methods to subscribe all variables
    subscribeAllSideBarVariables(
        observerUserResponseModel: Observer<CampaignUserResponseModel>,
        observerCampaignClientResponseModel: Observer<CampaignClientResponseModel>
    ) {
        this.campaignSidebarUserResponseModel.subscribe(observerUserResponseModel)
        this.campaignClientResponseModel.subscribe(observerCampaignClientResponseModel)
    }

    subscribeAddCampaignVariables(
        observerCampaignClientResponseModel: Observer<CampaignClientResponseModel>,
        observerSendingWindowsResponseModel: Observer<SendingWindowsResponseModel>,
        observerRegionResponseModel: Observer<RegionResponseModel>,
        observerCampaignAddUserResponseModel: Observer<CampaignUserResponseModel>,
        ) {
        this.campaignClientResponseModel.subscribe(observerCampaignClientResponseModel);
        this.sendingWindowsResponseModel.subscribe(observerSendingWindowsResponseModel);
        this.regionResponseModel.subscribe(observerRegionResponseModel);
        this.activeMainBoxesUserResponseModel.subscribe(observerCampaignAddUserResponseModel);
    }

    subscribeSummaryVariables(observerCampaignStatsResponseModel: Observer<CampaignStatsResponseModel>,
        observerCampaignStepResponseModel: Observer<CampaignStepResponseModel>,
        observerSendingWindowResponseModel: Observer<SendingWindowsModel>,
        observerScheduleTimezoneResponseModel: Observer<ScheduleTimezoneModel>,
        observerCampaignMasterStat: Observer<CampaignMasterStat>,
        observerFollowupConditionResponseModel: Observer<FollowupConditionResponseModel>) {
        this.campaignStatsResponseModel.subscribe(observerCampaignStatsResponseModel)
        this.campaignStepResponseModel.subscribe(observerCampaignStepResponseModel)
        this.sendingWindowResponseModel.subscribe(observerSendingWindowResponseModel)
        this.scheduleTimezoneResponseModel.subscribe(observerScheduleTimezoneResponseModel)
        this.campaignMasterStat.subscribe(observerCampaignMasterStat)
        this.followupConditionResponseModel.subscribe(observerFollowupConditionResponseModel)
    }

    subscribeToStepFollowUp(observerFollowupConditionResponseModel: Observer<FollowupConditionResponseModel>) {
        this.followupConditionResponseModel.subscribe(observerFollowupConditionResponseModel)
    }



    subscribeCampaignDetailSettingVariables(
        observerSendingWindowsResponseModel: Observer<SendingWindowsResponseModel>,
        observerRegionResponseModel: Observer<RegionResponseModel>,
        observerCampaignAddUserResponseModel: Observer<CampaignUserResponseModel>,
        ) {
        this.sendingWindowsResponseModel.subscribe(observerSendingWindowsResponseModel);
        this.regionResponseModel.subscribe(observerRegionResponseModel);
        this.activeMainBoxesUserResponseModel.subscribe(observerCampaignAddUserResponseModel)
    }

    subscribeCampaignProspectVariables(observerCampaignMasterStat: Observer<CampaignMasterStat>) {
        this.campaignMasterStat.subscribe(observerCampaignMasterStat)
    }

    subscribeCampaignReplyVariables(observerCampaignMasterStat: Observer<CampaignMasterStat>) {
        this.campaignMasterStat.subscribe(observerCampaignMasterStat)
    }

    subscribeCampaignRunlogVariables(observerCampaignRunLog: Observer<CampaignRunLogModel>) {
        this.campaignRunLogModel.subscribe(observerCampaignRunLog)
    }

    subscribeCampaignStepReviewVariables(
        observerCampaignRunLogStepReviewResponseModel: Observer<CampaignRunLogResponseModel>,
        observerCampaignStepModel: Observer<CampaignStepModel>,
        observerCampaignModel: Observer<CampaignModel>,
        observerCampaignStepResponseModel: Observer<CampaignStepResponseModel>,
        ) {
        this.campaignRunLogStepReviewResponseModel.subscribe(observerCampaignRunLogStepReviewResponseModel),
        this.campaignStepModel.subscribe(observerCampaignStepModel)
        this.campaignModel.subscribe(observerCampaignModel);
        this.campaignStepResponseModel.subscribe(observerCampaignStepResponseModel)
    }


    subscribeCampaignRunLogVariables(observerCampaignRunLogResponseModel: Observer<CampaignRunLogResponseModel>) {
        this.campaignRunLogResponseModel.subscribe(observerCampaignRunLogResponseModel)
    }


    subscribeCampaignRunLogVariablesNew(observerProspectResponseModel: Observer<ProspectResponseModel>) {
        this.prospectResponseModel.subscribe(observerProspectResponseModel)
    }


    subscribeCampaignDetailVariables(observerCampaignModel: Observer<CampaignModel>,
        observerCampaignUserModel: Observer<CampaignUserModel>,
        observerCampaignClientResponseModel: Observer<CampaignClientResponseModel>,
        observerRegionResponseModel: Observer<RegionResponseModel>
        ) {
        this.campaignModel.subscribe(observerCampaignModel);
        this.campaignUserModel.subscribe(observerCampaignUserModel);
        this.campaignClientResponseModel.subscribe(observerCampaignClientResponseModel);
        this.regionResponseModel.subscribe(observerRegionResponseModel);
    }


    getCampaignData(campaignFilterModel: CampaignFilterModel) {

        var str = "?";
        if (campaignFilterModel == undefined)
            return str;
        //name
        if (campaignFilterModel.name__contains !== undefined && campaignFilterModel.name__contains !== null) {
            str = str + "name__icontains=" + encodeURIComponent(campaignFilterModel.name__contains) + "&";
        }
        if (campaignFilterModel.name__endswith !== undefined && campaignFilterModel.name__endswith !== null) {
            str = str + "name__endswith=" + campaignFilterModel.name__endswith + "&";
        }
        if (campaignFilterModel.name__startswith !== undefined && campaignFilterModel.name__startswith !== null) {
            str = str + "name__startswith=" + campaignFilterModel.name__startswith + "&";
        }
        if (campaignFilterModel.name !== undefined && campaignFilterModel.name !== null) {
            str = str + "name=" + campaignFilterModel.name + "&";
        }

        //created_by
        if (campaignFilterModel.created_by__id !== undefined && campaignFilterModel.created_by__id !== null && campaignFilterModel.created_by__id !== 0) {
            str = str + "created_by__id=" + campaignFilterModel.created_by__id + "&";
        }

        //created_by
        if (campaignFilterModel.client__id !== undefined && campaignFilterModel.client__id !== null && campaignFilterModel.client__id !== 0) {
            str = str + "client__id=" + campaignFilterModel.client__id + "&";
        }
        //status
        if (campaignFilterModel.status !== undefined && campaignFilterModel.status !== null && campaignFilterModel.status !== "") {

            if(campaignFilterModel.status == "Active" || campaignFilterModel.status == "Inactive"){
                str = str + "status=" + campaignFilterModel.status + "&";
            } else{
                str = str + "cms_campaign__status="+ campaignFilterModel.status + "&"
            }
        }


        if(campaignFilterModel.created_at_start_date !== undefined && campaignFilterModel.created_at_start_date !== null){
            str = str + "created_at_start_date=" + campaignFilterModel.created_at_start_date + "&";
        }

        if(campaignFilterModel.created_at_end_date !== undefined && campaignFilterModel.created_at_end_date !== null){
            str = str + "created_at_end_date=" + campaignFilterModel.created_at_end_date + "&";
        }

        str = str + "page=" + campaignFilterModel.page + "&page_size=" + campaignFilterModel.page_size;
        return str;

    }

    // Method to all prospect
    getAllCampaign(data: any, caller,ordering?) {
        var str = ''
        if(caller === 'campaign-list'){
            var str = MapAPICollection.GET_CAMPAIGN_V2 + this.getCampaignData(data);
        }
        if(caller === 'campaign-list' && ordering){
          var str = MapAPICollection.GET_CAMPAIGN_V2 + this.getCampaignData(data) + '&ordering='+ordering;
      }
        if(caller === 'add-to-another-campaign'){
            var str = MapAPICollection.GET_CAMPAIGN +"?&name__icontains=" + data + "&page=1&page_size=10";
        }
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action:  str,
            Success: (e: any) => {
                this.campaignResponseModel.next(e);
                if(caller === 'campaign-list'){
                    this.parentComponent.CampaignReceived();
                }

                if(caller === 'add-to-another-campaign'){
                    this.parentAddToAnotherCampaignComponent.CampaignReceived();
                }

                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading campaign', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    // Method to all prospect
    // getAllCampaignStepStatus(data: CampaignFilterModel, caller) {
    //     var str = "?campaign_step_status=" + data.status
    //     let d: IApiServiceDelegate = {
    //         Data: {},
    //         Method: "GET",
    //         Action: MapAPICollection.GET_CAMPAIGN + str,
    //         Success: (e: any) => {
    //             this.campaignResponseModel.next(e);
    //             if(caller === 'campaign-list'){
    //                 this.parentComponent.CampaignReceived();
    //             }

    //             if(caller === 'add-to-another-campaign'){
    //                 this.parentAddToAnotherCampaignComponent.CampaignReceived();
    //             }

    //             return e;
    //         },
    //         Error: (e: String) => {
    //             AlertNotificationsComponent.alertCall.showAlert('Error while loading campaign', 'danger');
    //             return e;
    //         },
    //     };
    //     this.apiService.callService(d);
    // }

    getCampaignRunLogStepReview(page, stepId, search = null) {
        var str = ''
        if (!search) {
            str = '?email_sent_at__isnull=true&campaign_step__id=' + String(stepId)
        } else {
            str = '?email_sent_at__isnull=true&campaign_step__id=' + String(stepId) + '&recipient_email_address__icontains=' + search
        }
        str = str + "&page=" + page + "&page_size=5";

        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_CAMPAIGN_RUNLOG + str,
            Success: (e: any) => {
                this.campaignRunLogStepReviewResponseModel.next(e);
                this.parentCampaignStepReviewComponent.CampaignRunLogStepReviewReceived();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading step review', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    addProspectsToAnotherCampaign(filters, campaignId, total_prospects){
        filters.page_size = total_prospects;
        var str = this.getCampaignRunLogData(filters);
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.ADD_PROSPECTS_TO_CAMPAIGN.replace('{id}', String(campaignId)) + str,
            Success: (e: any) => {
                this.parentAddToAnotherCampaignComponent.addedToAnotherCampaign(String(campaignId));
                AlertNotificationsComponent.alertCall.showAlert('Prospects added to campaign', 'primary');
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while adding prospects to campaign', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    getCampaignStepById(stepId, caller) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_CAMPAIGN_STEP.replace('{id}', String(stepId)),
            Success: (e: any) => {
                this.campaignStepModel.next(e);
                if (caller === 'campaign-step-review') {
                    this.parentCampaignStepReviewComponent.CampaignStepReceived();
                }
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading step review', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    retrieveCampaignRunlogById(campaignRunlogId: number) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.PATCH_CAMPAIGN_RUNLOG.replace('{id}', String(campaignRunlogId)),
            Success: (e: any) => {
                this.campaignRunLogModel.next(e);
                this.parentViewCampaignDetailEmailComponent.campaignRunlogReceived();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while sending all emails', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);

    }

    sendReviewAllEmail(campaingId: number) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_CAMPAIGN_SEND_ALL_MANUAL.replace('{id}', String(campaingId)),
            Success: (e: any) => {
                // this.campaignRunLogStepReviewResponseModel.next(e);
                this.parentCampaignStepReviewComponent.sendAllEmailsSuccess();
                AlertNotificationsComponent.alertCall.showAlert('Emails are being sent', 'primary');
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while sending all emails', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    sendReviewOneEmail(objectId: number) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_CAMPAIGN_SEND_ONE_MANUAL.replace('{id}', String(objectId)),
            Success: (e: any) => {
                // this.campaignRunLogStepReviewResponseModel.next(e);
                this.parentCampaignStepReviewComponent.sendOneEmailsSuccess();
                if (e.total_email_sent === 1) {
                    AlertNotificationsComponent.alertCall.showAlert('Email sent successfully', 'primary');
                } else {
                    AlertNotificationsComponent.alertCall.showAlert('Email was not sent', 'danger');
                }
                return e;
            },
            Error: (e: String) => {
                this.parentCampaignStepReviewComponent.closeLoadingSpinner();
                AlertNotificationsComponent.alertCall.showAlert('Error while sending one email', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    deleteReviewEmail(objectId: number) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "DELETE",
            Action: MapAPICollection.DELETE_CAMPAIGN_RUNLOG.replace('{id}', String(objectId)),
            Success: (e: any) => {
                this.parentCampaignStepReviewComponent.deleteSuccess();
                AlertNotificationsComponent.alertCall.showAlert('Email deleted successfully', 'primary');
                return e;
            },
            Error: (e: String) => {
                this.parentCampaignStepReviewComponent.closeLoadingSpinner();
                AlertNotificationsComponent.alertCall.showAlert('Error while deleting email', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    customizeRunLogEmail(campaignRunlogObject: CampaignRunLogModel) {
        var objectPostData = {
            "subject": campaignRunlogObject.subject,
            "log_status": campaignRunlogObject.log_status,
            "slug": campaignRunlogObject.slug,
            "created_at": campaignRunlogObject.created_at,
            "modified_at": campaignRunlogObject.modified_at,
            "body": campaignRunlogObject.body,
            "recipient_email_address": campaignRunlogObject.recipient_email_address,
            "sender_email_address": campaignRunlogObject.sender_email_address,
            "sender_name": campaignRunlogObject.sender_name,
            "email_ext_provider": campaignRunlogObject.email_ext_provider,
            "email_sent_at": campaignRunlogObject.email_sent_at,
            "email_opened_at": campaignRunlogObject.email_opened_at,
            "email_bounced_at": campaignRunlogObject.email_bounced_at,
            "email_opted_out_at": campaignRunlogObject.email_opted_out_at,
            "email_replied_at": campaignRunlogObject.email_replied_at,
            "is_auto_reply": campaignRunlogObject.is_auto_reply,
            "created_by": campaignRunlogObject.created_by,
            "modified_by": campaignRunlogObject.modified_by,
            "campaign_step": campaignRunlogObject.campaign_step,
            "prospect": campaignRunlogObject.prospect,
            "clv_runlog": campaignRunlogObject.clv_runlog
        }
        let d: IApiServiceDelegate = {
            Data: objectPostData,
            Method: "PUT",
            Action: MapAPICollection.PUT_CAMPAIGN_RUNLOG.replace('{id}', String(campaignRunlogObject.id)),
            Success: (e: any) => {
                this.parentCampaignStepCustomizeComponent.successAfterCustomizedRunLogEmail();
                AlertNotificationsComponent.alertCall.showAlert('Email Update', 'primary');
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while sending all emails', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    closePopUp(caller){
        if (caller == 'campaign-prospect') {
            this.parentCampaignDetailProspectsComponent.exportApiOpsCompleted();
        }
        if (caller == 'campaign-email') {
            this.parentCampaignDetailEmailsComponent.exportApiOpsCompleted();
        }

        if (caller == 'campaign-reply') {
            this.parentCampaignDetailAutoReplyComponent.exportApiOpsCompleted();
        }
    }

    getExportRunlogs(campaignRunLogFilterModel: CampaignRunLogFilterModel, caller: string, page_size) {
        campaignRunLogFilterModel.page_size = page_size;
        var str = this.getCampaignRunLogData(campaignRunLogFilterModel);
        // var completeUrl = MapAPICollection.EXPORT_RUNLOGS + str
        // let httpHeaders = new HttpHeaders();
        // this.apiService.downloadFile(completeUrl, httpHeaders).subscribe((e: any) => {
        //     // this.parentSefetyChecksComponent.downloadReceived(e.res.body);
        //         if (caller == 'campaign-prospect') {
        //             this.parentCampaignDetailProspectsComponent.exportApiOpsCompleted(e.res.body);
        //         }
        //         if (caller == 'campaign-email') {
        //             this.parentCampaignDetailEmailsComponent.exportApiOpsCompleted(e.res.body);
        //         }

        //         if (caller == 'campaign-reply') {
        //             this.parentCampaignDetailAutoReplyComponent.exportApiOpsCompleted(e.res.body);
        //         }
        // },
        // (error: any) => {
        //     AlertNotificationsComponent.alertCall.showAlert('Error while exporting logs', 'danger');
        //     return error;
        //   }
        // );



        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.EXPORT_RUNLOGS + str,
            Success: (e: any) => {
                this.closePopUp(caller);
                AlertNotificationsComponent.alertCall.showAlert('We have received your request, please check your inbox', 'primary');
                campaignRunLogFilterModel.page_size = undefined;
                return e;
            },
            Error: (e: String) => {
                this.closePopUp(caller);
                AlertNotificationsComponent.alertCall.showAlert('Error fatching export data', 'danger');
                return e;
            },
        };

        this.apiService.callService(d);

    }

    // exportFinalRunlogs(items, prospectIds, caller){
    //     var str = "?id__inc=" + prospectIds.join(',') + '&page_size=' + prospectIds.length;
    //     let d: IApiServiceDelegate = {
    //         Data: {},
    //         Method: "GET",
    //         Action: MapAPICollection.GET_PROSPECTS + str,
    //         Success: (e: any) => {
    //             const completeItems = items.map(val => {
    //                 return Object.assign({}, val, e.results.filter(v => v.id === val.prospect)[0]);
    //             });

    //             if (caller == 'campaign-prospect') {
    //                 this.parentCampaignDetailProspectsComponent.exportApiOpsCompleted(completeItems);
    //             }

    //             if (caller == 'campaign-email') {
    //                 this.parentCampaignDetailEmailsComponent.exportApiOpsCompleted(completeItems);
    //             }

    //             if (caller == 'campaign-reply') {
    //                 this.parentCampaignDetailAutoReplyComponent.exportApiOpsCompleted(completeItems);
    //             }
    //             return e;
    //         },
    //         Error: (e: String) => {
    //             AlertNotificationsComponent.alertCall.showAlert('Error fatching export data', 'danger');
    //             return e;
    //         },
    //     };
    //     this.apiService.callService(d);
    // }


    // Method to all prospect
    getAllCampaignRunLog(campaignRunLogFilterModel: CampaignRunLogFilterModel, caller: string) {
        var str = this.getCampaignRunLogData(campaignRunLogFilterModel);
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_CAMPAIGN_RUNLOG + str,
            Success: (e: any) => {
                this.campaignRunLogResponseModel.next(e);
                if (caller == 'campaign-prospect') {
                    this.parentCampaignDetailProspectsComponent.CampaignRunLogReceivedOnProspects();
                }

                if (caller == 'campaign-email') {
                    this.parentCampaignDetailEmailsComponent.CampaignRunLogReceivedOnEmails();
                }

                if (caller == 'campaign-reply') {
                    this.parentCampaignDetailAutoReplyComponent.CampaignRunLogReceivedOnEmails();
                }

                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading campaign statistics', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    getCampaignRunLogData(campaignRunLogFilterModel: CampaignRunLogFilterModel) {
        if(!campaignRunLogFilterModel.page_size){
            campaignRunLogFilterModel.page_size = 1000;
        }
        var str = "?";
        if (campaignRunLogFilterModel == undefined) {
            return str;
        }
        //campaign_step__status
        if (campaignRunLogFilterModel.campaign_step__status !== undefined && campaignRunLogFilterModel.campaign_step__status !== null) {
            str = str + "campaign_step__status=" + campaignRunLogFilterModel.campaign_step__status + "&";
        }

        if (campaignRunLogFilterModel.email_bounced_at__isnull !== undefined && campaignRunLogFilterModel.email_bounced_at__isnull !== null) {
            str = str + "email_bounced_at__isnull=" + campaignRunLogFilterModel.email_bounced_at__isnull + "&";
        }
        if (campaignRunLogFilterModel.email_opened_at__isnull !== undefined && campaignRunLogFilterModel.email_opened_at__isnull !== null) {
            str = str + "email_opened_at__isnull=" + campaignRunLogFilterModel.email_opened_at__isnull + "&";
        }
        if (campaignRunLogFilterModel.email_opted_out_at__isnull !== undefined && campaignRunLogFilterModel.email_opted_out_at__isnull !== null) {
            str = str + "email_opted_out_at__isnull=" + campaignRunLogFilterModel.email_opted_out_at__isnull + "&";
        }
        if (campaignRunLogFilterModel.email_replied_at__isnull !== undefined && campaignRunLogFilterModel.email_replied_at__isnull !== null) {
            str = str + "email_replied_at__isnull=" + campaignRunLogFilterModel.email_replied_at__isnull + "&";
        }
        // is_auto_reply=True&

        // recipient_email_address__contains
        if (campaignRunLogFilterModel.recipient_email_address__contains !== undefined && campaignRunLogFilterModel.recipient_email_address__contains !== null) {
            str = str + "recipient_email_address__icontains=" + campaignRunLogFilterModel.recipient_email_address__contains + "&";
        }
        // recipient_email_address__contains
        if (campaignRunLogFilterModel.email_sent_at__isnull !== undefined && campaignRunLogFilterModel.email_sent_at__isnull !== null) {
            str = str + "email_sent_at__isnull=" + campaignRunLogFilterModel.email_sent_at__isnull + "&";
        }

        // log_status__startswith
        if (campaignRunLogFilterModel.log_status__startswith !== undefined && campaignRunLogFilterModel.log_status__startswith !== null) {
            str = str + "log_status__startswith=" + campaignRunLogFilterModel.log_status__startswith + "&";
        }

        // Auto reply type
        if (campaignRunLogFilterModel.auto_reply_type !== undefined && campaignRunLogFilterModel.auto_reply_type !== null) {
            str = str + "auto_reply_type=" + campaignRunLogFilterModel.auto_reply_type + "&";
        }

        // is auto reply true
        if (campaignRunLogFilterModel.is_auto_reply !== undefined && campaignRunLogFilterModel.is_auto_reply !== null) {
            str = str + "is_auto_reply=" + campaignRunLogFilterModel.is_auto_reply + "&";
        }

        //campaign_step__campaign__id
        if (campaignRunLogFilterModel.campaign_step__campaign__id !== undefined && campaignRunLogFilterModel.campaign_step__campaign__id !== null && campaignRunLogFilterModel.campaign_step__campaign__id !== 0) {
            str = str + "campaign_step__campaign__id=" + campaignRunLogFilterModel.campaign_step__campaign__id + "&";
        }

        //campaign_step__id
        if (campaignRunLogFilterModel.campaign_step__id !== undefined && campaignRunLogFilterModel.campaign_step__id !== null && campaignRunLogFilterModel.campaign_step__id !== 0) {
            str = str + "campaign_step__id=" + campaignRunLogFilterModel.campaign_step__id + "&";
        }

        //page
        if (campaignRunLogFilterModel.page !== undefined && campaignRunLogFilterModel.page !== null && campaignRunLogFilterModel.page !== 0) {
            str = str + "page=" + campaignRunLogFilterModel.page + "&page_size=" + campaignRunLogFilterModel.page_size;
        }
        // Removed & from last item
        // return str.slice(0, -1);
        return str;

    }

    getAllFollowUpConditions(caller) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_FOLLOWUP_CONSITIONS,
            Success: (e: any) => {
                if (caller === 'detail-summary') {
                    this.followupConditionResponseModel.next(e);
                    this.parentCampaignDetailSummaryComponent.followUpConditionsReceived();
                }

                if (caller === 'step-follow-up') {
                    this.followupConditionResponseModel.next(e);
                    this.parentStepFollowUpComponent.followUpConditionsReceived();
                }

                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading follow up conditions', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    getCampaignProspectById(campaignId, page) {
        var str = "?page=" + page + "&page_size=50";
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_CAMPIAGN_PROSPECTS.replace("{id}", campaignId.toString()) + str,
            Success: (e: any) => {
                this.prospectResponseModel.next(e);
                this.parentCampaignDetailProspectsComponent.CampaignProspectReceived();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading campaign data', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }
    getCampaignById(campaignId, caller) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_CAMPAIGN_BY_ID.replace("{id}", campaignId.toString()),
            Success: (e: any) => {
                this.campaignModel.next(e);
                if(caller === 'campaign-detail'){
                    this.parentCampaignDetailComponent.CampaignDetailReceived();
                }
                if(caller === 'campaign-review') {
                    this.parentCampaignStepReviewComponent.CampaignDetailReceived();
                }

                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading campaign data', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    getUserById(userId) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_USER_BY_ID.replace("{id}", userId.toString()),
            Success: (e: any) => {
                this.campaignUserModel.next(e);
                this.parentCampaignDetailComponent.UserRecieved();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading user data', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    // Method to all users
    getAllUsers(caller,) {
        var url =  MapAPICollection.GET_ALL_USERS + "?page_size=200"
        if(caller == 'campaign-add' || caller == 'detail-settings'){
            url += "&mailbox_active=true"
        }
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: url,
            Success: (e: any) => {
                if (caller == 'campaignSideBar') {
                    this.campaignSidebarUserResponseModel.next(e);
                    this.parentSidebarCampaignsComponent.UserRecieved();
                }
                if (caller == 'compaign') {
                    this.campaignUserResponseModel.next(e);
                    this.parentComponent.UserRecieved();
                    // calling campaignSideBar modal & method to save extra api call on campaign listing page
                    // this.campaignSidebarUserResponseModel.next(e);
                    // this.parentSidebarCampaignsComponent.UserRecieved();
                }

                if(caller == 'campaign-add'){
                    this.activeMainBoxesUserResponseModel.next(e);
                    this.parentAddCampaignsComponent.UserRecieved()
                }
                if(caller == 'detail-settings'){
                    this.activeMainBoxesUserResponseModel.next(e);
                    this.parentCampaignDetailSettingsComponent.UserRecieved()
                }
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading users', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }
    getAllUsersForClient(caller, clientId) {
      var url =  MapAPICollection.GET_ALL_USERS + "?page_size=200&client=" + clientId
      // if(caller == 'campaign-add' || caller == 'detail-settings'){
      //     url += "&mailbox_active=true"
      // }
      let d: IApiServiceDelegate = {
          Data: {},
          Method: "GET",
          Action: url,
          Success: (e: any) => {
              if (caller == 'campaignSideBar') {
                  this.campaignSidebarUserResponseModel.next(e);
                  this.parentSidebarCampaignsComponent.UserRecieved();
              }
              if (caller == 'compaign') {
                  this.campaignUserResponseModel.next(e);
                  this.parentComponent.UserRecieved();
              }

              if(caller == 'campaign-add'){
                  this.activeMainBoxesUserResponseModel.next(e);
                  this.parentAddCampaignsComponent.UserRecieved()
              }
              if(caller == 'detail-settings'){
                  this.activeMainBoxesUserResponseModel.next(e);
                  this.parentCampaignDetailSettingsComponent.UserRecieved()
              }
              return e;
          },
          Error: (e: String) => {
              AlertNotificationsComponent.alertCall.showAlert('Error while loading users', 'danger');
              return e;
          },
      };
      this.apiService.callService(d);
  }
    // Method to get all clients
    getAllClientsFilterOnName(name, caller, page_size) {
        var str = "?name__icontains=" + name + "&page=1&page_size=" + String(page_size);
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_CLIENTS + str,
            Success: (e: any) => {
                if (caller == 'sidebar-campaign') {
                    this.campaignClientResponseModel.next(e);
                    this.parentSidebarCampaignsComponent.ClientRecieved();
                    return e;
                }
                if (caller == 'add-campaign') {
                    this.campaignClientResponseModel.next(e);
                    this.parentAddCampaignsComponent.ClientRecieved();
                    return e;
                } if (caller == 'compaign-details') {
                    this.campaignClientResponseModel.next(e);
                    this.parentCampaignDetailComponent.ClientRecieved();
                    return e;
                } if (caller == 'compaign') {
                    this.campaignClientResponseModel.next(e);
                    this.parentComponent.ClientRecieved();
                    return e;
                }
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading Clients', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }
    // Method to get all clients
    getAllClients(caller) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_CLIENTS + "?page=1&page_size=500",
            Success: (e: any) => {
                if (caller == 'sidebar-campaign') {
                    this.campaignClientResponseModel.next(e);
                    this.parentSidebarCampaignsComponent.ClientRecieved();
                    return e;
                }
                if (caller == 'add-campaign') {
                    this.campaignClientResponseModel.next(e);
                    this.parentAddCampaignsComponent.ClientRecieved();
                    return e;
                } if (caller == 'compaign-details') {
                    this.campaignClientResponseModel.next(e);
                    this.parentCampaignDetailComponent.ClientRecieved();
                    return e;
                } if (caller == 'compaign') {
                    this.campaignClientResponseModel.next(e);
                    this.parentComponent.ClientRecieved();
                    return e;
                }
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading Clients', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }
    validateCampaign(campaignModel: CampaignModel) {
        if (campaignModel.name == undefined || campaignModel.name.trim().length == 0) {
            AlertNotificationsComponent.alertCall.showAlert('Please provide campaign name', 'danger');
            return false
        }
        if (campaignModel.client == undefined || campaignModel.client == null || campaignModel.client == 0) {
            AlertNotificationsComponent.alertCall.showAlert('Please provide client name', 'danger');
            return false
        }
        return true;
    }
    getCampaignPostData(campaignModel: CampaignModel) {
        var jsonPostData = {
            "name": campaignModel.name,
            "status": campaignModel.status,
            "send_limit_daily": campaignModel.send_limit_daily,
            "created_by": MapCommon.getloggedInUser().id,
            "client": campaignModel.client,
            "schedule": campaignModel.schedule,
            "prospect": campaignModel.prospect,
            "send_mode": campaignModel.send_mode,
            "use_personal_account": campaignModel.use_personal_account,
            "region": campaignModel.region,
            "senders": campaignModel.senders
        }
        return jsonPostData;
    }
    getCampaignPatchData(campaignModel: CampaignModel) {
        var jsonPostData = {
            "name": campaignModel.name,
            "modified_at": new Date(),
            "status": campaignModel.status,
            "send_limit_daily": campaignModel.send_limit_daily,
            "modified_by": MapCommon.getloggedInUser().id,
            "client": campaignModel.client,
            "schedule": campaignModel.schedule,
            "prospect": campaignModel.prospect
        }
        return jsonPostData;
    }

    // get all sending windows
    getAllSendingWindows(seachStr, caller, page_size) {
        var str = "?&name__icontains=" + seachStr + "&page=1&page_size=" + String(page_size);
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_SENDING_WINDOWS + str,
            Success: (e: any) => {
                if (caller == 'campaign-add') {
                    this.sendingWindowsResponseModel.next(e);
                    this.parentAddCampaignsComponent.sendingWindowsReceived();
                }
                if (caller == 'detail-settings') {
                    this.sendingWindowsResponseModel.next(e);
                    this.parentCampaignDetailSettingsComponent.sendingWindowsReceived();
                }
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading sending windows', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    getSendingWindowById(swID, caller) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_SENDING_WINDOWS + '/' + String(swID),
            Success: (e: any) => {
                if (caller == 'detail-summary') {
                    this.sendingWindowResponseModel.next(e);
                    this.parentCampaignDetailSummaryComponent.sendingWindowsReceived();
                }
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading sending windows', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    getTimezoneById(timezoneID) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_SCHEDULE_TIMEZONE + '/' + String(timezoneID),
            Success: (e: any) => {
                this.scheduleTimezoneResponseModel.next(e);
                this.parentCampaignDetailSummaryComponent.scheduleTimezoneReceived();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading timezone', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    getReportCampaignStats(campaignId) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            //Action: MapAPICollection.GET_REPORT_CAMPAIGN_STATS.replace("{id}", campaignId.toString()),
            Action: MapAPICollection.GET_REPORT_CAMPAIGN_MASTER_STAT.replace("{id}", campaignId.toString()),
            Success: (e: any) => {
                let timeWhen = new Date().getTime()
                this.campaignMasterStat.next(e);
                this.parentCampaignDetailSummaryComponent.CampaignReportStatsRecieved(timeWhen);
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading campaign report stats', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    getReportCampaignReplylogStats(campaignId) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            //Action: MapAPICollection.GET_REPORT_CAMPAIGN_REPLY_LOG_STATS.replace("{id}", campaignId.toString()),
            Action: MapAPICollection.GET_REPORT_CAMPAIGN_MASTER_STAT.replace("{id}", campaignId.toString()),
            Success: (e: any) => {
                this.campaignMasterStat.next(e);
                this.parentCampaignDetailAutoReplyComponent.CampaignReportReplyLogStatsRecieved();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading campaign runlog stats', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }
    getReportCampaignRunlogStats(campaignId, caller) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            //Action: MapAPICollection.GET_REPORT_CAMPAIGN_RUN_LOG_STATS.replace("{id}", campaignId.toString()),
            Action: MapAPICollection.GET_REPORT_CAMPAIGN_MASTER_STAT.replace("{id}", campaignId.toString()),
            Success: (e: any) => {
              let timeWhen = new Date().getTime()
                this.campaignMasterStat.next(e);
                if (caller == 'campaign-prospect')
                    this.parentCampaignDetailProspectsComponent.CampaignReportRunLogStatsRecieved(timeWhen);
                if (caller == 'campaign-email')
                    this.parentCampaignDetailEmailsComponent.CampaignReportRunLogStatsRecieved(timeWhen);
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading campaign runlog stats', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    getCampaignSteps(campaignId, caller) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_CAMPAIGN_STEPS + "?campaign__id=" + campaignId,

            Success: (e: any) => {
                this.campaignStepResponseModel.next(e);
                if(caller == 'campaign-review'){
                    this.parentCampaignStepReviewComponent.CampaignStepsRecieved();
                }
                if(caller == 'detail-summary'){
                    this.parentCampaignDetailSummaryComponent.CampaignStepsRecieved();
                }
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading campaign steps', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    deleteCampaignProspects(campaignId, includedProspects, excludedProspects) {
       var payload = {}
       if(includedProspects.length > 0){
        payload["prospect_id__inc"] = includedProspects;
       }
       else if (excludedProspects.length > 0){
        payload["prospect_id__exc"] = excludedProspects;
       } else {
        payload["prospect"] = [];
       }
        let d: IApiServiceDelegate = {
            Data: payload,
            Method: "PUT",
            Action: MapAPICollection.DELETE_CAMPAIGN_PROSPECT.replace("{id}", campaignId.toString()),
            Success: (e: any) => {
                this.parentCampaignDetailProspectsComponent.ngOnInit();
                AlertNotificationsComponent.alertCall.showAlert('Prospect(s) deleted successfully', 'primary');
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Campaign has been executed once, prospect deletion only allowed if campaign has not been excecuted.', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    deleteCampaignStep(stepId: number) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "DELETE",
            Action: MapAPICollection.DELETE_CAMPAIGN_STEP.replace("{id}", stepId.toString()),
            Success: (e: any) => {
                this.parentCampaignDetailSummaryComponent.UpdateStepsAfterActions();
                AlertNotificationsComponent.alertCall.showAlert('Campaign step deleted successfully', 'primary');
                return e;
            },
            Error: (e: String) => {
                this.parentCampaignDetailSummaryComponent.removeLoading();
                AlertNotificationsComponent.alertCall.showAlert('Error while deleting campaign step', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    pauseResumeCancelStep(stepId, actionType) {
        let d: IApiServiceDelegate = {
            Data: {
                "status": actionType
            },
            Method: "PATCH",
            Action: MapAPICollection.POST_PAUSE_RESUME_CANCEL.replace("{id}", stepId.toString()),
            Success: (e: any) => {
                this.parentCampaignDetailSummaryComponent.UpdateStepsAfterPauseResumeCancel(true);
                AlertNotificationsComponent.alertCall.showAlert('campaign step has been {{type}}'.replace("{{type}}", actionType) + 'd'
                    , 'primary');
                return e;
            },
            Error: (e: any) => {
                this.parentCampaignDetailSummaryComponent.UpdateStepsAfterPauseResumeCancel(false);
                if (e.hasOwnProperty('message')) {
                    AlertNotificationsComponent.alertCall.showAlert(e.message, 'danger');
                } else {
                    AlertNotificationsComponent.alertCall.showAlert(e, 'danger');
                }

                return e;
            },
        };
        this.apiService.callService(d);
    }

    createCampaignStep(stepAddModel: CampaignStepModel, caller) {
        const data = JSON.parse(JSON.stringify(stepAddModel));
        let d: IApiServiceDelegate = {
            Data: data,
            Method: "POST",
            Action: MapAPICollection.POST_CAMPAIGN_STEP,
            Success: (e: any) => {
                if (caller === "campaign-add-step") {
                    this.parentCampaignAddStepComponent.closePopupAfterAdd()
                    AlertNotificationsComponent.alertCall.showAlert('Campaign step added successfully', 'primary');
                }

                if (caller === "step-follow-up") {

                    this.parentStepFollowUpComponent.closePopUpAfterCreate();
                    AlertNotificationsComponent.alertCall.showAlert('Step follow up added successfully', 'primary');

                }

                if(caller === "campaign-clone"){
                    this.parentCampaignDetailSummaryComponent.UpdateStepsAfterActions();
                    AlertNotificationsComponent.alertCall.showAlert('Step cloned successfully', 'primary');
                }
                return e;
            },
            Error: (e: String) => {
                if (caller === "campaign-add-step") {
                    this.parentCampaignAddStepComponent.removeLoading()
                }
                if (caller === "step-follow-up") {
                    this.parentStepFollowUpComponent.removeLoading();
                }

                if(caller === "campaign-clone"){
                    this.parentCampaignDetailSummaryComponent.removeLoading();
                }
                AlertNotificationsComponent.alertCall.showAlert('Error while loading campaign steps', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    updateCampaignStep(stepAddModel: CampaignStepModel, caller) {
        const data = JSON.parse(JSON.stringify(stepAddModel));
        let d: IApiServiceDelegate = {
            Data: data,
            Method: "PUT",
            Action: MapAPICollection.PUT_CAMPAIGN_STEP + '/' + stepAddModel.id.toString(),
            Success: (e: any) => {
                if (caller === 'ab-test') {
                    this.parentStepAbTestComponent.closePopupAfterUpdate();
                };

                if (caller === 'step-update') {
                    this.parentCampaignEditStepComponent.closePopupAfterUpdate();
                };
                if (caller === 'send-after') {
                    this.parentStepSendAfterComponent.closePopupAfterUpdate();
                };
                AlertNotificationsComponent.alertCall.showAlert('Campaign step updated successfully', 'primary');
                return e;
            },
            Error: (e: String) => {
                if (caller === 'step-update') {
                    this.parentCampaignEditStepComponent.removeLoading();
                };

                if (caller === 'ab-test') {
                    this.parentStepAbTestComponent.removeLoading();
                };

                AlertNotificationsComponent.alertCall.showAlert('Error while updating campaign step', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }


    deleteCampaign(campaignId) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "DELETE",
            Action: MapAPICollection.DELETE_CAMPAIGN.replace("{id}", campaignId.toString()),
            Success: (e: any) => {
                AlertNotificationsComponent.alertCall.showAlert('Campaign deleted successfully', 'primary');
                this.parentCampaignDetailSettingsComponent.sendToCampaignListing();
                return e;
            },
            Error: (e: String) => {
                this.parentCampaignDetailSettingsComponent.clearLoading();
                AlertNotificationsComponent.alertCall.showAlert('Error while deleting campaign', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    getTemplates(name, page_index, caller){
        var page_size = 10
        var str = "?&page="+String(page_index)+"&page_size=" + String(page_size);
        if (name)
            str += "&name=" + name;
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_ALL_TEMPLATE + str,
            Success: (e: any) => {
                if (caller === 'add-campaignstep')
                    this.parentCampaignAddStepComponent.getTemplateList(e)
                if (caller === 'edit-campaignstep')
                    this.parentCampaignEditStepComponent.getTemplateList(e)
                if (caller === 'followup-campaignstep')
                    this.parentStepFollowUpComponent.getTemplateList(e)
                if (caller === 'abstep-campaignstep')
                    this.parentStepAbTestComponent.getTemplateList(e)
                // AlertNotificationsComponent.alertCall.showAlert('attached file deleted', 'primary');
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while fetching template list', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }


    getSubjects(caller, query, ABTestId?){
        var name = ''
        var page_index = 1
        var page_size = 10
        var str = "?&page="+String(page_index)+"&page_size=" + String(page_size)+"&subject__icontains=" + String(query);
        if (name)
            str += "&name=" + name;
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_ALL_SUBJECT + str,
            Success: (e: any) => {
                  this.SubjectLineAutoSuggestComponent.getSubjectList(e, ABTestId)
                // if (caller === 'add-step-subject')
                    // this.parentCampaignAddStepComponent.getSubjectList(e)
                // if (caller === 'edit-step-subject')
                    // this.parentCampaignEditStepComponent.getSubjectList(e)
                // if (caller === 'ab-step-subject')
                    // this.parentStepAbTestComponent.getSubjectList(e)
                // AlertNotificationsComponent.alertCall.showAlert('attached file deleted', 'primary');
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while fetching template list', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    sendTestEmail(stepId, recipientEmail, currentUser) {
        let d: IApiServiceDelegate = {
            Data: { 'created_by': currentUser, 'recipient_email_address': recipientEmail },
            Method: "POST",
            Action: MapAPICollection.POST_SEND_TEST_EMAIL.replace("{id}", stepId.toString()),
            Success: (e: any) => {
                AlertNotificationsComponent.alertCall.showAlert('Sent test email successfully', 'primary');
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while sending test', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    updateCampaign(campaignModel: CampaignModel) {
        if (!this.validateCampaign(campaignModel)) {
            return;
        }
        if (Number(campaignModel.send_limit_daily).toString() == 'NaN') {
            campaignModel.send_limit_daily = 0;
        }


        let d: IApiServiceDelegate = {
            Data: this.getCampaignPostData(campaignModel),
            Method: "PATCH",
            Action: MapAPICollection.UPDATE_CAMPAIGN.replace("{id}", campaignModel.id.toString()),
            Success: (e: any) => {
                this.parentCampaignDetailSettingsComponent.updateNameOfParent();
                this.getCampaignById(campaignModel.id, 'campaign-detail')
                AlertNotificationsComponent.alertCall.showAlert("Campaign updated successfully", 'primary');
                return e;

            },
            Error: (e: any) => {
                if (e.client !== undefined) {
                    AlertNotificationsComponent.alertCall.showAlert('Unable to find client', 'error');
                }
                else {
                    AlertNotificationsComponent.alertCall.showAlert('Something went wrong', 'danger');
                }
                return e;
            },
        };
        this.apiService.callService(d);

    }

    duplicateCampaign(campaign_id) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "POST",
            Action: MapAPICollection.DUPLICATE_CAMPAIGN.replace("{id}", campaign_id.toString()),
            Success: (e: any) => {
                if(e['dup_cmp_id']){
                    this.parentCampaignDetailSettingsComponent.redirectToCampaignDetailPage(e['dup_cmp_id']);
                } else {
                    this.parentCampaignDetailSettingsComponent.sendToCampaignListing();
                }
                AlertNotificationsComponent.alertCall.showAlert('Duplicate campaign has created', 'primary');
                return e;
            },
            Error: (e: String) => {
                this.parentCampaignDetailSettingsComponent.clearLoading();
                AlertNotificationsComponent.alertCall.showAlert('Error while creating duplicate', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    // Method to save prospect
    saveCampaign(campaignModel: CampaignModel, caller) {

        if (!this.validateCampaign(campaignModel)) {
            return;
        }
        if (Number(campaignModel.send_limit_daily).toString() == 'NaN') {
            campaignModel.send_limit_daily = 0;
        }
        let d: IApiServiceDelegate = {
            Data: this.getCampaignPostData(campaignModel),
            Method: "POST",
            Action: MapAPICollection.ADD_CAMPAIGN,
            Success: (e: any) => {
                // AlertNotificationsComponent.alertCall.showAlert("Campaign added successfully", 'primary');
                if (caller == 'add-campaign') {
                    this.parentAddCampaignsComponent.loadAllCampaigns();
                    AlertNotificationsComponent.alertCall.showAlert("Campaign added successfully", 'primary');
                }
                if (caller == 'campaign-detail') {
                    AlertNotificationsComponent.alertCall.showAlert("Campaign duplicated successfully", 'primary');
                    this.parentCampaignDetailSettingsComponent.sendToCampaignListing();
                }

                if(caller == 'add-to-another-campaign'){
                  this.parentAddToAnotherCampaignComponent.CampaignsAddedToProspect(e)
                }


                return e;

            },
            Error: (e: any) => {
                if (e.client !== undefined) {
                    AlertNotificationsComponent.alertCall.showAlert('Unable to find client', 'error');
                }
                else {
                    AlertNotificationsComponent.alertCall.showAlert('Something went wrong', 'danger');
                }
                return e;
            },
        };
        this.apiService.callService(d);
    }

    uploadAttachment(file, currentUser, caller){
        var formData = new FormData();
        var httpHeaders = new HttpHeaders();
        var uploadContentType = { type: 'multipart/form-data' }
        formData.set('media_file', new Blob([file], uploadContentType), file.name);
        formData.set('created_by', currentUser);
        httpHeaders.append('Accept', 'application/x-www-form-urlencoded');
        this.apiService.uploadFile(String(MapAPICollection.STEP_ATTACHMENT), formData,  httpHeaders).subscribe((e: any) => {

            if(caller === 'add-step'){
                this.parentCampaignAddStepComponent.clearAfterUpload(true, e.res)
            }

            if(caller === 'edit-step'){
                this.parentCampaignEditStepComponent.clearAfterUpload(true, e.res)
            }

            if(caller === 'follow-up'){
                this.parentStepFollowUpComponent.clearAfterUpload(true, e.res)
            }

            AlertNotificationsComponent.alertCall.showAlert('Attachment added successfully', 'primary');
        },
        (error: any) => {
            this.parentCampaignAddStepComponent.clearAfterUpload(false)
            if (error.Errors.duplicate_emails !== undefined || error.Errors.invalid_emails !== undefined) {
            //   this.parentNewImportComponent.showErrorWhileUplaoding(error.Errors.duplicate_emails, error.Errors.invalid_emails);
            }
            else {
            AlertNotificationsComponent.alertCall.showAlert('Something went wrong', 'danger');
            }
        }
        );

    }

    getAttachment(objectId){
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.STEP_ATTACHMENT + '/' + String(objectId),
            Success: (e: any) => {
                this.parentCampaignEditStepComponent.clearAfterUpload(true, e)
                // AlertNotificationsComponent.alertCall.showAlert('attached file deleted', 'primary');
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading attachment file', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    removeAttachment(objectId){
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "DELETE",
            Action: MapAPICollection.STEP_ATTACHMENT + '/' + String(objectId),
            Success: (e: any) => {
                AlertNotificationsComponent.alertCall.showAlert('attached file deleted', 'primary');
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while deleting attached file', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }


    getRegions(){
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_REGIONS,
            Success: (e: any) => {
                this.regionResponseModel.next(e);
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading regions', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    updateProspectIntarestRunlog(runLogID, selectedIntarest = null){
        let d: IApiServiceDelegate = {
            Data: {'prospect_interest_category': selectedIntarest},
            Method: "PATCH",
            Action: MapAPICollection.PATCH_CAMPAIGN_RUNLOG.replace('{id}', String(runLogID)),
            Success: (e: any) => {
                this.parentViewCampaignDetailEmailComponent.prospectIntarestUpdated();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while updating prospect intarest', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);

    }



    createReply(replyModel: ReplyModel) {
        const data = JSON.parse(JSON.stringify(replyModel));
        let d: IApiServiceDelegate = {
            Data: data,
            Method: "POST",
            Action: MapAPICollection.ADD_REPLY,
            Success: (e: any) => {
                this.parentReplyProspectComponent.closePopupAfterUpdate();
                AlertNotificationsComponent.alertCall.showAlert('Reply sent successfully', 'primary');
                return e;
            },
            Error: (e: String) => {
                this.parentReplyProspectComponent.removeLoading();
                AlertNotificationsComponent.alertCall.showAlert('Error while sending reply', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }


    getUserExtras(userIds){
        const url = MapAPICollection.GET_USER_EXTRA + "?user__id=" + userIds;
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: url,
            Success: (e: any) => {
                this.userExtraResponseModel.next(e)
                this.parentCampaignDetailSignatureComponent.userExtrasReceived();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading signature', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    generateABsStep(campaign_step_id, variants) {
        const data = {campaign_step_id:campaign_step_id,variants:variants};
        let d: IApiServiceDelegate = {
            Data: data,
            Method: "POST",
            Action: MapAPICollection.GENERATE_AB_STEPS,
            Success: (e: any) => {
                  this.parentCampaignDetailSummaryComponent.generateSuccess(campaign_step_id)
                  // this.stepAbGenerateComponent.closePopupAfterAdd(e)
                  AlertNotificationsComponent.alertCall.showAlert('AB steps genaration in progress', 'primary');
                 
                return e;
            },
            Error: (e: String) => {
                
                AlertNotificationsComponent.alertCall.showAlert('Error while fetching AB steps', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }




}
