import { Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observer } from "rxjs";
import {
  ProspectModel, ProspectGroupResponseModel, ProspectImportResponseModel,
  UserResponseModel,ColumnMappingResponseModel
} from "../import-model/import-model";
import { OneProspectImportComponent } from "../one-prospect-import/one-prospect-import.component";
import {
  IApiServiceDelegate,
  MapAPIService,
  MapAPICollection,
} from "../../app-providers/services/map-module";
import { AlertNotificationsComponent } from "src/app/Alerts/alert-notifications/alert-notifications.component";
import { ImportsComponent } from "../imports.component";
import { MapCommon } from "src/app/app-providers/map-base/MapCommon";
import { NewImportComponent } from "../new-import/new-import.component";
import { NewImportV2Component } from "../new-import-v2/new-import-v2.component";
import { ImportColMappingComponent } from "../import-col-mapping/import-col-mapping.component";

@Injectable({
  providedIn: "root",
})
export class ImportViewModel {
  parentComponent: OneProspectImportComponent;
  parentImportsComponent: ImportsComponent;
  parentNewImportComponent: NewImportComponent;
  parentNewImportV2Component: NewImportV2Component;
  parentImportColMappingComponent: ImportColMappingComponent;
  // Variables to serve data to component

  prospectGroupModel = new BehaviorSubject<ProspectGroupResponseModel>(
    new ProspectGroupResponseModel()
  );
  prospectImportResponseModel = new BehaviorSubject<ProspectImportResponseModel>(
    new ProspectImportResponseModel()
  );
  columnMappingResponseModel = new BehaviorSubject<ColumnMappingResponseModel>(
    new ColumnMappingResponseModel()
  );

  UserResponseModel = new BehaviorSubject<UserResponseModel>(
    new UserResponseModel()
  );
  constructor(private apiService: MapAPIService) { }

  // Methods to subscribe all variables
  subscribeAllVariables(observerProspectGroup: Observer<ProspectGroupResponseModel>) {
    this.prospectGroupModel.subscribe(observerProspectGroup);
  }
  // Methods to subscribe all variables
  subscribeImportProspectGroupVariables(observerImportProspectGroup: Observer<ProspectGroupResponseModel>) {
    this.prospectGroupModel.subscribe(observerImportProspectGroup);
  }
  subscribeProspectImportVariables(observerProspectImportResponse: Observer<ProspectImportResponseModel>) {
    this.prospectImportResponseModel.subscribe(observerProspectImportResponse);
  }

  subscribeUserImportVariables(observerUserImportResponse: Observer<UserResponseModel>) {
    this.UserResponseModel.subscribe(observerUserImportResponse);
  }

  subscribeColumnMatchingVariables(observerColumnMappingResponseModel: Observer<ColumnMappingResponseModel>) {
    this.columnMappingResponseModel.subscribe(observerColumnMappingResponseModel);
  }

  // to create post data for prospect
  getProspectPostData(prospectObj: ProspectModel) {
    var jsonPostData = {
      "firstname": prospectObj.firstname,
      "middlename": prospectObj.middlename,
      "lastname": prospectObj.lastname,
      "email_address": prospectObj.email_address,
      "company": prospectObj.company,
      "address": prospectObj.address,
      "city": prospectObj.city,
      "state": prospectObj.state,
      "region": prospectObj.region,
      "country": prospectObj.country,
      "phone": prospectObj.phone,
      "job_title": prospectObj.job_title,
      "industry": prospectObj.industry,
      "linkedin": prospectObj.linkedin,
      "snippet": prospectObj.snippet,
      "snippet1": prospectObj.snippet1,
      "snippet2": prospectObj.snippet2,
      "snippet3": prospectObj.snippet3,
      "snippet4": prospectObj.snippet4,
      "score": 0,
      "cnt_campaign": 0,
      "cnt_email_sent": 0,
      "cnt_email_open": 0,
      "cnt_replies_received": 0,
      "created_by": MapCommon.getloggedInUser().id,
      "status": 2,
      "prospect_group": prospectObj.prospect_group
    }
    return jsonPostData;
  }

  validateProspect(prospectObj: ProspectModel) {
    if (prospectObj.firstname == undefined || prospectObj.firstname.trim().length == 0) {
      AlertNotificationsComponent.alertCall.showAlert('Please enter first name', 'danger');
      return false
    }
    // if (prospectObj.lastname == undefined || prospectObj.lastname.trim().length == 0) {
    //   AlertNotificationsComponent.alertCall.showAlert('Please enter last name', 'danger');
    //   return false
    // }
    if (prospectObj.email_address == undefined || prospectObj.email_address.trim().length == 0) {
      AlertNotificationsComponent.alertCall.showAlert('Please enter email', 'danger');
      return false
    }
    if (prospectObj.email_address !== undefined && prospectObj.email_address.trim().length > 0 && !this.validateEmail(prospectObj.email_address)) {
      AlertNotificationsComponent.alertCall.showAlert('Invalid email', 'danger');
      return false
    }
    // if (prospectObj.job_title == undefined || prospectObj.job_title.trim().length == 0) {
    //   AlertNotificationsComponent.alertCall.showAlert('Please enter job title', 'danger');
    //   return false
    // }

    // if (prospectObj.country == undefined || prospectObj.country.trim().length == 0) {
    //   AlertNotificationsComponent.alertCall.showAlert('Please enter country', 'danger');
    //   return false
    // }

    // if (prospectObj.company == undefined || prospectObj.company.trim().length == 0) {
    //   AlertNotificationsComponent.alertCall.showAlert('Please enter company name', 'danger');
    //   return false
    // }


    // if (prospectObj.phone !== undefined || prospectObj.phone.trim().length > 0 && !this.validatePhone(prospectObj.phone)) {
    //   AlertNotificationsComponent.alertCall.showAlert('Invalid phone','danger');
    //   return false
    // }

    // if (prospectObj.linkedin !== undefined && prospectObj.linkedin.trim().length > 0 && !this.validateLinkedInURL(prospectObj.linkedin)) {
    //   AlertNotificationsComponent.alertCall.showAlert('Invalid Linkedin url', 'danger');
    //   return false
    // }

    return true;
  }
  // to validate linkedIn url
  validateLinkedInURL(linkedInUrl) {
    var pattern = new RegExp('^https?://((www|\w\w)\.)?linkedin.com/((in/[^/]+/?)|(pub/[^/]+/((\w|\d)+/?){3}))$'); // fragment locator
    return !!pattern.test(linkedInUrl);
  }
  validateEmail(email) {
    var pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    return !!pattern.test(String(email).toLowerCase());
  }
  validatePhone(phone) {
    var pattern = new RegExp('^((\\+91-?)|0)?[0-9]{10}$');
    return !!pattern.test(phone);
  }

  // Method to save prospect
  saveProspect(prospectObj: ProspectModel) {

    if (!this.validateProspect(prospectObj)) {
      return;
    }
    let d: IApiServiceDelegate = {
      Data: this.getProspectPostData(prospectObj),
      Method: "POST",
      Action: MapAPICollection.ADD_PROSPECTS,
      Success: (e: any) => {
        this.parentComponent.onSuccessSave();
        AlertNotificationsComponent.alertCall.showAlert("Prospect Added successfully", 'primary');
        return e;

      },
      Error: (e: any) => {
        if (e.email_address !== undefined) {
          AlertNotificationsComponent.alertCall.showAlert('Prospect {email_address} already exists'.replace(
            '{email_address}', prospectObj.email_address
          ), 'error');
        }
        else {
          AlertNotificationsComponent.alertCall.showAlert('Something went wrong', 'danger');
        }
        return e;
      },
    };
    this.apiService.callService(d);
  }

  removeErrorCountAfterCorrection(id:number) {
    let d: IApiServiceDelegate = {
      Data: {},
      Method: "DELETE",
      Action: MapAPICollection.GET_PROSPECTS_GROUPS_ERRORS + "/" + id,
      Success: (e: any) => {
        console.log(e, id)
        return e;
      },
      Error: (e: String) => {
        return e;
      },
    };
    this.apiService.callService(d);
  }


  // Method to all users
  getAllUsers() {
    let d: IApiServiceDelegate = {
      Data: {},
      Method: "GET",
      Action: MapAPICollection.GET_ALL_USERS + "?page_size=200",
      Success: (e: any) => {
        this.UserResponseModel.next(e);
        this.parentImportsComponent.UserReceived();
        return e;
      },
      Error: (e: String) => {
        AlertNotificationsComponent.alertCall.showAlert('Error while loading users', 'danger');
        return e;
      },
    };
    this.apiService.callService(d);
  }

  // Method to fetch results from API
  getAllProspectGroups(seachStr) {
    var str = "?&name__contains=" + seachStr + "&page=1&page_size=20";

    let d: IApiServiceDelegate = {
      Data: {},
      Method: "GET",
      Action: MapAPICollection.GET_PROSPECTS_GROUPS + str,
      Success: (e: any) => {
        this.prospectGroupModel.next(e);
        this.parentComponent.ProspectGroupReceived();
        return e;
      },
      Error: (e: String) => {
        return e;
      },
    };
    this.apiService.callService(d);
  }

    // Method to fetch results from API
    getAllProspectGroupsErrorData(prGroup,errType) {
      var str = "?&prospect_group=" + prGroup+ "&error_type=" +errType+ "&page=1&page_size=1000";

      let d: IApiServiceDelegate = {
        Data: {},
        Method: "GET",
        Action: MapAPICollection.GET_PROSPECTS_GROUPS_ERRORS + str,
        Success: (e: any) => {
          this.parentImportsComponent.errorDataReceived(e, errType);
          return e;
        },
        Error: (e: String) => {
          return e;
        },
      };
      this.apiService.callService(d);
    }

  // Method to fetch results from API
  deleteProspectGroup(pg_id) {

    let d: IApiServiceDelegate = {
      Data: {},
      Method: "DELETE",
      Action: MapAPICollection.DELETE_PROSPECTS_GROUPS.replace("{id}", pg_id),
      Success: (e: any) => {
        this.parentImportsComponent.refreshAfterDelete();
        AlertNotificationsComponent.alertCall.showAlert('Prospect group removed successfully', 'primary');
        return e;
      },
      Error: (e: String) => {
        AlertNotificationsComponent.alertCall.showAlert('Error while deleting prospect group', 'danger');
        return e;
      },
    };
    this.apiService.callService(d);
  }

  getAllProspectGroupsForImport(seachStr, created_by, page_index) {
    var str = "?page=" + page_index + "&page_size=40&status=all";
    if (seachStr.length > 0) {
      str += "&name__icontains=" + seachStr;
    }

    if(Number(created_by) > 0){
      str += "&created_by__id=" + created_by;
    }

    let d: IApiServiceDelegate = {
      Data: {},
      Method: "GET",
      Action: MapAPICollection.GET_PROSPECTS_GROUPS + str,
      Success: (e: any) => {
        this.prospectGroupModel.next(e);
        this.parentImportsComponent.ProspectGroupReceived();
        return e;
      },
      Error: (e: String) => {
        AlertNotificationsComponent.alertCall.showAlert('Something wrong happened while loading imports', 'warning');
        return e;
      },
    };
    this.apiService.callService(d);
  }

  // Method to import file and send that to server
  uploadProspectFile(file, fileName) {
    if (!this.validateUploadedFile(file)) {
      AlertNotificationsComponent.alertCall.showAlert('Please upload only csv/xslx file', 'warning');

    } else {

      this.apiService.uploadCSVFile(MapAPICollection.IMPORT_PROSPECTS, file, MapCommon.getloggedInUser().id, fileName).subscribe((e: any) => {
        // result goes here
        this.prospectImportResponseModel.next(e.res);
        this.parentNewImportComponent.clearTable(e);
        AlertNotificationsComponent.alertCall.showAlert('Prospects imported successfully', 'primary');
      },
        (error: any) => {
          if (error.Errors.duplicate_emails !== undefined || error.Errors.invalid_emails !== undefined) {
            if(error.Errors.duplicate_emails !== undefined && error.Errors.invalid_emails !== undefined) {
              this.parentNewImportComponent.showErrorWhileUplaoding(error.Errors.duplicate_emails, error.Errors.invalid_emails);
            }
            else if(error.Errors.invalid_emails !== undefined){
              this.parentNewImportComponent.showErrorWhileUplaoding(null, error.Errors.invalid_emails);
            }
            else {
              this.parentNewImportComponent.showErrorWhileUplaoding(error.Errors.duplicate_emails, null);
            }
          }
          else if (error.Errors.name !== undefined) {
            AlertNotificationsComponent.alertCall.showAlert('Prospect group with this name already exists', 'error');
          }

          else if (error.Errors.length > 0 && (error.Errors.duplicate_emails === undefined || error.Errors.invalid_emails === undefined)) {
            AlertNotificationsComponent.alertCall.showAlert(error.Errors[0], 'danger');
          }
          else {
            AlertNotificationsComponent.alertCall.showAlert('Something went wrong', 'danger');
          }
        }
      );

    }


  }

  getFormattedProspectData(prospectList){
    var newData = []
    prospectList.forEach(data => {
      var prospectData = {
        "firstname": data['firstname'],
        "middlename": data['middlename'],
        "lastname": data['lastname'],
        "email_address": data['email_address'],
        "company": data['company'],
        "address": data['address'],
        "city": data['city'],
        "state": data['state'],
        "region": data['region'],
        "country": data['country'],
        "phone": data['phone'],
        "job_title": data['job_title'],
        "industry": data['industry'],
        "linkedin": data['linkedin'],
        "snippet": data['snippet'],
        "snippet1": data['snippet1'],
        "snippet2": data['snippet2'],
        "snippet3": data['snippet3'],
        "snippet4": data['snippet4']
      }
      newData.push(prospectData)
    });

  return newData
  }

   uploadProspectJson(data, fileName) {
    let d: IApiServiceDelegate = {
      Data: {
        "name": fileName,
        "created_by": MapCommon.getloggedInUser().id,
        "data": this.getFormattedProspectData(data)
      },
      Method: "POST",
      Action: MapAPICollection.POST_PROSPECT_GROUPS_JSON,
      Success: (e: any) => {
        this.prospectImportResponseModel.next(e);
        this.parentNewImportV2Component.clearTable(e);
        AlertNotificationsComponent.alertCall.showAlert('Prospects imported successfully', 'primary');
        return e;
      },
      Error: (error: any) => {
        if (error.duplicate_emails !== undefined || error.invalid_emails !== undefined) {
          if(error.duplicate_emails !== undefined && error.invalid_emails !== undefined) {
            this.parentNewImportV2Component.showErrorWhileUplaoding(error.duplicate_emails, error.invalid_emails);
          }
          else if(error.invalid_emails !== undefined){
            this.parentNewImportV2Component.showErrorWhileUplaoding(null, error.invalid_emails);
          }
          else {
            this.parentNewImportV2Component.showErrorWhileUplaoding(error.duplicate_emails, null);
          }
        }
        else if (error.name !== undefined) {
          AlertNotificationsComponent.alertCall.showAlert('Prospect group with this name already exists', 'error');
        }

        else if (error.length > 0 && (error.duplicate_emails === undefined || error.invalid_emails === undefined)) {
          AlertNotificationsComponent.alertCall.showAlert(error[0], 'danger');
        }
        else {
          AlertNotificationsComponent.alertCall.showAlert('Something went wrong: ' + String(error), 'danger');
        }
        return error;
      },
    };
    this.apiService.callService(d);
  }

  validateUploadedFile(file) {
    // console.log()
    //  return file.type.indexOf('csv') > -1 || file.type.indexOf('xlsx') > -1 || file.type.indexOf('application/vnd.ms-excel') > -1
    if (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      || file.type === "application/vnd.ms-excel" || file.type === "text/csv"
    ) {
      return true
    } else { return false }
  }

  importColMapping(file, currentUser, fileName){
    var formData = new FormData();
    var httpHeaders = new HttpHeaders();
    var uploadContentType = { type: 'multipart/form-data' }
    formData.set('import_file_url', new Blob([file], uploadContentType), file.name);
    formData.set('created_by', currentUser);
    formData.set('name', fileName);


    httpHeaders.append('Accept', 'application/x-www-form-urlencoded');
    this.apiService.uploadFile(String(MapAPICollection.IMPORT_PROSPECT_COL_MATCHING), formData,  httpHeaders).subscribe((e: any) => {
      this.columnMappingResponseModel.next(e.res)
        this.parentImportColMappingComponent.clearAfterUpload(true)
    },
    (error: any) => {
        this.parentImportColMappingComponent.clearAfterUpload(false)
        AlertNotificationsComponent.alertCall.showAlert(error, 'danger');
    }
    );

}


 // Method to all users
 updateProspectGroups(mappedHeader, isSkipped, id) {
  var payload = {
    "mapped_header": mappedHeader,
    "skip_duplicate": isSkipped,
    "validate": false
  }
  let d: IApiServiceDelegate = {
    Data: payload,
    Method: "PATCH",
    Action: MapAPICollection.PROSPECT_GROUP_UPLOAD.replace('{id}', String(id)),
    Success: (e: any) => {
      // if(validate === true){
      //   this.parentImportColMappingComponent.completedValidationProcess(e, true);
      // }
      // if(validate === false){

      // }
      this.parentImportColMappingComponent.completeUploadGroup(e, true);
      AlertNotificationsComponent.alertCall.showAlert('Propect group updated successfully');
      return e;
    },
    Error: (e: String) => {
      AlertNotificationsComponent.alertCall.showAlert('Error while updating propspect group', 'danger');
      // this.parentImportColMappingComponent.completedValidationProcess(e, false)
      return e;
    },
  };
  this.apiService.callService(d);
}

}
