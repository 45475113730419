import { Component, OnInit } from '@angular/core';
import { TemplateModel } from '../template-model/template-model';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { MapAPIService } from "src/app/app-providers/services/map-module";
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { TemplateViewModel } from '../template-model/template-view-model';
import { TemplatesComponent } from '../templates.component';

@Component({
  selector: 'app-template-edit',
  templateUrl: './template-edit.component.html',
  styleUrls: ['./template-edit.component.scss']
})
export class TemplateEditComponent implements OnInit {
  templateModel=  new TemplateModel();;
  // parentTemplatesComponent: TemplatesComponent;
  formErrors = [];
  templateData: any = {};
  constructor( public modalRef: MdbModalRef<TemplateEditComponent>,
    private vm: TemplateViewModel,
    private router: Router) {
     }

  ngOnInit(): void {    
    this.templateModel = this.templateData;  
    this.vm.parentTemplateEditComponent = this; 
  }

  loadAllTemplate() {
    // this.parentTemplatesComponent.filterData();
    this.modalRef.close(true);
  }

  submit() {
    this.formErrors = [];
    // if ((Number(this.templateModel?.name?.length) <= 3) ||
    //   !this.templateModel.active ||
    //   (this.templateModel.name == undefined
    //     || this.templateModel.name == '')) {
    if ((Number(this.templateModel?.name?.length) <= 3) || (this.templateModel.name == undefined || this.templateModel.name == '')) {
      this.formErrors.push("Template name can't be less than 3");
    }
    //}

    else {
      this.vm.editTemplate(this.templateModel,'edit');
    }
  }

}
