import { Component, OnInit } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { MapCommon } from 'src/app/app-providers/map-base/MapCommon';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { TemplateModel } from '../template-model/template-model';

@Component({
  selector: 'app-template-preview',
  templateUrl: './template-preview.component.html',
  styleUrls: ['./template-preview.component.scss']
})
export class TemplatePreviewComponent implements OnInit {
  templateData: TemplateModel;
  constructor(public modalRef: MdbModalRef<TemplatePreviewComponent>) { }

  ngOnInit(): void {
  }

}
