import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AnalyticsClientResponseModel, AnalyticsClientsModel, RegionResponseModel, SubjectListResponseModel } from '../models/model';
import {AnalyticsViewModel} from '../models/viewmodel'
@Component({
  selector: 'app-subject-line',
  templateUrl: './subject-line.component.html',
  styleUrls: ['./subject-line.component.scss'],
  providers:[AnalyticsViewModel,DatePipe]
})
export class SubjectLineComponent implements OnInit {
  subjectList = [];
  clientList: [AnalyticsClientsModel];
  searchSubject: string = '';
  searchByRegion: number;
  search_start_date: string;
  search_end_date: string;
  bsMaxDate = new Date();
  column_selected;
  page_index = 1;
  sortDirection = 'asc'
  search_by_client: any ;
  ordering:string
  subjectListModel:SubjectListResponseModel
  subjectListResponseModel = new BehaviorSubject<SubjectListResponseModel>(
    new SubjectListResponseModel()
  );
  regionResponseModel = new BehaviorSubject<RegionResponseModel>(
    new RegionResponseModel()
  );

  clientResponseModel = new BehaviorSubject<AnalyticsClientResponseModel>(
    new AnalyticsClientResponseModel()
  );

  constructor(private vm:AnalyticsViewModel, private datePipe: DatePipe ) { }

  ngOnInit(): void {
    this.vm.subscribeSubjectLine(this.subjectListResponseModel, this.regionResponseModel, this.clientResponseModel)
    this.vm.SubjectLineComponentRef = this
    this.vm.getAllClients('subject-line');
    this.vm.getRegions();
    this.getSubjectDataList();
  }

  getSubjectList(data:any){

    if(this.subjectListResponseModel.value.previous){
      this.subjectList.push(...data.results)

    } else{
      this.subjectList = data.results
    }

  }

  onValueChange(value: Date): void {
    if (value) {
      this.search_start_date = this.datePipe.transform(value[0], "YYYY-MM-dd");
      this.search_end_date = this.datePipe.transform(value[1], "YYYY-MM-dd");
    } else {
      this.search_start_date = undefined;
      this.search_end_date = undefined;
    }

    this.filterCalled();
  }

  ClientRecieved(){
    this.clientList = this.clientResponseModel.value.results;
  }

  changeSubject(e:any){
    this.filterCalled();
  }

  onRegionSelected(){
    this.filterCalled();
  }

  onClientSelected(){
    this.filterCalled();
  }

  filterCalled(){
    this.page_index = 1
    this.getSubjectDataList();
  }

  // add this function to trigger subject
  sortOn( column: string) {
    this.column_selected = column;
    if (this.sortDirection === 'asc'){
      this.sortDirection = 'desc';
      this.ordering = '-'+column
    }
    else{
      this.sortDirection = 'asc';
      this.ordering = column
    }
    this.vm.getSubjects(this.searchSubject, this.searchByRegion, this.page_index, this.search_by_client, this.search_start_date,
      this.search_end_date , this.ordering)
    // if(column === 'subject'){
    //   if(this.sortDirection === 'asc'){
    //     this.subjectList.sort((a,b) => a.subject.localeCompare(b.subject))
    //   } else{
    //     this.subjectList.sort((a,b) => b.subject.localeCompare(a.subject))
    //   }
    // } else {
    //   if(this.sortDirection === 'asc'){
    //     this.subjectList.sort((a,b) => a.avg_open_rate - b.avg_open_rate)
    //   } else{
    //     this.subjectList.sort((a,b) => b.avg_open_rate - a.avg_open_rate )
    //   }
    // }
  }

  onSubjectScroll(){
    if(this.subjectListResponseModel.value.next){
      this.page_index++
      this.getSubjectDataList();
    }
  }


  getSubjectDataList(){
    this.vm.getSubjects(this.searchSubject, this.searchByRegion, this.page_index, this.search_by_client, this.search_start_date,
      this.search_end_date , this.ordering)
  }

  getRegionName(region){
    var regionName = '';
    this.regionResponseModel.value.results.length >0 ? this.regionResponseModel.value.results.forEach(el => {
      if(el.id == region){
        regionName = el.name;
      }
    }) : regionName = 'Default'
    // if(regionName.length == 0){
    //   regionName = 'Default'
    // }
    return regionName;
  }


}
