import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { BehaviorSubject } from 'rxjs';
import { MapCommon } from 'src/app/app-providers/map-base/MapCommon';
import { ClientsResponseModel, ProspectDncModel, ProspectDncResponseModel } from '../../setting-model/setting-model';
import { SettingViewModel } from '../../setting-view-model/setting-view-model';
import { AddProspectDncComponent } from '../add-prospect-dnc/add-prospect-dnc.component';
import { EditProspectDncComponent } from '../edit-prospect-dnc/edit-prospect-dnc.component';

@Component({
  selector: 'app-prospect-dnc',
  templateUrl: './prospect-dnc.component.html',
  styleUrls: ['./prospect-dnc.component.scss']
})
export class ProspectDncComponent implements OnInit {
  page_index = 1;
  prospectDncList = [];
  clientsList = [];
  prospectDncListB4Search = [];
  currentUser: number;
  addProspectmodalRef: MdbModalRef<AddProspectDncComponent>;
  editProspectModalRef: MdbModalRef<EditProspectDncComponent>;

  prospectDncResponseModel = new BehaviorSubject<ProspectDncResponseModel>(
    new ProspectDncResponseModel()
  );

  clientResponseModel = new BehaviorSubject<ClientsResponseModel>(
    new ClientsResponseModel()
  );

  prospectSearchValue = '';
  @ViewChild(NgForm) prospectSearchForm: NgForm;

  constructor(private vm: SettingViewModel, private modalService: MdbModalService,) {
    this.currentUser = MapCommon.getloggedInUser().id;
    this.vm.parentSettingProspectDnc = this;
    this.vm.subscribeClientsVariable(this.clientResponseModel);
    this.vm.getAllClients();
    this.vm.subscribeProspectDncVariable(this.prospectDncResponseModel);
    this.vm.getAllProspectDnc(this.page_index);
  }

  ngOnInit(): void {

  }

  clientsReceived() {
    this.clientsList = this.clientResponseModel.value.results;

  }

  prospectSearchSubmit(e: any) {
    this.vm.searchProspectDnc(this.prospectSearchForm.value.search)
  }

  prospectDncReceived() {
    if (this.prospectDncListB4Search.length == 0)
      this.prospectDncList = this.prospectDncResponseModel.value.results;
    else {
      this.prospectDncList = this.prospectDncResponseModel.value.results;
      if (this.prospectDncResponseModel.value.previous !== null) {
        for (var j = 0; j < this.prospectDncList.length; j++) {
          var index = this.prospectDncListB4Search.findIndex(x => x.id == this.prospectDncList[j].id);
          if (index === -1)
            this.prospectDncListB4Search.push(this.prospectDncList[j]);
        }
        this.prospectDncList = this.prospectDncListB4Search;
      }
    }

  }

  openProspectModal() {
    this.addProspectmodalRef = this.modalService.open(AddProspectDncComponent, { data: { clients: this.clientsList } });
  }

  getClientName(clientID: number) {
    try {
      var result = this.clientsList.filter(obj => {
        return obj.id === Number(clientID);
      })
      return (result[0].name).toString();
    } catch {
      return 'Global';
    };
  }

  editProspectModal(prospectDncModel: ProspectDncModel) {
    prospectDncModel.modified_by = this.currentUser;
    const json_data = JSON.stringify(prospectDncModel);
    this.editProspectModalRef = this.modalService.open(EditProspectDncComponent, {
      data: { prospectDncModel: JSON.parse(json_data), clients: this.clientsList }
    });
  }

  onProspectDNCScroll() {
    if (this.prospectDncResponseModel.value.next) {
      this.prospectDncListB4Search = this.prospectDncList;
      this.page_index++;
      this.vm.getAllProspectDnc(this.page_index);
    }
  }
}
