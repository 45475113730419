<div class="ms-sm-auto" style="width: 90%;">




  <div class="row" style="height: 100%;">

      <div class="col-2 shadow-3 " style="background: #FBF7FE; height: 100vh; overflow-x:hidden;
      overflow-y: auto; z-index: 1;">
         <app-analytics-sidebar></app-analytics-sidebar>
       </div>



      <div class="col-10 m-0" style="height: 100vh; overflow-x: hidden; overflow-y: auto; ">
        <div class="row shadow-1 p-3 sticky-top bg-white" style="z-index: 2;">
          <div class="col-md-12 px-0">
            <h4 class="map-text-header">Global Subject line Performance (Across all campaigns)
                <!-- <span
                style="font-size: 12px; color: #ef6c00;">({{totalClientRecieved}} found)</span> -->
              </h4>
          </div>







        </div>
        <div class="row py-4">
          <!-- <div class="col-3">
            <input type="text"  placeholder="Start date - End date" class="form-control w-100"
            bsDaterangepicker [bsConfig]="{ isAnimated: true, containerClass: 'theme-default', rangeInputFormat: 'DD/MM/YYYY'}"
            #dp="bsDaterangepicker" [maxDate]="bsMaxDate" (bsValueChange)="onValueChange($event)">
          </div> -->
          <div class="col-md-6">
            <div class="d-flex justify-content-end">
                  <mdb-form-control style="width: 100%;">
                    <input mdbInput (keyup)="changeSubject($event)" type="text"
                    [(ngModel)]="searchSubject"  id="search" class="form-control"
                     name="search"  />
                    <label mdbLabel class="form-label" for="search">
                      <i class="fas fa-search"></i> Search</label>
                  </mdb-form-control>
            </div>
          </div>

          <!-- <div class="col-4">
            <ng-select [(ngModel)]="search_by_client"  [multiple]="true"  id="clientInput" (change)='onClientSelected()'
                placeholder="Select client">
                <ng-option *ngFor="let o of clientList" [value]="o.id">{{o.name}}</ng-option>
              </ng-select>
          </div> -->
          <div class="col-6">
            <ng-select id="regionInput"  [(ngModel)]="searchByRegion" (change)='onRegionSelected()' class="header-imput w-100"
            placeholder="Region">
              <ng-option *ngFor="let o of regionResponseModel.value?.results" [value]="o.id">{{o.name}}</ng-option>
              </ng-select>
          </div>
        </div>
        <div class="row">
          <div class="col-12">

            <div #reportContainer style="height:100vh;overflow-x:scroll" class="table-responsive bg-white" infiniteScroll [infiniteScrollDistance]="1"
          [infiniteScrollThrottle]="100" (scrolled)="onSubjectScroll()" [scrollWindow]="false">

          <table class="table table-sm table-hover">
            <thead>
              <tr>
                <th width="44%" [class]="column_selected == 'subject' ? 'header-name active' : 'header-name'" scope="col"
                  (click)="sortOn('subject')"><i class="fas fa-sort"></i> Subject Line</th>

                <th [class]="column_selected == 'region' ? 'header-name active' : 'header-name'" scope="col" style="text-align: center;"
                (click)="sortOn('region')"><i class="fas fa-sort"></i> Region</th>

                <th [class]="column_selected == 'avg_rate' ? 'header-name active' : 'header-name'" scope="col" style="text-align: center;"
                  (click)="sortOn('avg_open_rate')"><i class="fas fa-sort"></i> Average Open rate</th>

                <!-- <th [class]="column_selected == 'avg_rate' ? 'header-name active' : 'header-name'" scope="col"
                  (click)="sortOn('last_open_rate')"><i class="fas fa-sort"></i> Last Open rate</th>

                <th [class]="column_selected == 'avg_rate' ? 'header-name active' : 'header-name'" scope="col"
                  (click)="sortOn('last_open_date')"><i class="fas fa-sort"></i> Last Open date</th> -->
              </tr>
            </thead>
            <tbody>

              <tr *ngFor="let subject of subjectList;">
                <td><strong> {{subject.subject.length < 110 ? subject.subject : subject.subject.slice(0,110)+'....' }} </strong></td>
                <td align="center">{{getRegionName(subject.region)}}</td>
                <td align="center"><span class="px-2 py-2 small fst-italic" [ngClass]="{'text-success' : subject.avg_open_rate > 5.0,'text-danger' : subject.avg_open_rate < 5.0}"> {{subject.avg_open_rate | number:'1.2-2'}}% </span></td>
                <!-- <td align="center"><span class="px-2 py-2 small fst-italic" [ngClass]="{'text-success' : subject.last_open_rate > 5.0,'text-danger' : subject.last_open_rate < 5.0}"> {{subject.last_open_rate | number:'1.2-2'}}% </span></td>
                <td align="center"><strong> {{subject.last_open_date | date  }} </strong></td> -->
              </tr>

            </tbody>
          </table>

        </div>
       </div>
      </div>
      </div>

  </div>
</div>
