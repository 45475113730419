import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { UnsubscribeProspectModel } from './models/unsubscribeModel';
import { UnsubscribeViewModel } from './models/unsubscribeViewModel';


@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribeComponent implements OnInit {

  UnsubscribeOptions = [
    {id: 1, name: "This Event", checked: true},
    {id: 2, name: "BuyerForesight", checked: false},
    // {id: 3, name: "Don't send to me or my company", checked: false}       
  ];

  currentURL: string = null;
  validationStatus: boolean = true;
  optOutSuccessStatus: boolean = false;
  optOutSuccessMessage: string = null;
  campaignProspect: UnsubscribeProspectModel;
  mainSlug: string;
  requestedProspectID: number;
  formError: string = '';

  ItemSelected: number;
  @ViewChild(NgForm) unsubscribeCheckboxForm: NgForm;

  unsubscribeProspectModel = new BehaviorSubject<UnsubscribeProspectModel>(
    new UnsubscribeProspectModel()
  );

  constructor(private router: Router, private vm: UnsubscribeViewModel) {
    this.currentURL = this.router.url;
    this.validateCurrentUrl();
    this.vm.parentUnsubscribeComponent = this;
    this.vm.subscribeUnsubscribeProspectVariable(this.unsubscribeProspectModel)
   }

  ngOnInit(): void {
    if(this.validationStatus){
      this.vm.getProspectEmail(this.requestedProspectID)
    }
  }

  cantFindProspectEmail() {
    this.validationStatus = false;
  }

  prospectEmailReceived(){
    this.campaignProspect = this.unsubscribeProspectModel.value;
    if (!this.campaignProspect.email_address) {
      this.validationStatus = false;
    }
  }

  validateCurrentUrl(){
    this.mainSlug = this.currentURL.replace('/opt-out/', '')
    var splitedSlugItems = this.mainSlug.split('-')
    // validations
    if(splitedSlugItems.length >= 4 || splitedSlugItems.length <= 0){this.validationStatus = false}
    if(this.validationStatus){
      if(!splitedSlugItems[0].includes('cm') 
      || !splitedSlugItems[1].includes('st') 
      || !splitedSlugItems[2].includes('pr')
      ){this.validationStatus = false}    
    }  

    if(this.validationStatus){
      this.requestedProspectID = Number(splitedSlugItems[2].substring(2));
    } 
  }


  unsubscribeClicked(e: any){
    if(!this.ItemSelected){
      this.formError = "Please select one"
    } else {
       const urlString = this.mainSlug + "?opt_out_reason=" + this.ItemSelected.toString();
       this.vm.unsubscribeMe(urlString);
    }
  }

  unsubsribeSuccess(){
    this.optOutSuccessStatus = true;
    if(Number(this.ItemSelected) === 1) {
      this.optOutSuccessMessage = "You will no longer receive any email communications against this event."
    } else {
      this.optOutSuccessMessage = "You will no longer receive any email communications from us."
    }
  }

  unsubsribeFailed(){
    this.validationStatus = false;
    this.optOutSuccessStatus = false;
    this.campaignProspect = null;
  }

}
