import { Component, OnInit } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ConfirmDialogService } from 'src/app/admin/confirm-dialog/confirm-dialog.service';
import { CampaignViewModel } from '../../campaign-view-model/campaign-view-model';

@Component({
  selector: 'app-step-ab-generate',
  templateUrl: './step-ab-generate.component.html',
  styleUrls: ['./step-ab-generate.component.scss']
})
export class StepAbGenerateComponent implements OnInit {
  ABSTEPS:number = 5
  campaignId;
  stepId
  isLoading:boolean = false
  constructor(
    public modalRef: MdbModalRef<StepAbGenerateComponent>,
    private vm: CampaignViewModel, private modalService: MdbModalService,
    private confirmDialogService: ConfirmDialogService,
  ) { this.vm.stepAbGenerateComponent = this }

  ngOnInit(): void {
    console.log(this.modalRef , this.confirmDialogService)
    console.log(   this.campaignId  ,this.stepId)
  }

  generateAbSteps(){
    this.isLoading = true
    this.vm.generateABsStep(this.stepId.id,this.ABSTEPS)
  }
  closePopupAfterAdd(e){
    this.isLoading = false
    this.modalRef.close(e)
  }
}
