


<div class="container-fluid">
    <div class="row">

      

    <ng-container *ngIf="isLoggedIn">
      <app-sidenav></app-sidenav>
    </ng-container> 

    <router-outlet>
      
    </router-outlet>

    <app-alert-notifications></app-alert-notifications>
    <app-confirm-dialog></app-confirm-dialog>  

    </div>
  </div>
