import { Component, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CompanyDncModel, CompanyDncResponseModel } from '../../setting-model/setting-model';
import { SettingViewModel } from '../../setting-view-model/setting-view-model';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { AddCompanyDncComponent } from '../add-company-dnc/add-company-dnc.component';
import { EditCompanyDncComponent } from '../edit-company-dnc/edit-company-dnc.component';
import { NgForm } from '@angular/forms';
import { MapCommon } from 'src/app/app-providers/map-base/MapCommon';

@Component({
  selector: 'app-company-dnc',
  templateUrl: './company-dnc.component.html',
  styleUrls: ['./company-dnc.component.scss']
})
export class CompanyDncComponent implements OnInit {

  companyDncList = [];
  companyDncListB4Search = [];
  page_index = 1;
  currentUser: number;
  addCompanyModalRef: MdbModalRef<AddCompanyDncComponent>;
  editCompanyModalRef: MdbModalRef<EditCompanyDncComponent>;
  
  companyDncResponseModel = new BehaviorSubject<CompanyDncResponseModel>(
    new CompanyDncResponseModel()
  );

  companySearchValue = '';
  @ViewChild(NgForm) companySearchForm: NgForm;

  constructor(private vm: SettingViewModel, private modalService: MdbModalService) { 
    this.currentUser = MapCommon.getloggedInUser().id;
    this.vm.parentSettingCompanyDnc = this;
    this.vm.subscribeCompanyDncVariable(this.companyDncResponseModel);
    this.vm.getAllCompanyDnc(this.page_index);
  }

  ngOnInit(): void {
  }

  companySearchSubmit(e: any) {
    this.vm.searchCompanyDnc(this.companySearchForm.value.search)
  }


  companyDncReceived() {
    if (this.companyDncListB4Search.length == 0)
      this.companyDncList = this.companyDncResponseModel.value.results;
    else {
      this.companyDncList = this.companyDncResponseModel.value.results;
      if (this.companyDncResponseModel.value.previous !== null) {
        for (var j = 0; j < this.companyDncList.length; j++) {
          var index = this.companyDncListB4Search.findIndex(x => x.id == this.companyDncList[j].id);
          if (index === -1)
            this.companyDncListB4Search.push(this.companyDncList[j]);
        }
        this.companyDncList = this.companyDncListB4Search;
      }
    }
  }

  addCompanyModal() {
    this.addCompanyModalRef = this.modalService.open(AddCompanyDncComponent)
  }

  editCompanyModal(companyDncModel: CompanyDncModel){
    companyDncModel.modified_by = this.currentUser;
    const json_data = JSON.stringify(companyDncModel);
    this.editCompanyModalRef = this.modalService.open(EditCompanyDncComponent, { 
      data: { companyDncModel: JSON.parse(json_data),}
    });
  }

  onClientDNCScroll()
  {
    if (this.companyDncResponseModel.value.next) {
      this.companyDncListB4Search = this.companyDncList;
      this.page_index++;
      this.vm.getAllCompanyDnc(this.page_index);
    }
  }

}
