import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { CampaignAddComponent } from './campaign-add/campaign-add.component';
import {
  CampaignClientResponseModel, CampaignResponseModel,
  CampaignFilterModel, CampaignUserResponseModel, RegionResponseModel
} from "./campaign-model/campaign-model";
import { MapAPIService } from "src/app/app-providers/services/map-module";
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { CampaignViewModel } from './campaign-view-model/campaign-view-model';
import { MapCommon } from '../app-providers/map-base/MapCommon';
@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.scss'],
  providers: [MapAPIService, CampaignViewModel],
})

export class CampaignsComponent implements OnInit {
  @ViewChild("campaignContainer") campaignContainer: ElementRef;
  page_index = 1;
  modalRef: MdbModalRef<CampaignAddComponent>;
  campaignlist = [];
  campaignlistB4Search = [];
  userList = [];
  clientList = [];
  totalCampaignRecieved: number;
  campaignFilterModel: CampaignFilterModel;
  loadingCamp :boolean = false
  modalOptions = {
    data: {
      parentCampaignComponent: this,
      actionCaller: 'add-campaign'
    },
    ignoreBackdropClick: true
  }
  sortDirection: string = 'desc';
  column_selected: string = '';
  column_level = 0;
  sortActive: boolean = false;
  sortByColumn(list: any[] | undefined, column: string, level, direction = 'desc'): any[] {
    let sortedArray = (list || []).sort((a, b) => {
      if (level === 0) {
        if (a[column] > b[column]) {
          return (direction === 'desc') ? 1 : -1;
        }
        if (a[column] < b[column]) {
          return (direction === 'desc') ? -1 : 1;
        }
        return 0;
      }
      if (level === 1) {
        if (Number(a[column].length) > Number(b[column].length)) {
          return (direction === 'desc') ? 1 : -1;
        }
        if (Number(a[column].length) < Number(b[column].length)) {
          return (direction === 'desc') ? -1 : 1;
        }
        return 0;
      }
    })
    return sortedArray;
  };

  campaignResponseModel = new BehaviorSubject<CampaignResponseModel>(
    new CampaignResponseModel()
  );

  campaignUserResponseModel = new BehaviorSubject<CampaignUserResponseModel>(
    new CampaignUserResponseModel()
  );

  campaignClientResponseModel = new BehaviorSubject<CampaignClientResponseModel>(
    new CampaignClientResponseModel()
  );

  regionResponseModel = new BehaviorSubject<RegionResponseModel>(
    new RegionResponseModel()
  );


  constructor(private modalService: MdbModalService, private vm: CampaignViewModel, private router: Router) { }

  ngOnInit(): void {
    this.vm.parentComponent = this;
    this.vm.subscribeAllVariables(
      this.campaignResponseModel,
      this.campaignUserResponseModel,
      this.campaignClientResponseModel,
      this.regionResponseModel);
    // this.vm.getAllUsers('compaign');
    // this.vm.getAllClients('compaign');
    this.vm.getRegions();
    this.campaignFilterModel = new CampaignFilterModel();
    this.campaignFilterModel.page = 1;
    this.campaignFilterModel.page_size = 30;
    // this.vm.getAllCampaign(this.campaignFilterModel, 'campaign-list');

    // this.addCampaignModal();
  }


  goToCampaign(id){
    this.router.navigate(['campaigns', id])
  }

  // add this function to trigger subject
  sortOn(level: number, column: string) {
    this.column_selected = column;
    this.sortActive = true;
    // this.sortByColumn(this.campaignlist, column, level, this.sortDirection);
    if (this.sortDirection === 'asc'){
      this.sortDirection = 'desc';
      this.column_selected = '-'+this.column_selected
    } else{
      this.sortDirection = 'asc';
      this.column_selected = this.column_selected
    }

    this.campaignContainer.nativeElement.scrollTop = 0;
    this.vm.getAllCampaign(this.campaignFilterModel, 'campaign-list',this.column_selected);
    this.loadingCamp = true
  }

  getRegion(campaignItem: any) {
    if (campaignItem.region) {
      const item = this.regionResponseModel.value.results.find(el => el.id == campaignItem.region)
      if (item) {
        campaignItem.region_name = item.name;
        return campaignItem.region_name;
      } else {

        return campaignItem.region;
      }
    } else {
      campaignItem.region_name = '-';
      return campaignItem.region_name
    }
  }

  addCampaignModal() {
    this.modalOptions.data.parentCampaignComponent = this;
    this.modalRef = this.modalService.open(CampaignAddComponent, this.modalOptions)
  }

  CampaignReceived() {
    if (this.campaignlistB4Search.length == 0)
      this.campaignlist = this.campaignResponseModel.value.results;
    else {
      this.campaignlist = this.campaignResponseModel.value.results;
      if (this.campaignResponseModel.value.previous !== null) {
        for (var j = 0; j < this.campaignlist.length; j++) {
          var index = this.campaignlistB4Search.findIndex(x => x.id == this.campaignlist[j].id);
          if (index === -1)
            this.campaignlistB4Search.push(this.campaignlist[j]);
        }
        this.campaignlist = this.campaignlistB4Search;
      }
    }
    this.totalCampaignRecieved = this.campaignResponseModel.value.count;
    this.loadingCamp = false
  }

  ClientRecieved(e: any = null) {
    if(e){
      this.clientList = e
    } else {
      this.clientList =  this.campaignClientResponseModel.value.results;
    }
  }

  callFilter(campaignFilterModel: CampaignFilterModel) {
    this.page_index = 1;
    campaignFilterModel.page = 1;
    campaignFilterModel.page_size = 30;
    this.vm.getAllCampaign(campaignFilterModel, 'campaign-list');
    this.loadingCamp = true
    this.campaignFilterModel = campaignFilterModel;
    // this.campaignContainer.nativeElement.scrollTop = 0;
  }



  loadAllCampaigns() {
    this.vm.getAllCampaign(this.campaignFilterModel, 'campaign-list');
    this.loadingCamp = true
  }

  UserRecieved(e: any = null) {
    if(e){
      this.userList = e
    } else {
      this.userList = this.campaignUserResponseModel.value.results;
    }


  }

  getUserName(id) {
    var userItem = this.userList.filter(i => i.id == Number(id));
    if (userItem.length > 0) {
      if(userItem[0].email?.length > 0) {
        return userItem[0].email;
      } else {
        if(userItem[0].username?.length > 0){
          return userItem[0].username
        }
      }
    }
    return ""
  }


  getUserEmail(id) {
    var userItem = this.userList.filter(i => i.id == Number(id));
    if (userItem.length > 0) {
      return userItem[0].email;
    }
  }

  getClientName(clientRow : any) {
    var clientItem = this.clientList.filter(i => i.id == Number(clientRow.client));
    if (clientItem.length > 0) {
      clientRow.clientName = clientItem[0].name;
      return clientItem[0].name;
    }
    else
    {
      clientRow.clientName = "";
      return "";
    }
  }

  gotoDetailPage(campaignDataById) {
    const campaignId = campaignDataById.id
    const campaignDetailUrl = '/campaigns/' + campaignId
    this.router.navigate([campaignDetailUrl], {
      state: {
        routeData: JSON.stringify({ campaignData: campaignDataById }),
        clientName: this.getClientName(campaignDataById),
        userEmail: this.getUserEmail(campaignDataById.created_by)
      }
    });
  }

  // This method is being called once user hits bottom while scrolling campaigns
  onCampaignScroll() {
    if (this.campaignResponseModel.value.next) {
      this.campaignlistB4Search = this.campaignlist;
      this.page_index++;
      this.campaignFilterModel.page = this.page_index;
      this.vm.getAllCampaign(this.campaignFilterModel, 'campaign-list',);
    }
  }

}
