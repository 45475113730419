import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgSelect2Module } from 'ng-select2';
import { ResizableModule } from 'angular-resizable-element';
// MDBBootstrap
//import { MdbModule } from 'mdb-angular-ui-kit/modal';
import { MdbAccordionModule } from 'mdb-angular-ui-kit/accordion';
import { MdbCarouselModule } from 'mdb-angular-ui-kit/carousel';
import { MdbCheckboxModule } from 'mdb-angular-ui-kit/checkbox';
import { MdbCollapseModule } from 'mdb-angular-ui-kit/collapse';
import { MdbDropdownModule } from 'mdb-angular-ui-kit/dropdown';
import { MdbFormsModule } from 'mdb-angular-ui-kit/forms';
import { MdbModalModule } from 'mdb-angular-ui-kit/modal';
import { MdbPopoverModule } from 'mdb-angular-ui-kit/popover';
import { MdbRadioModule } from 'mdb-angular-ui-kit/radio';
import { MdbRangeModule } from 'mdb-angular-ui-kit/range';
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { MdbScrollspyModule } from 'mdb-angular-ui-kit/scrollspy';
import { MdbTabsModule } from 'mdb-angular-ui-kit/tabs';
import { MdbTooltipModule } from 'mdb-angular-ui-kit/tooltip';
import { MdbValidationModule } from 'mdb-angular-ui-kit/validation';
// ng-select
import { NgSelectModule } from '@ng-select/ng-select';
import { ChartsModule } from 'ng2-charts';


// Components  
import { SidenavComponent } from './sidenav/sidenav.component';
import { ProspectsComponent } from './prospects/prospects.component';
import { ImportsComponent } from './imports/imports.component';
import { NewImportComponent } from './imports/new-import/new-import.component';
import { CopyPasteImportComponent } from './imports/copy-paste-import/copy-paste-import.component';
import { OneProspectImportComponent } from './imports/one-prospect-import/one-prospect-import.component';
import { SidebarImportComponent } from './imports/sidebar-import/sidebar-import.component';
import { CampaignsComponent } from './campaigns/campaigns.component';
import { SidebarProspectsComponent } from './prospects/sidebar-prospects/sidebar-prospects.component';
import { SidebarCampaignsComponent } from './campaigns/sidebar-campaigns/sidebar-campaigns.component';
import { ProspectDetailComponent } from './prospects/prospect-detail/prospect-detail.component';
import { ProspectEditComponent } from './prospects/prospect-edit/prospect-edit.component';
import { ProspectDeleteComponent } from './prospects/prospect-delete/prospect-delete.component';
import { ClientsComponent } from './clients/clients.component';
import { CampaignDeleteComponent } from './campaigns/campaign-delete/campaign-delete.component';
import { CampaignEditComponent } from './campaigns/campaign-edit/campaign-edit.component';
import { CampaignDetailComponent } from './campaigns/campaign-detail/campaign-detail.component';
import { CampaignAddComponent } from './campaigns/campaign-add/campaign-add.component';
import { CampaignDetailSummaryComponent } from './campaigns/campaign-detail-summary/campaign-detail-summary.component';
import { CampaignDetailProspectsComponent } from './campaigns/campaign-detail-prospects/campaign-detail-prospects.component';
import { CampaignDetailEmailsComponent } from './campaigns/campaign-detail-emails/campaign-detail-emails.component';
import { CampaignDetailSettingsComponent } from './campaigns/campaign-detail-settings/campaign-detail-settings.component';
import { SettingsComponent } from './settings/settings.component';
import { ReportsComponent } from './reports/reports.component';
import { SidebarSettingsComponent } from './settings/sidebar-settings/sidebar-settings.component';
import { SidebarReportsComponent } from './reports/sidebar-reports/sidebar-reports.component';
import { SidebarClientsComponent } from './clients/sidebar-clients/sidebar-clients.component';
import { SettingsPersonalComponent } from './settings/settings-personal/settings-personal.component';
import { SettingsStatusesComponent } from './settings/settings-statuses/settings-statuses.component';
import { ReportsCampaignsComponent } from './reports/reports-campaigns/reports-campaigns.component';
import { CampaignMailboxAlertComponent } from './campaigns/campaign-mailbox-alert/campaign-mailbox-alert.component';
import { SettingsGroupsComponent } from './settings/settings-groups/settings-groups.component';
import { SettingsMailboxComponent } from './settings/settings-mailbox/settings-mailbox.component';
import { SettingsSendingWindowsComponent } from './settings/settings-sending-windows/settings-sending-windows.component';
import { SettingsDncComponent } from './settings/settings-dnc/settings-dnc.component';
import { AlertNotificationsComponent } from './Alerts/alert-notifications/alert-notifications.component';
import { LoginComponent } from './login/login.component';
import { CampaignAddStepComponent } from './campaigns/campaign-add-step/campaign-add-step.component';
import { ClientAddComponent } from './clients/client-add/client-add.component';
import { ClientEditComponent } from './clients/client-edit/client-edit.component';
import { ClientDeleteComponent } from './clients/client-delete/client-delete.component';
import { MarkDncComponent } from './prospects/mark-dnc/mark-dnc.component';
import { SettingsGroupsAddComponent } from './settings/settings-groups-add/settings-groups-add.component';
import { AddSendingWindowsComponent } from './settings/settings-sending-windows/add-sending-windows/add-sending-windows.component';
import { MapAPIService } from './app-providers/services/map-api-service';
import { ProspectDncComponent } from './settings/settings-dnc/prospect-dnc/prospect-dnc.component';
import { CompanyDncComponent } from './settings/settings-dnc/company-dnc/company-dnc.component';
import { AddCompanyDncComponent } from './settings/settings-dnc/add-company-dnc/add-company-dnc.component';
import { AddProspectDncComponent } from './settings/settings-dnc/add-prospect-dnc/add-prospect-dnc.component';
import { EditCompanyDncComponent } from './settings/settings-dnc/edit-company-dnc/edit-company-dnc.component';
import { EditProspectDncComponent } from './settings/settings-dnc/edit-prospect-dnc/edit-prospect-dnc.component';
import { TimePickerModalComponent } from './settings/settings-sending-windows/time-picker-modal/time-picker-modal.component';
import { ConfirmDialogComponent } from './admin/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogService } from './admin/confirm-dialog/confirm-dialog.service';
import { UpdateSendingWindowsComponent } from './settings/settings-sending-windows/update-sending-windows/update-sending-windows.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { CampaignEditStepComponent } from './campaigns/campaign-edit-step/campaign-edit-step.component';
import { ViewCampaignDetailEmailComponent } from './campaigns/campaign-detail-emails/view-campaign-detail-email/view-campaign-detail-email.component';
import { AddToCampaignComponent } from './prospects/add-to-campaign/add-to-campaign.component';
import { StepSendTestComponent } from './campaigns/campaign-detail-summary/step-send-test/step-send-test.component';
import { StepAbTestComponent } from './campaigns/campaign-detail-summary/step-ab-test/step-ab-test.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ShowSendingWindowComponent } from './campaigns/campaign-detail-summary/show-sending-window/show-sending-window.component';
import { UnsubInternalPageComponent } from './unsubscribe/unsub-internal-page/unsub-internal-page.component';
import { CampaignStepReviewComponent } from './campaigns/campaign-step-review/campaign-step-review.component';
import { CampaignDetailAutoReplyComponent } from './campaigns/campaign-detail-auto-reply/campaign-detail-auto-reply.component';
import { CampaignStepCustomizeComponent } from './campaigns/campaign-step-review/campaign-step-customize/campaign-step-customize.component';
import { DncComponent } from './dnc/dnc.component';
import { DncGlobalComponent } from './dnc/dnc-global/dnc-global.component';
import { DncClientComponent } from './dnc/dnc-client/dnc-client.component';
import { DncDomainComponent } from './dnc/dnc-domain/dnc-domain.component';
import { SidebarDncComponent } from './dnc/sidebar-dnc/sidebar-dnc.component';
import { DncDomainAddComponent } from './dnc/dnc-domain/dnc-domain-add/dnc-domain-add.component';
import { DncDomainEditComponent } from './dnc/dnc-domain/dnc-domain-edit/dnc-domain-edit.component';
import { DncClientAddComponent } from './dnc/dnc-client/dnc-client-add/dnc-client-add.component';
import { DncClientEditComponent } from './dnc/dnc-client/dnc-client-edit/dnc-client-edit.component';
import { DncGlobalEditComponent } from './dnc/dnc-global/dnc-global-edit/dnc-global-edit.component';
import { DncGlobalAddComponent } from './dnc/dnc-global/dnc-global-add/dnc-global-add.component';
import { NoSanitizeHtmlPipe} from './app-providers/services/nosanitizerpipe';
import { NoSanitizeUrlPipe } from './app-providers/services/nosanitizerpipeUrl';
import { LoadingDialogComponent } from './admin/loading-dialog/loading-dialog.component';
import { SefetyChecksComponent } from './prospects/sefety-checks/sefety-checks.component';
import { SpinnerComponent } from './admin/spinner/spinner.component';
import { StepFollowUpComponent } from './campaigns/campaign-detail-summary/step-follow-up/step-follow-up.component';
import { ShowImportStatsComponent } from './prospects/sidebar-prospects/show-import-stats/show-import-stats.component';
import { PasswordResetComponent } from './login/password-reset/password-reset.component';
import { PasswordConfirmComponent } from './login/password-confirm/password-confirm.component';
import { LinkTrackingComponent } from './tools/link-tracking/link-tracking.component';
import { HomeComponent } from './home/home.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { AnalyticsSidebarComponent } from './analytics/analytics-sidebar/analytics-sidebar.component'; 
import { LinkTrackingAnalyticsComponent } from './analytics/link-tracking-analytics/link-tracking-analytics.component';
import { OpenTrackingAnalyticsComponent } from './analytics/open-tracking-analytics/open-tracking-analytics.component';
import { MaintenanceModeComponent } from './maintenance-mode/maintenance-mode.component';
import { NewImportV2Component } from './imports/new-import-v2/new-import-v2.component'; 
import { APIServiceInterceptor } from './app-providers/interceptors/APIService.interceptor';
import { BestSendingTimeComponent } from './analytics/best-sending-time/best-sending-time.component';
import { OptOutTrackingComponent } from './analytics/opt-out-tracking/opt-out-tracking.component';
import { StepSendAfterComponent } from './campaigns/campaign-detail-summary/step-send-after/step-send-after.component';
import { AddToAnotherCampaignComponent } from './campaigns/add-to-another-campaign/add-to-another-campaign.component';
import { ImportDncComponent } from './dnc/import-dnc/import-dnc.component';
import { HelpComponent } from './help/help.component';
import { SidebarHelpComponent } from './help/sidebar-help/sidebar-help.component';
import { HelpAboutComponent } from './help/help-about/help-about.component';
import { HelpFeedbackComponent } from './help/help-feedback/help-feedback.component';
import { HelpDocsComponent } from './help/help-docs/help-docs.component'; 
import { EncryptMsgService } from './app-providers/services/encryptMsgService';
import { ImportColMappingComponent } from './imports/import-col-mapping/import-col-mapping.component';
import { ReplyProspectComponent } from './reply-prospect/reply-prospect.component';
import { TemplatesComponent } from './templates/templates.component';
import { TemplateAddComponent } from './templates/template-add/template-add.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TemplateEditComponent } from './templates/template-edit/template-edit.component';
import { TemplatePreviewComponent } from './templates/template-preview/template-preview.component';
import { CampaignDetailSignatureComponent } from './campaigns/campaign-detail-signature/campaign-detail-signature.component';
import { PlusPipe } from './plus.pipe';
import { SubjectLineAutoSuggestComponent } from './campaigns/subject-line-auto-suggest/subject-line-auto-suggest.component';
import { SubjectLineComponent } from './analytics/subject-line/subject-line.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { RephraseAiComponent } from './rephrase-ai/rephrase-ai.component';
import { StepAbGenerateComponent } from './campaigns/campaign-detail-summary/step-ab-generate/step-ab-generate.component';
 
@NgModule({
  declarations: [
    AppComponent,
    NoSanitizeHtmlPipe,
    NoSanitizeUrlPipe,
    SidenavComponent,
    ProspectsComponent,
    ImportsComponent,
    NewImportComponent,
    CopyPasteImportComponent,
    OneProspectImportComponent,
    SidebarImportComponent,
    CampaignsComponent,
    SidebarProspectsComponent,
    SidebarCampaignsComponent,
    ProspectDetailComponent,
    ProspectEditComponent,
    ProspectDeleteComponent,
    ClientsComponent,
    CampaignAddComponent,
    CampaignDeleteComponent,
    CampaignEditComponent,
    CampaignDetailComponent,
    CampaignDetailSummaryComponent,
    CampaignDetailProspectsComponent,
    CampaignDetailEmailsComponent,
    CampaignDetailSettingsComponent,
    SettingsComponent,
    ReportsComponent,
    SidebarSettingsComponent,
    SidebarReportsComponent,
    SidebarClientsComponent,
    SettingsPersonalComponent,
    SettingsStatusesComponent,
    ReportsCampaignsComponent,
    CampaignMailboxAlertComponent,
    SettingsGroupsComponent,
    SettingsMailboxComponent,
    SettingsSendingWindowsComponent,
    SettingsDncComponent,
    AlertNotificationsComponent,
    LoginComponent,
    CampaignAddStepComponent,
    ClientAddComponent,
    ClientEditComponent,
    ClientDeleteComponent,
    MarkDncComponent,
    SettingsGroupsAddComponent,
    AddSendingWindowsComponent,
    ProspectDncComponent,
    CompanyDncComponent,
    AddCompanyDncComponent,
    AddProspectDncComponent,
    EditCompanyDncComponent,
    EditProspectDncComponent,
    TimePickerModalComponent,
    ConfirmDialogComponent,
    UpdateSendingWindowsComponent,
    UnsubscribeComponent,
    CampaignEditStepComponent,
    ViewCampaignDetailEmailComponent,
    AddToCampaignComponent,
    StepSendTestComponent,
    StepAbTestComponent,
    ShowSendingWindowComponent,
    UnsubInternalPageComponent,
    CampaignStepReviewComponent,
    CampaignDetailAutoReplyComponent,
    CampaignStepCustomizeComponent,
    DncComponent,
    DncGlobalComponent,
    DncClientComponent,
    DncDomainComponent,
    SidebarDncComponent,
    DncDomainAddComponent,
    DncDomainEditComponent,
    DncClientAddComponent,
    DncClientEditComponent,
    DncGlobalEditComponent,
    DncGlobalAddComponent,
    LoadingDialogComponent,
    SefetyChecksComponent,
    SpinnerComponent,
    StepFollowUpComponent,
    ShowImportStatsComponent,
    PasswordResetComponent,
    PasswordConfirmComponent,
    LinkTrackingComponent,
    HomeComponent,
    AnalyticsComponent,
    AnalyticsSidebarComponent, 
    LinkTrackingAnalyticsComponent, 
    OpenTrackingAnalyticsComponent, 
    MaintenanceModeComponent,
    NewImportV2Component,
    BestSendingTimeComponent,
    OptOutTrackingComponent,
    StepSendAfterComponent,
    AddToAnotherCampaignComponent,
    ImportDncComponent,
    HelpComponent,
    SidebarHelpComponent,
    HelpAboutComponent,
    HelpFeedbackComponent,
    HelpDocsComponent,
    ImportColMappingComponent,
    ReplyProspectComponent,
    TemplatesComponent,
    TemplateAddComponent,
    DashboardComponent,
    TemplateEditComponent,
    TemplatePreviewComponent,
    CampaignDetailSignatureComponent,
    PlusPipe,
    SubjectLineAutoSuggestComponent,
    SubjectLineComponent,
    UnauthorizedComponent,
    RephraseAiComponent,
    StepAbGenerateComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
 
    // Ng-select
    NgSelectModule,    
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),

    // MDBBootstrap
    //MdbModule,
    MdbAccordionModule,
    MdbCarouselModule,
    MdbCheckboxModule,
    MdbCollapseModule,
    MdbDropdownModule,
    MdbFormsModule,
    MdbModalModule,
    MdbPopoverModule,
    MdbRadioModule,
    MdbRangeModule,
    MdbRippleModule,
    MdbScrollspyModule,
    MdbTabsModule,
    MdbTooltipModule,
    MdbValidationModule,
    // Editor
    EditorModule,
    HttpClientModule, 
    TypeaheadModule.forRoot(),
    NgSelect2Module,
    InfiniteScrollModule,
    ResizableModule,
    ChartsModule

  ],
  providers: [
    MapAPIService, 
    ConfirmDialogService, 
    { provide: HTTP_INTERCEPTORS, useClass: APIServiceInterceptor, multi: true },
    EncryptMsgService, 
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }  

