<app-loading-dialog *ngIf="isLoading">

</app-loading-dialog>

<div class="modal-header">
  <h5 class="modal-title" *ngIf="!this.ABStepCreateItems" id="exampleModalLabel">AB Test</h5>
  <h5 class="modal-title"  *ngIf="this.ABStepCreateItems" id="exampleModalLabel">Select Steps</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">
  <div class="form-group" *ngFor="let fieldValue of fieldValues; let count=index;">
    <div class="row">
      <div class="col">

        <i class="fas fa-envelope fa-lg"></i>

        <span class="ps-1">Subject {{count + 1}}
          <span *ngIf="!(campaignStepModel.status === 'Finished' || campaignStepModel.status === 'Processing' || isLoading)">
            <span mdbDropdown class="dropdown me-4">
            <button class="dropdown-toggle veriable-button" id="mapSubjectItems" aria-expanded="false" mdbDropdownToggle> Variables</button>
            <ul mdbDropdownMenu class="dropdown-menu" aria-labelledby="mapSubjectItems" style="overflow-y: scroll; height: 250px;" >
              <li *ngFor="let variable of variables" (click)="addVariableinSubject(variable, fieldValue)">
                <span class="dropdown-item">{{variable}}</span></li>
            </ul>
          </span>
        </span>
        </span>
        <!-- <mdb-form-control>
          <input [disabled]="(is_followStep || campaignStepModel.status === 'Finished' || campaignStepModel.status === 'Processing')"
          [(ngModel)]="fieldValue.stepSubjectModel" mdbInput type="text" id="subject" class="form-control" />
        </mdb-form-control> -->

        <app-subject-line-auto-suggest  id={{returnVal(count)}} [getFieldValues]="fieldValues"  [subjectID]="count" name={{returnVal(count)}} [getSubjectModel]="fieldValue.stepSubjectModel" [disabled]="(is_followStep || campaignStepModel.status === 'Finished' || campaignStepModel.status === 'Processing')" (stepSubEvent)="addItem($event ,count)"> </app-subject-line-auto-suggest>

        <!-- <div class="col-12 d-flex p-0">
          <input #campaignType [(ngModel)]="fieldValue.stepSubjectModel" (typeaheadOnSelect)="onSelect($event)"
            (typeaheadLoading)="changeTypeaheadLoading(fieldValue.stepSubjectModel)" [typeaheadMinLength]="0" [typeahead]="subjectList"
            typeaheadOptionField="name" typeahead-wait-ms="100" class="form-control form-control" name="Subject" type="text"
            (paste)="onPaste($event)" placeholder="Search Subject" autocomplete="off" />
          <span class="highlight"></span>
        </div> -->




      </div>

      <div class="d-flex justify-content-between">
        <div class="form-check form-switch mt-2">
          <input mdbCheckbox  class="form-check-input"   type="checkbox" id="flexSwitchCheckDefault"
          [(ngModel)]="fieldValue.enableTemplate"  />
          <label class="form-check-label" for="flexSwitchCheckDefault" >Enable Template</label >
        </div>
       
      </div>

    </div>

    <div class="row mt-2">
      <div class="col">
        <div class="row" *ngIf="!fieldValue.enableTemplate">
          <div class="text-danger fw-bold fst-italic  text-end  mt-2"> <small> *Attention: Change font as per your need.</small></div>
          <editor apiKey="{{editorAPIKey}}" [init]="editormodules" [(ngModel)]="fieldValue.emailBodyModel"></editor>
          <span class="ab-variant"
          *ngIf="!(is_followStep || campaignStepModel.status === 'Finished' || campaignStepModel.status === 'Processing')"
          style="float: right;"
          (click)="deleteFieldValue($event, count)"><i class="fas fa-trash mt-3"></i> Delete</span>
        </div>

        <div class="row" *ngIf="fieldValue.enableTemplate">
          <div class="col-3 fixed">
            <mdb-form-control class="mt-2 mb-2">
              <input mdbInput type="text" id="form1" [(ngModel)]="search_by_name" class="form-control"
                  (keyup)="inputkeyUp()" (keydown)="inputKeyDown()" />
              <label mdbLabel class="form-label" for="form1">Search</label>
            </mdb-form-control>
            <!-- <div class="bg-white"> -->


            <div  class="template-items" infiniteScroll [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="10" (scrolled)="onTemplateScroll()" [scrollWindow]="false"
            #campaignContainer>

              <div class="list-group " >
                <a href="javascript:void(0)" (click)="mapTemplate(i, fieldValues.indexOf(fieldValue))" *ngFor="let i of templatelistB4Search; let index=index; "
                 [class]="i.id == selectedTemplate ? 'list-group-item list-group-item-action active ':
                 'list-group-item list-group-item-action template-item'" aria-current="true">
                 <i class="fas fa-file-image"></i> {{i.name.length > 17 ? (i.name|slice:0:17) + '...':i.name}}
                </a>
              </div>

            </div>

              <!-- <label for="templateModel" class="form-label">Template: </label>
              <textarea [(ngModel)]="templateModel.body" class="form-control w-60" id="templateModel" rows="15"
              value="{{templateModel.body}}"  name="html_content"> </textarea>  -->

          </div>

          <div class="col-9 border-start" style="background-color: #F3F3F3;">
              <iframe *ngIf="fieldValue.emailBodyModel"
                  height="100%" width="100%"  [srcdoc] = "fieldValue.emailBodyModel | noSanitizeHtml"></iframe>
              <div class="preview" *ngIf="!fieldValue.emailBodyModel">
                No Template selected
              </div>
          </div>

        </div>

      </div>
    </div>

    <div class="row">
      <div class="col text-center mt-2">
        <label mdbLabel class="form-label" style="color: red;" for="error">{{fieldValue.mandatoryError}}</label>
      </div>
    </div>




  </div>


  <div class=" p-2">
    <div class="row d-flex justify-content-between">
      <hr>
      <div class="col ps-3">
        <span class="ab-variant" *ngIf="!(is_followStep || campaignStepModel.status === 'Finished' || campaignStepModel.status === 'Processing')"
        (click)="addFieldValue($event)"><i class="fas fa-plus"></i> Add a new variant</span>
      </div>
      <div class="col d-flex justify-content-end pe-4">
        <button type="button" class="btn btn-link" (click)="modalRef.close()">
          Close
        </button>
        <button type="button" class="btn btn-primary"
          [disabled]="(campaignStepModel.status === 'Finished' || campaignStepModel.status === 'Processing' || isLoading)"
          (click)="updateStep()">Save changes</button>
       </div>
    </div>

  </div>
</div>
