import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter} from '@angular/core';
import { CampaignModel, CampaignStatsModel, CampaignStatsResponseModel, CampaignStepResponseModel,
   ScheduleTimezoneModel, SendingWindowsModel, CampaignMasterStat, StepTreeNode, FollowupConditionModel, FollowupConditionResponseModel, CampaignStepModel, UserextraPrams } from "../campaign-model/campaign-model";
import { MapAPIService } from "src/app/app-providers/services/map-module";
import { BehaviorSubject } from 'rxjs';
import { CampaignViewModel } from '../campaign-view-model/campaign-view-model';
import { CampaignAddStepComponent } from '../campaign-add-step/campaign-add-step.component';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { CampaignEditStepComponent } from '../campaign-edit-step/campaign-edit-step.component';
import { MapCommon } from 'src/app/app-providers/map-base/MapCommon';
import { ConfirmDialogService } from 'src/app/admin/confirm-dialog/confirm-dialog.service';
import { StepSendTestComponent } from './step-send-test/step-send-test.component';
import { StepAbTestComponent } from './step-ab-test/step-ab-test.component';
import { CampaignDetailComponent } from '../campaign-detail/campaign-detail.component';
import { ShowSendingWindowComponent } from './show-sending-window/show-sending-window.component';
import { Router } from '@angular/router';
import { AlertNotificationsComponent } from 'src/app/Alerts/alert-notifications/alert-notifications.component';
import { StepFollowUpComponent } from './step-follow-up/step-follow-up.component';
import { StepSendAfterComponent } from './step-send-after/step-send-after.component';
import { HostListener } from '@angular/core';
import { StepAbGenerateComponent } from './step-ab-generate/step-ab-generate.component';


@Component({
  selector: 'app-campaign-detail-summary',
  templateUrl: './campaign-detail-summary.component.html',
  styleUrls: ['./campaign-detail-summary.component.scss'],
  providers: [MapAPIService, CampaignViewModel],
})
export class CampaignDetailSummaryComponent implements OnInit {

  @Input() campaignState:string = '' ;
  @Input() campaignDetail:CampaignModel  ;
  @Output('limitReached') limitReached= new EventEmitter<boolean>()

  campaignId: number;
  campaignReportStats: any = [];
  campaignSteps: any = [];
  cnt_total: number = 0;
  cnt_sent: number = 0;
  cnt_reached: number = 0;
  cnt_opened: number = 0;
  cnt_replied: number = 0;
  cnt_bounced: number = 0;
  cnt_opted_out: number = 0;
  cnt_clv_rejection: number = 0;
  cnt_dnc_rejection: number = 0;
  campaign_reason: string = '';
  service_error_paused: boolean = false;
  showLimitReached: boolean = null

  timeFetched:string
  stepsList: any = [];
  showExistingSteps: boolean = false;
  addStepModalRef: MdbModalRef<CampaignAddStepComponent>;
  editStepModalRef: MdbModalRef<CampaignEditStepComponent>;
  sendAfterModalRef: MdbModalRef<StepSendAfterComponent>;
  // stepSendTestModalRef: MdbModalRef<StepSendTestComponent>;
  AbTestModalRef: MdbModalRef<StepAbTestComponent>;
  stepAbGenerateRef: MdbModalRef<StepAbGenerateComponent>;
  stepAbCreateRef: MdbModalRef<CampaignEditStepComponent>;
  followUpModalRef: MdbModalRef<StepFollowUpComponent>;
  showSendingWindowModalRef: MdbModalRef<ShowSendingWindowComponent>;
  currentUser: number;
  campaignSendMode: string;
  static siblingCall;

  sendingWindows: SendingWindowsModel;
  scheduleTimezone: ScheduleTimezoneModel;

  followUpConditions: [FollowupConditionModel];
  campaignStepsTree: StepTreeNode[];

  isLoading: boolean = false;

  followupConditionResponseModel = new BehaviorSubject<FollowupConditionResponseModel>(
    new FollowupConditionResponseModel()
  );


  campaignStatsResponseModel = new BehaviorSubject<CampaignStatsResponseModel>(
    new CampaignStatsResponseModel()
  );

  campaignStepsResponseModel = new BehaviorSubject<CampaignStepResponseModel>(
    new CampaignStepResponseModel()
  );

  sendingWindowResponseModel = new BehaviorSubject<SendingWindowsModel>(
    new SendingWindowsModel()
  );

  scheduleTimezoneResponseModel = new BehaviorSubject<ScheduleTimezoneModel>(
    new ScheduleTimezoneModel()
  );

  campaignMasterStat = new BehaviorSubject<CampaignMasterStat>(
    new CampaignMasterStat()
  );

  statsAutoUpdateInterval: any


  sendingRateList = MapCommon.getSendingRateList();

  constructor(private vm: CampaignViewModel, private modalService: MdbModalService,
    private confirmDialogService: ConfirmDialogService, private router: Router , ) {
    this.currentUser = MapCommon.getloggedInUser().id;
    CampaignDetailSummaryComponent.siblingCall = this;
  }

  ngOnDestroy():void {
    this.stopAutoRefreshStats();
  }

  ngOnInit(): void {
    var url = window.location.href;
    this.campaignId = Number(url.substring(url.lastIndexOf('/') + 1));
    this.vm.parentCampaignDetailSummaryComponent = this;
    this.vm.subscribeSummaryVariables(
      this.campaignStatsResponseModel,
      this.campaignStepsResponseModel,
      this.sendingWindowResponseModel,
      this.scheduleTimezoneResponseModel,
      this.campaignMasterStat,
      this.followupConditionResponseModel
       );
    this.vm.getAllFollowUpConditions('detail-summary');
    this.isLoading = true;
    this.vm.getReportCampaignStats(this.campaignId);

    this.startAutoRefreshStats();
  }



  @HostListener('window:visibilitychange', ['$event'])
  visibilityChange(event: FocusEvent): void {
    console.log("Window hidden status = > ", document.hidden, " = >", document.visibilityState);
    if(document.hidden === true){
      console.log( "Window Hidden");
      this.stopAutoRefreshStats();
    } else {
      console.log("Window Focused");
      this.startAutoRefreshStats();
    }
  }


  // @HostListener('window:focus', ['$event'])
  // onFocus(event: FocusEvent): void {
  //   console.log("Focused tab");
  //   this.startAutoRefreshStats();
  // }


  // @HostListener('window:blur', ['$event'])
  // onBlur(event: FocusEvent): void {
  //   console.log( "Tab is not focused")
  //   this.stopAutoRefreshStats();
  // }


  startAutoRefreshStats(){
    this.statsAutoUpdateInterval = setInterval(() => {
      this.autoRefreshCampaignStatsAndSteps();
      console.log("%cStats updated: PID %s",
      'color: white; background: green; font-size: 10px',
      this.statsAutoUpdateInterval)
      }, 10000);
  }

  stopAutoRefreshStats(){
    if (this.statsAutoUpdateInterval) {
      clearInterval(this.statsAutoUpdateInterval);
      console.log("%cAuto update destroyed: PID %s",
      'color: white; background: red; font-size: 10px',
      this.statsAutoUpdateInterval)
    }
  }


  getSteps(){
    return this.campaignSteps;
  }

  getDownloadUrl(media_link){
    return MapCommon.getBaseApiUrl() + media_link;
  }

  getOpenRateProbability(number){
      if (number <= 150) {
        return '100%'
      } else if ( number > 150 && number <= 250) {
        return '75%'
      } else if ( number > 250 && number <= 300) {
        return '50%'
      } else if (number > 300) {
        return '25%'
      } else {
        return null
      }
  }

  getConditionAsText(id){
    var result = this.followUpConditions.find(obj => {
      if (obj.id === Number(id)){
        return obj.condition;
      }
    });
    return result.condition;
  }


  getSendAfterText(waitTime: number){
    var seconds = (waitTime * 60);
    var d = Math.floor(seconds / (3600*24));
    var h = Math.floor(seconds % (3600*24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    // var s = Math.floor(seconds % 60);

    var dDisplay = d > 0 ? d + 'D' : "";
    var hDisplay = h > 0 ? h + 'H' : "";
    var mDisplay = m > 0 ? m + 'M' : "";
    // var sDisplay = s > 0 ? s + 'S' : "";

    var str = '';

    if (dDisplay.length > 0){
      str = dDisplay;
    }

    if (hDisplay.length > 0){
      if(dDisplay.length == 0){
        str = str + hDisplay;
      } else {
        str = str + '-' + hDisplay;
      }
    }


    if(mDisplay.length > 0){
      if(dDisplay.length == 0 && hDisplay.length == 0){
        str = str + mDisplay;
      } else {
        str = str + '-' + mDisplay;
      }
    }

    return str
  }

  getSendingRateAsText(id){
    var result = this.sendingRateList.find(obj => {
      if (obj.id === Number(id)){
        return obj.text;
      }
    });
    return result.text.split('(')[0];
  }

  followUpConditionsReceived() {
    this.followUpConditions = this.followupConditionResponseModel.value.results;
  }

  storeCampaignSendingMode(sending_mode){
    this.campaignSendMode = sending_mode;
  }


  callSendingWindow(scheduleId) {
    this.vm.getSendingWindowById(scheduleId, 'detail-summary');
  }

  scheduleTimezoneReceived() {
    this.scheduleTimezone = this.scheduleTimezoneResponseModel.value;
  }

  sendingWindowsReceived() {
    this.sendingWindows = this.sendingWindowResponseModel.value;
    this.vm.getTimezoneById(this.sendingWindows.timezone);
  }


  CampaignReportStatsRecieved(timeStatsReceived:any) {

    // sessionStorage.setItem("campaignReportStatsTime", timeStatsReceived);
    // this.timeFetched = timeStatsReceived

    // Auto refresh the stats and processes
    // if (this.statsAutoUpdateInterval) {
    //   clearInterval(this.statsAutoUpdateInterval);
    // }
    // if(this.campaignState === 'Processing'  ){
    //   this.statsAutoUpdateInterval = setInterval(() => {
    //     this.autoRefreshCampaignStatsAndSteps();
    //     console.log("%cStats updated: PID %s",
    //     'color: white; background: green; font-size: 10px',
    //     this.statsAutoUpdateInterval)
    //   }, 10000);
    // }

    this.campaignReportStats = this.campaignStatsResponseModel.value;
    this.campaignReportStats = this.campaignMasterStat.value;
    if (this.campaignReportStats.stats.length > 0)
      this.showExistingSteps = true;
    this.fillUpTheCounts();

  }

  // fetchData(){
  //   sessionStorage.removeItem("campaignReportStatsTime")
  //   this.vm.getReportCampaignStats(this.campaignId);

  // }

  fillUpTheCounts() {
    this.cnt_total = this.campaignReportStats.stats.total_emails;
    this.cnt_sent = this.campaignReportStats.stats.emails_sent.total;
    this.cnt_reached = this.campaignReportStats.stats.emails_sent.reached.total;
    this.cnt_opened = this.campaignReportStats.stats.emails_sent.reached.opened;
    this.cnt_replied = this.campaignReportStats.stats.emails_sent.reached.replied.total +
    this.campaignReportStats.stats.emails_sent.reached.replied.auto_reply.total;
    this.cnt_bounced = this.campaignReportStats.stats.emails_sent.bounced;
    this.cnt_opted_out = this.campaignReportStats.stats.emails_sent.reached.opted_out;
    this.cnt_clv_rejection = this.campaignReportStats.stats.emails_restricted.restricted_by_clv;
    this.cnt_dnc_rejection = this.campaignReportStats.stats.emails_restricted.restricted_by_client_dnc+ this.campaignReportStats.stats.emails_restricted.restricted_by_global_dnc;
    this.vm.getCampaignSteps(this.campaignId, 'detail-summary');
  }


  GetCampaignTreeData(data_list: any){

    var data = data_list.reduce(function (r, a) {
      function getParent(s, b) {
        return b.id === a.followup_from ? b : (b.children && b.children.reduce(getParent, s));
      }

      var index = 0, node;
      if ('followup_from' in a) {
        node = r.reduce(getParent, {});
      }
      if (node && Object.keys(node).length) {
        node.children = node.children || [];
        node.isExpanded = true;
        node.children.push(a);
        node.is
      } else {
        while (index < r.length) {
          if (r[index].followup_from === a.id) {
            a.children = (a.children || []).concat(r.splice(index, 1));
          } else {
            index++;
          }
        }
        r.push(a);
      }
      return r;
    }, []);
    return data;
  }



  CampaignStepsRecieved() {
    this.campaignSteps = this.campaignStepsResponseModel.value.results;
    if (this.campaignReportStats.step_stats.length > 0) {
      for( var i=this.campaignSteps.length - 1; i>=0; i--){
        for( var j=0; j<this.campaignReportStats.step_stats.length; j++){

        if(this.campaignSteps[i] && (this.campaignSteps[i].id === this.campaignReportStats.step_stats[j].id)){
          this.campaignSteps[i].step_stats=this.campaignReportStats.step_stats[j];
            if (this.campaignSteps[i].ab_variants.length > 0){
            // console.log(this.campaignSteps[i].step_stats.stats_by_variants)
            this.campaignSteps[i].ab_variants.forEach(element => {
              element.stats = this.campaignSteps[i].step_stats.stats_by_variants.find(el => {return el.name == element.subject})
            });
            }

         }

         if(this.campaignSteps[i].mailbox_connection.is_connected != false){
          var awsLimit = this.campaignSteps[i].mailbox_connection.send_limit.filter(i => i.provider == 'AWSSes')[0]
          var gmailLimit = this.campaignSteps[i].mailbox_connection.send_limit.filter(i => i.provider == 'Gmail')[0]
          var outlookLimit = this.campaignSteps[i].mailbox_connection.send_limit.filter(i => i.provider == 'Outlook')[0]
          var sendgridLimit = this.campaignSteps[i].mailbox_connection.send_limit.filter(i => i.provider == 'SendGrid')[0]
          if (awsLimit) {
            if (awsLimit.limit_remaining == 0 || awsLimit.limit_remaining < 100) {
              this.showLimitReached = true;
              this.limitReached.emit(this.showLimitReached)
            }
          }

          if (gmailLimit) {
            if (gmailLimit.limit_remaining == 0 || gmailLimit.limit_remaining < 100) {
              this.showLimitReached = true;
              this.limitReached.emit(this.showLimitReached)
            }
          }

          if (outlookLimit) {
            if (outlookLimit.limit_remaining == 0 || outlookLimit.limit_remaining < 100) {
              this.showLimitReached = true;
              this.limitReached.emit(this.showLimitReached)
            }
          }

          if (sendgridLimit) {
            if (sendgridLimit.limit_remaining == 0 || sendgridLimit.limit_remaining < 100) {
              this.showLimitReached = true;
              this.limitReached.emit(this.showLimitReached)
            }
          }

        }

        }
      }
      this.showExistingSteps = true;


    }
    
    this.campaignSteps.forEach(element =>  {
      if(element.step_stats.total_prospect > 0) {
        element.open_rate_probability = this.getOpenRateProbability(element.step_stats.total_prospect)
      } else {
        element.open_rate_probability = null
      }
    })

    this.campaignSteps.forEach(element => {
      element.children = [];
      element.isExpanded = false;
      element.space_style = 0;
    });

    this.campaignStepsTree = this.GetCampaignTreeData(this.campaignSteps);
    this.isLoading = false;


    this.getReason();
  }


  getReason(){
    var reasons = this.campaignStepsTree.filter(el => (el.status_text?.length > 0))
    var response = ''
    if (reasons.length > 0) {
      response = reasons[0].status_text;
    }
    else if (this.campaignSendMode === 'Auto' && !this.sendingWindows){
      response = "(Campaign would get fired immediately)"
    }

    else if (this.campaignSendMode === 'Auto' && this.sendingWindows){
      response = "(Campaign would get fired as per sending window)"
    }

    this.campaign_reason = response;
    if(this.campaign_reason.includes('Non Resumable')){
      this.service_error_paused = true;
    }

    // return response
  }

  followUp(step) {
    console.log(step)
    const stepJson = JSON.parse(JSON.stringify(step))
    const modalOptions = {
      data: {
        campaignId: this.campaignId,
        currentUser: this.currentUser,
        currentStep: stepJson,
        sending_rate:step.sending_rate,
      },
      modalClass: 'modal-lg',
      ignoreBackdropClick: true
    }

    modalOptions.data.campaignId = this.campaignId;
    this.followUpModalRef = this.modalService.open(StepFollowUpComponent, modalOptions);
    this.followUpModalRef.onClose.subscribe((message: any) => {
      if (message === true) {
        this.vm.getReportCampaignStats(this.campaignId);
        // this.vm.getCampaignSteps(this.campaignId);
      }
    });
  }




  UpdateStepsAfterActions(){
    this.isLoading = false;
    this.vm.getReportCampaignStats(this.campaignId);
  }



  AddStepPopup() {
    const modalOptions = {
      data: {
        campaignId: this.campaignId,
        currentUser: this.currentUser,
        campaignDetail: this.campaignDetail
      },
      modalClass: 'modal-lg',
      ignoreBackdropClick: true
    }

    modalOptions.data.campaignId = this.campaignId;
    this.addStepModalRef = this.modalService.open(CampaignAddStepComponent, modalOptions);
    this.addStepModalRef.onClose.subscribe((message: any) => {
      if (message === true) {
       // this.vm.parentCampaignDetailSummaryComponent = this;
       // this.vm.getCampaignSteps(this.campaignId);
       this.vm.getReportCampaignStats(this.campaignId);
      //  this.vm.getCampaignSteps(this.campaignId);
      }
    });
  }


  showSendingWindow(sendingWindowObject){

    const modalOptions = {
      data: {
        sendingWindowModel: sendingWindowObject,
        scheduleTimezoneName: this.scheduleTimezone.name
      },
    }
    this.showSendingWindowModalRef = this.modalService.open(
      ShowSendingWindowComponent, modalOptions
    )
  }

  generateAB(campiagnStep) {
   this.vm.generateABsStep(campiagnStep.id, 1);
  }

  generateSuccess(id){
    var el = document.getElementById("dropdownMenuButton-" + id);
    if(el){
      el.click();
    }
    
    this.campaignStepsTree.forEach(element => {
      if(element.id === id){
        element.status = "Generating Variants"
      }
    }); 

    this.isLoading = false;
  }

  abTest(selectedStepObject) {
    const modalOptions = {
      data: {
        campaignId: this.campaignId,
        currentUser: this.currentUser,
        StepObject: selectedStepObject
      },
      modalClass: 'modal-lg',
      ignoreBackdropClick: true
    }
    modalOptions.data.campaignId = this.campaignId;
    this.AbTestModalRef = this.modalService.open(StepAbTestComponent, modalOptions);
    this.AbTestModalRef.onClose.subscribe((message: any) => {

      if (message === true) {
        this.vm.getReportCampaignStats(this.campaignId);
        // this.vm.getCampaignSteps(this.campaignId);
      }
    });
  }

  EditStepPopup(selectedStepObject: CampaignStepModel) {
    const modalOptions = {
      data: {
        campaignId: this.campaignId,
        currentUser: this.currentUser,
        StepObject: JSON.parse(JSON.stringify(selectedStepObject)),
        followUpConditions: this.followUpConditions,
        campaign_can_edit: this.campaignDetail.can_edit
      },
      modalClass: 'modal-lg',
      ignoreBackdropClick: true
    }
    this.editStepModalRef = this.modalService.open(CampaignEditStepComponent, modalOptions);
    this.editStepModalRef.onClose.subscribe((message: any) => {

      if (message === true) {
        this.vm.getReportCampaignStats(this.campaignId);
        // this.vm.getCampaignSteps(this.campaignId);
      }
    });

  }

  removeLoading(){
    this.isLoading = false;
  }

  DeleteStep(stepId: number){
    this.isLoading = true;
    this.vm.deleteCampaignStep(stepId);
  }

  ConfirmDeleteStep(step){
      this.confirmDialogService.confirmThis("Are you sure you want to delete step: {SUBJECT}?".replace('{SUBJECT}', step.subject), function () {
        CampaignDetailSummaryComponent.siblingCall.DeleteStep(step.id);
      }, function () {
      })
  }

  ConfirmedCloneStep(step: CampaignStepModel){
    this.isLoading = true;
    const clonedCampaignStepModel = new CampaignStepModel();
    clonedCampaignStepModel.created_by = this.currentUser;
    clonedCampaignStepModel.type = "New Thread";
    clonedCampaignStepModel.subject = step.subject;
    clonedCampaignStepModel.body = step.body;
    clonedCampaignStepModel.campaign = step.campaign;
    clonedCampaignStepModel.sending_rate = step.sending_rate;
    clonedCampaignStepModel.ab_variants = step.ab_variants;
    clonedCampaignStepModel.status = "New";
    clonedCampaignStepModel.userextraparams = new UserextraPrams();
    clonedCampaignStepModel.userextraparams = step.userextraparams;
    this.vm.createCampaignStep(clonedCampaignStepModel, "campaign-clone");
  }


  CloneStep(step){
    console.log(step)
    this.confirmDialogService.confirmThis("Are you sure you want to clone the step: {SUBJECT}?".replace('{SUBJECT}', step.subject), function () {
      CampaignDetailSummaryComponent.siblingCall.ConfirmedCloneStep(step);
    }, function () {
    })
  }

  sendTestEmail(selectedStepId: number){
    if(selectedStepId){
      this.modalService.open(StepSendTestComponent, { data: { stepId: selectedStepId }});
    }
  }

  sendAfter(selectedStepObject: CampaignStepModel){

    const modalOptions = {
      data: {
        campaignId: this.campaignId,
        currentUser: this.currentUser,
        StepObject: selectedStepObject
      },
      modalClass: 'modal-sm'
    }
    this.editStepModalRef = this.modalService.open(StepSendAfterComponent, modalOptions);
    this.editStepModalRef.onClose.subscribe((message: any) => {

      if (message === true) {
        this.vm.getReportCampaignStats(this.campaignId);
      }
    });

  }

  reviewStep(stepId){
    const url = '/campaigns/' + String(this.campaignId) + '/review/' + stepId;
    this.router.navigateByUrl(url);
  }

  pauseResumeCancelStep(stepId, actionType){
    this.isLoading = true
    this.vm.pauseResumeCancelStep(stepId, actionType);
  }

  UpdateStepsAfterPauseResumeCancel(success){
    this.isLoading = false;
    if(success){
      this.vm.getReportCampaignStats(this.campaignId);
    }
    // this.vm.getCampaignSteps(this.campaignId);
  }

  autoRefreshCampaignStatsAndSteps(){
    this.vm.getReportCampaignStats(this.campaignId);
    // this.vm.getCampaignSteps(this.campaignId);
  }


}
