

<div class="ms-sm-auto" style="width: 90%;">



<div class="row" style="height: 100%;">



  <div class="col-2 shadow-3" style="background: #FBF7FE; height: 100vh; overflow-x: hidden; overflow-y: auto; ">    

        <app-sidebar-settings></app-sidebar-settings>
    </div>
  
  
     
  <div class="col-10 p-3" style="height: 100vh; overflow-x: hidden; overflow-y: auto; ">
    <h4>Prospect Statuses</h4> <hr> 

    <div *ngFor="let i of prospectStatuslist" class="row mt-1" style="padding-left: 12px; padding-right: 10px;"> 
      <div class="section border mb-3 p-2" style="width: 50%; background-color: rgb(243,243,243) ">
        <div class="row">
          <div class="col-1 mt-3" style="text-align: end;">
            <i class="fas fa-ellipsis-v text-primary" style="font-size: 17px;"></i> 
            <i class="fas fa-ellipsis-v text-primary" style="font-size: 17px;"></i>
          </div>
          <div class="col-11 mt-3">
            <div class="d-flex justify-content-start">
              <h6>{{i.name}}</h6>
              <span class="ps-2">-</span>
              <p class="ps-2">{{i.description}}</p>
            </div> 
          </div>  
        </div>
      </div>
    </div> 


  
  
</div>
   
</div>