import { Component, OnInit, ViewChild, ElementRef , ChangeDetectorRef } from '@angular/core';
import { CampaignReportResponseModel, CampaignReportUserResponseModel, CampaignClientResponseModel, RegionResponseModel } from '../report-model/report-model';
import { BehaviorSubject } from 'rxjs';
import { ReportViewModel } from '../report-view-model/report-view-model';
import { MapAPIService } from "src/app/app-providers/services/map-module";
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-reports-campaigns',
  templateUrl: './reports-campaigns.component.html',
  styleUrls: ['./reports-campaigns.component.scss'],
  providers: [MapAPIService, ReportViewModel, DatePipe],
})
export class ReportsCampaignsComponent implements OnInit {
  @ViewChild("reportContainer") reportContainer: ElementRef;
  sortDirection: string = 'desc';
  column_selected: string = '';
  column_level=0;
  sortActive:boolean = false;
  sortByColumn(list: any[] | undefined, column: string, level, direction = 'desc'): any[] {
    let sortedArray = (list || []).sort((a, b) => {
      if (level === 0) {
        if (a[column] > b[column]) {
          return (direction === 'desc') ? 1 : -1;
        }
        if (a[column] < b[column]) {
          return (direction === 'desc') ? -1 : 1;
        }
        return 0;
      }
      if (level === 1) {
        if (Number(a.data[column]) > Number(b.data[column])) {
          return (direction === 'desc') ? 1 : -1;
        }
        if (Number(a.data[column]) < Number(b.data[column])) {
          return (direction === 'desc') ? -1 : 1;
        }
        return 0;
      }
    })
    return sortedArray;
  };
  totalReportsRecieved: number = 0;
  campaignReport: any = [];
  clientList = [];
  clientId: number = null;
  regionId: number = null;
  campaignChartlist: any = [];
  campaignReportB4Search: any = [];
  allCampaignReport: any = [];
  page_index = 1;
  ordering: string;
  userList: any = [];
  createdById: string;
  search_start_date: string;
  search_end_date: string;
  bsMaxDate: Date;
  filterDateView: Date
  loadingCampaignReport = false;
  campaignReportResponseModel = new BehaviorSubject<CampaignReportResponseModel>(
    new CampaignReportResponseModel()
  );
  campaignReportUserResponseModel = new BehaviorSubject<CampaignReportUserResponseModel>(
    new CampaignReportUserResponseModel()
  );
  campaignClientResponseModel = new BehaviorSubject<CampaignClientResponseModel>(
    new CampaignClientResponseModel()
  );

  regionResponseModel = new BehaviorSubject<RegionResponseModel>(
    new RegionResponseModel()
  );

  appliedFilters: number = 0;

  user_report_dates: any;
  constructor(private vm: ReportViewModel, private datePipe: DatePipe , private cdref: ChangeDetectorRef  ) {

  }
  // add this function to trigger subject
  sortOn(level: number, column: string) {
    var ispercentage = column.endsWith("percentage");
    if (!ispercentage){
      this.column_selected = column;
      this.totalReportsRecieved = 0
      this.campaignReport = []
    }
    if (this.sortDirection === 'asc'){
      this.column_selected = column;
      this.sortDirection = 'desc';
      this.ordering = ispercentage ? null : column;
    }
    else{
      this.column_selected = column;
      this.sortDirection = 'asc';
      this.ordering = ispercentage ? null : '-'+column;
    }
    if  (ispercentage){
        this.column_selected = column;
        this.sortActive = true;
        this.sortByColumn(this.campaignReport, column, level, this.sortDirection);
    }else{
      this.page_index = 1
      this.loadingCampaignReport = true
      this.getCampaignReports();
      this.reportContainer.nativeElement.scrollTop = 0;
    }
  }


  ngOnInit(): void {
    this.vm.parentComponent = this;
    this.vm.subscribeAllVariables(
      this.campaignReportResponseModel,
      this.campaignReportUserResponseModel,
      this.campaignClientResponseModel,
      this.regionResponseModel
    );
    this.vm.getAllUsers();
    this.vm.getAllClients();
    this.vm.getRegions();
    this.updateDate();
    document.getElementById('filterBtn').click();
    // this.getCampaignReports();
  }

  updateDate(){
    // This will call the API on entry, need to fix it later
    var startDate = new Date();
    var LimitDate = new Date();
    // LimitDate.setDate(startDate.getDate() - 1);
    this.bsMaxDate= LimitDate;
    startDate.setDate(startDate.getDate() - 7);
    this.user_report_dates = [startDate, this.bsMaxDate];
    // this.search_start_date =  this.datePipe.transform(this.user_report_dates[0], "YYYY-MM-dd");
    // this.search_end_date = this.datePipe.transform(this.user_report_dates[0], "YYYY-MM-dd");
  }

  getCampaignReports() {

    this.appliedFilters = Number(this.vm.getCampaignReportsPayloadAndCount(
      false, // send false to get count
      this.createdById,
      this.clientId,
      this.search_start_date,
      this.search_end_date,
      this.regionId,
      this.ordering
    ));

    this.vm.getCampaignReportsId(
      this.createdById,
      this.clientId,
      this.page_index,
      this.search_start_date,
      this.search_end_date,
      this.regionId,
      this.ordering
    );
  }

  ClientRecieved() {
    this.clientList = this.campaignClientResponseModel.value.results;

  }

  onclientSelected() {
    this.page_index = 1;
    this.getCampaignReports();
  }

  CampaignReportReceived() {
    this.loadingCampaignReport = false
    if (this.campaignReportB4Search.length == 0)
      this.campaignReport = this.campaignReportResponseModel.value.results;
    else {
      this.campaignReport = this.campaignReportResponseModel.value.results;
      if (this.campaignReportResponseModel.value.previous !== null) {
        for (var j = 0; j < this.campaignReport.length; j++) {
          var index = this.campaignReportB4Search.findIndex(x => x.id == this.campaignReport[j].id);
          if (index === -1)
            this.campaignReportB4Search.push(this.campaignReport[j]);
        }
        this.campaignReport = this.campaignReportB4Search;
      }
    }
    this.campaignReport.forEach(element => {
      if (element.data.cnt_sent === 0) {
        element.data.cnt_opened_percentage = 0.00;
      } else {
        element.data.cnt_opened_percentage = ((element.data.cnt_opened / element.data.cnt_sent) * 100).toFixed(2);
      }

      if (element.data.cnt_sent === 0) {
        element.data.cnt_replied_percentage = 0.00;
      } else {
        element.data.cnt_replied_percentage = ((element.data.cnt_replied / element.data.cnt_sent) * 100).toFixed(2);
      }

      if (element.data.cnt_sent === 0) {
        element.data.cnt_bounced_percentage = 0.00;
      } else {
        element.data.cnt_bounced_percentage = ((element.data.cnt_bounced / element.data.cnt_sent) * 100).toFixed(2);
      }

      if (element.data.cnt_sent === 0) {
        element.data.cnt_opted_out_percentage = 0.00;
      } else {
        element.data.cnt_opted_out_percentage = ((element.data.cnt_opted_out / element.data.cnt_sent) * 100).toFixed(2);
      }

    });

    // if(this.sortActive)
    // {
    //   this.sortOn(this.column_level,this.column_selected);
    // }
    this.totalReportsRecieved = this.campaignReportResponseModel.value.count;
    this.allCampaignReport = this.campaignReportResponseModel.value.results;
    this.getDataForChart();


    var tempProspectGroupId = localStorage.getItem("selectedClient");
    if (tempProspectGroupId !== null) {
      this.clientId = parseInt(tempProspectGroupId);
      this.onclientSelected();
      if (tempProspectGroupId !== null) {
        localStorage.removeItem('selectedClient');
      }
    }

  }

  getUserName(id) {
    var userItem = this.userList.filter(i => i.id == Number(id));
    if (userItem.length > 0) {
      return userItem[0].username;
      // return userItem[0].first_name + " " + userItem[0].last_name;
    }
  }
  userRecieved() {
    this.userList = this.campaignReportUserResponseModel.value.results;
  }

  onCreatedBySelected() {
    this.page_index = 1;
    this.getCampaignReports();
  }

  onCampaignReportScroll() {
    if (this.campaignReportResponseModel.value.next) {
      this.campaignReportB4Search = this.campaignReport;
      this.page_index++;
      this.getCampaignReports();
    }
  }
  getDataForChart() {
    this.barChartData = [];
    this.barChartLabels = [];
    if (this.campaignReport.length >= 20)
      this.campaignChartlist = this.campaignReport.slice(0, 20);

    else
      this.campaignChartlist = this.campaignReport;
    var data1 = []; var data2 = []; var data3 = []; var data4 = []; var data5 = []; var data6 = [];
    var data7 = []; var data8 = []; var data9 = [];
    for (const val of this.campaignChartlist) { // You can use `let` instead of `const` if you like
      data1.push(val.data.cnt_sent);
      data2.push(val.data.cnt_opened);
      data3.push(val.data.cnt_replied);
      data4.push(val.data.cnt_bounced);
      data5.push(val.data.cnt_opted_out);
      data6.push(val.data.cnt_total);
      data7.push(val.data.cnt_clv_rejection);
      data8.push(val.data.cnt_dnc_rejection);
      data9.push(val.data.cnt_ooo_rejection);
      this.barChartLabels.push(val.campaign_name);
    }

    this.barChartData = [];
    this.barChartData.push({ 'data': data1, 'label': 'Sent', stack: 'a' });
    this.barChartData.push({ 'data': data2, 'label': 'Opened', stack: 'a' });
    this.barChartData.push({ 'data': data3, 'label': 'Replied', stack: 'a' });
    this.barChartData.push({ 'data': data4, 'label': 'Bounced', stack: 'a' });
    this.barChartData.push({ 'data': data5, 'label': 'Opted Out', stack: 'a' });
    this.barChartData.push({ 'data': data7, 'label': 'CLV Rejection', stack: 'a' });
    this.barChartData.push({ 'data': data8, 'label': 'DNC Rejection', stack: 'a' });
    this.barChartData.push({ 'data': data9, 'label': 'OOO Rejection', stack: 'a' });
  }

  public barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
  };
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];

  public barChartData: ChartDataSets[] = [
    { data: [], label: 'Sent', stack: 'a', },
    { data: [], label: 'Opened', stack: 'a' },
    { data: [], label: 'Replied', stack: 'a' },
    { data: [], label: 'Bounced', stack: 'a' },
    { data: [], label: 'Opted Out', stack: 'a' },
    { data: [], label: 'CLV Rejection', stack: 'a' },
    { data: [], label: 'DNC Rejection', stack: 'a' },
    { data: [], label: 'OOO Rejection', stack: 'a' }
  ];



  onValueChange(value: Date): void {
    this.filterDateView = value
    this.cdref.detectChanges();
    if (value) {
      this.search_start_date = this.datePipe.transform(value[0], "YYYY-MM-dd");
      this.search_end_date = this.datePipe.transform(value[1], "YYYY-MM-dd");
    } else {
      this.search_start_date = undefined;
      this.search_end_date = undefined;
    }
    this.page_index = 1
    if(value !== this.user_report_dates){
      this.totalReportsRecieved = null;
      // console.log(value === this.user_report_dates)
      this.getCampaignReports();
    } else {
      this.search_start_date = undefined;
      this.search_end_date = undefined;
    }

  }



  onRegionSelected(){
    this.page_index = 1
    this.getCampaignReports();
  }



  

}
