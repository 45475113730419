import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VariableService {
  dynamicValueSub = new Subject() ;
  constructor() { }

  setDynamicVariable(val:any){
    this.dynamicValueSub.next(val)
  }
  getDynamicVariable(){
   return this.dynamicValueSub
  }
}
