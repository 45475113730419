import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, AfterViewInit, ChangeDetectorRef, AfterContentInit, AfterContentChecked } from '@angular/core';
import { Inject } from '@angular/core';
import {
  ProspectFilterModel, ProspectUserResponseModel, ProspectStatusResponseModel,
  ProspectGroupResponseModel, CampaignResponseModel
} from '../model/prospect-model';
import { ProspectsComponent } from '../prospects.component';
import { ProspectViewModel } from '../viewModel/prospect-viewmodel';
import { MapAPIService } from "src/app/app-providers/services/map-module";
import { BehaviorSubject } from 'rxjs';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { ShowImportStatsComponent } from './show-import-stats/show-import-stats.component';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { MapCommon } from 'src/app/app-providers/map-base/MapCommon';
import { DOCUMENT } from '@angular/common';
import { MdbCollapseDirective } from 'mdb-angular-ui-kit/collapse/collapse.directive';
@Component({
  selector: 'app-sidebar-prospects',
  templateUrl: './sidebar-prospects.component.html',
  styleUrls: ['./sidebar-prospects.component.scss'],
  providers: [MapAPIService, ProspectViewModel],
})
export class SidebarProspectsComponent implements OnInit , AfterContentChecked {
  @ViewChild('myProspectGroup') myProspectGroup: ElementRef<HTMLElement>;
  @ViewChild('myCampaign') myCampaign: ElementRef<HTMLElement>;
  @ViewChild('myCampaignNotIn') myCampaignNotIn: ElementRef<HTMLElement>;
  @ViewChild('campaignTypeSidebar') campaignTypeSidebar: ElementRef<HTMLElement>;

  prospectFilterModel = new ProspectFilterModel();
  @Input('userList') userList = [];
  @Input('prospectStatuslist') prospectStatuslist = [];
  @Input('prospectLoaded') prospectLoaded = false;



  @Output() prospectGroupChangeEvent = new EventEmitter<any[]>();
  @Output() campaignsChangeEvent = new EventEmitter<any[]>();

  // userList = [];
  // prospectStatuslist = [];

  prospectGrouplist = [];
  searchEmailId: string = null;
  prospectgroupCheckBox: boolean = false;
  campaignCheckBox: boolean = false;
  prospectInterests = MapCommon.getProspectInterests();

  prospectUserResponseModel = new BehaviorSubject<ProspectUserResponseModel>(
    new ProspectUserResponseModel()
  );

  prospectStatusResponseModel = new BehaviorSubject<ProspectStatusResponseModel>(
    new ProspectStatusResponseModel()
  );

  prospectGroupResponseModel = new BehaviorSubject<ProspectGroupResponseModel>(
    new ProspectGroupResponseModel()
  );
  campaignResponseModel = new BehaviorSubject<CampaignResponseModel>(
    new CampaignResponseModel()
  );

  repliesCount: number = 0;
  clickCount: number = null;
  lastContactedCount: number = null;
  sentCount: number = null;

  scoreCount: number = null;

  replyFilterArr = [];
  clickFilterArr = [];
  sentFilterArr = [];
  lastContactedFilterArr = [];

  scoreFilterArr = [];

  emailContainsText: string = null;
  emailStartsWithText: string = null;
  emailEndsWithText: string = null;
  emailIsText: string = null;
  emailArr = [];

  firstnameContainsText: string = null;
  firstnameStartsWithText: string = null;
  firstnameEndsWithText: string = null;
  firstnameArr = [];

  lastnameContainsText: string = null;
  lastnameStartsWithText: string = null;
  lastnameEndsWithText: string = null;
  lastnameArr = [];

  companyContainsText: string = null;
  companyStartsWithText: string = null;
  companyEndsWithText: string = null;
  companyArr = [];

  industryContainsText: string = null;
  industryStartsWithText: string = null;
  industryEndsWithText: string = null;
  industryArr = [];

  phoneContainsText: string = null;
  phoneStartsWithText: string = null;
  phoneEndsWithText: string = null;
  phoneArr = [];

  job_titleContainsText: string = null;
  job_titleStartsWithText: string = null;
  job_titleEndsWithText: string = null;
  job_titleArr = [];

  cityContainsText: string = null;
  cityStartsWithText: string = null;
  cityEndsWithText: string = null;
  cityArr = [];

  stateContainsText: string = null;
  stateStartsWithText: string = null;
  stateEndsWithText: string = null;
  stateArr = [];

  regionContainsText: string = null;
  regionStartsWithText: string = null;
  regionEndsWithText: string = null;
  regionArr = [];

  countryContainsText: string = null;
  countryStartsWithText: string = null;
  countryEndsWithText: string = null;
  countryArr = [];

  addressContainsText: string = null;
  addressStartsWithText: string = null;
  addressEndsWithText: string = null;
  addressArr = [];

  linkedinContainsText: string = null;
  linkedinStartsWithText: string = null;
  linkedinEndsWithText: string = null;
  linkedinArr = [];

  snippetContainsText: string = null;
  snippetArr = [];

  snippet1ContainsText: string = null;
  snippet1Arr = [];

  snippet2ContainsText: string = null;
  snippet2Arr = [];

  snippet3ContainsText: string = null;
  snippet3Arr = [];

  snippet4ContainsText: string = null;
  snippet4Arr = [];

  prospectInterestStatus: number = null;
  prospectInterestArr = [];

  showDeletedObject: any = null;

  createdById: number = null;
  createdByArr = [];

  statusId: number = null;
  statusIdArr = [];

  prospectgroupId: string = null;
  prospectGroupArr = [];
 

  campaignName: string = null;
  campaignlist = [];
  tempCampaignlist = [];
  selectedCampaign: string;
  campaignArr = [];
  isFocused: boolean = false;
  isPasted: boolean = false;
  optOutStatus: boolean = null;
  optOutArr = [];

  autoReplyStatus: boolean = null;;
  autoReplyArr = []

  bouncedStatus: boolean = null;
  bouncedArr = [];
  lastusedFilters: boolean = false
  showImportStatsModalRef: MdbModalRef<ShowImportStatsComponent>;

  constructor(private pc: ProspectsComponent, private vm: ProspectViewModel,public changeDetectRef: ChangeDetectorRef,
    private modalService: MdbModalService, @Inject(DOCUMENT) document) {

  }

  ngOnInit(): void {

    this.vm.parentSidebarProspectsComponent = this;
    this.vm.subscribeUserVariables(this.prospectUserResponseModel);
    this.vm.subscribeStatusVariables(this.prospectStatusResponseModel);
    this.vm.subscribeGroupVariables(this.prospectGroupResponseModel);
    this.vm.subscribeCampaignVariable(this.campaignResponseModel);
    // this.vm.getAllUsers('sidebar');
    // this.vm.getAllProspectStatus('sidebar');
  
    this.showImportStats();
 

    //checking if prospect filters has any property except page_size & page

    console.log(JSON.parse(localStorage.getItem('lastUsedProspectFilter')))
    for(const  key in JSON.parse(localStorage.getItem('lastUsedProspectFilter'))){
      if( key !== 'page_size' && key !== 'page' && key !== 'email_address__icontains' ){
        this.lastusedFilters = true
      }
     
    }

    // code to auto apply saved prospect filter
   if(localStorage.getItem('selectedCampaign')){
    setTimeout( ()=> document.getElementById('flexRadioDefault13').click(), 0)
    setTimeout( ()=> document.getElementById('campaignEqual').click(), 2)
    setTimeout(()=> this.vm.getAllCampaign(localStorage.getItem('selectedCampaign'), 'sidebar'), 1000 ) 
   } else if ( this.lastusedFilters && !localStorage.getItem('selectdProspectGroup')){
    
      this.prospectFilterModel = JSON.parse(localStorage.getItem('lastUsedProspectFilter'))

      // no need to save last used email as it is not any filter also not important to remember what email searched last time so commented
      // if(this.prospectFilterModel?.email_address__icontains){
      //    this.searchEmailId =  this.prospectFilterModel.email_address__icontains
      // }

     if(this.prospectFilterModel.is_opted_out == true){
       setTimeout( ()=>  document.getElementById('flexRadioDefault8').click(), 200)
       setTimeout(() =>  document.getElementById('optedoutyes').click(), 300)
     } else if(this.prospectFilterModel.is_opted_out == false) {
       setTimeout( ()=>document.getElementById('flexRadioDefault8').click(), 200)
       setTimeout(() =>   document.getElementById('optedoutdno').click() , 300)
     }

     if(this.prospectFilterModel.status__id == 1){
       setTimeout(() => document.getElementById('flexRadioDefault2').click(), 200)
       setTimeout(() =>  document.getElementById('open_1').click() , 350)
     } else if(this.prospectFilterModel.status__id == 2) {
       setTimeout(() =>document.getElementById('flexRadioDefault2').click() ,200)
       setTimeout(() =>  document.getElementById('open_2').click() ,350)
     }

     if(this.prospectFilterModel.created_by__id){
       setTimeout(() =>   document.getElementById('flexRadioDefault1').click(),200)
       this.createdById = this.prospectFilterModel.created_by__id
     }
     if(this.prospectFilterModel.is_bounced  == true){
       setTimeout(() => document.getElementById('flexRadioDefault80').click(),200)
       setTimeout(() => document.getElementById('bounceddyes').click() ,300)
     } else if(this.prospectFilterModel.is_bounced  == false) {
       setTimeout(() => document.getElementById('flexRadioDefault80').click() ,200)
       setTimeout(() => document.getElementById('bounceddyes').click() ,300)
     }

     if(this.prospectFilterModel.prospect_group__name){
      setTimeout(() => document.getElementById('flexRadioDefault5').click() , 200)
      setTimeout(() => document.getElementById('propectGroup').focus() , 205)
       this.prospectgroupId = this.prospectFilterModel.prospect_group__name
     } 

     if(this.prospectFilterModel.cnt_email_sent  >= 0 && this.prospectFilterModel.cnt_email_sent !== null){
       setTimeout(() =>document.getElementById('flexRadioDefault10').click(),200)
       setTimeout(() => document.getElementById('msgSentEqualTo').click(),300)
       setTimeout( ()=> {this.sentCount =  this.prospectFilterModel.cnt_email_sent ;  this.handleUserInput('msgSent')} ,200)
     } else if(this.prospectFilterModel.cnt_email_sent__gt  >= 0 && this.prospectFilterModel.cnt_email_sent__gt !== null) {
       setTimeout(() => document.getElementById('flexRadioDefault10').click(),200)
       setTimeout(() => document.getElementById('msgSentMoreThan').click(),300)
       setTimeout( ()=> {this.sentCount =  this.prospectFilterModel.cnt_email_sent__gt ;  this.handleUserInput('msgSent')} ,200)
     } else if(this.prospectFilterModel.cnt_email_sent__lt  >= 0 && this.prospectFilterModel.cnt_email_sent__lt !== null) {
       setTimeout(() => document.getElementById('flexRadioDefault10').click(),200)
       setTimeout(() => document.getElementById('msgSentLessThan').click(),300)
       setTimeout( ()=> {this.sentCount =  this.prospectFilterModel.cnt_email_sent__lt ;  this.handleUserInput('msgSent')} ,200)
     }

     if(this.prospectFilterModel.cnt_email_open  >= 0 && this.prospectFilterModel.cnt_email_open !== null){
       setTimeout(() => document.getElementById('flexRadioDefault11').click(),200)
       setTimeout(() =>document.getElementById('msgClicksEqualTo').click(),300)
       setTimeout( ()=> {this.clickCount =  this.prospectFilterModel.cnt_email_open ;  this.handleUserInput('msgClicks')} ,200)
     } else if(this.prospectFilterModel.cnt_email_open__gt  >= 0 && this.prospectFilterModel.cnt_email_open__gt !== null) {
       setTimeout(() =>document.getElementById('flexRadioDefault11').click(),200)
       setTimeout(() => document.getElementById('msgClicksMoreThan').click() ,300)
       setTimeout( ()=> {this.clickCount =  this.prospectFilterModel.cnt_email_open__gt ;  this.handleUserInput('msgClicks')} ,200)
     } else if(this.prospectFilterModel.cnt_email_open__lt  >= 0 && this.prospectFilterModel.cnt_email_open__lt !== null) {
       setTimeout(() =>document.getElementById('flexRadioDefault11').click(),200)
       setTimeout(() => document.getElementById('msgClicksLessThan').click() ,300)
       setTimeout( ()=> {this.clickCount =  this.prospectFilterModel.cnt_email_open__lt  ;  this.handleUserInput('msgClicks')} ,200)
     }

     if(this.prospectFilterModel.cnt_replies_received == 0 &&  this.prospectFilterModel.cnt_replies_received !== null){
       setTimeout(() => document.getElementById('flexRadioDefault12').click(),200)
       setTimeout(() =>  document.getElementById('msgRepliesNo').click(),300)
     } else if(this.prospectFilterModel.cnt_replies_received__gt >= 0 &&  this.prospectFilterModel.cnt_replies_received__gt !== null) {
       setTimeout(() => document.getElementById('flexRadioDefault12').click(),200)
       setTimeout(() =>  document.getElementById('msgRepliesYes').click() ,300)
     }
     if(this.prospectFilterModel.is_auto_reply !== null &&  this.prospectFilterModel.is_auto_reply !== undefined  && this.prospectFilterModel.is_auto_reply){
       setTimeout(() => document.getElementById('flexRadioDefault32').click(),200)
       setTimeout(() => document.getElementById('autoreplyyes').click() ,300)

     } else if(!this.prospectFilterModel.is_auto_reply && this.prospectFilterModel.is_auto_reply !== null && this.prospectFilterModel.is_auto_reply !== undefined) {
       setTimeout(() => document.getElementById('flexRadioDefault32').click(),200)
       setTimeout(() =>   document.getElementById('autoreplyno').click() ,300)

     }

     if(this.prospectFilterModel.interested_category == 1 ){
       setTimeout(() => document.getElementById('flexRadioDefault31').click(),200)
       setTimeout(() => document.getElementById('prospectInterest_1').click(),300)

     } else if(this.prospectFilterModel.interested_category == 2) {
       setTimeout(() => document.getElementById('flexRadioDefault31').click(),200)
       setTimeout(() => document.getElementById('prospectInterest_2').click(),300)

     } else if(this.prospectFilterModel.interested_category == 3) {
       setTimeout(() =>document.getElementById('flexRadioDefault31').click(),200)
       setTimeout(() => document.getElementById('prospectInterest_3').click(),300)

     }

     if(this.prospectFilterModel.last_contacted_period__eq  >= 0 && this.prospectFilterModel.last_contacted_period__eq !== null){
       setTimeout(() =>document.getElementById('lastContactedRadio1').click(),200)
       setTimeout(() =>  document.getElementById('lastContactedEqualTo').click(),300)
       setTimeout( ()=> {this.lastContactedCount =  this.prospectFilterModel.last_contacted_period__eq ;  this.handleUserInput('lastContacted')} ,200)
     } else if(this.prospectFilterModel.last_contacted_period__gt  >= 0 && this.prospectFilterModel.last_contacted_period__gt !== null) {
       setTimeout( ()=>  document.getElementById('lastContactedRadio1').click(),200)
       setTimeout( ()=> document.getElementById('lastContactedMoreThan').click() ,300)
       setTimeout( ()=> {this.lastContactedCount =  this.prospectFilterModel.last_contacted_period__gt ;  this.handleUserInput('lastContacted')} ,200)
     } else if(this.prospectFilterModel.last_contacted_period__lt  >= 0 && this.prospectFilterModel.last_contacted_period__lt !== null) {
       setTimeout( ()=>  document.getElementById('lastContactedLessThan').click(),200)
       setTimeout( ()=> document.getElementById('msgClicksLessThan').click(),300)
       setTimeout( ()=> {this.lastContactedCount =  this.prospectFilterModel.last_contacted_period__lt   ;  this.handleUserInput('lastContacted')} ,200)
     }

     if(this.prospectFilterModel.is_deleted  && this.prospectFilterModel.is_deleted != null){
       setTimeout( ()=> document.getElementById('flexRadioDefault30').click() ,200)
     }

     if(this.prospectFilterModel.score__gt >= 0 && this.prospectFilterModel.score__gt !== null){
       setTimeout( ()=> document.getElementById('flexRadioDefault29').click(),200)
       setTimeout( ()=> document.getElementById('scoresMoreThan').click() ,300)
       setTimeout( ()=> {this.scoreCount =  this.prospectFilterModel.score__gt ;  this.handleUserInput('scores')} ,200)
     } else if(this.prospectFilterModel.score__lt  >= 0 && this.prospectFilterModel.score__lt !== null) {
       setTimeout( ()=>document.getElementById('flexRadioDefault29').click(),200)
       setTimeout( ()=> document.getElementById('scoresLessThan').click() ,300)
       setTimeout( ()=> {this.scoreCount =  this.prospectFilterModel.score__lt ;  this.handleUserInput('scores')} ,200)
     }

     if(this.prospectFilterModel.campaign__eq != null && this.prospectFilterModel?.campaign__eq.length > 0){
      setTimeout( ()=>document.getElementById('flexRadioDefault13').click(),200)
       setTimeout( ()=>document.getElementById('campaignMoreThan').click(),300)

       setTimeout( ()=> {this.selectedCampaign =  this.prospectFilterModel.campaign__eq ;   } ,201)
       setTimeout( ()=> this.prospectFilterModel.campaign__eq = this.selectedCampaign,301)
     } else if(this.prospectFilterModel.campaign__neq != null && this.prospectFilterModel?.campaign__neq.length > 0) {
      setTimeout( ()=>document.getElementById('flexRadioDefault13').click(),200)
       setTimeout( ()=> document.getElementById('campaignEqual').click(),300)

       setTimeout( ()=> {this.selectedCampaign =  this.prospectFilterModel.campaign__neq ;  } ,201)
       setTimeout( ()=> this.prospectFilterModel.campaign__neq  = this.selectedCampaign,301)
     }

     if(this.prospectFilterModel.email_address__startswith != null && this.prospectFilterModel?.email_address__startswith.length > 0){
      setTimeout( ()=>document.getElementById('flexRadioDefault3').click(),200)
       setTimeout( ()=> document.getElementById('emailStartsWith').click(),300)
       setTimeout( ()=> {this.emailStartsWithText =  this.prospectFilterModel.email_address__startswith ; this.handleUserInput('emailEndsWith')} ,200)
     } else if(this.prospectFilterModel.email_address__endswith != null && this.prospectFilterModel?.email_address__endswith.length > 0) {
      setTimeout( ()=> document.getElementById('flexRadioDefault3').click(),200)
       setTimeout( ()=> document.getElementById('emailEndsWith').click(),300)
       setTimeout( ()=> {this.emailEndsWithText =  this.prospectFilterModel.email_address__endswith ; this.handleUserInput('emailEndsWith') } ,200)
     }

     if(this.prospectFilterModel.firstname__endswith != null && this.prospectFilterModel?.firstname__endswith.length > 0){
      setTimeout( ()=> document.getElementById('flexRadioDefault33').click(),200)
       setTimeout( ()=> document.getElementById('firstnameEndsWith').click(),300)
       setTimeout( ()=> {this.firstnameEndsWithText =  this.prospectFilterModel.firstname__endswith ; this.handleUserInput('firstnameEndsWith')} ,200)
     } else if(this.prospectFilterModel.firstname__icontains != null && this.prospectFilterModel?.firstname__icontains.length > 0) {
      setTimeout( ()=>document.getElementById('flexRadioDefault33').click(),200)
       setTimeout( ()=>document.getElementById('firstnameContains').click(),300)
       setTimeout( ()=> {this.firstnameContainsText =  this.prospectFilterModel.firstname__icontains ; this.handleUserInput('firstnameEndsWith') } ,200)
     } else if(this.prospectFilterModel.firstname__startswith != null && this.prospectFilterModel?.firstname__startswith.length > 0) {
      setTimeout( ()=> document.getElementById('flexRadioDefault33').click(),200)
       setTimeout( ()=> document.getElementById('firstnameStartsWith').click(),300)
       setTimeout( ()=> {this.firstnameStartsWithText =  this.prospectFilterModel.firstname__startswith ; this.handleUserInput('firstnameEndsWith') } ,200)
     }

     if(this.prospectFilterModel.lastname__endswith != null && this.prospectFilterModel?.lastname__endswith.length > 0){
      setTimeout( ()=>document.getElementById('flexRadioDefault14').click() ,200)
       setTimeout( ()=> document.getElementById('lastnameEndsWith').click(),300)
       setTimeout( ()=> {this.lastnameEndsWithText =  this.prospectFilterModel.lastname__endswith  ; this.handleUserInput('lastnameContains')} ,200)
     } else if(this.prospectFilterModel.lastname__icontains != null && this.prospectFilterModel?.lastname__icontains.length > 0) {
       setTimeout( ()=> document.getElementById('flexRadioDefault14').click(),200)
       setTimeout( ()=> document.getElementById('lastnameContains').click() ,300)
       setTimeout( ()=> {this.lastnameContainsText =  this.prospectFilterModel.lastname__icontains ; this.handleUserInput('lastnameContains') } ,200)
     } else if(this.prospectFilterModel.lastname__startswith != null && this.prospectFilterModel?.lastname__startswith.length > 0) {
      setTimeout( ()=> document.getElementById('flexRadioDefault14').click(),200)
       setTimeout( ()=> document.getElementById('lastnameStartsWith').click() ,300)
       setTimeout( ()=> {this.lastnameStartsWithText =  this.prospectFilterModel.lastname__startswith ; this.handleUserInput('lastnameContains') } ,200)
     }

     if(this.prospectFilterModel.company__icontains != null && this.prospectFilterModel?.company__icontains.length > 0){
      setTimeout( ()=> document.getElementById('flexRadioDefault15').click(),200)
       setTimeout( ()=>document.getElementById('companyContains').click() ,300)
       setTimeout( ()=> {this.companyContainsText =  this.prospectFilterModel.company__icontains  ; this.handleUserInput('companyContains')} ,200)
     } else if(this.prospectFilterModel.company__endswith != null && this.prospectFilterModel?.company__endswith.length > 0) {
      setTimeout( ()=> document.getElementById('flexRadioDefault15').click(),200)
       setTimeout( ()=> document.getElementById('companyEndsWith').click() ,300)
       setTimeout( ()=> {this.companyEndsWithText =  this.prospectFilterModel.company__endswith ;  this.handleUserInput('companyContains') } ,200)
     } else if(this.prospectFilterModel.company__startswith != null && this.prospectFilterModel?.company__startswith.length > 0) {
      setTimeout( ()=>document.getElementById('flexRadioDefault15').click(),200)
       setTimeout( ()=> document.getElementById('companyStartsWith').click() ,300)
       setTimeout( ()=> {this.companyStartsWithText =  this.prospectFilterModel.company__startswith ;  this.handleUserInput('companyContains') } ,200)
     } else if(this.prospectFilterModel.company__isnull != null && this.prospectFilterModel.company__isnull) {
      setTimeout( ()=>document.getElementById('flexRadioDefault15').click(),200)
       setTimeout( ()=>document.getElementById('companyEmpty').click() ,300)
     } else if(this.prospectFilterModel.company__isnull != null && !this.prospectFilterModel.company__isnull) {
      setTimeout( ()=>  document.getElementById('flexRadioDefault15').click(),200)
       setTimeout( ()=> document.getElementById('companyNotEmpty').click() ,300)
     }

     if(this.prospectFilterModel.industry__icontains != null && this.prospectFilterModel?.industry__icontains.length > 0){
      setTimeout( ()=> document.getElementById('flexRadioDefault16').click(),200)
       setTimeout( ()=> document.getElementById('industryContains').click(),300)
       setTimeout( ()=> {this.industryContainsText =  this.prospectFilterModel.industry__icontains  ; this.handleUserInput('industryEndsWith')} ,200)
     } else if(this.prospectFilterModel.industry__endswith != null && this.prospectFilterModel?.industry__endswith.length > 0) {
      setTimeout( ()=>document.getElementById('flexRadioDefault16').click(),200)
       setTimeout( ()=> document.getElementById('industryEndsWith').click(),300)
       setTimeout( ()=> {this.industryEndsWithText =  this.prospectFilterModel.industry__endswith ;  this.handleUserInput('industryEndsWith') } ,200)
     } else if(this.prospectFilterModel.industry__startswith != null && this.prospectFilterModel?.industry__startswith.length > 0) {
      setTimeout( ()=> document.getElementById('flexRadioDefault16').click(),200)
       setTimeout( ()=> document.getElementById('industryStartsWith').click(),300)
       setTimeout( ()=> {this.industryStartsWithText =  this.prospectFilterModel.industry__startswith ;  this.handleUserInput('industryEndsWith') } ,200)
     } else if(this.prospectFilterModel.industry__isnull != null && this.prospectFilterModel.industry__isnull) {
      setTimeout( ()=>document.getElementById('flexRadioDefault16').click(),200)
       setTimeout( ()=> document.getElementById('industryEmpty').click(),300)
     } else if(this.prospectFilterModel.industry__isnull != null && !this.prospectFilterModel.industry__isnull) {
      setTimeout( ()=>document.getElementById('flexRadioDefault16').click(),200)
       setTimeout( ()=> document.getElementById('industryNotEmpty').click(),300)
     }

     if(this.prospectFilterModel.phone__icontains != null && this.prospectFilterModel?.phone__icontains.length > 0){
      setTimeout( ()=>document.getElementById('flexRadioDefault17').click(),200)
       setTimeout( ()=> document.getElementById('phoneContains').click(),300)
       setTimeout( ()=> {this.phoneContainsText =  this.prospectFilterModel.phone__icontains ; this.handleUserInput('phoneContains')} ,200)
     } else if(this.prospectFilterModel.phone__endswith  != null && this.prospectFilterModel?.phone__endswith.length > 0) {
      setTimeout( ()=> document.getElementById('flexRadioDefault17').click(),200)
       setTimeout( ()=> document.getElementById('phoneEndsWith').click(),300)
       setTimeout( ()=> {this.phoneEndsWithText =  this.prospectFilterModel.phone__endswith ;  this.handleUserInput('phoneContains') } ,200)
     } else if(this.prospectFilterModel.phone__startswith != null && this.prospectFilterModel?.phone__startswith.length > 0) {
      setTimeout( ()=>document.getElementById('flexRadioDefault17').click(),200)
       setTimeout( ()=> document.getElementById('phoneStartsWith').click(),300)
       setTimeout( ()=> {this.phoneStartsWithText =  this.prospectFilterModel.phone__startswith ;  this.handleUserInput('phoneContains') } ,200)
     } else if(this.prospectFilterModel.phone__isnull != null && this.prospectFilterModel.phone__isnull) {
      setTimeout( ()=>document.getElementById('flexRadioDefault17').click(),200)
       setTimeout( ()=> document.getElementById('phoneEmpty').click(),300)
     } else if(this.prospectFilterModel.phone__isnull != null && !this.prospectFilterModel.phone__isnull) {
      setTimeout( ()=>document.getElementById('flexRadioDefault17').click(),200)
       setTimeout( ()=> document.getElementById('phoneNotEmpty').click(),300)
     }

     if(this.prospectFilterModel.job_title__icontains  != null && this.prospectFilterModel?.job_title__icontains.length > 0){
      setTimeout( ()=>document.getElementById('flexRadioDefault18').click() ,200)
       setTimeout( ()=> document.getElementById('job_titleContains').click(),300)
       setTimeout( ()=> {this.job_titleContainsText =  this.prospectFilterModel.job_title__icontains ; this.handleUserInput('job_titleContains')} ,200)
     } else if(this.prospectFilterModel.job_title__endswith  != null && this.prospectFilterModel?.job_title__endswith.length > 0) {
      setTimeout( ()=> document.getElementById('flexRadioDefault18').click(),200)
       setTimeout( ()=> document.getElementById('job_titleEndsWith').click(),300)
       setTimeout( ()=> {this.job_titleEndsWithText =  this.prospectFilterModel.job_title__endswith ;  this.handleUserInput('job_titleContains') } ,200)
     } else if(this.prospectFilterModel.job_title__startswith != null && this.prospectFilterModel?.job_title__startswith.length > 0) {
      setTimeout( ()=> document.getElementById('flexRadioDefault18').click(),200)
       setTimeout( ()=> document.getElementById('job_titleStartsWith').click(),300)
       setTimeout( ()=> {this.job_titleStartsWithText =  this.prospectFilterModel.job_title__startswith ;  this.handleUserInput('job_titleContains') } ,200)
     } else if(this.prospectFilterModel.job_title__isnull != null && this.prospectFilterModel.job_title__isnull) {
      setTimeout( ()=>document.getElementById('flexRadioDefault18').click(),200)
       setTimeout( ()=>document.getElementById('job_titleEmpty').click(),300)
     } else if(this.prospectFilterModel.job_title__isnull != null && !this.prospectFilterModel.job_title__isnull) {
      setTimeout( ()=>document.getElementById('flexRadioDefault18').click(),200)
       setTimeout( ()=> document.getElementById('job_titleNotEmpty').click(),300)
     }

     if(this.prospectFilterModel.city__icontains != null && this.prospectFilterModel?.city__icontains.length > 0){
      setTimeout( ()=>document.getElementById('flexRadioDefault19').click(),200)
       setTimeout( ()=> document.getElementById('cityContains').click(),300)
       setTimeout( ()=> {this.cityContainsText =  this.prospectFilterModel.city__icontains ; this.handleUserInput('job_titleContains')} ,200)
     } else if(this.prospectFilterModel.city__endswith != null && this.prospectFilterModel?.city__endswith.length > 0) {
      setTimeout( ()=> document.getElementById('flexRadioDefault19').click(),200)
       setTimeout( ()=> document.getElementById('cityEndsWith').click(),300)
       setTimeout( ()=> {this.cityEndsWithText =  this.prospectFilterModel.city__endswith  ;  this.handleUserInput('job_titleContains') } ,200)
     } else if(this.prospectFilterModel.city__startswith != null && this.prospectFilterModel?.city__startswith.length > 0) {
      setTimeout( ()=>document.getElementById('flexRadioDefault19').click(),200)
       setTimeout( ()=> document.getElementById('cityStartsWith').click(),300)
       setTimeout( ()=> {this.cityStartsWithText =  this.prospectFilterModel.job_title__startswith ;  this.handleUserInput('job_titleContains') } ,200)
     } else if(this.prospectFilterModel.city__isnull  != null && this.prospectFilterModel.city__isnull) {
      setTimeout( ()=>document.getElementById('flexRadioDefault19').click(),200)
       setTimeout( ()=> document.getElementById('cityEmpty').click(),300)
     } else if(this.prospectFilterModel.city__isnull != null && !this.prospectFilterModel.city__isnull) {
      setTimeout( ()=>document.getElementById('flexRadioDefault19').click(),200)
       setTimeout( ()=> document.getElementById('cityNotEmpty').click(),300)
     }

     if(this.prospectFilterModel.state__icontains  != null && this.prospectFilterModel?.state__icontains.length > 0){
      setTimeout( ()=> document.getElementById('flexRadioDefault20').click(),200)
       setTimeout( ()=> document.getElementById('stateContains').click(),300)
       setTimeout( ()=> {this.stateContainsText =  this.prospectFilterModel.state__icontains ; this.handleUserInput('stateContains')} ,200)
     } else if(this.prospectFilterModel.state__endswith != null && this.prospectFilterModel?.state__endswith.length > 0) {
      setTimeout( ()=>document.getElementById('flexRadioDefault20').click(),200)
       setTimeout( ()=> document.getElementById('stateEndsWith').click(),300)
       setTimeout( ()=> {this.stateEndsWithText =  this.prospectFilterModel.state__endswith  ;  this.handleUserInput('stateContains') } ,200)
     } else if(this.prospectFilterModel.state__startswith != null && this.prospectFilterModel?.state__startswith.length > 0) {
      setTimeout( ()=>document.getElementById('flexRadioDefault20').click(),200)
       setTimeout( ()=> document.getElementById('stateStartsWith').click(),300)
       setTimeout( ()=> {this.stateStartsWithText =  this.prospectFilterModel.state__startswith ;  this.handleUserInput('stateContains') } ,200)
     } else if(this.prospectFilterModel.state__isnull  != null && this.prospectFilterModel.state__isnull) {
      setTimeout( ()=> document.getElementById('flexRadioDefault20').click(),200)
       setTimeout( ()=> document.getElementById('stateEmpty').click(),300)
     } else if(this.prospectFilterModel.state__isnull != null && !this.prospectFilterModel.state__isnull) {
      setTimeout( ()=> document.getElementById('flexRadioDefault20').click(),200)
       setTimeout( ()=> document.getElementById('stateNotEmpty').click(),300)
     }

     if(this.prospectFilterModel.country__icontains != null && this.prospectFilterModel?.country__icontains.length > 0){
      setTimeout( ()=>  document.getElementById('flexRadioDefault21').click() ,200)
       setTimeout( ()=> document.getElementById('countryContains').click(),300)
       setTimeout( ()=> {this.countryContainsText =  this.prospectFilterModel.country__icontains ; this.handleUserInput('countryContains')} ,200)
     } else if(this.prospectFilterModel.country__endswith != null && this.prospectFilterModel.country__endswith.length > 0) {
      setTimeout( ()=>document.getElementById('flexRadioDefault21').click(),200)
       setTimeout( ()=>document.getElementById('countryEndsWith').click(),300)
       setTimeout( ()=> {this.countryEndsWithText =  this.prospectFilterModel.country__endswith  ;  this.handleUserInput('countryContains') } ,200)
     } else if(this.prospectFilterModel.country__startswith != null && this.prospectFilterModel?.country__startswith.length > 0) {
      setTimeout( ()=>document.getElementById('flexRadioDefault21').click(),200)
       setTimeout( ()=> document.getElementById('countryStartsWith').click(),300)
       setTimeout( ()=> {this.countryStartsWithText =  this.prospectFilterModel.country__startswith ;  this.handleUserInput('countryContains') } ,200)
     } else if(this.prospectFilterModel.country__isnull   != null && this.prospectFilterModel.country__isnull ) {
      setTimeout( ()=>document.getElementById('flexRadioDefault21').click(),200)
       setTimeout( ()=> document.getElementById('countryEmpty').click(),300)
     } else if(this.prospectFilterModel.country__isnull != null && !this.prospectFilterModel.country__isnull ) {
      setTimeout( ()=>document.getElementById('flexRadioDefault21').click(),200)
       setTimeout( ()=> document.getElementById('countryNotEmpty').click(),300)
     }

     if(this.prospectFilterModel.address__icontains!= null && this.prospectFilterModel?.address__icontains.length > 0){
      setTimeout( ()=> document.getElementById('flexRadioDefault22').click(),200)
       setTimeout( ()=> document.getElementById('addressContains').click(),300)
       setTimeout( ()=> {this.addressContainsText =  this.prospectFilterModel.address__icontains ; this.handleUserInput('addressEndsWith')} ,200)
     } else if(this.prospectFilterModel.address__endswith != null && this.prospectFilterModel?.address__endswith.length > 0) {
      setTimeout( ()=> document.getElementById('flexRadioDefault22').click(),200)
       setTimeout( ()=> document.getElementById('addressEndsWith').click(),300)
       setTimeout( ()=> {this.addressEndsWithText =  this.prospectFilterModel.address__endswith;  this.handleUserInput('addressEndsWith') } ,200)
     } else if(this.prospectFilterModel.address__startswith != null && this.prospectFilterModel?.address__startswith.length > 0) {
      setTimeout( ()=> document.getElementById('flexRadioDefault22').click(),200)
       setTimeout( ()=> document.getElementById('addressStartsWith').click(),300)
       setTimeout( ()=> {this.addressStartsWithText =  this.prospectFilterModel.address__startswith ;  this.handleUserInput('addressEndsWith') } ,200)
     } else if(this.prospectFilterModel.address__isnull   != null && this.prospectFilterModel.address__isnull ) {
      setTimeout( ()=>  document.getElementById('flexRadioDefault22').click(),200)
       setTimeout( ()=> document.getElementById('addressEmpty').click(),300)
     } else if(this.prospectFilterModel.address__isnull != null && !this.prospectFilterModel.address__isnull ) {
       setTimeout( ()=> document.getElementById('flexRadioDefault22').click(),200)
       setTimeout( ()=> document.getElementById('addressNotEmpty').click(),300)
     }


     if(this.prospectFilterModel.linkedin__icontains!= null && this.prospectFilterModel?.linkedin__icontains.length > 0){
      setTimeout( ()=>document.getElementById('flexRadioDefault23').click(),200)
       setTimeout( ()=>document.getElementById('linkedinContains').click(),300)
       setTimeout( ()=> {this.linkedinContainsText =  this.prospectFilterModel.linkedin__icontains ; this.handleUserInput('addressEndsWith')} ,200)
     } else if(this.prospectFilterModel.linkedin__endswith != null && this.prospectFilterModel?.linkedin__endswith.length > 0) {
      setTimeout( ()=> document.getElementById('flexRadioDefault23').click(),200)
       setTimeout( ()=>document.getElementById('linkedinEndsWith').click(),300)
       setTimeout( ()=> {this.linkedinEndsWithText =  this.prospectFilterModel.linkedin__endswith;  this.handleUserInput('addressEndsWith') } ,200)
     } else if(this.prospectFilterModel.lastname__startswith != null && this.prospectFilterModel?.lastname__startswith.length > 0) {
      setTimeout( ()=> document.getElementById('flexRadioDefault23').click(),200)
       setTimeout( ()=> document.getElementById('linkedinStartsWith').click(),300)
       setTimeout( ()=> {this.linkedinStartsWithText =  this.prospectFilterModel.lastname__startswith ;  this.handleUserInput('addressEndsWith') } ,200)
     } else if(this.prospectFilterModel.linkedin__isnull   != null && this.prospectFilterModel.linkedin__isnull ) {
      setTimeout( ()=> document.getElementById('flexRadioDefault23').click(),200)
       setTimeout( ()=>  document.getElementById('linkedinEmpty').click(),300)
     } else if(this.prospectFilterModel.linkedin__isnull != null && !this.prospectFilterModel.linkedin__isnull ) {
      setTimeout( ()=>document.getElementById('flexRadioDefault23').click(),200)
       setTimeout( ()=> document.getElementById('linkedinNotEmpty').click(),300)
     }

     if(this.prospectFilterModel.snippet__icontains != null && this.prospectFilterModel?.snippet__icontains.length > 0){
      setTimeout( ()=> document.getElementById('flexRadioDefault24').click(),200)
       setTimeout( ()=> document.getElementById('snippetContains').click(),300)
       setTimeout( ()=> {this.snippetContainsText =  this.prospectFilterModel.snippet__icontains ; this.handleUserInput('snippetContains')} ,200)
     }
     if(this.prospectFilterModel.snippet1__icontains != null && this.prospectFilterModel?.snippet1__icontains.length > 0){
      setTimeout( ()=> document.getElementById('flexRadioDefault25').click(),200)
       setTimeout( ()=> document.getElementById('snippet1Contains').click(),300)
       setTimeout( ()=> {this.snippet1ContainsText =  this.prospectFilterModel.snippet1__icontains ; this.handleUserInput('snippet1Contains')} ,200)
     }
     if(this.prospectFilterModel.snippet2__icontains != null && this.prospectFilterModel?.snippet2__icontains.length > 0){
      setTimeout( ()=>document.getElementById('flexRadioDefault26').click(),200)
       setTimeout( ()=> document.getElementById('snippet2Contains').click(),300)
       setTimeout( ()=> {this.snippet2ContainsText =  this.prospectFilterModel.snippet2__icontains ; this.handleUserInput('snippet2Contains')} ,200)
     }
     if(this.prospectFilterModel.snippet3__icontains != null && this.prospectFilterModel?.snippet3__icontains.length > 0){
      setTimeout( ()=>  document.getElementById('flexRadioDefault27').click(),200)
       setTimeout( ()=> document.getElementById('snippet3Contains').click(),300)
       setTimeout( ()=> {this.snippet3ContainsText =  this.prospectFilterModel.snippet3__icontains ; this.handleUserInput('snippet3Contains')} ,200)
     }
     if(this.prospectFilterModel.snippet4__icontains != null && this.prospectFilterModel?.snippet4__icontains.length > 0){
      setTimeout( ()=> document.getElementById('flexRadioDefault28').click(),200)
       setTimeout( ()=> document.getElementById('snippet4Contains').click(),300)
       setTimeout( ()=> {this.snippet4ContainsText =  this.prospectFilterModel.snippet4__icontains ; this.handleUserInput('snippet4Contains')} ,200)
     }
     } else if(localStorage.getItem('selectdProspectGroup')){
      this.vm.getAllProspectGroupsForProspect("", 'sidebar');
     }else{
      this.CallFinalFilter();
     }
  }


  ngAfterContentChecked() {

    // this.changeDetectRef.detectChanges();
}



  filterArr = [];
  showClearFilter = false;

  showImportStats() {
    var tempProspectImportStats = localStorage.getItem("prospectImportStats");
    if (tempProspectImportStats) {
      var stats = JSON.parse(tempProspectImportStats)
      const modalOptions = {
        data: { stats },
        modalClass: 'modal-medium'
      }
      this.modalService.open(ShowImportStatsComponent, modalOptions);
    }
  }

  filterHasElement() {
    return this.filterArr.length > 0;
  }
  searchSubmit(e: any) {

    if(this.prospectFilterModel.email_address__icontains != this.searchEmailId) {
       this.prospectFilterModel.email_address__icontains = this.searchEmailId;
      this.CallFinalFilter()
    }
   
    if(this.searchEmailId.length == 0){
      this.prospectFilterModel.email_address__icontains = null
    }

  }
  ProspectStatusRecieved() {
    this.prospectStatuslist = this.prospectStatusResponseModel.value.results;
  }

  UserRecieved() {
    this.userList = this.prospectUserResponseModel.value.results;
  }


  checkForSelectedProspectGroup(){
    var tempProspectGroupId = localStorage.getItem("selectdProspectGroup");
    if (tempProspectGroupId !== null) {
      this.prospectgroupId = tempProspectGroupId;
      this.prospectgroupCheckBox = true;
      let el: HTMLElement = this.myProspectGroup.nativeElement;
      let el1: HTMLElement = document.getElementById("propectGroup")
      // el.click()
      // el1.click()
      el.classList.add("show");
      el1.classList.add("show");
      this.onProspectGroupSelected();
      if (tempProspectGroupId !== null) {
        localStorage.removeItem('selectdProspectGroup');
      }

     
    }

    // this.vm.getAllCampaign("", 'sidebar');
    // var texts = document.getElementsByClassName("dropdown open bottom dropdown-menu ng-star-inserted") as HTMLCollectionOf<HTMLElement>;
    // if (texts.length > 0) {
    //   texts[0].style.fontSize = '11px';
    //   texts[0].style.marginLeft = '-52px';
    //   texts[0].style.width = '230px';
    // }
  }


  ProspectGroupReceived() {
    this.prospectGrouplist = this.prospectGroupResponseModel.value.results;
   
    if(localStorage.getItem('selectdProspectGroup')){
      this.checkForSelectedProspectGroup();
    } 
    // else if(!localStorage.getItem('lastUsedProspectFilter')){
    //   this.vm.getAllCampaign("", 'sidebar');
    // }
  
    this.prospectGroupChangeEvent.emit(this.prospectGrouplist);
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  

  onFocusProspectGroup() {
    this.delay(200).then(any => {
      var texts = document.getElementsByClassName("dropdown open bottom dropdown-menu ng-star-inserted") as HTMLCollectionOf<HTMLElement>;

      if(texts.length > 0){
        texts[0].style.fontSize = '11px';
        texts[0].style.marginLeft = '-52px';
        texts[0].style.width = '230px';
      }

    });

  }


  // onFocusEvent() {
  //   this.isFocused = true;
  //   this.vm.getAllCampaign("", 'sidebar');
  // }
  sleep(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }
  onPaste(event: any) {
    this.isPasted = true;
  }

  CampaignReceived() {
    this.tempCampaignlist = this.campaignResponseModel.value.results;

    this.campaignsChangeEvent.emit(this.tempCampaignlist);

    // if (this.isFocused) {
    //   this.campaignlist = this.tempCampaignlist.slice(0, 10);
    //   this.isFocused = false;
    // }
    // else {
      this.campaignlist = this.tempCampaignlist;
      if (this.isPasted) {

        let el: HTMLElement = this.campaignTypeSidebar.nativeElement;
        this.campaignTypeSidebar.nativeElement.blur();
        this.campaignTypeSidebar.nativeElement.focus();
        this.isPasted = false;
      }
    // }
    var tempCampaign = localStorage.getItem("selectedCampaign");
    if (tempCampaign !== null) {
      this.selectedCampaign = tempCampaign;
      this.campaignName = tempCampaign;
      this.onCampaignSelected(tempCampaign);
      this.campaignCheckBox = true;
      // let el: HTMLElement = this.myCampaign.nativeElement;
      // el.click();
      // let el1: HTMLElement = this.myCampaignNotIn.nativeElement;
      // el1.click();
      // el.classList.add("show");
      // el1.classList.add("show");
      // setTimeout(() => el1.click(), 2000);
      
      setTimeout(()=> localStorage.removeItem('selectedCampaign') , 100) ;
    }
    
  }

  onProspectSelect(event: TypeaheadMatch): void {
    //this.selectedCampaignObj = event.item;
    this.onProspectSelected(event.item.name);

  }

  onProspectSelected(name) {
    this.prospectgroupId = name;
    this.prospectGroupArr = [];
    this.prospectFilterModel.prospect_group__name = this.prospectgroupId;
    this.prospectGroupArr.push({ 'filterName': 'prospect_group__name' }, { 'filterValue': this.prospectgroupId });
    if(localStorage.getItem("selectdProspectGroup") == null){
      this.CallFinalFilter();
    }
   
  }

  changeTypeaheadProspectLoading(event) {
    // if (this.prospectgroupId && this.prospectgroupId.length > 2)
    //   this.vm.getAllProspectGroupsForProspect(this.prospectgroupId, 'sidebar');
      
      let tempVar = '' , checkInt
      clearTimeout(checkInt)
      // console.log(this.prospectgroupId ,event, this.prospectgroupId.length)
      if( this.prospectgroupId && this.prospectgroupId.length > 2){
        
       checkInt = setTimeout( ()=> {
        console.log(tempVar.length == this.prospectgroupId.length , tempVar.length, this.prospectgroupId.length, this.prospectGrouplist)
          if(tempVar.length == this.prospectgroupId.length){
            if(this.prospectFilterModel.prospect_group__name !=  this.prospectgroupId ) {
              this.prospectFilterModel.prospect_group__name = this.prospectgroupId;
              this.vm.getAllProspectGroupsForProspect(this.prospectgroupId, 'sidebar');
            }
          }
        }, 2900)
        setTimeout(() => {
          tempVar = this.prospectgroupId
        }, 2000);

      }
      if(this.prospectgroupId && this.prospectgroupId.length == 0){
        this.prospectFilterModel.prospect_group__name = null
      }
  }

  onSelect(event: any): void {
    //this.selectedCampaignObj = event.item;
    this.onCampaignSelected(event.item.name);
  }

  onCampaignSelected(name) {
    if (this.campaignArr.length > 0) {
      this.campaignName = name;
      this.campaignArr[1].filterValue = this.campaignName;
    }
    this.AddFilter('campaign');
  }

  //call all prospecting group
  changeTypeaheadLoading(event) {
    if (this.selectedCampaign && this.selectedCampaign.length > 2)
      this.vm.getAllCampaign(this.selectedCampaign, 'sidebar');
  }

  clearSelection(e) {
    e.preventDefault();
    localStorage.removeItem('lastUsedProspectFilter')
    if(this.prospectFilterModel?.email_address__icontains){
      this.searchEmailId =  null
   }
    this.prospectFilterModel = new ProspectFilterModel();
    this.prospectFilterModel.page = 1;
    this.prospectFilterModel.page_size = 30;
    this.pc.callFilter(this.prospectFilterModel);

    if (this.showDeletedObject) {
      this.showDeletedObject.target.checked = false;
    }

    // clear created by
    this.createdById = null;
    this.createdByArr = [];

    // clear group
    this.prospectgroupId = null;
    this.prospectGroupArr = [];

    // Auto reply
    this.autoReplyStatus = null;
    this.autoReplyArr = [];
    this.replyFilterArr = []
    // clear the child selection for optedOut
    if (this.optOutArr.length > 0) {
      this.optOutArr[2].radioObj.target.checked = false;
    }
    this.optOutArr = [];

    // Clear prospect interest
    this.prospectInterestStatus = null;
    if (this.prospectInterestArr.length > 0) {
      this.prospectInterestArr[2].radioObj.target.checked = false;
    }

    if(this.statusIdArr.length > 0){
      this.statusIdArr = [];
      this.prospectFilterModel.status__id = null
      this.statusId = null
    }

    // clear the child selection for opted out
    if (this.bouncedArr.length > 0) {
      this.bouncedArr[2].radioObj.target.checked = false;
    }
    this.bouncedArr = [];
    // clear the score
    if (this.scoreFilterArr.length > 0) {
      this.scoreFilterArr[2].radioObj.target.checked = false;
      this.scoreFilterArr[3].toggleObj.toggle();
    }
    this.scoreCount = null;
    this.activeScoreFilter = null;
    this.scoreFilterArr = [];

    // clear the message sent
    if (this.sentFilterArr.length > 0) {
      this.sentFilterArr[2].radioObj.target.checked = false;
      this.sentFilterArr[3].toggleObj.toggle();
    }
    this.sentCount = null;
    this.activeMessageSentFilter = null;
    this.sentFilterArr = [];
    // clear the message click
    if (this.clickFilterArr.length > 0) {
      this.clickFilterArr[2].radioObj.target.checked = false;
      this.clickFilterArr[3].toggleObj.toggle();
    }
    this.clickCount = null;
    this.activeMessageClickFilter = null;
    this.clickFilterArr = [];

    // clear the message click
    if (this.replyFilterArr.length > 0) {
      this.replyFilterArr[2].radioObj.target.checked = false;
      this.replyFilterArr[3].toggleObj.toggle();
    }
    this.repliesCount = 0;
    this.activeMessageReplyFilter = null;
    this.replyFilterArr = [];

    // Clear last contacted
    if (this.lastContactedFilterArr.length > 0) {
      this.lastContactedFilterArr[2].radioObj.target.checked = false;
      this.lastContactedFilterArr[3].toggleObj.toggle();
    }
    this.lastContactedCount = null;
    this.activeLastContactedFilter = null;
    this.lastContactedFilterArr = [];


    // clear campaign
    if (this.campaignArr.length > 0) {
      this.campaignArr[2].radioObj.target.checked = false;
      this.campaignArr[3].toggleObj.toggle();
    }
    this.campaignName = null;
    this.activeCampaing = null;
    this.activeCampaignFilter = null;
    this.selectedCampaign = null;
    this.campaignArr = [];

    // clear email
    if (this.emailArr.length > 0) {
      this.emailArr[2].radioObj.target.checked = false;
      this.emailArr[3].toggleObj.toggle();
    }

    this.emailEndsWithText = null;
    this.emailStartsWithText = null;
    this.emailContainsText = null;
    this.activeEmail = null;
    this.emailArr = [];

    // clear fname
    if (this.firstnameArr.length > 0) {
      this.firstnameArr[2].radioObj.target.checked = false;
      this.firstnameArr[3].toggleObj.toggle();
    }

    this.firstnameEndsWithText = null;
    this.firstnameStartsWithText = null;
    this.firstnameContainsText = null;
    this.activefirstname = null;
    this.firstnameArr = [];

    // clear last name
    if (this.lastnameArr.length > 0) {
      this.lastnameArr[2].radioObj.target.checked = false;
      this.lastnameArr[3].toggleObj.toggle();
    }

    this.lastnameEndsWithText = null;
    this.lastnameStartsWithText = null;
    this.lastnameContainsText = null;
    this.activelastname = null;
    this.lastnameArr = [];

    // clear Company
    if (this.companyArr.length > 0) {
      this.companyArr[2].radioObj.target.checked = false;
      this.companyArr[3].toggleObj.toggle();
    }

    this.companyEndsWithText = null;
    this.companyStartsWithText = null;
    this.companyContainsText = null;
    this.activecompany = null;
    this.companyArr = [];

    // clear Industry
    if (this.industryArr.length > 0) {
      this.industryArr[2].radioObj.target.checked = false;
      this.industryArr[3].toggleObj.toggle();
    }

    this.industryEndsWithText = null;
    this.industryStartsWithText = null;
    this.industryContainsText = null;
    this.activeindustry = null;
    this.industryArr = [];

    // clear Phone
    if (this.phoneArr.length > 0) {
      this.phoneArr[2].radioObj.target.checked = false;
      this.phoneArr[3].toggleObj.toggle();
    }

    this.phoneEndsWithText = null;
    this.phoneStartsWithText = null;
    this.phoneContainsText = null;
    this.activephone = null;
    this.phoneArr = [];

    // clear job_title
    if (this.job_titleArr.length > 0) {
      this.job_titleArr[2].radioObj.target.checked = false;
      this.job_titleArr[3].toggleObj.toggle();
    }

    this.job_titleEndsWithText = null;
    this.job_titleStartsWithText = null;
    this.job_titleContainsText = null;
    this.activejob_title = null;
    this.job_titleArr = [];

    // clear city
    if (this.cityArr.length > 0) {
      this.cityArr[2].radioObj.target.checked = false;
      this.cityArr[3].toggleObj.toggle();
    }

    this.cityEndsWithText = null;
    this.cityStartsWithText = null;
    this.cityContainsText = null;
    this.activecity = null;
    this.cityArr = [];

    // clear state
    if (this.stateArr.length > 0) {
      this.stateArr[2].radioObj.target.checked = false;
      this.stateArr[3].toggleObj.toggle();
    }

    this.stateEndsWithText = null;
    this.stateStartsWithText = null;
    this.stateContainsText = null;
    this.activestate = null;
    this.stateArr = [];


    // clear region
    if (this.regionArr.length > 0) {
      this.regionArr[2].radioObj.target.checked = false;
      this.regionArr[3].toggleObj.toggle();
    }

    this.regionEndsWithText = null;
    this.regionStartsWithText = null;
    this.regionContainsText = null;
    this.activeregion = null;
    this.regionArr = [];


    // clear country
    if (this.countryArr.length > 0) {
      this.countryArr[2].radioObj.target.checked = false;
      this.countryArr[3].toggleObj.toggle();
    }

    this.countryEndsWithText = null;
    this.countryStartsWithText = null;
    this.countryContainsText = null;
    this.activecountry = null;
    this.countryArr = [];

    // clear address
    if (this.addressArr.length > 0) {
      this.addressArr[2].radioObj.target.checked = false;
      this.addressArr[3].toggleObj.toggle();
    }

    this.addressEndsWithText = null;
    this.addressStartsWithText = null;
    this.addressContainsText = null;
    this.activeaddress = null;
    this.addressArr = [];

    // clear linkedin
    if (this.linkedinArr.length > 0) {
      this.linkedinArr[2].radioObj.target.checked = false;
      this.linkedinArr[3].toggleObj.toggle();
    }

    this.linkedinEndsWithText = null;
    this.linkedinStartsWithText = null;
    this.linkedinContainsText = null;
    this.activelinkedin = null;
    this.linkedinArr = [];

    // clear snippet
    if (this.snippetArr.length > 0) {
      this.snippetArr[2].radioObj.target.checked = false;
      this.snippetArr[3].toggleObj.toggle();
    }

    this.snippetContainsText = null;
    this.activesnippet = null;
    this.snippetArr = [];

    // clear snippet1
    if (this.snippet1Arr.length > 0) {
      this.snippet1Arr[2].radioObj.target.checked = false;
      this.snippet1Arr[3].toggleObj.toggle();
    }

    this.snippet1ContainsText = null;
    this.activesnippet1 = null;
    this.snippet1Arr = [];

    // clear snippet2
    if (this.snippet2Arr.length > 0) {
      this.snippet2Arr[2].radioObj.target.checked = false;
      this.snippet2Arr[3].toggleObj.toggle();
    }

    this.snippet2ContainsText = null;
    this.activesnippet2 = null;
    this.snippet2Arr = [];

    // clear snippet3
    if (this.snippet3Arr.length > 0) {
      this.snippet3Arr[2].radioObj.target.checked = false;
      this.snippet3Arr[3].toggleObj.toggle();
    }

    this.snippet3ContainsText = null;
    this.activesnippet3 = null;
    this.snippet3Arr = [];

    // clear snippet4
    if (this.snippet4Arr.length > 0) {
      this.snippet4Arr[2].radioObj.target.checked = false;
      this.snippet4Arr[3].toggleObj.toggle();
    }

    this.snippet4ContainsText = null;
    this.activesnippet4 = null;
    this.snippet4Arr = [];

    //this.pc.uncheckOnClearSelection();
    // uncheck all parent selection
    this.filterArr.forEach((element, index) => {
      if (element.toggleObj) {
        element.toggleObj.toggle();
      }
      element.filterObj.target.checked = false;
    });
    this.filterArr = [];


    this.showClearFilter = this.filterHasElement();
  }

  activeCampaing = null;
  toggleCampaign(obj = null) {
    if (obj == this.activeCampaing)
      return;
    if (this.activeCampaing) {
      this.activeCampaing.toggle();
    }

    if (obj) {
      obj.toggle();
    }

    this.activeCampaing = obj;
  }


  activeScoreFilter = null;
  toggleScoreFilter(obj = null, actionSelected, optedRadio) {
    if (actionSelected == 'scoresMoreThan' ) {
      this.prospectFilterModel.score__gt = this.scoreCount;
      this.prospectFilterModel.score__lt = null;
      this.scoreFilterArr = [];
      this.scoreFilterArr.push({ 'filterName': 'score__gt' }, { 'filterValue': this.scoreCount }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'scoresLessThan' ) {
      this.prospectFilterModel.score__gt = null;
      this.prospectFilterModel.score__lt = this.scoreCount;
      this.scoreFilterArr = [];
      this.scoreFilterArr.push({ 'filterName': 'score__lt' }, { 'filterValue': this.scoreCount }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }

    this.CallFinalFilter();
    if (obj == this.activeScoreFilter)
      return;
    if (this.activeScoreFilter) {
      this.activeScoreFilter.toggle();
    }

    if (obj) {
      obj.toggle();
    }

    this.activeScoreFilter = obj;
  }


  activeMessageSentFilter = null;
  toggleMessageSentFilter(obj = null, actionSelected, optedRadio) {

    if (actionSelected == 'msgSentMoreThan' ) {
      this.prospectFilterModel.cnt_email_sent = null;
      this.prospectFilterModel.cnt_email_sent__gt = this.sentCount;
      this.prospectFilterModel.cnt_email_sent__lt = null;
      this.sentFilterArr = [];
      this.sentFilterArr.push({ 'filterName': 'cnt_email_sent__gt' }, { 'filterValue': this.sentCount }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'msgSentLessThan' ) {
      this.prospectFilterModel.cnt_email_sent = null;
      this.prospectFilterModel.cnt_email_sent__gt = null;
      this.prospectFilterModel.cnt_email_sent__lt = this.sentCount;
      this.sentFilterArr = [];
      this.sentFilterArr.push({ 'filterName': 'cnt_email_sent__lt' }, { 'filterValue': this.sentCount }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'msgSentEqualTo' ) {
      this.prospectFilterModel.cnt_email_sent = this.sentCount;
      this.prospectFilterModel.cnt_email_sent__gt = null;
      this.prospectFilterModel.cnt_email_sent__lt = null;
      this.sentFilterArr = [];
      this.sentFilterArr.push({ 'filterName': 'cnt_email_sent' }, { 'filterValue': this.sentCount }, { 'radioObj': optedRadio }), { 'toggleObj': obj };

    }
    this.CallFinalFilter();
    if (obj == this.activeMessageSentFilter)
      return;
    if (this.activeMessageSentFilter) {
      this.activeMessageSentFilter.toggle();
    }

    if (obj) {
      obj.toggle();
    }

    this.activeMessageSentFilter = obj;
  }


  activeLastContactedFilter = null;
  toggleLastContactedFilter(obj = null, actionSelected, optedRadio) {
    if (actionSelected == 'lastContactedLessThan'  ) {
      this.prospectFilterModel.last_contacted_period__gt = null
      this.prospectFilterModel.last_contacted_period__lt = this.lastContactedCount;;
      this.prospectFilterModel.last_contacted_period__eq = null;
      this.lastContactedFilterArr = [];
      this.lastContactedFilterArr.push({ 'filterName': 'last_contacted_period__gt' }, { 'filterValue': this.lastContactedCount }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'lastContactedMoreThan'  ) {
      this.prospectFilterModel.last_contacted_period__gt = this.lastContactedCount;
      this.prospectFilterModel.last_contacted_period__lt = null;
      this.prospectFilterModel.last_contacted_period__eq = null;
      this.lastContactedFilterArr = [];
      this.lastContactedFilterArr.push({ 'filterName': 'last_contacted_period__lt' }, { 'filterValue': this.lastContactedCount }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'lastContactedEqualTo'  ) {
      this.prospectFilterModel.last_contacted_period__gt = null;
      this.prospectFilterModel.last_contacted_period__lt = null;
      this.prospectFilterModel.last_contacted_period__eq = this.lastContactedCount;
      this.lastContactedFilterArr = [];
      this.lastContactedFilterArr.push({ 'filterName': 'last_contacted_period__eq' }, { 'filterValue': this.lastContactedCount }, { 'radioObj': optedRadio }, { 'toggleObj': obj });

    }
    this.CallFinalFilter();
    if (obj == this.activeLastContactedFilter)
      return;
    if (this.activeLastContactedFilter) {
      this.activeLastContactedFilter.toggle();
    }

    if (obj) {
      obj.toggle();
    }

    this.activeLastContactedFilter = obj;
  }

  activeMessageClickFilter = null;
  toggleMessageClickFilter(obj = null, actionSelected, optedRadio) {
    if (actionSelected == 'msgClicksMoreThan'  ) {
      this.prospectFilterModel.cnt_email_open = null;
      this.prospectFilterModel.cnt_email_open__gt = this.clickCount;
      this.prospectFilterModel.cnt_email_open__lt = null;
      this.clickFilterArr = [];
      this.clickFilterArr.push({ 'filterName': 'cnt_email_open__gt' }, { 'filterValue': this.clickCount }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'msgClicksLessThan'  ) {
      this.prospectFilterModel.cnt_email_open = null;
      this.prospectFilterModel.cnt_email_open__gt = null;
      this.prospectFilterModel.cnt_email_open__lt = this.clickCount;
      this.clickFilterArr = [];
      this.clickFilterArr.push({ 'filterName': 'cnt_email_open__lt' }, { 'filterValue': this.clickCount }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'msgClicksEqualTo'  ) {
      this.prospectFilterModel.cnt_email_open = this.clickCount;
      this.prospectFilterModel.cnt_email_open__gt = null;
      this.prospectFilterModel.cnt_email_open__lt = null;
      this.clickFilterArr = [];
      this.clickFilterArr.push({ 'filterName': 'cnt_email_open' }, { 'filterValue': this.clickCount }, { 'radioObj': optedRadio }, { 'toggleObj': obj });

    }
    this.CallFinalFilter();
    if (obj == this.activeMessageClickFilter)
      return;
    if (this.activeMessageClickFilter) {
      this.activeMessageClickFilter.toggle();
    }

    if (obj) {
      obj.toggle();
    }

    this.activeMessageClickFilter = obj;
  }

  // this message handle input for Message reply, clicked and sent
  handleUserInput(inputVal) {
    if (inputVal == 'lastContacted') {
      if (this.lastContactedFilterArr.length > 0) {
        this.lastContactedFilterArr[1].filterValue = this.lastContactedCount;
      }
      this.AddFilter('lastContacted');
    }

    if (inputVal == 'msgReplies') {
      if (this.replyFilterArr.length > 0) {
        this.replyFilterArr[1].filterValue = this.repliesCount;
      }
      this.AddFilter('msgReplies');
    }
    if (inputVal == 'msgClicks') {
      if (this.clickFilterArr.length > 0) {
        this.clickFilterArr[1].filterValue = this.clickCount;
      }
      this.AddFilter('msgClicks');
    }
    if (inputVal == 'msgSent') {
      if (this.sentFilterArr.length > 0) {
        this.sentFilterArr[1].filterValue = this.sentCount;
      }
      this.AddFilter('msgSent');
    }
    


    // score

    if (inputVal == 'scores') {
      if (this.scoreFilterArr.length > 0) {
        this.scoreFilterArr[1].filterValue = this.scoreCount;
      }
      this.AddFilter('scores');
    }
    // email
    if (inputVal == 'emailContains') {
      console.log(this.emailArr)
      if (this.emailArr.length > 0) {
        this.emailArr[1].filterValue = this.emailContainsText;
      }
      this.AddFilter('email');
    }
    if (inputVal == 'emailStartsWith') {
      console.log(this.emailArr)
      if (this.emailArr.length > 0) {
        this.emailArr[1].filterValue = this.emailStartsWithText;
      }
      this.AddFilter('email');
    }
    if (inputVal == 'emailEndsWith') {
      if (this.emailArr.length > 0) {
        this.emailArr[1].filterValue = this.emailEndsWithText;
      }
      this.AddFilter('email');
    }
    if (inputVal == 'emailIs') {
      if (this.emailArr.length > 0) {
        this.emailArr[1].filterValue = this.emailIsText;
      }
      this.AddFilter('email');
    }

    // firstname
    if (inputVal == 'firstnameContains') {
      if (this.firstnameArr.length > 0) {
        this.firstnameArr[1].filterValue = this.firstnameContainsText;
      }
      this.AddFilter('firstname');
    }
    if (inputVal == 'firstnameStartsWith') {
      if (this.firstnameArr.length > 0) {
        this.firstnameArr[1].filterValue = this.firstnameStartsWithText;
      }
      this.AddFilter('firstname');
    }
    if (inputVal == 'firstnameEndsWith') {
      if (this.firstnameArr.length > 0) {
        this.firstnameArr[1].filterValue = this.firstnameEndsWithText;
      }
      this.AddFilter('firstname');
    }

    // lastname
    if (inputVal == 'lastnameContains') {
      if (this.lastnameArr.length > 0) {
        this.lastnameArr[1].filterValue = this.lastnameContainsText;
      }
      this.AddFilter('lastname');
    }
    if (inputVal == 'lastnameStartsWith') {
      if (this.lastnameArr.length > 0) {
        this.lastnameArr[1].filterValue = this.lastnameStartsWithText;
      }
      this.AddFilter('lastname');
    }
    if (inputVal == 'lastnameEndsWith') {
      if (this.lastnameArr.length > 0) {
        this.lastnameArr[1].filterValue = this.lastnameEndsWithText;
      }
      this.AddFilter('lastname');
    }

    // company
    if (inputVal == 'companyContains') {
      if (this.companyArr.length > 0) {
        this.companyArr[1].filterValue = this.companyContainsText;
      }
      this.AddFilter('company');
    }
    if (inputVal == 'companyStartsWith') {
      if (this.companyArr.length > 0) {
        this.companyArr[1].filterValue = this.companyStartsWithText;
      }
      this.AddFilter('company');
    }
    if (inputVal == 'companyEndsWith') {
      if (this.companyArr.length > 0) {
        this.companyArr[1].filterValue = this.companyEndsWithText;
      }
      this.AddFilter('company');
    }
    // industry
    if (inputVal == 'industryContains') {
      if (this.industryArr.length > 0) {
        this.industryArr[1].filterValue = this.industryContainsText;
      }
      this.AddFilter('industry');
    }
    if (inputVal == 'industryStartsWith') {
      if (this.industryArr.length > 0) {
        this.industryArr[1].filterValue = this.industryStartsWithText;
      }
      this.AddFilter('industry');
    }
    if (inputVal == 'industryEndsWith') {
      if (this.industryArr.length > 0) {
        this.industryArr[1].filterValue = this.industryEndsWithText;
      }
      this.AddFilter('industry');
    }

    // phone
    if (inputVal == 'phoneContains') {
      if (this.phoneArr.length > 0) {
        this.phoneArr[1].filterValue = this.phoneContainsText;
      }
      this.AddFilter('phone');
    }
    if (inputVal == 'phoneStartsWith') {
      if (this.phoneArr.length > 0) {
        this.phoneArr[1].filterValue = this.phoneStartsWithText;
      }
      this.AddFilter('phone');
    }
    if (inputVal == 'phoneEndsWith') {
      if (this.phoneArr.length > 0) {
        this.phoneArr[1].filterValue = this.phoneEndsWithText;
      }
      this.AddFilter('phone');
    }

    // job_title
    if (inputVal == 'job_titleContains') {
      if (this.job_titleArr.length > 0) {
        this.job_titleArr[1].filterValue = this.job_titleContainsText;
      }
      this.AddFilter('job_title');
    }
    if (inputVal == 'job_titleStartsWith') {
      if (this.job_titleArr.length > 0) {
        this.job_titleArr[1].filterValue = this.job_titleStartsWithText;
      }
      this.AddFilter('job_title');
    }
    if (inputVal == 'job_titleEndsWith') {
      if (this.job_titleArr.length > 0) {
        this.job_titleArr[1].filterValue = this.job_titleEndsWithText;
      }
      this.AddFilter('job_title');
    }

    // city
    if (inputVal == 'cityContains') {
      if (this.cityArr.length > 0) {
        this.cityArr[1].filterValue = this.cityContainsText;
      }
      this.AddFilter('city');
    }
    if (inputVal == 'cityStartsWith') {
      if (this.cityArr.length > 0) {
        this.cityArr[1].filterValue = this.cityStartsWithText;
      }
      this.AddFilter('city');
    }
    if (inputVal == 'cityEndsWith') {
      if (this.cityArr.length > 0) {
        this.cityArr[1].filterValue = this.cityEndsWithText;
      }
      this.AddFilter('city');
    }

    // state
    if (inputVal == 'stateContains') {
      if (this.stateArr.length > 0) {
        this.stateArr[1].filterValue = this.stateContainsText;
      }
      this.AddFilter('state');
    }
    if (inputVal == 'stateStartsWith') {
      if (this.stateArr.length > 0) {
        this.stateArr[1].filterValue = this.stateStartsWithText;
      }
      this.AddFilter('state');
    }
    if (inputVal == 'stateEndsWith') {
      if (this.stateArr.length > 0) {
        this.stateArr[1].filterValue = this.stateEndsWithText;
      }
      this.AddFilter('state');
    }

    // region
    if (inputVal == 'regionContains') {
      if (this.regionArr.length > 0) {
        this.regionArr[1].filterValue = this.regionContainsText;
      }
      this.AddFilter('region');
    }
    if (inputVal == 'regionStartsWith') {
      if (this.regionArr.length > 0) {
        this.regionArr[1].filterValue = this.regionStartsWithText;
      }
      this.AddFilter('region');
    }
    if (inputVal == 'regionEndsWith') {
      if (this.regionArr.length > 0) {
        this.regionArr[1].filterValue = this.regionEndsWithText;
      }
      this.AddFilter('region');
    }


    // country
    if (inputVal == 'countryContains') {
      if (this.countryArr.length > 0) {
        this.countryArr[1].filterValue = this.countryContainsText;
      }
      this.AddFilter('country');
    }
    if (inputVal == 'countryStartsWith') {
      if (this.countryArr.length > 0) {
        this.countryArr[1].filterValue = this.countryStartsWithText;
      }
      this.AddFilter('country');
    }
    if (inputVal == 'countryEndsWith') {
      if (this.countryArr.length > 0) {
        this.countryArr[1].filterValue = this.countryEndsWithText;
      }
      this.AddFilter('country');
    }

    // address
    if (inputVal == 'addressContains') {
      if (this.addressArr.length > 0) {
        this.addressArr[1].filterValue = this.addressContainsText;
      }
      this.AddFilter('address');
    }
    if (inputVal == 'addressStartsWith') {
      if (this.addressArr.length > 0) {
        this.addressArr[1].filterValue = this.addressStartsWithText;
      }
      this.AddFilter('address');
    }
    if (inputVal == 'addressEndsWith') {
      if (this.addressArr.length > 0) {
        this.addressArr[1].filterValue = this.addressEndsWithText;
      }
      this.AddFilter('address');
    }

    // linkedin
    if (inputVal == 'linkedinContains') {
      if (this.linkedinArr.length > 0) {
        this.linkedinArr[1].filterValue = this.linkedinContainsText;
      }
      this.AddFilter('linkedin');
    }
    if (inputVal == 'linkedinStartsWith') {
      if (this.linkedinArr.length > 0) {
        this.linkedinArr[1].filterValue = this.linkedinStartsWithText;
      }
      this.AddFilter('linkedin');
    }
    if (inputVal == 'linkedinEndsWith') {
      if (this.linkedinArr.length > 0) {
        this.linkedinArr[1].filterValue = this.linkedinEndsWithText;
      }
      this.AddFilter('linkedin');
    }
    if (inputVal == 'snippetContains') {
      if (this.snippetArr.length > 0) {
        this.snippetArr[1].filterValue = this.snippetContainsText;
      }
      this.AddFilter('snippet');
    }

    if (inputVal == 'snippet1Contains') {
      if (this.snippet1Arr.length > 0) {
        this.snippet1Arr[1].filterValue = this.snippet1ContainsText;
      }
      this.AddFilter('snippet1');
    }

    if (inputVal == 'snippet2Contains') {
      if (this.snippet2Arr.length > 0) {
        this.snippet2Arr[1].filterValue = this.snippet2ContainsText;
      }
      this.AddFilter('snippet2');
    }

    if (inputVal == 'snippet3Contains') {
      if (this.snippet3Arr.length > 0) {
        this.snippet3Arr[1].filterValue = this.snippet3ContainsText;
      }
      this.AddFilter('snippet3');
    }

    if (inputVal == 'snippet4Contains') {
      if (this.snippet4Arr.length > 0) {
        this.snippet4Arr[1].filterValue = this.snippet4ContainsText;
      }
      this.AddFilter('snippet4');
    }

    // if (inputVal == 'campaign') {
    //   if (this.campaignArr.length > 0) {
    //     this.campaignArr[1].filterValue = this.campaignName;
    //   }
    //   this.AddFilter('campaign');
    // }

  };
  activeMessageReplyFilter = null;
  toggleMessageReplyFilter(obj = null, actionSelected, optedRadio) {
    if (actionSelected == 'yes' ) {
      this.prospectFilterModel.cnt_replies_received = null;
      this.prospectFilterModel.cnt_replies_received__gt = this.repliesCount;
      this.prospectFilterModel.cnt_replies_received__lt = null;
      this.replyFilterArr = [];
      this.replyFilterArr.push({ 'filterName': 'cnt_replies_received__gt' }, { 'filterValue': this.repliesCount }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }

    if (actionSelected == 'no' ) {
      this.prospectFilterModel.cnt_replies_received = this.repliesCount;
      this.prospectFilterModel.cnt_replies_received__gt = null;
      this.prospectFilterModel.cnt_replies_received__lt = null;
      this.replyFilterArr = [];

      this.replyFilterArr.push({ 'filterName': 'cnt_replies_received' }, { 'filterValue': this.repliesCount }, { 'radioObj': optedRadio }, { 'toggleObj': obj });

    }
    // if (actionSelected == 'msgRepliesMoreThan') {
    //   this.prospectFilterModel.cnt_replies_received = null;
    //   this.prospectFilterModel.cnt_replies_received__gt = this.repliesCount;
    //   this.prospectFilterModel.cnt_replies_received__lt = null;
    //   this.replyFilterArr = [];
    //   this.replyFilterArr.push({ 'filterName': 'cnt_replies_received__gt' }, { 'filterValue': this.repliesCount }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    // }
    // if (actionSelected == 'msgRepliesLessThan') {
    //   this.prospectFilterModel.cnt_replies_received = null;
    //   this.prospectFilterModel.cnt_replies_received__gt = null;
    //   this.prospectFilterModel.cnt_replies_received__lt = this.repliesCount;
    //   this.replyFilterArr = [];
    //   this.replyFilterArr.push({ 'filterName': 'cnt_replies_received__lt' }, { 'filterValue': this.repliesCount }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    // }
    // if (actionSelected == 'msgRepliesEqualTo') {
    //   this.prospectFilterModel.cnt_replies_received = this.repliesCount;
    //   this.prospectFilterModel.cnt_replies_received__gt = null;
    //   this.prospectFilterModel.cnt_replies_received__lt = null;
    //   this.replyFilterArr = [];

    //   this.replyFilterArr.push({ 'filterName': 'cnt_replies_received' }, { 'filterValue': this.repliesCount }, { 'radioObj': optedRadio }, { 'toggleObj': obj });

    // }
    this.CallFinalFilter();
    if (obj == this.activeMessageReplyFilter)
      return;
    if (this.activeMessageReplyFilter) {
      this.activeMessageReplyFilter.toggle();
    }

    if (obj) {
      obj.toggle();
    }

    this.activeMessageReplyFilter = obj;

  }

  activeCallCompleteFilter = null;
  toggleCallCompleteFilter(obj = null) {
    if (obj == this.activeCallCompleteFilter)
      return;
    if (this.activeCallCompleteFilter) {
      this.activeCallCompleteFilter.toggle();
    }

    if (obj) {
      obj.toggle();
    }

    this.activeCallCompleteFilter = obj;
  }

  // activeLastConnectedFilter = null;
  // toggleLastConnectedFilter(obj = null) {
  //   if (obj == this.activeLastConnectedFilter)
  //     return;
  //   if (this.activeLastConnectedFilter) {
  //     this.activeLastConnectedFilter.toggle();
  //   }

  //   if (obj) {
  //     obj.toggle();
  //   }

  //   this.activeLastConnectedFilter = obj;
  // }
  activeCampaignFilter = null;
  togglecampaignFilter(obj = null, actionSelected, optedRadio) {

    if (actionSelected == 'campaignMoreThan' ) {
      this.prospectFilterModel.campaign__eq = this.campaignName;
      this.prospectFilterModel.campaign__neq = null;

      this.campaignArr = [];
      this.campaignArr.push({ 'filterName': 'campaign__eq' }, { 'filterValue': this.campaignName }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'campaignEqual' ) {
      this.prospectFilterModel.campaign__eq = null;
      this.prospectFilterModel.campaign__neq = this.campaignName;

      this.campaignArr = [];
      this.campaignArr.push({ 'filterName': 'campaign__neq' }, { 'filterValue': this.campaignName }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if(!localStorage.getItem('selectedCampaign') && !this.lastusedFilters){
      this.CallFinalFilter();
    }
    

    if (obj == this.activeCampaignFilter)
      return;
    if (this.activeCampaignFilter) {
      this.activeCampaignFilter.toggle();
    }

    if (obj) {
      obj.toggle();
    }

    this.activeCampaignFilter = obj;
  }
  sidebarFilter(obj:MdbCollapseDirective = null, filterName, checkboxObj) {
    if (obj) {
      // check if element exist of not
      var exists = false;
      this.filterArr.forEach((element, index) => {
        if (element.filterName == filterName)
          exists = true;
      });
      if (!exists) {

        this.filterArr.push({ 'filterName': filterName, 'filterObj': checkboxObj, 'toggleObj': obj });
        // Add filter value
        this.AddFilter(filterName);
      }
      else {
        this.filterArr.forEach((element, index) => {
          if (element.filterName == filterName) this.filterArr.splice(index, 1);
        });
        // remove filter
        this.RemoveFilter(filterName);

      }
      obj.toggle();

    }

    if (filterName == 'showDeleted') {
      if (checkboxObj.target.checked) {
        this.prospectFilterModel.is_deleted = true;
        this.showDeletedObject = checkboxObj;
        this.filterArr.push({ 'filterName': filterName, 'filterObj': checkboxObj, 'toggleObj': null });
        this.CallFinalFilter();
      } else {
        this.prospectFilterModel.is_deleted = false;
        this.showDeletedObject = null;
        this.filterArr.forEach((element, index) => {
          if (element.filterName == filterName) this.filterArr.splice(index, 1);
        });
        this.CallFinalFilter();
      }
    }


    this.showClearFilter = this.filterHasElement();
  }

  AddFilter(filterName) {
    if (filterName == 'msgReplies') {
      if (this.replyFilterArr.length > 0) {
        if (this.replyFilterArr[0].filterName == 'cnt_replies_received')
          this.prospectFilterModel.cnt_replies_received = this.replyFilterArr[1].filterValue;

        if (this.replyFilterArr[0].filterName == 'cnt_replies_received__gt')
          this.prospectFilterModel.cnt_replies_received__gt = this.replyFilterArr[1].filterValue;

        if (this.replyFilterArr[0].filterName == 'cnt_replies_received__lt')
          this.prospectFilterModel.cnt_replies_received__lt = this.replyFilterArr[1].filterValue;
      }
    }

    if (filterName == 'msgClicks') {
      if (this.clickFilterArr.length > 0) {
        if (this.clickFilterArr[0].filterName == 'cnt_email_open')
          this.prospectFilterModel.cnt_email_open = this.clickFilterArr[1].filterValue;

        if (this.clickFilterArr[0].filterName == 'cnt_email_open__gt')
          this.prospectFilterModel.cnt_email_open__gt = this.clickFilterArr[1].filterValue;

        if (this.clickFilterArr[0].filterName == 'cnt_email_open__lt')
          this.prospectFilterModel.cnt_email_open__lt = this.clickFilterArr[1].filterValue;
      }
    }

    if (filterName == 'lastContacted') {
      if (this.lastContactedFilterArr.length > 0) {
        if (this.lastContactedFilterArr[0].filterName == 'last_contacted_period__gt')
          this.prospectFilterModel.last_contacted_period__gt = this.lastContactedFilterArr[1].filterValue;

        if (this.lastContactedFilterArr[0].filterName == 'last_contacted_period__lt')
          this.prospectFilterModel.last_contacted_period__lt = this.lastContactedFilterArr[1].filterValue;

        if (this.lastContactedFilterArr[0].filterName == 'last_contacted_period__eq')
          this.prospectFilterModel.last_contacted_period__eq = this.lastContactedFilterArr[1].filterValue;
      }
    }


    if (filterName == 'msgSent') {
      if (this.sentFilterArr.length > 0) {
        if (this.sentFilterArr[0].filterName == 'cnt_email_sent')
          this.prospectFilterModel.cnt_email_sent = this.sentFilterArr[1].filterValue;

        if (this.sentFilterArr[0].filterName == 'cnt_email_sent__gt')
          this.prospectFilterModel.cnt_email_sent__gt = this.sentFilterArr[1].filterValue;

        if (this.sentFilterArr[0].filterName == 'cnt_email_sent__lt')
          this.prospectFilterModel.cnt_email_sent__lt = this.sentFilterArr[1].filterValue;
      }
    }

    if (filterName == 'scores') {
      if (this.scoreFilterArr.length > 0) {

        if (this.scoreFilterArr[0].filterName == 'score__gt')
          this.prospectFilterModel.score__gt = this.scoreFilterArr[1].filterValue;

        if (this.scoreFilterArr[0].filterName == 'score__lt')
          this.prospectFilterModel.score__lt = this.scoreFilterArr[1].filterValue;
      }
    }

    if (filterName == 'email') {
      if (this.emailArr.length > 0) {
        if (this.emailArr[0].filterName == 'email_address')
          this.prospectFilterModel.email_address = this.emailArr[1].filterValue;

        if (this.emailArr[0].filterName == 'email_address__icontains')
          this.prospectFilterModel.email_address__icontains = this.emailArr[1].filterValue;

        if (this.emailArr[0].filterName == 'email_address__startswith')
          this.prospectFilterModel.email_address__startswith = this.emailArr[1].filterValue;

        if (this.emailArr[0].filterName == 'email_address__endswith')
          this.prospectFilterModel.email_address__endswith = this.emailArr[1].filterValue;
      }
    }
    if (filterName == 'firstname') {
      if (this.firstnameArr.length > 0) {

        if (this.firstnameArr[0].filterName == 'firstname__icontains')
          this.prospectFilterModel.firstname__icontains = this.firstnameArr[1].filterValue;

        if (this.firstnameArr[0].filterName == 'firstname__startswith')
          this.prospectFilterModel.firstname__startswith = this.firstnameArr[1].filterValue;

        if (this.firstnameArr[0].filterName == 'firstname__endswith')
          this.prospectFilterModel.firstname__endswith = this.firstnameArr[1].filterValue;
      }
    }
    if (filterName == 'lastname') {
      if (this.lastnameArr.length > 0) {

        if (this.lastnameArr[0].filterName == 'lastname__icontains')
          this.prospectFilterModel.lastname__icontains = this.lastnameArr[1].filterValue;

        if (this.lastnameArr[0].filterName == 'lastname__startswith')
          this.prospectFilterModel.lastname__startswith = this.lastnameArr[1].filterValue;

        if (this.lastnameArr[0].filterName == 'lastname__endswith')
          this.prospectFilterModel.lastname__endswith = this.lastnameArr[1].filterValue;
      }
    }

    if (filterName == 'company') {
      if (this.companyArr.length > 0) {

        if (this.companyArr[0].filterName == 'company__icontains')
          this.prospectFilterModel.company__icontains = this.companyArr[1].filterValue;

        if (this.companyArr[0].filterName == 'company__startswith')
          this.prospectFilterModel.company__startswith = this.companyArr[1].filterValue;

        if (this.companyArr[0].filterName == 'company__endswith')
          this.prospectFilterModel.company__endswith = this.companyArr[1].filterValue;

        if (this.companyArr[0].filterName == 'company__isnull')
          this.prospectFilterModel.company__isnull = this.companyArr[1].filterValue;
      }
    }

    if (filterName == 'industry') {
      if (this.industryArr.length > 0) {

        if (this.industryArr[0].filterName == 'industry__icontains')
          this.prospectFilterModel.industry__icontains = this.industryArr[1].filterValue;

        if (this.industryArr[0].filterName == 'industry__startswith')
          this.prospectFilterModel.industry__startswith = this.industryArr[1].filterValue;

        if (this.industryArr[0].filterName == 'industry__endswith')
          this.prospectFilterModel.industry__endswith = this.industryArr[1].filterValue;

        if (this.industryArr[0].filterName == 'industry__isnull')
          this.prospectFilterModel.industry__isnull = this.industryArr[1].filterValue;
      }
    }

    if (filterName == 'phone') {
      if (this.phoneArr.length > 0) {

        if (this.phoneArr[0].filterName == 'phone__icontains')
          this.prospectFilterModel.phone__icontains = this.phoneArr[1].filterValue;

        if (this.phoneArr[0].filterName == 'phone__startswith')
          this.prospectFilterModel.phone__startswith = this.phoneArr[1].filterValue;

        if (this.phoneArr[0].filterName == 'phone__endswith')
          this.prospectFilterModel.phone__endswith = this.phoneArr[1].filterValue;

        if (this.phoneArr[0].filterName == 'phone__isnull')
          this.prospectFilterModel.phone__isnull = this.phoneArr[1].filterValue;
      }
    }
    if (filterName == 'job_title') {
      if (this.job_titleArr.length > 0) {

        if (this.job_titleArr[0].filterName == 'job_title__icontains')
          this.prospectFilterModel.job_title__icontains = this.job_titleArr[1].filterValue;

        if (this.job_titleArr[0].filterName == 'job_title__startswith')
          this.prospectFilterModel.job_title__startswith = this.job_titleArr[1].filterValue;

        if (this.job_titleArr[0].filterName == 'job_title__endswith')
          this.prospectFilterModel.job_title__endswith = this.job_titleArr[1].filterValue;

        if (this.job_titleArr[0].filterName == 'job_title__isnull')
          this.prospectFilterModel.job_title__isnull = this.job_titleArr[1].filterValue;
      }
    }

    if (filterName == 'city') {
      if (this.cityArr.length > 0) {

        if (this.cityArr[0].filterName == 'city__icontains')
          this.prospectFilterModel.city__icontains = this.cityArr[1].filterValue;

        if (this.cityArr[0].filterName == 'city__startswith')
          this.prospectFilterModel.city__startswith = this.cityArr[1].filterValue;

        if (this.cityArr[0].filterName == 'city__endswith')
          this.prospectFilterModel.city__endswith = this.cityArr[1].filterValue;

        if (this.cityArr[0].filterName == 'city__isnull')
          this.prospectFilterModel.city__isnull = this.cityArr[1].filterValue;
      }
    }

    if (filterName == 'state') {
      if (this.stateArr.length > 0) {

        if (this.stateArr[0].filterName == 'state__icontains')
          this.prospectFilterModel.state__icontains = this.stateArr[1].filterValue;

        if (this.stateArr[0].filterName == 'state__startswith')
          this.prospectFilterModel.state__startswith = this.stateArr[1].filterValue;

        if (this.stateArr[0].filterName == 'state__endswith')
          this.prospectFilterModel.state__endswith = this.stateArr[1].filterValue;

        if (this.stateArr[0].filterName == 'state__isnull')
          this.prospectFilterModel.state__isnull = this.stateArr[1].filterValue;
      }
    }

    if (filterName == 'region') {
      if (this.regionArr.length > 0) {

        if (this.regionArr[0].filterName == 'region__icontains')
          this.prospectFilterModel.region__icontains = this.regionArr[1].filterValue;

        if (this.regionArr[0].filterName == 'region__startswith')
          this.prospectFilterModel.region__startswith = this.regionArr[1].filterValue;

        if (this.regionArr[0].filterName == 'region__endswith')
          this.prospectFilterModel.region__endswith = this.regionArr[1].filterValue;

        if (this.regionArr[0].filterName == 'region__isnull')
          this.prospectFilterModel.region__isnull = this.regionArr[1].filterValue;
      }
    }

    if (filterName == 'country') {
      if (this.countryArr.length > 0) {

        if (this.countryArr[0].filterName == 'country__icontains')
          this.prospectFilterModel.country__icontains = this.countryArr[1].filterValue;

        if (this.countryArr[0].filterName == 'country__startswith')
          this.prospectFilterModel.country__startswith = this.countryArr[1].filterValue;

        if (this.countryArr[0].filterName == 'country__endswith')
          this.prospectFilterModel.country__endswith = this.countryArr[1].filterValue;

        if (this.countryArr[0].filterName == 'country__isnull')
          this.prospectFilterModel.country__isnull = this.countryArr[1].filterValue;
      }
    }

    if (filterName == 'address') {
      if (this.addressArr.length > 0) {

        if (this.addressArr[0].filterName == 'address__icontains')
          this.prospectFilterModel.address__icontains = this.addressArr[1].filterValue;

        if (this.addressArr[0].filterName == 'address__startswith')
          this.prospectFilterModel.address__startswith = this.addressArr[1].filterValue;

        if (this.addressArr[0].filterName == 'address__endswith')
          this.prospectFilterModel.address__endswith = this.addressArr[1].filterValue;

        if (this.addressArr[0].filterName == 'address__isnull')
          this.prospectFilterModel.address__isnull = this.addressArr[1].filterValue;
      }
    }

    if (filterName == 'linkedin') {
      if (this.linkedinArr.length > 0) {

        if (this.linkedinArr[0].filterName == 'linkedin__icontains')
          this.prospectFilterModel.linkedin__icontains = this.linkedinArr[1].filterValue;

        if (this.linkedinArr[0].filterName == 'linkedin__startswith')
          this.prospectFilterModel.linkedin__startswith = this.linkedinArr[1].filterValue;

        if (this.linkedinArr[0].filterName == 'linkedin__endswith')
          this.prospectFilterModel.linkedin__endswith = this.linkedinArr[1].filterValue;

        if (this.linkedinArr[0].filterName == 'linkedin__isnull')
          this.prospectFilterModel.linkedin__isnull = this.linkedinArr[1].filterValue;
      }
    }

    if (filterName == 'snippet') {
      if (this.snippetArr.length > 0) {

        if (this.snippetArr[0].filterName == 'snippet__icontains')
          this.prospectFilterModel.snippet__icontains = this.snippetArr[1].filterValue;

      }
    }

    if (filterName == 'snippet1') {
      if (this.snippet1Arr.length > 0) {

        if (this.snippet1Arr[0].filterName == 'snippet1__icontains')
          this.prospectFilterModel.snippet1__icontains = this.snippet1Arr[1].filterValue;

      }
    }

    if (filterName == 'snippet2') {
      if (this.snippet2Arr.length > 0) {

        if (this.snippet2Arr[0].filterName == 'snippet2__icontains')
          this.prospectFilterModel.snippet2__icontains = this.snippet2Arr[1].filterValue;

      }
    }

    if (filterName == 'snippet3') {
      if (this.snippet3Arr.length > 0) {

        if (this.snippet3Arr[0].filterName == 'snippet3__icontains')
          this.prospectFilterModel.snippet3__icontains = this.snippet3Arr[1].filterValue;

      }
    }

    if (filterName == 'snippet4') {
      if (this.snippet4Arr.length > 0) {

        if (this.snippet4Arr[0].filterName == 'snippet4__icontains')
          this.prospectFilterModel.snippet4__icontains = this.snippet4Arr[1].filterValue;

      }
    }

    if (filterName == 'createdBy') {
      if (this.createdByArr.length > 0) {

        if (this.createdByArr[0].filterName == 'created_by__id')
          this.prospectFilterModel.created_by__id = this.createdByArr[1].filterValue;

      }
    }
    if (filterName == 'status') {
      if (this.statusIdArr.length > 0) {

        if (this.statusIdArr[0].filterName == 'status__id')
          this.prospectFilterModel.status__id = this.statusIdArr[1].filterValue;
        }
    }

    if (filterName == 'propectGroup') {
      if (this.prospectGroupArr.length > 0) {
        if (this.prospectGroupArr[0].filterName == 'prospect_group__name')
          this.prospectFilterModel.prospect_group__name = this.prospectGroupArr[1].filterValue;

      }
    }

    if (filterName == 'campaign') {
      if (this.campaignArr.length > 0) {

        if (this.campaignArr[0].filterName == 'campaign__eq')
          this.prospectFilterModel.campaign__eq = this.campaignArr[1].filterValue;

        if (this.campaignArr[0].filterName == 'campaign__neq')
          this.prospectFilterModel.campaign__neq = this.campaignArr[1].filterValue;
      }
    }
    if (filterName == 'optedout') {
      if (this.optOutArr.length > 0) {

        if (this.optOutArr[0].filterName == 'is_opted_out')
          this.prospectFilterModel.is_opted_out = this.optOutArr[1].filterValue;
      }
    }

    if (filterName == 'bounced') {
      if (this.bouncedArr.length > 0) {

        if (this.bouncedArr[0].filterName == 'is_bounced')
          this.prospectFilterModel.is_bounced = this.bouncedArr[1].filterValue;
      }
    }

    this.CallFinalFilter();
  }

  RemoveFilter(filterName) {
    if (filterName == 'scores') {
      this.prospectFilterModel.score__lt = null;
      this.prospectFilterModel.score__gt = null;
    }

    if (filterName == 'lastContacted') {
      this.prospectFilterModel.last_contacted_period__gt = null;
      this.prospectFilterModel.last_contacted_period__lt = null;
      this.prospectFilterModel.last_contacted_period__eq = null;
    }

    if (filterName == 'msgReplies') {
      this.prospectFilterModel.cnt_replies_received = null;
      this.prospectFilterModel.cnt_replies_received__gt = null;
      this.prospectFilterModel.cnt_replies_received__lt = null;
      this.replyFilterArr = []
    }
    if (filterName == 'msgClicks') {
      this.prospectFilterModel.cnt_email_open = null;
      this.prospectFilterModel.cnt_email_open__gt = null;
      this.prospectFilterModel.cnt_email_open__lt = null;
    }
    if (filterName == 'msgSent') {
      this.prospectFilterModel.cnt_email_sent = null;
      this.prospectFilterModel.cnt_email_sent__gt = null;
      this.prospectFilterModel.cnt_email_sent__lt = null;
    }
    if (filterName == 'email') {
      this.prospectFilterModel.email_address = null;
      this.prospectFilterModel.email_address__icontains = null;
      this.prospectFilterModel.email_address__startswith = null;
      this.prospectFilterModel.email_address__endswith = null;
    }
    if (filterName == 'firstname') {
      this.prospectFilterModel.firstname__icontains = null;
      this.prospectFilterModel.firstname__startswith = null;
      this.prospectFilterModel.firstname__endswith = null;
    }
    if (filterName == 'lastname') {
      this.prospectFilterModel.lastname__icontains = null;
      this.prospectFilterModel.lastname__startswith = null;
      this.prospectFilterModel.lastname__endswith = null;
    }
    if (filterName == 'company') {
      this.prospectFilterModel.company__icontains = null;
      this.prospectFilterModel.company__startswith = null;
      this.prospectFilterModel.company__endswith = null;
      this.prospectFilterModel.company__isnull = null
    }
    if (filterName == 'industry') {
      this.prospectFilterModel.industry__icontains = null;
      this.prospectFilterModel.industry__startswith = null;
      this.prospectFilterModel.industry__endswith = null;
      this.prospectFilterModel.industry__isnull = null
    }

    if (filterName == 'phone') {
      this.prospectFilterModel.phone__icontains = null;
      this.prospectFilterModel.phone__startswith = null;
      this.prospectFilterModel.phone__endswith = null;
      this.prospectFilterModel.phone__isnull = null
    }

    if (filterName == 'job_title') {
      this.prospectFilterModel.job_title__icontains = null;
      this.prospectFilterModel.job_title__startswith = null;
      this.prospectFilterModel.job_title__endswith = null;
      this.prospectFilterModel.job_title__isnull = null
    }

    if (filterName == 'city') {
      this.prospectFilterModel.city__icontains = null;
      this.prospectFilterModel.city__startswith = null;
      this.prospectFilterModel.city__endswith = null;
      this.prospectFilterModel.city__isnull = null
    }

    if (filterName == 'state') {
      this.prospectFilterModel.state__icontains = null;
      this.prospectFilterModel.state__startswith = null;
      this.prospectFilterModel.state__endswith = null;
      this.prospectFilterModel.state__isnull = null
    }

    if (filterName == 'region') {
      this.prospectFilterModel.region__icontains = null;
      this.prospectFilterModel.region__startswith = null;
      this.prospectFilterModel.region__endswith = null;
      this.prospectFilterModel.region__isnull = null
    }

    if (filterName == 'country') {
      this.prospectFilterModel.country__icontains = null;
      this.prospectFilterModel.country__startswith = null;
      this.prospectFilterModel.country__endswith = null;
      this.prospectFilterModel.country__isnull = null
    }
    if (filterName == 'address') {
      this.prospectFilterModel.address__icontains = null;
      this.prospectFilterModel.address__startswith = null;
      this.prospectFilterModel.address__endswith = null;
      this.prospectFilterModel.address__isnull = null
    }

    if (filterName == 'linkedin') {
      this.prospectFilterModel.linkedin__icontains = null;
      this.prospectFilterModel.linkedin__startswith = null;
      this.prospectFilterModel.linkedin__endswith = null;
      this.prospectFilterModel.linkedin__isnull = null
    }

    if (filterName == 'snippet') {
      this.prospectFilterModel.snippet__icontains = null;
    }

    if (filterName == 'snippet1') {
      this.prospectFilterModel.snippet1__icontains = null;
    }

    if (filterName == 'snippet2') {
      this.prospectFilterModel.snippet2__icontains = null;
    }

    if (filterName == 'snippet3') {
      this.prospectFilterModel.snippet3__icontains = null;
    }

    if (filterName == 'snippet4') {
      this.prospectFilterModel.snippet4__icontains = null;
    }

    if (filterName == 'createdBy') {
      this.prospectFilterModel.created_by__id = null;
    }
    if (filterName == 'status') {
      this.prospectFilterModel.status__id = null;
      this.statusId = null
    }

    if (filterName == 'propectGroup') {
      this.prospectFilterModel.prospect_group__name = null;
    }

    if (filterName == 'campaign') {
      this.prospectFilterModel.campaign__eq = null;
      this.prospectFilterModel.campaign__neq = null;
    }
    if (filterName == 'optedout') {
      this.prospectFilterModel.is_opted_out = null;
      this.optOutArr = []
    }
    if (filterName == 'bounced') {
      this.prospectFilterModel.is_bounced = null;
      this.bouncedArr = []
    }

    if (filterName == 'prospectInterest') {
      this.prospectFilterModel.interested_category = null
    }
    if (filterName == 'autoreply') {
        this.autoReplyStatus = false;
        this.prospectFilterModel.is_auto_reply = null
    }


    this.CallFinalFilter();
  }


  autoReplyState(e){
    if(this.prospectFilterModel.is_auto_reply && e == 'yes'){
      return true
    } else if(this.prospectFilterModel.is_auto_reply != null && !this.prospectFilterModel.is_auto_reply  && e == 'no'){
      return true
    }

  }

  msgReplyState(e){
    if(this.prospectFilterModel.cnt_replies_received__gt == 0 && e == 'yes' ){
      return true
    }else if( this.prospectFilterModel.cnt_replies_received == 0 && e == 'no'  ){
      return true
    }

  }

  bouncedState(e){
    if(this.prospectFilterModel.is_bounced  && e == 'yes' ){
      return true
    }else if( this.prospectFilterModel.is_bounced != null && !this.prospectFilterModel.is_bounced  && e == 'no' ){
      return true
    }

  }

  optOutState(e){
    if(this.prospectFilterModel.is_opted_out  && e == 'yes' ){
      return true
    }else if( this.prospectFilterModel.is_opted_out != null && !this.prospectFilterModel.is_opted_out  && e == 'no' ){
      return true
    }

  }


  onCreatedBySelected() {
    this.createdByArr = [];
    this.prospectFilterModel.created_by__id = this.createdById;
    this.createdByArr.push({ 'filterName': 'created_by__id' }, { 'filterValue': this.createdById });
    this.CallFinalFilter();
  }

  onStatusSelected(input) {

    this.statusId = input.id;
    this.statusIdArr = [];
    this.prospectFilterModel.status__id = this.statusId;
    this.statusIdArr.push({ 'filterName': 'status__id' }, { 'filterValue': this.statusId });
    this.CallFinalFilter();
  }
  onoptedoutSelected(input, optedRadio) {

    if (input == 'yes'   )
      this.optOutStatus = true;
    if (input == 'no'   )
      this.optOutStatus = false;

    this.optOutArr = [];
    this.prospectFilterModel.is_opted_out = this.optOutStatus;
    this.optOutArr.push({ 'filterName': 'is_opted_out' }, { 'filterValue': this.optOutStatus }, { 'radioObj': optedRadio });
    this.CallFinalFilter();
  }


  autoReplySelected(input, optedRadio) {
    if (input == 'yes'  )
      this.autoReplyStatus = true;
    if (input == 'no'  )
      this.autoReplyStatus = false;

    this.optOutArr = [];
    this.prospectFilterModel.is_auto_reply = this.autoReplyStatus;
    this.optOutArr.push({ 'filterName': 'is_auto_reply' }, { 'filterValue': this.autoReplyStatus }, { 'radioObj': optedRadio });
    this.CallFinalFilter();
  }



  onProspectInterestSelected(input, optedRadio) {
    this.prospectInterestStatus = input;
    this.prospectInterestArr = [];
    this.prospectFilterModel.interested_category = this.prospectInterestStatus;
    this.optOutArr.push({ 'filterName': 'interested_category' }, { 'filterValue': this.prospectInterestStatus }, { 'radioObj': optedRadio });
    this.CallFinalFilter();
  }




  onbouncedSelected(input, optedRadio) {
    if (input == 'yes' )
      this.bouncedStatus = true;
    if (input == 'no' )
      this.bouncedStatus = false;

    this.bouncedArr = [];
    this.prospectFilterModel.is_bounced = this.bouncedStatus;
    this.bouncedArr.push({ 'filterName': 'is_bounced' }, { 'filterValue': this.bouncedStatus }, { 'radioObj': optedRadio });
    this.CallFinalFilter();
  }

  onProspectGroupSelected() {
    this.prospectGroupArr = [];
    this.prospectFilterModel.prospect_group__name = this.prospectgroupId;
    this.prospectGroupArr.push({ 'filterName': 'prospect_group__name' }, { 'filterValue': this.prospectgroupId });
    this.CallFinalFilter(); 
  }

  CallFinalFilter() { 
    this.pc.callFilter(this.prospectFilterModel);
    for(const key in this.prospectFilterModel){
      if( this.prospectFilterModel[key] == null || key == 'email_address__icontains'){
        delete this.prospectFilterModel[key]
      }
    }
    localStorage.setItem('lastUsedProspectFilter',JSON.stringify(this.prospectFilterModel))
  }


  activeEmail = null;
  toggleEmailFilter(obj = null, actionSelected, optedRadio) {

    if (actionSelected == 'emailContains'  ) {
      this.prospectFilterModel.email_address = null;
      this.prospectFilterModel.email_address__icontains = this.emailContainsText;
      this.prospectFilterModel.email_address__startswith = null;
      this.prospectFilterModel.email_address__endswith = null;
      this.emailArr = [];
      this.emailArr.push({ 'filterName': 'email_address__icontains' }, { 'filterValue': this.emailContainsText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'emailStartsWith'  ) {
      this.prospectFilterModel.email_address = null;
      this.prospectFilterModel.email_address__icontains = null;
      this.prospectFilterModel.email_address__startswith = this.emailStartsWithText;
      this.prospectFilterModel.email_address__endswith = null;
      this.emailArr = [];
      this.emailArr.push({ 'filterName': 'email_address__startswith' }, { 'filterValue': this.emailStartsWithText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'emailEndsWith'  ) {
      this.prospectFilterModel.email_address = null;
      this.prospectFilterModel.email_address__icontains = null;
      this.prospectFilterModel.email_address__startswith = null;
      this.prospectFilterModel.email_address__endswith = this.emailEndsWithText;
      this.emailArr = [];
      this.emailArr.push({ 'filterName': 'email_address__endswith' }, { 'filterValue': this.emailEndsWithText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'emailIs'  ) {
      this.prospectFilterModel.email_address = this.emailIsText;
      this.prospectFilterModel.email_address__icontains = null;
      this.prospectFilterModel.email_address__startswith = null;
      this.prospectFilterModel.email_address__endswith = null;
      this.emailArr = [];
      this.emailArr.push({ 'filterName': 'email_address' }, { 'filterValue': this.emailIsText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    this.CallFinalFilter();

    if (obj == this.activeEmail)
      return;
    if (this.activeEmail) {
      this.activeEmail.toggle();
    }

    if (obj) {
      obj.toggle();
    }

    this.activeEmail = obj;
  }

  activefirstname = null;
  togglefirstnameFilter(obj = null, actionSelected, optedRadio) {

    if (actionSelected == 'firstnameContains' ) {
      this.prospectFilterModel.firstname__icontains = this.firstnameContainsText;
      this.prospectFilterModel.firstname__startswith = null;
      this.prospectFilterModel.firstname__endswith = null;
      this.firstnameArr = [];
      this.firstnameArr.push({ 'filterName': 'firstname__icontains' }, { 'filterValue': this.firstnameContainsText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'firstnameStartsWith' ) {
      this.prospectFilterModel.firstname__icontains = null;
      this.prospectFilterModel.firstname__startswith = this.firstnameStartsWithText;
      this.prospectFilterModel.firstname__endswith = null;
      this.firstnameArr = [];
      this.firstnameArr.push({ 'filterName': 'firstname__startswith' }, { 'filterValue': this.firstnameStartsWithText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'firstnameEndsWith' ) {
      this.prospectFilterModel.firstname__icontains = null;
      this.prospectFilterModel.firstname__startswith = null;
      this.prospectFilterModel.firstname__endswith = this.firstnameEndsWithText;
      this.firstnameArr = [];
      this.firstnameArr.push({ 'filterName': 'firstname__endswith' }, { 'filterValue': this.firstnameEndsWithText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    this.CallFinalFilter();

    if (obj == this.activefirstname)
      return;
    if (this.activefirstname) {
      this.activefirstname.toggle();
    }

    if (obj) {
      obj.toggle();
    }

    this.activefirstname = obj;
  }

  activelastname = null;
  togglelastnameFilter(obj = null, actionSelected, optedRadio) {

    if (actionSelected == 'lastnameContains' ) {
      this.prospectFilterModel.lastname__icontains = this.lastnameContainsText;
      this.prospectFilterModel.lastname__startswith = null;
      this.prospectFilterModel.lastname__endswith = null;
      this.lastnameArr = [];
      this.lastnameArr.push({ 'filterName': 'lastname__icontains' }, { 'filterValue': this.lastnameContainsText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'lastnameStartsWith' ) {
      this.prospectFilterModel.lastname__icontains = null;
      this.prospectFilterModel.lastname__startswith = this.lastnameStartsWithText;
      this.prospectFilterModel.lastname__endswith = null;
      this.lastnameArr = [];
      this.lastnameArr.push({ 'filterName': 'lastname__startswith' }, { 'filterValue': this.lastnameStartsWithText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'lastnameEndsWith' ) {
      this.prospectFilterModel.lastname__icontains = null;
      this.prospectFilterModel.lastname__startswith = null;
      this.prospectFilterModel.lastname__endswith = this.lastnameEndsWithText;
      this.lastnameArr = [];
      this.lastnameArr.push({ 'filterName': 'lastname__endswith' }, { 'filterValue': this.lastnameEndsWithText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    this.CallFinalFilter();

    if (obj == this.activelastname)
      return;
    if (this.activelastname) {
      this.activelastname.toggle();
    }

    if (obj) {
      obj.toggle();
    }

    this.activelastname = obj;
  }

  activecompany = null;
  togglecompanyFilter(obj = null, actionSelected, optedRadio) {

    if (actionSelected == 'companyContains' ) {
      this.prospectFilterModel.company__icontains = this.companyContainsText;
      this.prospectFilterModel.company__startswith = null;
      this.prospectFilterModel.company__endswith = null;
      this.prospectFilterModel.company__isnull = null;
      this.companyArr = [];
      this.companyArr.push({ 'filterName': 'company__icontains' }, { 'filterValue': this.companyContainsText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'companyStartsWith' ) {
      this.prospectFilterModel.company__icontains = null;
      this.prospectFilterModel.company__startswith = this.companyStartsWithText;
      this.prospectFilterModel.company__endswith = null;
      this.prospectFilterModel.company__isnull = null;
      this.companyArr = [];
      this.companyArr.push({ 'filterName': 'company__startswith' }, { 'filterValue': this.companyStartsWithText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'companyEndsWith' ) {
      this.prospectFilterModel.company__icontains = null;
      this.prospectFilterModel.company__startswith = null;
      this.prospectFilterModel.company__endswith = this.companyEndsWithText;
      this.prospectFilterModel.company__isnull = null;
      this.companyArr = [];
      this.companyArr.push({ 'filterName': 'company__endswith' }, { 'filterValue': this.companyEndsWithText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'companyEmpty' ) {
      this.prospectFilterModel.company__icontains = null;
      this.prospectFilterModel.company__startswith = null;
      this.prospectFilterModel.company__endswith = null;
      this.prospectFilterModel.company__isnull = true;
      this.companyArr = [];
      this.companyArr.push({ 'filterName': 'company__isnull' }, { 'filterValue': true }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'companyNotEmpty' ) {
      this.prospectFilterModel.company__icontains = null;
      this.prospectFilterModel.company__startswith = null;
      this.prospectFilterModel.company__endswith = null;
      this.prospectFilterModel.company__isnull = false;
      this.companyArr = [];
      this.companyArr.push({ 'filterName': 'company__isnull' }, { 'filterValue': false }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    this.CallFinalFilter();

    if (obj == this.activecompany)
      return;
    if (this.activecompany) {
      this.activecompany.toggle();
    }

    if (obj) {
      obj.toggle();
    }

    this.activecompany = obj;
  }

  activeindustry = null;
  toggleindustryFilter(obj = null, actionSelected, optedRadio) {

    if (actionSelected == 'industryContains' ) {
      this.prospectFilterModel.industry__icontains = this.industryContainsText;
      this.prospectFilterModel.industry__startswith = null;
      this.prospectFilterModel.industry__endswith = null;
      this.prospectFilterModel.industry__isnull = null;
      this.industryArr = [];
      this.industryArr.push({ 'filterName': 'industry__icontains' }, { 'filterValue': this.industryContainsText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'industryStartsWith' ) {
      this.prospectFilterModel.industry__icontains = null;
      this.prospectFilterModel.industry__startswith = this.industryStartsWithText;
      this.prospectFilterModel.industry__endswith = null;
      this.prospectFilterModel.industry__isnull = null;
      this.industryArr = [];
      this.industryArr.push({ 'filterName': 'industry__startswith' }, { 'filterValue': this.industryStartsWithText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'industryEndsWith' ) {
      this.prospectFilterModel.industry__icontains = null;
      this.prospectFilterModel.industry__startswith = null;
      this.prospectFilterModel.industry__endswith = this.industryEndsWithText;
      this.prospectFilterModel.industry__isnull = null;
      this.industryArr = [];
      this.industryArr.push({ 'filterName': 'industry__endswith' }, { 'filterValue': this.industryEndsWithText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'industryEmpty' ) {
      this.prospectFilterModel.industry__icontains = null;
      this.prospectFilterModel.industry__startswith = null;
      this.prospectFilterModel.industry__endswith = null;
      this.prospectFilterModel.industry__isnull = true;
      this.industryArr = [];
      this.industryArr.push({ 'filterName': 'industry__isnull' }, { 'filterValue': true }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'industryNotEmpty' ) {
      this.prospectFilterModel.industry__icontains = null;
      this.prospectFilterModel.industry__startswith = null;
      this.prospectFilterModel.industry__endswith = null;
      this.prospectFilterModel.industry__isnull = false;
      this.industryArr = [];
      this.industryArr.push({ 'filterName': 'industry__isnull' }, { 'filterValue': false }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    this.CallFinalFilter();

    if (obj == this.activeindustry)
      return;
    if (this.activeindustry) {
      this.activeindustry.toggle();
    }

    if (obj) {
      obj.toggle();
    }

    this.activeindustry = obj;
  }

  activephone = null;
  togglephoneFilter(obj = null, actionSelected, optedRadio) {

    if (actionSelected == 'phoneContains' ) {
      this.prospectFilterModel.phone__icontains = this.phoneContainsText;
      this.prospectFilterModel.phone__startswith = null;
      this.prospectFilterModel.phone__endswith = null;
      this.prospectFilterModel.phone__isnull = null;
      this.phoneArr = [];
      this.phoneArr.push({ 'filterName': 'phone__icontains' }, { 'filterValue': this.phoneContainsText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'phoneStartsWith' ) {
      this.prospectFilterModel.phone__icontains = null;
      this.prospectFilterModel.phone__startswith = this.phoneStartsWithText;
      this.prospectFilterModel.phone__endswith = null;
      this.prospectFilterModel.phone__isnull = null;
      this.phoneArr = [];
      this.phoneArr.push({ 'filterName': 'phone__startswith' }, { 'filterValue': this.phoneStartsWithText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'phoneEndsWith' ) {
      this.prospectFilterModel.phone__icontains = null;
      this.prospectFilterModel.phone__startswith = null;
      this.prospectFilterModel.phone__endswith = this.phoneEndsWithText;
      this.prospectFilterModel.phone__isnull = null;
      this.phoneArr = [];
      this.phoneArr.push({ 'filterName': 'phone__endswith' }, { 'filterValue': this.phoneEndsWithText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'phoneEmpty' ) {
      this.prospectFilterModel.phone__icontains = null;
      this.prospectFilterModel.phone__startswith = null;
      this.prospectFilterModel.phone__endswith = null;
      this.prospectFilterModel.phone__isnull = true;
      this.phoneArr = [];
      this.phoneArr.push({ 'filterName': 'phone__isnull' }, { 'filterValue': true }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'phoneNotEmpty' ) {
      this.prospectFilterModel.phone__icontains = null;
      this.prospectFilterModel.phone__startswith = null;
      this.prospectFilterModel.phone__endswith = null;
      this.prospectFilterModel.phone__isnull = false;
      this.phoneArr = [];
      this.phoneArr.push({ 'filterName': 'phone__isnull' }, { 'filterValue': false }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    this.CallFinalFilter();

    if (obj == this.activephone)
      return;
    if (this.activephone) {
      this.activephone.toggle();
    }

    if (obj) {
      obj.toggle();
    }

    this.activephone = obj;
  }

  activejob_title = null;
  togglejob_titleFilter(obj = null, actionSelected, optedRadio) {

    if (actionSelected == 'job_titleContains' ) {
      this.prospectFilterModel.job_title__icontains = this.job_titleContainsText;
      this.prospectFilterModel.job_title__startswith = null;
      this.prospectFilterModel.job_title__endswith = null;
      this.prospectFilterModel.job_title__isnull = null;
      this.job_titleArr = [];
      this.job_titleArr.push({ 'filterName': 'job_title__icontains' }, { 'filterValue': this.job_titleContainsText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'job_titleStartsWith' ) {
      this.prospectFilterModel.job_title__icontains = null;
      this.prospectFilterModel.job_title__startswith = this.job_titleStartsWithText;
      this.prospectFilterModel.job_title__endswith = null;
      this.prospectFilterModel.job_title__isnull = null;
      this.job_titleArr = [];
      this.job_titleArr.push({ 'filterName': 'job_title__startswith' }, { 'filterValue': this.job_titleStartsWithText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'job_titleEndsWith' ) {
      this.prospectFilterModel.job_title__icontains = null;
      this.prospectFilterModel.job_title__startswith = null;
      this.prospectFilterModel.job_title__endswith = this.job_titleEndsWithText;
      this.prospectFilterModel.job_title__isnull = null;
      this.job_titleArr = [];
      this.job_titleArr.push({ 'filterName': 'job_title__endswith' }, { 'filterValue': this.job_titleEndsWithText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'job_titleEmpty' ) {
      this.prospectFilterModel.job_title__icontains = null;
      this.prospectFilterModel.job_title__startswith = null;
      this.prospectFilterModel.job_title__endswith = null;
      this.prospectFilterModel.job_title__isnull = true;
      this.job_titleArr = [];
      this.job_titleArr.push({ 'filterName': 'job_title__isnull' }, { 'filterValue': true }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'job_titleNotEmpty' ) {
      this.prospectFilterModel.job_title__icontains = null;
      this.prospectFilterModel.job_title__startswith = null;
      this.prospectFilterModel.job_title__endswith = null;
      this.prospectFilterModel.job_title__isnull = false;
      this.job_titleArr = [];
      this.job_titleArr.push({ 'filterName': 'job_title__isnull' }, { 'filterValue': false }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    this.CallFinalFilter();

    if (obj == this.activejob_title)
      return;
    if (this.activejob_title) {
      this.activejob_title.toggle();
    }

    if (obj) {
      obj.toggle();
    }

    this.activejob_title = obj;
  }

  activecity = null;
  togglecityFilter(obj = null, actionSelected, optedRadio) {

    if (actionSelected == 'cityContains' ) {
      this.prospectFilterModel.city__icontains = this.cityContainsText;
      this.prospectFilterModel.city__startswith = null;
      this.prospectFilterModel.city__endswith = null;
      this.prospectFilterModel.city__isnull = null;
      this.cityArr = [];
      this.cityArr.push({ 'filterName': 'city__icontains' }, { 'filterValue': this.cityContainsText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'cityStartsWith' ) {
      this.prospectFilterModel.city__icontains = null;
      this.prospectFilterModel.city__startswith = this.cityStartsWithText;
      this.prospectFilterModel.city__endswith = null;
      this.prospectFilterModel.city__isnull = null;
      this.cityArr = [];
      this.cityArr.push({ 'filterName': 'city__startswith' }, { 'filterValue': this.cityStartsWithText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'cityEndsWith' ) {
      this.prospectFilterModel.city__icontains = null;
      this.prospectFilterModel.city__startswith = null;
      this.prospectFilterModel.city__endswith = this.cityEndsWithText;
      this.prospectFilterModel.city__isnull = null;
      this.cityArr = [];
      this.cityArr.push({ 'filterName': 'city__endswith' }, { 'filterValue': this.cityEndsWithText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'cityEmpty' ) {
      this.prospectFilterModel.city__icontains = null;
      this.prospectFilterModel.city__startswith = null;
      this.prospectFilterModel.city__endswith = null;
      this.prospectFilterModel.city__isnull = true;
      this.cityArr = [];
      this.cityArr.push({ 'filterName': 'city__isnull' }, { 'filterValue': true }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'cityNotEmpty' ) {
      this.prospectFilterModel.city__icontains = null;
      this.prospectFilterModel.city__startswith = null;
      this.prospectFilterModel.city__endswith = null;
      this.prospectFilterModel.city__isnull = false;
      this.cityArr = [];
      this.cityArr.push({ 'filterName': 'city__isnull' }, { 'filterValue': false }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    this.CallFinalFilter();

    if (obj == this.activecity)
      return;
    if (this.activecity) {
      this.activecity.toggle();
    }

    if (obj) {
      obj.toggle();
    }

    this.activecity = obj;
  }

  activestate = null;
  togglestateFilter(obj = null, actionSelected, optedRadio) {

    if (actionSelected == 'stateContains' ) {
      this.prospectFilterModel.state__icontains = this.stateContainsText;
      this.prospectFilterModel.state__startswith = null;
      this.prospectFilterModel.state__endswith = null;
      this.prospectFilterModel.state__isnull = null;
      this.stateArr = [];
      this.stateArr.push({ 'filterName': 'state__icontains' }, { 'filterValue': this.stateContainsText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'stateStartsWith' ) {
      this.prospectFilterModel.state__icontains = null;
      this.prospectFilterModel.state__startswith = this.stateStartsWithText;
      this.prospectFilterModel.state__endswith = null;
      this.prospectFilterModel.state__isnull = null;
      this.stateArr = [];
      this.stateArr.push({ 'filterName': 'state__startswith' }, { 'filterValue': this.stateStartsWithText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'stateEndsWith' ) {
      this.prospectFilterModel.state__icontains = null;
      this.prospectFilterModel.state__startswith = null;
      this.prospectFilterModel.state__endswith = this.stateEndsWithText;
      this.prospectFilterModel.state__isnull = null;
      this.stateArr = [];
      this.stateArr.push({ 'filterName': 'state__endswith' }, { 'filterValue': this.stateEndsWithText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'stateEmpty'  ) {
      this.prospectFilterModel.state__icontains = null;
      this.prospectFilterModel.state__startswith = null;
      this.prospectFilterModel.state__endswith = null;
      this.prospectFilterModel.state__isnull = true;
      this.stateArr = [];
      this.stateArr.push({ 'filterName': 'state__isnull' }, { 'filterValue': true }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'stateNotEmpty' ) {
      this.prospectFilterModel.state__icontains = null;
      this.prospectFilterModel.state__startswith = null;
      this.prospectFilterModel.state__endswith = null;
      this.prospectFilterModel.state__isnull = false;
      this.stateArr = [];
      this.stateArr.push({ 'filterName': 'state__isnull' }, { 'filterValue': false }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    this.CallFinalFilter();

    if (obj == this.activestate)
      return;
    if (this.activestate) {
      this.activestate.toggle();
    }

    if (obj) {
      obj.toggle();
    }

    this.activestate = obj;
  }


  activeregion = null;
  toggleregionFilter(obj = null, actionSelected, optedRadio) {

    if (actionSelected == 'regionContains' ) {
      this.prospectFilterModel.region__icontains = this.regionContainsText;
      this.prospectFilterModel.region__startswith = null;
      this.prospectFilterModel.region__endswith = null;
      this.prospectFilterModel.region__isnull = null;
      this.regionArr = [];
      this.regionArr.push({ 'filterName': 'region__icontains' }, { 'filterValue': this.regionContainsText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'regionStartsWith' ) {
      this.prospectFilterModel.region__icontains = null;
      this.prospectFilterModel.region__startswith = this.regionStartsWithText;
      this.prospectFilterModel.region__endswith = null;
      this.prospectFilterModel.region__isnull = null;
      this.regionArr = [];
      this.regionArr.push({ 'filterName': 'region__startswith' }, { 'filterValue': this.regionStartsWithText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'regionEndsWith'  ) {
      this.prospectFilterModel.region__icontains = null;
      this.prospectFilterModel.region__startswith = null;
      this.prospectFilterModel.region__endswith = this.regionEndsWithText;
      this.prospectFilterModel.region__isnull = null;
      this.regionArr = [];
      this.regionArr.push({ 'filterName': 'region__endswith' }, { 'filterValue': this.regionEndsWithText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'regionEmpty' ) {
      this.prospectFilterModel.region__icontains = null;
      this.prospectFilterModel.region__startswith = null;
      this.prospectFilterModel.region__endswith = null;
      this.prospectFilterModel.region__isnull = true;
      this.regionArr = [];
      this.regionArr.push({ 'filterName': 'region__isnull' }, { 'filterValue': true }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'regionNotEmpty' ) {
      this.prospectFilterModel.region__icontains = null;
      this.prospectFilterModel.region__startswith = null;
      this.prospectFilterModel.region__endswith = null;
      this.prospectFilterModel.region__isnull = false;
      this.regionArr = [];
      this.regionArr.push({ 'filterName': 'region__isnull' }, { 'filterValue': false }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    this.CallFinalFilter();

    if (obj == this.activeregion)
      return;
    if (this.activeregion) {
      this.activeregion.toggle();
    }

    if (obj) {
      obj.toggle();
    }

    this.activeregion = obj;
  }


  activecountry = null;
  togglecountryFilter(obj = null, actionSelected, optedRadio) {

    if (actionSelected == 'countryContains' ) {
      this.prospectFilterModel.country__icontains = this.countryContainsText;
      this.prospectFilterModel.country__startswith = null;
      this.prospectFilterModel.country__endswith = null;
      this.prospectFilterModel.country__isnull = null;
      this.countryArr = [];
      this.countryArr.push({ 'filterName': 'country__icontains' }, { 'filterValue': this.countryContainsText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'countryStartsWith' ) {
      this.prospectFilterModel.country__icontains = null;
      this.prospectFilterModel.country__startswith = this.countryStartsWithText;
      this.prospectFilterModel.country__endswith = null;
      this.prospectFilterModel.country__isnull = null;
      this.countryArr = [];
      this.countryArr.push({ 'filterName': 'country__startswith' }, { 'filterValue': this.countryStartsWithText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'countryEndsWith' ) {
      this.prospectFilterModel.country__icontains = null;
      this.prospectFilterModel.country__startswith = null;
      this.prospectFilterModel.country__endswith = this.countryEndsWithText;
      this.prospectFilterModel.country__isnull = null;
      this.countryArr = [];
      this.countryArr.push({ 'filterName': 'country__endswith' }, { 'filterValue': this.countryEndsWithText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'countryEmpty' ) {
      this.prospectFilterModel.country__icontains = null;
      this.prospectFilterModel.country__startswith = null;
      this.prospectFilterModel.country__endswith = null;
      this.prospectFilterModel.country__isnull = true;
      this.countryArr = [];
      this.countryArr.push({ 'filterName': 'country__isnull' }, { 'filterValue': true }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'countryNotEmpty' ) {
      this.prospectFilterModel.country__icontains = null;
      this.prospectFilterModel.country__startswith = null;
      this.prospectFilterModel.country__endswith = null;
      this.prospectFilterModel.country__isnull = false;
      this.countryArr = [];
      this.countryArr.push({ 'filterName': 'country__isnull' }, { 'filterValue': false }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    this.CallFinalFilter();

    if (obj == this.activecountry)
      return;
    if (this.activecountry) {
      this.activecountry.toggle();
    }

    if (obj) {
      obj.toggle();
    }

    this.activecountry = obj;
  }

  activeaddress = null;
  toggleaddressFilter(obj = null, actionSelected, optedRadio) {

    if (actionSelected == 'addressContains' ) {
      this.prospectFilterModel.address__icontains = this.addressContainsText;
      this.prospectFilterModel.address__startswith = null;
      this.prospectFilterModel.address__endswith = null;
      this.prospectFilterModel.address__isnull = null;
      this.addressArr = [];
      this.addressArr.push({ 'filterName': 'address__icontains' }, { 'filterValue': this.addressContainsText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'addressStartsWith' ) {
      this.prospectFilterModel.address__icontains = null;
      this.prospectFilterModel.address__startswith = this.addressStartsWithText;
      this.prospectFilterModel.address__endswith = null;
      this.prospectFilterModel.address__isnull = null;
      this.addressArr = [];
      this.addressArr.push({ 'filterName': 'address__startswith' }, { 'filterValue': this.addressStartsWithText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'addressEndsWith' ) {
      this.prospectFilterModel.address__icontains = null;
      this.prospectFilterModel.address__startswith = null;
      this.prospectFilterModel.address__endswith = this.addressEndsWithText;
      this.prospectFilterModel.address__isnull = null;
      this.addressArr = [];
      this.addressArr.push({ 'filterName': 'address__endswith' }, { 'filterValue': this.addressEndsWithText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'addressEmpty' ) {
      this.prospectFilterModel.address__icontains = null;
      this.prospectFilterModel.address__startswith = null;
      this.prospectFilterModel.address__endswith = null;
      this.prospectFilterModel.address__isnull = true;
      this.addressArr = [];
      this.addressArr.push({ 'filterName': 'address__isnull' }, { 'filterValue': true }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'addressNotEmpty' ) {
      this.prospectFilterModel.address__icontains = null;
      this.prospectFilterModel.address__startswith = null;
      this.prospectFilterModel.address__endswith = null;
      this.prospectFilterModel.address__isnull = false;
      this.addressArr = [];
      this.addressArr.push({ 'filterName': 'address__isnull' }, { 'filterValue': false }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    this.CallFinalFilter();

    if (obj == this.activeaddress)
      return;
    if (this.activeaddress) {
      this.activeaddress.toggle();
    }

    if (obj) {
      obj.toggle();
    }

    this.activeaddress = obj;
  }

  activelinkedin = null;
  togglelinkedinFilter(obj = null, actionSelected, optedRadio) {

    if (actionSelected == 'linkedinContains' ) {
      this.prospectFilterModel.linkedin__icontains = this.linkedinContainsText;
      this.prospectFilterModel.linkedin__startswith = null;
      this.prospectFilterModel.linkedin__endswith = null;
      this.prospectFilterModel.linkedin__isnull = null;
      this.linkedinArr = [];
      this.linkedinArr.push({ 'filterName': 'linkedin__icontains' }, { 'filterValue': this.linkedinContainsText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'linkedinStartsWith' ) {
      this.prospectFilterModel.linkedin__icontains = null;
      this.prospectFilterModel.linkedin__startswith = this.linkedinStartsWithText;
      this.prospectFilterModel.linkedin__endswith = null;
      this.prospectFilterModel.linkedin__isnull = null;
      this.linkedinArr = [];
      this.linkedinArr.push({ 'filterName': 'linkedin__startswith' }, { 'filterValue': this.linkedinStartsWithText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'linkedinEndsWith'  ) {
      this.prospectFilterModel.linkedin__icontains = null;
      this.prospectFilterModel.linkedin__startswith = null;
      this.prospectFilterModel.linkedin__endswith = this.linkedinEndsWithText;
      this.prospectFilterModel.linkedin__isnull = null;
      this.linkedinArr = [];
      this.linkedinArr.push({ 'filterName': 'linkedin__endswith' }, { 'filterValue': this.linkedinEndsWithText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'linkedinEmpty' ) {
      this.prospectFilterModel.linkedin__icontains = null;
      this.prospectFilterModel.linkedin__startswith = null;
      this.prospectFilterModel.linkedin__endswith = null;
      this.prospectFilterModel.linkedin__isnull = true;
      this.linkedinArr = [];
      this.linkedinArr.push({ 'filterName': 'linkedin__isnull' }, { 'filterValue': true }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    if (actionSelected == 'linkedinNotEmpty' ) {
      this.prospectFilterModel.linkedin__icontains = null;
      this.prospectFilterModel.linkedin__startswith = null;
      this.prospectFilterModel.linkedin__endswith = null;
      this.prospectFilterModel.linkedin__isnull = false;
      this.linkedinArr = [];
      this.linkedinArr.push({ 'filterName': 'linkedin__isnull' }, { 'filterValue': false }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }
    this.CallFinalFilter();

    if (obj == this.activelinkedin)
      return;
    if (this.activelinkedin) {
      this.activelinkedin.toggle();
    }

    if (obj) {
      obj.toggle();
    }

    this.activelinkedin = obj;
  }

  activesnippet = null;
  togglesnippetFilter(obj = null, actionSelected, optedRadio) {

    if (actionSelected == 'snippetContains' ) {
      this.prospectFilterModel.snippet__icontains = this.snippetContainsText;
      this.snippetArr = [];
      this.snippetArr.push({ 'filterName': 'snippet__icontains' }, { 'filterValue': this.snippetContainsText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }

    this.CallFinalFilter();

    if (obj == this.activesnippet)
      return;
    if (this.activesnippet) {
      this.activesnippet.toggle();
    }

    if (obj) {
      obj.toggle();
    }

    this.activesnippet = obj;
  }

  activesnippet1 = null;
  togglesnippet1Filter(obj = null, actionSelected, optedRadio) {

    if (actionSelected == 'snippet1Contains' ) {
      this.prospectFilterModel.snippet1__icontains = this.snippet1ContainsText;
      this.snippet1Arr = [];
      this.snippet1Arr.push({ 'filterName': 'snippet1__icontains' }, { 'filterValue': this.snippet1ContainsText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }

    this.CallFinalFilter();

    if (obj == this.activesnippet1)
      return;
    if (this.activesnippet1) {
      this.activesnippet1.toggle();
    }

    if (obj) {
      obj.toggle();
    }

    this.activesnippet1 = obj;
  }

  activesnippet2 = null;
  togglesnippet2Filter(obj = null, actionSelected, optedRadio) {

    if (actionSelected == 'snippet2Contains' ) {
      this.prospectFilterModel.snippet2__icontains = this.snippet2ContainsText;
      this.snippet2Arr = [];
      this.snippet2Arr.push({ 'filterName': 'snippet2__icontains' }, { 'filterValue': this.snippet2ContainsText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }

    this.CallFinalFilter();

    if (obj == this.activesnippet2)
      return;
    if (this.activesnippet2) {
      this.activesnippet2.toggle();
    }

    if (obj) {
      obj.toggle();
    }

    this.activesnippet2 = obj;
  }

  activesnippet3 = null;
  togglesnippet3Filter(obj = null, actionSelected, optedRadio) {

    if (actionSelected == 'snippet3Contains' ) {
      this.prospectFilterModel.snippet3__icontains = this.snippet3ContainsText;
      this.snippet3Arr = [];
      this.snippet3Arr.push({ 'filterName': 'snippet3__icontains' }, { 'filterValue': this.snippet3ContainsText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }

    this.CallFinalFilter();

    if (obj == this.activesnippet3)
      return;
    if (this.activesnippet3) {
      this.activesnippet3.toggle();
    }

    if (obj) {
      obj.toggle();
    }

    this.activesnippet3 = obj;
  }

  activesnippet4 = null;
  togglesnippet4Filter(obj = null, actionSelected, optedRadio) {

    if (actionSelected == 'snippet4Contains' ) {
      this.prospectFilterModel.snippet4__icontains = this.snippet4ContainsText;
      this.snippet4Arr = [];
      this.snippet4Arr.push({ 'filterName': 'snippet4__icontains' }, { 'filterValue': this.snippet4ContainsText }, { 'radioObj': optedRadio }, { 'toggleObj': obj });
    }

    this.CallFinalFilter();

    if (obj == this.activesnippet4)
      return;
    if (this.activesnippet4) {
      this.activesnippet4.toggle();
    }

    if (obj) {
      obj.toggle();
    }

    this.activesnippet4 = obj;
  }

}
