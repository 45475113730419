<div class="ms-sm-auto" style="width: 90%;">




  <div class="row" style="height: 100%;">

    <div class="col-2 shadow-3 " style="background: #FBF7FE; height: 100vh; overflow-x:hidden;scrollbar-width: thin;;
        overflow-y: auto; z-index: 1;">
      <app-analytics-sidebar></app-analytics-sidebar>
    </div>


    <div class="col-10 m-0" style="height: 100vh; overflow-x: hidden; overflow-y: auto;scrollbar-width: thin; ">



      <div class="row shadow-1 p-3 sticky-top bg-white" style="z-index: 2;">
        <div class="col-md-4">
          <h4 class="map-text-header">Link Tracking
            <span style="font-size: 12px; color: #ef6c00;">({{linktrackingList?.length}}/{{total_lt_count}}
              found)</span>
          </h4>
        </div>

        <div class="col-md-8">
          <div class="d-flex justify-content-end">



          </div>
        </div>

      </div>
      <div class="row">
        <div class="col-12 mt-2">


          <div class="row">
            <div class="col-4">
              <mdb-form-control>
                <input mdbInput type="text" id="email" class="form-control" name="email" [(ngModel)]="search_by_email"
                  (change)="searchSubmit($event)" />
                <label mdbLabel class="form-label" for="email">
                  Search by email</label>
              </mdb-form-control>
            </div>

            <div class="col-4">
              <mdb-form-control>
                <input mdbInput type="text" id="link" class="form-control" name="link" [(ngModel)]="search_by_link"
                  (input)="filterData()" />
                <label mdbLabel class="form-label" for="link">
                  Search by link</label>
              </mdb-form-control>
            </div>

            <div class="col-4">

              <ng-select [(ngModel)]="search_by_client" id="clientInput" (change)='filterData()'
                placeholder="Select client">
                <ng-option *ngFor="let o of clientList" [value]="o.id">{{o.name}}</ng-option>
              </ng-select>


            </div>

          </div>


          <div class="row mt-2">
            <div class="col-4">
              <input #campaignType [(ngModel)]="selectedCampaign" (typeaheadOnSelect)="onSelect($event)"
                (typeaheadLoading)="changeTypeaheadLoading($event)" [typeaheadMinLength]="0" [typeahead]="campaignlist"
                typeaheadOptionField="name" typeahead-wait-ms="100" class="form-control" name="Campaigns" type="text"
                (paste)="onPaste($event)" placeholder="Search by campaign" autocomplete="off" />
              <span class="highlight"></span>
            </div>

            <div class="col-4">
              <mdb-form-control>
                <input mdbInput type="text" [(ngModel)]="search_by_step" id="search3"
                  class="form-control form-control-sm" name="search" (keyup)="inputkeyUp()"
                  (keydown)="inputKeyDown()" />
                <label mdbLabel class="form-label" for="search3">
                  Search by step</label>
              </mdb-form-control>
            </div>


            <div class="col-4">
              <input id="bsdatepicker" type="text" class="form-control"
                [bsConfig]="{ isAnimated: true, containerClass: 'theme-default'}" placeholder="Search by date"
                #dp="bsDatepicker" (bsValueChange)="onValueChange($event)" bsDatepicker [(bsValue)]="myDateValue">
            </div>



          </div>




          <div class="row mt-2">
            <div class="col-12" style="scrollbar-width: thin;">

              <div #linkTrackingContainer class="table-responsive" infiniteScroll [infiniteScrollDistance]="2"
                [infiniteScrollThrottle]="30" (scrolled)="onLinkTrackingScroll()" [scrollWindow]="false">

                <table class="table table-sm table-hover table-bordered">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Clicked At</th>
                      <th>Client</th>
                      <th>Campaign</th>
                      <th>Step</th>
                      <th>Device</th>
                      <th>Location</th>
                      <th>Link</th>
                      <!-- <th>Clicked</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of linktrackingList">
                      <td>{{item.prospect_name}}</td>
                      <td>{{item.prospect_email_address}}</td>
                      <td>{{item.created_at | date: 'medium'}}</td>
                      <td>{{item.client.client_name}}</td>
                      <td>{{item.campaign_name}}</td>
                      <td>{{item.campaign_step_name}}</td>
                      <td>{{item.linkopened?.device}}</td>
                      <td>{{item.linkopened?.location.state ?
                        item.linkopened?.location.state + ',': ''}} {{item.linkopened?.location.country}}</td>
                      <!-- <td><a href="{{item.link | noSanitizeUrl}}" target="__blank">
                      <i class="fas fa-external-link-alt"></i></a>
                    </td> -->
                      <td><a href="{{item.link}}" target="__blank">
                          <i class="fas fa-external-link-alt"></i></a>
                      </td>
                      <!-- <td>{{item.cnt_clicks}}</td> -->

                    </tr>


                  </tbody>
                </table>

              </div>

            </div>
          </div>




        </div>
      </div>
    </div>


  </div>
</div>
