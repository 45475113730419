export class DomainDncModel {
    "id": number
    "email_domain": string
    "created_at": string    
    "modified_at": Date
    "dnc_reason": string
    "created_by": number    
    "modified_by": number
}


export class DomainDncResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [DomainDncModel]
}

export class ClientDncModel {
    "id": number
    "email_address": string
    "modified_at": Date
    "dnc_reason": string
    "created_by": number
    "created_at": string
    "client": number
    "modified_by": number
}

export class ClientDncResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [ClientDncModel]
}

export class GlobalDncModel {
    "id": number
    "email_address": string
    "modified_at": Date
    "dnc_reason": string
    "created_by": number
    "created_at": string
    "client": number
    "modified_by": number
}

export class GlobalDncResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [GlobalDncModel]
}

export class ClientsModel {
    "id": number
    "name": string
    "slug": string
    "created_at": Date
    "modified_at": Date
    "cnt_campaign": number
    "is_deleted": string
    "created_by": number
    "modified_by": number
} 


export class  ClientsResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [ClientsModel]
}



export class ImportDncResponseModel {
    total_record: number
    failed: any[]
    failed_count: number
    success_count: number
}