<div class="modal-header">
    <h5 class="modal-title" id="addProspectDnc">Add Global DNC</h5>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modalRef.close()"
    ></button>
  </div>
  <div class="modal-body">



    <div class="row">
      <div class="col text-center">

        <p>To add a DNC prospect, create prospect by clicking <span (click)="goTOCreateProspect()" class="create-prospect-link">"Create Prospect"</span> and then mark it as DNC by going to prospect detail page.</p>

          <!-- <svg xmlns="http://www.w3.org/2000/svg" width="161.814" height="161.814" viewBox="0 0 161.814 161.814">
              <path id="Icon_material-broken_image" data-name="Icon material-broken_image" d="M166.314,22.479V81.721L139.345,54.662,103.386,90.711,67.428,54.752,31.469,90.711,4.5,63.652V22.479A18.032,18.032,0,0,1,22.479,4.5H148.334A18.032,18.032,0,0,1,166.314,22.479ZM139.345,80.193l26.969,27.059v41.083a18.032,18.032,0,0,1-17.979,17.979H22.479A18.032,18.032,0,0,1,4.5,148.334V89.182l26.969,26.879L67.428,80.1l35.959,35.959Z" transform="translate(-4.5 -4.5)" fill="#f3f3f3"/>
            </svg> -->
            <!-- <p class="pt-4">This selection will be available from next release.</p>  -->
      </div>
    </div>


    <!-- <mdb-form-control>
      <input mdbInput type="email" id="form1" class="form-control" />
      <label mdbLabel class="form-label" for="form1">Email</label>
    </mdb-form-control>

    <div class="pt-3"></div>

    <select [(ngModel)]="selectedClientModel" class="form-select" aria-label="Select Client">
      <option selected value="0">Select Client</option>
      <option *ngFor="let opt of clients" value="{{opt.id}}">{{opt.name}}</option>
    </select>
  
    <div class="pt-3"></div> 
    <mdb-form-control>
      <textarea mdbInput class="form-control" id="textAreaExample" rows="4"></textarea>
      <label mdbLabel class="form-label" for="textAreaExample">Reason For DNC</label>
    </mdb-form-control> -->

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-link" (click)="modalRef.close()">
      Close
    </button>
    <!-- <button type="button" (click)="createProspectDNC()" class="btn btn-primary">Create</button> -->
  </div>