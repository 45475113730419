
<app-loading-dialog *ngIf="loadingSpiner">

</app-loading-dialog>

<!-- <div class="col-12 pe-3 ps-3">
  <p class="text-end"> Last fetched at {{ timeFetched | date :'shortTime' }} <i class=" px-2 fas fa-redo-alt text-danger" style="cursor:pointer;" (click)="fetchData()"></i> </p>
 </div> -->


<div class="col-12 pe-3 ps-3">


  <div class="col-12">
    <div style="overflow-y: auto;" class="pb-2">

      <div class="btn-group btn-group-lg shadow-1 prospectStats" role="group" aria-label="prospectStats">
        <button type="button"
          [class]="tab_selected == 'All' ? 'btn prospectStatsManuItemActive' : 'btn prospectStatsManuItem'"
          (click)="filterTabClicked('All',0)">All</button>


            <button type="button" *ngFor="let csd of campaignStepDetails; let count=index;"
            [class]="tab_selected == csd.id ? 'btn prospectStatsManuItemActive' : 'btn prospectStatsManuItem'"
            (click)="filterTabClicked(csd.id,csd.id)">Step {{count+1}}</button>

      </div>

    </div>


    <div class="col-12 mt-3">
      <div class="btn-group btn-group-lg shadow-1 prospectStats" role="group" aria-label="prospectStats">
        <button type="button"
          [class]="btn_selected == 'Total' ? 'btn prospectStatsManuItemActive' : 'btn prospectStatsManuItem'"
          id="btn_total" (click)="filterButtonClick('Total')">Total <br><span
            class="prospectStatsNumber">{{cnt_prospect_total}}</span><br><span class="text-muted">100.00%</span></button>
        <button type="button"
          [class]="btn_selected == 'New' ? 'btn prospectStatsManuItemActive' : 'btn prospectStatsManuItem'" id="btn_New"
          (click)="filterButtonClick('New')">New <br><span class="prospectStatsNumber">{{cnt_new}}</span><br>
           <span class="text-muted">{{cnt_prospect_total == 0 ? 0 :
            ((cnt_new/cnt_prospect_total)*100).toFixed(2)}}%</span></button>
        <button type="button"
          [class]="btn_selected == 'Processing' ? 'btn prospectStatsManuItemActive' : 'btn prospectStatsManuItem'"
          id="btn_Processing" (click)="filterButtonClick('Processing')">{{campiagnStatus == true ? 'Processing' :
          'Paused'}}
          <br><span class="prospectStatsNumber">{{cnt_processing}}</span><br>
          <span class="text-muted">{{cnt_prospect_total == 0 ? 0 :
           ((cnt_processing/cnt_prospect_total)*100).toFixed(2)}}%</span></button>
        <button type="button"
          [class]="btn_selected == 'Scheduled' ? 'btn prospectStatsManuItemActive' : 'btn prospectStatsManuItem'"
          id="btn_Scheduled" (click)="filterButtonClick('Scheduled')">Scheduled <br><span
            class="prospectStatsNumber">{{cnt_scheduled}}</span><br>
            <span class="text-muted">{{cnt_prospect_total == 0 ? 0 :
             ((cnt_scheduled/cnt_prospect_total)*100).toFixed(2)}}%</span></button>
        <button type="button"
          [class]="btn_selected == 'Finished' ? 'btn prospectStatsManuItemActive' : 'btn prospectStatsManuItem'"
          id="btn_Finished" (click)="filterButtonClick('Finished')">Sent <br><span
            class="prospectStatsNumber">{{cnt_sent}}</span><br>
            <span class="text-muted">{{cnt_prospect_total == 0 ? 0 :
             ((cnt_sent/cnt_prospect_total)*100).toFixed(2)}}%</span></button>

            <button type="button"
            [class]="btn_selected == 'NotOpened' ? 'btn prospectStatsManuItemActive' : 'btn prospectStatsManuItem'"
            id="btn_Opened" (click)="filterButtonClick('NotOpened')">Unopened <br><span
              class="prospectStatsNumber">{{cnt_notopened}}</span><br>
              <span class="text-muted">{{cnt_prospect_total == 0 ? 0 :
               ((cnt_notopened/cnt_prospect_total)*100).toFixed(2)}}%</span></button>


        <button type="button"
          [class]="btn_selected == 'Opened' ? 'btn prospectStatsManuItemActive' : 'btn prospectStatsManuItem'"
          id="btn_Opened" (click)="filterButtonClick('Opened')">Opened <br><span
            class="prospectStatsNumber">{{cnt_opened}}</span><br>
            <span class="text-muted">{{cnt_prospect_total == 0 ? 0 :
             ((cnt_opened/cnt_prospect_total)*100).toFixed(2)}}%</span></button>

        <button type="button"
          [class]="btn_selected == 'Replied' ? 'btn prospectStatsManuItemActive' : 'btn prospectStatsManuItem'"
          id="btn_Replied" (click)="filterButtonClick('Replied')">Replied <br><span
            class="prospectStatsNumber">{{cnt_replied}}</span><br>
            <span class="text-muted">{{cnt_prospect_total == 0 ? 0 :
             ((cnt_replied/cnt_prospect_total)*100).toFixed(2)}}%</span></button>
        <button type="button"
          [class]="btn_selected == 'Bounced' ? 'btn prospectStatsManuItemActive' : 'btn prospectStatsManuItem'"
          id="btn_Bounced" (click)="filterButtonClick('Bounced')">Bounced <br><span
            class="prospectStatsNumber">{{cnt_bounced}}</span><br>
            <span class="text-muted">{{cnt_prospect_total == 0 ? 0 :
             ((cnt_bounced/cnt_prospect_total)*100).toFixed(2)}}%</span></button>
        <button type="button"
          [class]="btn_selected == 'OptedOut' ? 'btn prospectStatsManuItemActive' : 'btn prospectStatsManuItem'"
          id="btn_OptedOut" (click)="filterButtonClick('OptedOut')">Opted Out <br><span
            class="prospectStatsNumber">{{cnt_opted_out}}</span><br>
            <span class="text-muted">{{cnt_prospect_total == 0 ? 0 :
             ((cnt_opted_out/cnt_prospect_total)*100).toFixed(2)}}%</span></button>
        <button type="button"
          [class]="btn_selected == 'Rejected' ? 'btn prospectStatsManuItemActive' : 'btn prospectStatsManuItem'"
          id="btn_Rejected" (click)="filterButtonClick('Rejected')">Rejected <br><span
            class="prospectStatsNumber">{{cnt_rejected}}</span><br>
            <span class="text-muted">{{cnt_prospect_total == 0 ? 0 :
             ((cnt_rejected/cnt_prospect_total)*100).toFixed(2)}}%</span></button>
      </div>
    </div>


  </div>


  <div class="col-12 mt-3">
    <div class="d-flex justify-content-between">
      <div class="col-3">
        <mdb-form-control>
          <input mdbInput type="text" id="form1" class="form-control form-control-sm" 
          (keyup)="inputkeyUp()"
          (keydown)="inputKeyDown()"  
            [(ngModel)]="emailContainsText" value="{{emailContainsText}}" />
          <label mdbLabel class="form-label" for="form1"><i class="fas fa-search"></i> Search</label>
        </mdb-form-control>
      </div>

      <div class="col-3" *ngIf="showProspectDelete && selectedProspectlist > 0">
        <button type="button" (click)="confirmDeleteCampaign()" class="btn btn-link btn-sm"> <i
            class="far fa-trash-alt"></i> Delete
        </button>
      </div>


      <div class="col-3">
        <select *ngIf="ShowRestriction" [(ngModel)]="selectedRestriction" (change)="changeRestriction()"
          class="form-select form-select-sm" aria-label=".form-select-sm example">
          <option selected value="Restricted">All Restriction Selected</option>
          <option value="Restricted-CLV">CLV Restriction</option>
          <option value="Restricted-Global-DNC">Global DNC Restriction</option>
          <option value="Restricted-Client-DNC">Client DNC Restriction</option>
          <option value="Restricted-OOO">Out Of Office Restriction</option>
          <option value="Restricted-Prospect-SL">Prospect Send limit Restriction</option>
          <option value="Error-Service">Service Errors</option>
        </select>
      </div>

      <!-- <button *ngIf="(!(tab_selected == 'All' && btn_selected == 'Total') || btn_selected == 'Bounced' || btn_selected == 'OptedOut' || btn_selected == 'Rejected')" (click)="addToAnotherCampaign()" class="btn btn-link">
        <i class="fas fa-users" style="color: #EF6C00;"></i> Add to another campaign</button> -->
        <button *ngIf="btn_selected == 'Finished' || btn_selected == 'NotOpened' || btn_selected == 'Opened' || btn_selected == 'Replied'" (click)="addToAnotherCampaign()" class="btn btn-link">
          <i class="fas fa-users" style="color: #EF6C00;"></i> Add to another campaign</button>


      <button *ngIf="!(tab_selected == 'All' && btn_selected == 'Total')" class="btn btn-link" (click)="exportAll()">
        <i class="fas fa-cloud-download-alt" style="color: #EF6C00;"></i> Export All</button>
    </div>
  </div>

</div>



<ng-container *ngIf="cnt_prospect_total >= 1 && campaignRunLogList.length >= 1">
  <div #prospectContainer1 class="table-responsive tableFixHead" infiniteScroll [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="1000" (scrolled)="onEmailScroll()" [scrollWindow]="false">
    <div class="col-12 pe-3 ps-3 mt-3">
      <table class="table bg-white shadow-1 table-sm table-hover table-bordered">
        <thead style="background-color: rgb(240, 240, 240);">
          <tr>
            <th *ngIf="showProspectDelete" style="width: 20px;" scope="col">
              <input mdbRadio style="cursor: pointer;" type="checkbox" [(ngModel)]="isMasterSel"
                name="flexRadioDefault1" id="flexRadioDefault1" (change)="checkUncheckAll($event)" />
            </th>
            <th scope="col">Prospect Email</th>
            <th scope="col">Campaign Outcome</th>
            <th scope="col">Sender</th>
            <th scope="col">Step</th>
            <th scope="col">Phone</th>
            <th *ngIf="(btn_selected == 'Opened') || (btn_selected == 'Replied')" scope="col">Device Info</th>
            <th *ngIf="(btn_selected == 'Opened') || (btn_selected == 'Replied')">Location</th>
            <th >Provider</th>
            <th scope="col">Date & Time</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let i of campaignRunLogList">
            <td *ngIf="showProspectDelete" style="width: 20px;">
              <input type="checkbox" [(ngModel)]="i.isSelected" name="list_name" value="{{i.id}}"
                (change)="isAllSelected($event, i.prospect)" />
            </td>
            <td><a routerLink="/prospects/{{i.prospect}}"><strong>{{i.recipient_email_address}}</strong></a></td>

            <td>{{i.log_status}}</td>
            <td>{{i.sender_email_address}}</td>
            <td>{{i.subject}}</td>
            <td>{{i.phone}}</td>
            <td *ngIf="(btn_selected == 'Opened') || (btn_selected == 'Replied')">
              {{convertDevicesListToStringWithComma(i.mailopened)}}</td>
            <td *ngIf="(btn_selected == 'Opened') || (btn_selected == 'Replied')">{{getLocation(i.mailopened)}}</td>

            <td *ngIf="(btn_selected == 'Sent')">{{(i.email_sent_at | date:'medium') || ''}}</td>
            <td *ngIf="(btn_selected == 'Opened')">{{(i.email_opened_at | date:'medium') || ''}}</td>
            <td *ngIf="(btn_selected == 'Replied')">{{(i.email_replied_at | date:'medium') || ''}}
              <span *ngIf="!i.is_auto_reply" class="badge rounded-pill bg-primary">Normal</span>
              <span *ngIf="i.is_auto_reply" class="badge rounded-pill bg-warning text-dark">Auto</span>

            </td>
            <td *ngIf="(btn_selected == 'OptedOut')">{{(i.email_opened_at | date:'medium') || ''}}</td>
            <td >{{i.email_ext_provider}} </td>
            <td *ngIf="!(btn_selected == 'Sent') &&
          !(btn_selected == 'Opened') &&
          !(btn_selected == 'Replied') &&
          !(btn_selected == 'OptedOut')">{{(i.created_at | date:'medium') || ''}}</td>

          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-container>


<ng-container *ngIf="cnt_prospect_total >= 1 && campaignRunLogList.length === 0">

  <div class="col-12">
    <div class="row add-step me-3 ms-3 shadow-1 bg-white mt-3 p-3" style="border-radius: 5px;">
      <div class="col-12">
        <span><i style="color:#EF6C00" class="far fa-user-circle fa-3x add-step-icon"></i></span>
        <p class="add-step-text">{{tab_selected === '' ? 'Apply filter to view' : 'No Data Found!'}} <br />
          <span>
            {{tab_selected === '' ? 'Filter is not applied' : 'No prospect available on selected filters'}}
            </span>
        </p>
        <!-- <button *ngIf="tab_selected != ''" routerLink="/prospects" type="button" class="btn btn-primary"><i class="fas fa-plus"></i> ADD
          Prospect</button> -->
      </div>
    </div>
  </div>

</ng-container>


<ng-container *ngIf="cnt_prospect_total === 0 && campaignRunLogList.length === 0">


  <div class="col-12">
    <div class="row add-step me-3 ms-3 shadow-1 bg-white mt-3 p-3" style="border-radius: 5px;">
      <div class="col-12">
        <span><i style="color:#EF6C00" class="far fa-user-circle fa-3x add-step-icon"></i></span>
        <p class="add-step-text">Add your first prospect!<br />
          <span>You haven't added any prospects yet.</span>
        </p>
        <!-- <button routerLink="/prospects" type="button" class="btn btn-primary"><i class="fas fa-plus"></i> ADD
          Prospect</button> -->
      </div>
    </div>
  </div>

</ng-container>



<div class="row mt-5">
  <div class="col-12 mt-5"></div>
  <div class="col-12 mt-5"></div>
</div>
