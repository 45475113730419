import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoginViewModel } from '../models/login-view-model';
import { EncryptMsgService } from '../../app-providers/services/encryptMsgService';

@Component({
  selector: 'app-password-confirm',
  templateUrl: './password-confirm.component.html',
  styleUrls: ['./password-confirm.component.scss']
})
export class PasswordConfirmComponent implements OnInit {

  userToken: string = null;
  userEmail: string = null;
  showBrokenUrl: boolean = false;
  showPasswordMatchError: boolean = false;
  showCheckingToken: boolean = false;
  showPasswordResetSuccess: boolean = false;
  password1: string = null;
  password2: string = null;

  constructor(private route: ActivatedRoute, private vm: LoginViewModel, private EncrDecr: EncryptMsgService) {
    // console.log(this.EncrDecr.set("root@123"))
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.userToken = params['token'];
      this.userEmail = params['email'];
    });
    
    this.showCheckingToken = true;
    this.vm.parentPasswordConfirmComponent = this;
    this.vm.validateToken(this.userEmail, this.userToken);

  }

  tokenValidationDone(success){
    if(success === false){
      this.showBrokenUrl = true;
    }
    this.showCheckingToken = false;
  }

  confirmPassword(){
    if(this.password1 == this.password2 && this.password1?.length > 5){
      this.showPasswordMatchError = false;
      this.vm.parentPasswordConfirmComponent = this;
      var encryptedPassword1 = this.EncrDecr.set(this.password1);
      var encryptedPassword2 = this.EncrDecr.set(this.password2);
      this.vm.confirmPassword(this.userEmail, this.userToken, encryptedPassword1, encryptedPassword2);
    } else {
      this.showPasswordMatchError = true;
    }
  }

  confirmPasswordSuccess(){
    this.showPasswordResetSuccess = true;
    // console.log(this.showPasswordResetSuccess)
  }

}
