

<div class="ms-sm-auto" style="width: 100%;">

    <div class="row" style="
      background-image: url(../../assets/images/map-background.jpg); 
      background-repeat: no-repeat;
      background-size: cover;
      height: 100vh;">

        <div class="col"></div>

        <div class="col align-self-stretch" style="margin-top: 100px;"> 

            <div class="card p-2">
                <div class="card-body">

                  <div class="row mt-1">
                    <div class="col-12" style="text-align: center;">
                      <img class="logo" src="../../assets/images/logo-mini.png">
                    </div>
                  </div>
                  
                  <div class="mt-2 text-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="145.665" height="65" viewBox="0 0 145.665 65">
                      <g id="Group_731" data-name="Group 731" transform="translate(238 -76)">
                        <text id="MAP" transform="translate(-238 129)" fill="#43116e" font-size="49" font-family="SegoeUI, Segoe UI"><tspan x="0" y="0">MAP</tspan></text>
                        <path id="Icon_simple-minutemailer" data-name="Icon simple-minutemailer" d="M10.028,4.055l6.218,6.73-4.387,8.647,9.264-1.66L25.34,27.948,43.255,4.456ZM8.882,18.632l1.725-1.418-2.224.7-.227-.324.919-.892-1.206.487a12.276,12.276,0,0,0-4.025,20.38,12.189,12.189,0,0,1-1.6-6.047,12.583,12.583,0,0,1,7.777-11.571l.568-1.381-1.352.6-.38-.514Zm7.865-3.551L19.627,10,36.565,6.03Z" transform="translate(-135.59 92.196)" fill="#ef6c00"/>
                      </g>
                    </svg>                    
                  </div>

                  <hr style="height: 0.5px">

                 <div class="text-center">
                  <h5 class="card-title">Login to MAP</h5>
                 </div>

                 <p class="p-0 m-0">Username</p>
                  <mdb-form-control class="mt-1">                    
                    <input mdbInput [(ngModel)]="usernameModel" type="text" id="username" class="form-control" /> 
                  </mdb-form-control>

                  <p class="p-0 m-0 mt-3">Password</p>
                  <mdb-form-control class="mt-1">
                    <input mdbInput  [(ngModel)]="passwordModel" type="password" id="password" class="form-control" />
                  </mdb-form-control>

                  <ng-container *ngIf="loginError">
                      <P class="text-center text-danger m-0 p-0 mt-2">{{loginError}}</P>
                  </ng-container>
                  <div class="text-center mt-2">
                    <button type="button" (click)="Login()" class="btn btn-primary mt-2">Login</button>
                  </div>

                  <div class="text-center mt-2">
                    <a routerLink="/login/password-reset">Forgot password?</a>
                  </div>
                 
                </div>
              </div>


        </div>


        <div class="col"></div>
    </div> 

 

    </div>