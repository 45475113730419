

<div class="ms-sm-auto" style="width: 100%;">

    <div class="row" style="
    background-image: url(../../assets/images/map-background.jpg); 
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;">

        <div class="col"></div>

        <div class="col align-self-stretch" style="margin-top: 100px;"> 

            <div class="card p-2">
                <div class="card-body">

                  <div class="row mt-1">
                    <div class="col-12" style="text-align: center;">
                      <img class="logo" src="../../assets/images/logo-mini.png">
                    </div>
                  </div>
                  
                  <div class="mt-2 text-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="145.665" height="65" viewBox="0 0 145.665 65">
                      <g id="Group_731" data-name="Group 731" transform="translate(238 -76)">
                        <text id="MAP" transform="translate(-238 129)" fill="#43116e" font-size="49" font-family="SegoeUI, Segoe UI"><tspan x="0" y="0">MAP</tspan></text>
                        <path id="Icon_simple-minutemailer" data-name="Icon simple-minutemailer" d="M10.028,4.055l6.218,6.73-4.387,8.647,9.264-1.66L25.34,27.948,43.255,4.456ZM8.882,18.632l1.725-1.418-2.224.7-.227-.324.919-.892-1.206.487a12.276,12.276,0,0,0-4.025,20.38,12.189,12.189,0,0,1-1.6-6.047,12.583,12.583,0,0,1,7.777-11.571l.568-1.381-1.352.6-.38-.514Zm7.865-3.551L19.627,10,36.565,6.03Z" transform="translate(-135.59 92.196)" fill="#ef6c00"/>
                      </g>
                    </svg>                    
                  </div>

                  <hr style="height: 0.5px">

                 <div class="text-center">
                  <h5 class="card-title">Password reset</h5>
                 </div>

                  <div *ngIf="!showSuccess">
                    <mdb-form-control class="mt-1">                    
                      <input mdbInput [(ngModel)]="userEmail" type="text" id="email" class="form-control" placeholder="Registered Email" /> 
                    </mdb-form-control>
  
                    <div class="text-center mt-2" *ngIf="showError">
                      <div style="color: red;">Email address is required</div>
                    </div>

                    <div class="d-flex justify-content-center" *ngIf="showSpinner">
                      <app-spinner></app-spinner>
                    </div>
  
                    <div class="text-center mt-2">
                      <p>Enter you email to get password reset link</p>
                    </div>
                  </div>


                  <div *ngIf="showSuccess" class="text-center">
                    <p>We have received your request for password reset, if your account is active, 
                      you will get a password reset email. Please check your inbox/spam folder.</p>
                  </div>

                  <div class="d-flex justify-content-between">
                    <a routerLink="/login" mdbRipple type="button" class="btn btn-light">Cancel</a>
                    <button *ngIf="!showSuccess" mdbRipple type="button" class="btn btn-primary" (click)="resetMyPassword()">Reset</button>
                  </div>

                
                 
                </div>
              </div>


        </div>


        <div class="col"></div>
    </div> 

 

    </div>