<div class="ms-sm-auto" style="width: 90%;">

<div class="row" style="height: 100%;">

    
  <div class="col-2" style="background: #FBF7FE; height: 100vh; overflow-x: hidden; overflow-y: auto; ">  
 
        <app-sidebar-import></app-sidebar-import>
      
      </div>

    <div class="col-10 p-5">
      
    <H3>Copy & Paste</H3>

    <p class="mt-4" style="color: gray;">The first row must contain column headers: email, first_name, company_name, etc <br>
      We can import up to 2,000 rows of data at a time.
    </p>

    <div class="md-form">
      <textarea type="text" class="md-textarea form-control" mdbInput rows="5"></textarea>
    </div>

    <button type="button" class="btn btn-primary mt-3">NEXT STEP</button>

     </div>
  
  </div>

</div>