import { Component, OnInit , ViewChild, ElementRef } from '@angular/core';
import {
  CampaignRunLogStatsResponseModel, CampaignRunLogFilterModel,
  ProspectResponseModel, CampaignRunLogResponseModel, CampaignMasterStat
} from "../campaign-model/campaign-model";
import { MapAPIService } from "src/app/app-providers/services/map-module";
import { BehaviorSubject } from 'rxjs';
import { CampaignViewModel } from '../campaign-view-model/campaign-view-model';
import { CampaignDetailComponent } from '../campaign-detail/campaign-detail.component';
import { CampaignDetailSummaryComponent } from '../campaign-detail-summary/campaign-detail-summary.component'
import { ExportCampaignService } from 'src/app/admin/services/export-campaign.service';
import { ConfirmDialogService } from '../../admin/confirm-dialog/confirm-dialog.service';
import { AlertNotificationsComponent } from 'src/app/Alerts/alert-notifications/alert-notifications.component';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { AddToAnotherCampaignComponent } from '../add-to-another-campaign/add-to-another-campaign.component';
import { Router } from '@angular/router';
@Component({
  selector: 'app-campaign-detail-prospects',
  templateUrl: './campaign-detail-prospects.component.html',
  styleUrls: ['./campaign-detail-prospects.component.scss'],
  providers: [MapAPIService, CampaignViewModel],
})
export class CampaignDetailProspectsComponent implements OnInit {
  @ViewChild("prospectContainer1") prospectContainer1: ElementRef;
  campaignId: number;
  emailContainsText: string = null;
  campaignReportRunlogStats: any = [];
  campaignStepDetails: any = [];
  campiagnStatus: boolean = false;
  cnt_prospect_total: number = 0;
  cnt_new: number = 0;
  cnt_scheduled: number = 0;
  cnt_processing: number = 0;
  cnt_sent: number = 0;
  cnt_bounced: number = 0;
  cnt_opened: number = 0;
  cnt_notopened: number = 0;
  cnt_replied: number = 0;
  cnt_opted_out: number = 0;
  cnt_rejected: number = 0;
  btn_selected: string = ''
  tab_selected: string = ''
  selectedRestriction: string = 'Restricted';
  ShowRestriction: boolean;
  campaignRunLogFilterModel = new CampaignRunLogFilterModel();
  campaignRunLogList: any = [];
  campaignRunLogListB4Search: any = [];
  isMasterSel: boolean = false;
  prospectListB4Search: any = [];
  page_index = 1;
  prospectList: any = [];
  static settingCall;
  showProspectDelete: boolean = false;
  // selectedRunLogStatslist: number[] = [];
  exportAllPageSize: number;
  loadingSpiner: boolean = false;
  addToAnotherCampaignModalRef: MdbModalRef<AddToAnotherCampaignComponent>;
  timeFetched:string;

  campaignRunLogStatsResponseModel = new BehaviorSubject<CampaignRunLogStatsResponseModel>(
    new CampaignRunLogStatsResponseModel()
  );
  prospectResponseModel = new BehaviorSubject<ProspectResponseModel>(
    new ProspectResponseModel()
  );
  campaignRunLogResponseModel = new BehaviorSubject<CampaignRunLogResponseModel>(
    new CampaignRunLogResponseModel()
  );
  campaignMasterStat = new BehaviorSubject<CampaignMasterStat>(
    new CampaignMasterStat()
  );

  includedProspects: [];
  excludedProspects: [];
  selectedProspectlist = 0;
  totalProspectRecieved = 0;
  
  
  typingTimer;                //timer identifier
  doneTypingInterval = 1000;  //time in ms, 5 second for example


  constructor(private vm: CampaignViewModel, private exportCampaignService: ExportCampaignService,
    private confirmDialogService: ConfirmDialogService, private modalService: MdbModalService, private router: Router) {
    CampaignDetailProspectsComponent.settingCall = this;
  }

  ngOnInit(): void {
    this.clearMultiselect();
    var url = window.location.href;
    this.campaignId = Number(url.substring(url.lastIndexOf('/') + 1));
    this.getCampaignStatus();
    this.vm.parentCampaignDetailProspectsComponent = this;
    this.vm.subscribeCampaignProspectVariables(this.campaignMasterStat);
    this.vm.subscribeCampaignRunLogVariablesNew(this.prospectResponseModel);

    this.vm.subscribeCampaignRunLogVariables(this.campaignRunLogResponseModel)
    this.vm.getReportCampaignRunlogStats(this.campaignId, 'campaign-prospect');

    // if(!sessionStorage.getItem("campaignProspectReportRunlogStats")){
    //   this.vm.getReportCampaignRunlogStats(this.campaignId, 'campaign-prospect');
    //   } else{
    //     this.campaignReportRunlogStats = JSON.parse(sessionStorage.getItem("campaignProspectReportRunlogStats"))
    //     this.filterTabClicked('All', 0);
    //     this.fillUpTheCounts('All');
    //     this.timeFetched =  sessionStorage.getItem("campaignProspectReportRunlogStatsTime");

    //     console.log(sessionStorage.getItem("campaignReportRunlogStats") , this.campaignReportRunlogStats)
    //   }
  }

  clearMultiselect(){
    this.isMasterSel = false;
    this.selectedProspectlist = 0;
    this.includedProspects = [];
    this.excludedProspects = [];
  }

  exportAll() {
    if(this.campaignRunLogList.length > 0){
      this.loadingSpiner = true;
      this.vm.parentCampaignDetailProspectsComponent = this;
      this.vm.getExportRunlogs(this.campaignRunLogFilterModel, 'campaign-prospect', this.exportAllPageSize);
    } else {
      AlertNotificationsComponent.alertCall.showAlert("No data available, can't export", 'danger')
    }
  }

  exportApiOpsCompleted(){
    this.loadingSpiner = false;
    // this.exportCampaignService.downloadCSV(data, (this.campaignId + '_campaign_export'));
  }

  // exportProspectsReceived(items){
  //   var prospects = items.map(item => item.prospect);
  //   prospects = prospects.filter((x, i, a) => a.indexOf(x) == i);
  //   this.vm.parentCampaignDetailProspectsComponent = this;
  //   this.vm.exportFinalRunlogs(items, prospects, 'campaign-prospect');
  // }



  getCampaignStatus() {
    this.campiagnStatus = CampaignDetailComponent.parentCall.campiagnStatus;
    this.campaignStepDetails = CampaignDetailSummaryComponent.siblingCall.campaignSteps;
  }

  CampaignReportRunLogStatsRecieved(timeStatsFetched:any) {
    this.campaignReportRunlogStats = this.campaignMasterStat.value;
    // if(!sessionStorage.getItem("campaignProspectReportRunlogStats")){
    //   this.filterTabClicked('All', 0);
    // }
    // this.filterTabClicked('All', 0);
    this.fillUpTheCounts('All');
    // let sessionReportRunLog =  JSON.stringify(this.campaignMasterStat.value);
    // sessionStorage.setItem("campaignProspectReportRunlogStats", sessionReportRunLog);

    // sessionStorage.setItem("campaignProspectReportRunlogStatsTime", timeStatsFetched);
    // this.timeFetched  =  timeStatsFetched
  }

  // fetchData(){
  //   sessionStorage.removeItem("campaignProspectReportRunlogStatsTime")
  //   this.vm.getReportCampaignRunlogStats(this.campaignId, 'campaign-prospect');
  //   this.filterButtonClick(localStorage.getItem('filterButtonData'))
  // }


  CampaignRunLogReceivedOnProspects() {
    this.loadingSpiner = false;
    if (this.campaignRunLogListB4Search.length == 0){
      this.campaignRunLogList = this.campaignRunLogResponseModel.value.results;
    }
    else {
      this.campaignRunLogList = this.campaignRunLogResponseModel.value.results;
      if (this.campaignRunLogResponseModel.value.previous !== null) {
        for (var j = 0; j < this.campaignRunLogList.length; j++) {
          var index = this.campaignRunLogListB4Search.findIndex(x => x.id == this.campaignRunLogList[j].id);
          if (index === -1)
            this.campaignRunLogListB4Search.push(this.campaignRunLogList[j]);
        }
        this.campaignRunLogList = this.campaignRunLogListB4Search;
      }
    }

    this.exportAllPageSize = this.campaignRunLogResponseModel.value.count;

  }

  CampaignProspectReceived() {
    if (this.prospectListB4Search.length == 0)
      this.prospectList = this.prospectResponseModel.value.results;
    else {
      this.prospectList = this.prospectResponseModel.value.results;
      if (this.prospectResponseModel.value.previous !== null) {
        for (var j = 0; j < this.prospectList.length; j++) {
          var index = this.prospectListB4Search.findIndex(x => x.id == this.prospectList[j].id);
          if (index === -1)
            this.prospectListB4Search.push(this.prospectList[j]);
        }
        this.prospectList = this.prospectListB4Search;
      }
    }
    //  this.prospectList = this.prospectResponseModel.value.results;
    this.campaignRunLogList = [];
    for (var j = 0; j < this.prospectList.length; j++) {
      this.campaignRunLogList.push({ 'recipient_email_address': this.prospectList[j].email_address, 'slog_status': "", 'subjects': "", 'created_at': "", 'prospect': this.prospectList[j].id });
    }
    this.totalProspectRecieved =  this.prospectResponseModel.value.count;
  }

  fillUpTheCounts(caller) {
    if (caller == 'All') {
      this.cnt_prospect_total = this.campaignReportRunlogStats.stats.total_prospect;
      this.cnt_new = this.campaignReportRunlogStats.stats.emails_created;
      this.cnt_scheduled = this.campaignReportRunlogStats.stats.emails_scheduled;
      this.cnt_opened = this.campaignReportRunlogStats.stats.emails_sent.reached.opened;
      this.cnt_notopened= this.campaignReportRunlogStats.stats.emails_sent.reached.notopened;
      this.cnt_replied = this.campaignReportRunlogStats.stats.emails_sent.reached.replied.total +
      this.campaignReportRunlogStats.stats.emails_sent.reached.replied.auto_reply.total;
      this.cnt_bounced = this.campaignReportRunlogStats.stats.emails_sent.bounced;
      this.cnt_opted_out = this.campaignReportRunlogStats.stats.emails_sent.reached.opted_out;
      this.cnt_processing = this.campaignReportRunlogStats.stats.emails_processing;
      this.cnt_sent = this.campaignReportRunlogStats.stats.emails_sent.total;
      this.cnt_rejected = this.campaignReportRunlogStats.stats.emails_restricted.total;
    }
    else {
      var stepLen = this.campaignReportRunlogStats.step_stats.length;
      if (stepLen > 0) {
        for (let i = 0; i < stepLen; i++) {
          if (this.campaignReportRunlogStats.step_stats[i].id == caller) {
            this.cnt_prospect_total = this.campaignReportRunlogStats.step_stats[i].total_prospect;
            this.cnt_new = this.campaignReportRunlogStats.step_stats[i].emails_created;
            this.cnt_scheduled = this.campaignReportRunlogStats.step_stats[i].emails_scheduled;
            this.cnt_opened = this.campaignReportRunlogStats.step_stats[i].emails_sent.reached.opened;
            this.cnt_notopened= this.campaignReportRunlogStats.step_stats[i].emails_sent.reached.notopened;
            this.cnt_replied = this.campaignReportRunlogStats.step_stats[i].emails_sent.reached.replied.total
            + this.campaignReportRunlogStats.step_stats[i].emails_sent.reached.replied.auto_reply.total;
            this.cnt_bounced = this.campaignReportRunlogStats.step_stats[i].emails_sent.bounced;
            this.cnt_opted_out = this.campaignReportRunlogStats.step_stats[i].emails_sent.reached.opted_out;
            this.cnt_processing = this.campaignReportRunlogStats.step_stats[i].emails_processing;
            this.cnt_sent = this.campaignReportRunlogStats.step_stats[i].emails_sent.total;
            this.cnt_rejected = this.campaignReportRunlogStats.step_stats[i].emails_restricted.total;
            return;
          }
        }
      };
    }
  }

  convertDevicesListToStringWithComma(data){
    if (data?.length > 0)
    {
      var items = []
    for (var i of data){
      if(!items.includes(i.device))
      {
        items.push(i.device)
      }
    }

    return items.toString()
    }
}


addToAnotherCampaign(){

  const modalOptions = {
    data: {
      selectedfilters: this.campaignRunLogFilterModel,
      selectedProspects: this.campaignRunLogResponseModel.value.count
    },
    modalClass: 'modal-small'
  }
  this.addToAnotherCampaignModalRef = this.modalService.open(AddToAnotherCampaignComponent, modalOptions);
  this.addToAnotherCampaignModalRef.onClose.subscribe((message: any) => {
    if (message) {
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
      this.router.navigate([`/campaigns/${message}`]));
    }
  });

}

getLocation(data){
  var location = ""
  if (data?.length > 0) {
   var item = data[data.length - 1]
   if(item?.location){
    if (item.location.city?.length > 0 ){
      location = location + item.location.city
     }
     if (item.location.state?.length > 0){
       if(!(item.location.city?.length > 0)){
        location = location + item.location.state
       } else {
        location = location + ', ' + item.location.state
       }

     }
     if (item.location.country?.length > 0){

      if(!(item.location.city?.length > 0) && !(item.location.state?.length > 0)){
        location = location + item.location.country
       } else {
        location = location +  ', ' + item.location.country
       }

     }
   }


  }
  return location
}



  confirmDeleteCampaign() {
    this.confirmDialogService.confirmThis("Are you sure you want to delete prospect?", function () {
      CampaignDetailProspectsComponent.settingCall.deleteProspects();
    }, function () {
    })
  }
  deleteProspects() {
    this.vm.deleteCampaignProspects(this.campaignId, this.includedProspects, this.excludedProspects);
  }
  getCheckedItemList() {
    // this.selectedRunLogStatslist = [];
    // for (var i = 0; i < this.campaignRunLogList.length; i++) {
    //   if (this.campaignRunLogList[i].isSelected)
    //     this.selectedRunLogStatslist.push(this.campaignRunLogList[i].prospect);
    // }

    this.selectedProspectlist = 0;
    this.excludedProspects = [];
    this.includedProspects = [];
    if (this.isMasterSel) {
      this.selectedProspectlist = this.totalProspectRecieved;
      this.excludedProspects = this.campaignRunLogList.filter(item => item.isSelected == false).map(data => data.prospect)
    } else {
      this.selectedProspectlist = this.campaignRunLogList.filter(item => item.isSelected == true).map(data => data.prospect).length;
      this.includedProspects = this.campaignRunLogList.filter(item => item.isSelected == true).map(data => data.prospect)
    }
    this.selectedProspectlist = this.selectedProspectlist - this.excludedProspects.length;
  }

  checkUncheckAll(e) {
    this.isMasterSel = e.target.checked;
    if (!this.isMasterSel) {
      this.includedProspects = [];
      this.excludedProspects = [];
    }

    for (var i = 0; i < this.campaignRunLogList.length; i++) {
      this.campaignRunLogList[i].isSelected = this.isMasterSel;
    }
    // for (var i = 0; i < this.campaignRunLogList.length; i++) {
    //   this.campaignRunLogList[i].isSelected = this.isMasterSel;
    // }
    this.getCheckedItemList();
  }

    isAllSelected(e: any, itemId: number) {
      // this.isMasterSel = this.campaignRunLogList.every(function (item: any) {
      //   return item.isSelected == true;
      // })
      this.campaignRunLogList.find(item => item.prospect == itemId).isSelected = e.target.checked;
      this.getCheckedItemList();
    }

  filterTabClicked(tabSelected, stepId) {
    this.tab_selected = tabSelected;
    this.showProspectDelete = false;
    this.page_index = 1;
   // this.prospectContainer1.nativeElement.scrollTop = 0;

   if(this.btn_selected === ''){
    this.btn_selected = 'Total'
   }

    if (tabSelected == 'All') {
      if (this.btn_selected === 'Total'){
        this.showProspectDelete = true;
      }

      this.campaignRunLogFilterModel.campaign_step__campaign__id = this.campaignId;
      this.campaignRunLogFilterModel.campaign_step__id = null;
      this.fillUpTheCounts('All');
      this.callCampaignProspect();
    }
    else {
      this.campaignRunLogFilterModel.campaign_step__campaign__id = null;
      this.campaignRunLogFilterModel.campaign_step__id = stepId;
      this.fillUpTheCounts(stepId);
      this.CallFinalFiler();
    }

    //this.CallFinalFiler();
  }

  changeRestriction() {
    this.campaignRunLogFilterModel.log_status__startswith = this.selectedRestriction;
    this.CallFinalFiler();
  }

  callCampaignProspect() {
    this.vm.getCampaignProspectById(this.campaignId, this.page_index);
  }

  filterButtonClick(filterChoose) {
    // this.filterButtonData = filterChoose
    // console.log(filterChoose)
    // localStorage.setItem('filterButtonData',filterChoose)
    this.page_index = 1;
   // this.prospectContainer1.nativeElement.scrollTop = 0;
    this.btn_selected = filterChoose;
    this.isMasterSel = false;
    this.selectedProspectlist = 0;
    this.showProspectDelete = false;

    if(this.tab_selected === ''){
      this.tab_selected = 'All';
      this.campaignRunLogFilterModel.campaign_step__campaign__id = this.campaignId;
    }
    

    if (filterChoose == 'Total') {
      if (this.tab_selected === 'All') {
        this.showProspectDelete = true;

        this.callCampaignProspect();
      }
      else {
        this.campaignRunLogFilterModel.email_opened_at__isnull = null;
        this.campaignRunLogFilterModel.email_bounced_at__isnull = null;
        this.campaignRunLogFilterModel.email_opted_out_at__isnull = null;
        this.campaignRunLogFilterModel.email_replied_at__isnull = null;
        this.campaignRunLogFilterModel.campaign_step__status = null;
        this.campaignRunLogFilterModel.email_sent_at__isnull = null;
        this.campaignRunLogFilterModel.log_status__startswith = null;
        this.ShowRestriction = false;
        this.CallFinalFiler();
      }
    }
    else {

      this.campaignRunLogFilterModel.email_opened_at__isnull = null;
      this.campaignRunLogFilterModel.email_bounced_at__isnull = null;
      this.campaignRunLogFilterModel.email_opted_out_at__isnull = null;
      this.campaignRunLogFilterModel.email_replied_at__isnull = null;
      this.campaignRunLogFilterModel.campaign_step__status = null;
      this.campaignRunLogFilterModel.log_status__startswith = null;
      this.campaignRunLogFilterModel.email_sent_at__isnull = null;
      this.ShowRestriction = false;

      if (filterChoose == 'New'){
        this.campaignRunLogFilterModel.campaign_step__status = 'New';
      }

      if (filterChoose == 'Processing'){
        this.campaignRunLogFilterModel.campaign_step__status = 'Processing';
      }

      if (filterChoose == 'Scheduled'){
        this.campaignRunLogFilterModel.campaign_step__status = 'Scheduled';
      }

      if (filterChoose == 'Finished'){
        this.campaignRunLogFilterModel.email_sent_at__isnull = 'false';
      }

      if (filterChoose == 'Rejected') {
        this.ShowRestriction = true;
        this.campaignRunLogFilterModel.log_status__startswith = this.selectedRestriction;
      }

      if (filterChoose == 'Bounced'){
        this.campaignRunLogFilterModel.email_bounced_at__isnull = 'False';
      }

      if (filterChoose == 'Opened'){
        this.campaignRunLogFilterModel.email_opened_at__isnull = 'False';
        this.campaignRunLogFilterModel.email_bounced_at__isnull = 'True';
      }

      if (filterChoose == 'NotOpened'){
        this.campaignRunLogFilterModel.email_sent_at__isnull = 'False';
        this.campaignRunLogFilterModel.email_opened_at__isnull = 'True';
        this.campaignRunLogFilterModel.email_bounced_at__isnull = 'True';

      }


      if (filterChoose == 'Replied'){
        this.campaignRunLogFilterModel.email_replied_at__isnull = 'False';
      }

      if (filterChoose == 'OptedOut'){
        this.campaignRunLogFilterModel.email_opted_out_at__isnull = 'False';
      }

      this.CallFinalFiler();
    }
  }

  status: boolean = false;
  clickEvent() {
    this.status = !this.status;
  }
  handleUserInput() {

    if(this.tab_selected === ''){
      this.tab_selected = 'All';
      this.campaignRunLogFilterModel.campaign_step__campaign__id = this.campaignId;
    }

    if(this.btn_selected === ''){
      this.btn_selected = 'Total';
      this.showProspectDelete = true;
    }

    
    this.campaignRunLogFilterModel.recipient_email_address__contains = this.emailContainsText;
    this.CallFinalFiler();
  }

  //on keyup, start the countdown
  inputkeyUp() {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(this.handleUserInput.bind(this), this.doneTypingInterval);
  };

  //on keydown, clear the countdown 
  inputKeyDown() {
    clearTimeout(this.typingTimer);
  };


  CallFinalFiler() {
    this.page_index=1;
    this.campaignRunLogFilterModel.page=this.page_index;
    this.loadingSpiner = true;
    this.vm.getAllCampaignRunLog(this.campaignRunLogFilterModel, 'campaign-prospect');
  }


  // This method is being called once user hits bottom while scrolling email
  onEmailScroll() {
    if (this.btn_selected === 'Total' && this.tab_selected === 'All') {
      if (this.prospectResponseModel.value.next) {
        this.prospectListB4Search = this.prospectList;
        this.page_index++;
        // this.prospectResponseModel = this.page_index;

        this.vm.getCampaignProspectById(this.campaignId, this.page_index);
      }
    }
    else
      if (this.campaignRunLogResponseModel.value.next) {
        this.campaignRunLogListB4Search = this.campaignRunLogList;
        this.page_index++;
        this.campaignRunLogFilterModel.page=this.page_index;
        this.vm.getAllCampaignRunLog(this.campaignRunLogFilterModel, 'campaign-prospect');
      }

  }

}
