<div class="ms-sm-auto" style="width: 90%;">

  <div class="row sticky-top" style="z-index: 2;">
    <div class="col-12 shadow-1 p-3 bg-white">
      <div class="row">
        
        <div class="col-md-4">
          <h4 class="map-text-header">Edit Prospect</h4> 
      </div>
    
      <div class="col-md-8">
        <div class="d-flex justify-content-end">  
          <button type="button" class="btn btn-link" (click)="redirectToPreviousPage(prospectModel.id)">Cancel</button>
          <div class="ps-2"></div>
          <button type="button" class="btn btn-primary" (click)="updateProspect()">Save changes</button>
        </div> 
      </div>
  
      </div>
      
    </div> 
  </div>


  <div class="row pt-3" style="height: 100vh; overflow-x: hidden; overflow-y: auto; background-color: #FBF7FE;"> 

    <form>

      <div class="row m-2 bg-white p-4 shadow-1">

        <div class="col-4">
          <mdb-form-control>
            <input mdbInput type="text" id="form0" class="form-control" [(ngModel)]="prospectModel.firstname"
              value="prospectModel.firstname" name="firstname" />
            <label mdbLabel class="form-label" for="form0">First Name *</label>
          </mdb-form-control>
        </div>
  
        <div class="col-4">
          <mdb-form-control>
            <input mdbInput type="text" md-autofocus="true" id="prospectmiddlename" class="form-control"
              [(ngModel)]="prospectModel.middlename" value="prospectModel.middlename" name="middlename" />
            <label mdbLabel class="form-label" for="prospectmiddlename">Middle Name</label>
          </mdb-form-control>
        </div>
  
  
        <div class="col-4">
          <mdb-form-control>
            <input mdbInput type="text" md-autofocus="true" id="form1" class="form-control"
              [(ngModel)]="prospectModel.lastname" value="prospectModel.lastname" name="lastname" />
            <label mdbLabel class="form-label" for="form1">Last Name *</label>
          </mdb-form-control>
        </div>
  
        <!-- <div class="col-6 mt-3">
          <mdb-form-control>
            <input mdbInput type="email" [disabled]="true" id="form2"
             class="form-control" [(ngModel)]="prospectModel.email_address" value="prospectModel.email_address" 
             name="email_address" />
            <label mdbLabel class="form-label" for="form2">Email</label>
          </mdb-form-control>[ngModelOptions]="{standalone: true}"
        </div> -->
  
        <div class="col-6 mt-3 d-flex flex-row">
          <div class="input-group mb-3">
            <span class="input-group-text" id="form2"><i class="far fa-envelope"></i></span>
            <input mdbInput class="form-control" id="form2" type="email" [(ngModel)]="prospectModel.email_address"
              aria-label="email_address disabled" 
              [ngModelOptions]="{standalone: true}" />
          </div>
        </div>
  
        <div class="col-6 mt-3">
          <mdb-form-control>
            <input mdbInput type="text" id="form3" class="form-control" [(ngModel)]="prospectModel.company"
              value="prospectModel.company" name="company" />
            <label mdbLabel class="form-label" for="form3">Company *</label>
          </mdb-form-control>
        </div>
  
        <div class="col-6 mt-3">
          <mdb-form-control>
            <input mdbInput type="text" id="form4" class="form-control" [(ngModel)]="prospectModel.job_title"
              value="prospectModel.job_title" name="job_title" />
            <label mdbLabel class="form-label" for="form4">Title *</label>
          </mdb-form-control>
        </div>

        <div class="col-6 mt-3">
          <mdb-form-control>
            <input mdbInput type="text" id="form5" class="form-control" [(ngModel)]="prospectModel.phone"
              value="prospectModel.phone" name="phone" />
            <label mdbLabel class="form-label" for="form5">Phone</label>
          </mdb-form-control>
        </div>
  
        <!-- <div class="col-6 mt-3">
          <ng-select2  [options]="options" [width]="500" [(ngModel)]="value2" class="w-100"
            [ngModelOptions]="{standalone: true}" placeholder="Select Status" >
            <option *ngFor="let i of statuslistItems" [value]="i.id">{{i.text}}</option>
          </ng-select2>
        </div> -->

        <div class="col-6 mt-3">
          <!-- <ng-select [(ngModel)]="regionId" id="form5" 
          class="header-imput w-100" (change)='onRegionSelected()' placeholder="Select Status"> -->
          <ng-select [(ngModel)]="prospectModel.status" id="form5" [ngModelOptions]="{standalone: true}"
          class="w-100" placeholder="Select Status">
            <ng-option *ngFor="let i of statuslistItems" [value]="i.id">{{i.text}}</ng-option>
            </ng-select>
        </div>

        <div class="col-6 mt-3">
          <mdb-form-control>
            <input mdbInput type="text" id="form6" class="form-control" [(ngModel)]="prospectModel.region"
              value="prospectModel.region" name="region" />
            <label mdbLabel class="form-label" for="form6">Region</label>
          </mdb-form-control>
        </div>

        <div class="col-6 mt-3">
          <mdb-form-control>
            <input mdbInput type="text" id="form6" class="form-control" [(ngModel)]="prospectModel.address"
              value="prospectModel.address" name="address" />
            <label mdbLabel class="form-label" for="form6">Address</label>
          </mdb-form-control>
        </div>

        <div class="col-6 mt-3">
          <mdb-form-control>
            <input mdbInput type="text" id="form7" class="form-control" [(ngModel)]="prospectModel.city"
              value="prospectModel.city" name="city" />
            <label mdbLabel class="form-label" for="form7">City</label>
          </mdb-form-control>
        </div>
  
        <div class="col-6 mt-3">
          <mdb-form-control>
            <input mdbInput type="text" id="form8" class="form-control" [(ngModel)]="prospectModel.state"
              value="prospectModel.state" name="state" />
            <label mdbLabel class="form-label" for="form8">State</label>
          </mdb-form-control>
        </div>

        <div class="col-6 mt-3">
          <mdb-form-control>
            <input mdbInput type="text" id="form9" class="form-control" [(ngModel)]="prospectModel.country"
              value="prospectModel.country" name="country" />
            <label mdbLabel class="form-label" for="form9">Country *</label>
          </mdb-form-control>
        </div>
  
        <div class="col-6 mt-3">
          <mdb-form-control>
            <input mdbInput type="text" id="form10" class="form-control" [(ngModel)]="prospectModel.industry"
              value="prospectModel.industry" name="industry" />
            <label mdbLabel class="form-label" for="form10">Industry</label>
          </mdb-form-control>
        </div>

        <div class="col-6 mt-3">
          <mdb-form-control>
            <input mdbInput type="text" id="form11" class="form-control" [(ngModel)]="prospectModel.snippet"
              value="prospectModel.snippet" name="snippet" />
            <label mdbLabel class="form-label" for="form11">Snippet</label>
          </mdb-form-control>
        </div>

        <div class="col-6 mt-3">
          <mdb-form-control>
            <input mdbInput type="text" id="form10" class="form-control" [(ngModel)]="prospectModel.snippet1"
              value="prospectModel.snippet1" name="snippet1" />
            <label mdbLabel class="form-label" for="form10">Snippet 1</label>
          </mdb-form-control>
        </div>

        <div class="col-6 mt-3">
          <mdb-form-control>
            <input mdbInput type="text" id="form11" class="form-control" [(ngModel)]="prospectModel.snippet2"
              value="prospectModel.snippet2" name="snippet2" />
            <label mdbLabel class="form-label" for="form11">Snippet 2</label>
          </mdb-form-control>
        </div>

        <div class="col-6 mt-3">
          <mdb-form-control>
            <input mdbInput type="text" id="form10" class="form-control" [(ngModel)]="prospectModel.snippet3"
              value="prospectModel.snippet3" name="snippet3" />
            <label mdbLabel class="form-label" for="form10">Snippet 3</label>
          </mdb-form-control>
        </div>

        <div class="col-6 mt-3">
          <mdb-form-control>
            <input mdbInput type="text" id="form11" class="form-control" [(ngModel)]="prospectModel.snippet4"
              value="prospectModel.snippet4" name="snippet4" />
            <label mdbLabel class="form-label" for="form11">Snippet 4</label>
          </mdb-form-control>
        </div>

        <div class="col-6 mt-3">
          <mdb-form-control>
            <input mdbInput type="url" id="form12" class="form-control" [(ngModel)]="prospectModel.linkedin"
              value="prospectModel.linkedin" name="linkedin" />
            <label mdbLabel class="form-label" for="form12">Linkedin</label>
          </mdb-form-control>
        </div>
        
        <div class="col-6 mt-3">
          <ng-select2  [options]="options" [width]="500" [(ngModel)]="value1" class="w-100"
            [ngModelOptions]="{standalone: true}" placeholder="Select Prospect Group" >
            <option *ngFor="let i of pglistItems" [value]="i.id">{{i.text}}</option>
          </ng-select2>
        </div>
  
      </div>
  
    </form>


    <!-- Main div end -->
    
  </div>


 