import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { CampaignStepModel } from '../../campaign-model/campaign-model';
import { CampaignViewModel } from '../../campaign-view-model/campaign-view-model';

@Component({
  selector: 'app-step-send-after',
  templateUrl: './step-send-after.component.html',
  styleUrls: ['./step-send-after.component.scss']
})
export class StepSendAfterComponent implements OnInit {

  campaignId: number;
  StepObject: CampaignStepModel;
  currentUser: number;

  daysModel: number;
  hoursModel: number;
  minutesModel: number;
  formError: string = null;

  constructor(
    public modalRef: MdbModalRef<StepSendAfterComponent>,
    private vm: CampaignViewModel,
  ) { }

  ngOnInit(): void {
   if(
    this.StepObject.wait_minutes != undefined || 
    this.StepObject.wait_minutes != null
    ){
      if(this.StepObject.wait_minutes != 0){
        var seconds = this.StepObject.wait_minutes * 60;
        this.daysModel = Math.floor(seconds / (3600*24));
        this.hoursModel = Math.floor(seconds % (3600*24) / 3600);
        this.minutesModel = Math.floor(seconds % 3600 / 60);
      } else {
        console.log("Got 0")
      }
   }
  }

  updateSendAfter(){
    this.formError = ''
    if(!this.daysModel && !this.hoursModel && !this.minutesModel){
      this.formError = "Please enter any (days, hours or minutes)";
    } else if (this.daysModel > 7 || this.hoursModel > 24 || this.minutesModel > 60){
      this.formError = "Inputs can't be greater than (Days - 7, Hours - 24, Minutes - 60) respectively"
    } else {
      var totalMinutes = 0;
      if(this.daysModel){
        totalMinutes = totalMinutes + (this.daysModel * 24 * 60);
      }

      if(this.hoursModel){
        totalMinutes = totalMinutes + (this.hoursModel * 60);
      }

      if(this.minutesModel){
        totalMinutes = totalMinutes + this.minutesModel;
      }
      
      const editCampaignStepModel = new CampaignStepModel;
      editCampaignStepModel.id = this.StepObject.id;
      editCampaignStepModel.body = this.StepObject.body;
      editCampaignStepModel.campaign = this.StepObject.campaign;
      editCampaignStepModel.created_by = this.StepObject.created_by;
      editCampaignStepModel.subject = this.StepObject.subject;
      editCampaignStepModel.modified_by = this.currentUser;
      editCampaignStepModel.modified_at = new Date;
      editCampaignStepModel.wait_minutes = totalMinutes;
      this.vm.parentStepSendAfterComponent = this;
      this.vm.updateCampaignStep(editCampaignStepModel, 'send-after');
    }    
  }

  closePopupAfterUpdate() {
    this.modalRef.close(true);
  }


}
