import { Component, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DomainDncResponseModel, DomainDncModel } from "../dnc-models/dnc-model";
import { DncViewModel } from '../dnc-models/dnc-view-model';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { NgForm } from '@angular/forms';
import { MapCommon } from 'src/app/app-providers/map-base/MapCommon';
import { DncDomainAddComponent } from './dnc-domain-add/dnc-domain-add.component';
import { DncDomainEditComponent } from './dnc-domain-edit/dnc-domain-edit.component';
import { ExportCampaignService } from 'src/app/admin/services/export-campaign.service';
import { MapAPIService } from 'src/app/app-providers/services/map-api-service';

@Component({
  selector: 'app-dnc-domain',
  templateUrl: './dnc-domain.component.html',
  styleUrls: ['./dnc-domain.component.scss'],
  providers: [MapAPIService, DncViewModel, ExportCampaignService],
})

export class DncDomainComponent implements OnInit { 

  domainDncList = [];
  domainDncListB4Search = [];
  totalDncItems: number = 0;
  page_index = 1;
  currentUser: number;
  addDomainDncModalRef: MdbModalRef<DncDomainAddComponent>;
  editDomainDncModalRef: MdbModalRef<DncDomainEditComponent>;
  
  domainDncResponseModel = new BehaviorSubject<DomainDncResponseModel>(
      new DomainDncResponseModel()
  )

  domainDncSearchValue = '';
  @ViewChild(NgForm) domainDncSearchForm: NgForm;

  constructor(private vm: DncViewModel, private modalService: MdbModalService, 
    private exportCampaignService: ExportCampaignService) { 
    this.currentUser = MapCommon.getloggedInUser().id;
    this.vm.parentDncDomainComponent = this;
    this.vm.subscribeDomainDncVariable(this.domainDncResponseModel);
    this.vm.getAllDomainDnc(this.page_index);
  }

  ngOnInit(): void {
  }


  // Multi Select
  isMasterSel: boolean = false;
  includedItems: number[] = [];
  excludedItems: number[] = [];
  selectedItems: number = 0;

  checkUncheckAll(e: any) {
    this.isMasterSel = e.target.checked;
    if (!this.isMasterSel) {
      this.includedItems = [];
      this.excludedItems = [];
    }
    for (var i = 0; i < this.domainDncList.length; i++) {
      this.domainDncList[i].isSelected = this.isMasterSel;
    }    
    this.getCheckedItemList();
  }

  isAllSelected(e: any, itemId: number) {
    this.domainDncList.find(item => item.id == itemId).isSelected = e.target.checked;
    this.getCheckedItemList();
  }

  getCheckedItemList() {
    this.selectedItems = 0;
    this.includedItems = [];
    this.excludedItems = [];
    if(this.isMasterSel){
      this.selectedItems = this.totalDncItems;
      this.excludedItems = this.domainDncList.filter(item => item.isSelected == false).map(data => data.id);
    } else {
      this.selectedItems = this.domainDncList.filter(item => item.isSelected == true).map(data => data.id).length;
      this.includedItems = this.domainDncList.filter(item => item.isSelected == true).map(data => data.id)
    }
    this.selectedItems = this.selectedItems - this.excludedItems.length;
  }


  // Export
  showingExportLoading: boolean = false;

  exportAll(){
    this.showingExportLoading = true;
    this.vm.parentDncDomainComponent = this;
    this.vm.exportDomainDNC(this.domainDncSearchForm.value.search, this.includedItems, this.excludedItems);

  }

  domainDncExported(){
    // this.exportCampaignService.downloadCSV(data, 'domain_dnc_export.csv');
    this.showingExportLoading = false;
  }



  domainDncSearchSubmit(e: any) {
    this.vm.parentDncDomainComponent = this;
    this.vm.searchDomainDnc(this.domainDncSearchForm.value.search)
  }


  domainDncReceived() {
    this.totalDncItems = this.domainDncResponseModel.value.count;
    if (this.domainDncListB4Search.length == 0)
      this.domainDncList = this.domainDncResponseModel.value.results;
    else {
      this.domainDncList = this.domainDncResponseModel.value.results;
      if (this.domainDncResponseModel.value.previous !== null) {
        for (var j = 0; j < this.domainDncList.length; j++) {
          var index = this.domainDncListB4Search.findIndex(x => x.id == this.domainDncList[j].id);
          if (index === -1)
            this.domainDncListB4Search.push(this.domainDncList[j]);
        }
        this.domainDncList = this.domainDncListB4Search;
      }
    }

    if(this.isMasterSel){
      this.domainDncList.forEach(el => el.isSelected = true);
    }
  }

  addDomainDncModal() {
    this.addDomainDncModalRef = this.modalService.open(DncDomainAddComponent);
    this.addDomainDncModalRef.onClose.subscribe((message: any) => {
      if (message === true) {
        this.page_index = 1;
        this.vm.getAllDomainDnc(this.page_index);
      }
    });
  }

  editDomainDncModal(domainDncModel: DomainDncModel){
    domainDncModel.modified_by = this.currentUser;
    const json_data = JSON.stringify(domainDncModel);
    this.editDomainDncModalRef = this.modalService.open(DncDomainEditComponent, { 
      data: { domainDncModel: JSON.parse(json_data),}
    });
    this.editDomainDncModalRef.onClose.subscribe((message: any) => {
      if (message === true) {
        this.page_index = 1;
        this.vm.getAllDomainDnc(this.page_index);
      }
    });
  }

  onDomainDNCScroll()
  {
    if (this.domainDncResponseModel.value.next) {
      this.domainDncListB4Search = this.domainDncList;
      this.page_index++;
      this.vm.getAllDomainDnc(this.page_index);
    }
  }


}
