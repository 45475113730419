// import { ChartDataSets, ChartOptions } from 'chart.js';
// import { Color, Label } from 'ng2-charts';

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MapAPIService } from "src/app/app-providers/services/map-module";
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { MapCommon } from '../../app-providers/map-base/MapCommon';
import {
  AnalyticsClientsModel, AnalyticsClientResponseModel, LinkTracking,
  LinkTrackingResponseModel,
  AnalyticsCampaignResponseModel,
  AnalyticsCampaignModel
} from '../models/model'
import { AnalyticsViewModel } from '../models/viewmodel'
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-link-tracking-analytics',
  templateUrl: './link-tracking-analytics.component.html',
  styleUrls: ['./link-tracking-analytics.component.scss'],
  providers: [DatePipe]
})
export class LinkTrackingAnalyticsComponent implements OnInit {

  @ViewChild("campaignType") campaignType: ElementRef;
  @ViewChild("linkTrackingContainer") linkTrackingContainer: ElementRef;

  typingTimer;                //timer identifier
  doneTypingInterval = 1000;  //time in ms, 5 second for example


  selectedCampaign: string;
  page_index = 1;
  linktrackingList: [LinkTracking];
  clientList: [AnalyticsClientsModel];
  total_lt_count: number = 0;
  search_by_email: string = '';
  search_by_link: string = '';
  search_by_client: number = null;
  search_by_step: string = '';
  fillAutoCampaign: boolean = false;
  isFocused: boolean = false;
  isPasted: boolean = false;
  campaignlist = [];
  tempCampaignlist = [];

  search_start_date: string;

  myDateValue: Date;
  selectedCampaignObj = new AnalyticsCampaignModel();

  linkTrackingResponseModel = new BehaviorSubject<LinkTrackingResponseModel>(
    new LinkTrackingResponseModel()
  );

  clientResponseModel = new BehaviorSubject<AnalyticsClientResponseModel>(
    new AnalyticsClientResponseModel()
  );

  campaignResponseModel = new BehaviorSubject<AnalyticsCampaignResponseModel>(
    new AnalyticsCampaignResponseModel()
  );

  constructor(
    private vm: AnalyticsViewModel, private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.vm.parentLinkTrackingComponent = this;
    this.vm.subscribeAllLinkTrackingVariables(
      this.clientResponseModel,
      this.linkTrackingResponseModel,
      this.campaignResponseModel
    );
    this.vm.getAllCampaign("", 'link-tracking');
    this.vm.getAllClients('link-tracking');
    this.getLinkTrackingList();
  }

  CampaignReceived() {
    this.tempCampaignlist = this.campaignResponseModel.value.results;
    if (this.isFocused) {
      this.campaignlist = this.tempCampaignlist.slice(0, 10);
      // this.campaignlist.unshift({"id":0,"name":"Showing top 10 campaigns"});
      this.isFocused = false;
    }
    else {
      this.campaignlist = this.tempCampaignlist;
      if (this.isPasted) {

        let el: HTMLElement = this.campaignType.nativeElement;
        this.campaignType.nativeElement.blur();
        this.campaignType.nativeElement.focus();
        this.isPasted = false;
      }
    }
    if (this.fillAutoCampaign) {
      this.selectedCampaignObj = this.campaignlist[0];
      // this.selectedCampaignObj.temp_prospect = [0];
      if (Number(this.selectedCampaignObj.id) > 0) {
        this.fillAutoCampaign = false;
      }
    }
    var tempCampaign = localStorage.getItem("selectedCampaign");
    if (tempCampaign !== null) {
      this.selectedCampaign = tempCampaign;
    }
  }
  onPaste(event: any) {
    this.isPasted = true;
  }


  onSelect(event: TypeaheadMatch): void {
    if (event.item.id > 0) {

      this.selectedCampaignObj = event.item;
      this.filterData();

    }
  }

  getLinkTrackingList() {
    this.vm.getAllLinkTracking(
      this.page_index,
      this.search_by_email,
      this.search_by_link,
      this.search_by_client,
      this.selectedCampaignObj.id,
      this.search_by_step,
      this.search_start_date
    )
  }

  linkTrackingReceived() {
    this.linktrackingList = this.linkTrackingResponseModel.value.results;
    this.total_lt_count = this.linkTrackingResponseModel.value.count;
  }

  filterData() {
    // this.page_index = 1;
    // this.getLinkTrackingList();
    // this.linkTrackingContainer.nativeElement.scrollTop = 0;
  }


  searchSubmit(e: any) {
    let tempVar = '' , checkInt
    if( this.search_by_email.length > 3){
    clearTimeout(checkInt)
     checkInt = setTimeout( ()=> {
        if(tempVar.length == e.length){
          this.page_index = 1;
          this.getLinkTrackingList();
        }
      },300)
      setTimeout(() => {
        tempVar = e
      }, 200);

    }

  }


  //on keyup, start the countdown
  inputkeyUp() {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(this.filterData.bind(this), this.doneTypingInterval);
  };

  //on keydown, clear the countdown
  inputKeyDown() {
    clearTimeout(this.typingTimer);
  };

  ClientRecieved() {
    this.clientList = this.clientResponseModel.value.results;
  }

  onLinkTrackingScroll() {
    if (this.linkTrackingResponseModel.value.next) {
      this.page_index++;
      this.getLinkTrackingList();
      this.linkTrackingContainer.nativeElement.scrollTop = 0;
    }
  }
  onValueChange(value: Date): void {
    if (value) {
      this.search_start_date = this.datePipe.transform(value, "YYYY-MM-dd");

    } else {
      this.search_start_date = undefined;
    }

    this.page_index = 1
    this.filterData();
  }

  //call all prospecting group
  changeTypeaheadLoading(event) {
    if (this.selectedCampaign && this.selectedCampaign.length > 2)
      this.vm.getAllCampaign(this.selectedCampaign, 'link-tracking');
  }


}
