import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { BehaviorSubject } from 'rxjs';
import { ExportCampaignService } from 'src/app/admin/services/export-campaign.service';
import { MapCommon } from 'src/app/app-providers/map-base/MapCommon';
import { MapAPIService } from 'src/app/app-providers/services/map-api-service';
import { GlobalDncModel, GlobalDncResponseModel } from '../dnc-models/dnc-model';
import { DncViewModel } from '../dnc-models/dnc-view-model';
import { DncGlobalAddComponent } from './dnc-global-add/dnc-global-add.component';
import { DncGlobalEditComponent } from './dnc-global-edit/dnc-global-edit.component';

@Component({
  selector: 'app-dnc-global',
  templateUrl: './dnc-global.component.html',
  styleUrls: ['./dnc-global.component.scss'],
  providers: [MapAPIService, DncViewModel, ExportCampaignService],
})
export class DncGlobalComponent implements OnInit {

  page_index = 1;
  globalDncList = [];
  globalDncListB4Search = [];
  clientsList = [];
  currentUser: number;
  totalDncItems: number = 0;
  addGlobalDncModalRef: MdbModalRef<DncGlobalAddComponent>;
  editGlobalDncModalRef: MdbModalRef<DncGlobalEditComponent>;
  selectedDncType: number = null; 
  dncSubType = [{"id":"attendee","name":"Attendances"}, {"id": "sponsor","name": "Sponnsorship"}]
  globalDncResponseModel = new BehaviorSubject<GlobalDncResponseModel>(
    new GlobalDncResponseModel()
  );


  globalDncSearchValue = '';
  @ViewChild(NgForm) globalDncSearchForm: NgForm;

  constructor(private vm: DncViewModel, private modalService: MdbModalService,
    private exportCampaignService: ExportCampaignService
    ) {
   
  }

  ngOnInit(): void {
    this.currentUser = MapCommon.getloggedInUser().id;
    this.vm.parentDncGlobalComponent = this;
    this.vm.subscribeGlobalDncVariable(this.globalDncResponseModel);
    this.vm.getAllGlobalDnc(this.page_index);
  }

    // Multi Select
    isMasterSel: boolean = false;
    includedItems: number[] = [];
    excludedItems: number[] = [];
    selectedItems: number = 0;
  
    checkUncheckAll(e: any) {
      this.isMasterSel = e.target.checked;
      if (!this.isMasterSel) {
        this.includedItems = [];
        this.excludedItems = [];
      }
      for (var i = 0; i < this.globalDncList.length; i++) {
        this.globalDncList[i].isSelected = this.isMasterSel;
      }    
      this.getCheckedItemList();
    }
  
    isAllSelected(e: any, itemId: number) {
      this.globalDncList.find(item => item.id == itemId).isSelected = e.target.checked;
      this.getCheckedItemList();
    }
  
    getCheckedItemList() {
      this.selectedItems = 0;
      this.includedItems = [];
      this.excludedItems = [];
      if(this.isMasterSel){
        this.selectedItems = this.totalDncItems;
        this.excludedItems = this.globalDncList.filter(item => item.isSelected == false).map(data => data.id);
      } else {
        this.selectedItems = this.globalDncList.filter(item => item.isSelected == true).map(data => data.id).length;
        this.includedItems = this.globalDncList.filter(item => item.isSelected == true).map(data => data.id)
      }
      this.selectedItems = this.selectedItems - this.excludedItems.length;
    }
  
  
  // Export
  showingExportLoading: boolean = false;

  exportAll(){
    this.showingExportLoading = true;
    this.vm.parentDncGlobalComponent = this;
    this.vm.exportGlobalDNC(this.globalDncSearchForm.value.search, this.includedItems, this.excludedItems);


  }

  domainDncExported(){
    // this.exportCampaignService.downloadCSV(data, 'domain_dnc_export.csv');
    this.showingExportLoading = false;
  }


  globalDncSearchSubmit(e: any) {
    this.vm.parentDncGlobalComponent = this;
    this.vm.searchGlobalDnc(this.globalDncSearchForm.value.search)
  }

  globalDncReceived() {
    this.totalDncItems = this.globalDncResponseModel.value.count;
    if (this.globalDncListB4Search.length == 0)
      this.globalDncList = this.globalDncResponseModel.value.results;
    else {
      this.globalDncList = this.globalDncResponseModel.value.results;
      if (this.globalDncResponseModel.value.previous !== null) {
        for (var j = 0; j < this.globalDncList.length; j++) {
          var index = this.globalDncListB4Search.findIndex(x => x.id == this.globalDncList[j].id);
          if (index === -1)
            this.globalDncListB4Search.push(this.globalDncList[j]);
        }
        this.globalDncList = this.globalDncListB4Search;
      }
    }

    if(this.isMasterSel){
      this.globalDncList.forEach(el => el.isSelected = true);
    }

  }

  openGlobalDncModal() {
    this.addGlobalDncModalRef = this.modalService.open(DncGlobalAddComponent);
  }

 

  editGlobalDncModal(prospectDncModel: GlobalDncModel) {
    prospectDncModel.modified_by = this.currentUser;
    const json_data = JSON.stringify(prospectDncModel);
    this.editGlobalDncModalRef = this.modalService.open(DncGlobalEditComponent, {
      data: { globalDncModel: JSON.parse(json_data), clients: this.clientsList }
    });
    this.editGlobalDncModalRef.onClose.subscribe(message => {
      if (message === true) {
        this.vm.parentDncGlobalComponent = this;
        this.vm.getAllGlobalDnc(this.page_index);
      }
    })
  }

  onGlobalDncScroll() {
    if (this.globalDncResponseModel.value.next) {
      this.globalDncListB4Search = this.globalDncList;
      this.page_index++;
      this.vm.getAllGlobalDnc(this.page_index);
    }
  }

}
