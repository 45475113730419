export class MapAPICollection {

  // prospect operations
  static ADD_PROSPECTS: String = "/prospect";
  static GET_PROSPECTS: String = "/prospect";

  static GET_PROSPECTS_V2: String = "/prospects-v2";


  static UPDATE_PROSPECTS: String = "/prospect";
  static IMPORT_PROSPECTS: String = "/prospect-group";
  static IMPORT_PROSPECT_COL_MATCHING: String = "/prospect-group/validate_file"
  static ADD_DNC_PROSPECT: String = "/dnc-prospect";
  static DELETE_PROSPECT: String = "/prospect/{id}";
  static GET_PROSPECT_BY_ID: String = "/prospect/{id}";
  static DELETE_BULK_PROSPECT: String = "/prospect/bulk_delete";
  static REMOVE_PROSPECT_FROM_CAMPAIGN: String ="/prospect/{id}/campaign_delete";
  static CHECK_PROSPECT_HISTORY ="/campaign/{id}/check_prospect_history";
  static PROSPECT_GROUP_UPLOAD = "/prospect-group-upload/{id}"

  static GET_PROSPECT_HISTORY: String ="/prospect/{id}/get_prospect_log_history";
  // prospect-status

  static GET_PROSPECT_STATUS: String = "/prospect-status";
  static GET_PROSPECT_STATUS_BY_ID: String = "/prospect-status/{id}";


  //prospect-group
  static GET_PROSPECTS_GROUPS: String = "/prospect-group";
  static GET_PROSPECTS_GROUPS_ERRORS: String = "/prospect-group-error-data";
  static DELETE_PROSPECTS_GROUPS: String = "/prospect-group/{id}";
  static POST_PROSPECT_GROUPS_JSON: String = "/prospect-group-upload";

  // campaign operations
  static GET_CAMPAIGN: String = "/campaign";
  static GET_CAMPAIGN_V2: String = "/campaign-v2";
  static UPDATE_CAMPAIGN: String = "/campaign/{id}";
  static DELETE_CAMPAIGN: String = "/campaign/{id}";
  static GET_CAMPAIGN_BY_ID: String = "/campaign/{id}";
  static ADD_CAMPAIGN: String = "/campaign";
  static GET_REPORT_CAMPAIGN_STATS: String = "/report-campaign-stat/{id}";
  static GET_CAMPIAGN_PROSPECTS: String = "/campaign/{id}/prospect_detail";
  static GET_REPORT_CAMPAIGN_MASTER_STAT  ="/report-campaign-master-stat/{id}";
  static DELETE_CAMPAIGN_PROSPECT = "/campaign/{id}/prospect_remove";
  static DUPLICATE_CAMPAIGN: String = "/campaign/{id}/duplicate_campaign";
  static ADD_PROSPECTS_TO_CAMPAIGN: String = "/campaign/{id}/add_prospect_to_campaign"

  // Campaign-review
  static GET_CAMPAIGN_SEND_ALL_MANUAL: String = "/campaign-step/{id}/send_all_manual";
  static GET_CAMPAIGN_SEND_ONE_MANUAL: String = "/campaign-runlog/{id}/send_one_manual";

  // campiagn-runlog
  static GET_REPORT_CAMPAIGN_RUNLOG_STATS: String ="/report-campaign-runlog-stat";
  static GET_REPORT_CAMPAIGN_RUN_LOG_STATS: String ="/report-campaign-runlog-stat/{id}";
  static GET_CAMPAIGN_RUNLOG: String = "/campaign-runlog";
  static PUT_CAMPAIGN_RUNLOG: String = "/campaign-runlog/{id}";
  static DELETE_CAMPAIGN_RUNLOG: String = "/campaign-runlog/{id}";
  static PATCH_CAMPAIGN_RUNLOG: String = "/campaign-runlog/{id}";
  static ADD_REPLY: String = "/campaign-runlog/sendreply";

 // campiagn-replylog
 static GET_REPORT_CAMPAIGN_REPLYLOG_STATS: String ="/report-campaign-reply-stat";
 static GET_REPORT_CAMPAIGN_REPLY_LOG_STATS: String ="/report-campaign-reply-stat/{id}";

  // campiagn-report
  static GET_CAMPAIGN_REPORT_STATS: String ="/report-all-campaign-stat";
  static GET_CAMPAIGN_REPORT_STATS_NEW: String ="/campaign-report";

  // Campaign Steps
  static GET_CAMPAIGN_STEPS: String =  "/campaign-step";
  static POST_CAMPAIGN_STEP: String = "/campaign-step";
  static PUT_CAMPAIGN_STEP: String = "/campaign-step";
  static DELETE_CAMPAIGN_STEP: String = "/campaign-step/{id}";
  static POST_SEND_TEST_EMAIL: String = "/campaign-step/{id}/send_test";
  static GET_CAMPAIGN_STEP: String = "/campaign-step/{id}";
  static POST_PAUSE_RESUME_CANCEL: String = "/campaign-step/{id}/update_step_processing_status";
  static STEP_ATTACHMENT: String = "/campaign-step-media";
  static GENERATE_AB_STEPS: String = "/campaign-step/addvariants";
  // static GENERATE_AB_STEPS_new: String = "/chatgpt/subjectbody?step_id=323&variations=4";



  //campaign-followup-condition
  static GET_FOLLOWUP_CONSITIONS: String = "/campaign-followup-condition";

  // Clients
  static GET_CLIENTS: String = "/client";
  static POST_CLIENTS: String = "/client";
  static DELETE_CLIENTS: String = "/client";
  static UPDATE_CLIENTS: String = "/client";
  static GET_CLIENTS_BY_ID: String = "/client/{id}";

  // setting-mailbox
  static GET_USER_EXTRA: String = "/user-extra";
  static PUT_USER_EXTRA: String = "/user-extra";
  static GET_MAILBOX_EXT_CONNECTION: String = "/mailbox-ext-connection";

  // setting-sending-windows
  static GET_SENDING_WINDOWS: String = "/schedule";
  static PUT_SENDING_WINDOWS: String = "/schedule";
  static POST_SENDING_WINDOWS: String = "/schedule";
  static DELETE_SENDING_WINDOWS: String = "/schedule";

  // Schedule Timezone
  static GET_SCHEDULE_TIMEZONE: String = "/schedule-timezone";

  // Setting-dnc
  static GET_COMPANY_DNC: String = "/dnc-company";
  static PUT_COMPANY_DNC: String = "/dnc-company";
  static DELETE_COMPANY_DNC: String = "/dnc-company";
  static POST_COMPANY_DNC: String = "/dnc-company";
  static GET_PROSPECT_DNC: String = "/dnc-prospect";
  static PUT_PROSPECT_DNC: String = "/dnc-prospect";
  static DELETE_PROSPECT_DNC: String = "/dnc-prospect";
  static POST_PROSPECT_DNC: String = "/dnc-prospect";
  static IMPORT_DNC: String = "/dnc-prospect/import_dnc";

  // Setting-group
  static DELETE_SETTINGS_GROUPS: String = "/prospect-group";

 // mailbox-connection
 static CONNECT_MAILBOX: String = "/mailbox-connection/connect_mailbox";
 static DISCONNECT_MAILBOX: String = "/mailbox-connection/{id}"
 static GET_MAILBOX: String = "/mailbox-connection/{id}"

//  user
 static GET_ALL_USERS: String = "/user-account";
 static GET_USER_BY_ID: String = "/user-account/{id}";

//  Login
static LOGIN_USER: String = '/login';
static POST_PASSWORD_RESET: String = '/reset-user-password-validation/send_password_reset_request';
static POST_PASSWORD_CONFIRM: String = '/reset-user-password-validation/request_validation';
static POST_TOKEN_VALIDATION: String = '/reset-user-password-validation/validate_request_url';


//Dashboard
static GET_ALL_DASHBOARD_ENTITIES: String = "/dashboard/overview";

//Template
static GET_ALL_TEMPLATE: String = "/template-record";
static GET_ALL_SUBJECT: String = "/email-subject";
static POST_TEMPLATE: String = "/template-record";
static EDIT_TEMPLATE: String = "/template-record/{id}";
static DELETE_TEMPLATE: String = "/template-record/{id}";

// Unsubscribe
static UNSUBSCRIBE: String = '/campaign-step-optout';

// Link Tracking
static LINK_TRACKING: String = '/campaign-step-track-link';
// Analytics
static GET_ANALYTICS_LINK_TRACKING: String = '/campaign-step-link';
static GET_ANALYTICS_OPEN_TRACKING: String = '/campaign-track-open';
// Best Sending Time
static GET_BEST_SENDING_TIME: String = '/campaign-runlog/best_time_to_send_email';

static EXPORT_PROSPECTS: String = '/export/prospect';
static EXPORT_RUNLOGS: String = '/export/campaignrunlog';
static EXPORT_DOMAIN_DNC: String = "/export/company_dnc";
static EXPORT_PROSPECT_DNC: String = "/export/prospect_dnc";

// Region
static GET_REGIONS: String = '/region';


// Release Note
static GET_RELEASE_NOTE: String = '/releasenote';

static GET_PROSPECT_EMAIL:  String = '/prospect/email?id={id}';

}

