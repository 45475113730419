import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { UserResponseModel, ProspectGroupResponseModel,ProspectModel } from './import-model/import-model';
import { ImportViewModel } from './import-view-model/import-view-model';
import { MapAPIService } from "src/app/app-providers/services/map-module";
import { BehaviorSubject, Subject } from 'rxjs';
import { ConfirmDialogService } from '../admin/confirm-dialog/confirm-dialog.service';
;
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FormArray, FormBuilder, FormGroup,FormsModule } from '@angular/forms';
import { AlertNotificationsComponent } from '../Alerts/alert-notifications/alert-notifications.component';
@Component({
  selector: 'app-imports',
  templateUrl: './imports.component.html',
  styleUrls: ['./imports.component.scss'],
  providers: [MapAPIService, ImportViewModel, FormsModule],
})
export class ImportsComponent implements OnInit {
  prospectGroupList = [];
  userList = [];
  statsAutoUpdateInterval:any
  static importsCall;
  userResponseModel = new BehaviorSubject<UserResponseModel>(
    new UserResponseModel()
  );
  prospectGroupModel = new BehaviorSubject<ProspectGroupResponseModel>(
    new ProspectGroupResponseModel()
  );
  prospectModel = new ProspectModel();

  selectedText: string = "";
  createUserId:number = null;
  page_index = 1;
  prospectGroupListB4Search = []
  duplicateMsg:boolean
  errorMsg:boolean
  duplicateInMap:boolean
  duplicateMsgList = []
  duplicateInMapList= []
  errorInFileList = []
  selectedProspectGroupName:number
  EditErrorProspect:boolean = false

  constructor(private vm: ImportViewModel,  private confirmDialogService: ConfirmDialogService,private fb:FormBuilder,private router: Router) {
    ImportsComponent.importsCall = this;
   }

  ngOnInit(): void {
    this.vm.parentImportsComponent = this;
    this.vm.subscribeImportProspectGroupVariables(this.prospectGroupModel);
    this.vm.subscribeUserImportVariables(this.userResponseModel);
    this.vm.getAllProspectGroupsForImport(this.selectedText, this.createUserId, this.page_index);
    this.vm.getAllUsers();
    this.startAutoRefreshStats();
  }

  ngOnDestroy():void {
    this.stopAutoRefreshStats();
  }



  // DUPLICATE_RECORDS_IN_FILE = 1
  // DUPLICATE_RECORDS_IN_DATABASE = 2
  // INVALID_RECORDS = 3

  closeModal(){
    this.duplicateMsg = false
    this.errorMsg = false
    this.duplicateInMap = false
    this.EditErrorProspect = false
    this.prospectModel =  new ProspectModel();
    localStorage.removeItem('create-prospect-from-error')
    localStorage.removeItem('selectedProspectGroupName')
  }

  showDupModal(count,id){
    console.log(count)
    if(count > 0) {
      this.duplicateMsg = true
      this.vm.getAllProspectGroupsErrorData(id,1)
    }

  }

  copyToClipBoard(data:any){
    navigator.clipboard.writeText(data);
    AlertNotificationsComponent.alertCall.showAlert(`${data} copied to clipboard`, 'primary');
  }

  createErrorProspectAfterEditing(data:any){
    let dataSt = JSON.stringify(data)
    localStorage.setItem('create-prospect-from-error',dataSt)
    localStorage.setItem('selectedProspectGroupName',`${this.selectedProspectGroupName}` )
    this.EditErrorProspect = true
  }


  showErrorModal(count,id){

    if(count > 0){
      this.errorMsg = true
      this.selectedProspectGroupName = id
      this.vm.getAllProspectGroupsErrorData(id,3)
    }

  }

  showDupInMapModal(count,id){

    if(count > 0){
      this.duplicateInMap = true
      this.vm.getAllProspectGroupsErrorData(id,2)
    }
  }

  errorDataReceived(data,errType){
    this.duplicateInMapList= []
    this.duplicateMsgList = []
    this.errorInFileList= []
    var data = data.results;
    data.forEach(element => {

      var str  = element.err_data;
      str = str.replace(/'/g, '"')
      var obj = JSON.parse(str);
      obj.id = element.id
      if(errType == 2){
        this.duplicateInMapList.push(obj)
      } else if(errType == 1){
        this.duplicateMsgList.push(obj)
      } else{
        this.errorInFileList.push(obj)
      }
      console.log(obj);
    });


  }

  startAutoRefreshStats(){
    this.statsAutoUpdateInterval = setInterval(() => {
      this.getAllProspectGroups();
      console.log("%c Prospect group Stats updated: PID %s",
      'color: white; background: green; font-size: 10px',
      this.statsAutoUpdateInterval)
      }, 15000);
  }
  stopAutoRefreshStats(){
    if (this.statsAutoUpdateInterval) {
      clearInterval(this.statsAutoUpdateInterval);
      console.log("%cProspect group Auto update destroyed: PID %s",
      'color: white; background: red; font-size: 10px',
      this.statsAutoUpdateInterval)
    }
  }

  @HostListener('window:visibilitychange', ['$event'])
  visibilityChange(event: FocusEvent): void {
    console.log("Window hidden status = > ", document.hidden, " = >", document.visibilityState);
    if(document.hidden === true){
      console.log( "Window Hidden");
      this.stopAutoRefreshStats();
    } else {
      console.log("Window Focused");
      this.startAutoRefreshStats();
    }
  }

  filterGroupsOnProspects(name){
    localStorage.setItem("selectdProspectGroup", name);
    this.router.navigate(['/prospects'])
  }



  UserReceived() {
    this.userList = this.userResponseModel.value.results;
  }

  getAllProspectGroups(){
    this.vm.getAllProspectGroupsForImport(this.selectedText, this.createUserId, this.page_index);
  }


  refreshAfterDelete()
  {
    this.getAllProspectGroups();
  }
  onUserSelected()
  {
    this.getAllProspectGroups();
  }
  handleSearchInput(inputValue)
  {
    this.selectedText = inputValue;
    this.getAllProspectGroups();
  }



  deleteImportGroup(inputObj)
  {
    this.confirmDialogService.confirmThis("Are you sure you to delete (" + inputObj.name +  ")?", function () {
      ImportsComponent.importsCall.vm.deleteProspectGroup(inputObj.id);
    }, function () {

    });

  }

  getUserName(id) {
    var userItem = this.userList.filter(i => i.id == Number(id));
    if (userItem.length > 0) {
      if(userItem[0].email?.length > 0) {
        return userItem[0].email;
      } else {
        if(userItem[0].username?.length > 0){
          return userItem[0].username
        }
      }
    }
    return ""
  }

  getTime(value) {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (seconds < 29) // less than 30 seconds ago will show as 'Just now'
        return 'Just now';
      const intervals = {
        'year': 31536000,
        'month': 2592000,
        'week': 604800,
        'day': 86400,
        'hour': 3600,
        'minute': 60,
        'second': 1
      };
      let counter;
      for (const i in intervals) {
        counter = Math.floor(seconds / intervals[i]);
        if (counter > 0)
          if (counter === 1) {
            return counter + ' ' + i + ' ago'; // singular (1 day ago)
          } else {
            return counter + ' ' + i + 's ago'; // plural (2 days ago)
          }
      }
    }
    return value;
  }


  onImportScroll(){
    if (this.prospectGroupModel.value.next) {
      this.prospectGroupListB4Search = this.prospectGroupList;
      this.page_index++;
      this.getAllProspectGroups();
    }

  }

  getDownloadUrl(media){
    var url = ''
    if(media?.length > 0) {
      return url + environment.base_api_url + media
    }


  }

  ProspectGroupReceived() {
    if (this.prospectGroupListB4Search.length == 0)
    this.prospectGroupList = this.prospectGroupModel.value.results;
    else {
      this.prospectGroupList = this.prospectGroupModel.value.results;
      if (this.prospectGroupModel.value.previous !== null) {
        for (var j = 0; j < this.prospectGroupList.length; j++) {
          var index = this.prospectGroupListB4Search.findIndex(x => x.id == this.prospectGroupList[j].id);
          if (index === -1)
            this.prospectGroupListB4Search.push(this.prospectGroupList[j]);
        }
        this.prospectGroupList = this.prospectGroupListB4Search;
      }
    }

    this.prospectGroupList.forEach(element => {
      if(element['status'] == 1){
        element['status_text'] = "Processing"
      }
      else if(element['status'] == 2){
        element['status_text'] = "Completed"
      } else {
        element['status_text'] = 'Api Error'
      }
    });
  }

}
