

<div class="ms-sm-auto" style="width: 90%;">

<div class="row" style="height: 100%;">


  <div *ngIf="!editPrevProspect" class="col-2 shadow-3" style="background: #FBF7FE; height: 100vh; overflow-x: hidden; overflow-y: auto; ">

    <app-sidebar-import></app-sidebar-import>

  </div>


  <div class="col-10" [ngClass]="{'p-3':editPrevProspect,'p-5':!editPrevProspect}">

    <h3 *ngIf="!editPrevProspect" >Create Prospect</h3>
    <h3 *ngIf="editPrevProspect" >Re-Create Prospect after correcting errors!</h3>

    <hr>

    <form >

      <div class="row">
        <div class="col">
          <mdb-form-control>
            <input mdbInput type="text" id="inputFirst" (change)="nameChange()" [(ngModel)]="prospectModel.firstname" name="firstname"
              class="form-control" [ngClass]="{ 'is-invalid': nameValidate }"  />
            <label mdbLabel class="form-label" for="form1">First Name *</label>
          </mdb-form-control>
          <small class="text-danger" *ngIf="nameValidate"> Please provide your name! </small>
        </div>
        <div class="col">
          <mdb-form-control>
            <input mdbInput type="text" id="inputLast" [(ngModel)]="prospectModel.middlename" name="middlename"
              class="form-control" />
            <label mdbLabel class="form-label" for="form1">Middle Name</label>
          </mdb-form-control>
        </div>
        <div class="col">
          <mdb-form-control>
            <input mdbInput type="text" id="inputLast" [(ngModel)]="prospectModel.lastname" name="lastname"
              class="form-control" />
            <label mdbLabel class="form-label" for="form1">Last Name *</label>
          </mdb-form-control>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <mdb-form-control>
            <input mdbInput type="email" id="inputEmail" (change)="emailChange()"  [(ngModel)]="prospectModel.email_address" name="email_address"
              class="form-control" [ngClass]="{ 'is-invalid': emailValidate }" />
            <label mdbLabel class="form-label" for="form1">Email *</label>
          </mdb-form-control>
          <small class="text-danger" *ngIf="emailValidate"> Please provide a valid email address! </small>
        </div>
        <div class="col">
          <mdb-form-control>
            <input mdbInput type="text" id="inputPhone" [(ngModel)]="prospectModel.phone" name="phone"
              class="form-control" />
            <label mdbLabel class="form-label" for="form1">Phone</label>
          </mdb-form-control>

        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <mdb-form-control>
            <input mdbInput type="text" id="inputTitle" [(ngModel)]="prospectModel.job_title" name="job_title"
              class="form-control" />
            <label mdbLabel class="form-label" for="form1">Title</label>
          </mdb-form-control>
        </div>

        <div class="col">
          <mdb-form-control>
            <input mdbInput type="text" id="inputCompany" [(ngModel)]="prospectModel.company" name="company"
              class="form-control" />
            <label mdbLabel class="form-label" for="form1">Company</label>
          </mdb-form-control>
        </div>
      </div>


      <div class="row mt-2">
        <div class="col">
          <mdb-form-control>
            <input mdbInput type="text" id="inputAddress" [(ngModel)]="prospectModel.address" name="address"
              class="form-control" />
            <label mdbLabel class="form-label" for="form1">Address</label>
          </mdb-form-control>
        </div>
        <div class="col">
          <mdb-form-control>
            <input mdbInput type="text" id="inputCity" [(ngModel)]="prospectModel.city" name="city"
              class="form-control" />
            <label mdbLabel class="form-label" for="form1">City</label>
          </mdb-form-control>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <mdb-form-control>
            <input mdbInput type="text" id="inputState" [(ngModel)]="prospectModel.state" name="state"
              class="form-control" />
            <label mdbLabel class="form-label" for="form1">State/Province</label>
          </mdb-form-control>
        </div>
        <!-- <div class="col">
          <mdb-form-control>
            <input mdbInput type="text" id="inputRegion" [(ngModel)]="prospectModel.region" name="region"
              class="form-control" />
            <label mdbLabel class="form-label" for="form1">Region</label>
          </mdb-form-control>
        </div> -->
        <div class="col">
          <mdb-form-control>
            <input mdbInput type="text" id="inputCountry" [(ngModel)]="prospectModel.country" name="country"
              class="form-control" />
            <label mdbLabel class="form-label" for="form1">Country</label>
          </mdb-form-control>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <mdb-form-control>
            <input mdbInput type="text" id="inputIndustry" [(ngModel)]="prospectModel.industry" name="industry"
              class="form-control" />
            <label mdbLabel class="form-label" for="form1">Industry</label>
          </mdb-form-control>
        </div>
        <div class="col">
          <mdb-form-control>
            <input mdbInput type="text" id="inputSnippet" [(ngModel)]="prospectModel.snippet" name="snippet"
              class="form-control" />
            <label mdbLabel class="form-label" for="form1">Snippet</label>
          </mdb-form-control>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <mdb-form-control>
            <input mdbInput type="text" id="inputSnippet1" [(ngModel)]="prospectModel.snippet1" name="snippet1"
              class="form-control" />
            <label mdbLabel class="form-label" for="form1">Snippet1</label>
          </mdb-form-control>
        </div>
        <div class="col">
          <mdb-form-control>
            <input mdbInput type="text" id="inputSnippet2" [(ngModel)]="prospectModel.snippet2" name="snippet2"
              class="form-control" />
            <label mdbLabel class="form-label" for="form1">Snippet2</label>
          </mdb-form-control>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <mdb-form-control>
            <input mdbInput type="text" id="inputSnippet3" [(ngModel)]="prospectModel.snippet3" name="snippet3"
              class="form-control" />
            <label mdbLabel class="form-label" for="form3">Snippet3</label>
          </mdb-form-control>
        </div>
        <div class="col">
          <mdb-form-control>
            <input mdbInput type="text" id="inputSnippet4" [(ngModel)]="prospectModel.snippet4" name="snippet4"
              class="form-control" />
            <label mdbLabel class="form-label" for="form1">Snippet4</label>
          </mdb-form-control>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <mdb-form-control>
            <input mdbInput type="url" id="inputLinkedin" [(ngModel)]="prospectModel.linkedin" name="linkedin"
              class="form-control" />
            <label mdbLabel class="form-label" for="form1">Linkedin</label>
          </mdb-form-control>
        </div>

        <div class="col">
          <input [(ngModel)]="selectedProspectGroupName" [disabled]="editPrevProspect" (typeaheadOnSelect)="onSelect($event)"
            (typeaheadLoading)="changeTypeaheadLoading($event)" [typeaheadMinLength]="3" [typeahead]="prospectGroupList"
            typeaheadOptionField="name" class="form-control" name="Prospect Group" type="text"
            placeholder="Prospect Group" />
          <span class="highlight"></span>
        </div>
      </div>


      <div class="row">
        <div class="col">
          <button type="submit" class="btn btn-primary mt-2" (click)="submit()">Create</button>
        </div>
      </div>

    </form>

  </div>

</div>

</div>
