import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar-settings',
  templateUrl: './sidebar-settings.component.html',
  styleUrls: ['./sidebar-settings.component.scss']
})
export class SidebarSettingsComponent implements OnInit {


  ST: string = '';
  showType(st: string) {
    this.ST = st;
  }
  
  constructor() { }

  ngOnInit(): void {
  }

}
