import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { MapCommon } from 'src/app/app-providers/map-base/MapCommon';
import { LoginUserModel } from 'src/app/login/models/login-model';
import { EmailSenderResponseModel, UserExtraModel } from '../setting-model/setting-model';
import { SettingViewModel } from '../setting-view-model/setting-view-model';
import { environment } from '../../../environments/environment';
import { AlertNotificationsComponent } from 'src/app/Alerts/alert-notifications/alert-notifications.component';




@Component({
  selector: 'app-settings-mailbox',
  templateUrl: './settings-mailbox.component.html',
  styleUrls: ['./settings-mailbox.component.scss']
})
export class SettingsMailboxComponent implements OnInit {

  editormodules: any;
  Optouteditormodules: any;
  editorAPIKey: string;
  userExtraData: UserExtraModel;
  currentUser: LoginUserModel;
  signatureModel: string = '';
  optOutStatementModel: string = '';
  isOptOutEnabledModel: boolean = false;
  isSignatureEnabledModel: boolean = false;
  trackingModel: boolean = false;
  linkTrackingModel: boolean = false;
  connectedMailbox: string;
  connectedMailboxId: number;
  senderNameModel: string = '';

  savingSpiner: boolean = false;

  mailboxSenderSelectError: string = null;
  mailBoxDisconnectError: string = null;
  @ViewChild(NgForm) mailboxConnectForm: NgForm;
  selectedSenderModel: number = 0;
  emailSenderList = [];

  userExtraModel = new BehaviorSubject<UserExtraModel>(
    new UserExtraModel()
  );

  emailSenderListModel = new BehaviorSubject<EmailSenderResponseModel>(
    new EmailSenderResponseModel()
  );


  constructor(private vm:SettingViewModel, private router: Router) {
    this.editormodules = MapCommon.getSettingMailboxEditorModule();
    this.Optouteditormodules = MapCommon.getSettingMailboxEditorModule('opt-out');
    this.editorAPIKey = MapCommon.getTinyEditorAPIKey();
    this.currentUser = MapCommon.getloggedInUser(); 
    this.vm.parentMailboxComponent = this;
    this.vm.subscribeUserExtraVariable(this.userExtraModel);
    this.vm.getUserExtra(this.currentUser.id);
    this.vm.subscribeEmailSenderVariable(this.emailSenderListModel);
    this.vm.getEmailSender();


    this.connectedMailbox = localStorage.getItem('map_connected_mailbox');
    const selectedMailbox = localStorage.getItem('map_selected_mailbox');
    this.connectedMailboxId = Number(localStorage.getItem('map_connection_id'));
    this.checkMailboxConnectionStatus();

    if (!this.connectedMailbox) {
      const currentURL = window.location.href;
      if (currentURL.includes('mailbox#code=') && currentURL.includes('116898175893-tvqtmg8ekmaehakg4q460tt7atlnq8c8.apps.googleusercontent.com')) {
        const startIX = currentURL.indexOf('code') + 5;
        const endIX = currentURL.indexOf('&');
        const authCode = currentURL.substring(startIX, endIX);
        const requestObj = {
          auth_code: authCode,
          mailbox_provider: 'Google',
          email: selectedMailbox,
          created_by: this.currentUser.id
        };

        this.vm.connectMailbox(requestObj);

      } else if (currentURL.includes('mailbox?code=')) {
        const startIX = currentURL.indexOf('code') + 5;
        const endIX = currentURL.indexOf('&');
        const authCode = currentURL.substring(startIX, endIX);
        const requestObj = {
          auth_code: authCode,
          mailbox_provider: 'Microsoft',
          email: selectedMailbox,
          created_by: this.currentUser.id
        };

        this.vm.connectMailbox(requestObj);
      }
    }
   }

  ngOnInit(): void {

  }


  checkMailboxConnectionStatus(){
    if (this.connectedMailbox) {
      this.vm.getMailboxConnection(this.connectedMailboxId);
    }
  }

  errorFoundWhileMailboxConnectionCheck(){
    try {
      localStorage.removeItem('map_connected_mailbox');
    } catch {

    }
    try {
      localStorage.removeItem('map_connection_id');
    }catch {

    }
    this.connectedMailbox = undefined;
    this.connectedMailboxId = undefined;
  }

 EmailSenderReceived(){
   this.emailSenderList = this.emailSenderListModel.value.results;
   this.emailSenderList = this.emailSenderList.filter(obj => {
        return (obj.email_address === this.currentUser.email || obj.email_address === this.currentUser.username);
   })
 }

  UserExtraReceived() {
    this.userExtraData = this.userExtraModel.value;
    this.signatureModel = this.userExtraData.email_signature;
    this.optOutStatementModel = this.userExtraData.opt_out_message;
    this.isOptOutEnabledModel = this.userExtraData.is_opt_out_enabled;
    this.trackingModel = this.userExtraData.is_tracking_enabled;
    this.linkTrackingModel = this.userExtraData.is_link_tracking_enabled;
    this.senderNameModel = this.userExtraData.email_sender_name;
    this.isSignatureEnabledModel = this.userExtraData.is_signature_enabled;
    this.savingSpiner = false;
  }

  updateUserExtra(){
    this.savingSpiner = true;
    this.vm.updateUserExtra(this.userExtraData);
  }

  saveDailySendingLimit(sendingLimit: number){
    this.userExtraData.send_limit_daily = Number(sendingLimit);
    this.userExtraData.modified_at = new Date();
    this.updateUserExtra();
  }

  saveSignature(){
    this.userExtraData.email_signature = String(this.signatureModel);
    this.userExtraData.is_signature_enabled = this.isSignatureEnabledModel;
    this.userExtraData.modified_at = new Date();
    this.userExtraData.modified_by = this.currentUser.id;
    this.updateUserExtra();
  }

  saveSenderName(){
    this.userExtraData.email_sender_name = String(this.senderNameModel);
    this.userExtraData.modified_at = new Date();
    this.userExtraData.modified_by = this.currentUser.id;
    this.updateUserExtra();
  }

  saveOptOutStatement() {

    var error = "There can be one opt-out URL in this section."; 
    console.log(this.optOutStatementModel.split(' '))
    if(this.optOutStatementModel.includes('{{Opt-Out-Url}}') && 
    ((this.optOutStatementModel.split(' ').filter(e => e.includes("{{Opt-Out-Url}}") ).length == 1))){
      this.userExtraData.opt_out_message = String(this.optOutStatementModel);
      this.userExtraData.is_opt_out_enabled = this.isOptOutEnabledModel;
      this.userExtraData.modified_at = new Date();
      this.userExtraData.modified_by = this.currentUser.id;
      this.updateUserExtra();
    } else { 
      AlertNotificationsComponent.alertCall.showAlert(error, 'danger');
    }
 
    
  }

  saveTracking(){
    this.userExtraData.is_tracking_enabled = this.trackingModel;
    this.userExtraData.is_link_tracking_enabled = this.linkTrackingModel;
    this.userExtraData.modified_at = new Date();
    this.userExtraData.modified_by = this.currentUser.id;
    console.log(this.userExtraData)
    this.updateUserExtra();

  }

  connectWithGoogle(selected_mailbox: string): void {
    localStorage.setItem('map_selected_mailbox', selected_mailbox);
    window.open('https://accounts.google.com/o/oauth2/auth/oauthchooseaccount'
    + '?redirect_uri=' + environment.oauth_login_redirect
    + '&client_id=116898175893-tvqtmg8ekmaehakg4q460tt7atlnq8c8.apps.googleusercontent.com'
    + '&response_type=code permission id_token'
    + '&scope=email profile openid https://mail.google.com/ https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/gmail.send'
    + '&ss_domain=' + environment.oauth_login_redirect
    + '&access_type=offline'
    + '&include_granted_scopes=true'
    + '&prompt=consent'
    + '&origin=' + environment.base_app_url
    // + '&gsiwebsdk=2'
    + '&service=lso'
    + '&o2v=1'
    + '&flowName=GeneralOAuthFlow', '_self');
    // window.open('https://accounts.google.com/o/oauth2/auth/oauthchooseaccount'
    //   + '?redirect_uri=' + environment.oauth_login_redirect
    //   + '&client_id=116898175893-tvqtmg8ekmaehakg4q460tt7atlnq8c8.apps.googleusercontent.com'
    //   + '&response_type=code permission id_token'
    //   + '&scope=profile email openid https://mail.google.com/'
    //   + '&ss_domain=' + environment.oauth_login_redirect
    //   + '&access_type=offline'
    //   + '&include_granted_scopes=true'
    //   + '&prompt=consent'
    //   + '&origin=' + environment.base_app_url
    //   + '&gsiwebsdk=2'
    //   + '&flowName=GeneralOAuthFlow', '_self');
    // window.open('https://accounts.google.com/o/oauth2/auth/oauthchooseaccount'
    //   + '?redirect_uri=https://map-dev.buyerforesight.net/settings/mailbox'
    //   + '&client_id=116898175893-tvqtmg8ekmaehakg4q460tt7atlnq8c8.apps.googleusercontent.com'
    //   + '&response_type=code permission id_token'
    //   + '&scope=profile email openid https://mail.google.com/'
    //   + '&ss_domain=https://map-dev.buyerforesight.net/settings/mailbox'
    //   + '&access_type=offline'
    //   + '&include_granted_scopes=true'
    //   + '&prompt=consent'
    //   + '&origin=https://map-dev.buyerforesight.net'
    //   + '&gsiwebsdk=2'
    //   + '&flowName=GeneralOAuthFlow', '_self');
  }

  connectWithMicrosoft(selected_mailbox: string): void {
    localStorage.setItem('map_selected_mailbox', selected_mailbox);
    window.open('https://login.microsoftonline.com/common/oauth2/v2.0/authorize'
      + '?client_id=59e81158-e62d-4b86-96cb-362cb0ca4c4a'
      + '&response_type=code'
      + '&redirect_uri=' + environment.oauth_login_redirect
      + '&response_mode=query'
      + '&scope=profile email openid mail.readwrite mail.send user.read offline_access'
      + '&prompt=consent'
      + '&code_challenge=Nr3RxVS7s7cL56S04p24l7bQG7cRJqN2SFO862l1H5FvvDsVbW', '_self');
    // window.open('https://login.microsoftonline.com/common/oauth2/v2.0/authorize'
    //   + '?client_id=59e81158-e62d-4b86-96cb-362cb0ca4c4a'
    //   + '&response_type=code'
    //   + '&redirect_uri=https://map-dev.buyerforesight.net/settings/mailbox'
    //   + '&response_mode=query'
    //   + '&scope=profile email openid mail.readwrite mail.send user.read offline_access'
    //   + '&prompt=consent'
    //   + '&code_challenge=Nr3RxVS7s7cL56S04p24l7bQG7cRJqN2SFO862l1H5FvvDsVbW', '_self');
  }


  userConnectMailbox(e: any){
    if(!this.mailboxConnectForm.value.selectedSender){
      this.mailboxSenderSelectError = "Please select an email sender";
    } else {
      const emailSenderNewModel = this.emailSenderList.find( x => { return x.id === Number(this.selectedSenderModel)});
      if (emailSenderNewModel.host === 'Microsoft') {
          this.connectWithMicrosoft(emailSenderNewModel.email_address);
      } else if (emailSenderNewModel.host === 'Google') {
        this.connectWithGoogle(emailSenderNewModel.email_address);
      } else {
        console.log("Dont play with this application...");
      }
    }

   }

  connectedMailBoxReceived(){
    this.connectedMailbox = localStorage.getItem('map_connected_mailbox');
    this.connectedMailboxId = Number(localStorage.getItem('map_connection_id'));
    this.router.navigate([]); // clear all params from url
  }

  disconnectedMailBoxReceived(){
    this.connectedMailbox = localStorage.getItem('map_connected_mailbox');
    this.connectedMailboxId = Number(localStorage.getItem('map_connection_id'));
    this.vm.getEmailSender();
  }


  disconnectConnectedMailbox() {
    if(this.connectedMailboxId){
      this.vm.disconnectMailbox(this.connectedMailboxId);
      this.connectedMailbox = localStorage.getItem('map_connected_mailbox');
      this.connectedMailboxId = Number(localStorage.getItem('map_connection_id'));
    } else {
      this.mailBoxDisconnectError = "Can't disconnect, please logout and retry again";

    }
  }



}
