import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ClientResponseModel, DNCModel } from '../model/prospect-model';
import { ProspectViewModel } from '../viewModel/prospect-viewmodel';
import { MapAPIService } from "src/app/app-providers/services/map-module";
import { BehaviorSubject } from 'rxjs';
import { ProspectModel } from 'src/app/imports/import-model/import-model';
@Component({
  selector: 'app-mark-dnc',
  templateUrl: './mark-dnc.component.html',
  styleUrls: ['./mark-dnc.component.scss'],
  providers: [MapAPIService, ProspectViewModel],
})
export class MarkDncComponent implements OnInit {
  prospectData: any = {};
  clientList = [];
  allClientList = [];
  existingClientList = [];
  checked: boolean = false;
  is_attendee: boolean = false;
  is_sponsor: boolean = false;
  selectedProspectType: number = undefined;
  dnc_reason: string;
  clientId: number = 0;
  dncObj = new DNCModel();

  prospectModel = new ProspectModel();

  clientResponseModel = new BehaviorSubject<ClientResponseModel>(
    new ClientResponseModel()
  );

  constructor(public modalRef: MdbModalRef<MarkDncComponent>, private vm: ProspectViewModel) { }

  ngOnInit(): void {
    this.prospectModel = this.prospectData;
    this.existingClientList = this.prospectData.addl_dnc_client;
    this.vm.parentMarkDncComponent = this;
    this.vm.subscribeClientVariable(this.clientResponseModel);
    this.vm.getAllClients();
  }

  ClientRecieved() {
    this.clientList = this.clientResponseModel.value.results;
    this.fillClientDropdown();
  }

  fillClientDropdown() {
    for( var i=this.clientList.length - 1; i>=0; i--){
      for( var j=0; j<this.existingClientList.length; j++){
          if(this.clientList[i] && (this.clientList[i].name === this.existingClientList[j].client__name)){
            this.clientList.splice(i, 1);
         }
       }
   }
  }

  saveChange() {
    this.dncObj.client = Number(this.clientId);
    this.dncObj.prospect = this.prospectModel.id;
    this.dncObj.email_address = this.prospectModel.email_address;
    this.dncObj.dnc_reason = this.dnc_reason;
    this.dncObj.slug = this.prospectModel.slug;
    this.vm.saveDNCProcpect(this.dncObj, this.checked, this.selectedProspectType);
    this.modalRef.close();
  }
  checkValue(event: any) {
    this.checked = event;
  }

}
