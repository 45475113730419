import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { IApiServiceDelegate } from './map-api-IApiServiceDelegate';
import { MapCommon } from 'src/app/app-providers/map-base/MapCommon';
import { environment } from '../../../environments/environment';

@Injectable()
export class MapAPIService {
  // base endpoint of api server
  //  url = 'https://map-api-dev.buyerforesight.net/bfsmap/v2';
  // url = 'https://api.map.buyerforesight.net/bfsmap/v2';
  url = environment.api_full_url;

  constructor(private http: HttpClient) { }

  // this method is provided to client classes to call api
  callService(data: IApiServiceDelegate) {
    var newURL = this.url + data.Action;
    this.execute(data.Data, newURL, data.Method).subscribe(
      (result: any) => {
        let res = result.res;
        data.Success(res);
      },
      (error: any) => {
        if (typeof error['Errors'] !== 'undefined') {
          data.Error(error['Errors']);
        }
      }
    );
  }

  callGrafanaService(data: IApiServiceDelegate) {
    this.executeGrafana(String(data.Action)).subscribe(
      (result: any) => {
        let res = result.res;
        data.Success(res);
      },
      (error: any) => {
        if (typeof error['Errors'] !== 'undefined') {
          data.Error(error['Errors']);
        }
      }
    );
  }

  executeGrafana(url: string) {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'text/html');
    httpHeaders = httpHeaders.set("Authorization", "Basic bWFwLWRhc2hib2FyZDptYXBVSUFwZSMxMg==");
    httpHeaders = httpHeaders.set('Access-Control-Allow-Origin', 'http://dev.map.buyerforesight.net:3000')
    const httpOptions = {
      headers: httpHeaders
    };
    return this.http.get(url, httpOptions).pipe(map((res: Response) => {  return { res: res }; })).pipe(catchError(this.errorHandler));
  }

  // to create request headers
  getHeaderOptions() {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    if (MapCommon.getloggedInUser() && MapCommon.getloggedInUser().token){
      httpHeaders = httpHeaders.set("Authorization", "Token " + MapCommon.getloggedInUser().token);
    }
    const httpOptions = {
      headers: httpHeaders
    };
    return httpOptions;
  }

  execute(apiData: any, url: string, method: String) {
    if (method == 'POST') {
      return this.http
        .post(url, apiData, this.getHeaderOptions())
        .pipe(
          map((res: Response) => {
            return { res: res };
          })
        )
        .pipe(catchError(this.errorHandler));
    } else if (method == 'DELETE') {
      return this.http
        .delete(url, this.getHeaderOptions())
        .pipe(
          map((res: Response) => {
            return { res: res };
          })
        )
        .pipe(catchError(this.errorHandler));
    } else if (method == 'PATCH') {
      return this.http
        .patch(url, apiData, this.getHeaderOptions())
        .pipe(
          map((res: Response) => {
            return { res: res };
          })
        )
        .pipe(catchError(this.errorHandler));

    } else if (method == 'PUT') {
      return this.http
        .put(url, apiData, this.getHeaderOptions())
        .pipe(
          map((res: Response) => {
            return { res: res };
          })
        )
        .pipe(catchError(this.errorHandler));

    } else {
      return this.http
        .get(url, this.getHeaderOptions())
        .pipe(
          map((res: Response) => {
            return { res: res };
          })
        )
        .pipe(catchError(this.errorHandler));
    }
  }


uploadFile(url: string, formData: FormData, headers: HttpHeaders){

  if (MapCommon.getloggedInUser() && MapCommon.getloggedInUser().token){
    headers = headers.set("Authorization", "Token " + MapCommon.getloggedInUser().token);
  }


  var newURL = this.url + url;
  const httpOptions = {
    headers: headers,
  };
  // var httpOptions = this.getHeaderOptions();

  return this.http.post(newURL, formData, httpOptions).pipe(
    map((res: Response) => {
      return { res: res };
    })
  )
  .pipe(catchError(this.errorHandler));
}


downloadFile(url, headers: HttpHeaders){
  var httpOptions = this.getHeaderOptions();
  httpOptions['observe'] = "response" as "body";
  httpOptions['responseType'] = "blob" as "json";
  var newURL = this.url + url;
  // var httpOptions = {
  //   headers: headers,
  //   observe: "response" as "body",
  //   responseType: "blob" as "json"
  // }
  return this.http.get(newURL, httpOptions).pipe(
    map((res: Response) => {
      return { res: res };
    })
  )
  .pipe(catchError(this.errorHandler));
}


// to handle api of upload a file
uploadCSVFile(url, file, currentUser,updatedFileName) {
  var newURL = this.url + url;
  const uploadedFile = new FormData();
  // uploadedFile.set('import_file_url', new Blob([file], { type: 'text/csv' }), file.name);
  if (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      var uploadContentType = { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }
  } else if (file.type === "application/vnd.ms-excel") {
      var uploadContentType = { type: 'application/vnd.ms-excel' }
  } else {
    var uploadContentType = { type: 'text/csv' }
  };

  uploadedFile.set('import_file_url', new Blob([file], uploadContentType), file.name);

  uploadedFile.set('name',updatedFileName);
  uploadedFile.set('created_by', currentUser);
  let httpHeaders = new HttpHeaders();
  httpHeaders.append('Accept', 'application/x-www-form-urlencoded');
  if (MapCommon.getloggedInUser() && MapCommon.getloggedInUser().token){
    httpHeaders = httpHeaders.set("Authorization", "Token " + MapCommon.getloggedInUser().token);
  }
  const httpOptions = {
    headers: httpHeaders
  };
  return this.http.post(newURL, uploadedFile, httpOptions).pipe(
    map((res: Response) => {
      return { res: res };
    })
  )
    .pipe(catchError(this.errorHandler));
}


  errorHandler(e: any) {
       return throwError({ Errors: e.error });
  }
}
