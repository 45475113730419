<div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Customize Email</h5>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modalRef.close()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="">
      <p class="m-0 p-0">Subject</p>
      <mdb-form-control>
        <input [(ngModel)]="stepSubjectModel" value="{{stepSubjectModel}}" mdbInput type="text" id="subject" class="form-control" />
        <!-- <label mdbLabel class="form-label" for="subject">Subject</label> -->
      </mdb-form-control>        
    </div>
    <div class="row mt-3">
      <editor apiKey="{{editorAPIKey}}" [init]="editormodules" [(ngModel)]="emailBodyModel"></editor>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-link" (click)="modalRef.close()">
      Close
    </button>
    <button type="button" (click)="updateCampaignRunlogObject()" class="btn btn-primary">Update</button>
  </div>