import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { MapCommon } from 'src/app/app-providers/map-base/MapCommon';
import { ClientsModel } from '../client-models/clientModel';
import { ClientsViewModel } from '../client-models/clientViewModel';


@Component({
  selector: 'app-client-edit',
  templateUrl: './client-edit.component.html',
  styleUrls: ['./client-edit.component.scss']
})
export class ClientEditComponent implements OnInit {

  clientModel: ClientsModel;
  currentUser: number;

  constructor(public modalRef: MdbModalRef<ClientEditComponent>, private vm: ClientsViewModel) {
    this.currentUser = MapCommon.getloggedInUser().id;
  }

  ngOnInit(): void {
  }

  editClient(){
    this.clientModel.modified_at = new Date;
    this.clientModel.modified_by = this.currentUser;
    this.vm.editClient(this.clientModel);  
    this.modalRef.close();
  }

}
