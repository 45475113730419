<div class="position-fixed bottom-0 end-0 p-3" style="z-index: 234234234">
    <div id="dangertoast" class="toast align-items-center text-white bg-danger border-0 hide" role="alert"
    style="box-shadow: 1px 1px 5px grey;"
        aria-live="assertive" aria-atomic="true" data-delay="5000">
        <div class="d-flex">
            <div class="toast-body">
                {{alert_message}}
            </div>
            <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
                aria-label="Close"></button>
        </div>
    </div>
</div>
<div class="position-fixed bottom-0 end-0 p-3" style="z-index: 234234234">
    <div id="primarytoast" class="toast align-items-center text-white bg-primary border-0 hide" role="alert"
        aria-live="assertive" aria-atomic="true" data-delay="5000" style="box-shadow: 1px 1px 5px grey;">
        <div class="d-flex">
            <div class="toast-body">
                {{alert_message}}
            </div>
            <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
                aria-label="Close"></button>
        </div>
    </div>
</div>
