<div class="ms-sm-auto" style="width: 90%;">




    <div class="row" style="height: 100%;">

        <div class="col-2 shadow-3 " style="background: #FBF7FE; height: 100vh; overflow-x:hidden; 
        overflow-y: auto; z-index: 1;">


            <mdb-form-control class="mt-3">
                <input mdbInput type="text" id="form1" [(ngModel)]="search_by_name" class="form-control"
                    (keyup)="inputkeyUp()" (keydown)="inputKeyDown()" />
                <label mdbLabel class="form-label" for="form1">Search</label>
            </mdb-form-control>


            <ng-select [(ngModel)]="search_by_active" id="activeInput" (change)='filterData()'
                class="header-imput w-100 mt-2" placeholder="Status">
                <ng-option [value]="true">Active</ng-option>
                <ng-option [value]="false">Inactive</ng-option>
            </ng-select>

        </div>


        <div class="col-10 m-0"
            style="height: 100vh; overflow-x: hidden; overflow-y: auto; background-color: rgb(243, 243, 243);">

            <div class="row shadow-1 p-3 sticky-top bg-white" style="z-index: 2;">
                <div class="col-md-4">
                    <h4 class="map-text-header">Templates
                        <!-- <span
                    style="font-size: 12px; color: #ef6c00;">({{totalClientRecieved}} found)</span> -->
                    </h4>
                </div>

                <div class="col-md-8">

                    <div class="d-flex justify-content-end">
                        <button type="button" class="btn btn-primary btn-sm" (click)="addTemplateModal()">
                            <i class="fas fa-plus"></i> Create New</button>
                    </div>
                </div>

            </div>



            <div class="row p-2">
                <div class="col-12">
                    <div class="bg-white">
                        <table class="table table-sm table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">NAME</th>
                                    <th scope="col">CREATED BY</th>
                                    <th scope="col">Status</th>
                                    <th scope="col" class="text-center">CREATED AT</th>
                                    <th scope="col" class="text-center">MODIFIED AT</th>
                                    <th scope="col" class="text-center">ACTIONS</th>

                                </tr>
                            </thead>
                            <tbody>
  
                                <tr *ngFor="let i of templateResponseModel.value?.results">
                                    <td (click)="previewTemplate(i)">
                                        <p class="fw-bold m-0 text-primary cursor-pointer"><span>{{i.name}}</span></p>
                                    </td>
                                    <td>
                                        <p class="m-0 map-color2">{{i.created_by_name ? i.created_by_name: "No Name"}}</p>
                                    </td>
                                    <td>{{ i.status == true ? 'Active': 'Inactive'}}
                                    </td>
                                    <td class="text-center">{{i.created_at | date: 'medium'}}</td>
                                    <td class="text-center">{{i.modified_at | date: 'medium'}}</td>
                                    <td class="text-center">
                                        <div class="d-flex justify-content-between fw-bold">
                                            <a href="javascript:void(0);" (click)="editTemplate(i)">Edit</a> &bull;
                                            <a href="javascript:void(0);" (click)="previewTemplate(i)">Preview</a>
                                            &bull;

                                            <div mdbDropdown class="dropdown">
                                                <a href="javascript:void(0)" class="dropdown-toggle" type="button"
                                                    id="dropdownMenuButton" aria-expanded="false" mdbDropdownToggle>
                                                    More
                                                </a>
                                                <ul mdbDropdownMenu class="dropdown-menu"
                                                    aria-labelledby="dropdownMenuButton">
                                                    <li><a class="dropdown-item" href="javascript:void(0);" (click)="duplicateTemplate(i)">Duplicate</a></li>
                                                    <li><a class="dropdown-item" href="javascript:void(0);" (click)="activateTemplate(i)">Change Status</a></li>
                                                    <li><a class="dropdown-item" href="javascript:void(0);" (click)="deleteTemplate(i)">Delete</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </td>

                                </tr>

                                <tr *ngIf="templateResponseModel.value?.results?.length == 0 || !templateResponseModel.value?.results" >
                                    <td colspan="5"> No Template available...</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>





        </div>



    </div>
</div>