



<div class="px-4 py-5 my-5 text-center">
    <img class="d-block mx-auto mb-4" src="../../assets/images/logo-mini.png" alt="" width="72" height="57">
    <!-- <h1 class="display-5 fw-bold">BuyerForesight</h1> -->
    <div class="col-lg-6 mx-auto">
      

        <div class="fof text-center">
            <h1>Error 401</h1>
            <h2 class="h1">Sorry! Unauthorized access</h2>
             </div>
     
      <!-- <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
        <a href="https://www.buyerforesight.com" class="btn btn-primary btn-lg px-4 gap-3">Visit BuyerForesight for more</a>
      </div> -->
    </div>
  </div>