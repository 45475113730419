import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import {
  CampaignClientResponseModel, CampaignModel, CampaignUserResponseModel, RegionResponseModel, SendingWindowsResponseModel
} from "../campaign-model/campaign-model";
import { MapAPIService } from "src/app/app-providers/services/map-module";
import { BehaviorSubject } from 'rxjs';
import { CampaignViewModel } from '../campaign-view-model/campaign-view-model';
import { CampaignsComponent } from '../campaigns.component';
import { Router } from '@angular/router';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

@Component({
  selector: 'app-campaign-add',
  templateUrl: './campaign-add.component.html',
  styleUrls: ['./campaign-add.component.scss']
})
export class CampaignAddComponent implements OnInit {
  campaignModel = new CampaignModel();
  parentCampaignComponent: CampaignsComponent;
  clientList = [];
  userList = []
  sendingWindowslist = [];
  clientId: number;
  selectedClientId: number;
  sendingWindowId: number;
  sendModeModel: boolean = false;
  sendEmailModeModel: boolean = true;
  sendEmailExpressModel: boolean = false;
  selectedSendingWindow: string;
  selectedClient: string;
  isFocused: boolean = false;
  campaignRegionModel: number = 1;
  formErrors = [];
  actionCaller = '';
  multiMailmoxesModelCheck: boolean = false;

  isLoading: boolean = false;

  campaignClientResponseModel = new BehaviorSubject<CampaignClientResponseModel>(
    new CampaignClientResponseModel()
  );

  sendingWindowsResponseModel = new BehaviorSubject<SendingWindowsResponseModel>(
    new SendingWindowsResponseModel()
  );

  regionResponseModel = new BehaviorSubject<RegionResponseModel>(
    new RegionResponseModel()
  );

  activeMainBoxesUserResponseModel = new BehaviorSubject<CampaignUserResponseModel>(
    new CampaignUserResponseModel()
  );

  constructor(
    public modalRef: MdbModalRef<CampaignAddComponent>,
    private vm: CampaignViewModel,
    private router: Router
  ) {
    this.campaignModel.senders = [];
  }

  ngOnInit(): void {

    this.vm.parentAddCampaignsComponent = this;
    this.vm.subscribeAddCampaignVariables(
      this.campaignClientResponseModel,
      this.sendingWindowsResponseModel,
      this.regionResponseModel,
      this.activeMainBoxesUserResponseModel
      );
    this.vm.getAllClientsFilterOnName('', 'add-campaign', 10);
    this.vm.getAllSendingWindows("", 'campaign-add', 10);
    this.vm.getRegions();
    // this.vm.getAllUsers('campaign-add');
  }

  ClientRecieved() {
    this.clientList = this.campaignClientResponseModel.value.results;
  }


  UserRecieved(){
    this.userList = this.activeMainBoxesUserResponseModel.value.results;
  }

  // regionsReceived(){

  // }


  onFocusEvent() {
    this.isFocused = true;
    this.vm.getAllSendingWindows("", 'campaign-add', 10);
  }
  onFocusClientEvent() {
    this.isFocused = true;
    this.vm.getAllClientsFilterOnName("", 'add-campaign', 10);
  }
  switchStatus() {
    if (this.sendModeModel === true) {
      return true
    } else {
      return false
    }
  }
  onSelectClient(event: TypeaheadMatch): void {
    this.clientId = Number(event.item.id)
    this.vm.getAllUsersForClient('campaign-add' ,this.clientId );
  }
  onSelect(event: TypeaheadMatch): void {
    this.sendingWindowId = event.item.id;
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }


  //call all clients
  changeTypeaheadLoadingClient(event) {
   if (this.selectedClient && this.selectedClient.length > 0) {
      this.vm.getAllClientsFilterOnName(this.selectedClient, 'add-campaign', 10);
   }

  }

  //call all sliding window
  changeTypeaheadLoading(event) {
    if (this.selectedSendingWindow && this.selectedSendingWindow.length > 2)
      this.vm.getAllSendingWindows(this.selectedSendingWindow, 'campaign-add', 10);
  }

  loadAllCampaigns() {
    this.isLoading = false;
    this.parentCampaignComponent.loadAllCampaigns();
    this.modalRef.close();
  }


  sendingWindowsReceived() {
    this.sendingWindowslist = this.sendingWindowsResponseModel.value.results;
  }



  addSendingWindow() {
    this.router.navigateByUrl('/settings/sending-windows');
    this.modalRef.close();
  }

  onMailBoxSelected(e:any){
    let dateExpired = new Date(new Date().getDate()+7).getMilliseconds()
    let  expired = this.userList.filter(e => e.mailbox_connection.is_connected ? new Date(e.mailbox_connection?.expires_on.substr(0,10)).getMilliseconds() : new Date().getMilliseconds()  <= dateExpired  ).map(e => e.mailbox_connection.expires_on)

  }
expiredChecker(dateString:string = ''){
  let dateExpired = new Date(new Date().getDate()+7).getMilliseconds()
  return new Date(dateString.substr(0,10)).getMilliseconds()   <= dateExpired
}


  submit() {


    this.formErrors = [];

    if((Number(this.campaignModel?.name?.length) <= 3) ||
      !this.clientId ||
      (this.campaignModel.name == undefined
      || this.campaignModel.name == '')){

      if (!this.clientId) {
        this.formErrors.push("Client is not selected");
      }
      if ((Number(this.campaignModel?.name?.length) <= 3) || (this.campaignModel.name == undefined || this.campaignModel.name == '')) {
        this.formErrors.push("Campaign name can't be less than 3");
      }


    } else if(this.multiMailmoxesModelCheck == true &&
      (this.campaignModel.senders?.length == 0 || this.campaignModel.senders === undefined)){
        console.log(this.campaignModel.senders)
      this.formErrors.push("Please select at least one sender account or turn off multi-account feature.");
    }


    else {
        this.campaignModel.client = this.clientId;

        // if (!this.campaignRegionModel){
        //   this.campaignRegionModel = 1;
        // }

        if (this.sendModeModel === true) {
          this.campaignModel.send_mode = 'Auto'
          if (this.sendingWindowId > 0) {
            this.campaignModel.schedule = this.sendingWindowId;
          }

        } else {
          this.campaignModel.send_mode = 'Manual'
          this.campaignModel.schedule = null;
        };

        this.campaignModel.use_personal_account = this.sendEmailModeModel;
        this.campaignModel.prospect = [];
        this.campaignModel.region = this.campaignRegionModel;
        // this.vm.saveCampaign(this.campaignModel, 'add-campaign');

        this.isLoading = true;
        this.vm.saveCampaign(this.campaignModel, this.actionCaller);

    }



  }

  changesMailboxToggle(event){
    if(!event.target.checked || event.target.checked == false){
      this.campaignModel.senders = [];
      this.formErrors = [];
    }
  }



}
