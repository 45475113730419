

<app-sidenav></app-sidenav>

<div class="ms-sm-auto" style="width: 90%;">


<div class="row" style="height: 100%;">

  <div class="col-2 pt-2" style="background: #FBF7FE; height: 100vh; overflow-x: hidden; overflow-y: auto; ">

    <mdb-form-control class="mt-3">
      <input mdbInput type="text" id="form1" class="form-control" (input)="handleSearchInput($event.target.value)"
        [(ngModel)]="selectedText" />
      <label mdbLabel class="form-label" for="form1"><i class="fas fa-search"></i> Search</label>
    </mdb-form-control>

    <div class="col-12 mt-3">

      <ng-select [(ngModel)]="createUserId" id="client" (change)='onUserSelected()' placeholder="Imported By">
        <ng-option *ngFor="let o of userList" [value]="o.id">{{o.email?.length > 0 ? o.email: o.username}}</ng-option>
      </ng-select>
    </div>

  </div>



  <div class="col-10 m-0 pe-0" style="height: 100vh; overflow-x: hidden; overflow-y: auto; ">



    <!-- Header -->
    <div class="row shadow-1 p-3 sticky-top bg-white" style="z-index: 2;">
      <div class="col-md-6">
        <h4 class="map-text-header">Imports </h4>
      </div>



      <div class="col-md-6">

        <div class="d-flex justify-content-end">
          <button type="button" routerLink='/imports/column-mapping' class="btn btn-primary btn-sm ">
            <i class="fas fa-plus"></i> IMPORT PROSPECTS</button>
          </div>

        </div>
    </div>

    <div class="row">
     <div class="col-12">
      <div class="table-responsive" infiniteScroll [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="40" (scrolled)="onImportScroll()" [scrollWindow]="false">
        <table class="table table-sm table-hover table-responsive import-table">
          <thead>
            <tr>
              <th scope="col">Import Name</th>
              <th scope="col">BY</th>
              <th scope="col">Created</th>
              <th scope="col">New</th>
              <th scope="col">Already In MAP</th>
              <th scope="col">Duplicates In File</th>
              <th scope="col">Errors</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let i of prospectGroupList">
              <td style="text-align:left">

                <div *ngIf="i.status_text === 'Completed'">
                  <a style="cursor: pointer;" class="text-primary" (click)="filterGroupsOnProspects(i.name)" >
                    <strong>{{i.name.length > 30 ? (i.name | slice:0:30) : i.name}}</strong></a>
                </div>

                <div *ngIf="i.status_text === 'Processing'">
                  <a class="text-primary" >
                    <strong>{{i.name.length > 30 ? (i.name | slice:0:30) : i.name}}</strong></a>
                </div>

                <div *ngIf="i.status_text != 'Processing' && i.status_text != 'Completed'">
                  <a class="text-primary" >
                    <strong>{{i.name.length > 30 ? (i.name | slice:0:30) : i.name}}</strong></a>
                </div>


              </td>
              <td style="color: #EF6C00;">{{getUserName(i.created_by)}}</td>
              <td> {{getTime(i.created_at)}} </td>
              <td> {{i.cnt_total - i.cnt_duplicate - i.cnt_error - i.cnt_file_duplicates}} </td>
              <td> {{i.cnt_duplicate}}</td>
              <td (click)="showDupModal(i.cnt_file_duplicates,i.id)" class="cursor-pointer"> {{i.cnt_file_duplicates}} </td>
              <td (click)="showErrorModal(i.cnt_error,i.id)" class="cursor-pointer" > {{i.cnt_error}} </td>
              <td>

                <i *ngIf="i.status_text === 'Processing'" class="fas fa-spinner fa-spin "></i>
                {{i.status_text }}



                <span *ngIf="i.import_file_url?.length > 0 && i.status_text == 'Completed'">
                  <a href="{{getDownloadUrl(i.import_file_url)}}" target="_blank" class="importStatusItem">
                    <i class="fas fa-download "></i></a>
                </span>

                <span *ngIf="((i.import_file_url?.length == 0) || !(i.import_file_url)) && i.status_text == 'Completed' "
                mdbTooltip="Link is not available"
                placement="left">
                    <i class="fas fa-download importStatusItemDisabled"></i>
                </span>

                <span *ngIf="i.status_text == 'Completed'">
                  <a (click)="deleteImportGroup(i)" ><i class="far fa-trash-alt importStatusItem"></i></a></span>
                <!-- <div mdbDropdown class="dropdown">
                  <button class="btn btn-primary btn-sm" type="button" id="dropdownMenuButton"
                    aria-expanded="false" mdbDropdownToggle>
                    imported
                  </button>
                  <ul mdbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <li><a class="dropdown-item" href="{{i.import_file_url}}"><i class="fas fa-file-download"></i>
                        Download</a></li>
                    <li><a class="dropdown-item" (click)="deleteImportGroup(i)"><i class="fas fa-file"></i> Delete</a>
                    </li>
                  </ul>
                </div> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
     </div>
    </div>

  </div>

</div>


<div class="modal shadow d-block" *ngIf="duplicateMsg"  id="staticBackdrop">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel"> Duplicates in data.</h5>
        <button type="button" class="btn-close" (click)="closeModal()" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12">

            <ol>
              <li *ngFor="let dup of duplicateMsgList"> {{dup.email_address}} <span class="float-end" (click)="copyToClipBoard(dup.email_address)"> <i class="fas fa-copy"></i> </span> </li>
            </ol>

          </div>
          </div>
      </div>
      <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-secondary" (click)="closeModal()" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<div class="modal shadow d-block" *ngIf="errorMsg"  id="staticBackdrop">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel"> Errors in data.</h5>
        <button type="button" class="btn-close" (click)="closeModal()" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12">

            <ol >
              <li *ngFor="let er of errorInFileList"> {{ er.email_address }}
                 <span class="float-end " (click)="copyToClipBoard(er.email_address)"> <i class="fas fa-copy"></i> </span>
                 <span class="float-end mx-2" (click)="createErrorProspectAfterEditing(er)" > <i class="fas fa-edit"></i> </span>
                </li>
            </ol>

          </div>
          </div>
      </div>
      <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-secondary" (click)="closeModal()" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<div class="modal shadow d-block" *ngIf="duplicateInMap"  id="staticBackdrop">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Data already In MAP.</h5>
        <button type="button" class="btn-close" (click)="closeModal()" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12">

            <ol >
              <li *ngFor="let dup of duplicateInMapList"> {{dup.email_address}}  <span class="float-end" (click)="copyToClipBoard(dup.email_address)"> <i class="fas fa-copy"></i> </span></li>
            </ol>

          </div>
          </div>
      </div>
      <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-secondary" (click)="closeModal()" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
<div class="modal shadow d-block" *ngIf="EditErrorProspect"  id="EditErrorPros">
  <div class="modal-dialog" style="max-width:1000px">
    <div class="modal-content">
      <div class="modal-header">

        <button type="button" class="btn-close" (click)="closeModal()" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
       <app-one-prospect-import></app-one-prospect-import>
      </div>
      <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-secondary" (click)="closeModal()" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

</div>


