import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CampaignResponseModel, CampaignModel } from '../model/prospect-model';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ProspectViewModel } from '../viewModel/prospect-viewmodel';
import { MapAPIService } from "src/app/app-providers/services/map-module";
import { BehaviorSubject } from 'rxjs';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
@Component({
  selector: 'app-add-to-campaign',
  templateUrl: './add-to-campaign.component.html',
  styleUrls: ['./add-to-campaign.component.scss'],
  providers: [MapAPIService, ProspectViewModel]
})
export class AddToCampaignComponent implements OnInit {

  @ViewChild("campaignType") campaignType: ElementRef;
  isPasted: boolean = false;
  selectedCampaign: string;
  campaignlist = [];
  prospectId: number;
  campaignId: number;
  selectedCampaignObj = new CampaignModel();
  campaignResponseModel = new BehaviorSubject<CampaignResponseModel>(
    new CampaignResponseModel()
  );
  constructor(private vm: ProspectViewModel, public modalRef: MdbModalRef<AddToCampaignComponent>) { }

  ngOnInit(): void {
    this.vm.parentAddToCampaignComponent = this;
    this.vm.subscribeCampaignVariable(this.campaignResponseModel);
    this.vm.getAllCampaign("", 'prospect-add-to-capaign');
  }

  onFocusEvent() {
    this.vm.getAllCampaign("", 'prospect-add-to-capaign');
  }

  //call all prospecting group
  changeTypeaheadLoading(event) {
    if (this.selectedCampaign && this.selectedCampaign.length > 2)
      this.vm.getAllCampaign(this.selectedCampaign, 'prospect-add-to-capaign');

  }
  onPaste(event: any) {
    this.isPasted = true;
  }
  CampaignReceived() {
    this.campaignlist = this.campaignResponseModel.value.results;
    if (this.isPasted) {

      let el: HTMLElement = this.campaignType.nativeElement;
      this.campaignType.nativeElement.blur();
      this.campaignType.nativeElement.focus();
      this.isPasted = false;
    }
  }
  onSelect(event: TypeaheadMatch): void {
    this.selectedCampaignObj = event.item;
  }
  addToCampaign() {
    var url = window.location.href;
    this.prospectId = Number(url.substring(url.lastIndexOf('/') + 1));
    if (this.selectedCampaignObj.prospect.indexOf(this.prospectId) === -1) {
      this.selectedCampaignObj.prospect.push(this.prospectId);
    }
    this.vm.updateCampaign(this.selectedCampaignObj, 'prospect-add-to-capaign');
  }

  onSuccessClosePopUp() {
    this.modalRef.close();
  }

}
