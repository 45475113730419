 
    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabel">Preview - {{templateData.name}}
      </h5> 
  
      <button type="button" class="btn btn-primary" (click)="modalRef.close()">
        Close
      </button> 
    </div>


    <div class="modal-body"  style="background-color: #F3F3F3;"> 
        <iframe [srcdoc] = "templateData.body | noSanitizeHtml"></iframe> 
    </div>
  
    <div class="modal-footer">
      <button type="button" class="btn btn-link" (click)="modalRef.close()">
          Close
      </button>
    </div>