import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MapCommon } from 'src/app/app-providers/map-base/MapCommon';

@Component({
  selector: 'app-settings-personal',
  templateUrl: './settings-personal.component.html',
  styleUrls: ['./settings-personal.component.scss']
})
export class SettingsPersonalComponent implements OnInit {

  

  constructor(private router: Router) { }

  ngOnInit(): void {
 
    
  }

  logout(){
    MapCommon.removeItemsOnLogOut();
    this.router.navigateByUrl('/login/password-reset').then(()=>window.location.reload());
  }

}
