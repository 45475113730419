import { Injectable } from "@angular/core";
import { BehaviorSubject, Observer } from "rxjs";
import { AlertNotificationsComponent } from "src/app/Alerts/alert-notifications/alert-notifications.component";
import { MapCommon } from "src/app/app-providers/map-base/MapCommon";
import { MapAPICollection } from "src/app/app-providers/services/map-api-collection";
import { IApiServiceDelegate } from "src/app/app-providers/services/map-api-IApiServiceDelegate";
import { MapAPIService } from "src/app/app-providers/services/map-api-service"; 
import { AppComponent } from "src/app/app.component";
import { LoginComponent } from "../login.component";
import { PasswordConfirmComponent } from "../password-confirm/password-confirm.component";
import { PasswordResetComponent } from "../password-reset/password-reset.component";

@Injectable({
    providedIn: "root",
})

export class LoginViewModel { 
    
    parentLogin: LoginComponent;
    parentAppcomponent: AppComponent;
    parentPasswordResetComponent: PasswordResetComponent;
    parentPasswordConfirmComponent: PasswordConfirmComponent

    constructor(private apiService: MapAPIService) { } 

    logMeIn(cred: string) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.LOGIN_USER + '?cred=' + cred,
            Success: (e: any) => {
                try {
                    if (e.mailbox_connection.is_connected === true){
                        localStorage.setItem('map_connected_mailbox', e.mailbox_connection.connected_mailbox_address);
                        localStorage.setItem('map_connection_id', e.mailbox_connection.connection_id);
                    };
                } catch {
                    
                }
                MapCommon.setloggedInUser(e);
                this.parentAppcomponent.updateSidebar();
                this.parentLogin.userDetailReceived();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while login', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    resetPassword(userEmail: string){
        let d: IApiServiceDelegate = {
            Data: {
                "email": userEmail
            },
            Method: "POST",
            Action: MapAPICollection.POST_PASSWORD_RESET,
            Success: (e: any) => {
                this.parentPasswordResetComponent.passwordResetSuccess();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while resetting password', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);

    }

    validateToken(email, token){
        let d: IApiServiceDelegate = {
            Data: {
                "email": email,
                "token": token
            },
            Method: "POST",
            Action: MapAPICollection.POST_TOKEN_VALIDATION,
            Success: (e: any) => {
                this.parentPasswordConfirmComponent.tokenValidationDone(true);
                return e;
            },
            Error: (e: String) => {
                this.parentPasswordConfirmComponent.tokenValidationDone(false);
                return e;
            },
        };
        this.apiService.callService(d);
    }
    

    confirmPassword(email, token, password1, password2){
        let d: IApiServiceDelegate = {
            Data: {
                "email": email,
                "token": token,
                "password1": password1,
                "password2": password2
            },
            Method: "POST",
            Action: MapAPICollection.POST_PASSWORD_CONFIRM,
            Success: (e: any) => {
                this.parentPasswordConfirmComponent.confirmPasswordSuccess();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert(e['error'], 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }
}