export class DashboardResponseModel {
    client_count: number
    prospect_count: number  
    campaign_count: number
    email_sent_count: number
    dnc_count: number
    email_open_count: number
    click_count: number
    step_count: number
    email_reply_count: number

    // Custom
    open_percentage: number
    dnc_percentage: number
    reply_percentage: number
    click_percentage: number
}

export class  CampaignResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [CampaignModel]
}

export class CampaignModel
{
    id:number
    name:string
    recipient:number
    open:number
    unsubscribe:number 
}
