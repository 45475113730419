import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-settings-groups-add',
  templateUrl: './settings-groups-add.component.html',
  styleUrls: ['./settings-groups-add.component.scss']
})
export class SettingsGroupsAddComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
