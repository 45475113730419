<app-loading-dialog *ngIf="isLoading"></app-loading-dialog>

<div class="ms-sm-auto" style="width: 90%;">

<div class="row" style="height: 100%;">



  <div class="col-2 shadow-3" style="background: #FBF7FE; height: 100vh; overflow-x: hidden; overflow-y: auto; scrollbar-width: thin;">

        <app-sidebar-settings></app-sidebar-settings>
    </div>




  <div class="col-10 p-3" style="height: 100vh; overflow-x: hidden; overflow-y: auto;scrollbar-width: thin; ">

    <div class="d-flex mb-2">
      <div class="me-auto"> <h4>Sending Windows <span
        style="font-size: 12px; color: #ef6c00;">({{totalSendingWindowsRecieved}} found) </span></h4>  </div>

      <form #groupsSearchForm="ngForm" >
        <mdb-form-control class="me-3">
          <input mdbInput type="text" id="groupsSearch" class="form-control form-control"
          [(ngModel)]="groupSearchModel" name="search"  
          (keyup)="inputkeyUp()"
          (keydown)="inputKeyDown()"
          />
          <label mdbLabel class="form-label" for="groupsSearch">Search</label>
        </mdb-form-control>
      </form>
        <button type="button" class="btn btn-primary btn-sm" (click)="addSendingWindows()"><i class="fas fa-plus"></i> Add Sending Window</button>


    </div>
    <hr>

    <p style="margin-top: 1.5%;">
      A sending window ensures that emails are only sent within the times you specify.
    </p>

    <div class="col-12">
      <div class="table-responsive" infiniteScroll [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="30" (scrolled)="onSendingWindowScroll()" [scrollWindow]="false">
      <table class="table mt-3 table-hover table-responsive">
        <thead style="background-color: #F6F4EE;">
          <tr>
            <th scope="col">INDEX</th>
            <th scope="col">NAME</th>
            <th scope="col">TIMEZONE</th>
            <th scope="col">DONE</th>
          </tr>
        </thead>
        <tbody>

          <tr (click)="editSendingWindows(i)" class="dncItem" *ngFor="let i of sendingWindowslist; let count=index;">
           <td>{{count+1 }}</td>  <td class="text-primary"><strong>{{i.name}}</strong></td>
            <td style="color: #EF6C00">{{i.timezone_text}} <span class="gmt-display">({{getTimezoneGmtOffSet(i.timezone)}})</span> </td>
            <td>
              <a [ngClass]="{'week-butons text-muted': i.monday_start_at.length === 0,
              'week-butons-active': i.monday_start_at.length != 0}">Mo</a>

              <a [ngClass]="{'week-butons text-muted': i.tuesday_start_at.length === 0,
              'week-butons-active': i.tuesday_start_at.length != 0}">Tu</a>

              <a [ngClass]="{'week-butons text-muted': i.wednesday_start_at.length === 0,
              'week-butons-active': i.wednesday_start_at != 0}">We</a>

              <a [ngClass]="{'week-butons text-muted': i.thursday_start_at.length === 0,
              'week-butons-active': i.thursday_start_at.length != 0}">Th</a>

              <a [ngClass]="{'week-butons text-muted': i.friday_start_at.length === 0,
              'week-butons-active': i.friday_start_at.length != 0}">Fr</a>

              <a [ngClass]="{'week-butons text-muted': i.saturday_start_at.length === 0,
              'week-butons-active': i.saturday_start_at.length != 0}">Sa</a>

              <a [ngClass]="{'week-butons text-muted': i.sunday_start_at.length === 0,
              'week-butons-active': i.sunday_start_at.length != 0}">Su</a>

            </td>

          </tr>




        </tbody>
      </table>
      </div>
    </div>

    </div>


  </div>

  </div>
