import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AlertNotificationsComponent } from 'src/app/Alerts/alert-notifications/alert-notifications.component';
import { ImportViewModel } from '../import-view-model/import-view-model';
import { MapAPIService } from "src/app/app-providers/services/map-module";
import { MapCommon } from "src/app/app-providers/map-base/MapCommon";
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';
import { BehaviorSubject, Observer } from "rxjs";
import { Router } from '@angular/router';
import { ColumnMappingResponseModel } from '../import-model/import-model';

@Component({
  selector: 'app-import-col-mapping',
  templateUrl: './import-col-mapping.component.html',
  styleUrls: ['./import-col-mapping.component.scss'],
  providers: [MapAPIService, ImportViewModel, DatePipe],
})
export class ImportColMappingComponent implements OnInit {
  map = new Map<string, string>();
  availableSet = {}
  errorAfterUpload = {fileName:'',rows:0}
  selectedImportAction: string;
  selectedAction: string = "";
  currentUser: number;
  fileName: string;
  hiddenEvent: any;
  date: Date;
  importDncUploadInProgress: boolean = false;
  colMap: any
  errorMsg:string
  rowsSelectedVal:string
  columnMappingResponseModel = new BehaviorSubject<ColumnMappingResponseModel>(
    new ColumnMappingResponseModel()
  );
  isLoading: boolean = false;
  // completedValidation: boolean = false;
  user_mapped_header: any;

  mapFieldList = ['firstname', 'middlename', 'lastname', 'email_address', 'company', 'address', 'city', 'state', 'country', 'phone',
    'job_title', 'industry', 'linkedin', 'snippet', 'snippet1', 'snippet2', 'snippet3', 'snippet4',]

  constructor(private vm: ImportViewModel, public datepipe: DatePipe,
    private router: Router,
    public changeDetectRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.currentUser = MapCommon.getloggedInUser().id;
    this.vm.subscribeColumnMatchingVariables(this.columnMappingResponseModel);
    this.vm.parentImportColMappingComponent = this;
  }

  onFileSelected(event): void {
    // this.completedValidation = false;
    this.colMap = undefined;
    this.map = new Map<string, string>();

    this.hiddenEvent = Object.assign({}, event.target.files);
    const file: any = event.target.files[0];
    if (file) {
      var size = 1 * 1024 * 1024
      if (file.size > size) {
        AlertNotificationsComponent.alertCall.showAlert("Please upload less than 1MB file", 'danger');
      } else {
        this.importDncUploadInProgress = true;
        // this.vm.parentImportDncComponent = this;
        if(file.name.includes('csv')){
          let reader = new FileReader()
          let csvToRow =reader.readAsText(file)
          let csvRecordArray = []
          console.log(file)
          reader.onload = () =>{
          let csvData = reader.result
          csvRecordArray = (<string>csvData).split(/\r/);

          if(csvRecordArray.length < 3001){
            this.vm.importColMapping(this.hiddenEvent[0], this.currentUser, this.getFileName(this.hiddenEvent[0].name));
           }else{
            this.errorAfterUpload.rows = csvRecordArray.length
            this.errorAfterUpload.fileName = file.name
            this.importDncUploadInProgress = false
           }
          }
        } else if(file.name.includes('xlsx')){
          let reader = new FileReader()
          let csvToRow =reader.readAsArrayBuffer(file)
          let csvRecordArray :any , sheetRecordArray

          reader.onload = (e) =>{
          let csvData = reader.result

          const wb = XLSX.read(csvData, { type: 'binary' });
          const wsName: string = wb.SheetNames[0];
          const ws: XLSX.WorkSheet = wb.Sheets[wsName];

          /* save data */
          sheetRecordArray = XLSX.utils.sheet_to_csv(ws) ;
          csvRecordArray = (<string>sheetRecordArray).split(/\n/) ;


          console.log(file,csvData,sheetRecordArray,csvRecordArray)

          if(csvRecordArray.length < 3001){
            this.vm.importColMapping(this.hiddenEvent[0], this.currentUser, this.getFileName(this.hiddenEvent[0].name));
           }else{
            this.errorAfterUpload.rows = csvRecordArray.length
            this.errorAfterUpload.fileName = file.name
            this.importDncUploadInProgress = false
           }
          }
        }



      }

    }
  }

  getFileName(fileName) {
    var upload_file_name = ""
    if (fileName.endsWith('.csv')) {
      upload_file_name = fileName.slice(0, -4).toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
    } else {
      upload_file_name = fileName.slice(0, -5).toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
    }
    this.date = new Date();
    let latest_date = this.datepipe.transform(this.date, 'HHmmss') + '_' + this.datepipe.transform(this.date, 'ddMMMyy');
    var name = MapCommon.getloggedInUser().first_name + "_";
    return name + upload_file_name + "_" + latest_date;

  }

  clearAfterUpload(success: boolean): void {
    this.colMap = this.columnMappingResponseModel.value;
    this.importDncUploadInProgress = false;

  }


  onAvailableFieldSelected(event, selectedColumn) {
    let availBle = Object.values(this.availableSet)
    let availBleKeys = Object.keys(this.availableSet)
    var exitingItem = this.map.get(event);
    if(event === undefined && availBle.indexOf(selectedColumn) >= 0){
       delete this.availableSet[availBleKeys[availBle.indexOf(selectedColumn)]]
    } else if (this.availableSet.hasOwnProperty(event)  && event !== undefined ) {
      AlertNotificationsComponent.alertCall.showAlert("This fieldset is already mapped with " + exitingItem, 'danger');
    }
    else if(event !== undefined){
      this.map.set(event, selectedColumn);
      this.availableSet[event] = selectedColumn
    }
  }

  validateImport() {
    if (this.selectedAction === "") {
      AlertNotificationsComponent.alertCall.showAlert("Please select if duplicate prospect has to be skipped or updated", 'danger');
      return;
    }
    var mapped_header = {};
    var inputVal = "";
    this.colMap?.available_fieldset.forEach(e => {
      // console.log(e)
      var currentItem = e;
      if (this.map.get(currentItem))
        inputVal = this.map.get(currentItem)
      else {
        inputVal = "";
      }
      mapped_header[currentItem] = inputVal;
    })
    // console.log(this.map.get('firstname') == undefined , this.map.get('emailaddress') == undefined)
    if( this.map.get('firstname') == undefined   ) {
      AlertNotificationsComponent.alertCall.showAlert("Please select Firstname ", 'danger');
      return
    } else if( this.map.get('emailaddress') == undefined ){
      AlertNotificationsComponent.alertCall.showAlert("Please select email address", 'danger');
      return
    }
    // var ss = this.colMap.id;
    // var nn = mapped_header;
    var isSkipped = false;
    if (this.selectedAction === "Skip"){
      isSkipped = true;
    }
    this.isLoading = true;
    this.user_mapped_header = mapped_header;
    this.vm.updateProspectGroups(mapped_header, isSkipped, this.colMap.id);
  }


  // completeImport(){
  //   this.isLoading = true;
  //   var isSkipped = false;
  //   if (this.selectedAction === "Skip"){
  //     isSkipped = true;
  //   }
  //   this.vm.updateProspectGroups(this.user_mapped_header, isSkipped, this.colMap.id, false);
  // }

  changeAction(e) {
    this.selectedAction = e.target.value;
  }

  // completedValidationProcess(response, success){
  //   this.isLoading = false;
  //   if(success){
  //     this.completedValidation = true;
  //   } else{
  //     this.errorMsg = response;
  //   }
  // }

  completeUploadGroup(response, success){
    this.isLoading = false;
    if(success){
      // localStorage.setItem("selectdProspectGroup", response.name);
      // localStorage.setItem("prospectImportStats", JSON.stringify(response));
      // this.router.navigate(['/prospects']);
      this.router.navigate(['/imports']);
    } else{
      this.errorMsg = response;
    }

  }

  closeModal(){
    this.errorMsg = undefined

  }

  closeModal2(){
    this.colMap = undefined
    this.errorAfterUpload = {fileName:'',rows:0}
  }

}
