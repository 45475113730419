import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dnc',
  templateUrl: './dnc.component.html',
  styleUrls: ['./dnc.component.scss']
})
export class DncComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
