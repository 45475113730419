import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observer } from "rxjs";
import { MapAPIService } from "src/app/app-providers/services/map-api-service";
import { MapAPICollection } from "src/app/app-providers/services/map-api-collection";
import { HelpAboutComponent } from "../help-about/help-about.component";
import { ReleaseNoteModel, ReleaseNoteResponseModel } from "./help-model";
import { IApiServiceDelegate } from "src/app/app-providers/services/map-api-IApiServiceDelegate";
import { AlertNotificationsComponent } from "src/app/Alerts/alert-notifications/alert-notifications.component";




@Injectable({
    providedIn: "root",
})

export class ReleaseNoteViewModel {

    parentHelpAboutComponent: HelpAboutComponent;
    releaseNoteResponseModel = new BehaviorSubject<ReleaseNoteResponseModel>(
        new ReleaseNoteResponseModel()
    );

    constructor(private apiService: MapAPIService){}

    subscribeReleaseNoteResponseVariable(
        observerReleaseNoteResponseModel: Observer<ReleaseNoteResponseModel>,) {
        this.releaseNoteResponseModel.subscribe(observerReleaseNoteResponseModel)
    }

    getAllReleaseNote(page) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_RELEASE_NOTE + "?page=" + page + "&page_size=5",
            Success: (e: any) => {
                this.releaseNoteResponseModel.next(e);
                this.parentHelpAboutComponent.releaseNoteReceived();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading release note', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

}