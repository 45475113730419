<div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Send After</h5>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modalRef.close(false)"
    ></button>
  </div>
  <div class="modal-body">

    <p> I want to send the email after: </p>

    <mdb-form-control class="mt-2">
      <input mdbInput [(ngModel)]="daysModel" type="number" id="daysModel" class="form-control"
      value="{{daysModel}}" placeholder="Days" />
    </mdb-form-control>

    <mdb-form-control class="mt-2">
      <input mdbInput [(ngModel)]="hoursModel" type="number" id="hoursModel" class="form-control"
      value="{{hoursModel}}" placeholder="Hours" />
    </mdb-form-control>

    <mdb-form-control class="mt-2">
      <input mdbInput [(ngModel)]="minutesModel" type="number" id="minutesModel" class="form-control"
      value="{{minutesModel}}" placeholder="Minutes" />
    </mdb-form-control>

    <ng-container *ngIf="formError">
      <div class="mt-3 text-center text-danger">
        <p>{{formError}}</p>
      </div>
    </ng-container>

    <div class="mt-1"></div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-link" (click)="modalRef.close(false)">
      Close
    </button>
    <button type="button" (click)="updateSendAfter()" class="btn btn-primary">Update</button>
  </div>