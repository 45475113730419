<div class="ms-sm-auto" style="width: 100%;">

    <div class="row" style="height: 100vh; overflow-x: hidden; overflow-y: auto; background-color: #FBF7FE;">
      <div class="col-3"></div>
  
      <div class="col-6 pt-4">
  
  
        <div class="row mt-5">
          <div class="col-12" style="text-align: center;">
            <img class="logo" src="../../assets/images/logo-mini.png">
          </div>
        </div>
  
        
      <div class="row mt-3">
        <div class="card">
          <div class="card-body">
              <h6 class="text-success text-center mt-3">{{publicEmail}}</h6>
              <p class="text-center">
                I don't want to receive any email communications from
              </p>


              <div class="d-flex justify-content-center">
                <div class="d-flex flex-column mb-3">

                  <div class="form-check mt-1">
                    <input
                      mdbRadio
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDisabled"
                      id="flexRadioDisabled"
                      [disabled]="true"
                    />
                    <label class="form-check-label" for="flexRadioDisabled"> This Event </label>
                  </div>

                  <div class="form-check mt-1">
                    <input
                      mdbRadio
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDisabled"
                      id="flexRadioDisabled"
                      [disabled]="true"
                    />
                    <label class="form-check-label" for="flexRadioDisabled"> BuyerForesight </label>
                  </div>


                  <div class="form-check mt-1">
                    <input
                      mdbRadio
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDisabled"
                      id="flexRadioDisabled"
                      [disabled]="true"
                    />
                    <label class="form-check-label" for="flexRadioDisabled"> Don't send to me or my company </label>
                  </div>

                </div>
              </div>

              <div class="p-3 text-center">
                <button type="submit" [disabled]="true" class="btn btn-danger">Unsubscribe</button>
              </div>
         
          </div>
        </div>
      </div>
         

        
       
  
      <div class="col-3"></div>
  
  
  
  
    </div>
  
  </div>