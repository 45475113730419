import { Component, OnInit } from '@angular/core';
import { MapAPIService } from "src/app/app-providers/services/map-module";
import { BehaviorSubject } from 'rxjs';
import {
  CampaignReplyLogStatsResponseModel, CampaignRunLogFilterModel, CampaignRunLogResponseModel,
  CampaignMasterStat
} from "../campaign-model/campaign-model";

import { CampaignDetailComponent } from '../campaign-detail/campaign-detail.component';
import { CampaignDetailSummaryComponent } from '../campaign-detail-summary/campaign-detail-summary.component'

import { CampaignViewModel } from '../campaign-view-model/campaign-view-model';
import { ExportCampaignService } from 'src/app/admin/services/export-campaign.service';
import { AlertNotificationsComponent } from 'src/app/Alerts/alert-notifications/alert-notifications.component';
@Component({
  selector: 'app-campaign-detail-auto-reply',
  templateUrl: './campaign-detail-auto-reply.component.html',
  styleUrls: ['./campaign-detail-auto-reply.component.scss'],
  providers: [MapAPIService, CampaignViewModel],
})
export class CampaignDetailAutoReplyComponent implements OnInit {

  campaignReportReplylogStats: any = [];
  emailContainsText: string = null;
  campaignStepDetails: any = [];
  campiagnStatus: boolean = false;
  campaignId: number;
  cnt_total_auto_reply: number = 0;
  cnt_nlw_reply: number = 0;
  cnt_ooo_reply: number = 0;
  btn_selected: string = 'Total'
  tab_selected: string = 'All'
  campaignRunLogFilterModel = new CampaignRunLogFilterModel();
  campaignRunLogList: any = [];
  campaignRunLogListB4Search: any = [];
  page_index = 1;
  loadingSpiner: boolean = false;
  exportAllPageSize: number;

  campaignReplyLogStatsResponseModel = new BehaviorSubject<CampaignReplyLogStatsResponseModel>(
    new CampaignReplyLogStatsResponseModel()
  );
  campaignRunLogResponseModel = new BehaviorSubject<CampaignRunLogResponseModel>(
    new CampaignRunLogResponseModel()
  );
  campaignMasterStat = new BehaviorSubject<CampaignMasterStat>(
    new CampaignMasterStat()
  );
  constructor(private vm: CampaignViewModel, private exportCampaignService: ExportCampaignService) { }

  ngOnInit(): void {
    var url = window.location.href;
    this.campaignId = Number(url.substring(url.lastIndexOf('/') + 1));
    this.getCampaignStatus();
    this.vm.parentCampaignDetailAutoReplyComponent = this;
    this.vm.subscribeCampaignReplyVariables(this.campaignMasterStat);
    this.vm.getReportCampaignReplylogStats(this.campaignId);
    this.vm.subscribeCampaignRunLogVariables(this.campaignRunLogResponseModel)
  }


 


  CampaignReportReplyLogStatsRecieved() {
    this.campaignReportReplylogStats = this.campaignMasterStat.value;
    this.filterTabClicked('All', 0);
    this.fillUpTheCounts('All');
  }

  CampaignRunLogReceivedOnEmails() {
    this.loadingSpiner = false;
    if (this.campaignRunLogListB4Search.length == 0)
      this.campaignRunLogList = this.campaignRunLogResponseModel.value.results;
    else {
      this.campaignRunLogList = this.campaignRunLogResponseModel.value.results;
      if (this.campaignRunLogResponseModel.value.previous !== null) {
        for (var j = 0; j < this.campaignRunLogList.length; j++) {
          var index = this.campaignRunLogListB4Search.findIndex(x => x.id == this.campaignRunLogList[j].id);
          if (index === -1)
            this.campaignRunLogListB4Search.push(this.campaignRunLogList[j]);
        }
        this.campaignRunLogList = this.campaignRunLogListB4Search;
      }
    }
  }

  getCampaignStatus() {
    this.campiagnStatus = CampaignDetailComponent.parentCall.campiagnStatus;
    this.campaignStepDetails = CampaignDetailSummaryComponent.siblingCall.campaignSteps;
  }

  // exportAll() {
  //   this.exportCampaignService.exportCampaign(this.campaignRunLogList, (this.campaignId + '_campaign_export'))
  // }

  exportAll() {
    if(this.campaignRunLogList.length > 0){
      this.loadingSpiner = true;
      this.vm.parentCampaignDetailAutoReplyComponent = this;
      this.vm.getExportRunlogs(this.campaignRunLogFilterModel, 'campaign-reply', this.exportAllPageSize);
    } else {
      AlertNotificationsComponent.alertCall.showAlert("No data available, can't export", 'danger')
    }
  }

  // exportProspectsReceived(items){
  //   var prospects = items.map(item => item.prospect);
  //   prospects = prospects.filter((x, i, a) => a.indexOf(x) == i);
  //   this.vm.parentCampaignDetailAutoReplyComponent = this;
  //   this.vm.exportFinalRunlogs(items, prospects, 'campaign-reply');
  // }

  exportApiOpsCompleted(){
    this.loadingSpiner = false;
    // this.exportCampaignService.downloadCSV(data, (this.campaignId + '_campaign_export'));
  }

  filterTabClicked(tabSelected, stepId) {
    this.campaignRunLogFilterModel.is_auto_reply = true;
    this.tab_selected = tabSelected;
    if (tabSelected == 'All') {
      this.campaignRunLogFilterModel.campaign_step__campaign__id = this.campaignId;
      this.campaignRunLogFilterModel.campaign_step__id = null;
      this.fillUpTheCounts('All');
    }
    else {
      this.campaignRunLogFilterModel.campaign_step__campaign__id = null;
      this.campaignRunLogFilterModel.campaign_step__id = stepId;
      this.fillUpTheCounts(stepId);
    }

    this.CallFinalFiler();
  }
  handleUserInput() {
    this.campaignRunLogFilterModel.recipient_email_address__contains = this.emailContainsText;
    this.CallFinalFiler();
  }
  CallFinalFiler() {
    this.page_index = 1;
    this.campaignRunLogFilterModel.page = this.page_index;
    this.campaignRunLogFilterModel.is_auto_reply = true;
    this.loadingSpiner = true;
    this.vm.getAllCampaignRunLog(this.campaignRunLogFilterModel, 'campaign-reply');
  }
  filterButtonClick(filterChoose) {
    this.btn_selected = filterChoose;
    this.campaignRunLogFilterModel.is_auto_reply = true;
    if (filterChoose == 'Total')
      this.campaignRunLogFilterModel.auto_reply_type = null;

    else {
      this.campaignRunLogFilterModel.auto_reply_type = filterChoose;
    }

    this.CallFinalFiler();
  }
  fillUpTheCounts(caller) {
    if (caller == 'All') {
      this.cnt_total_auto_reply = this.campaignReportReplylogStats.stats.emails_sent.reached.replied.auto_reply.total;
      this.cnt_nlw_reply = this.campaignReportReplylogStats.stats.emails_sent.reached.replied.auto_reply.no_longer_working;
      this.cnt_ooo_reply = this.campaignReportReplylogStats.stats.emails_sent.reached.replied.auto_reply.on_vacation;
    }
    else {
    
      var stepLen = this.campaignReportReplylogStats.step_stats.length;
      if (stepLen > 0) {
        for (let i = 0; i < stepLen; i++) {
          if (this.campaignReportReplylogStats.step_stats[i].id == caller) {
            this.cnt_total_auto_reply = this.campaignReportReplylogStats.step_stats[i].emails_sent.reached.replied.auto_reply.total;
            this.cnt_nlw_reply = this.campaignReportReplylogStats.step_stats[i].emails_sent.reached.replied.auto_reply.no_longer_working;
            this.cnt_ooo_reply = this.campaignReportReplylogStats.step_stats[i].emails_sent.reached.replied.auto_reply.on_vacation;
            return;
          }
        }
      };
    }
  }


  // This method is being called once user hits bottom while scrolling email
  onEmailScroll() {
    if (this.campaignRunLogResponseModel.value.next) {
      this.campaignRunLogListB4Search = this.campaignRunLogList;
      this.page_index++;
      this.campaignRunLogFilterModel.page = this.page_index;
      this.vm.getAllCampaignRunLog(this.campaignRunLogFilterModel, 'campaign-reply');
    }
  }

}
