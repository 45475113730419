import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-settings-dnc',
  templateUrl: './settings-dnc.component.html',
  styleUrls: ['./settings-dnc.component.scss']
})
export class SettingsDncComponent implements OnInit {



  constructor() {

  }

  ngOnInit(): void {
  }




}
