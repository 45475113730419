import { Component, OnInit ,Output, EventEmitter, Input, SimpleChanges, } from '@angular/core';
import { CampaignViewModel } from '../campaign-view-model/campaign-view-model';
import { Subject } from 'rxjs';
import { VariableService } from './variable.service';
@Component({
  selector: 'app-subject-line-auto-suggest',
  templateUrl: './subject-line-auto-suggest.component.html',
  styleUrls: ['./subject-line-auto-suggest.component.scss']
})
export class SubjectLineAutoSuggestComponent implements OnInit {
  stepSubjectModel: string = '';

  @Input() getFieldValues: [{ stepSubjectModel: null,
                              emailBodyModel: null,
                              mandatoryError: null,
                              enableTemplate: null,
                              subjectList:null}] ;
  @Input() subjectID: string ;
  setId = "ID-"
  subjectList = []
  loadSubject:number

  @Output() stepSubEvent = new EventEmitter<string>();
  FieldValues: [{
    stepSubjectModel: null,
    emailBodyModel: null,
    mandatoryError: null,
    enableTemplate: null,
    subjectList:null
  }] ;

  private _getSubjectModel: string ;

  @Input() set getSubjectModel(value: string) {
    this._getSubjectModel = value;
    if(!this.FieldValues){
      this.stepSubjectModel = value;
    }
    // console.log(value ,this._getSubjectModel, this.stepSubjectModel )

 };

  constructor(private vm: CampaignViewModel,private dynamicVariableservice:VariableService) {}

  ngOnInit(): void {
    this.vm.SubjectLineAutoSuggestComponent = this
    this.stepSubjectModel = this._getSubjectModel ? this._getSubjectModel : ''
    this.setId+=this.subjectID
    this.FieldValues = this.getFieldValues
    // this.dynamicVariableservice.getDynamicVariable().subscribe(e =>this.stepSubjectModel += e )
  }


//   ngOnChanges(changes: SimpleChanges) {
//     console.log(changes.getSubjectModel.currentValue)
// }


  returnVal(id:number){
    return 'InnerSubject-'+id
  }


  onSelect(event){
    this.stepSubjectModel = event.subject
    this._getSubjectModel = event.subject
    if(!this.FieldValues){
    this.stepSubEvent.emit(this._getSubjectModel);
    } else{
      this.stepSubEvent.emit(this._getSubjectModel);
    }
    setTimeout(()=>{this.subjectList = []}, 500);
    if(this.FieldValues) {
      setTimeout(()=>{this.FieldValues[this.loadSubject].subjectList = null}, 500)
    }

  }
  hideIt(){
    setTimeout(()=>{this.subjectList = []}, 500)
    if(this.FieldValues) {
    setTimeout(()=>{this.FieldValues[this.loadSubject].subjectList = null}, 500)
    }
  }

  changeTypeaheadLoading() {

    if( this._getSubjectModel?.length > 3){
      this.vm.getSubjects('add-step-subject', this._getSubjectModel,);
    } else{
      this.subjectList = []
    }
    setTimeout(() => { this.stepSubEvent.emit(this._getSubjectModel);} , 500)
  }

  changeTypeaheadLoadingFocus(event:any) {

    setTimeout(()=> {
      this.loadSubject = parseInt(event.target.id.slice(3))
      if( this._getSubjectModel?.length > 3){
        this.vm.getSubjects('add-step-subject', this._getSubjectModel, this.loadSubject);
      } else{
        this.FieldValues[this.loadSubject].subjectList = null

      }
    }, 20)

  }
  changeTypeaheadLoadingFocusNot(event:any) {
    setTimeout(()=> {
      if( this._getSubjectModel?.length > 3){
        this.vm.getSubjects('add-step-subject', this._getSubjectModel, this.loadSubject);
      } else{
        this.FieldValues[this.loadSubject].subjectList = null

      }
    }, 2000)
    setTimeout(() => { this.stepSubEvent.emit(this._getSubjectModel);} , 20)
  }

  getSubjectList(subjectList,id:number){
    if(!this.FieldValues){
      this.subjectList = subjectList['results'].map(item => { return  { 'subject':item.subject ,'open_rate' :item.avg_open_rate}});
    } else if(this.FieldValues){
      setTimeout(
        ()=> {
          this.FieldValues[id].subjectList = subjectList['results'].map(item => { return  { 'subject':item.subject ,'open_rate' :item.avg_open_rate}})
        } , 400
      )

    }

  }

}
