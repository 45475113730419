import { Component, OnInit } from '@angular/core';
import { TemplateModel } from '../template-model/template-model';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { MapAPIService } from "src/app/app-providers/services/map-module";
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { TemplateViewModel } from '../template-model/template-view-model';
import { TemplatesComponent } from '../templates.component';
import { MapCommon } from 'src/app/app-providers/map-base/MapCommon';
@Component({
  selector: 'app-template-add',
  templateUrl: './template-add.component.html',
  styleUrls: ['./template-add.component.scss']
})
export class TemplateAddComponent implements OnInit {
  templateModel=  new TemplateModel();
  templateData: any = {};
  formErrors = [];
  currentUser: any = null;

  constructor(
    public modalRef: MdbModalRef<TemplateAddComponent>,
    private vm: TemplateViewModel,
    private router: Router
  ) {
    const localStorage_user = MapCommon.getloggedInUser();
    if (localStorage_user){
      this.currentUser = localStorage_user
    }

   }

  ngOnInit(): void {
    if(this.templateData?.id > 0)
    {
      this.templateData.id = 0;
    this.templateModel = this.templateData;
    }
    this.vm.parentTemplateAddComponent = this;
  }

  loadAllTemplate() {
    this.modalRef.close(true);
  } 


  submit() {
    this.formErrors = [];
    if ((Number(this.templateModel?.name?.length) <= 3) || (this.templateModel.name == undefined || this.templateModel.name == '')) {
      this.formErrors.push("Template name can't be less than 3");
    }

    else {
      this.templateModel.created_by = this.currentUser.id
      this.vm.saveTemplate(this.templateModel);
    }
  }

}
