import { Component, OnInit } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { SendingWindowsModel, ScheduleTimezoneResponseModel ,  } from '../../setting-model/setting-model';
import { SettingViewModel } from '../../setting-view-model/setting-view-model';
import { TimePickerModalComponent } from '../time-picker-modal/time-picker-modal.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';



@Component({
  selector: 'app-update-sending-windows',
  templateUrl: './update-sending-windows.component.html',
  styleUrls: ['./update-sending-windows.component.scss']
})
export class UpdateSendingWindowsComponent implements OnInit {

  TimeSelections = [
    {time: '00:00:00', time_display: "12:00 AM"}, {time: '01:00:00', time_display: "01:00 AM"},
    {time: '02:00:00', time_display: "02:00 AM"}, {time: '03:00:00', time_display: "03:00 AM"},
    {time: '04:00:00', time_display: "04:00 AM"}, {time: '05:00:00', time_display: "05:00 AM"},
    {time: '06:00:00', time_display: "06:00 AM"}, {time: '07:00:00', time_display: "07:00 AM"},
    {time: '08:00:00', time_display: "08:00 AM"}, {time: '09:00:00', time_display: "09:00 AM"},
    {time: '10:00:00', time_display: "10:00 AM"}, {time: '11:00:00', time_display: "11:00 AM"},
    {time: '12:00:00', time_display: "12:00 PM"}, {time: '13:00:00', time_display: "01:00 PM"},
    {time: '14:00:00', time_display: "02:00 PM"}, {time: '15:00:00', time_display: "03:00 PM"},
    {time: '16:00:00', time_display: "04:00 PM"}, {time: '17:00:00', time_display: "05:00 PM"},
    {time: '18:00:00', time_display: "06:00 PM"}, {time: '19:00:00', time_display: "07:00 PM"},
    {time: '20:00:00', time_display: "08:00 PM"}, {time: '21:00:00', time_display: "09:00 PM"},
    {time: '22:00:00', time_display: "10:00 PM"}, {time: '23:00:00', time_display: "11:00 PM"},

  ];

  userDeleteWarning: boolean = false;
  removeSendingWindowWarningCss = 'btn btn-danger ms-auto me-auto';

  TimePickerModalRef: MdbModalRef<TimePickerModalComponent>;
  scheduleTimezoneList = [];
  sendingWindow: any;

  mondayStartModel = [];
  mondayEndModel = [];

  tuesdayStartModel = [];
  tuesdayEndModel = [];

  wednesdayStartModel = [];
  wednesdayEndModel = [];

  thursdayStartModel = [];
  thursdayEndModel = [];

  fridayStartModel = [];
  fridayEndModel = [];

  saturdayStartModel = [];
  saturdayEndModel = [];

  sundayStartModel = [];
  sundayEndModel = [];

  timezoneSelectModel: number = null;
  sendingWindowNameModel = "";
  currentUser: number;
  sendingWindowModel: SendingWindowsModel;

  nameError: boolean = false;
  timezoneError: boolean = false;
  noTimeError: boolean = false;
  TimezoneData= [];


  specialCharacters = ['!','@','#','$','%','^','&','*','(',')','?','/',',','.','+','-','`','~','_','-','=']
  specialCharSpotted:boolean = false
  specialCharError:boolean = false

  scheduleTimezoneResponseModel = new BehaviorSubject<ScheduleTimezoneResponseModel>(
    new ScheduleTimezoneResponseModel()
  );

  sendingWindowsModel = new BehaviorSubject<SendingWindowsModel>(
    new SendingWindowsModel()
  );

  constructor(private modalService: MdbModalService, private vm: SettingViewModel, private route: ActivatedRoute, private router: Router) {
    this.vm.parentUpdateSendingWindowsComponent = this;
  }

  ngOnInit(): void {
    // for (var i = 0; i < this.scheduleTimezoneList.length; i++) {
    //   this.TimezoneData.push({ id: this.scheduleTimezoneList[i].id, text: this.getTimezoneGmtOffSet(this.scheduleTimezoneList[i]) });
    // }
    const id = Number(this.route.snapshot.paramMap.get('id'))
    this.vm.subscribeSendingWindowVariable(this.sendingWindowsModel);
    this.vm.getSendingWindow(id)
    this.vm.subscribeScheduleTimezoneVariable(this.scheduleTimezoneResponseModel);
    this.vm.getAllScheduleTimezone('edit');
  }


  cancelWarning(){
    // this.updateSendingWindowsModalRef.close();
    this.userDeleteWarning = false;
  }


  displayTime(startTime: string, endTime: string){
    var startdisplay = startTime.slice(0,2) + ' ' + startTime.slice(-2);
    var enddisplay = endTime.slice(0,2) + ' ' + endTime.slice(-2);
    return startdisplay + ' - ' + enddisplay
  }
  removeSendingWindowConfirmed(){
    this.vm.deleteSendingWindow(this.sendingWindowModel);
    // this.updateSendingWindowsModalRef.close();
    this.router.navigate(['/settings/sending-windows'])

  }

  removeSendingWindowWarning(){
    this.userDeleteWarning = true;
    this.removeSendingWindowWarningCss = 'btn btn-outline-light ms-auto me-auto';

  }
  sendingWindowsReceived(){
    this.sendingWindowModel = this.sendingWindowsModel.value
    this.setUpUpdateData();

  }

  scheduleTimezoneReceived(){
    this.scheduleTimezoneList = this.scheduleTimezoneResponseModel.value.results;
    for (var i = 0; i < this.scheduleTimezoneList.length; i++) {
      this.TimezoneData.push({ id: this.scheduleTimezoneList[i].id, text: this.getTimezoneGmtOffSet(this.scheduleTimezoneList[i]) });
    }
  }



  nameChange(){
    if(this.sendingWindowNameModel.length > 0){
      this.nameError = false
      for(let i =0 ; i < this.specialCharacters.length ; i++){
        this.specialCharError = this.sendingWindowNameModel.indexOf(this.specialCharacters[i]) >= 0 ? true :  false
        if(this.specialCharError){
          break;
        }

      }
    }
  }

  timezoneChange(){
    if(this.timezoneSelectModel != null){
      this.timezoneError = false
    }
  }

  setUpUpdateData(){
    this.sendingWindowNameModel = this.sendingWindowModel.name;
    this.timezoneSelectModel = this.sendingWindowModel.timezone;

    if (this.sendingWindowModel.monday_start_at && this.sendingWindowModel.monday_end_at){
      this.mondayStartModel = this.getFullItem(this.sendingWindowModel.monday_start_at)
      this.mondayEndModel = this.getFullItem(this.sendingWindowModel.monday_end_at)
    };

    if (this.sendingWindowModel.sunday_start_at && this.sendingWindowModel.sunday_end_at){
      this.sundayStartModel = this.getFullItem(this.sendingWindowModel.sunday_start_at)
      this.sundayEndModel = this.getFullItem(this.sendingWindowModel.sunday_end_at)
    };

    if (this.sendingWindowModel.saturday_start_at && this.sendingWindowModel.saturday_end_at){
      this.saturdayStartModel = this.getFullItem(this.sendingWindowModel.saturday_start_at)
      this.saturdayEndModel = this.getFullItem(this.sendingWindowModel.saturday_end_at)
    };

    if (this.sendingWindowModel.friday_start_at && this.sendingWindowModel.friday_end_at){
      this.fridayStartModel = this.getFullItem(this.sendingWindowModel.friday_start_at)
      this.fridayEndModel = this.getFullItem(this.sendingWindowModel.friday_end_at)
    };

    if (this.sendingWindowModel.thursday_start_at && this.sendingWindowModel.thursday_end_at){
      this.thursdayStartModel = this.getFullItem(this.sendingWindowModel.thursday_start_at)
      this.thursdayEndModel = this.getFullItem(this.sendingWindowModel.thursday_end_at)
    };

    if (this.sendingWindowModel.wednesday_start_at && this.sendingWindowModel.wednesday_end_at){
      this.wednesdayStartModel = this.getFullItem(this.sendingWindowModel.wednesday_start_at)
      this.wednesdayEndModel = this.getFullItem(this.sendingWindowModel.wednesday_end_at)
    };

    if (this.sendingWindowModel.tuesday_start_at && this.sendingWindowModel.tuesday_end_at){
      this.tuesdayStartModel = this.getFullItem(this.sendingWindowModel.tuesday_start_at)
      this.tuesdayEndModel = this.getFullItem(this.sendingWindowModel.tuesday_end_at)
    };
  }

  getFullItem(SelectTime: any){
    var resultList = []
    for (let x of SelectTime){
      var result = this.TimeSelections.filter(obj => {
        return obj.time === x;
      })
      resultList.push(result[0])
    }
    return resultList
  };

  getActualTime(model: any){
    var result = []
    for (let x of model){
      result.push(x.time)
    }
    return result

  }

  closewindow(){
    this.router.navigate(['/settings/sending-windows'])
  }

  updateNewSendingWindow() {
      this.nameChange()
      this.timezoneChange()
      console.log(this.specialCharError)
    if (this.sendingWindowNameModel === "") {
      this.nameError = true;
    } else if (this.timezoneSelectModel === null) {
      this.timezoneError = true;
    } else if (
      this.mondayStartModel.length == 0 &&
      this.tuesdayStartModel.length == 0 &&
      this.wednesdayStartModel.length == 0 &&
      this.thursdayStartModel.length == 0 &&
      this.fridayStartModel.length == 0 &&
      this.saturdayStartModel.length == 0 &&
      this.sundayStartModel.length == 0
      ) {
        this.noTimeError = true;
    } else {
      if(!this.nameError && !this.timezoneError  && !this.specialCharError && !this.noTimeError   ){
      const sendingWindowsUpdateModel = new SendingWindowsModel;
      sendingWindowsUpdateModel.name = this.sendingWindowNameModel;
      sendingWindowsUpdateModel.id = this.sendingWindowModel.id;
      sendingWindowsUpdateModel.sunday_start_at = this.getActualTime(this.sundayStartModel);
      sendingWindowsUpdateModel.sunday_end_at = this.getActualTime(this.sundayEndModel);
      sendingWindowsUpdateModel.monday_start_at = this.getActualTime(this.mondayStartModel);
      sendingWindowsUpdateModel.monday_end_at = this.getActualTime(this.mondayEndModel);
      sendingWindowsUpdateModel.tuesday_start_at = this.getActualTime(this.tuesdayStartModel);
      sendingWindowsUpdateModel.tuesday_end_at = this.getActualTime(this.tuesdayEndModel);
      sendingWindowsUpdateModel.wednesday_start_at = this.getActualTime(this.wednesdayStartModel);
      sendingWindowsUpdateModel.wednesday_end_at = this.getActualTime(this.wednesdayEndModel);
      sendingWindowsUpdateModel.thursday_start_at = this.getActualTime(this.thursdayStartModel);
      sendingWindowsUpdateModel.thursday_end_at = this.getActualTime(this.thursdayEndModel);
      sendingWindowsUpdateModel.friday_start_at = this.getActualTime(this.fridayStartModel);
      sendingWindowsUpdateModel.friday_end_at = this.getActualTime(this.fridayEndModel);
      sendingWindowsUpdateModel.saturday_start_at = this.getActualTime(this.saturdayStartModel);
      sendingWindowsUpdateModel.saturday_end_at = this.getActualTime(this.saturdayEndModel);
      sendingWindowsUpdateModel.timezone = this.timezoneSelectModel;
      sendingWindowsUpdateModel.created_by = this.sendingWindowModel.created_by;
      const modified_by = this.currentUser;
      this.vm.updateSendingWindows(sendingWindowsUpdateModel, modified_by);
      // this.updateSendingWindowsModalRef.close();
      this.router.navigate(['/settings/sending-windows'])
     }

    }

  }



   // Monday
   mondaySelectModal(){
    this.TimePickerModalRef = this.modalService.open(TimePickerModalComponent);
    this.TimePickerModalRef.onClose.subscribe((message: any) => {
      if (message) {
        if(this.processSendingSlot(message, this.mondayStartModel, this.mondayEndModel)){
          this.mondayStartModel.push(message.start);
          this.mondayEndModel.push(message.end);
          this.noTimeError= false
        }
      }
    });
  }

  mondayRemove(e) {
    e.stopPropagation();
    this.mondayStartModel = [];
    this.mondayEndModel = [];
  }

  // Tuesday
  tuesdaySelectModal(){
    this.TimePickerModalRef = this.modalService.open(TimePickerModalComponent);
    this.TimePickerModalRef.onClose.subscribe((message: any) => {
      if (message) {
        if(this.processSendingSlot(message, this.tuesdayStartModel, this.tuesdayEndModel)){
          this.tuesdayStartModel.push(message.start);
          this.tuesdayEndModel.push(message.end);
          this.noTimeError= false
        }
      }
    });
  }

  tuesdayRemove(e) {
    e.stopPropagation();
    this.tuesdayStartModel = [];
    this.tuesdayEndModel = [];
  }



  processSendingSlot(message: any, startmodel: any, endmodel: any){

    for (let x in startmodel) {
      if(startmodel[x].time <= message.start.time && endmodel[x].time >= message.end.time){
        return false
      }
      else if(startmodel[x].time <= message.start.time && endmodel[x].time >= message.start.time && endmodel[x].time <= message.end.time){
        endmodel[x] = message.end
        return false
      }
      else if(startmodel[x].time <= message.end.time && startmodel[x].time >= message.start.time && endmodel[x].time >= message.end.time ){
        startmodel[x] = message.start
        return false
      }
      else if(startmodel[x].time >= message.start.time && endmodel[x].time <= message.end.time){
        startmodel[x] = message.start
        endmodel[x] = message.end
        return false
      }
    }
    return true

  }


  // Wednesday
  wednesdaySelectModal(){
    this.TimePickerModalRef = this.modalService.open(TimePickerModalComponent);
    this.TimePickerModalRef.onClose.subscribe((message: any) => {
      if (message) {
        if(this.processSendingSlot(message, this.wednesdayStartModel, this.wednesdayEndModel)){
          this.wednesdayStartModel.push(message.start);
          this.wednesdayEndModel.push(message.end);
          this.noTimeError= false
        }
      }
    });
  }

  wednesdayRemove(e) {
    e.stopPropagation();
    this.wednesdayStartModel = [];
    this.wednesdayEndModel = [];
  }



  // Thursday
  thursdaySelectModal(){
    this.TimePickerModalRef = this.modalService.open(TimePickerModalComponent);
    this.TimePickerModalRef.onClose.subscribe((message: any) => {
      if (message) {
        if(this.processSendingSlot(message, this.thursdayStartModel, this.thursdayEndModel)){
          this.thursdayStartModel.push(message.start);
          this.thursdayEndModel.push(message.end);
          this.noTimeError= false
        }
      }
    });
  }

  thursdayRemove(e) {
    e.stopPropagation();
    this.thursdayStartModel = [];
    this.thursdayEndModel = [];
  }


  // Friday
  fridaySelectModal(){
    this.TimePickerModalRef = this.modalService.open(TimePickerModalComponent);
    this.TimePickerModalRef.onClose.subscribe((message: any) => {
      if (message) {
        if(this.processSendingSlot(message, this.fridayStartModel, this.fridayEndModel)){
          this.fridayStartModel.push(message.start);
          this.fridayEndModel.push(message.end);
          this.noTimeError= false
        }
      }
    });
  }

  fridayRemove(e) {
    e.stopPropagation();
    this.fridayStartModel = [];
    this.fridayEndModel = [];
  }


  // Saturday
  saturdaySelectModal(){
    this.TimePickerModalRef = this.modalService.open(TimePickerModalComponent);
    this.TimePickerModalRef.onClose.subscribe((message: any) => {
      if (message) {
        if(this.processSendingSlot(message, this.saturdayStartModel, this.saturdayEndModel)){
          this.saturdayStartModel.push(message.start);
          this.saturdayEndModel.push(message.end);
          this.noTimeError= false
        }
      }
    });
  }

  saturdayRemove(e) {
    e.stopPropagation();
    this.saturdayStartModel = [];
    this.saturdayEndModel = [];
  }


  // Sunday
  sundaySelectModal(){
    this.TimePickerModalRef = this.modalService.open(TimePickerModalComponent);
    this.TimePickerModalRef.onClose.subscribe((message: any) => {
      if (message) {
        if(this.processSendingSlot(message, this.sundayStartModel, this.sundayEndModel)){
          this.sundayStartModel.push(message.start);
          this.sundayEndModel.push(message.end);
          this.noTimeError= false
        }
      }
    });
  }

  sundayRemove(e) {
    e.stopPropagation();
    this.sundayStartModel = [];
    this.sundayEndModel = [];
  }
  getTimezoneGmtOffSet(timezoneItem) {
    var is_behind_gmt = timezoneItem['is_behind_gmt'] ? '-' : '+'
    var h = Math.floor(timezoneItem['gmt_offset_minute'] / 60);
    var m = timezoneItem['gmt_offset_minute'] % 60;
    var new_h = (h < 10) ? '0' + h : h;
    var new_m = (m < 10) ? '0' + m : m;
    var gmt = '(GMT' + is_behind_gmt + new_h + ':' + new_m +")";
    return timezoneItem.name + " "+ gmt;
  }


  clearMon(event: any, index){
    // event.target.value = index
    this.mondayEndModel.splice(index, 1)
    this.mondayStartModel.splice(index, 1)

  }
  clearTues(event: any, index){
    this.tuesdayEndModel.splice(index, 1)
    this.tuesdayStartModel.splice(index, 1)
  }
  clearWed(event: any, index){
    this.wednesdayEndModel.splice(index, 1)
    this.wednesdayStartModel.splice(index, 1)
  }
  clearThurs(event: any, index){
    this.thursdayEndModel.splice(index, 1)
    this.thursdayStartModel.splice(index, 1)
  }
  clearFri(event: any, index){
    this.fridayEndModel.splice(index, 1)
    this.fridayStartModel.splice(index, 1)
  }
  clearSat(event: any, index){
    this.saturdayEndModel.splice(index, 1)
    this.saturdayStartModel.splice(index, 1)
  }
  clearSun(event: any, index){
    this.sundayEndModel.splice(index, 1)
    this.sundayStartModel.splice(index, 1)
  }
}
