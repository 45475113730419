import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MdbModalRef , MdbModalService } from 'mdb-angular-ui-kit/modal';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { BehaviorSubject } from 'rxjs';
import { CampaignResponseModel } from '../campaign-model/campaign-model';
import { CampaignViewModel } from '../campaign-view-model/campaign-view-model';
import { CampaignAddComponent } from '../campaign-add/campaign-add.component';
import { CampaignsComponent } from '../campaigns.component';
@Component({
  selector: 'app-add-to-another-campaign',
  templateUrl: './add-to-another-campaign.component.html',
  styleUrls: ['./add-to-another-campaign.component.scss']
})
export class AddToAnotherCampaignComponent implements OnInit {

  @ViewChild("campaignType") campaignType: ElementRef;
  selectedCampaign: string;
  selectedCampaignObj: any;
  campaignlist = [];
  isFocused: boolean = false;
  isPasted: boolean = false;
  tempCampaignlist = [];
  fillAutoCampaign: boolean = false;
  showWarning: boolean = false;
  selectedfilters: any;
  selectedProspects: number;
  modalOptions = {
    data: {
      parentCampaignComponent: this,
      actionCaller: 'add-to-another-campaign'
    }
  }
  parentCampaignComponent: CampaignsComponent;

  campaignResponseModel = new BehaviorSubject<CampaignResponseModel>(
    new CampaignResponseModel()
  );

  constructor(
    private vm: CampaignViewModel,
    public modalRef: MdbModalRef<AddToAnotherCampaignComponent>,
    public modalRefPros: MdbModalRef<AddToAnotherCampaignComponent>,
    private router: Router ,
    private modalService: MdbModalService
    ) { }

  ngOnInit(): void {
    this.vm.parentAddToAnotherCampaignComponent = this;
    this.vm.subscribeAddToAnotherCampaignVeriables(
      this.campaignResponseModel
    )
  }

  onSelect(event: TypeaheadMatch): void {
    if (event.item.id > 0) {
      this.selectedCampaignObj = event.item;
      this.showWarning = true;
    }
  }


  onPaste(event: any) {
    this.isPasted = true;
  }

  CampaignReceived() {
    this.tempCampaignlist = this.campaignResponseModel.value.results;
    if (this.isFocused) {
      this.campaignlist = this.tempCampaignlist.slice(0, 10);
      // this.campaignlist.unshift({"id":0,"name":"Showing top 10 campaigns"});
      this.isFocused = false;
    }
    else {
      this.campaignlist = this.tempCampaignlist;
      if (this.isPasted) {

        let el: HTMLElement = this.campaignType.nativeElement;
        this.campaignType.nativeElement.blur();
        this.campaignType.nativeElement.focus();
        this.isPasted = false;
      }
    }
    if (this.fillAutoCampaign) {
      this.selectedCampaignObj = this.campaignlist[0];
      // this.selectedCampaignObj.temp_prospect = [0];
      if (Number(this.selectedCampaignObj.id) > 0) {
        this.fillAutoCampaign = false;
      }
    }
    var tempCampaign = localStorage.getItem("selectedCampaign");
    if (tempCampaign !== null) {
      this.selectedCampaign = tempCampaign;
    }
  }



  //call all prospecting group
  changeTypeaheadLoading(event) {
    if (this.selectedCampaign && this.selectedCampaign.length > 2) {
      this.vm.parentAddToAnotherCampaignComponent = this;
      this.vm.getAllCampaign(this.selectedCampaign, 'add-to-another-campaign');
    }

  }

  addToAnotherCampaign(){
    this.vm.parentAddToAnotherCampaignComponent = this;
    this.vm.addProspectsToAnotherCampaign(this.selectedfilters, this.selectedCampaignObj.id, this.selectedProspects);
  }

  CampaignsAddedToProspect(e:any) {
    this.modalRefPros.close(e);
    this.modalRefPros.onClose.subscribe((message: any) => {
      console.log(message)
      if(message){
        // this.vm.parentAddToAnotherCampaignComponent = this;
      this.vm.addProspectsToAnotherCampaign(this.selectedfilters, message.id, this.selectedProspects);
      }
     })
  }

  CreateAndAddToAnotherCampaign(){
    this.modalOptions.data.parentCampaignComponent = this;
    this.modalRefPros = this.modalService.open(CampaignAddComponent, this.modalOptions)
  }

  addedToAnotherCampaign(id:any){
    this.selectedProspects = 0;
    this.modalRef.close(id);
  }

  closeModal(){
    this.selectedProspects = 0;
    this.modalRef.close(false)
  }


}
