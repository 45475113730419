<div class="ms-sm-auto" style="width: 90%;">




  <div class="row" style="height: 100%;">

    <div class="col-2 shadow-3 border-end" style="background: #FBF7FE; height: 100vh; overflow-x:hidden;
   overflow-y: auto; z-index: 1;scrollbar-width: thin;">
      <app-sidebar-campaigns
      (userListChangeEvent)="UserRecieved($event)"
      (clientChangeEvent)="ClientRecieved($event)"
      ></app-sidebar-campaigns>
    </div>




    <div class="col-10 m-0" style="height: 100vh; overflow-x: hidden; overflow-y: auto;scrollbar-width: thin;">


      <div class="row shadow-1 p-3 sticky-top" style="z-index: 2; background: #FBF7FE;">
        <div class="col-md-4">

             <ng-container *ngIf="totalCampaignRecieved else loadingTotal">
              <h4 class="map-text-header">Campaigns
              <span style="font-size: 12px; color: #ef6c00;">({{totalCampaignRecieved}} found) </span>
            </h4>
            </ng-container>
            <ng-template #loadingTotal>
              <ng-container *ngIf="totalCampaignRecieved === 0 else spinnerCampaign ">
                <h4 class="map-text-header">Campaigns
                  <span style="font-size: 12px; color: #ef6c00;">(0 found) </span>
                </h4>
              </ng-container>
              <ng-template #spinnerCampaign>
                <app-spinner></app-spinner>
              </ng-template>
            </ng-template>

        </div>

        <div class="col-md-8">
          <div class="d-flex justify-content-end">
            <button type="button" class="btn btn-primary btn-sm " (click)="addCampaignModal()"> <i
                class="fas fa-plus"></i> Add New Campaign</button>
          </div>
        </div>
      </div>


      <div class="row">

        <!-- <hr> -->

        <div class="col-12 p-0">
          <div #campaignContainer class="table-responsive tableFixHead" infiniteScroll [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="100" (scrolled)="onCampaignScroll()" [scrollWindow]="false">
            <table class="table table-sm table-hover table-striped ">
              <thead>
                <tr>
                  <th scope="col">
                    #
                  </th>
                  <th [class]="column_selected == 'name' ? 'header-name active' : 'header-name'" scope="col"
                  (click)="sortOn(0,'name')"><i class="fas fa-sort"></i> Name</th>
                  <th [class]="column_selected == 'last_step_created_at' ? 'header-name active' : 'header-name'" scope="col"
                  (click)="sortOn(0,'last_step_created_at')"><i class="fas fa-sort"></i> Last executed at </th>

                  <th [class]="column_selected == 'prospect' ? 'header-name active' : 'header-name'" scope="col"
                  (click)="sortOn(1,'prospect')"><i class="fas fa-sort"></i> Prospect</th>

                  <th [class]="column_selected == 'campaign_step_status' ? 'header-name active' : 'header-name'" scope="col"
                  (click)="sortOn(0,'campaign_step_status')"><i class="fas fa-sort"></i> Status</th>

                  <th [class]="column_selected == 'provider' ? 'header-name active' : 'header-name'" scope="col"
                  (click)="sortOn(0,'provider')"><i class="fas fa-sort"></i> Provider</th>

                  <th [class]="column_selected == 'region_name' ? 'header-name active' : 'header-name'" scope="col"
                  (click)="sortOn(0,'region_name')"><i class="fas fa-sort"></i> Region</th>
                  <th [class]="column_selected == 'clientName' ? 'header-name active' : 'header-name'" scope="col"
                  (click)="sortOn(0,'clientName')"><i class="fas fa-sort"></i> Client</th>
                  <th [class]="column_selected == 'send_mode' ? 'header-name active' : 'header-name'" scope="col"
                  (click)="sortOn(0,'send_mode')"><i class="fas fa-sort"></i> Mode</th>

                  <th [class]="column_selected == 'created_at' ? 'header-name active' : 'header-name'" scope="col"
                  (click)="sortOn(0,'created_at')"><i class="fas fa-sort"></i> Created at</th>

                </tr>
              </thead>
              <tbody>


                <tr *ngFor="let i of campaignlist; let count=index;">
                  <td class="text-center" scope="row">{{count+1}}</td>
                  <td>
                  <p class="mb-1" style="color: #543074; font-weight: 500;"><strong>
                    <a title={{i.name}} href="/campaigns/{{i.id}}">{{i.name?.length > 30 ? (i.name | slice: 0:30) + "..." : i.name }}</a>
                  </strong></p>
                  <p class="m-0" style="color: #EF6C00; font-size: 10px;">{{getUserName(i.created_by)}}</p>
                  </td>

                  <td>
                    {{i.last_executed_at | date:'medium'}}
                  </td>
                  <td class="text-center">{{i.prospect.length}}</td>
                  <td>{{i.status}}</td>
                  <td>{{i.provider ? i.provider : 'NA'}}</td>
                  <td>{{getRegion(i)}}</td>
                  <td>{{getClientName(i)}}</td>

                  <td class="text-center">{{i.send_mode}}</td>
                    <td>{{i.created_at | date: 'medium'}}</td>


                </tr>

              </tbody>
            </table>
          </div>

        </div>


      </div>


    </div>

    <app-loading-dialog *ngIf="loadingCamp"></app-loading-dialog>
