import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { BehaviorSubject } from 'rxjs';
import { AnalyticsCampaignModel, AnalyticsCampaignResponseModel, OpenTracking, OpenTrackingResponseModel } from '../models/model';
import { DatePipe } from '@angular/common';
import { MapAPIService } from 'src/app/app-providers/services/map-api-service';
import { AnalyticsViewModel } from '../models/viewmodel';

@Component({
  selector: 'app-open-tracking-analytics',
  templateUrl: './open-tracking-analytics.component.html',
  styleUrls: ['./open-tracking-analytics.component.scss'],
  providers: [MapAPIService, AnalyticsViewModel, DatePipe],
})
export class OpenTrackingAnalyticsComponent implements OnInit {
  @ViewChild("openTrackingContainer") openTrackingContainer: ElementRef;
  @ViewChild("campaignType") campaignType: ElementRef;
  search_by_email: string = '';
  search_start_date: string;
  search_end_date: string;
  bsMaxDate = new Date();
  page_index = 1;
  fillAutoCampaign: boolean = false;
  selectedCampaign: string;
  isFocused: boolean = false;
  isPasted: boolean = false;
  campaignlist = [];
  tempCampaignlist = [];
  selectedCampaignObj = new AnalyticsCampaignModel();
  opentrackingList: [OpenTracking];
  total_lt_count: number = 0;
  typingTimer;                //timer identifier
  doneTypingInterval = 1000;  //time in ms, 5 second for example
  openTrackingResponseModel = new BehaviorSubject<OpenTrackingResponseModel>(
    new OpenTrackingResponseModel()
  );

  campaignResponseModel = new BehaviorSubject<AnalyticsCampaignResponseModel>(
    new AnalyticsCampaignResponseModel()
  );

  constructor(
    private vm: AnalyticsViewModel, private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.vm.parentOpenTrackingAnalyticsComponent = this;
    this.vm.subscribeAllOpenTrackingVariables(
      this.openTrackingResponseModel,
      this.campaignResponseModel
    );
    this.vm.getAllCampaign("", 'open-tracking');
    this.getOpenLinkList();
  }

  filterData() {
    this.page_index = 1;
    this.getOpenLinkList();
    this.openTrackingContainer.nativeElement.scrollTop = 0;
  }
  getOpenLinkList() {
    this.vm.getAllOpenTracking(this.selectedCampaignObj.id, this.search_by_email,
      this.search_start_date, this.search_end_date, this.page_index);

  }

  openTrackingReceived() {
    this.opentrackingList = this.openTrackingResponseModel.value.results;
    this.total_lt_count = this.openTrackingResponseModel.value.count;
  }

  CampaignReceived() {
    this.tempCampaignlist = this.campaignResponseModel.value.results;
    if (this.isFocused) {
      this.campaignlist = this.tempCampaignlist.slice(0, 10);
      // this.campaignlist.unshift({"id":0,"name":"Showing top 10 campaigns"});
      this.isFocused = false;
    }
    else {
      this.campaignlist = this.tempCampaignlist;
      if (this.isPasted) {

        let el: HTMLElement = this.campaignType.nativeElement;
        this.campaignType.nativeElement.blur();
        this.campaignType.nativeElement.focus();
        this.isPasted = false;
      }
    }
    if (this.fillAutoCampaign) {
      this.selectedCampaignObj = this.campaignlist[0];
      // this.selectedCampaignObj.temp_prospect = [0];
      if (Number(this.selectedCampaignObj.id) > 0) {
        this.fillAutoCampaign = false;
      }
    }
    var tempCampaign = localStorage.getItem("selectedCampaign");
    if (tempCampaign !== null) {
      this.selectedCampaign = tempCampaign;
    }
  }

  onPaste(event: any) {
    this.isPasted = true;
  }

  changeTypeaheadLoading(event) {
    if (this.selectedCampaign && this.selectedCampaign.length > 2) {
      this.vm.getAllCampaign(this.selectedCampaign, 'open-tracking');
    }
  }

  onValueChange(value: Date): void {
    if (value) {
      this.search_start_date = this.datePipe.transform(value[0], "YYYY-MM-dd");
      this.search_end_date = this.datePipe.transform(value[1], "YYYY-MM-dd");
    } else {
      this.search_start_date = undefined;
      this.search_end_date = undefined;
    }

    this.filterData();
  }


  onSelect(event: TypeaheadMatch): void {
    if (event.item.id > 0) {
      this.selectedCampaignObj = event.item;
      this.filterData();

    }
  }
  //on keyup, start the countdown
  inputkeyUp() {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(this.filterData.bind(this), this.doneTypingInterval);
  };

  searchSubmit(e: any) {
    let tempVar = '' , checkInt
    if( this.search_by_email.length > 3){
    clearTimeout(checkInt)
     checkInt = setTimeout( ()=> {
        if(tempVar.length == e.length){
          // this.page_index = 1;
          clearTimeout(this.typingTimer);
          this.typingTimer = setTimeout(this.filterData.bind(this), this.doneTypingInterval);
        }
      }, 300)
      setTimeout(() => {
        tempVar = e
      }, 200);

    }

  }

  //on keydown, clear the countdown
  inputKeyDown() {
    clearTimeout(this.typingTimer);
  };

  onOpenTrackingScroll() {
    if (this.openTrackingResponseModel.value.next) {
      this.page_index++;
      this.getOpenLinkList();
      this.openTrackingContainer.nativeElement.scrollTop = 0;
    }
  }

}
