<div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Add Prospects to Another Campaign</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
  </div>
  <div class="modal-body">

    <div class="col-12 text-center">
        <h6>Total Selected prospects ({{selectedProspects}})</h6>
        <p>Prospects will be added to selected campaign</p>
    </div>




    <div class="col-12">
        <input #campaignType [(ngModel)]="selectedCampaign" (typeaheadOnSelect)="onSelect($event)"
    (typeaheadLoading)="changeTypeaheadLoading($event)" [typeaheadMinLength]="0" [typeahead]="campaignlist"
    typeaheadOptionField="name" typeahead-wait-ms="100" class="form-control" name="Campaigns" type="text"
    (paste)="onPaste($event)" placeholder="Search by campaign" autocomplete="off" />
  <span class="highlight"></span>
    </div>

    <div class="col-12 text-center mt-4" *ngIf="showWarning">
        Are you sure want to add these prospects to <strong>{{selectedCampaign}}</strong> <br>
        <button type="button" (click)="addToAnotherCampaign()" class="btn btn-primary mt-2">Yes, Add Prospects ({{selectedProspects}})</button>
    </div>

    <div class="mt-3"></div>


  </div>
  <div class="modal-footer justify-content-between">

    <button type="button" class="btn btn-link" (click)="CreateAndAddToAnotherCampaign()">
    Create new Campaign and Prospect
    </button>
    <button type="button" class="btn btn-link" (click)="closeModal()">
    Close
    </button>

  </div>
