import { Injectable } from '@angular/core';

export class ExportCampaignModel {
  'subject': string
  'log_status': string
  'created_at': Date
  'modified_at': Date
  'recipient_email_address': string
  'sender_email_address': string
  'sender_name': string
  'email_ext_provider': string
  'email_sent_at': Date
  'email_opened_at': Date
  'email_bounced_at': Date
  'email_opted_out_at': Date
  'email_replied_at': Date
  'is_auto_reply': boolean
  'campaign_step': number
  'prospect': number
  'clv_runlog': string
}

@Injectable({
  providedIn: 'root'
})
export class ExportCampaignService {

  constructor() { }


  downloadCSV(body, filename){
    const blob: any = new Blob([body], { type: 'text/csv;charset=utf-8;' });
    let url = URL.createObjectURL(blob);
    let dwldLink = document.createElement("a");
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  
      dwldLink.setAttribute("target", "_blank");
    }
    if (dwldLink.download !== undefined) {
      dwldLink.setAttribute("href", url);
      dwldLink.setAttribute("download", filename);
      dwldLink.style.visibility = "hidden";
      document.body.appendChild(dwldLink);
      dwldLink.click();
      document.body.removeChild(dwldLink);
    }
  }

  // exportCampaign(data: ExportCampaignModel, filename: string) {
  //   let csvData = this.ConvertToCSV(data, 
  //     ['firstname', 'middlename','lastname', 'email_address', 'company', 'address', 'city', 'state', 'country', 'phone',
  //     'job_title', 'industry', 'linkedin', 'snippet', 'snippet1', 'snippet2', 'snippet3', 'snippet4', 'subject', 'log_status', 'created_at', 'modified_at', 'recipient_email_address', 'sender_email_address',
  //     'sender_name', 'email_ext_provider', 'email_sent_at', 'email_opened_at', 'email_bounced_at', 'email_opted_out_at',
  //   'email_replied_at', 'is_auto_reply', 'campaign_step', 'prospect', 'clv_runlog']
  //     );
  //   this.downloader(csvData, filename)
  // }

  // downloader(csvData: any, filename: string){
  //   let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
  //   let dwldLink = document.createElement("a");
  //   let url = URL.createObjectURL(blob);
  //   let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
  //   if (isSafariBrowser) {  
  //       dwldLink.setAttribute("target", "_blank");
  //   }
  //   dwldLink.setAttribute("href", url);
  //   dwldLink.setAttribute("download", filename + ".csv");
  //   dwldLink.style.visibility = "hidden";
  //   document.body.appendChild(dwldLink);
  //   dwldLink.click();
  //   document.body.removeChild(dwldLink);
  // }

  // ConvertToCSV(objArray: any, headerList: any) {
  //   let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray; 
  
  //   let str = '';
  //   let row = '';
  
  //   for (let index in headerList) {
  //       row += headerList[index] + ',';
  //   }
  //   row = row.slice(0, -1);
  //   str += row + '\r\n';
  //   for (let i = 0; i < array.length; i++) {
        
  //       let line = '';
  //       for (let index in headerList) {
  //         let head = headerList[index]; 
  //         line += array[i][head] + ',';
  //       }
  //       str += line + '\r\n';
  //   }
  //   return str;
  // }
  
}
