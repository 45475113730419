

<div class="ms-sm-auto" style="width: 90%;">

    <div class="row" style="height: 100%;">

      <div class="col-2 shadow-3" style="background: #FBF7FE; height: 100vh; overflow-x: hidden; overflow-y: auto; ">

            <app-sidebar-settings></app-sidebar-settings>
        </div>




      <div class="col-10 p-3" style="height: 100vh; overflow-x: hidden; overflow-y: auto; ">


        <div class="d-flex mb-2">
            <div class="me-auto"> <h4>Add Sending Window</h4>  </div>


            <button type="button" class="btn btn-primary btn-sm" (click)="closewindow()">
            Close</button>


          </div>
          <hr>

          <p style="margin-top: 1.5%;">
           Please add sending window here.
          </p>



          <div class="row">
            <div class="col-6">
                <mdb-form-control>
                    <input mdbInput [(ngModel)]="sendingWindowNameModel" (change)="nameChange()" type="text" id="sendingWindowName" class="form-control" />
                    <label mdbLabel class="form-label" for="sendingWindowName">Name</label>
                </mdb-form-control>
                <small style="font-size: 10px;"> <b>*</b>Special characters (@#$&*-_ etc.) are not allowed. </small>
                <p class="text-danger small" *ngIf="specialCharError"> No special characters allowed. Please try again after removing special character. </p>
                <ng-container *ngIf="nameError">
                    <div class="col-12 text-left text-danger">
                        Please enter a name *
                    </div>
                </ng-container>
            </div>

            <div class="col-6">
                <!-- <ng-select2 [data]="TimezoneData" [(ngModel)]="timezoneSelectModel"
                [width]="500" aria-placeholder=" Please select a timezone" placeholder="Please select a timezone">
                </ng-select2> -->


                <ng-select [(ngModel)]="timezoneSelectModel" (change)="timezoneChange()" aria-placeholder=" Please select a timezone" placeholder="Please select a timezone">
                    <ng-option *ngFor="let timezone of TimezoneData" [value]="timezone.id">{{timezone.text}}</ng-option>
                 </ng-select>

                <ng-container *ngIf="timezoneError">
                    <div class="col-12 text-left text-danger">
                        Please select a timezone
                    </div>
                </ng-container>




            </div>


          </div>

          <div class="row mt-4 ps-4 d-flex">
            <div class="col-1 dayItem">
                <p class="font-weight-bold"> Monday: </p>
            </div>

            <ng-container *ngIf="mondayStartModel.length > 0">
                <div class="col-2" *ngFor="let item of mondayStartModel; let i=index">
                    <!-- <h6>{{mondayStartModel.time_display}} - {{mondayEndModel.time_display}}</h6> -->
                    <button type="button" class="btn btn-primary">{{displayTime(item.time_display, mondayEndModel[i].time_display)}}
                        <span value={{i}} (click)="clearMon($event, i)">
                            <i class="far fa-times-circle fa-1x"></i>
                        </span>
                    </button>
                </div>
                <div class="col-2 text-white">
                    <button (click)="mondaySelectModal()" type="button" class="btn btn-sm btn-outline-primary snAddButton">
                        <i class="far fa-clock"></i> Add
                    </button>
                </div>
            </ng-container>

            <ng-container *ngIf="mondayStartModel.length == 0">
                <div class="col-6 text-white">
                    <button (click)="mondaySelectModal()" type="button" class="btn btn-sm btn-outline-primary snAddButton">
                        <i class="far fa-clock"></i> Add
                    </button>
                </div>
            </ng-container>

            <div class="col-2">
                <p (click)="mondayRemove()" class="snRemoveTime"><i class="far fa-times-circle fa-1x"></i> Clear</p>
            </div>

        </div>



        <div class="row mt-2 ps-4 d-flex">
            <div class="col-1 dayItem">
              <p class="font-weight-bold">Tuesday:</p>
            </div>

            <ng-container *ngIf="tuesdayStartModel.length > 0">

                <div class="col-2" *ngFor="let item of tuesdayStartModel; let i=index">
                    <button type="button" class="btn btn-primary">{{displayTime(item.time_display, tuesdayEndModel[i].time_display)}}
                        <span value={{i}} (click)="clearTues($event, i)">
                            <i class="far fa-times-circle fa-1x"></i>
                        </span>
                    </button>
                </div>
                <div class="col-2 text-white">
                    <button (click)="tuesdaySelectModal()" type="button" class="btn btn-sm btn-outline-primary snAddButton">
                        <i class="far fa-clock"></i> Add
                    </button>
                </div>
            </ng-container>

            <ng-container *ngIf="tuesdayStartModel.length == 0">
                <div class="col-6 text-white">
                    <button (click)="tuesdaySelectModal()" type="button" class="btn btn-sm btn-outline-primary snAddButton">
                        <i class="far fa-clock"></i> Add
                    </button>
                </div>
            </ng-container>

            <div class="col-2">
                <p (click)="tuesdayRemove()" class="snRemoveTime"><i class="far fa-times-circle fa-1x"></i> Clear</p>
            </div>
        </div>




        <div class="row mt-2 ps-4 d-flex">
            <div class="col-1 dayItem">
              <p class="font-weight-bold">Wednesday:</p>
            </div>

            <!-- <ng-container *ngIf="wednesdayStartModel"> -->
            <ng-container *ngIf="wednesdayStartModel.length > 0">
                <!-- <div class="col-6"> -->
                <div class="col-2" *ngFor="let item of wednesdayStartModel ; let i=index">
                    <button type="button" class="btn btn-primary">{{displayTime(item.time_display, wednesdayEndModel[i].time_display) }}
                        <span value={{i}} (click)="clearWed($event, i)">
                            <i class="far fa-times-circle fa-1x"></i>
                        </span>
                    </button>
                </div>
                <div class="col-2 text-white">
                    <button (click)="wednesdaySelectModal()" type="button" class="btn btn-sm btn-outline-primary snAddButton">
                        <i class="far fa-clock"></i> Add
                    </button>
                </div>
            </ng-container>

            <ng-container *ngIf="wednesdayStartModel.length == 0">
                <div class="col-6 text-white">
                    <button (click)="wednesdaySelectModal()" type="button"
                        class="btn btn-sm btn-outline-primary snAddButton">
                        <i class="far fa-clock"></i> Add
                    </button>
                </div>
            </ng-container>
            <div class="col-2">
                <p (click)="wednesdayRemove()" class="snRemoveTime"><i class="far fa-times-circle fa-1x"></i> Clear</p>
            </div>
        </div>



        <div class="row mt-2 ps-4 d-flex">
            <div class="col-1 dayItem">
              <p class="font-weight-bold">Thursday:</p>
            </div>

            <ng-container *ngIf="thursdayStartModel.length > 0">

                <div class="col-2" *ngFor="let item of thursdayStartModel; let i=index">
                    <button type="button" class="btn btn-primary">{{displayTime(item.time_display, thursdayEndModel[i].time_display)}}
                        <span value={{i}} (click)="clearThurs($event, i)">
                            <i class="far fa-times-circle fa-1x"></i>
                        </span>
                    </button>
                </div>
                <div class="col-2 text-white">
                    <button (click)="thursdaySelectModal()" type="button" class="btn btn-sm btn-outline-primary snAddButton">
                        <i class="far fa-clock"></i> Add
                    </button>
                </div>
            </ng-container>

            <ng-container *ngIf="thursdayStartModel.length == 0">
                <div class="col-6 text-white">
                    <button (click)="thursdaySelectModal()" type="button" class="btn btn-sm btn-outline-primary snAddButton">
                        <i class="far fa-clock"></i> Add
                    </button>
                </div>
            </ng-container>

            <div class="col-2">
                <p (click)="thursdayRemove()" class="snRemoveTime"><i class="far fa-times-circle fa-1x"></i> Clear</p>
            </div>
        </div>



        <div class="row mt-2 ps-4 d-flex">
            <div class="col-1 dayItem">
              <p class="font-weight-bold">Friday:</p>
            </div>

            <ng-container *ngIf="fridayStartModel.length > 0">
                <div class="col-2" *ngFor="let item of fridayStartModel; let i=index">
                    <button type="button" class="btn btn-primary">{{displayTime(item.time_display, fridayEndModel[i].time_display)}}
                        <span value={{i}} (click)="clearFri($event, i)">
                            <i class="far fa-times-circle fa-1x"></i>
                        </span>
                    </button>
                </div>
                <div class="col-2 text-white">
                    <button (click)="fridaySelectModal()" type="button" class="btn btn-sm btn-outline-primary snAddButton">
                        <i class="far fa-clock"></i> Add
                    </button>
                </div>
            </ng-container>

            <ng-container *ngIf="fridayStartModel.length == 0">
                <div class="col-6 text-white">
                    <button (click)="fridaySelectModal()" type="button" class="btn btn-sm btn-outline-primary snAddButton">
                        <i class="far fa-clock"></i> Add
                    </button>
                </div>
            </ng-container>
            <div class="col-2">
                <p (click)="fridayRemove()" class="snRemoveTime"><i class="far fa-times-circle fa-1x"></i> Clear</p>
            </div>
        </div>



        <div class="row mt-2 ps-4 d-flex">
            <div class="col-1 dayItem">
              <p class="font-weight-bold">Saturday:</p>
            </div>

            <ng-container *ngIf="saturdayStartModel.length > 0">
                <div class="col-2" *ngFor="let item of saturdayStartModel; let i=index">
                    <button type="button" class="btn btn-primary">{{displayTime(item.time_display, saturdayEndModel[i].time_display)}}
                        <span value={{i}} (click)="clearSat($event, i)">
                            <i class="far fa-times-circle fa-1x"></i>
                        </span>
                    </button>
                </div>
                <div class="col-2 text-white">
                    <button (click)="saturdaySelectModal()" type="button" class="btn btn-sm btn-outline-primary snAddButton">
                        <i class="far fa-clock"></i> Add
                    </button>
                </div>
            </ng-container>

            <!-- <ng-container *ngIf="!saturdayStartModel"> -->
            <ng-container *ngIf="saturdayStartModel.length == 0">
                <div class="col-6 text-white">
                    <button (click)="saturdaySelectModal()" type="button"
                        class="btn btn-sm btn-outline-primary snAddButton">
                        <i class="far fa-clock"></i> Add
                    </button>
                </div>
            </ng-container>
            <div class="col-2">
                <p (click)="saturdayRemove()" class="snRemoveTime"><i class="far fa-times-circle fa-1x"></i> Clear</p>
            </div>
        </div>


        <div class="row mt-2 ps-4 d-flex">
            <div class="col-1 dayItem">
              <p class="font-weight-bold">Sunday:</p>
            </div>

            <ng-container *ngIf="sundayStartModel.length > 0">
                <div class="col-2" *ngFor="let item of sundayStartModel; let i=index">
                    <button type="button" class="btn btn-primary">{{displayTime(item.time_display, sundayEndModel[i].time_display)}}
                        <span value={{i}} (click)="clearSun($event, i)">
                            <i class="far fa-times-circle fa-1x"></i>
                        </span>
                    </button>
                </div>
                <div class="col-2 text-white">
                    <button (click)="sundaySelectModal()" type="button" class="btn btn-sm btn-outline-primary snAddButton">
                        <i class="far fa-clock"></i> Add
                    </button>
                </div>
            </ng-container>

            <ng-container *ngIf="sundayStartModel.length == 0">
                <div class="col-6 text-white">
                    <button (click)="sundaySelectModal()" type="button" class="btn btn-sm btn-outline-primary snAddButton">
                        <i class="far fa-clock"></i> Add
                    </button>
                </div>
            </ng-container>
            <div class="col-2">
                <p (click)="sundayRemove()" class="snRemoveTime"><i class="far fa-times-circle fa-1x"></i> Clear</p>
            </div>
        </div>

        <ng-container *ngIf="noTimeError">
                    <div class="col-12 text-center text-danger mt-3">
                      Please Add atleast one Time slot.
                    </div>
         </ng-container>
            <div class="modal-footer">
                <button (click)="createNewSendingWindow()" type="button" class="btn btn-primary">Create</button>
            </div>
        </div>

    </div>

</div>

<div class="row">
    <button type="button" class="btn btn-link">
        Close
    </button>
    <button (click)="createNewSendingWindow()"   type="button" class="btn btn-primary">Create</button>
</div>



    <!-- <select [(ngModel)]="timezoneSelectModel" class="form-select mt-2" aria-label="Select Time Zone">
    <option value="0">Select a timezone *</option>
      <option *ngFor="let opt of scheduleTimezoneList" value="{{opt.id}}">{{opt.name}}
          <span>({{getTimezoneGmtOffSet(opt)}})</span></option>
  </select> -->




