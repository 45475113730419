<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Mark DNC</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">

  <div class="ps-2">
    <div class="form-check">
      <input mdbCheckbox class="form-check-input" type="checkbox" value="" 
      id="globalDncCheckBox" [(ngModel)]="checked" (change)="checkValue(checked)" />
      <label class="form-check-label" for="globalDncCheckBox">
        Global
      </label>
    </div>
  </div>
  
    <!-- <div class="container"> -->

    <div class="pt-3"></div>
    <select class="form-select" aria-label="Default select example" [(ngModel)]="clientId" [disabled]="checked">
      <option value='0'>Select Client</option>
      <option *ngFor="let c of clientList" [value]="c.id">{{c.name}}</option>
    </select>

    <div class="pt-3"></div>
    <div class="col-12">
      <div *ngIf="clientId">
        <p class="ps-2">Type</p>
        <div class="row">
          <div class="col">
            <div class="ps-2">
              <div class="form-check mt-1">
                <input value="1" mdbRadio class="form-check-input" type="radio" 
                [(ngModel)]="selectedProspectType" [name]="prospectType_1" id="checkboxItemAttendee" />
                <label class="form-check-label mt-1" for="checkboxItemAttendee">Attendee</label>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="ps-2">
              <div class="form-check mt-1">
                <input value="2" mdbRadio class="form-check-input" type="radio" 
                [(ngModel)]="selectedProspectType" [name]="prospectType_2" id="checkboxItemSponsor" />
                <label class="form-check-label mt-1" for="checkboxItemSponsor">Sponsor</label>
              </div>
            </div>
          </div> 

        
          <!-- <div class="col">
            <div class="ps-2">
              <div class="form-check">
                <input mdbCheckbox class="form-check-input" type="checkbox" name="prospecttype"
                id="attendeeCheckBox"(change)="changeProspectType('attendee')" />
                <label class="form-check-label" for="attendeeCheckBox">
                  Attendee
                </label>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="ps-2">
              <div class="form-check">
                <input mdbCheckbox class="form-check-input" type="checkbox" name="prospecttype"
                id="sponsorCheckBox" (change)="changeProspectType('sponsor')" />
                <label class="form-check-label" for="sponsorCheckBox">
                  Sponnsor
                </label>
              </div>
            </div>
          </div> -->
        </div>
      </div>

    <div class="pt-3"></div>
    <mdb-form-control>
      <textarea mdbInput class="form-control" id="textAreaExample" rows="4"  [(ngModel)]="dnc_reason" ></textarea>
      <label mdbLabel class="form-label" for="textAreaExample">Reason for DNC</label>
    </mdb-form-control>
  </div>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-link" (click)="modalRef.close()">
    Close
  </button>
  <button type="button" class="btn btn-primary" (click)="saveChange()">Save changes</button>
</div>