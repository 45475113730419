import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observer } from "rxjs";
import { AlertNotificationsComponent } from "src/app/Alerts/alert-notifications/alert-notifications.component";
import { MapAPICollection } from "src/app/app-providers/services/map-api-collection";
import { IApiServiceDelegate } from "src/app/app-providers/services/map-api-IApiServiceDelegate";
import { MapAPIService } from "src/app/app-providers/services/map-api-service";
import { DashboardComponent } from "../dashboard.component";
import { CampaignResponseModel, DashboardResponseModel } from "./dashboard-model";



@Injectable({
    providedIn: "root",
})

export class DashboardViewModel {
    parentDashboardComponent: DashboardComponent;


    dashboardResponseModel = new BehaviorSubject<DashboardResponseModel>(
        new DashboardResponseModel()
    );

    campaignResponseModel = new BehaviorSubject<CampaignResponseModel>(
        new CampaignResponseModel()
    );

    constructor(private apiService: MapAPIService) { }

    subscribeToDashboardVariable(observerDashboardResponseModel: Observer<DashboardResponseModel>,
        observerCampaignResponseModel: Observer<CampaignResponseModel>) {
        this.dashboardResponseModel.subscribe(observerDashboardResponseModel)
        this.campaignResponseModel.subscribe(observerCampaignResponseModel)
    }

    getAllDashboardEntities(selectedOverviewButton) {
        var url = MapAPICollection.GET_ALL_DASHBOARD_ENTITIES;
        if(selectedOverviewButton != 'all' || !selectedOverviewButton){
            url += "?time_interval=" + selectedOverviewButton;
        }
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: url,
            Success: (e: any) => {
                this.dashboardResponseModel.next(e);
                this.parentDashboardComponent.receivedDashboardOverview();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading dashboard entities', 'danger');
                return e;
            },
        };
       this.apiService.callService(d);
    }

    getTopCampaign(page_size) {
        var str = "?page=1&page_size=" + page_size;

        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GET_CAMPAIGN + str,
            Success: (e: any) => {
                this.campaignResponseModel.next(e);
                // this.parentDashboardComponent.globalDncReceived();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading campaigns', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }


    getGrafanaPanel(url){
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: url,
            Success: (e: any) => {
                console.log(e)
                // this.campaignResponseModel.next(e);
                // this.parentDashboardComponent.globalDncReceived();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading campaigns', 'danger');
                return e;
            },
        };
        this.apiService.callGrafanaService(d);
    }

}