import { Component, OnInit ,OnDestroy, Output , EventEmitter  } from '@angular/core';
import { MapAPIService } from "src/app/app-providers/services/map-module";
import { Router } from '@angular/router';
import { CampaignModel, CampaignUserModel, CampaignClientResponseModel, RegionResponseModel } from '../campaign-model/campaign-model';
import { BehaviorSubject } from 'rxjs';
import { CampaignViewModel } from '../campaign-view-model/campaign-view-model';
import { ConfirmDialogService } from '../../admin/confirm-dialog/confirm-dialog.service';
import { CampaignDetailSummaryComponent } from '../campaign-detail-summary/campaign-detail-summary.component';
import { Subject } from "rxjs";
@Component({
  selector: 'app-campaign-detail',
  templateUrl: './campaign-detail.component.html',
  styleUrls: ['./campaign-detail.component.scss']
})
export class CampaignDetailComponent implements OnInit , OnDestroy {

  data: any = {};
  routeAllData: any;
  campaignDetails: any = {};
  userEmail: string;
  clientName: string;
  static parentCall;
  campiagnStatus: boolean = false;
  campaignData = new CampaignModel();
  campaignId: number;
  userList = [];
  clientList = [];
  usersendingLimit: number = null;
  campaignUser: CampaignUserModel;
  showLimitReached: boolean = null
  showSignature :boolean = false;
  campaignStatus:string = ''
  sendersID = []
  campaignModel = new BehaviorSubject<CampaignModel>(
    new CampaignModel()
  );

  campaignUserModel = new BehaviorSubject<CampaignUserModel>(
    new CampaignUserModel()
  );

  campaignClientResponseModel = new BehaviorSubject<CampaignClientResponseModel>(
    new CampaignClientResponseModel()
  );

  regionResponseModel = new BehaviorSubject<RegionResponseModel>(
    new RegionResponseModel()
  );

  constructor(private router: Router, private vm: CampaignViewModel, private confirmDialogService: ConfirmDialogService,) {


    if (this.router.getCurrentNavigation().extras.state) {
      this.routeAllData = this.router.getCurrentNavigation().extras.state;
      if (this.routeAllData) {
        this.data.routeData = this.routeAllData.routeData ? JSON.parse(this.routeAllData.routeData) : '';
        this.userEmail = this.routeAllData.userEmail ? this.routeAllData.userEmail : '';
        this.clientName = this.routeAllData.clientName ? this.routeAllData.clientName : '';
      }
    }
    CampaignDetailComponent.parentCall = this;
  }

  ngOnInit(): void {
    this.vm.parentCampaignDetailComponent = this;
    var url = window.location.href;
    this.campaignId = Number(url.substring(url.lastIndexOf('/') + 1));
    this.vm.subscribeCampaignDetailVariables(
      this.campaignModel,
      this.campaignUserModel,
      this.campaignClientResponseModel,
      this.regionResponseModel
      );
    this.vm.getAllClients('compaign-details');
    this.vm.getRegions();


  }

  ngOnDestroy(): void {
    sessionStorage.removeItem("campaignProspectReportRunlogStatsTime")
    sessionStorage.removeItem("campaignProspectReportRunlogStats")
    sessionStorage.removeItem("campaignReportStatsRecieved")
    sessionStorage.removeItem("campaignReportStatsTime")
    sessionStorage.removeItem("campaignReportRunlogStats")
    sessionStorage.removeItem("campaignReportRunlogStatsTime")
  }

  limitReached(e){
    this.showLimitReached = e
    console.log(e)
  }

  getRegion(region: number){
    if(region){
      const item = this.regionResponseModel.value.results.find(el => el.id == region)
      if (item){
        return item.name
      } else {

        return region
      }
    } else {
      return '-'
    }
  }


  gotoProspectPage()
  {
    localStorage.setItem("selectedCampaign", this.campaignDetails.name);
    this.router.navigate(['prospects']);
  }

  getClientNameUsingId(id) {
    var clientItem = this.clientList.filter(i => i.id == Number(id));
    if (clientItem.length > 0) {
      return clientItem[0].name;
    }
  }
  getClientId(id) {
    var clientItem = this.clientList.filter(i => i.id == Number(id));
    if (clientItem.length > 0) {
      return clientItem[0].id;
    }
  }
  ConfirmStatusChanged(e) {
    this.confirmDialogService.confirmThis("Are you sure you want to change campaign status?", function () {
      CampaignDetailComponent.parentCall.statusChanged();
    }, function () {
      if (CampaignDetailComponent.parentCall.campaignData.status == 'Active') {
        e.target.checked = true;
        CampaignDetailComponent.parentCall.campiagnStatus = true;
      }
      else {
        e.target.checked = false;
        CampaignDetailComponent.parentCall.campiagnStatus = false;
      }

    })

  }



  CampaignDetailReceived() {
    this.campaignDetails = this.campaignModel.value;
    this.campaignData = this.campaignDetails;
    this.showSignature = this.campaignData.senders.length > 0
    this.vm.getUserById(this.campaignModel.value.created_by);
    this.clientName = this.getClientNameUsingId(this.campaignData.client);
    this.sendersID =this.campaignDetails.senders
    this.campaignStatus = this.campaignDetails.campaign_step_status
    console.log(this.campaignData.senders.length > 0 , this.campaignData.senders.length )
    if (this.campaignDetails.schedule === null) {

    } else {
      CampaignDetailSummaryComponent.siblingCall.callSendingWindow(this.campaignDetails.schedule);
    }

    if (this.campaignData.status == 'Active')
      {this.campiagnStatus = true}
    else
      {this.campiagnStatus = false}

    CampaignDetailSummaryComponent.siblingCall.storeCampaignSendingMode(this.campaignDetails.send_mode);

    }



  ClientRecieved() {
    this.clientList = this.campaignClientResponseModel.value.results;
    this.vm.getCampaignById(this.campaignId, 'campaign-detail');
  }

  statusChanged() {
    var updatedStatus = this.campaignData.status == 'Active' ? 'Inactive' : 'Active';
    this.campaignData.status = updatedStatus;

    this.vm.updateCampaign(this.campaignData);

    this.campaignDetails.status = updatedStatus;

    if (this.campaignData.status == 'Active')
      this.campiagnStatus = true;
    else
      this.campiagnStatus = false;
  }

  getData() {
    return Object.assign({}, this.campaignDetails);
  }

  getClientName() {
    return this.clientName;
  }

  UserRecieved() {
    this.userEmail = this.campaignUserModel.value.email;
    this.campaignUser = this.campaignUserModel.value;

    if(this.campaignUser.mailbox_connection.is_connected != false){
      var awsLimit = this.campaignUser.mailbox_connection.send_limit.filter(i => i.provider == 'AWSSes')[0]
      var gmailLimit = this.campaignUser.mailbox_connection.send_limit.filter(i => i.provider == 'Gmail')[0]
      var outlookLimit = this.campaignUser.mailbox_connection.send_limit.filter(i => i.provider == 'Outlook')[0]
      var sendgridLimit = this.campaignUser.mailbox_connection.send_limit.filter(i => i.provider == 'SendGrid')[0]
      if (awsLimit) {
        if (awsLimit.limit_remaining == 0 || awsLimit.limit_remaining < 100) {
          this.showLimitReached = true;
        }
      }

      if (gmailLimit) {
        if (gmailLimit.limit_remaining == 0 || gmailLimit.limit_remaining < 100) {
          this.showLimitReached = true;
        }
      }

      if (outlookLimit) {
        if (outlookLimit.limit_remaining == 0 || outlookLimit.limit_remaining < 100) {
          this.showLimitReached = true;
        }
      }

      if (sendgridLimit) {
        if (sendgridLimit.limit_remaining == 0 || sendgridLimit.limit_remaining < 100) {
          this.showLimitReached = true;
        }
      }

    }



  }

  updateName(newName) {
    this.campaignDetails.name = newName;
  }


}
