


    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabel">Add to campaign</h5>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="modalRef.close()"
      ></button>
    </div>
    <div class="modal-body">

      <div class="col-12">
        <input #campaignType [(ngModel)]="selectedCampaign"  (typeaheadOnSelect)="onSelect($event)"  typeahead-wait-ms="100"
          (typeaheadLoading)="changeTypeaheadLoading($event)" [typeahead]="campaignlist" typeaheadOptionField="name"
          class="form-control" name="Campaigns" type="text" placeholder="Select a campaign"  [typeaheadMinLength]="0"
          (paste)="onPaste($event)" autocomplete="off"/>
        <span class="highlight"></span>
      </div>
    
      <div class="col-12 mt-3">
    
            <div class="col d-flex justify-content-center">
              <button type="button" class="btn btn-link" (click)="modalRef.close()">
                Close
              </button>
                <button type="button" class="btn btn-primary" (click)="addToCampaign()">Add to Campaign</button>
            </div>

    </div>
   
    <!-- <div class="modal-footer">
     
      <button type="button" class="btn btn-primary" (click)="addToCampaign()">Add to Campaign</button>
    </div> -->