
<app-loading-dialog *ngIf="loadingSpiner">

</app-loading-dialog>

<!-- <div class="col-12 pe-3 ps-3">
 <p class="text-end"> Last fetched at {{ timeFetched | date :'shortTime' }} <i class=" px-2 fas fa-redo-alt text-danger" style="cursor:pointer;" (click)="fetchData()"></i> </p>
</div> -->

<div class="col-12 pe-3 ps-3">


  <div class="col-12">

    <div style="overflow-y: auto;" class="pb-2">
    <div class="btn-group btn-group-lg shadow-1 prospectStats" role="group" aria-label="prospectStats">
      <button type="button"
        [class]="tab_selected == 'All' ? 'btn prospectStatsManuItemActive' : 'btn prospectStatsManuItem'"
        (click)="filterTabClicked('All',0)">All</button>

      <button type="button" *ngFor="let csd of campaignStepDetails; let count=index;"
        [class]="tab_selected == csd.id ? 'btn prospectStatsManuItemActive' : 'btn prospectStatsManuItem'"
        (click)="filterTabClicked(csd.id,csd.id)">Step {{count+1}}</button>

    </div>
    </div>

    <div class="col-12 mt-3">
      <div class="btn-group btn-group-lg shadow-1 prospectStats" role="group" aria-label="prospectStats">
        <button type="button"
          [class]="btn_selected == 'Total' ? 'btn prospectStatsManuItemActive' : 'btn prospectStatsManuItem'"
          id="btn_total" (click)="filterButtonClick('Total')">Total <br><span
            class="prospectStatsNumber">{{cnt_email_total}}</span><br><span class="text-muted">100.00%</span></button>
        <button type="button"
          [class]="btn_selected == 'New' ? 'btn prospectStatsManuItemActive' : 'btn prospectStatsManuItem'" id="btn_New"
          (click)="filterButtonClick('New')">New <br><span class="prospectStatsNumber">{{cnt_new}}</span>
          <br>
          <span class="text-muted">{{cnt_email_total == 0 ? 0 :
           ((cnt_new/cnt_email_total)*100).toFixed(2)}}%</span></button>
        <button type="button"
          [class]="btn_selected == 'Processing' ? 'btn prospectStatsManuItemActive' : 'btn prospectStatsManuItem'"
          id="btn_Processing" (click)="filterButtonClick('Processing')">{{campiagnStatus == true ? 'Processing' :
          'Paused'}}
          <br><span class="prospectStatsNumber">{{cnt_processing}}</span><br>
          <span class="text-muted">{{cnt_email_total == 0 ? 0 :
           ((cnt_processing/cnt_email_total)*100).toFixed(2)}}%</span></button>
        <button type="button"
          [class]="btn_selected == 'Scheduled' ? 'btn prospectStatsManuItemActive' : 'btn prospectStatsManuItem'"
          id="btn_Scheduled" (click)="filterButtonClick('Scheduled')">Scheduled <br><span
            class="prospectStatsNumber">{{cnt_scheduled}}</span><br>
            <span class="text-muted">{{cnt_email_total == 0 ? 0 :
             ((cnt_scheduled/cnt_email_total)*100).toFixed(2)}}%</span></button>
        <button type="button"
          [class]="btn_selected == 'Finished' ? 'btn prospectStatsManuItemActive' : 'btn prospectStatsManuItem'"
          id="btn_Finished" (click)="filterButtonClick('Finished')">Sent <br><span
            class="prospectStatsNumber">{{cnt_sent}}</span><br>
            <span class="text-muted">{{cnt_email_total == 0 ? 0 :
             ((cnt_sent/cnt_email_total)*100).toFixed(2)}}%</span></button>

            <button type="button"
            [class]="btn_selected == 'NotOpened' ? 'btn prospectStatsManuItemActive' : 'btn prospectStatsManuItem'"
            id="btn_Opened" (click)="filterButtonClick('NotOpened')">Unopened <br><span
              class="prospectStatsNumber">{{cnt_notopened}}</span><br>
              <span class="text-muted">{{cnt_email_total == 0 ? 0 :
               ((cnt_notopened/cnt_email_total)*100).toFixed(2)}}%</span></button>

        <button type="button"
          [class]="btn_selected == 'Opened' ? 'btn prospectStatsManuItemActive' : 'btn prospectStatsManuItem'"
          id="btn_Opened" (click)="filterButtonClick('Opened')">Opened <br><span
            class="prospectStatsNumber">{{cnt_opened}}</span><br>
            <span class="text-muted">{{cnt_email_total == 0 ? 0 :
             ((cnt_opened/cnt_email_total)*100).toFixed(2)}}%</span></button>

        <button type="button"
          [class]="btn_selected == 'Replied' ? 'btn prospectStatsManuItemActive' : 'btn prospectStatsManuItem'"
          id="btn_Replied" (click)="filterButtonClick('Replied')">Replied <br><span
            class="prospectStatsNumber">{{cnt_replied}}</span><br>
            <span class="text-muted">{{cnt_email_total == 0 ? 0 :
             ((cnt_replied/cnt_email_total)*100).toFixed(2)}}%</span></button>
        <button type="button"
          [class]="btn_selected == 'Bounced' ? 'btn prospectStatsManuItemActive' : 'btn prospectStatsManuItem'"
          id="btn_Bounced" (click)="filterButtonClick('Bounced')">Bounced <br><span
            class="prospectStatsNumber">{{cnt_bounced}}</span><br>
            <span class="text-muted">{{cnt_email_total == 0 ? 0 :
             ((cnt_bounced/cnt_email_total)*100).toFixed(2)}}%</span></button>
        <button type="button"
          [class]="btn_selected == 'OptedOut' ? 'btn prospectStatsManuItemActive' : 'btn prospectStatsManuItem'"
          id="btn_OptedOut" (click)="filterButtonClick('OptedOut')">Opted Out <br><span
            class="prospectStatsNumber">{{cnt_opted_out}}</span><br>
            <span class="text-muted">{{cnt_email_total == 0 ? 0 :
             ((cnt_opted_out/cnt_email_total)*100).toFixed(2)}}%</span></button>
        <button type="button"
          [class]="btn_selected == 'Rejected' ? 'btn prospectStatsManuItemActive' : 'btn prospectStatsManuItem'"
          id="btn_Rejected" (click)="filterButtonClick('Rejected')">Rejected <br><span
            class="prospectStatsNumber">{{cnt_rejected}}</span><br>
            <span class="text-muted">{{cnt_email_total == 0 ? 0 :
             ((cnt_rejected/cnt_email_total)*100).toFixed(2)}}%</span></button>
      </div>
    </div>


  </div>


</div>

<div class="col-12 pe-3 ps-3 mt-3">
  <div class="d-flex justify-content-between">
    <div class="col-3">
      <mdb-form-control>
        <input mdbInput type="text" id="form1" class="form-control form-control-sm" 
        (keyup)="inputkeyUp()"
        (keydown)="inputKeyDown()"        
          [(ngModel)]="emailContainsText" value="{{emailContainsText}}" />
        <label mdbLabel class="form-label" for="form1"><i class="fas fa-search"></i> Search</label>
      </mdb-form-control>
    </div>

    <div class="col-3">
      <select *ngIf="ShowRestriction" [(ngModel)]="selectedRestriction" (change)="changeRestriction()"
      class="form-select form-select-sm" aria-label=".form-select-sm example">
      <option selected value="Restricted">All Restriction Selected</option>
      <option value="Restricted-CLV">CLV Restriction</option>
      <option value="Restricted-Global-DNC">Global DNC Restriction</option>
      <option value="Restricted-Client-DNC">Client DNC Restriction</option>
      <option value="Restricted-OOO">Out Of Office Restriction</option>
      <option value="Restricted-Prospect-SL">Prospect Send limit Restriction</option>
      <option value="Error-Service">Service Errors</option>
    </select>
    </div>

    <button *ngIf="campaignRunLogList.length > 0 && (btn_selected == 'Finished' || btn_selected == 'NotOpened' || btn_selected == 'Opened' || btn_selected == 'Replied')" (click)="addToAnotherCampaign()" class="btn btn-link">
      <i class="fas fa-users" style="color: #EF6C00;"></i> Add to another campaign</button>

    <button (click)="exportAll()" class="btn btn-link">
      <i class="fas fa-cloud-download-alt" style="color: #EF6C00;"></i> Export All</button>
  </div>
</div>


<ng-container *ngIf="cnt_email_total >= 1 && campaignRunLogList.length >= 1">

  <div class="col-12 pe-3 ps-3 mt-3">

    <div class="table-resposive tableFixHead" infiniteScroll [infiniteScrollDistance]="2" #runlogContainer
[infiniteScrollThrottle]="1000" (scrolled)="onEmailScroll()" [scrollWindow]="false">

    <table class="table bg-white shadow-1 table-sm table-hover  table-bordered">
      <thead style="background-color: rgb(240, 240, 240);">
        <tr>

          <th scope="col">Prospect Email</th>
          <th scope="col">Sender</th>
          <th scope="col">Step</th>

          <th [class]="column_selected == 'email_sent_at' ? 'header-name active' : 'header-name'" scope="col"
          (click)="sortOn(0,'email_sent_at')"><i class="fas fa-sort"></i> Sent At</th>

          <th *ngIf="(btn_selected == 'Opened') || (btn_selected == 'Replied')"
          [class]="column_selected == 'email_opened_at' ? 'header-name active' : 'header-name'" scope="col"
                  (click)="sortOn(0,'email_opened_at')"><i class="fas fa-sort"></i>
            Open at</th>

          <th *ngIf="(btn_selected == 'Opened') || (btn_selected == 'Replied')"
          [class]="column_selected == 'cnt_email_open' ? 'header-name active' : 'header-name'"
          (click)="sortOn(0,'cnt_email_open')" scope="col">
            <i class="fas fa-sort"></i>
            Opened</th>

            <th *ngIf="(btn_selected == 'Opened') || (btn_selected == 'Replied')"
            [class]="column_selected == 'email_replied_at' ? 'header-name active' : 'header-name'" scope="col"
            (click)="sortOn(0,'email_replied_at')"><i class="fas fa-sort"></i> Replied at</th>

          <th *ngIf="(btn_selected == 'Opened') || (btn_selected == 'Replied')"
          [class]="column_selected == 'ctn_link_tracking' ? 'header-name active' : 'header-name'"
          (click)="sortOn(0,'ctn_link_tracking')" scope="col"><i class="fas fa-sort"></i> Clicked</th>



          <th [class]="column_selected == 'email_opted_out_at' ? 'header-name active' : 'header-name'" scope="col"
          (click)="sortOn(0,'email_opted_out_at')"><i class="fas fa-sort"></i> Unsubscribed at</th>
          <th scope="col">Phone</th>
          <th *ngIf="(btn_selected == 'Opened') || (btn_selected == 'Replied')" scope="col">Device Info</th>
          <th *ngIf="(btn_selected == 'Opened') || (btn_selected == 'Replied')">Location</th>
          <th  scope="col">Provider</th>
          <!-- <th scope="col">Body</th> -->
          <!-- <th scope="col">Click Count</th> -->
          <!-- <th scope="col">Sent From</th> -->

          <th scope="col">Remarks</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let i of campaignRunLogList">
          <td (click)="viewEmailDetail(i)" style="cursor: pointer;">
            <strong class="text-primary">{{i.recipient_email_address}}</strong>
          </td>
          <td>{{i.sender_email_address}}</td>
          <td>{{i.subject}}</td>

          <td>{{i.email_sent_at | date: 'medium'}} </td>
          <td *ngIf="(btn_selected == 'Opened') || (btn_selected == 'Replied')">{{i.email_opened_at | date: 'medium'}}</td>
          <td *ngIf="(btn_selected == 'Opened') || (btn_selected == 'Replied')">{{i.cnt_email_open}}</td>

          <td *ngIf="!i.email_replied_at && ((btn_selected == 'Opened') || (btn_selected == 'Replied'))"></td>
          <td (click)="viewEmailDetail(i)" style="cursor: pointer;"
          *ngIf="i.email_replied_at && ((btn_selected == 'Opened') || (btn_selected == 'Replied'))">
          <span><i class="far fa-eye"></i></span> {{i.email_replied_at | date: 'medium'}}
          <span *ngIf="!i.is_auto_reply" class="badge rounded-pill bg-primary">Normal</span>
          <span *ngIf="i.is_auto_reply" class="badge rounded-pill bg-warning text-dark">Auto</span>
          </td>
          

          <td *ngIf="(btn_selected == 'Opened') || (btn_selected == 'Replied')" scope="col">{{i.ctn_link_tracking}}</td>

          <td>{{i.email_opted_out_at | date: 'medium'}}</td>
          <td>{{i.phone}}</td>
          <td *ngIf="(btn_selected == 'Opened') || (btn_selected == 'Replied')">
            {{convertDevicesListToStringWithComma(i.mailopened)}}</td>
            <td *ngIf="(btn_selected == 'Opened') || (btn_selected == 'Replied')">{{getLocation(i.mailopened)}}</td>
            <td >{{i.email_ext_provider}}</td>
          <!-- <td>{{i.body}}</td> -->
          <!-- <td>Count</td> -->
          <!-- <td>{{i.sender_email_address}}</td> -->
          <td>{{i.log_status}}</td>
        </tr>


      </tbody>
    </table>
  </div>
</div>
</ng-container>



<ng-container *ngIf="cnt_email_total >= 1 && campaignRunLogList.length === 0">

  <div class="col-12">
    <div class="row add-step me-3 ms-3 shadow-1 bg-white mt-3 p-3" style="border-radius: 5px;">
      <div class="col-12">
        <span><i style="color:#EF6C00" class="far fa-user-circle fa-3x add-step-icon"></i></span>
        <p class="add-step-text">{{tab_selected === '' ? 'Apply filter to view' : 'No Data Found!'}} <br />
          <span>
            {{tab_selected === '' ? 'Filter is not applied' : 'No prospect available on selected filters'}}
            </span>
        </p>
        <!-- <button *ngIf="tab_selected != ''" routerLink="/prospects" type="button" class="btn btn-primary"><i class="fas fa-plus"></i> ADD
          Prospect</button> -->
      </div>
    </div>
  </div>

</ng-container>


<ng-container *ngIf="cnt_email_total === 0 && campaignRunLogList.length === 0">
  <div class="col-12">
    <div class="row add-step me-3 ms-3 shadow-1 bg-white mt-3 p-3" style="border-radius: 5px;">
      <div class="col-12">
        <span><i style="color:#EF6C00" class="far fa-envelope fa-3x add-step-icon"></i></span>
        <p class="add-step-text">No email found!<br />
          <span>Add prospects and send emails to them.</span>
        </p>
        <!-- <button type="button" routerLink="/prospects" class="btn btn-primary">+ Add prospects</button> -->
      </div>
    </div>
  </div>
</ng-container>

<div class="row mt-5">
  <div class="col-12 mt-5"></div>
  <div class="col-12 mt-5"></div>
</div>
