<div class="ms-sm-auto" style="width: 100%;">

  <div class="row" style="height: 100vh; overflow-x: hidden; overflow-y: auto; background-color: #FBF7FE;">
    <div class="col-3"></div>

    <div class="col-6 pt-4">


      <div class="row mt-5">
        <div class="col-12" style="text-align: center;">
          <img class="logo" src="../../assets/images/logo-mini.png">
        </div>
      </div>


      <div class="row mt-3" *ngIf="campaignProspect && !optOutSuccessStatus">
        <div class="card">
          <div class="card-body">
            <form #unsubscribeCheckboxForm="ngForm" (submit)="unsubscribeClicked($event)">
              <h6 class="text-success text-center mt-3">{{campaignProspect.email_address}}</h6>
              <p class="text-center">
                I don't want to receive any email communications from
              </p>

              <p class="text-center text-danger p-0 m-0 mt-2" *ngIf="formError">{{formError}}</p>

              <div class="d-flex justify-content-center">
                <div class="d-flex flex-column mb-3">

                  <div class="form-check mt-1" *ngFor="let item of UnsubscribeOptions">
                    <input value="{{item.id}}" mdbRadio class="form-check-input" type="radio" 
                    [(ngModel)]="ItemSelected" [name]="item.id"  
                    id="checkboxItem_{{item.id}}" />
                    <label class="form-check-label mt-1" for="checkboxItem_{{item.id}}">{{item.name}}</label>
                  </div> 

                </div>
              </div>

              <div class="p-3 text-center">
                <button type="submit" class="btn btn-danger">Unsubscribe</button>
              </div>
            </form>
          </div>
        </div>
      </div>


      <div class="row mt-3 text-center" *ngIf="optOutSuccessStatus">
        <div class="card">
          <div class="card-body">
            <p>
              We will miss you!
            </p>
            <h6 class="text-success">{{campaignProspect.email_address}}</h6>
            <h3>You have been unsubscribed.</h3>
            <p>
              {{optOutSuccessMessage}}<br> Thank you for your cooperation!.
            </p>

          </div>
        </div>
      </div>



      <div class="row mt-3 text-center" *ngIf="!validationStatus">
        <div class="card">
          <div class="card-body">
            <h3 class="mt-2"> Sorry!</h3>

            <svg xmlns="http://www.w3.org/2000/svg" width="161.814" height="161.814" viewBox="0 0 161.814 161.814">
              <path id="Icon_material-broken_image" data-name="Icon material-broken_image"
                d="M166.314,22.479V81.721L139.345,54.662,103.386,90.711,67.428,54.752,31.469,90.711,4.5,63.652V22.479A18.032,18.032,0,0,1,22.479,4.5H148.334A18.032,18.032,0,0,1,166.314,22.479ZM139.345,80.193l26.969,27.059v41.083a18.032,18.032,0,0,1-17.979,17.979H22.479A18.032,18.032,0,0,1,4.5,148.334V89.182l26.969,26.879L67.428,80.1l35.959,35.959Z"
                transform="translate(-4.5 -4.5)" fill="#f3f3f3" />
            </svg>

            <p class="mt-3">Something is broken! Can't process your request.<br>Please email us at <a href="mailto:grow@buyerforesight.com?subject=Unsubscribe">grow@buyerforesight.com</a></p>

          </div>
        </div>
      </div>


      <div class="pt-3 text-center"><p>&#169; 2022 BuyerForesight &#8482;. All Rights Reserved.<br>
<a href="https://www.buyerforesight.com/privacy-policy/" target="_blank">Privacy Policy & Terms of Service</a> </p></div>


    </div>

    <div class="col-3"></div>




  </div>

</div>