import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
// import { SendingWindowsModel  } from '../../../setting-model/setting-model';
import { SendingWindowsModel  } from '../../../settings/setting-model/setting-model';

@Component({
  selector: 'app-show-sending-window',
  templateUrl: './show-sending-window.component.html',
  styleUrls: ['./show-sending-window.component.scss']
})
export class ShowSendingWindowComponent implements OnInit {

  TimeSelections = [
    {time: '00:00:00', time_display: "12:00 AM"}, {time: '01:00:00', time_display: "01:00 AM"},
    {time: '02:00:00', time_display: "02:00 AM"}, {time: '03:00:00', time_display: "03:00 AM"},
    {time: '04:00:00', time_display: "04:00 AM"}, {time: '05:00:00', time_display: "05:00 AM"},
    {time: '06:00:00', time_display: "06:00 AM"}, {time: '07:00:00', time_display: "07:00 AM"},
    {time: '08:00:00', time_display: "08:00 AM"}, {time: '09:00:00', time_display: "09:00 AM"},
    {time: '10:00:00', time_display: "10:00 AM"}, {time: '11:00:00', time_display: "11:00 AM"}, 
    {time: '12:00:00', time_display: "12:00 PM"}, {time: '13:00:00', time_display: "01:00 PM"},
    {time: '14:00:00', time_display: "02:00 PM"}, {time: '15:00:00', time_display: "03:00 PM"},
    {time: '16:00:00', time_display: "04:00 PM"}, {time: '17:00:00', time_display: "05:00 PM"},
    {time: '18:00:00', time_display: "06:00 PM"}, {time: '19:00:00', time_display: "07:00 PM"},
    {time: '20:00:00', time_display: "08:00 PM"}, {time: '21:00:00', time_display: "09:00 PM"},
    {time: '22:00:00', time_display: "10:00 PM"}, {time: '23:00:00', time_display: "11:00 PM"},
    
  ];

  sendingWindowModel: SendingWindowsModel;
  scheduleTimezoneName = [];

  mondayStartModel = [];
  mondayEndModel = [];

  tuesdayStartModel = [];
  tuesdayEndModel = [];

  wednesdayStartModel = [];
  wednesdayEndModel = [];

  thursdayStartModel = [];
  thursdayEndModel = [];

  fridayStartModel = [];
  fridayEndModel = [];

  saturdayStartModel = [];
  saturdayEndModel = [];

  sundayStartModel= [];
  sundayEndModel= []; 

  constructor(public modalRef: MdbModalRef<ShowSendingWindowComponent>) {}

  ngOnInit(): void {
    this.setUpUpdateData();
  }

  setUpUpdateData(){

    if (this.sendingWindowModel.monday_start_at && this.sendingWindowModel.monday_end_at){
      this.mondayStartModel = this.getFullItem(this.sendingWindowModel.monday_start_at)
      this.mondayEndModel = this.getFullItem(this.sendingWindowModel.monday_end_at)
      console.log('hello from sending window monday')
      console.log(this.mondayStartModel)
      console.log(this.mondayEndModel)
    
    }; 
  
    if (this.sendingWindowModel.sunday_start_at && this.sendingWindowModel.sunday_end_at){
      this.sundayStartModel = this.getFullItem(this.sendingWindowModel.sunday_start_at)
      this.sundayEndModel = this.getFullItem(this.sendingWindowModel.sunday_end_at)
    };

    if (this.sendingWindowModel.saturday_start_at && this.sendingWindowModel.saturday_end_at){
      this.saturdayStartModel = this.getFullItem(this.sendingWindowModel.saturday_start_at)
      this.saturdayEndModel = this.getFullItem(this.sendingWindowModel.saturday_end_at)
    };

    if (this.sendingWindowModel.friday_start_at && this.sendingWindowModel.friday_end_at){
      this.fridayStartModel = this.getFullItem(this.sendingWindowModel.friday_start_at)
      this.fridayEndModel = this.getFullItem(this.sendingWindowModel.friday_end_at)
    };

    if (this.sendingWindowModel.thursday_start_at && this.sendingWindowModel.thursday_end_at){
      this.thursdayStartModel = this.getFullItem(this.sendingWindowModel.thursday_start_at)
      this.thursdayEndModel = this.getFullItem(this.sendingWindowModel.thursday_end_at)
    };

    if (this.sendingWindowModel.wednesday_start_at && this.sendingWindowModel.wednesday_end_at){
      this.wednesdayStartModel = this.getFullItem(this.sendingWindowModel.wednesday_start_at)
      this.wednesdayEndModel = this.getFullItem(this.sendingWindowModel.wednesday_end_at)
    };

    if (this.sendingWindowModel.tuesday_start_at && this.sendingWindowModel.tuesday_end_at){
      this.tuesdayStartModel = this.getFullItem(this.sendingWindowModel.tuesday_start_at)
      this.tuesdayEndModel = this.getFullItem(this.sendingWindowModel.tuesday_end_at)
    };
  }

  // getFullItem(SelectTime: string){    
  //   var result = this.TimeSelections.filter(obj => {
  //     return obj.time === SelectTime;
  //   })
  //   return result[0]
  // };


  getFullItem(SelectTime: any){   
    var resultList = []
    for (let x of SelectTime){
      var result = this.TimeSelections.filter(obj => {
        return obj.time === x;
      })
      resultList.push(result[0]) 
    }
    return resultList
  };

  displayTime(startTime: string, endTime: string){
    var startdisplay = startTime.slice(0,2) + ' ' + startTime.slice(-2);
    var enddisplay = endTime.slice(0,2) + ' ' + endTime.slice(-2);
    return startdisplay + ' - ' + enddisplay
  }

   

}
