export class ReleaseNoteModel {
    "id": number
    "title": string
    "slug": string
    "description": string
    "created_by": number    
    "created_at": string    
    "modified_at": string
    "modified_by": number
    "is_deleted": boolean
}


export class ReleaseNoteResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [ReleaseNoteModel]
}