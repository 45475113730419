
<app-loading-dialog *ngIf="savingSpiner">

</app-loading-dialog>



<div class="ms-sm-auto" style="width: 90%;">


  <div class="row" style="height: 100%;">



    <div class="col-2 shadow-3" style="background: #FBF7FE; height: 100vh; overflow-x: hidden; overflow-y: auto;scrollbar-width: thin; ">

      <app-sidebar-settings></app-sidebar-settings>
    </div>




    <div class="col-10 p-3" style="height: 100vh; overflow-x: hid den; overflow-y: auto; scrollbar-width: thin;">

      <ng-container *ngIf="userExtraData else loading">


        <h4>Mailbox</h4>
        <hr>

        <div class="row mt-4">
          <div class="col-12">
            <h6 class="text-primary">Email Sender Name</h6>
            <p>Actual name of the email account </p>
          </div>
          <div class="col-5">
            <mdb-form-control>
              <input mdbInput type="text" id="email_sender" class="form-control"
              [(ngModel)]="senderNameModel" placeholder="Sender Name"/>
              <!-- <label mdbLabel class="form-label" for="form1">Example label</label> -->
            </mdb-form-control>
          </div>
          <div class="col-7">
            <button type="button" (click)="saveSenderName()" class="btn btn-link"><i class="far fa-save"></i>
              Save</button>
          </div>
        </div>

        <hr>

        <div class="row">

          <div class="col-12">
            <h6 class="text-primary">Connection</h6>
            <p>Please connect your email account to start sending emails through Map.
              This also allows the system to detect replies, bounces and auto-replies (out of office).</p>
          </div>

          <ng-container *ngIf="!connectedMailbox">

            <div class="col-8 ms-4 pt-2 pb-2" style="background-color: #4b4b4b;">

              <ng-container *ngIf="emailSenderList.length > 0 else canNotConnectEmail">
                <form #mailboxConnectForm="ngForm" (ngSubmit)="userConnectMailbox($event)">
                  <ng-container *ngIf="mailboxSenderSelectError">
                    <div class="mt-1 text-danger text-center">
                     {{mailboxSenderSelectError}}
                    </div>
                  </ng-container>
                  <div class="d-flex justify-content-center pt-4">
                    <select [(ngModel)]="selectedSenderModel" name="selectedSender" class="form-select"
                      aria-label="select sender options">
                      <option value="0">Please select an email sender</option>
                      <option *ngFor="let i of emailSenderList" value="{{i.id}}">{{i.email_address}}</option>
                    </select>
                  </div>
                  <p class="text-white pt-2 pe-4 text-center">Please select one method to login, make sure you select the
                    correct email sender.</p>
                  <div class="d-flex justify-content-center mb-3">
                    <button type="submit" class="btn btn-light me-5">
                      Connect Mailbox</button>
                  </div>
                </form>
              </ng-container>
              <ng-template #canNotConnectEmail>
                <p class="p-5 text-white">You can't connect an email, please contact with Administrator.</p>
              </ng-template>
            </div>

          </ng-container>

          <ng-container *ngIf="connectedMailbox">

            <div class="col-8 ms-4 mt-1" style="background-color: #543074;">
              <p class="text-white pt-4 pe-4 text-center">You have successfully connected with {{connectedMailbox}}</p>

              <ng-container *ngIf="mailBoxDisconnectError">
                <div class="mt-1 text-danger text-center">
                 <p>{{mailBoxDisconnectError}}</p>
                </div>
              </ng-container>

              <div class="d-flex justify-content-center mb-3">
                <button (click)=disconnectConnectedMailbox() type="button" class="btn me-5"
                  style="background-color: #f10b58; color:white">Disconnect</button>
              </div>
            </div>

          </ng-container>



        </div>



        <hr>



        <div class="row mt-4">
          <div class="col-12">
            <h6 class="text-primary">Signature</h6>
            <p>If included, this signature will appear on all outgoing emails <span class="float-end text-danger fw-bold fst-italic"><small> *Attention: Change font as per your need.</small> </span></p>
          </div>
          <div class="col-12">

            <!-- <quill-editor [modules]="editormodules" [(ngModel)]="signatureModel"></quill-editor> -->

            <editor apiKey="{{editorAPIKey}}" [init]="editormodules" [(ngModel)]="signatureModel"></editor>

          </div>
          <!-- <div class="col-12 pt-2"> -->
          <div class="d-flex flex-row mb-3 mt-2">
            <div class="p-2">
              <div class="form-check">
                <input mdbCheckbox class="form-check-input" type="checkbox" value="" id="flexCheckEnableSignature"
                [(ngModel)]="isSignatureEnabledModel"  />
                <label class="form-check-label" for="flexCheckEnableSignature">
                  Include Signature in email body.
                </label>
              </div>
            </div>
            <button type="button" (click)="saveSignature()" class="btn btn-link"><i class="far fa-save"></i>
              Save</button>
          </div>
        </div>


        <hr>

        <div class="row mt-4">

          <div class="col-12">
            <h6 class="text-primary">Opt out statement</h6>
            <p>This statement can be included at the bottom of your emails.
              If a prospect clicks on the link, the opted out field of the prospect will be marked true.</p>
          </div>
          <div class="col-12" style="min-height: 250px;">

            <editor [init]="Optouteditormodules" [(ngModel)]="optOutStatementModel"></editor>

          </div>

          <div class="d-flex flex-row mb-3 mt-2">
            <div class="p-2">
              <div class="form-check">
                <input mdbCheckbox class="form-check-input"  type="checkbox" value="" id="isOptOutEnabledCheck"
                [(ngModel)]="isOptOutEnabledModel"/>
                <label class="form-check-label" for="isOptOutEnabledCheck">Include opt-out statement in emails</label>
              </div>
            </div>

            <button type="button" (click)="saveOptOutStatement()" class="btn btn-link"><i class="far fa-save"></i>
              Save</button>
          </div>


        </div>

        <hr>




        <div class="row mt-4">
          <div class="col-12">
            <h6 class="text-primary">Tracking</h6>
          </div>
          <div class="col-12">

            <div class="d-flex flex-row mb-3">
              <div class="p-2">
                <div class="form-check">
                  <input mdbCheckbox class="form-check-input" type="checkbox" value="" id="flexChecktracking"
                  [(ngModel)]="trackingModel" />
                  <label class="form-check-label" for="flexChecktracking">
                    Track whether an email has been opened.
                  </label>
                </div>
                <div class="form-check">
                  <input mdbCheckbox class="form-check-input" type="checkbox" value="" id="flexCheckLinktracking"
                  [(ngModel)]="linkTrackingModel"  />
                  <label class="form-check-label" for="flexCheckLinktracking">
                    Track whether a link has been clicked.
                  </label>
                </div>
              </div>



              <div class="mt-3 ms-3"><button (click)="saveTracking()" type="button" class="btn btn-link"><i class="far fa-save"></i>
                  Save</button></div>
            </div>

          </div>
        </div>
      </ng-container>

      <ng-template #loading>
        <div class="row">
          <div class="col">
            <h4><i class="fas fa-spinner fa-spin"></i> Looking for Settings...</h4>
            <hr>
          </div>
        </div>
      </ng-template>

    </div>



  </div>


</div>
