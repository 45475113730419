<div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Delete Client</h5>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modalRef.close()"
    ></button>
  </div>
  <div class="modal-body">
    <p style="color: #EF6C00">Attention! This action is permanent.</p>
    <h5>Are you sure what to delete <span>"{{deleteClientName}}"</span>?</h5>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-link" (click)="modalRef.close()">
      Close
    </button>
    <button type="button" (click)="deleteClient()" class="btn btn-primary">Confirm</button>
  </div>