export class ClientsModel {
    "id": number
    "name": string
    "slug": string
    "created_at": Date
    "modified_at": Date
    "cnt_campaign": number
    "is_deleted": string
    "created_by": number
    "modified_by": number
} 


export class  ClientsResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [ClientsModel]
} 


export class ClientUserResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [ClientUserModel]
}

export class ClientUserModel {
    "id": number
    "username": string
    "first_name": string
    "last_name": string
    "email": string
}