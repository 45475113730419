<div class="ms-sm-auto" style="width: 90%;">


    

    <div class="row" style="height: 100%;">
    
        <div class="col-2 shadow-3 " style="background: #FBF7FE; height: 100vh; overflow-x:hidden; 
        overflow-y: auto; z-index: 1;">
           <app-analytics-sidebar></app-analytics-sidebar>
         </div>
  
  
         <div class="col-10 m-0" style="height: 100vh; overflow-x: hidden; overflow-y: auto; background-color: rgb(243, 243, 243);">
  
            <div class="row shadow-1 p-3 sticky-top bg-white" style="z-index: 2;">
                <div class="col-md-4">
                <h4 class="map-text-header">Best Sending Time
                    <!-- <span
                    style="font-size: 12px; color: #ef6c00;">({{totalClientRecieved}} found)</span> -->
                    </h4>
                </div>
        
                <!-- <div class="col-md-8">
                <div class="d-flex justify-content-end">
                    
                        <mdb-form-control style="width: 350px;">
                        <input mdbInput type="text"  id="search" class="form-control form-control-sm" 
                        name="search"  />
                        <label mdbLabel class="form-label" for="search">
                            <i class="far fa-envelope"></i> Search</label>
                        </mdb-form-control>
                        
                </div>
                </div> -->
            </div>

            <div class="row mt-3">
                <div class="col-3">
                  <input type="text"  placeholder="Start date - End date" class="form-control w-100" 
                  bsDaterangepicker [bsConfig]="{ isAnimated: true, containerClass: 'theme-default', rangeInputFormat: 'DD/MM/YYYY'}" 
                  #dp="bsDaterangepicker" [maxDate]="bsMaxDate" (bsValueChange)="onValueChange($event)">
                </div>
      
                <div class="col-3">
                <ng-select [(ngModel)]="clientId" id="clientInput" class="header-imput w-100" (change)='onclientSelected()' placeholder="Client">
                <ng-option *ngFor="let o of clientList" [value]="o.id">{{o.name}}</ng-option>
                </ng-select>
                </div>


                <div class="col-3">
                    <input #campaignType [(ngModel)]="selectedCampaign" (typeaheadOnSelect)="onSelect($event)"
                (typeaheadLoading)="changeTypeaheadLoading($event)" [typeaheadMinLength]="0" [typeahead]="campaignlist"
                typeaheadOptionField="name" typeahead-wait-ms="100" class="form-control" name="Campaigns" type="text"
                (paste)="onPaste($event)" placeholder="Search by campaign" autocomplete="off" />
              <span class="highlight"></span>
                </div>
                    

                <div class="col-3">
                  <ng-select [(ngModel)]="regionId" id="regionInput" 
                  class="header-imput w-100" (change)='onRegionSelected()' placeholder="Region">
                    <ng-option *ngFor="let o of regionResponseModel.value?.results" [value]="o.id">{{o.name}}</ng-option>
                    </ng-select>
                </div>


                <div class="col-9 mt-1">
                    <ng-select [(ngModel)]="createdById" id="createdBy" class="header-imput w-100" (change)='onCreatedBySelected()'
                    placeholder="Created By" [multiple]="true">
                    <ng-option *ngFor="let o of userList" [value]="o.id">{{o.email?.length > 0 ? o.email: o.username}}</ng-option>
                  </ng-select> 
                </div>

              

                <div class="col-3 mt-1">
                  <ng-select [(ngModel)]="reportTimezoneModel" id="timezoneInput" 
                  class="header-imput w-100" placeholder="Timezone" (change)='onTimezoneSelected()'>
                    <ng-option *ngFor="let o of reportTimezones" [value]="o.value">{{o.name}}</ng-option>
                    </ng-select>
                </div>

            </div> 

            <div class="row p-3">
                <div *ngIf="bestSendingTimeResponse" class="col-12 pt-2 pb-2 d-flex justify-content-center shadow-1 bg-white">
                  <div class="chartjs-container">
                    <canvas height="40vh" width="80vw" baseChart [datasets]="barChartData" 
                    [labels]="barChartLabels" [options]="barChartOptions"
                      [plugins]="barChartPlugins" [legend]="barChartLegend" responsive="true"
                      [chartType]="barChartType">
                    </canvas> 

                    <div class="text-center mt-3">
                      Open count intervals performance ({{reportTimezoneModel}})
                    </div>
                    
                  </div>
                  
                    
                </div>

                  <div class="col-12">

                    <div class="text-center mt-1" *ngIf="firstTimeLoad">
                      Use above filters to get results
                    </div>

                    
                    
                    <div *ngIf="!bestSendingTimeResponse && !firstTimeLoad">
                      <div class="text-center mt-1">
                      <app-spinner></app-spinner> 
                    </div>
                  </div>
                </div>
            </div>
  
        </div>

    
    
    </div>
  </div>
  