
<!-- <app-loading-dialog *ngIf="savingSpiner">
    
</app-loading-dialog> -->



<div class="ms-sm-auto" style="width: 90%;">


    <div class="row" style="height: 100%;">
  
  
  
      <div class="col-2 shadow-3" style="background: #FBF7FE; height: 100vh; overflow-x: hidden; overflow-y: auto; ">
  
        <app-sidebar-help></app-sidebar-help>
      </div>
  
  
  
  
      <div class="col-10 p-3" style="height: 100vh; overflow-x: hid den; overflow-y: auto; ">
  
          <div class="row">
              <div class="col-12 d-flex justify-content-between">
                  <h4>About </h4>
                  <h6 style="font-size: 14px;">Build: <span class="text-secondary" >{{currentVersion}}</span> </h6>
              </div>
              <hr class="m-0">
          </div>

          <div class="row p-5" >
            <div class="col-6 justify-content-between">
              <h4>Company description</h4>
              <p class="mt-3">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
            </div>
            <div class="col-6 justify-content-between">
              <div class="row">
                <div class="col-6">
                  <svg xmlns="http://www.w3.org/2000/svg" width="277.665" height="125" viewBox="0 0 77.665 35">
                    <g id="Group_730" data-name="Group 730" transform="translate(238 -76)">
                      <text id="MAP" transform="translate(-238 104)" fill="#543074" font-size="26" font-family="SegoeUI, Segoe UI"><tspan x="0" y="0">MAP</tspan></text>
                      <path id="Icon_simple-minutemailer" data-name="Icon simple-minutemailer" d="M5.347,4.055,8.662,7.643,6.323,12.253l4.939-.885,2.249,5.425L23.063,4.269Zm-.611,7.772.92-.756-1.186.375-.121-.173.49-.476-.643.259A6.546,6.546,0,0,0,2.05,21.922,6.5,6.5,0,0,1,1.2,18.7a6.709,6.709,0,0,1,4.146-6.169l.3-.736-.721.318-.2-.274.011-.011ZM8.929,9.934l1.536-2.711L19.5,5.108Z" transform="translate(-183.397 82.743)" fill="#ef6c00"/>
                    </g>
                  </svg>
                </div>
                <div class="col-6 pt-4">
                    <img class="pt-4"
                    src="assets/images/logo-mini.png"
                    class="img-fluid rounded-pill"
                    alt=""/>
                </div>
              </div>

                

            </div>
          </div>


          <div class="row p-5" >
            <mdb-accordion [multiple]="true">
              <mdb-accordion-item  [collapsed]="i > 0" *ngFor="let releasenote of releaseNoteList; let i=index">
                <ng-template mdbAccordionItemHeader><h6>{{releasenote.title}}</h6>
                <span class="text-muted ps-2" style="margin-top: -10px;">
                  {{releasenote.created_at | date:'medium'}}
                </span></ng-template>
                <ng-template mdbAccordionItemBody>
                 <div [innerHTML]="releasenote.description | noSanitizeHtml"></div>
                </ng-template>
              </mdb-accordion-item>
            </mdb-accordion>
          </div>
  
     
  
      </div>
  
  
  
    </div>
  
  
  </div>