import { Component, OnInit } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { BehaviorSubject } from 'rxjs';
import { SendingWindowsModel, ScheduleTimezoneResponseModel } from '../../setting-model/setting-model';
import { SettingViewModel } from '../../setting-view-model/setting-view-model';
import { TimePickerModalComponent } from '../time-picker-modal/time-picker-modal.component';
import { MapCommon } from "src/app/app-providers/map-base/MapCommon";
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-sending-windows',
  templateUrl: './add-sending-windows.component.html',
  styleUrls: ['./add-sending-windows.component.scss']
})
export class AddSendingWindowsComponent implements OnInit {

  TimePickerModalRef: MdbModalRef<TimePickerModalComponent>;
  scheduleTimezoneList = [];
  currentUser: number;
  mondayStartModel = [];
  mondayEndModel = [];

  tuesdayStartModel = [];
  tuesdayEndModel = [];

  wednesdayStartModel = [];
  wednesdayEndModel = [];

  thursdayStartModel = [];
  thursdayEndModel = [];

  fridayStartModel = [];
  fridayEndModel = [];

  saturdayStartModel = [];
  saturdayEndModel = [];

  sundayStartModel = [];
  sundayEndModel = [];

  timezoneSelectModel: number = null;
  sendingWindowNameModel :string="";

  nameError: boolean = false;
  timezoneError: boolean = false;
  noTimeError: boolean = false;
  TimezoneData= [];
  specialCharacters = ['!','@','#','$','%','^','&','*','(',')','?','/',',','.','+','-','`','~','_','-','=','{','}','<','>','\\','|','[',']',';', ':','\'', '\,','\"']
  specialCharSpotted:boolean = false
  specialCharError:boolean = false

  scheduleTimezoneResponseModel = new BehaviorSubject<ScheduleTimezoneResponseModel>(
    new ScheduleTimezoneResponseModel()
  );



  constructor(
    private modalService: MdbModalService, private vm: SettingViewModel,  private router: Router) {
      this.vm.parentAddSendingWindowsComponent = this;



    }

    ngOnInit(): void {
      this.currentUser = MapCommon.getloggedInUser().id
      this.vm.subscribeScheduleTimezoneVariable(this.scheduleTimezoneResponseModel);
      this.vm.getAllScheduleTimezone('add');
  }

  closewindow(){
    this.router.navigate(['/settings/sending-windows'])
  }

  displayTime(startTime: string, endTime: string){
    var startdisplay = startTime.slice(0,2) + ' ' + startTime.slice(-2);
    var enddisplay = endTime.slice(0,2) + ' ' + endTime.slice(-2);
    return startdisplay + ' - ' + enddisplay
  }

  getActualTime(model: any) {
    let timeArray = []
    for(let x in model){
      timeArray.push(model[x].time)
    }
    return timeArray;
  }


  scheduleTimezoneReceived(){
    this.scheduleTimezoneList = this.scheduleTimezoneResponseModel.value.results;
    for (var i = 0; i < this.scheduleTimezoneList.length; i++) {
      this.TimezoneData.push({ id: this.scheduleTimezoneList[i].id, text: this.getTimezoneGmtOffSet(this.scheduleTimezoneList[i]) });
    }
  }

  nameChange(){
    if(this.sendingWindowNameModel.length > 0){
      this.nameError = false
      for(let i =0 ; i < this.specialCharacters.length ; i++){
        this.specialCharError = this.sendingWindowNameModel.indexOf(this.specialCharacters[i]) >= 0 ? true :  false
        if(this.specialCharError){
          break;
        }

      }
    }
  }
  timezoneChange(){
    if(this.timezoneSelectModel != null){
      this.timezoneError = false
    }
  }

  createNewSendingWindow() {
      this.nameChange()
      this.timezoneChange()
    if (this.sendingWindowNameModel.length == 0) {
      this.nameError = true;
    } else if (this.timezoneSelectModel === null) {
      this.timezoneError = true;
    } else if (
      this.mondayStartModel.length == 0 &&
      this.tuesdayStartModel.length == 0 &&
      this.wednesdayStartModel.length == 0 &&
      this.thursdayStartModel.length == 0 &&
      this.fridayStartModel.length == 0 &&
      this.saturdayStartModel.length == 0 &&
      this.sundayStartModel.length == 0
    ) {
      this.noTimeError = true;
    } else {
      if(!this.nameError && !this.timezoneError  && !this.specialCharError && !this.noTimeError   ){
      const sendingWindowsCreateModel = new SendingWindowsModel;
      sendingWindowsCreateModel.name = this.sendingWindowNameModel;
      sendingWindowsCreateModel.sunday_start_at = this.getActualTime(this.sundayStartModel);
      sendingWindowsCreateModel.sunday_end_at = this.getActualTime(this.sundayEndModel);
      sendingWindowsCreateModel.monday_start_at = this.getActualTime(this.mondayStartModel);
      sendingWindowsCreateModel.monday_end_at = this.getActualTime(this.mondayEndModel);
      sendingWindowsCreateModel.tuesday_start_at = this.getActualTime(this.tuesdayStartModel);
      sendingWindowsCreateModel.tuesday_end_at = this.getActualTime(this.tuesdayEndModel);
      sendingWindowsCreateModel.wednesday_start_at = this.getActualTime(this.wednesdayStartModel);
      sendingWindowsCreateModel.wednesday_end_at = this.getActualTime(this.wednesdayEndModel);
      sendingWindowsCreateModel.thursday_start_at = this.getActualTime(this.thursdayStartModel);
      sendingWindowsCreateModel.thursday_end_at = this.getActualTime(this.thursdayEndModel);
      sendingWindowsCreateModel.friday_start_at = this.getActualTime(this.fridayStartModel);
      sendingWindowsCreateModel.friday_end_at = this.getActualTime(this.fridayEndModel);
      sendingWindowsCreateModel.saturday_start_at = this.getActualTime(this.saturdayStartModel);
      sendingWindowsCreateModel.saturday_end_at = this.getActualTime(this.saturdayEndModel);
      sendingWindowsCreateModel.created_by = this.currentUser;
      sendingWindowsCreateModel.timezone = this.timezoneSelectModel;
      this.vm.createSendingWindows(sendingWindowsCreateModel);
      // this.addSendingWindowsModalRef.close();
      this.router.navigate(['/settings/sending-windows'])
     }
  }

  }


  getTimezoneGmtOffSet(timezoneItem) {
    var is_behind_gmt = timezoneItem['is_behind_gmt'] ? '-' : '+'
    var h = Math.floor(timezoneItem['gmt_offset_minute'] / 60);
    var m = timezoneItem['gmt_offset_minute'] % 60;
    var new_h = (h < 10) ? '0' + h : h;
    var new_m = (m < 10) ? '0' + m : m;
    var gmt = '(GMT' + is_behind_gmt + new_h + ':' + new_m +")";
    return timezoneItem.name + " "+ gmt;
  }



  // Monday
  mondaySelectModal() {
    this.TimePickerModalRef = this.modalService.open(TimePickerModalComponent);
    this.TimePickerModalRef.onClose.subscribe((message: any) => {
      if (message) {
        // this.mondayStartModel = message.start;
        // this.mondayEndModel = message.end;
        if(this.processSendingSlot(message, this.mondayStartModel, this.mondayEndModel)){
          this.mondayStartModel.push(message.start);
          this.mondayEndModel.push(message.end);
          this.noTimeError = false
        }
      }
    });
  }

  mondayRemove() {
    this.mondayStartModel = [];
    this.mondayEndModel = [];
  }

  // Tuesday
  // tuesdaySelectModal() {
  //   this.TimePickerModalRef = this.modalService.open(TimePickerModalComponent);
  //   this.TimePickerModalRef.onClose.subscribe((message: any) => {
  //     if (message) {
  //       this.tuesdayStartModel = message.start;
  //       this.tuesdayEndModel = message.end;
  //     }
  //   });
  // }
  tuesdaySelectModal() {
    this.TimePickerModalRef = this.modalService.open(TimePickerModalComponent);
    this.TimePickerModalRef.onClose.subscribe((message: any) => {
      if (message) {

        if(this.processSendingSlot(message, this.tuesdayStartModel, this.tuesdayEndModel)){
          this.tuesdayStartModel.push(message.start);
          this.tuesdayEndModel.push(message.end);
          this.noTimeError = false
        }
      }
    });
  }

  processSendingSlot(message: any, startmodel: any, endmodel: any){

    for (let x in startmodel) {
      if(startmodel[x].time <= message.start.time && endmodel[x].time >= message.end.time){
        return false
      }
      else if(startmodel[x].time <= message.start.time && endmodel[x].time >= message.start.time && endmodel[x].time <= message.end.time){
        endmodel[x] = message.end
        return false
      }
      else if(startmodel[x].time <= message.end.time && startmodel[x].time >= message.start.time && endmodel[x].time >= message.end.time ){
        startmodel[x] = message.start
        return false
      }
      else if(startmodel[x].time >= message.start.time && endmodel[x].time <= message.end.time){
        startmodel[x] = message.start
        endmodel[x] = message.end
        return false
      }
    }
    return true

  }

  tuesdayRemove() {
    this.tuesdayStartModel = [];
    this.tuesdayEndModel = [];
  }


  // Wednesday
  wednesdaySelectModal() {
    this.TimePickerModalRef = this.modalService.open(TimePickerModalComponent);
    this.TimePickerModalRef.onClose.subscribe((message: any) => {
      if (message) {
        // this.wednesdayStartModel = message.start;
        // this.wednesdayEndModel = message.end;

        if(this.processSendingSlot(message, this.wednesdayStartModel, this.wednesdayEndModel)){
          this.wednesdayStartModel.push(message.start);
          this.wednesdayEndModel.push(message.end);
          this.noTimeError = false
        }
      }
    });
  }

  wednesdayRemove() {
    this.wednesdayStartModel = [];
    this.wednesdayEndModel = [];
  }



  // Thursday
  thursdaySelectModal() {
    this.TimePickerModalRef = this.modalService.open(TimePickerModalComponent);
    this.TimePickerModalRef.onClose.subscribe((message: any) => {
      if (message) {

        if(this.processSendingSlot(message, this.thursdayStartModel, this.thursdayEndModel)){
          this.thursdayStartModel.push(message.start);
          this.thursdayEndModel.push(message.end);
          this.noTimeError = false
        }

      }
    });
  }

  thursdayRemove() {
    this.thursdayStartModel = [];
    this.thursdayEndModel = [];
  }


  // Friday
  fridaySelectModal() {
    this.TimePickerModalRef = this.modalService.open(TimePickerModalComponent);
    this.TimePickerModalRef.onClose.subscribe((message: any) => {
      if (message) {

        if(this.processSendingSlot(message, this.fridayStartModel, this.fridayEndModel)){
          this.fridayStartModel.push(message.start);
          this.fridayEndModel.push(message.end);
          this.noTimeError = false
        }

      }
    });
  }

  fridayRemove() {
    this.fridayStartModel = [];
    this.fridayEndModel = [];
  }


  // Saturday
  saturdaySelectModal() {
    this.TimePickerModalRef = this.modalService.open(TimePickerModalComponent);
    this.TimePickerModalRef.onClose.subscribe((message: any) => {
      if (message) {
        // this.saturdayStartModel = message.start;
        // this.saturdayEndModel = message.end;

        if(this.processSendingSlot(message, this.saturdayStartModel, this.saturdayEndModel)){
          this.saturdayStartModel.push(message.start);
          this.saturdayEndModel.push(message.end);
          this.noTimeError = false
        }

      }
    });
  }

  saturdayRemove() {
    this.saturdayStartModel = [];
    this.saturdayEndModel = [];
  }


  // Sunday
  sundaySelectModal() {
    this.TimePickerModalRef = this.modalService.open(TimePickerModalComponent);
    this.TimePickerModalRef.onClose.subscribe((message: any) => {
      if (message) {
        // this.sundayStartModel = message.start;
        // this.sundayEndModel = message.end;

        if(this.processSendingSlot(message, this.sundayStartModel, this.sundayEndModel)){
          this.sundayStartModel.push(message.start);
          this.sundayEndModel.push(message.end);
          this.noTimeError = false
        }
      }
    });
  }

  sundayRemove() {
    this.sundayStartModel = [];
    this.sundayEndModel = [];
  }

  clearMon(event: any, index){
    this.mondayEndModel.splice(index, 1)
    this.mondayStartModel.splice(index, 1)
  }
  clearTues(event: any, index){
    this.tuesdayEndModel.splice(index, 1)
    this.tuesdayStartModel.splice(index, 1)
  }
  clearWed(event: any, index){
    this.wednesdayEndModel.splice(index, 1)
    this.wednesdayStartModel.splice(index, 1)
  }
  clearThurs(event: any, index){
    this.thursdayEndModel.splice(index, 1)
    this.thursdayStartModel.splice(index, 1)
  }
  clearFri(event: any, index){
    this.fridayEndModel.splice(index, 1)
    this.fridayStartModel.splice(index, 1)
  }
  clearSat(event: any, index){
    this.saturdayEndModel.splice(index, 1)
    this.saturdayStartModel.splice(index, 1)
  }
  clearSun(event: any, index){
    this.sundayEndModel.splice(index, 1)
    this.sundayStartModel.splice(index, 1)
  }


}
