<app-loading-dialog *ngIf="isLoading">

</app-loading-dialog>

<div class="ms-sm-auto" style="width: 90%;">

    <div class="row" style="height: 100%;">

        <div class="col-2 shadow-3 " style="background: #FBF7FE; height: 100vh; overflow-x:hidden;
        overflow-y: auto; z-index: 1;">

            <form #emailSearchForm="ngForm">
                <mdb-form-control class="mt-3">
                    <input mdbInput type="text" id="emailSearch" class="form-control form-control"
                        name="search" [ngModel]="emailSearchValue" (input)="searchWithEmail($event)"/>
                    <label mdbLabel class="form-label" for="emailSearch"><i class="fas fa-search"></i>
                        Search</label>
                </mdb-form-control>
            </form>

        </div>

        <div class="col-10 m-0"
            style="height: 100vh; overflow-x: hidden; overflow-y: auto; background: #fcfcfc !important;">
            <div class="row shadow-1 p-3 sticky-top bg-white" style="z-index: 2;">
                <div class="col-md-8">
                    <h4 class="map-text-header">
                        <div *ngIf="camapaignStepDetail">
                            <i style="color: #EF6C00;" class="fas fa-envelope"></i>  {{camapaignStepDetail.subject}}
                       <span
                        style="font-size: 12px; color: #ef6c00;">({{totalReviewListCount}} found) </span>
                        </div>
                        <!-- <span style="font-size: 12px; color: #ef6c00;">(dasdsad found)</span> -->
                    </h4>
                </div>

                <div class="col-md-4">
                    <div class="d-flex justify-content-end">

                        <button type="button" [disabled]="disableAllButtons" (click)="sendAllEmailsConfirmation()"
                         class="btn btn-primary btn-sm"><i class="fas fa-paper-plane"></i> Send
                            All</button>
                    </div>
                </div>
            </div>


    <app-campaign-mailbox-alert></app-campaign-mailbox-alert>


            <ng-container *ngIf="!loadingData else loading">

                <div #emailreview class="table-responsive" infiniteScroll [infiniteScrollDistance]="2"
                [infiniteScrollThrottle]="100" (scrolled)="onReviewEmailScroll()" [scrollWindow]="false">
            <div class="row p-3 mt-1" *ngFor="let item of reviewList; let i = index">
                <div class="col-9">
                    <div class="card">
                        <div class="card-header reviewEmail-heading">

                            <div data-toggle="collapse"  [attr.data-target]="'#reviewEmail-collapse-'+ i"
                             aria-expanded="true">

                                <div class="d-flex justify-content-between">
                                    <p class="m-0"> {{item.recipient_email_address}}</p>
                                    <p class="m-0">{{item.created_at | date: 'medium'}}</p>
                                </div>

                            </div>
                        </div>

                        <div id="reviewEmail-collapse-{{i}}" class="accordion-collapse collapse show">
                            <div class="card-body p-0">
                                <div class="emailReview-header ps-3 pt-2 pb-2">
                                    <p><strong>From:</strong> {{item.sender_name}}
                                        <span class="span-sender-email-address"><{{item.sender_email_address}}></span></p>
                                    <p><strong>To:</strong> {{item.recipient_email_address}}</p>
                                    <p><strong>Subject:</strong> {{item.subject}}</p>
                                    <p *ngIf="item.step_media" class="step-attachment mb-1 mt-1" >
                                        <a href="{{getDownloadUrl(item.step_media_details.media_file)}}" mdbTooltip="Download File">
                                          <i class="fas fa-paperclip"></i> {{item.step_media_details.file_name}}</a>
                                      </p>
                                </div>
                                <hr class="m-0" style="height: 0.5px;">
                                <div class="emailReview-body p-3">

                                    <!-- <div [innerHTML]="item.body | noSanitizeHtml"></div> -->
                                <div >
                                <iframe  [height]="emailReviewBodyFrame" width="100%" onload="this.height=this.contentWindow.document.body.scrollHeight;" [srcdoc] = "item.body | noSanitizeHtml"></iframe>
                                </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3">
                    <div id="reviewEmail-collapse-{{i}}" class="accordion-collapse collapse show">
                        <div class="d-flex flex-column mb-3">
                            <ng-container *ngIf="(item.log_status === 'Restricted-CLV' ||
                            item.log_status === 'Restricted-Global-DNC' ||
                            item.log_status === 'Restricted-Client-DNC' ||
                            item.log_status === 'Restricted-Prospect-SL' ||
                            item.log_status === 'Restricted-OOO') else notRejectedByClv">
                                <p *ngIf="item.log_status === 'Restricted-Global-DNC'"
                                style="font-weight: 600; color: #ef6c00;">Prospect is Global DNC</p>
                                <p *ngIf="item.log_status === 'Restricted-Client-DNC'"
                                style="font-weight: 600; color: #ef6c00;">Prospect is Client DNC</p>
                                <p *ngIf="item.log_status === 'Restricted-CLV'"
                                style="font-weight: 600; color: #ef6c00;">Prospect is restricted by CLV</p>
                                <p *ngIf="item.log_status === 'Restricted-OOO'"
                                style="font-weight: 600; color: #ef6c00;">Prospect is Out Of Office</p>
                                <p *ngIf="item.log_status === 'Restricted-Prospect-SL'"
                                style="font-weight: 600; color: #ef6c00;">Prospect Sending Limit Over</p>
                            </ng-container>
                            <ng-template #notRejectedByClv>
                                <div class="p-2">
                                    <button [disabled]="disableAllButtons" (click)="sendOneEmailConfirmation(item.id)" type="button"
                                    class="btn btn-link"><i class="fas fa-paper-plane"></i> Send</button>
                                </div>
                                <div class="p-2">
                                    <button [disabled]="disableAllButtons" type="button" (click)="customizeEmail(item)"
                                    class="btn btn-link"><i class="far fa-edit"></i> Customize</button>
                                </div>

                                <div class="p-2">
                                    <button [disabled]="disableAllButtons" type="button" (click)="deleteEmailConfirmation(item)"
                                    class="btn btn-outline-danger"><i class="far fa-trash-alt"></i> Delete</button>
                                </div>
                            </ng-template>
                        </div>
                    </div>

                </div>
            </div>
                </div>
            <div class="row" *ngIf="reviewList.length == 0">
                <p class="text-center mt-3">No email found!</p>
            </div>

        </ng-container>

            <ng-template #loading>
                <div class="row mt-2">
                  <div class="col text-center">
                    <h4><i class="fas fa-spinner fa-spin"></i> Looking for emails...</h4>
                  </div>
                </div>
            </ng-template>



        </div>
    </div>
</div>

