

<div class="ms-sm-auto" style="width: 90%;">

    
    <div class="row" style="height: 100%;">
    
    
    
        <div class="col-2 shadow-3" style="background: #FBF7FE; height: 100vh; overflow-x: hidden; overflow-y: auto; ">      
    
            <app-sidebar-dnc></app-sidebar-dnc>
        </div>
      
      
       
        <div class="col-10 p-2" style="height: 100vh; overflow-x: hidden; overflow-y: auto; ">


            <div class="col-12 d-flex justify-content-between">
              <div class="d-flex">
                <h6 class="pt-1 ps-1">Domain DNC 
                  <span *ngIf="totalDncItems && selectedItems === 0"
                  class="header-count">({{totalDncItems}} found) </span>
                  <span *ngIf="totalDncItems && selectedItems >= 1"
                    class="header-count">({{selectedItems}} of {{totalDncItems}}
                    selected) </span>
                
                </h6>

                <button type="button" *ngIf="selectedItems > 0" (click)="exportAll()"
                  class="btn btn-link btn-sm ms-2">
                  <i  *ngIf="showingExportLoading" class="fas fa-spinner fa-spin"></i>
                  <i *ngIf="!showingExportLoading" class="fas fa-cloud-download-alt"></i>
                </button>
              </div>
              
                <form #domainDncSearchForm="ngForm">
                  <mdb-form-control class="me-3">
                    <input mdbInput type="text" id="domainDncSearch" class="form-control form-control-sm" 
                    [ngModel]="domainDncSearchValue" name="search" (input)="domainDncSearchSubmit($event)" />
                    <label mdbLabel class="form-label" for="domainDncSearch"><i class="fas fa-city"></i> Search</label>
                  </mdb-form-control>
                </form> 
            
                <button type="button" (click)="addDomainDncModal()" class="btn btn-link btn-sm" > <i class="fas fa-plus"></i> Add Domain</button>
            </div>
            <div class="table-responsive" infiniteScroll [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="100" (scrolled)="onDomainDNCScroll()" [scrollWindow]="false">
            <table class="table table-sm mt-1 table-responsive table-hover">
                <thead style="background-color: #F6F4EE;">
                  <tr>
                    <th scope="col" style="width: 30px;">
                      <input #masterSelect mdbRadio style="cursor: pointer;" type="checkbox" [(ngModel)]="isMasterSel"
                        name="flexRadioDefault1" id="flexRadioDefault1" (change)="checkUncheckAll($event)" />
                    </th>
                    <th scope="col">Domain</th>
                    <th scope="col">Added at</th>
                    <th scope="col">Reason</th>
                  </tr>
                </thead>
                <tbody>
            
                  <tr class="dncItem" *ngFor="let i of domainDncList">
                    <td>
                      <input type="checkbox" [(ngModel)]="i.isSelected" name="list_name" value="{{i.id}}"
                        (change)="isAllSelected($event, i.id)" />
                    </td>

                    <td (click)="editDomainDncModal(i)" class="text-primary"><strong>{{i.email_domain}}</strong></td>
                    <td (click)="editDomainDncModal(i)">{{i.created_at | date:'medium'}}</td>
                    <td (click)="editDomainDncModal(i)">{{i.dnc_reason}}</td>
                  </tr> 
            
                </tbody>
              </table></div>
            
    
         
        
        </div>  
    
    
      
      
      </div>
       
    
      </div>