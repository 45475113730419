import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-feedback',
  templateUrl: './help-feedback.component.html',
  styleUrls: ['./help-feedback.component.scss']
})
export class HelpFeedbackComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
