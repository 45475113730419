import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AnalyticsViewModel } from '../models/viewmodel';
import { MapAPIService } from 'src/app/app-providers/services/map-api-service';
import { AnalyticsCampaignModel, AnalyticsCampaignResponseModel, AnalyticsClientResponseModel, AnalyticsClientsModel, AnalyticsUserResponseModel, BestSendingTimeModel, RegionResponseModel } from '../models/model';
import { BehaviorSubject, range } from 'rxjs';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'app-best-sending-time',
  templateUrl: './best-sending-time.component.html',
  styleUrls: ['./best-sending-time.component.scss'],
  providers: [MapAPIService, AnalyticsViewModel, DatePipe],
})
export class BestSendingTimeComponent implements OnInit {

  @ViewChild("campaignType") campaignType: ElementRef;
  search_start_date: string;
  search_end_date: string;
  bsMaxDate = new Date();
  clientId: number = null;
  regionId: number = null;
  clientList: [AnalyticsClientsModel];
  tempCampaignlist = [];
  isFocused: boolean = false;
  isPasted: boolean = false;
  campaignlist = [];
  fillAutoCampaign: boolean = false;
  selectedCampaignObj = new AnalyticsCampaignModel();
  selectedCampaign: string;
  createdById: string;
  userList: any = [];
  bestSendingTimeResponse: BestSendingTimeModel = null;
  reportTimezones = [
    {'value': 'IST', 'name': 'India Standard Time' },
    {'value': 'GMT', 'name': 'Greenwich Mean Time' }
  ]
  reportTimezoneModel = 'IST';
  firstTimeLoad: boolean = true;


  public barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
  };
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];
  public barChartData:ChartDataSets[] = [{
      data: [], 
      label: 'Open Count', 
      backgroundColor: [
        'rgba(190, 183, 183, 0.2)',
        'rgba(190, 183, 183, 0.2)',
        'rgba(190, 183, 183, 0.2)',
        'rgba(190, 183, 183, 0.2)',
        'rgba(190, 183, 183, 0.2)',
        'rgba(190, 183, 183, 0.2)',
        'rgba(190, 183, 183, 0.2)',
        'rgba(190, 183, 183, 0.2)'
      ], 
      borderColor: [
      'rgb(255, 99, 132)',
      'rgb(255, 99, 132)',
      'rgb(255, 99, 132)',
      'rgb(255, 99, 132)',
      'rgb(255, 99, 132)',
      'rgb(255, 99, 132)',
      'rgb(255, 99, 132)',
      'rgb(255, 99, 132)'
      ], 
      borderWidth: 1
  }];

  clientResponseModel = new BehaviorSubject<AnalyticsClientResponseModel>(
    new AnalyticsClientResponseModel()
  );

  campaignResponseModel = new BehaviorSubject<AnalyticsCampaignResponseModel>(
    new AnalyticsCampaignResponseModel()
  );


  userResponseModel = new BehaviorSubject<AnalyticsUserResponseModel>(
    new AnalyticsUserResponseModel()
  );

  bestSendingTimeModel = new BehaviorSubject<BestSendingTimeModel>(
    new BestSendingTimeModel()
  );

  regionResponseModel = new BehaviorSubject<RegionResponseModel>(
    new RegionResponseModel()
  );

  constructor(
    private vm: AnalyticsViewModel, private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.vm.parentBestSendingTimeComponent= this;
    this.vm.subscribeAllBestSendingVariables(
      this.clientResponseModel,
      this.campaignResponseModel,
      this.userResponseModel,
      this.bestSendingTimeModel,
      this.regionResponseModel,
    );
    this.vm.getAllClients('best-sending-time');
    this.vm.getAllCampaign("", 'best-sending-time');
    this.vm.getAllUsers();
    this.vm.getRegions();
    // this.getBestSendingTime();
  }

  getDataForChart() {
    this.barChartData[0]['data'] = []
    this.barChartLabels = []
    this.barChartData[0]['data'].push(
      this.bestSendingTimeResponse.time_interval_1.email_open_count,
      this.bestSendingTimeResponse.time_interval_2.email_open_count,
      this.bestSendingTimeResponse.time_interval_3.email_open_count,
      this.bestSendingTimeResponse.time_interval_4.email_open_count,
      this.bestSendingTimeResponse.time_interval_5.email_open_count,
      this.bestSendingTimeResponse.time_interval_6.email_open_count,
      this.bestSendingTimeResponse.time_interval_7.email_open_count,
      this.bestSendingTimeResponse.time_interval_8.email_open_count
      );
    this.barChartLabels.push(
      this.bestSendingTimeResponse.time_interval_1.hour_span,
      this.bestSendingTimeResponse.time_interval_2.hour_span,
      this.bestSendingTimeResponse.time_interval_3.hour_span,
      this.bestSendingTimeResponse.time_interval_4.hour_span,
      this.bestSendingTimeResponse.time_interval_5.hour_span,
      this.bestSendingTimeResponse.time_interval_6.hour_span,
      this.bestSendingTimeResponse.time_interval_7.hour_span,
      this.bestSendingTimeResponse.time_interval_8.hour_span
    ) 
  }


  getBestSendingTime(){
    if(this.firstTimeLoad){  
      this.firstTimeLoad = false    
    }
    this.bestSendingTimeResponse = null;
    this.vm.getBestSendingTime(
      this.createdById,
      this.selectedCampaignObj.id,
      this.clientId,
      this.search_start_date,
      this.search_end_date,
      this.regionId,
      this.reportTimezoneModel
    );
   
  }

  
  CampaignReceived() {
    this.tempCampaignlist = this.campaignResponseModel.value.results;
    if (this.isFocused) {
      this.campaignlist = this.tempCampaignlist.slice(0, 10);
      // this.campaignlist.unshift({"id":0,"name":"Showing top 10 campaigns"});
      this.isFocused = false;
    }
    else {
      this.campaignlist = this.tempCampaignlist;
      if (this.isPasted) {

        let el: HTMLElement = this.campaignType.nativeElement;
        this.campaignType.nativeElement.blur();
        this.campaignType.nativeElement.focus();
        this.isPasted = false;
      }
    }
    if (this.fillAutoCampaign) {
      this.selectedCampaignObj = this.campaignlist[0];
      // this.selectedCampaignObj.temp_prospect = [0];
      if (Number(this.selectedCampaignObj.id) > 0) {
        this.fillAutoCampaign = false;
      }
    }
    var tempCampaign = localStorage.getItem("selectedCampaign");
    if (tempCampaign !== null) {
      this.selectedCampaign = tempCampaign;
    }
  }

  BestSendingTimeReceived(){
    this.bestSendingTimeResponse = this.bestSendingTimeModel.value;
    this.getDataForChart(); 
  }

  onSelect(event: TypeaheadMatch): void {
    if (event.item.id > 0) {
      this.selectedCampaignObj = event.item;
      this.getBestSendingTime();
    }
  }

  userRecieved() {
    this.userList = this.userResponseModel.value.results;
  }

  onCreatedBySelected() {
    this.getBestSendingTime();
  }

  onPaste(event: any) {
    this.isPasted = true;
  }

  onclientSelected() {
    this.getBestSendingTime();
  }

  onRegionSelected(){
    this.getBestSendingTime();
  }

  onTimezoneSelected(){
    this.getBestSendingTime();
  }

  ClientRecieved() {
    this.clientList = this.clientResponseModel.value.results;
  }

  onValueChange(value: Date): void {
    if (value) {
      this.search_start_date = this.datePipe.transform(value[0], "YYYY-MM-dd");
      this.search_end_date = this.datePipe.transform(value[1], "YYYY-MM-dd");
    } else {
      this.search_start_date = undefined;
      this.search_end_date = undefined;
    }

    this.getBestSendingTime();
  }

  //call all prospecting group
  changeTypeaheadLoading(event) {
    if (this.selectedCampaign && this.selectedCampaign.length > 2)
      this.vm.getAllCampaign(this.selectedCampaign, 'link-tracking-analytics');
  }

}
