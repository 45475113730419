import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-campaign-mailbox-alert',
  templateUrl: './campaign-mailbox-alert.component.html',
  styleUrls: ['./campaign-mailbox-alert.component.scss']
})
export class CampaignMailboxAlertComponent implements OnInit {

  connectedMailbox: string = null;
  connectedMailboxId: number = null;
  
  constructor() {
    this.connectedMailbox = localStorage.getItem('map_connected_mailbox');
    this.connectedMailboxId = Number(localStorage.getItem('map_connection_id'));
   }

  ngOnInit(): void {
  }

}
