
<app-loading-dialog *ngIf="loadingSpiner">

</app-loading-dialog>


<div class="col-12 pe-3 ps-3">

    <!-- <div class="col-12 bg-white p-3 shadow-1 mt-2">

    </div> -->


    <!-- (ngModelChange)="checkSpamAndVeriablesWords($event)" [(ngModel)]="emailBodyModel" -->
<div class="row ms-1 justify-content-start">
    <div class="col-4 bg-white p-3 shadow-2  ml-3" >
        <div class="d-flex justify-content-start flex-column">
          <h6 class="text-primary mb-1"> Sender Accounts </h6>
          <hr class="mb-4">
          <div class="list-group" >
            <button href="#" *ngFor="let item of userExtraList ;let i = index" [class]="activeButton === 0 && i === 0 ? 'active-btn': activeButton === i ? 'active-btn' : '' " (click)="showTemplate(item.email_signature , i)"
            class="list-group-item list-group-item-action rounded-0 text-primary px-2 text-left" style="font-size:13px"  aria-current="true">
              {{item.email_address}}
            </button>

          </div>
        </div>
    </div>
    <div class="col-7 bg-white p-3 shadow-2" style="margin-left:13px!important;width:63%">
      <div class="d-flex justify-content-start flex-column">
        <h6 class="mb-1 text-primary"> Signature </h6>
        <hr class="mb-4">
        <div>
          <editor apiKey="{{editorAPIKey}}"  [init]="editormodules" [disabled]="true" [(ngModel)]="SelectedEmail"></editor>
        </div>
      </div>
    </div>
  </div>

</div>
