export class ProspectModel {
    "id": number
    "firstname": string
    "middlename": string
    "lastname": string
    "email_address": string
    "slug": string

    // "2number21-number6-number2Tnumber7:51:number9.5number3Z"
    "modified_at": Date
    "region": string
    "company": string
    "address": string
    "city": string
    "state": string
    "country": string
    "phone": string
    "job_title": string
    "industry": string
    "linkedin": string
    "cnt_campaign": number
    "snippet": string
    "snippet1": string
    "snippet2": string
    "snippet3": string
    "snippet4": string
    // "2number21-number6-number2Tnumber7:51:number9.5number3Z"
    "last_contacted": Date
    "is_global_dnc": boolean
    "cnt_email_sent": number
    "cnt_email_open": number
    "cnt_replies_received": number
    "is_opted_out": boolean
    "is_bounced": boolean
    "is_deleted": boolean
    "created_by": number
    "modified_by": number
    "status": number
    "prospect_group": [number]
    "is_Selected":boolean=false
    "addl_campaign":[AdditionalCampaignDetails]
    "addl_dnc_client":[AdditionalDNCClientDetails]
}
export class AdditionalDNCClientDetails
{
    "client__id": number
    "client__name": string
}
export class AdditionalCampaignDetails
{
    "id": number
    "name": string
}
export class ProspectResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [ProspectModel]
}
export class ProspectGroupModel {
    "id": number
    "name": string
    "slug": string
    "created_at": Date
    "import_file_url": string
    "cnt_total": number
    "cnt_duplicate": number
    "cnt_dnc": number
    "is_deleted": boolean
    "created_by": number
}

export class ProspectGroupResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [ProspectGroupModel]
}

export class ProspectStatusResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [ProspectStatusModel]
}

export class ProspectStatusModel {
    "id": number
    "name": string
    "slug": string
    "created_at": Date
    "modified_at": Date
    "description": string
    "is_deleted": boolean
    "created_by": number
    "modified_by": number
}

export class ProspectUserResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [UserModel]
}

export class UserModel {
    "id": number
    "username": string
    "first_name": string
    "last_name": string
    "email": string
}


export class  ClientResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [Clients]
}
export class Clients {
    "id": number
    "name": string
    "slug": string
    "created_at": Date
    "modified_at": null
    "cnt_campaign": number
    "is_deleted": string
    "created_by": number
    "modified_by": number
}

export class DNCModel {
    "email_address": string
    "slug": string
    "modified_at": Date
    "dnc_reason": string
    "is_deleted": true
    "created_by": number
    "modified_by": number
    "prospect": number
    "client": number
    "type": string
}
export class CheckProspectHistory
{
    "outreached_cmp_o": []
      "blocked_cl_t": []
      "blocked_cl_o": []
      "opted_out": []
      "bounced": []
      "replied": []
      "rejected_clv": []
      "is_ooo": []
      "total": []
}
export class CampaignResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [CampaignModel]
}
export class CampaignModel {
    "id": number
    "name": string
    "slug": string
    "created_at": Date
    "modified_at": Date
    "status": string
    "send_limit_daily": number
    "is_deleted": string
    "created_by": number
    "modified_by": number
    "client": number
    "schedule": string
    "prospect": [number]
    "temp_prospect": [number]
    "prospectFilterModel": ProspectFilterModel
    "excludedProspects": [number]
    "includedProspects": [number]
    "total_prospect_selected": number
}

export class ProspectFilterModel {
    email_address: string
    email_address__icontains: string
    email_address__startswith: string
    email_address__endswith: string
    firstname__icontains: string
    firstname__startswith: string
    firstname__endswith: string
    lastname__icontains: string
    lastname__startswith: string
    lastname__endswith: string
    company__icontains: string
    company__startswith: string
    company__endswith: string
    company__isnull: boolean
    industry__icontains: string
    industry__startswith: string
    industry__endswith: string
    industry__isnull: boolean
    phone__icontains: string
    phone__startswith: string
    phone__endswith: string
    phone__isnull: boolean
    job_title__icontains: string
    job_title__startswith: string
    job_title__endswith: string
    job_title__isnull: boolean
    city__icontains: string
    city__startswith: string
    city__endswith: string
    city__isnull: boolean
    state__icontains: string
    state__startswith: string
    state__endswith: string
    state__isnull: boolean
    region__icontains: string
    region__startswith: string
    region__endswith: string
    region__isnull: boolean
    country__icontains: string
    country__startswith: string
    country__endswith: string
    country__isnull: boolean
    address__icontains: string
    address__startswith: string
    address__endswith: string
    address__isnull: boolean
    linkedin__icontains: string
    linkedin__startswith: string
    linkedin__endswith: string
    linkedin__isnull: boolean
    snippet__icontains: string
    snippet1__icontains: string
    snippet2__icontains: string
    snippet3__icontains: string
    snippet4__icontains: string
    created_by__id: number
    status__id: number
    prospect_group__name: string
    cnt_campaign: number
    cnt_campaign__gt: number
    campaign__eq: string
    campaign__neq: string
    cnt_replies_received: number
    cnt_replies_received__gt: number
    cnt_replies_received__lt: number
    cnt_email_open: number
    cnt_email_open__gt: number
    cnt_email_open__lt: number
    cnt_email_sent: number
    cnt_email_sent__gt: number
    cnt_email_sent__lt: number
    score__gt:number
    score__lt:number
    is_opted_out: boolean
    is_bounced: boolean
    page: number
    page_size: number
    last_contacted_period__gt: number
    last_contacted_period__lt: number
    last_contacted_period__eq: number
    is_deleted: boolean
    interested_category: number
    is_auto_reply: boolean

}


export class ProspectHistoryResponseModel {
    "results": []
}


// export class SendLimitDate {
//     send_limit_used: number
//     send_limit_max: number
//     send_limit_last_reset_at: Date
//     send_limit_reset_date: Date
//     send_limit_reset_duration: number
// }