import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { MapCommon } from 'src/app/app-providers/map-base/MapCommon';
import { CampaignRunLogModel } from '../../campaign-model/campaign-model';
import { CampaignViewModel } from '../../campaign-view-model/campaign-view-model';

@Component({
  selector: 'app-campaign-step-customize',
  templateUrl: './campaign-step-customize.component.html',
  styleUrls: ['./campaign-step-customize.component.scss']
})
export class CampaignStepCustomizeComponent implements OnInit {

  object: CampaignRunLogModel;
  editormodules: any;
  editorAPIKey: string;
  currentUser: number;
  emailBodyModel: string = '';
  stepSubjectModel: string = '';

  constructor(public modalRef: MdbModalRef<CampaignStepCustomizeComponent>, private vm: CampaignViewModel) {
    this.editormodules = MapCommon.getCampaignReviewEditorModule();
    this.editorAPIKey = MapCommon.getTinyEditorAPIKey();
  }

  ngOnInit(): void {
    this.emailBodyModel = this.object.body;
    this.stepSubjectModel = this.object.subject;
  }

  updateCampaignRunlogObject(){
    var campaignRunlogObject = new CampaignRunLogModel;
    campaignRunlogObject = this.object;
    campaignRunlogObject.body = this.emailBodyModel;
    campaignRunlogObject.subject = this.stepSubjectModel;
    campaignRunlogObject.modified_at = new Date;
    campaignRunlogObject.modified_by = this.currentUser;
    this.vm.parentCampaignStepCustomizeComponent = this;
    this.vm.customizeRunLogEmail(campaignRunlogObject);
  }

  successAfterCustomizedRunLogEmail(){
    this.modalRef.close(true);
  }

}
