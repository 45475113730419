<app-loading-dialog *ngIf="componentLoading">
</app-loading-dialog>

<div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Add to campaign</h5>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modalRef.close()"
    ></button>
  </div>

  <ng-container *ngIf="!showLoading else loading">

    <div class="modal-body" style="background-color: rgb(240, 240, 240);">

      <div class="row">
        <div class="col-3 sefetyOptions"><span>Campaign</span></div>
        <div class="col-9"><span><strong>{{campaignModel.name}}</strong></span></div>
      </div>
  
      <div class="row mt-1">
        <div class="col-3 sefetyOptions"><span>From</span></div>
        <div class="col-9"><span><strong>{{connected_mailbox}}</strong></span></div>
      </div>
  
      <div class="row mt-1">
        <div class="col-3 sefetyOptions"><span>Prospects</span></div>
        <div class="col-9"><span class="sefetyNumbers">{{Selected_Prospect_from_campaign}}</span> <strong> Selected</strong>
          <!-- <span class="downloadIcon" (click)="dowloadProspects('selected')"><i class="fas fa-cloud-download-alt"></i></span> -->
        </div>
        
      </div>
  
      <div class="row mt-1">
  
        <div class="row p-0 m-0 mb-1">
          <div class="col-3 sefetyOptions"><span>Safety Checks</span></div>
          <div class="col-6"><span class="sefetyNumbers">{{outreached_cmp_o}}</span> <strong> in another campaign</strong> 
            <span *ngIf="outreached_cmp_o > 0" class="downloadIcon" (click)="dowloadProspects('outreached_cmp_o')"><i class="fas fa-cloud-download-alt"></i></span>
          </div>
          <div class="col-3">
            <select class="form-select form-select-sm" (change)="changeSaftyCheck($event,'OutReach')" aria-label=".form-select-sm example">
              <option value="0">skip</option>
              <option value="1" selected>keep</option>
            </select>
          </div>
        </div>
  
        <!-- <div class="row p-0 m-0 mb-1">
          <div class="col-3"><span></span></div>
          <div class="col-6"><span class="sefetyNumbers">0</span> in this campaign</div>
          <div class="col-3">
            <select class="form-select form-select-sm" aria-label=".form-select-sm example">
              <option value="0">skip</option>
              <option value="1" selected>keep</option>
            </select>
          </div>
        </div> -->
  
        <div class="row p-0 m-0 mb-1">
          <div class="col-3"><span></span></div>
          <div class="col-6"><span class="sefetyNumbers">{{replied}}</span> <strong> previously replied</strong>
            <span *ngIf="replied > 0" class="downloadIcon" (click)="dowloadProspects('replied')"><i class="fas fa-cloud-download-alt"></i></span>
          </div>
          <div class="col-3">
            <select class="form-select form-select-sm"  (change)="changeSaftyCheck($event,'replied')" aria-label=".form-select-sm example">
              <option value="0">skip</option>
              <option value="1" selected>keep</option>
            </select>
          </div>
        </div>
  
        <div class="row p-0 m-0 mb-1">
          <div class="col-3"><span></span></div>
          <div class="col-6"><span class="sefetyNumbers">{{bounced}}</span> <strong> previously bounced </strong>
            <span *ngIf="bounced > 0" class="downloadIcon" (click)="dowloadProspects('bounced')"><i class="fas fa-cloud-download-alt"></i></span>
          </div>
          <div class="col-3">
            <select class="form-select form-select-sm" disabled  (change)="changeSaftyCheck($event,'bounced')" aria-label=".form-select-sm example">
              <option value="0" selected>skip</option>
              <option value="1">keep</option>
            </select>
          </div>
        </div>
  
        <!-- <div class="row p-0 m-0 mb-1">
          <div class="col-3"><span></span></div>
          <div class="col-6"><span class="sefetyNumbers">93</span> in engaged</div>
          <div class="col-3">
            <select class="form-select form-select-sm" aria-label=".form-select-sm example">
              <option value="0">skip</option>
              <option value="1" selected>keep</option>
            </select>
          </div>
        </div> -->
  
        <div class="row p-0 m-0 mb-1">
          <div class="col-3"><span></span></div>
          <div class="col-6"><span class="sefetyNumbers">{{rejected_clv}}</span><strong> CLV rejected</strong>
            <span *ngIf="rejected_clv > 0" class="downloadIcon" (click)="dowloadProspects('rejected_clv')"><i class="fas fa-cloud-download-alt"></i></span>
           </div>
          <div class="col-3">
            <select class="form-select form-select-sm" (change)="changeSaftyCheck($event,'clv-rejection')" aria-label=".form-select-sm example">
              <option value="0">skip</option>
              <option value="1" selected>keep</option>
            </select>
          </div>
        </div>
  
        <div class="row p-0 m-0 mb-1">
          <div class="col-3"><span></span></div>
          <div class="col-6"><span class="sefetyNumbers">{{is_ooo}}</span> <strong> on vacation </strong>
            <span *ngIf="is_ooo > 0" class="downloadIcon" (click)="dowloadProspects('is_ooo')"><i class="fas fa-cloud-download-alt"></i></span>
          </div>
          <div class="col-3">
            <select class="form-select form-select-sm"  (change)="changeSaftyCheck($event,'out_of_ofc')" aria-label=".form-select-sm example">
              <option value="0" selected>skip</option>
              <option value="1" >keep</option>
            </select>
          </div>
        </div>
  
        <div class="row p-0 m-0 mb-1">
          <div class="col-3"><span></span></div>
          <div class="col-6"><span class="sefetyNumbers">{{blocked_cl_t}}</span> <strong> in {{clientName}}'s DNC </strong>
            <span *ngIf="blocked_cl_t > 0" class="downloadIcon" (click)="dowloadProspects('blocked_cl_t')"><i class="fas fa-cloud-download-alt"></i></span>
          </div>
          <div class="col-3">
            <select class="form-select form-select-sm" disabled (change)="changeSaftyCheck($event,'client-dnc')" aria-label=".form-select-sm example">
              <option value="0" selected>skip</option>
              <option value="1" >keep</option>
            </select>
          </div>
        </div>
  
        <div class="row p-0 m-0 mb-1">
          <div class="col-3"><span></span></div>
          <div class="col-6"><span class="sefetyNumbers">{{blocked_cl_o}}</span> <strong> in other clients' DNC </strong>
            <span *ngIf="blocked_cl_o > 0" class="downloadIcon" (click)="dowloadProspects('blocked_cl_o')"><i class="fas fa-cloud-download-alt"></i></span>
          </div>
          <div class="col-3">
            <select class="form-select form-select-sm"  (change)="changeSaftyCheck($event,'global-dnc')" aria-label=".form-select-sm example">
              <option value="0">skip</option>
              <option value="1" selected>keep</option>
            </select>
          </div>
        </div>
  
        <div class="row p-0 m-0 mb-1">
          <div class="col-3"><span></span></div>
          <div class="col-6"><span class="sefetyNumbers">{{opted_out}}</span> <strong>  previously opted out</strong>
            <span *ngIf="opted_out > 0" class="downloadIcon" (click)="dowloadProspects('opted_out')"><i class="fas fa-cloud-download-alt"></i></span>
          </div>
          <div class="col-3">
            <select class="form-select form-select-sm"  (change)="changeSaftyCheck($event,'opted_out')"  aria-label=".form-select-sm example">
              <option value="0" selected>skip</option>
              <option value="1">keep</option>
            </select>
          </div>
        </div>
  
      </div>
  
      <div class="row mt-1">
        <div class="col-3 sefetyOptions"><span>Ignored</span></div>
        <div class="col-9"><span class="sefetyNumbers">{{ignored_Prospect_from_campaign}}</span><strong> prospects</strong> 
          <!-- <span class="downloadIcon" (click)="dowloadProspects('ignored_Prospect_from_campaign')"><i class="fas fa-cloud-download-alt"></i></span> -->
        </div>
      </div>
  
      
   
  
    </div>

  </ng-container>

  <ng-template #loading>
    <div class="d-flex justify-content-center m-3">
      <div class="spinner-border spinner-border-sm mt-1" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div>
        <span class="ms-2">Checking... Please wait!</span>
      </div>
    </div>
   
  </ng-template>
  
  <div class="modal-footer">
    <button type="button" class="btn btn-link" (click)="modalRef.close()">
      Close
    </button>
    <button type="button" class="btn btn-primary" (click)="updateCampaign()">Add {{totalSelectProspects}} prospects</button>
  </div>